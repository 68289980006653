import React from "react";
import { v4 as uuid } from "uuid";
import _blank from "../../../images/blank-profile.png";

export const UserHeader = ({ userInfo }) => {
  const {
    TrendLevel,
    ProfileImageUrl,
    DisplayName,
    MemberName,
    Service,
    ServiceTags,
  } = userInfo;

  // const { More, Phone, chat } = row.Actions[0];
  return (
    <div className="d-flex align-items-center" style={{ maxWidth: "300px" }}>
      <div className="col-3 d-flex align-items-center">
        <div
          className={
            TrendLevel == 0
              ? "pic-circle-neutral"
              : TrendLevel == 1
              ? "pic-circle-arrow-up"
              : "pic-circle-arrow-down"
          }
        >
          <div class="student-list-pic" style={{ flex: "0 0 29%" }}>
            <img
              src={ProfileImageUrl || _blank}
              // alt="student"
              class="img-circle"
              height="40"
              width="40"
              style={{
                borderRadius: "50%",
                padding: "2px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-9 d-flex align-items-center">
        <div className="d-flex flex-column">
          <div>
            <span className="member-name">{DisplayName || MemberName}</span>{" "}
            <span className="member-service">{Service}</span>
          </div>
          <div style={{ wordBreak: "break-all" }}>
            {ServiceTags &&
              ServiceTags.split(",").map((tag) => {
                return (
                  <span className="member-tag" key={uuid()}>
                    {tag}
                  </span>
                );
              })}
            {/* <p className="member-tag">{}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};
