import ReduxHandler from "../../core/handler";

var keys = [
  "HANDLE_ERRORS",
  "DISPLAY_ERROR",
  "FORGET_PASSWORD",
  "RESET_PASSWORD",
  "COMBOS",
  "FILTER_COMBO",
  "SMARTCOMBOS",
  "LOG_DEVICE",
  "LOG_ERROR",
  "LOAD_MENU",
  "LOGOUT",
  "STUDENT_PIN_UPSERT",
  "STUDENT_VALIDATE_PIN",
  "DASHBOARD_GET",
  "STUDENTS",
  "STUDENT_ADD",
  "STUDENT_GET",
  "TODO_GET_ALL",
  "TODO_GET_ALL_BY_STUDENT",
  "TODO_ADD",
  "ACTIVITY_GET_ALL",
  "ACTIVITY_GET_ALL_BY_STUDENT",
  "STUDENT_MARK_COMPLETE",
  "STUDENT_UNASSIGN",
  "STUDENT_DISCHARGE",
  "STUDENT_REINSTATE",
  "STUDENT_NOTE_ADD",
  "GET_REPORT_DATA",
  "ANNOUNCEMENT_GET_ALL",
  "ANNOUNCEMENT_GET_JSON",
  "ANNOUNCEMENT_UPSERT",
  "ANNOUNCEMENT_DELETE",
  "STUDENT_GET_PROFILE_DASHBOARD_DATA",
  "STUDENTS_SEARCH",
  "LIST_JOB_TITLE",
  "UPDATE_JOB_TITLE",
  "UPDATE_ROLE",
  "DELETE_ROLE",
  "DELETE_JOB_TITLE",
  "UPDATE_JOB_TITLE_SYNC",
  "USER_FEEDBACK",
  "GET_DASHBOARD_LOAD_APP_USERS",
  "GET_VALIDATE_IMAGE",
  "GET_LICENSE_ACTIVITES",
  "GET_CURRENT_LICENSES",
  "GET_LICENSES_INVOICE",
  "GET_LICENSES_RECEIPT",
  "ADD_LICENSES",
  "GET_PAYMENT_INFO",
  "IS_ACH_VALIDATION",
  "ACH_VALIDATE",
  "GET_BILLING_INFO",
  "UPDATE_PAYMENT_METHODE",
  "ADD_NEW_APPLICANT",
  "GET_APP_SUBSCRIBERS",
  "GET_APP_SUBSCRIBERS_DASHBOARD",
  "GET_APP_SUBSCRIBERS_PROFILE",
  "GET_APP_SUBSCRIBERS_CHAT",
  "GET_APP_SUBSCRIBERS_CHAT_COACH",
  "GET_APP_SUBSCRIBERS_ACTIVITIES",
  "APP_SUBSCRIBERS_INSERT_NOTE",
  "APP_SUBSCRIBERS_ADD_TASK",
  "APP_SUBSCRIBERS_DELETE_TASK",
  "APP_SUBSCRIBERS_USER_SEARCH",
  "APP_SUBSCRIBERS_COMPLETE_TASK",
  "APP_SUBSCRIBERS_UPDATE_CASE",
  "GET_LIST_MOBILE_APP_ROLE",
  "APP_SUBSCRIBERS_MAP_HISTORY",
  "APP_INVITE",
  "GET_MOBILE_APP_DOWNLOAD_STATUS",
  "UPDATE_MOBILE_APP_ROLE",
  "ADD_NOTE_TYPE",
  "CHANGE_APP_ROLE",
  "TWO_STEP_AUTH",
  "VALIDATE_PASS_CODE",
  "APP_INVITE_DASHBOARD",
  "GET_ALL_CALL_SESSION",
  "ADD_CALL_SESSION",
  "GET_ALL_VACATION",
  "ADD_VACATION_DAYS",
  "GET_ONE_CALL_TIMESLOTS",
  "GET_ONE_CALL_SESSION",
  "INVITE_ONE_CALL_SESSION",
  "CANCEL_ONE_CALL_SESSION",
  "GET_STRIPE_API_KEY",
  "EDIT_GROUP_CALL_SESSION",
  "GET_CALL_SESSION_VIDEO",
  "GET_CALL_SESSION_VIDEO_URL",
  "GET_REPORTS",
  "GET_PROMO_CODE",
  "GET_COUNSELOR",
  "GET_CLIENT_COUNSELOR",
  "SEND_VOUCHER_CODE",
  "UPLOAD_LEADER_PHOTO",
  "MARK_SYSTEM_OBSERVATION",
  "APP_MARK_SYSTEM_OBSERVATION",
  "GET_ANALYTICS",
  "GET_ACTIVE_CLIENT",
  "IS_MANAGE_LICENSE_REQUIRED",
  "GET_SMALL_GROUP_USERS",
  "GET_NOTES",
  "GET_CATEGORY_CLIENT",
  "GET_CLIENT_DEMOGRAPHIC",
  "GET_COST_OF_CARE",
  "GET_BEHAVIORAL_RATING",
  "GET_SCORE_IMPROVEMENT",
  "GET_PROVIDER_PERFORMANCE",
  "GET_TRIGGER_LOCATION",
  "GET_SERVICE_DELIVERED",
  "GET_REFERRAL_SOURCE",
  "GET_DOCUMENT_LIST",
  "ARCHIVE_STUDENT_DOCUMENT",
  "UPLOAD_DOCUMENT",
  "DOWNLOAD_DOCUMENT",
  "NEWS_UPSERT",
  "GET_LIST_NEWS",
  "GET_NEWS",
  "DELETE_NEWS",
  "GET_EMBED_INFO",
  "GET_CLIENT_LIST",
  "GET_CLIENT_DETAIL",
  "UPSERT_PATIENT_ENROLLMENT",
  "UPSERT_CLIENT_INSURANCE",
  "UPSERT_CLIENT_MEDICAL",
  "UPSERT_CLIENT_PAYMENT",
  "UPSERT_PROVIDER_ENROLLMENT",
  "UPSERT_APPOINTMENT",
  "LIST_APPOINTMENT",
  "GET_APPOINTMENT",
  "DELETE_APPOINTMENT",
  "COMBOS_MEDICATE",
  "COMBOS_PRACTITIONER",
  "COMBOS_PRACTITIONER_OFFICE",
  "UPSERT_GROUP",
  "LIST_GROUP",
  "GET_GROUP",
  "DELETE_GROUP",
  "UPSERT_CLIENT_GROUP",
  "GET_CLIENT_GROUP",
  "UPSERT_GROUP_APPOINTMENT",
  "UPSERT_GROUP_APPOINTMENT_GROUP_ALL_INFO_JSON",
  "GET_LIST_CLIENT_GROUP",
  "PATIENT_COMBO_GROUP",
  "DEACTIVATE_GROUP_APPOINTMENT",
  "UPSERT_CLIENT_CONSENT",
  "LIST_CLIENT_CONSENT",
  "DEACTIVATE_CLIENT_CONSENT",
  "DEACTIVATE_CLIENT_SCREENING",
  "LOAD_JUDICIAL_DASHBOARD",
  "GET_JUDICIAL_USER_LIST",
  "UPLOAD_DOCUMENT_USING_MULTIPART",
  "UPSERT_CLIENT_DOCUMENT",
  "LIST_CLIENT_DOCUMENT",
  "DOWNLOAD_CLIENT_DOCUMENT",
  "ARCHIVE_DOCUMENT",
  "LIST_GROUP_MEMBERSHIP",
  "GET_CONSENT_FORM_TEMPLATE",
  "GET_COMBO_FACEILITY",
  "DOWNLOAD_CLIENT_CONSENT",
  "COMBO_PRACTITIONER_ALL",
  "COMBO_FACILITY_ALL",
  "SAVE_CLIENT_CONSENT",
  "GET_TREATMENT_PLAN",
  "GET_DIAGNOSIS",
  "UPSERT_TREATMENT_PLAN_PROBLEM",
  "UPSERT_MEDICAL_ANALYSIS",
  "UPSERT_TREATMENT_PLAN_GOAL",
  "UPSERT_TREATMENT_PLAN_OBJECTIVE",
  "UPSERT_TREATMENT_PLAN_INTERVENTION",
  "DELETE_TREATMENT_PLAN",
  "GET_DIAGNOSIS_DESCRIPTION",
  "GET_DIAGNOSIS_CODE",
  "LIST_TREATMENT_PLAN",
  "COMBO_PROBLEM_TEMPLATE",
  "COMBO_GOAL_TEMPLATE",
  "RECURRING_COUNT",
  "LOAD_CASE_PROFILE",
  "LOAD_LOCATION_AUTH_HISTORY",
  "SCREEN_AUTHORIZATION",
  "LIST_CONSENT_FORM",
  "UPSERT_TREATMENT_PLAN_DIMENSION",
  "SAVE_CONSENT_SIGN",
  "CONSENT_FORM_DOWNLOAD",
  "LIST_MEETING",
  "UPSERT_PROGRESS_GROUP_NOTE",
  "GET_PROGRESS_NOTE_GROUP",
  "GET_USER_NOTE_PATIENT",
  "GET_USER_NOTE_COMMENT",
  "ADD_USER_NOTE_COMMENT",
  "GET_USER_NOTE_EMOJI",
  "ADD_USER_NOTE_EMOJI",
  "COMBO_INTERVENTION_TEMPLATE",
  "GET_PROGRESS_NOTE_FOR_INTERVENTION",
  "UPSERT_PAYER",
  "LIST_PAYER",
  "DELETE_PAYER",
  "GET_PAYER",
  "COMBO_PAYER",
  "UPSERT_SERVICE_CODE",
  "LIST_SERVICE_CODE",
  "DELETE_SERVICE_CODE",
  "GET_SERVICE_CODE",
  "COMBO_CODE",
  "GET_CODE_DESCRIPTION",
  "APPOINTMENT_NOTE_LIST",
  "UPSERT_APPOINTMENT_NOTE",
  "GET_APPOINTMENT_NOTE",
  "COMBO_PLACE_OF_SERVICE",
  "LIST_SERVICE_LINE",
  "GET_SERVICE_LINE",
  "UPSERT_SERVICE_LINE",
  "UPSERT_APPOINTMENT_NOTE_ADDENDUM",
  "LIST_GROUP_APPOINTMENT_NOTE",
  "GET_GROUP_APPOINTMENT_NOTE",
  "COMBO_TREATMENT_PLAN_INTERVENTION",
  "LIST_FEE_SCHEDULE",
  "GET_FEE_SCHEDULE",
  "UPSERT_FEE_SCHEDULE",
  "GET_TREATMENT_PLAN_GRAPH",
  "LIST_GROUP_SERVICE_LINE",
  "GET_GROUP_SERVICE_LINE",
  "GET_GROUP_BANNER",
  "UPSERT_GROUP_SERVICE_LINE",
  "UPSERT_GROUP_APPOINTMENT_NOTE",
  "UPSERT_GROUP_APPOINTMENT_NOTE_ADDENDUM",
  "UPSERT_APPOINTMENT_NOTE_INTERVENTION",
  "MARK_AS_COMPLETE",
  "GET_SERVICE_LINE_COST",
  "UPSERT_PIN",
  "LOAD_SMALLGROUP_DASHBOARD",
  "UPSERT_TREATMENT_PLAN",
  "LIST_CLIENT_DIAGNOSIS",
  "GET_CLIENT_DIAGNOSIS",
  "DELETE_CLIENT_DIAGNOSIS",
  "VALIDATE_PIN_EXISTS",
  "CLONE_DIAGNOSIS",
  "CLONE_TREATMENT_PLAN_DIAGNOSIS",
  "RCM_CLIENT_MERGE",
  "PRE_VALIDATE_PIN",
  "VALIDATE_CLIENT",
  "GET_APP_ADVISOR",
  "GET_MEDIA_VIDEO_URL",
  "GET_DASHBOARD_MENU",
  "GET_SERVICE_CODE_BUNDLE",
  "LIST_SERVICE_CODE_BUNDLE",
  "GET_STUDENT_NOTE",
  "GET_STUDENT_NOTE_COMMENT",
  "ADD_STUDENT_NOTE_COMMENT",
  "ADD_STUDENT_NOTE_EMOJI",
  "GET_STUDENT_NOTE_EMOJI",
  "GET_TREATMENT_PLAN_ACCORDIN",
  "GET_ADVISOR_COST",
  "UPSERT_ADVISOR_COST",
  "CLAIMS_PDF_DOWNLOAD",
  "GET_CLIENT_SERVICE",
  "GET_DASHBOARD_DAILY_SCHEDULES",
  "GET_DASHBOARD_DAILY_CASE_LOAD",
  "GET_DASHBOARD_ALERT",
  "ADD_CLIENT_INSURANCE_ELIGIBILITY",
  "EDIT_CLIENT_INSURANCE_ELIGIBILITY",
  "DELETE_CLIENT_INSURANCE_ELIGIBILITY",
  "DISCHARGE_SUMMERY_SAVE",
  "GET_CLIENT_APPOINTMENT",
  "GET_GROUP_APPOINTMENT",
  "UNSIGN_FORM",
  "GET_FILTERS",
  "LIST_LAB_ORDER",
  "GET_LAB_ORDER",
  "UPSERT_LAB_ORDER",
  "COMBO_DIAGNOSIS_LIST",
  "GET_MEDICATION_NOTE",
  "UPSERT_CLIENT_GROUP_APPOINTMENT",
  "SIGN_GROUP_APPOINTMENT_NOTE",
  "LIST_SERVICE_LINE_PAYMENT",
  "GET_SERVICE_LINE_PAYMENT",
  "UPSERT_SERVICE_LINE_PAYMENT",
  "DELETE_SERVICE_LINE_PAYMENT",
  "COMBO_SUPERVISOR",
  "LOCATION_DAY_HISTORY",
  "UPSERT_PAYMENT_CLIENT",
  "GET_INSURANCE_PRIORITY_ORDER",
  "UPSERT_INSURANCE_PRIORITY_ORDER",
  "LIST_CW_PAYMENT",
  "GET_CW_PAYMENT",
  "DELETE_CW_PAYMENT",
  "PAST_MEETING_INFO",
  "LIST_PAST_MEETING_INFO",
  "COMBO_APPOINTMENT_TREATMENT_PLAN",
  "LINK_APPOINTMENT",
  "APPOINTMENT_LINK_GET",
  "GET_BASIC_PROFILE",
  "GET_INVITED_LIST",
  "INVITE_RESEND",
  "SUSPICIOUS_TERM_ACK",
  "GET_BROADCAST_FILTER",
  "SEND_BROADCAST",
  "GET_SERVICE_LINE_DIAGNOSIS",
  "UPSERT_SERVICE_LINE_DIAGNOSIS",
  "COMBO_BROADCAST_TEMPLATE",
  "UPSERT_BROADCAST_TEMPLATE",
  "DELETE_NOTE_TYPE",
  "LIST_CLIENT_AUTHORIZATION",
  "GET_CLIENT_AUTHORIZATION",
  "DELETE_CLIENT_AUTHORIZATION",
  "UPSERT_CLIENT_AUTHORIZATION",
  "GET_NOTE_TYPE_ARCHIVE",
  "GET_NOTE_TYPE_LIST",
  "GENERATE_CALL_TOKEN",
  "BADGE_COMBO_GET",
  "BADGE_UPDATE",
  "PANAS_SCALE_MOODS_GET",
  "PANAS_TAG_UPSERT",
  "BED_ASSIGNMENT_GET_ALL",
  "BED_ASSIGNMENT_UPSERT",
  "UPCOMING_DISCHARGES_GET_ALL",
  "UPCOMING_ADMISSIONS",
  "ADMISSION_CANCEL",
  "PANAS_TAG_DELETE",
  "ADVISOR_MEMBER_TRENDS_HISTORY_GET",
  "DASHBOARD_ANALYTICS_GET",
  "USER_ACTIVITY_GET",
  "USER_SUSPICIOUS_WORD_GET",
  "USER_NOTIFICATION_GET",
  "USER_MEMBER_TREND_HISTORY",
  "UPSERT_CLIENT_NOTE",
  "GET_PREVIOUS_NOTE",
  "MEMBER_TREND_TRIGGER_HISTORY",
  "MEMBER_HEADER_INFO_GET",
  "USER_ROLE_LIST",
  "UPDATE_ROLE_PERMISSIONS",
  "IS_LICENSE_AVAILABLE",
  "SP_LIST_COMBOS",
  "COMBOS_DYNAMIC",
  "DYNAMIC_GRID_LIST",
  "GET_GRID",
  "GET_GRID_DATA",
  "DYNAMIC_GRID_DATA_GET",
  "GET_ASSESSMENT_DETAIL_LIST_V2",
  "ASSIGN_MULTIPLE_V2",
  "CHAT_FRIEND_LIST_GET",
  "LIST_CLIENT_V2",
  "STUDENT_GET_ALL_COURSES",
  "STUDENT_COURSE_ASSIGN",
  "COURSE_GET_ALL",
  "COURSE_DELETE",
  "APP_SUBSCRIBERS_ADD_COURSE",
  "APP_SUBSCRIBERS_DELETE_COURSE",
  "APP_SUBSCRIBERS_COMPLETE_COURSE",
  "CENTER_GET_ALL",
  "CENTER_DELETE",
  "UPDATE_STAFF_CENTER",
  "COMBO_CENTER_CONSENT",
  "CENTER_PROGRAM_TYPE_GET",
  "GET_CENTER_ADVISOR_DEFAULT_COSTS",
  "UPDATE_CENTER_ADVISOR_DEFAULT_COST",
  "CENTER_REACTIVATE",
  "GET_CONTRACT",
  "UPSERT_CONTRACT",
  "DELETE_CONTRACT",
  "GET_CONTRACT_TEMPLATE",
  "UPSERT_CONTRACT_TEMPLATE",
  "GET_STUDENT_CONTRACT_TEMPLATE",
  "CREATE_CONTRACT_FROM_TEMPLATE",
  "CLOSE_CONTRACT",
  "MENU_CONTRACT_TEMPLATE",
  "ARCHIVE_CONTRACT_TEMPLATE",
  "ADD_FAVORITE_TEMPLATE",
  "REMOVE_FAVORITE_TEMPLATE",
  "STAFF_DELETE",
  "UPDATE_STAFF_EMAIL",
  "UPDATE_STAFF_PASSWORD",
  "UPLOAD_STAFF_IMAGE",
  "GET_STAFF_SHIFT_LOG",
  "ADD_STAFF_SHIFT_LOG",
  "STAFF_REACTIVATE",
  "CHECK_STAFF_PRO_BIO",
  "UPDATE_STAFF_PRO_BIO",
  "VALIDATE_STAFF_PIN",
  "GET_STAFF_PRACTITIONER",
  "SURVEY_STUDENTS_GET_ALL_PENDING",
  "SURVEY_QUESTION_GET_MULTIPLE",
  "SURVEY_ASSIGN",
  "SURVEY_GET_TO_ASSIGN",
  "SURVEY_TASK_MARK_IN_COMPLETE",
  "STUDENT_SURVEY_DASHBOARD_GET_JSON",
  "SURVEY_ANSWERS_GET",
  "APP_SUBSCRIBERS_SURVEY_ASSIGN",
  "APP_SUBSCRIBERS_SURVEY_UNASSIGN",
  "LIST_SURVEY_QUESTION_CATEGORY",
  "LIST_SURVEY_QUESTION_CATEGORY_ASSESSMENT",
  "LIST_SURVEY_QUESTION_ASSESSMENT",
  "GET_SURVEY_SCORE",
  "LOAD_APP_SUB_SURVEY_DASHBOARD",
  "APP_SUB_SURVEY_ANS_GET",
  "LIST_SURVEY_QUESTION_DISCHARGE",
  "GET_SURVEYS_V2",
  //youu api started.....................................................................................................
  "LOGIN",
  "LOAD_DEFAULT_VALUES",
  "USER_DATA_GET",
  "ALL_GET",
  "SIDE_BAR_MENU_GET",
  "CLIENT_FRIEND_ALL_GET",
  "POPULATION_ACTIVITIES_CLIENT_LIST_GET",
  "CLIENT_LIST_DROPDOWN",
  //Dynamic API's,
  "GET_MULTISTEP_TAB",
  "GET_DYNAMIC_SCREEN_TAB",
  "GET_DYNAMIC_GRID_FILTER",
  "DYNAMIC_GRID_COLUMN_CONFIGURATION_GET",
  "UPSERT_FORM_DATA",
  "FORM_DATA_GET",
  "GET_FORM_FIELD_META_DATA",
  //Combo API's
  "COMBO_LIST_GET",
  "SERVICE_CODE_LIST_GET",
  "COUNSELOR_LIST_GET",
  "FACILITY_LIST_GET",
  //Profile API's
  "GET_USER_INFO",
  //Tasks API's
  "TASK_OPTIONS_GET",
  "TASK_LIST_GET",
  "TASK_ASSIGN",
  "TASK_UNASSIGN",
  "TASK_MARK_COMPLETE",
  //Center API's
  "CENTER_OPTION_GET",
  "CENTER_UPSERT",
  "CENTER_LIST_GET",
  "CENTER_GET",
  //Course API's
  "COURSE_OPTION_GET",
  "COURSE_ASSIGN",
  "COURSE_UNASSIGN",
  "COURSE_MARK_AS_COMPLETE",
  "ASSIGNED_COURSE_LIST_GET",
  "COURSE_UPSERT",
  "COURSE_GET",
  "COURSE_LIST_GET",
  //ALBUM API's
  "GET_CLIENT_ALBUM",
  //Posts API's
  "POST_LIST_GET",
  //Journal API's
  "GET_ALL_JOURNAL",
  "JOURNAL_ADD",
  "GET_ALL_JOURNAL_COMMENTS",
  "JOURNAL_COMMENT_UPSERT",
  //Timetime API's
  "TIME_TRAVEL_LIST_GET",
  //Trigger API's
  "TRIGGERS_LIST_GET",
  //Dashboard API's
  "MEMBER_TRENDS_GET",
  //Assessment API's
  "SURVEY_QUESTION_CATEGORY_OPTION_GET",
  "ASSESSMENTS_MULTIPLE_ASSIGN",
  "ASSESSMENTS_LIST_GET",
  "ASSESSMENTS_MARK_AS_DISCARD",
  //Intake API's
  "INTAKE_CLIENT_UPSERT",
  "INTAKE_INSURANCE_UPSERT",
  "INTAKE_INSURANCE_PRIORITY_ORDER_GET",
  "INTAKE_INSURANCE_PRIORITY_ORDER_UPDATE",
  "INTAKE_MEDICAL_HISTORY_UPSERT",
  "INTAKE_FORM_DATA_GET",
  "INTAKE_ENROLL_NOW",
  "PRE_INTAKE_GRID_REPORTS",
  "PRE_INTAKE_DEFAULT_ASSESSMENT_GET",
  //Trend API's
  "TRENDS_LIST_GET",
  "COURSE_STATUS_UPDATE",
  "CENTER_STATUS_UPDATE",
  //Growth Plan API's
  "GROWTH_PLAN_TEMPLATE_UPSERT",
  "GROWTH_PLAN_TEMPLATE_MENU_GET",
  "GROWTH_PLAN_TEMPLATE_GET",
  "GROWTH_PLAN_TEMPLATE_FAVORITE_ADD",
  "GROWTH_PLAN_TEMPLATE_FAVORITE_DELETE",
  "GROWTH_PLAN_TEMPLATE_DELETE",
  "CLIENT_GROWTH_PLAN_UPSERT",
  "CLIENT_GROWTH_PLAN_LIST_GET",
  "CLIENT_GROWTH_PLAN_GET",
  "CLIENT_GROWTH_PLAN_DELETE",
  "CLIENT_GROWTH_PLAN_FROM_TEMPLATE_INSERT",
  "CLIENT_GROWTH_PLAN_CLOSE",
  //Staff  API's
  "STAFF_LIST_GET",
  "STAFF_GET",
  "STAFF_UPSERT",
  "GET_STAFF_PERMISSION",
  "UPDATE_STAFF_PERMISSION",
  "CLINICAL_CENTER_GET",
  "STAFF_MOBILE_APP_INVITE_SEND",
  // Small Group API's
  "GET_SMALL_GROUP_LIST",
  "SMALL_GROUP_UPSERT",
  "SMALL_GROUP_GET",
  "SMALL_GROUP_REFERRAL",
  "SMALL_GROUP_USER_LIST_GET",
  "SMALL_GROUP_USER_REMOVE",
  "SMALL_GROUP_LOCATION_GET",
  "SMALL_GROUP_LOCATION_UPDATE",
  "SMALL_GROUP_IMAGES_GET",
  "UPSERT_BROADCAST_TEMPLATE",
  "BROADCAST_SEND",
  // Survey API's
  "SURVEY_ASSIGN_MULTIPLE",
  "SURVEY_ANSWER_UPSERT",
  "CLIENT_SURVEY_LIST_GET",
  "SURVEY_QUESTION_CATEGORY_LIST_GET",
  "SURVEY_QUESTION_HIERARCHIAL_GET",
  "SURVEY_MARK_AS_DISCARD",
  "SURVEY_ASSIGNED_LIST_GET",
  //CLINICAL GROUPS API's
  "GROUP_UPSERT",
  "GROUP_GET",
  "GROUP_LIST_GET",
  "CLIENT_GROUP_UPSERT",
  "CLIENTS_NOT_PARTICIPATING_IN_GROUP",
  "CLIENT_GROUP_GET",
  "GROUP_APPOINTMENT_GROUP_GET",
  "GROUP_APPOINTMENT_GROUP_NOTES_GET",
  "GROUP_SERVICE_LINES_GET",
  "GROUP_DEACTIVATE",
  //Coupon
  "COUPON_LIST_GET",
  "UPSERT_COUPON_CODE",
  "DELETE_COUPON_CODE",
  "GET_COUPON_CODE",
  //Breathalyzer
  "GET_BREATHALYZER_SCHEDULE",
  "LIST_BREATHALYZER_SCHEDULE",
  "UPSERT_BREATHALYZER_SCHEDULE",
  "BREATHALYZER_PUSH_A_TEST",
  "BREATHALYZER_ENABLE",
  "GET_BREATHALYZER_TEST_RESULT",
  "GET_BREATHALYZER_TEST_UPCOMING",
  "BREATHALYZER_TEST_UPCOMING_TOGGLE",
  "GET_STUDENT_BREATHALYZER_TEST_RESULT",
  //Miscellaneous
  "MISCELLANEOUS_TASK_GET",
  "MISCELLANEOUS_TASK_LIST_GET",
  "MISCELLANEOUS_TASK_DELETE",
  "MISCELLANEOUS_TASK_UPSERT",
  "MISCELLANEOUS_NOTE_TYPE_INSERT",
  "MISCELLANEOUS_COMBO_NOTE_TYPES_LIST_GET",
  "MISCELLANEOUS_NOTE_TYPE_DELETE",
  "MISCELLANEOUS_NOTE_TYPES_LIST_GET",
  "MISCELLANEOUS_NOTE_TYPE_GET",
  //Appointments
  "PRACTITIONER_OPTIONS_GET",
  "FACILITY_OPTIONS_GET",
  "COMBO_PRACTITIONER",
  "COMBO_FACILITY",
  "APPOINTMENT_LIST_GET",
  "APPOINTMENT_UPSERT",
  "GROUP_APPOINTMENT_UPSERT",
  "BLOCK_APPOINTMENT_UPSERT",
  "STAFF_PIN_VALIDATE",
  "APPOINTMENT_NOTE_GET",
  "APPOINTMENT_NOTE_UPSERT",
  "APPOINTMENT_NOTE_LIST_GET",
  "APPOINTMENT_NOTE_ADDENDUM_UPSERT",
  "GROUP_APPOINTMENT_NOTE_GET",
  "GROUP_APPOINTMENT_NOTE_UPSERT",
  "GROUP_OPTION_GET",
  "CLIENT_GROUP_OPTIONS_GET",
];
var handler = new ReduxHandler(keys);

var ActionTypes = handler.getActionTypes();

var Actions = handler.getActions();

export { ActionTypes, Actions };
