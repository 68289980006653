import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import SVG from "react-inlinesvg";
import _blank from "../../../images/blank-profile.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _Close from "../../../images/close.svg";
const ChatComments = (props) => {
  const { commentsData } = useSelector(
    ({ get_all_journal_comments: { data, isFetching, error } }) => {
      const { Data, Message } = data || {};

      return {
        commentsData: Data && Data.Comments,
      };
    },
    shallowEqual
  );
  return (
    <>
      <div className="container">
        <div className="text-right mt-2">
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        <div className="row">
          {commentsData &&
            commentsData.map((item, index) => {
              const {
                Badge,
                Comment,
                CommentDateTime,
                CommentFriendProfileImageUrl,
                PreferredName,
                UserGuid,
                UserNoteCommentGuid,
                UserNoteEmoji,
                ClientId,
              } = item;
              var appliedStyle = {
                minWidth: "300px",
                maxWidth: "400px",
                background:
                  parseInt(props.ID) == ClientId ? "#F8F8F8" : "#E5F0FF",
                borderRadius: "4px",
              };

              if (parseInt(props.ID) == ClientId) {
                appliedStyle.display = "block";
                appliedStyle.margin = "0 50%";
              }
              return (
                <div
                  className="col-12 mt-5 mb-5 p-4"
                  style={{
                    ...appliedStyle,
                  }}
                  key={uuid()}
                >
                  <div className="col-12 d-flex align-items-center">
                    <div
                      class="student-list-pic"
                      // style={{ flex: "0 0 29%" }}
                    >
                      <img
                        src={CommentFriendProfileImageUrl || _blank}
                        // alt="student"
                        class="img-circle"
                        height="40"
                        width="40"
                        style={{
                          borderRadius: "50%",
                          padding: "2px",
                          objectFit: "cover",
                        }}
                      />
                    </div>{" "}
                    &nbsp;&nbsp;
                    <div className="member-name">{PreferredName}</div>
                    &nbsp;&nbsp;
                    <div className="date-time-style"> {CommentDateTime}</div>
                  </div>

                  <div className="col-12 d-flex align-items-center mt-3">
                    <div>{Comment}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default ChatComments;
