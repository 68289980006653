import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Icon from "@material-ui/core/Icon";
import SVG from "react-inlinesvg";
import Tooltip from "@material-ui/core/Tooltip";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  assignedCourseListGet,
  courseUnassign,
  courseMarkAsComplete,
} from "../../../redux/actions";
import _RedCross from "../../images/icons/redCross.svg";
import _BlueCheck from "../../images/icons/blueCheck.svg";
import _Edit from "../../images/icons/edit.svg";
import CourseForm from "./forms/assignCourse";
import { CourseCard } from "../../components/custom-cards";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Course = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [drawerState, setDrawerState] = useState({
    right: false,
  });
  const [open, setOpen] = React.useState(false);
  const [courseCenterStudentId, setCourseCenterStudentId] = React.useState();
  const [score, setScore] = React.useState();
  const [rowData, setRowData] = React.useState({});

  const handleClickOpen = (data) => {
    const { ClientCourseCenterId } = data;
    setOpen(true);
    setCourseCenterStudentId(ClientCourseCenterId);
  };

  const handleClose = (submit) => {
    setOpen(false);
    if (submit == true) {
      markAsCourseComplete(courseCenterStudentId, score);
    }
  };

  const handleEditCourse = (rowData) => {
    setRowData(rowData);
    toggleDrawer("right", true)("noUse");
  };

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [side]: open });
  };

  useEffect(() => {
    let data = {
      Course: [
        {
          ClientId: props.ID,
        },
      ],
    };

    dispatch(assignedCourseListGet(data));
  }, []);

  const {
    assignedCourseList,
    isCourseAssign,
    isCourseUnassign,
    isCourseCompleted,
  } = useSelector(
    ({
      assigned_course_list_get: { data: courseData },
      course_assign: { data: assignCourse },
      course_unassign: { data: unAssignCourse },
      course_mark_as_complete: { data: completeCourse },
    }) => {
      return {
        assignedCourseList: courseData?.Data?.Courses,
        isCourseAssign: assignCourse?.ResponseCode === 1,
        isCourseUnassign: unAssignCourse?.ResponseCode === 1,
        isCourseCompleted: completeCourse?.ResponseCode === 1,
      };
    },
    shallowEqual
  );

  const onCourseMarkInComplete = (data) => {
    const { ClientCourseCenterId } = data;
    var data = {
      Course: [
        {
          ClientCourseCenterId,
        },
      ],
    };
    dispatch(courseUnassign(data));
  };
  const markAsCourseComplete = (ClientCourseCenterId, Score) => {
    let data = {
      Course: [
        {
          ClientCourseCenterId,
          Score,
        },
      ],
    };
    dispatch(courseMarkAsComplete(data));
  };

  useEffect(() => {
    if (isCourseAssign) {
      toggleDrawer("right", false)("no needs");
      getUpdatedAssignedCourse();
    }
  }, [isCourseAssign]);
  useEffect(() => {
    if (isCourseCompleted) {
      getUpdatedAssignedCourse();
    }
  }, [isCourseCompleted]);
  useEffect(() => {
    if (isCourseUnassign) {
      getUpdatedAssignedCourse();
    }
  }, [isCourseUnassign]);

  const getUpdatedAssignedCourse = () => {
    let data = {
      Course: [
        {
          ClientId: props.ID,
        },
      ],
    };

    dispatch(assignedCourseListGet(data));
    window.scroll(0, 0);
  };
  return (
    <div className="card card-custom w-100">
      <div className="card-body pt-5">
        <div className="clientChart-course-tab">
          {" "}
          <div className="d-flex justify-content-end">
            <Button
              onClick={toggleDrawer("right", true)}
              className="secondary mx-2"
              variant="contained"
              color="secondary"
            >
              + Assign Course
            </Button>
          </div>
          <div className="survey-list-section mt-5 mb-5">
            {assignedCourseList &&
              assignedCourseList.map((item, index) => {
                return (
                  <React.Fragment key={uuid()}>
                    <CourseCard
                      data={item}
                      handleEdit={handleEditCourse}
                      handleDelete={onCourseMarkInComplete}
                      handleComplete={handleClickOpen}
                    />
                  </React.Fragment>
                );
              })}
          </div>
          <Drawer
            anchor="right"
            open={drawerState.right}
            onClose={toggleDrawer("right", false)}
          >
            <div role="presentation" style={{ width: "800px" }}>
              <CourseForm
                side="right"
                rowData={rowData}
                ID={props.ID}
                toggleDrawer={toggleDrawer}
              />
            </div>
          </Drawer>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Course Score</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to mark this course as complete?
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Please enter a score to mark this course complete."
                type="email"
                fullWidth
                variant="standard"
                onChange={(e) => {
                  setScore(parseInt(e.target.value));
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  handleClose(true);
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
export default Course;
