import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getPatientList(action) {
  try {
    const { payload } = action;
    const response = yield API.getPatientList(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_LIST_FAILURE,
    });
  }
}
export function* getPatientDetail(action) {
  try {
    const { payload } = action;
    const response = yield API.getPatientDetail(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_DETAIL_SUCCESS,
      payload: JSON.parse(response.data.data)[0].Client[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_DETAIL_FAILURE,
    });
  }
}

export function* upsertPatientEnrollment(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertPatientEnrollment(payload);
    yield put({
      type: ActionTypes.UPSERT_PATIENT_ENROLLMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_PATIENT_ENROLLMENT_FAILURE,
    });
  }
}
export function* upsertClientInsurance(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertClientInsurance(payload);
    yield put({
      type: ActionTypes.UPSERT_CLIENT_INSURANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CLIENT_INSURANCE_FAILURE,
    });
  }
}
export function* upsertClientMedical(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertClientMedical(payload);
    yield put({
      type: ActionTypes.UPSERT_CLIENT_MEDICAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CLIENT_MEDICAL_FAILURE,
    });
  }
}
export function* upsertClientPayment(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertClientPayment(payload);
    yield put({
      type: ActionTypes.UPSERT_CLIENT_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CLIENT_PAYMENT_FAILURE,
    });
  }
}
export function* upsertProviderEnrollment(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertProviderEnrollment(payload);
    yield put({
      type: ActionTypes.UPSERT_PROVIDER_ENROLLMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_PROVIDER_ENROLLMENT_FAILURE,
    });
  }
}

export function* upsertAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertAppointment(payload);
    yield put({
      type: ActionTypes.UPSERT_APPOINTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_APPOINTMENT_FAILURE,
    });
  }
}

export function* listAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.listAppointment(payload);
    yield put({
      type: ActionTypes.LIST_APPOINTMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_APPOINTMENT_FAILURE,
    });
  }
}
export function* getAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppointment(payload);
    yield put({
      type: ActionTypes.GET_APPOINTMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APPOINTMENT_FAILURE,
    });
  }
}

export function* deleteAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteAppointment(payload);
    yield put({
      type: ActionTypes.DELETE_APPOINTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_APPOINTMENT_FAILURE,
    });
  }
}

export function* practitionerCombos(action) {
  try {
    const { payload } = action;
    const response = yield API.practitionerCombos(payload);
    yield put({
      type: ActionTypes.COMBOS_PRACTITIONER_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBOS_PRACTITIONER_FAILURE,
    });
  }
}
export function* practitionerCombosOffice(action) {
  try {
    const { payload } = action;
    const response = yield API.practitionerCombosOffice(payload);
    yield put({
      type: ActionTypes.COMBOS_PRACTITIONER_OFFICE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBOS_PRACTITIONER_OFFICE_FAILURE,
    });
  }
}

export function* upsertGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertGroup(payload);
    yield put({
      type: ActionTypes.UPSERT_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_GROUP_FAILURE,
    });
  }
}

export function* deleteGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteGroup(payload);
    yield put({
      type: ActionTypes.DELETE_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_GROUP_FAILURE,
    });
  }
}

export function* listGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.listGroup(payload);
    yield put({
      type: ActionTypes.LIST_GROUP_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_GROUP_FAILURE,
    });
  }
}

export function* getGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.getGroup(payload);
    yield put({
      type: ActionTypes.GET_GROUP_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_GROUP_FAILURE,
    });
  }
}

export function* upsertClientGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertClientGroup(payload);
    yield put({
      type: ActionTypes.UPSERT_CLIENT_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CLIENT_GROUP_FAILURE,
    });
  }
}
export function* getClientGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.getClientGroup(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_GROUP_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_GROUP_FAILURE,
    });
  }
}

export function* upsertGroupAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertGroupAppointment(payload);
    yield put({
      type: ActionTypes.UPSERT_GROUP_APPOINTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_GROUP_APPOINTMENT_FAILURE,
    });
  }
}

export function* upsertGroupAppointmentGroupAllInfoJson(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertGroupAppointmentGroupAllInfoJson(payload);
    yield put({
      type: ActionTypes.UPSERT_GROUP_APPOINTMENT_GROUP_ALL_INFO_JSON_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_GROUP_APPOINTMENT_GROUP_ALL_INFO_JSON_FAILURE,
    });
  }
}

export function* getListClientGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.getListClientGroup(payload);
    yield put({
      type: ActionTypes.GET_LIST_CLIENT_GROUP_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LIST_CLIENT_GROUP_FAILURE,
    });
  }
}

export function* patientComboGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.patientComboGroup(payload);
    yield put({
      type: ActionTypes.PATIENT_COMBO_GROUP_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PATIENT_COMBO_GROUP_FAILURE,
    });
  }
}
export function* deactivateGroupAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.deactivateGroupAppointment(payload);
    yield put({
      type: ActionTypes.DEACTIVATE_GROUP_APPOINTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DEACTIVATE_GROUP_APPOINTMENT_FAILURE,
    });
  }
}
export function* comboCenterConsent(action) {
  try {
    const { payload } = action;
    const response = yield API.comboCenterConsent(payload);
    yield put({
      type: ActionTypes.COMBO_CENTER_CONSENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_CENTER_CONSENT_FAILURE,
    });
  }
}
export function* upsertClientConsent(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertClientConsent(payload);
    yield put({
      type: ActionTypes.UPSERT_CLIENT_CONSENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CLIENT_CONSENT_FAILURE,
    });
  }
}
export function* listClientConsent(action) {
  try {
    const { payload } = action;
    const response = yield API.listClientConsent(payload);
    yield put({
      type: ActionTypes.LIST_CLIENT_CONSENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_CLIENT_CONSENT_FAILURE,
    });
  }
}
export function* deactivateClientConsent(action) {
  try {
    const { payload } = action;
    const response = yield API.deactivateClientConsent(payload);
    yield put({
      type: ActionTypes.DEACTIVATE_CLIENT_CONSENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DEACTIVATE_CLIENT_CONSENT_FAILURE,
    });
  }
}
export function* deactivateClientScreening(action) {
  try {
    const { payload } = action;
    const response = yield API.deactivateClientScreening(payload);
    yield put({
      type: ActionTypes.DEACTIVATE_CLIENT_SCREENING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DEACTIVATE_CLIENT_SCREENING_FAILURE,
    });
  }
}
export function* UploadDocumentUsingMultiPart(action) {
  try {
    const { payload } = action;
    const response = yield API.UploadDocumentUsingMultiPart(payload);
    yield put({
      type: ActionTypes.UPLOAD_DOCUMENT_USING_MULTIPART_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPLOAD_DOCUMENT_USING_MULTIPART_FAILURE,
    });
  }
}
export function* upsertClientDocument(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertClientDocument(payload);
    yield put({
      type: ActionTypes.UPSERT_CLIENT_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CLIENT_DOCUMENT_FAILURE,
    });
  }
}
export function* listClientDocument(action) {
  try {
    const { payload } = action;
    const response = yield API.listClientDocument(payload);
    yield put({
      type: ActionTypes.LIST_CLIENT_DOCUMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_CLIENT_DOCUMENT_FAILURE,
    });
  }
}
export function* downloadClientDocument(action) {
  try {
    const { payload } = action;
    const response = yield API.downloadClientDocument(payload);
    yield put({
      type: ActionTypes.DOWNLOAD_CLIENT_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DOWNLOAD_CLIENT_DOCUMENT_FAILURE,
    });
  }
}
export function* archiveClientDocument(action) {
  try {
    const { payload } = action;
    const response = yield API.archiveClientDocument(payload);
    yield put({
      type: ActionTypes.ARCHIVE_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ARCHIVE_DOCUMENT_FAILURE,
    });
  }
}

export function* listGroupMembership(action) {
  try {
    const { payload } = action;
    const response = yield API.listGroupMembership(payload);
    yield put({
      type: ActionTypes.LIST_GROUP_MEMBERSHIP_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_GROUP_MEMBERSHIP_FAILURE,
    });
  }
}

export function* getConsentFormTemplate(action) {
  try {
    const { payload } = action;
    const response = yield API.getConsentFormTemplate(payload);
    yield put({
      type: ActionTypes.GET_CONSENT_FORM_TEMPLATE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CONSENT_FORM_TEMPLATE_FAILURE,
    });
  }
}
export function* getComboFacility(action) {
  try {
    const { payload } = action;
    const response = yield API.getComboFacility(payload);
    yield put({
      type: ActionTypes.GET_COMBO_FACEILITY_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_COMBO_FACEILITY_FAILURE,
    });
  }
}

export function* centerProgramTypeGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerProgramTypeGet(payload);
    yield put({
      type: ActionTypes.CENTER_PROGRAM_TYPE_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_PROGRAM_TYPE_GET_FAILURE,
    });
  }
}

export function* downloadClientConsent(action) {
  try {
    const { payload } = action;
    const response = yield API.downloadClientConsent(payload);
    yield put({
      type: ActionTypes.DOWNLOAD_CLIENT_CONSENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DOWNLOAD_CLIENT_CONSENT_FAILURE,
    });
  }
}
export function* comboPractitionerAll(action) {
  try {
    const { payload } = action;
    const response = yield API.comboPractitionerAll(payload);
    yield put({
      type: ActionTypes.COMBO_PRACTITIONER_ALL_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_PRACTITIONER_ALL_FAILURE,
    });
  }
}
export function* comboFacilityAll(action) {
  try {
    const { payload } = action;
    const response = yield API.comboFacilityAll(payload);
    yield put({
      type: ActionTypes.COMBO_FACILITY_ALL_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_FACILITY_ALL_FAILURE,
    });
  }
}
export function* saveClientConsent(action) {
  try {
    const { payload } = action;
    const response = yield API.saveClientConsent(payload);
    yield put({
      type: ActionTypes.SAVE_CLIENT_CONSENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SAVE_CLIENT_CONSENT_FAILURE,
    });
  }
}

export function* getTreatmentPlan(action) {
  try {
    const { payload } = action;
    const response = yield API.getTreatmentPlan(payload);
    yield put({
      type: ActionTypes.GET_TREATMENT_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_TREATMENT_PLAN_FAILURE,
    });
  }
}

export function* upsertTreatmentPlanProblem(action) {
  try {
    const { payload, treatmentPlanDimensionId } = action;
    const response = yield API.upsertTreatmentPlanProblem(payload);
    yield put({
      type: ActionTypes.UPSERT_TREATMENT_PLAN_PROBLEM_SUCCESS,
      payload: response.data,
      treatmentPlanDimensionId,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_TREATMENT_PLAN_PROBLEM_FAILURE,
    });
  }
}
export function* upsertMedicalAnalysis(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertMedicalAnalysis(payload);
    yield put({
      type: ActionTypes.UPSERT_MEDICAL_ANALYSIS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_MEDICAL_ANALYSIS_FAILURE,
    });
  }
}

export function* upsertTreatmentPlanGoal(action) {
  try {
    const { payload, treatmentPlanDimensionId } = action;
    const response = yield API.upsertTreatmentPlanGoal(payload);
    yield put({
      type: ActionTypes.UPSERT_TREATMENT_PLAN_GOAL_SUCCESS,
      payload: response.data,
      treatmentPlanDimensionId,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_TREATMENT_PLAN_GOAL_FAILURE,
    });
  }
}

export function* upsertTreatmentPlanObjective(action) {
  try {
    const { payload, treatmentPlanDimensionId } = action;
    const response = yield API.upsertTreatmentPlanObjective(payload);
    yield put({
      type: ActionTypes.UPSERT_TREATMENT_PLAN_OBJECTIVE_SUCCESS,
      payload: response.data,
      treatmentPlanDimensionId,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_TREATMENT_PLAN_OBJECTIVE_FAILURE,
    });
  }
}

export function* upsertTreatmentPlanIntervention(action) {
  try {
    const { payload, treatmentPlanDimensionId } = action;
    const response = yield API.upsertTreatmentPlanIntervention(payload);
    yield put({
      type: ActionTypes.UPSERT_TREATMENT_PLAN_INTERVENTION_SUCCESS,
      payload: response.data,
      treatmentPlanDimensionId,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_TREATMENT_PLAN_INTERVENTION_FAILURE,
    });
  }
}

export function* deleteTreatmentPlan(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteTreatmentPlan(payload);
    yield put({
      type: ActionTypes.DELETE_TREATMENT_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_TREATMENT_PLAN_FAILURE,
    });
  }
}
export function* getDiagnosisDescription(action) {
  try {
    const { payload } = action;
    const response = yield API.getDiagnosisDescription(payload);
    yield put({
      type: ActionTypes.GET_DIAGNOSIS_DESCRIPTION_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DIAGNOSIS_DESCRIPTION_FAILURE,
    });
  }
}

export function* listTreatmentPlan(action) {
  try {
    const { payload } = action;
    const response = yield API.listTreatmentPlan(payload);
    yield put({
      type: ActionTypes.LIST_TREATMENT_PLAN_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_TREATMENT_PLAN_FAILURE,
    });
  }
}

export function* comboProblemTemplate(action) {
  try {
    const { payload } = action;
    const response = yield API.comboProblemTemplate(payload);
    yield put({
      type: ActionTypes.COMBO_PROBLEM_TEMPLATE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_PROBLEM_TEMPLATE_FAILURE,
    });
  }
}

export function* comboGoalTemplate(action) {
  try {
    const { payload } = action;
    const response = yield API.comboGoalTemplate(payload);
    yield put({
      type: ActionTypes.COMBO_GOAL_TEMPLATE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_GOAL_TEMPLATE_FAILURE,
    });
  }
}
export function* recurringCount(action) {
  try {
    const { payload } = action;
    const response = yield API.recurringCount(payload);
    yield put({
      type: ActionTypes.RECURRING_COUNT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RECURRING_COUNT_FAILURE,
    });
  }
}
export function* listConsentForm(action) {
  try {
    const { payload } = action;
    const response = yield API.listConsentForm(payload);
    yield put({
      type: ActionTypes.LIST_CONSENT_FORM_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_CONSENT_FORM_FAILURE,
    });
  }
}

export function* upsertTreatmentPlanDimension(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertTreatmentPlanDimension(payload);
    yield put({
      type: ActionTypes.UPSERT_TREATMENT_PLAN_DIMENSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_TREATMENT_PLAN_DIMENSION_FAILURE,
    });
  }
}
export function* saveConsentSign(action) {
  try {
    const { payload } = action;
    const response = yield API.saveConsentSign(payload);
    yield put({
      type: ActionTypes.SAVE_CONSENT_SIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SAVE_CONSENT_SIGN_FAILURE,
    });
  }
}

export function* consentFormDownload(action) {
  try {
    const { payload } = action;
    const response = yield API.consentFormDownload(payload);
    yield put({
      type: ActionTypes.CONSENT_FORM_DOWNLOAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CONSENT_FORM_DOWNLOAD_FAILURE,
    });
  }
}

export function* listMeeting(action) {
  try {
    const { payload } = action;
    const response = yield API.listMeeting(payload);
    yield put({
      type: ActionTypes.LIST_MEETING_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_MEETING_FAILURE,
    });
  }
}
export function* upsertProgressGroupNote(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertProgressGroupNote(payload);
    yield put({
      type: ActionTypes.UPSERT_PROGRESS_GROUP_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_PROGRESS_GROUP_NOTE_FAILURE,
    });
  }
}

export function* getProgressNoteGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.getProgressNoteGroup(payload);
    yield put({
      type: ActionTypes.GET_PROGRESS_NOTE_GROUP_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PROGRESS_NOTE_GROUP_FAILURE,
    });
  }
}

export function* comboInterventionTemplate(action) {
  try {
    const { payload } = action;
    const response = yield API.comboInterventionTemplate(payload);
    yield put({
      type: ActionTypes.COMBO_INTERVENTION_TEMPLATE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_INTERVENTION_TEMPLATE_FAILURE,
    });
  }
}

export function* getProgressNoteForIntervention(action) {
  try {
    const { payload } = action;
    const response = yield API.getProgressNoteForIntervention(payload);
    yield put({
      type: ActionTypes.GET_PROGRESS_NOTE_FOR_INTERVENTION_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PROGRESS_NOTE_FOR_INTERVENTION_FAILURE,
    });
  }
}
export function* upsertPayer(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertPayer(payload);
    yield put({
      type: ActionTypes.UPSERT_PAYER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_PAYER_FAILURE,
    });
  }
}
export function* listPayer(action) {
  try {
    const { payload } = action;
    const response = yield API.listPayer(payload);
    yield put({
      type: ActionTypes.LIST_PAYER_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_PAYER_FAILURE,
    });
  }
}
export function* deletePayer(action) {
  try {
    const { payload } = action;
    const response = yield API.deletePayer(payload);
    yield put({
      type: ActionTypes.DELETE_PAYER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_PAYER_FAILURE,
    });
  }
}
export function* getPayer(action) {
  try {
    const { payload } = action;
    const response = yield API.getPayer(payload);
    yield put({
      type: ActionTypes.GET_PAYER_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PAYER_FAILURE,
    });
  }
}
export function* comboPayer(action) {
  try {
    const { payload } = action;
    const response = yield API.comboPayer(payload);
    yield put({
      type: ActionTypes.COMBO_PAYER_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_PAYER_FAILURE,
    });
  }
}
export function* upsertServiceCode(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertServiceCode(payload);
    yield put({
      type: ActionTypes.UPSERT_SERVICE_CODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_SERVICE_CODE_FAILURE,
    });
  }
}
export function* listServiceCode(action) {
  try {
    const { payload } = action;
    const response = yield API.listServiceCode(payload);
    yield put({
      type: ActionTypes.LIST_SERVICE_CODE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_SERVICE_CODE_FAILURE,
    });
  }
}
export function* deleteServiceCode(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteServiceCode(payload);
    yield put({
      type: ActionTypes.DELETE_SERVICE_CODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_SERVICE_CODE_FAILURE,
    });
  }
}
export function* getServiceCode(action) {
  try {
    const { payload } = action;
    const response = yield API.getServiceCode(payload);
    yield put({
      type: ActionTypes.GET_SERVICE_CODE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SERVICE_CODE_FAILURE,
    });
  }
}

export function* comboCode(action) {
  try {
    const { payload } = action;
    const response = yield API.comboCode(payload);
    yield put({
      type: ActionTypes.COMBO_CODE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_CODE_FAILURE,
    });
  }
}
export function* getCodeDescription(action) {
  try {
    const { payload } = action;
    const response = yield API.getCodeDescription(payload);
    yield put({
      type: ActionTypes.GET_CODE_DESCRIPTION_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CODE_DESCRIPTION_FAILURE,
    });
  }
}

export function* appointmentNoteList(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentNoteList(payload);
    yield put({
      type: ActionTypes.APPOINTMENT_NOTE_LIST_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_NOTE_LIST_FAILURE,
    });
  }
}

export function* upsertAppointmentNote(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertAppointmentNote(payload);
    yield put({
      type: ActionTypes.UPSERT_APPOINTMENT_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_APPOINTMENT_NOTE_FAILURE,
    });
  }
}

export function* getAppointmentNote(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppointmentNote(payload);
    yield put({
      type: ActionTypes.GET_APPOINTMENT_NOTE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APPOINTMENT_NOTE_FAILURE,
    });
  }
}
export function* listServiceLine(action) {
  try {
    const { payload } = action;
    const response = yield API.listServiceLine(payload);
    yield put({
      type: ActionTypes.LIST_SERVICE_LINE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_SERVICE_LINE_FAILURE,
    });
  }
}
export function* getServiceLine(action) {
  try {
    const { payload } = action;
    const response = yield API.getServiceLine(payload);
    yield put({
      type: ActionTypes.GET_SERVICE_LINE_SUCCESS,
      payload: JSON.parse(response.data.data)[0].ServiceLine[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SERVICE_LINE_FAILURE,
    });
  }
}
export function* upsertServiceLine(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertServiceLine(payload);
    yield put({
      type: ActionTypes.UPSERT_SERVICE_LINE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_SERVICE_LINE_FAILURE,
    });
  }
}

export function* upsertAppointmentNoteAddendum(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertAppointmentNoteAddendum(payload);
    yield put({
      type: ActionTypes.UPSERT_APPOINTMENT_NOTE_ADDENDUM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_APPOINTMENT_NOTE_ADDENDUM_FAILURE,
    });
  }
}

export function* listGroupAppointmentNote(action) {
  try {
    const { payload } = action;
    const response = yield API.listGroupAppointmentNote(payload);
    yield put({
      type: ActionTypes.LIST_GROUP_APPOINTMENT_NOTE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_GROUP_APPOINTMENT_NOTE_FAILURE,
    });
  }
}

export function* getGroupAppointmentNote(action) {
  try {
    const { payload } = action;
    const response = yield API.getGroupAppointmentNote(payload);
    yield put({
      type: ActionTypes.GET_GROUP_APPOINTMENT_NOTE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_GROUP_APPOINTMENT_NOTE_FAILURE,
    });
  }
}

export function* comboTreatmentPlanIntervention(action) {
  try {
    const { payload } = action;
    const response = yield API.comboTreatmentPlanIntervention(payload);
    yield put({
      type: ActionTypes.COMBO_TREATMENT_PLAN_INTERVENTION_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_TREATMENT_PLAN_INTERVENTION_FAILURE,
    });
  }
}
export function* listFeeSchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.listFeeSchedule(payload);
    yield put({
      type: ActionTypes.LIST_FEE_SCHEDULE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_FEE_SCHEDULE_FAILURE,
    });
  }
}

export function* getFeeSchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.getFeeSchedule(payload);
    yield put({
      type: ActionTypes.GET_FEE_SCHEDULE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_FEE_SCHEDULE_FAILURE,
    });
  }
}

export function* upsertFeeSchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertFeeSchedule(payload);
    yield put({
      type: ActionTypes.UPSERT_FEE_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_FEE_SCHEDULE_FAILURE,
    });
  }
}

export function* getTreatmentPlanGraph(action) {
  try {
    const { payload } = action;
    const response = yield API.getTreatmentPlanGraph(payload);
    yield put({
      type: ActionTypes.GET_TREATMENT_PLAN_GRAPH_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_TREATMENT_PLAN_GRAPH_FAILURE,
    });
  }
}
export function* listGroupServiceLine(action) {
  try {
    const { payload } = action;
    const response = yield API.listGroupServiceLine(payload);
    yield put({
      type: ActionTypes.LIST_GROUP_SERVICE_LINE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_GROUP_SERVICE_LINE_FAILURE,
    });
  }
}
export function* getGroupServiceLine(action) {
  try {
    const { payload } = action;
    const response = yield API.getGroupServiceLine(payload);
    yield put({
      type: ActionTypes.GET_GROUP_SERVICE_LINE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_GROUP_SERVICE_LINE_FAILURE,
    });
  }
}
export function* getGroupBanner(action) {
  try {
    const { payload } = action;
    const response = yield API.getGroupBanner(payload);
    yield put({
      type: ActionTypes.GET_GROUP_BANNER_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_GROUP_BANNER_FAILURE,
    });
  }
}

export function* upsertGroupServiceLine(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertGroupServiceLine(payload);
    yield put({
      type: ActionTypes.UPSERT_GROUP_SERVICE_LINE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_GROUP_SERVICE_LINE_FAILURE,
    });
  }
}

export function* upsertGroupAppointmentNote(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertGroupAppointmentNote(payload);
    yield put({
      type: ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_FAILURE,
    });
  }
}

export function* upsertGroupAppointmentNoteAddendum(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertGroupAppointmentNoteAddendum(payload);
    yield put({
      type: ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_ADDENDUM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_ADDENDUM_FAILURE,
    });
  }
}

export function* upsertAppointmentNoteIntervention(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertAppointmentNoteIntervention(payload);
    yield put({
      type: ActionTypes.UPSERT_APPOINTMENT_NOTE_INTERVENTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_APPOINTMENT_NOTE_INTERVENTION_FAILURE,
    });
  }
}

export function* markAsComplete(action) {
  try {
    const { payload } = action;
    const response = yield API.markAsComplete(payload);
    yield put({
      type: ActionTypes.MARK_AS_COMPLETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MARK_AS_COMPLETE_FAILURE,
    });
  }
}

export function* getServiceLineCost(action) {
  try {
    const { payload } = action;
    const response = yield API.getServiceLineCost(payload);
    yield put({
      type: ActionTypes.GET_SERVICE_LINE_COST_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SERVICE_LINE_COST_FAILURE,
    });
  }
}

export function* upsertTreatmentPlan(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertTreatmentPlan(payload);
    yield put({
      type: ActionTypes.UPSERT_TREATMENT_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_TREATMENT_PLAN_FAILURE,
    });
  }
}
export function* listClientDiagnosis(action) {
  try {
    const { payload } = action;
    const response = yield API.listClientDiagnosis(payload);
    yield put({
      type: ActionTypes.LIST_CLIENT_DIAGNOSIS_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_CLIENT_DIAGNOSIS_FAILURE,
    });
  }
}
export function* getClientDiagnosis(action) {
  try {
    const { payload } = action;
    const response = yield API.getClientDiagnosis(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_DIAGNOSIS_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_DIAGNOSIS_FAILURE,
    });
  }
}
export function* deleteClientDiagnosis(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteClientDiagnosis(payload);
    yield put({
      type: ActionTypes.DELETE_CLIENT_DIAGNOSIS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_CLIENT_DIAGNOSIS_FAILURE,
    });
  }
}
export function* cloneDiagnosis(action) {
  try {
    const { payload } = action;
    const response = yield API.cloneDiagnosis(payload);
    yield put({
      type: ActionTypes.CLONE_DIAGNOSIS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLONE_DIAGNOSIS_FAILURE,
    });
  }
}
export function* cloneTreatmentPlan(action) {
  try {
    const { payload } = action;
    const response = yield API.cloneTreatmentPlan(payload);
    yield put({
      type: ActionTypes.CLONE_TREATMENT_PLAN_DIAGNOSIS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLONE_TREATMENT_PLAN_DIAGNOSIS_FAILURE,
    });
  }
}

export function* rcmClientMerge(action) {
  try {
    const { payload } = action;
    const response = yield API.rcmClientMerge(payload);
    yield put({
      type: ActionTypes.RCM_CLIENT_MERGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RCM_CLIENT_MERGE_FAILURE,
    });
  }
}
export function* preValidatePin(action) {
  try {
    const { payload } = action;
    const response = yield API.preValidatePin(payload);
    yield put({
      type: ActionTypes.PRE_VALIDATE_PIN_SUCCESS,
      payload: JSON.parse(response.data.data)[0].Staff,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PRE_VALIDATE_PIN_FAILURE,
    });
  }
}
export function* validateClient(action) {
  try {
    const { payload } = action;
    const response = yield API.validateClient(payload);
    yield put({
      type: ActionTypes.VALIDATE_CLIENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.VALIDATE_CLIENT_FAILURE,
    });
  }
}
export function* getTreatmentPlanAccordin(action) {
  try {
    const { payload } = action;
    const response = yield API.getTreatmentPlanAccordin(payload);
    yield put({
      type: ActionTypes.GET_TREATMENT_PLAN_ACCORDIN_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_TREATMENT_PLAN_ACCORDIN_FAILURE,
    });
  }
}
export function* listServiceCodeBundle(action) {
  try {
    const { payload } = action;
    const response = yield API.listServiceCodeBundle(payload);
    yield put({
      type: ActionTypes.LIST_SERVICE_CODE_BUNDLE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_SERVICE_CODE_BUNDLE_FAILURE,
    });
  }
}
export function* getServiceCodeBundle(action) {
  try {
    const { payload } = action;
    const response = yield API.getServiceCodeBundle(payload);
    yield put({
      type: ActionTypes.GET_SERVICE_CODE_BUNDLE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SERVICE_CODE_BUNDLE_FAILURE,
    });
  }
}
export function* getClientService(action) {
  try {
    const { payload } = action;
    const response = yield API.getClientService(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_SERVICE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_SERVICE_FAILURE,
    });
  }
}
export function* getDashboardDailySchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.getDashboardDailySchedule(payload);
    yield put({
      type: ActionTypes.GET_DASHBOARD_DAILY_SCHEDULES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DASHBOARD_DAILY_SCHEDULES_FAILURE,
    });
  }
}
export function* getDashboardDailyCaseLoad(action) {
  try {
    const { payload } = action;
    const response = yield API.getDashboardDailyCaseLoad(payload);
    yield put({
      type: ActionTypes.GET_DASHBOARD_DAILY_CASE_LOAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DASHBOARD_DAILY_CASE_LOAD_FAILURE,
    });
  }
}
export function* getDashboardAlert(action) {
  try {
    const { payload } = action;
    const response = yield API.getDashboardAlert(payload);
    yield put({
      type: ActionTypes.GET_DASHBOARD_ALERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DASHBOARD_ALERT_FAILURE,
    });
  }
}
export function* addClientInsuranceEligibility(action) {
  try {
    const { payload } = action;
    const response = yield API.addClientInsuranceEligibility(payload);
    yield put({
      type: ActionTypes.ADD_CLIENT_INSURANCE_ELIGIBILITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_CLIENT_INSURANCE_ELIGIBILITY_FAILURE,
    });
  }
}
export function* editClientInsuranceEligibility(action) {
  try {
    const { payload } = action;
    const response = yield API.editClientInsuranceEligibility(payload);
    yield put({
      type: ActionTypes.EDIT_CLIENT_INSURANCE_ELIGIBILITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.EDIT_CLIENT_INSURANCE_ELIGIBILITY_FAILURE,
    });
  }
}
export function* deleteClientInsuranceEligibility(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteClientInsuranceEligibility(payload);
    yield put({
      type: ActionTypes.DELETE_CLIENT_INSURANCE_ELIGIBILITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_CLIENT_INSURANCE_ELIGIBILITY_FAILURE,
    });
  }
}
export function* getStaffPractitioner(action) {
  try {
    const { payload } = action;
    const response = yield API.getStaffPractitioner(payload);
    yield put({
      type: ActionTypes.GET_STAFF_PRACTITIONER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STAFF_PRACTITIONER_FAILURE,
    });
  }
}
export function* dischargeSummarySave(action) {
  try {
    const { payload } = action;
    const response = yield API.dischargeSummarySave(payload);
    yield put({
      type: ActionTypes.DISCHARGE_SUMMERY_SAVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DISCHARGE_SUMMERY_SAVE_FAILURE,
    });
  }
}
export function* getClientAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.getClientAppointment(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_APPOINTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_APPOINTMENT_FAILURE,
    });
  }
}
export function* getGroupAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.getGroupAppointment(payload);
    yield put({
      type: ActionTypes.GET_GROUP_APPOINTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_GROUP_APPOINTMENT_FAILURE,
    });
  }
}
export function* unsignForm(action) {
  try {
    const { payload } = action;
    const response = yield API.unsignForm(payload);
    yield put({
      type: ActionTypes.UNSIGN_FORM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UNSIGN_FORM_FAILURE,
    });
  }
}
export function* listLabOrder(action) {
  try {
    const { payload } = action;
    const response = yield API.listLabOrder(payload);
    yield put({
      type: ActionTypes.LIST_LAB_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_LAB_ORDER_FAILURE,
    });
  }
}
export function* getLabOrder(action) {
  try {
    const { payload } = action;
    const response = yield API.getLabOrder(payload);
    yield put({
      type: ActionTypes.GET_LAB_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LAB_ORDER_FAILURE,
    });
  }
}
export function* upsertLabOrder(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertLabOrder(payload);
    yield put({
      type: ActionTypes.UPSERT_LAB_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_LAB_ORDER_FAILURE,
    });
  }
}
export function* getMedicationNote(action) {
  try {
    const { payload } = action;
    const response = yield API.getMedicationNote(payload);
    yield put({
      type: ActionTypes.GET_MEDICATION_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_MEDICATION_NOTE_FAILURE,
    });
  }
}

export function* upsertClientGroupAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertClientGroupAppointment(payload);
    yield put({
      type: ActionTypes.UPSERT_CLIENT_GROUP_APPOINTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CLIENT_GROUP_APPOINTMENT_FAILURE,
    });
  }
}

export function* signGroupAppointmentNote(action) {
  try {
    const { payload } = action;
    const response = yield API.signGroupAppointmentNote(payload);
    yield put({
      type: ActionTypes.SIGN_GROUP_APPOINTMENT_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SIGN_GROUP_APPOINTMENT_NOTE_FAILURE,
    });
  }
}

export function* listServiceLinePayment(action) {
  try {
    const { payload } = action;
    const response = yield API.listServiceLinePayment(payload);
    yield put({
      type: ActionTypes.LIST_SERVICE_LINE_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_SERVICE_LINE_PAYMENT_FAILURE,
    });
  }
}
export function* getServiceLinePayment(action) {
  try {
    const { payload } = action;
    const response = yield API.getServiceLinePayment(payload);
    yield put({
      type: ActionTypes.GET_SERVICE_LINE_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SERVICE_LINE_PAYMENT_FAILURE,
    });
  }
}
export function* upsertServiceLinePayment(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertServiceLinePayment(payload);
    yield put({
      type: ActionTypes.UPSERT_SERVICE_LINE_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_SERVICE_LINE_PAYMENT_FAILURE,
    });
  }
}
export function* deleteServiceLinePayment(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteServiceLinePayment(payload);
    yield put({
      type: ActionTypes.DELETE_SERVICE_LINE_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_SERVICE_LINE_PAYMENT_FAILURE,
    });
  }
}

export function* locationDayHistory(action) {
  try {
    const { payload } = action;
    const response = yield API.locationDayHistory(payload);
    yield put({
      type: ActionTypes.LOCATION_DAY_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOCATION_DAY_HISTORY_FAILURE,
    });
  }
}
export function* upsertPaymentClient(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertPaymentClient(payload);
    yield put({
      type: ActionTypes.UPSERT_PAYMENT_CLIENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_PAYMENT_CLIENT_FAILURE,
    });
  }
}
export function* getInsurancePriorityOrder(action) {
  try {
    const { payload } = action;
    const response = yield API.getInsurancePriorityOrder(payload);
    yield put({
      type: ActionTypes.GET_INSURANCE_PRIORITY_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_INSURANCE_PRIORITY_ORDER_FAILURE,
    });
  }
}
export function* upsertInsurancePriorityOrder(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertInsurancePriorityOrder(payload);
    yield put({
      type: ActionTypes.UPSERT_INSURANCE_PRIORITY_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_INSURANCE_PRIORITY_ORDER_FAILURE,
    });
  }
}
export function* listCWPayment(action) {
  try {
    const { payload } = action;
    const response = yield API.listCWPayment(payload);
    yield put({
      type: ActionTypes.LIST_CW_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_CW_PAYMENT_FAILURE,
    });
  }
}
export function* getCWPayment(action) {
  try {
    const { payload } = action;
    const response = yield API.getCWPayment(payload);
    yield put({
      type: ActionTypes.GET_CW_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CW_PAYMENT_FAILURE,
    });
  }
}
export function* deleteCWPayment(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteCWPayment(payload);
    yield put({
      type: ActionTypes.DELETE_CW_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_CW_PAYMENT_FAILURE,
    });
  }
}
export function* comboAppointmentTreatmentPlan(action) {
  try {
    const { payload } = action;
    const response = yield API.comboAppointmentTreatmentPlan(payload);
    yield put({
      type: ActionTypes.COMBO_APPOINTMENT_TREATMENT_PLAN_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_APPOINTMENT_TREATMENT_PLAN_FAILURE,
    });
  }
}
export function* linkAppointment(action) {
  try {
    const { payload } = action;
    const response = yield API.linkAppointment(payload);
    yield put({
      type: ActionTypes.LINK_APPOINTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LINK_APPOINTMENT_FAILURE,
    });
  }
}
export function* appointmentLinkGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentLinkGet(payload);

    yield put({
      type: ActionTypes.APPOINTMENT_LINK_GET_SUCCESS,
      payload: JSON.parse(response.data.data).AppointmentLink[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_LINK_GET_FAILURE,
    });
  }
}
export function* getBasicProfile(action) {
  try {
    const { payload } = action;
    const response = yield API.getBasicProfile(payload);
    yield put({
      type: ActionTypes.GET_BASIC_PROFILE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BASIC_PROFILE_FAILURE,
    });
  }
}
export function* getServiceLineDiagnosis(action) {
  try {
    const { payload } = action;
    const response = yield API.getServiceLineDiagnosis(payload);
    yield put({
      type: ActionTypes.GET_SERVICE_LINE_DIAGNOSIS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SERVICE_LINE_DIAGNOSIS_FAILURE,
    });
  }
}
export function* upsertServiceLineDiagnosis(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertServiceLineDiagnosis(payload);
    yield put({
      type: ActionTypes.UPSERT_SERVICE_LINE_DIAGNOSIS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_SERVICE_LINE_DIAGNOSIS_FAILURE,
    });
  }
}
export function* listClientAuthorization(action) {
  try {
    const { payload } = action;
    const response = yield API.listClientAuthorization(payload);
    yield put({
      type: ActionTypes.LIST_CLIENT_AUTHORIZATION_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_CLIENT_AUTHORIZATION_FAILURE,
    });
  }
}
export function* getClientAuthorization(action) {
  try {
    const { payload } = action;
    const response = yield API.getClientAuthorization(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_AUTHORIZATION_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_AUTHORIZATION_FAILURE,
    });
  }
}
export function* deleteClientAuthorization(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteClientAuthorization(payload);
    yield put({
      type: ActionTypes.DELETE_CLIENT_AUTHORIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_CLIENT_AUTHORIZATION_FAILURE,
    });
  }
}
export function* upsertClientAuthorization(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertClientAuthorization(payload);
    yield put({
      type: ActionTypes.UPSERT_CLIENT_AUTHORIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CLIENT_AUTHORIZATION_FAILURE,
    });
  }
}

export function* listSurveyQuestionCategory(action) {
  try {
    const { payload } = action;
    const response = yield API.listSurveyQuestionCategory(payload);
    yield put({
      type: ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_FAILURE,
    });
  }
}

export function* listSurveyQuestionCategoryAssessment(action) {
  try {
    const { payload } = action;
    const response = yield API.listSurveyQuestionCategoryAssessment(payload);
    yield put({
      type: ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_ASSESSMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction:
        ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_ASSESSMENT_FAILURE,
    });
  }
}

export function* listSurveyQuestionAssessment(action) {
  try {
    const { payload } = action;
    const response = yield API.listSurveyQuestionAssessment(payload);
    yield put({
      type: ActionTypes.LIST_SURVEY_QUESTION_ASSESSMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_SURVEY_QUESTION_ASSESSMENT_FAILURE,
    });
  }
}

export function* getSurveyScore(action) {
  try {
    const { payload } = action;
    const response = yield API.getSurveyScore(payload);
    yield put({
      type: ActionTypes.GET_SURVEY_SCORE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SURVEY_SCORE_FAILURE,
    });
  }
}

export function* listSurveyQuestionDischarge(action) {
  try {
    const { payload } = action;
    const response = yield API.listSurveyQuestionDischarge(payload);
    yield put({
      type: ActionTypes.LIST_SURVEY_QUESTION_DISCHARGE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_SURVEY_QUESTION_DISCHARGE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_CLIENT_LIST_REQUEST, getPatientList),
    takeLatest(ActionTypes.GET_CLIENT_DETAIL_REQUEST, getPatientDetail),
    takeLatest(ActionTypes.UPSERT_PATIENT_ENROLLMENT_REQUEST, upsertPatientEnrollment),
    takeLatest(ActionTypes.UPSERT_CLIENT_INSURANCE_REQUEST, upsertClientInsurance),
    takeLatest(ActionTypes.UPSERT_CLIENT_MEDICAL_REQUEST, upsertClientMedical),
    takeLatest(ActionTypes.UPSERT_CLIENT_PAYMENT_REQUEST, upsertClientPayment),
    takeLatest(ActionTypes.UPSERT_PROVIDER_ENROLLMENT_REQUEST, upsertProviderEnrollment),
    takeLatest(ActionTypes.UPSERT_APPOINTMENT_REQUEST, upsertAppointment),
    takeLatest(ActionTypes.LIST_APPOINTMENT_REQUEST, listAppointment),
    takeLatest(ActionTypes.GET_APPOINTMENT_REQUEST, getAppointment),
    takeLatest(ActionTypes.DELETE_APPOINTMENT_REQUEST, deleteAppointment),
    takeLatest(ActionTypes.COMBOS_PRACTITIONER_REQUEST, practitionerCombos),
    takeLatest(ActionTypes.COMBOS_PRACTITIONER_OFFICE_REQUEST, practitionerCombosOffice),
    takeLatest(ActionTypes.UPSERT_GROUP_REQUEST, upsertGroup),
    takeLatest(ActionTypes.DELETE_GROUP_REQUEST, deleteGroup),
    takeLatest(ActionTypes.LIST_GROUP_REQUEST, listGroup),
    takeLatest(ActionTypes.GET_GROUP_REQUEST, getGroup),
    takeLatest(ActionTypes.UPSERT_CLIENT_GROUP_REQUEST, upsertClientGroup),
    takeLatest(ActionTypes.GET_CLIENT_GROUP_REQUEST, getClientGroup),
    takeLatest(ActionTypes.UPSERT_GROUP_APPOINTMENT_REQUEST, upsertGroupAppointment),
    takeLatest(
      ActionTypes.UPSERT_GROUP_APPOINTMENT_GROUP_ALL_INFO_JSON_REQUEST,
      upsertGroupAppointmentGroupAllInfoJson,
    ),
    takeLatest(ActionTypes.GET_LIST_CLIENT_GROUP_REQUEST, getListClientGroup),
    takeLatest(ActionTypes.PATIENT_COMBO_GROUP_REQUEST, patientComboGroup),
    takeLatest(ActionTypes.DEACTIVATE_GROUP_APPOINTMENT_REQUEST, deactivateGroupAppointment),
    takeLatest(ActionTypes.COMBO_CENTER_CONSENT_REQUEST, comboCenterConsent),
    takeLatest(ActionTypes.UPSERT_CLIENT_CONSENT_REQUEST, upsertClientConsent),
    takeLatest(ActionTypes.LIST_CLIENT_CONSENT_REQUEST, listClientConsent),
    takeLatest(ActionTypes.DEACTIVATE_CLIENT_CONSENT_REQUEST, deactivateClientConsent),
    takeLatest(ActionTypes.DEACTIVATE_CLIENT_SCREENING_REQUEST, deactivateClientScreening),
    takeLatest(ActionTypes.UPLOAD_DOCUMENT_USING_MULTIPART_REQUEST, UploadDocumentUsingMultiPart),
    takeLatest(ActionTypes.UPSERT_CLIENT_DOCUMENT_REQUEST, upsertClientDocument),
    takeLatest(ActionTypes.LIST_CLIENT_DOCUMENT_REQUEST, listClientDocument),
    takeLatest(ActionTypes.DOWNLOAD_CLIENT_DOCUMENT_REQUEST, downloadClientDocument),
    takeLatest(ActionTypes.ARCHIVE_DOCUMENT_REQUEST, archiveClientDocument),
    takeLatest(ActionTypes.LIST_GROUP_MEMBERSHIP_REQUEST, listGroupMembership),
    takeLatest(ActionTypes.GET_CONSENT_FORM_TEMPLATE_REQUEST, getConsentFormTemplate),
    takeLatest(ActionTypes.GET_COMBO_FACEILITY_REQUEST, getComboFacility),
    takeLatest(ActionTypes.CENTER_PROGRAM_TYPE_GET_REQUEST, centerProgramTypeGet),
    takeLatest(ActionTypes.DOWNLOAD_CLIENT_CONSENT_REQUEST, downloadClientConsent),
    takeLatest(ActionTypes.COMBO_PRACTITIONER_ALL_REQUEST, comboPractitionerAll),
    takeLatest(ActionTypes.COMBO_FACILITY_ALL_REQUEST, comboFacilityAll),
    takeLatest(ActionTypes.SAVE_CLIENT_CONSENT_REQUEST, saveClientConsent),
    takeLatest(ActionTypes.GET_TREATMENT_PLAN_REQUEST, getTreatmentPlan),
    takeLatest(ActionTypes.UPSERT_TREATMENT_PLAN_PROBLEM_REQUEST, upsertTreatmentPlanProblem),
    takeLatest(ActionTypes.UPSERT_MEDICAL_ANALYSIS_REQUEST, upsertMedicalAnalysis),
    takeLatest(ActionTypes.UPSERT_TREATMENT_PLAN_GOAL_REQUEST, upsertTreatmentPlanGoal),
    takeLatest(ActionTypes.UPSERT_TREATMENT_PLAN_OBJECTIVE_REQUEST, upsertTreatmentPlanObjective),
    takeLatest(
      ActionTypes.UPSERT_TREATMENT_PLAN_INTERVENTION_REQUEST,
      upsertTreatmentPlanIntervention,
    ),
    takeLatest(ActionTypes.DELETE_TREATMENT_PLAN_REQUEST, deleteTreatmentPlan),
    takeLatest(ActionTypes.GET_DIAGNOSIS_DESCRIPTION_REQUEST, getDiagnosisDescription),
    takeLatest(ActionTypes.LIST_TREATMENT_PLAN_REQUEST, listTreatmentPlan),
    takeLatest(ActionTypes.COMBO_PROBLEM_TEMPLATE_REQUEST, comboProblemTemplate),
    takeLatest(ActionTypes.COMBO_GOAL_TEMPLATE_REQUEST, comboGoalTemplate),
    takeLatest(ActionTypes.RECURRING_COUNT_REQUEST, recurringCount),
    takeLatest(ActionTypes.LIST_CONSENT_FORM_REQUEST, listConsentForm),
    takeLatest(ActionTypes.UPSERT_TREATMENT_PLAN_DIMENSION_REQUEST, upsertTreatmentPlanDimension),
    takeLatest(ActionTypes.SAVE_CONSENT_SIGN_REQUEST, saveConsentSign),
    takeLatest(ActionTypes.CONSENT_FORM_DOWNLOAD_REQUEST, consentFormDownload),
    takeLatest(ActionTypes.LIST_MEETING_REQUEST, listMeeting),
    takeLatest(ActionTypes.UPSERT_PROGRESS_GROUP_NOTE_REQUEST, upsertProgressGroupNote),
    takeLatest(ActionTypes.GET_PROGRESS_NOTE_GROUP_REQUEST, getProgressNoteGroup),
    takeLatest(ActionTypes.COMBO_INTERVENTION_TEMPLATE_REQUEST, comboInterventionTemplate),
    takeLatest(
      ActionTypes.GET_PROGRESS_NOTE_FOR_INTERVENTION_REQUEST,
      getProgressNoteForIntervention,
    ),
    takeLatest(ActionTypes.UPSERT_PAYER_REQUEST, upsertPayer),
    takeLatest(ActionTypes.LIST_PAYER_REQUEST, listPayer),
    takeLatest(ActionTypes.DELETE_PAYER_REQUEST, deletePayer),
    takeLatest(ActionTypes.GET_PAYER_REQUEST, getPayer),
    takeLatest(ActionTypes.COMBO_PAYER_REQUEST, comboPayer),
    takeLatest(ActionTypes.UPSERT_SERVICE_CODE_REQUEST, upsertServiceCode),
    takeLatest(ActionTypes.LIST_SERVICE_CODE_REQUEST, listServiceCode),
    takeLatest(ActionTypes.DELETE_SERVICE_CODE_REQUEST, deleteServiceCode),
    takeLatest(ActionTypes.GET_SERVICE_CODE_REQUEST, getServiceCode),
    takeLatest(ActionTypes.COMBO_CODE_REQUEST, comboCode),
    takeLatest(ActionTypes.GET_CODE_DESCRIPTION_REQUEST, getCodeDescription),
    takeLatest(ActionTypes.APPOINTMENT_NOTE_LIST_REQUEST, appointmentNoteList),
    takeLatest(ActionTypes.UPSERT_APPOINTMENT_NOTE_REQUEST, upsertAppointmentNote),
    takeLatest(ActionTypes.GET_APPOINTMENT_NOTE_REQUEST, getAppointmentNote),
    takeLatest(ActionTypes.LIST_SERVICE_LINE_REQUEST, listServiceLine),
    takeLatest(ActionTypes.GET_SERVICE_LINE_REQUEST, getServiceLine),
    takeLatest(ActionTypes.UPSERT_SERVICE_LINE_REQUEST, upsertServiceLine),
    takeLatest(ActionTypes.UPSERT_APPOINTMENT_NOTE_ADDENDUM_REQUEST, upsertAppointmentNoteAddendum),
    takeLatest(ActionTypes.LIST_GROUP_APPOINTMENT_NOTE_REQUEST, listGroupAppointmentNote),
    takeLatest(ActionTypes.GET_GROUP_APPOINTMENT_NOTE_REQUEST, getGroupAppointmentNote),
    takeLatest(
      ActionTypes.COMBO_TREATMENT_PLAN_INTERVENTION_REQUEST,
      comboTreatmentPlanIntervention,
    ),
    takeLatest(ActionTypes.LIST_FEE_SCHEDULE_REQUEST, listFeeSchedule),
    takeLatest(ActionTypes.GET_FEE_SCHEDULE_REQUEST, getFeeSchedule),
    takeLatest(ActionTypes.UPSERT_FEE_SCHEDULE_REQUEST, upsertFeeSchedule),
    takeLatest(ActionTypes.GET_TREATMENT_PLAN_GRAPH_REQUEST, getTreatmentPlanGraph),
    takeLatest(ActionTypes.LIST_GROUP_SERVICE_LINE_REQUEST, listGroupServiceLine),
    takeLatest(ActionTypes.GET_GROUP_SERVICE_LINE_REQUEST, getGroupServiceLine),
    takeLatest(ActionTypes.GET_GROUP_BANNER_REQUEST, getGroupBanner),
    takeLatest(ActionTypes.UPSERT_GROUP_SERVICE_LINE_REQUEST, upsertGroupServiceLine),
    takeLatest(ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_REQUEST, upsertGroupAppointmentNote),
    takeLatest(
      ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_ADDENDUM_REQUEST,
      upsertGroupAppointmentNoteAddendum,
    ),
    takeLatest(
      ActionTypes.UPSERT_APPOINTMENT_NOTE_INTERVENTION_REQUEST,
      upsertAppointmentNoteIntervention,
    ),
    takeLatest(ActionTypes.MARK_AS_COMPLETE_REQUEST, markAsComplete),
    takeLatest(ActionTypes.GET_SERVICE_LINE_COST_REQUEST, getServiceLineCost),
    takeLatest(ActionTypes.UPSERT_TREATMENT_PLAN_REQUEST, upsertTreatmentPlan),
    takeLatest(ActionTypes.LIST_CLIENT_DIAGNOSIS_REQUEST, listClientDiagnosis),
    takeLatest(ActionTypes.GET_CLIENT_DIAGNOSIS_REQUEST, getClientDiagnosis),
    takeLatest(ActionTypes.DELETE_CLIENT_DIAGNOSIS_REQUEST, deleteClientDiagnosis),
    takeLatest(ActionTypes.CLONE_DIAGNOSIS_REQUEST, cloneDiagnosis),
    takeLatest(ActionTypes.CLONE_TREATMENT_PLAN_DIAGNOSIS_REQUEST, cloneTreatmentPlan),
    takeLatest(ActionTypes.RCM_CLIENT_MERGE_REQUEST, rcmClientMerge),
    takeLatest(ActionTypes.PRE_VALIDATE_PIN_REQUEST, preValidatePin),
    takeLatest(ActionTypes.VALIDATE_CLIENT_REQUEST, validateClient),
    takeLatest(ActionTypes.GET_TREATMENT_PLAN_ACCORDIN_REQUEST, getTreatmentPlanAccordin),
    takeLatest(ActionTypes.LIST_SERVICE_CODE_BUNDLE_REQUEST, listServiceCodeBundle),
    takeLatest(ActionTypes.GET_SERVICE_CODE_BUNDLE_REQUEST, getServiceCodeBundle),
    takeLatest(ActionTypes.GET_CLIENT_SERVICE_REQUEST, getClientService),
    takeLatest(ActionTypes.GET_DASHBOARD_DAILY_SCHEDULES_REQUEST, getDashboardDailySchedule),
    takeLatest(ActionTypes.GET_DASHBOARD_DAILY_CASE_LOAD_REQUEST, getDashboardDailyCaseLoad),
    takeLatest(ActionTypes.GET_DASHBOARD_ALERT_REQUEST, getDashboardAlert),
    takeLatest(ActionTypes.ADD_CLIENT_INSURANCE_ELIGIBILITY_REQUEST, addClientInsuranceEligibility),
    takeLatest(
      ActionTypes.EDIT_CLIENT_INSURANCE_ELIGIBILITY_REQUEST,
      editClientInsuranceEligibility,
    ),
    takeLatest(
      ActionTypes.DELETE_CLIENT_INSURANCE_ELIGIBILITY_REQUEST,
      deleteClientInsuranceEligibility,
    ),
    takeLatest(ActionTypes.GET_STAFF_PRACTITIONER_REQUEST, getStaffPractitioner),
    takeLatest(ActionTypes.DISCHARGE_SUMMERY_SAVE_REQUEST, dischargeSummarySave),
    takeLatest(ActionTypes.GET_CLIENT_APPOINTMENT_REQUEST, getClientAppointment),
    takeLatest(ActionTypes.GET_GROUP_APPOINTMENT_REQUEST, getGroupAppointment),
    takeLatest(ActionTypes.UNSIGN_FORM_REQUEST, unsignForm),
    takeLatest(ActionTypes.LIST_LAB_ORDER_REQUEST, listLabOrder),
    takeLatest(ActionTypes.GET_LAB_ORDER_REQUEST, getLabOrder),
    takeLatest(ActionTypes.UPSERT_LAB_ORDER_REQUEST, upsertLabOrder),
    takeLatest(ActionTypes.GET_MEDICATION_NOTE_REQUEST, getMedicationNote),
    takeLatest(ActionTypes.UPSERT_CLIENT_GROUP_APPOINTMENT_REQUEST, upsertClientGroupAppointment),
    takeLatest(ActionTypes.SIGN_GROUP_APPOINTMENT_NOTE_REQUEST, signGroupAppointmentNote),
    takeLatest(ActionTypes.LIST_SERVICE_LINE_PAYMENT_REQUEST, listServiceLinePayment),
    takeLatest(ActionTypes.GET_SERVICE_LINE_PAYMENT_REQUEST, getServiceLinePayment),
    takeLatest(ActionTypes.UPSERT_SERVICE_LINE_PAYMENT_REQUEST, upsertServiceLinePayment),
    takeLatest(ActionTypes.DELETE_SERVICE_LINE_PAYMENT_REQUEST, deleteServiceLinePayment),
    takeLatest(ActionTypes.LOCATION_DAY_HISTORY_REQUEST, locationDayHistory),
    takeLatest(ActionTypes.UPSERT_PAYMENT_CLIENT_REQUEST, upsertPaymentClient),
    takeLatest(ActionTypes.GET_INSURANCE_PRIORITY_ORDER_REQUEST, getInsurancePriorityOrder),
    takeLatest(ActionTypes.UPSERT_INSURANCE_PRIORITY_ORDER_REQUEST, upsertInsurancePriorityOrder),
    takeLatest(ActionTypes.LIST_CW_PAYMENT_REQUEST, listCWPayment),
    takeLatest(ActionTypes.GET_CW_PAYMENT_REQUEST, getCWPayment),
    takeLatest(ActionTypes.DELETE_CW_PAYMENT_REQUEST, deleteCWPayment),
    takeLatest(ActionTypes.COMBO_APPOINTMENT_TREATMENT_PLAN_REQUEST, comboAppointmentTreatmentPlan),
    takeLatest(ActionTypes.LINK_APPOINTMENT_REQUEST, linkAppointment),
    takeLatest(ActionTypes.APPOINTMENT_LINK_GET_REQUEST, appointmentLinkGet),
    takeLatest(ActionTypes.GET_BASIC_PROFILE_REQUEST, getBasicProfile),
    takeLatest(ActionTypes.GET_SERVICE_LINE_DIAGNOSIS_REQUEST, getServiceLineDiagnosis),
    takeLatest(ActionTypes.UPSERT_SERVICE_LINE_DIAGNOSIS_REQUEST, upsertServiceLineDiagnosis),
    takeLatest(ActionTypes.LIST_CLIENT_AUTHORIZATION_REQUEST, listClientAuthorization),
    takeLatest(ActionTypes.GET_CLIENT_AUTHORIZATION_REQUEST, getClientAuthorization),
    takeLatest(ActionTypes.DELETE_CLIENT_AUTHORIZATION_REQUEST, deleteClientAuthorization),
    takeLatest(ActionTypes.UPSERT_CLIENT_AUTHORIZATION_REQUEST, upsertClientAuthorization),
    takeLatest(ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_REQUEST, listSurveyQuestionCategory),
    takeLatest(
      ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_ASSESSMENT_REQUEST,
      listSurveyQuestionCategoryAssessment,
    ),
    takeLatest(ActionTypes.LIST_SURVEY_QUESTION_ASSESSMENT_REQUEST, listSurveyQuestionAssessment),
    takeLatest(ActionTypes.GET_SURVEY_SCORE_REQUEST, getSurveyScore),
    takeLatest(ActionTypes.LIST_SURVEY_QUESTION_DISCHARGE_REQUEST, listSurveyQuestionDischarge),
  ]);
}
