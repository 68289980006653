/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Switch, Route, useLocation, Link, Redirect } from "react-router-dom";
import { Logout, AuthPage } from "./modules/Auth";
import { ErrorPage } from "./components/404Error";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { Dashboard } from "./pages/dashboard";
import { MyPage2 } from "./pages/MyPage2";
import MyPage3 from "./pages/MyPage3";
import MyPage4 from "./pages/MyPage4";
import MemberTrends from "./pages/member-trends";
import BreathlyzerComponent from "./pages/breathlyzer";
import CourseComponent from "./pages/course";
import CourseForm from "./pages/courseForm";
import Centers from "./pages/centers";
import Assessments from "./pages/assessments";
import CenterForm from "./components/custom-screens/centers/centerForm";
import BreathlyzerTestForm from "./components/custom-screens/breathlyzerPage/breathlyzerTestForm";
// import BreathlyzerTestForm from "./pages/breathlyzerTestForm";
import StaffComponent from "./pages/staff";
import StaffForm from "./pages/staffForm";
import { CouponList } from "./pages/referralCodes";
import CouponForm from "./pages/referralCodes/couponForm";
import { DashboardPage } from "./pages/DashboardPage";
import { PreIntake, Intake } from "./pages/intake";
import { PrivateRoute } from "./util/check-auth/PrivateRoute";
import ClientChartPage from "./components/custom-screens/clientChartPage";
import PopulationActivitiesPage from "./components/custom-screens/populationActivitiesPage";
import ClientRosterPage from "./components/custom-screens/clientRosterPage";
import ClinicalGroupsPage from "./components/custom-screens/clinicalGroupsPage";
import IntakeForm from "./pages/intakeForm";
import PreIntakeForm from "./pages/pre-intakeForm";
import GrowthPlan from "./pages/growthPlan";
import Miscellaneous from "./pages/miscellaneous";
import AddNoteType from "./pages/miscellaneous/AddNoteType";
import AddPredinedTask from "./pages/miscellaneous/AddPredefinedTask";
import Templates from "./pages/templates";
import { SmallGroupList, ClinicalGroupList } from "./pages/community";
import {
  ServiceLineList,
  GroupServiceLineList,
  BundeledServiceList,
  ServicesByDateList,
} from "./pages/claims";
import {
  BatchingList,
  FeeScheduleList,
  PayerList,
  PaymentList,
  ServiceCodeList,
} from "./pages/RCM";
import SmallGroupForm from "./pages/smallgroupForm";
import ClinicalGroupForm from "./pages/clinicalgroupform";
import Details from "./pages/smallgroupForm/details";
import Appointments from "./pages/appointments";
export function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={AuthPage} />
      <Route path="/Login" exact component={AuthPage} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute
        exact={true}
        path="/intake/new-registration"
        component={IntakeForm}
      />
      <PrivateRoute exact={true} path="/intake/intake" component={Intake} />
      <PrivateRoute
        exact={true}
        path="/population-activities"
        component={PopulationActivitiesPage}
      />
      <PrivateRoute
        exact={true}
        path="/client-roster"
        component={ClientRosterPage}
      />{" "}
      <PrivateRoute
        exact={true}
        path="/client-roster/chart"
        component={ClientChartPage}
      />
      <PrivateRoute
        exact={true}
        path="/intake/intake-list"
        component={Intake}
      />
      <PrivateRoute
        exact={true}
        path="/community/small-group-list"
        component={SmallGroupList}
      />
      <PrivateRoute
        exact={true}
        path="/community/clinical-group-list"
        component={ClinicalGroupList}
      />
      <PrivateRoute
        exact={true}
        path="/claims/service-line-list"
        component={ServiceLineList}
      />
      <PrivateRoute
        exact={true}
        path="/claims/group-service-line-list"
        component={GroupServiceLineList}
      />
      <PrivateRoute
        exact={true}
        path="/claims/bundeled-service-list"
        component={BundeledServiceList}
      />
      <PrivateRoute
        exact={true}
        path="/claims/services-by-date-list"
        component={ServicesByDateList}
      />
      <PrivateRoute
        exact={true}
        path="/rcm/batching-list"
        component={BatchingList}
      />
      <PrivateRoute
        exact={true}
        path="/rcm/fee-schedule-list"
        component={FeeScheduleList}
      />
      <PrivateRoute exact={true} path="/rcm/payer-list" component={PayerList} />
      <PrivateRoute
        exact={true}
        path="/rcm/payment-list"
        component={PaymentList}
      />
      <PrivateRoute
        exact={true}
        path="/rcm/service-code-list"
        component={ServiceCodeList}
      />
      <PrivateRoute
        exact={true}
        path="/shift-logs"
        component={ServiceCodeList}
      />
      <PrivateRoute exact={true} path="/intake/form" component={IntakeForm} />
      <PrivateRoute
        exact={true}
        path="/intake/pre-intake"
        component={PreIntake}
      />
      <PrivateRoute
        exact={true}
        path="/community/smallgroup/new"
        component={SmallGroupForm}
      />
      <PrivateRoute
        exact={true}
        path="/community/smallgroup/edit"
        component={SmallGroupForm}
      />
      <PrivateRoute
        exact={true}
        path="/community/group/edit"
        component={ClinicalGroupForm}
      />
      <PrivateRoute
        exact={true}
        path="/community/group/new"
        component={ClinicalGroupForm}
      />
      <PrivateRoute
        exact={true}
        path="/group-details"
        component={ClinicalGroupsPage}
      />
      <PrivateRoute
        exact={true}
        path="/preintake/new-registration"
        component={PreIntakeForm}
      />
      <PrivateRoute
        exact={true}
        path="/preintake/form"
        component={PreIntakeForm}
      />
      <PrivateRoute exact={true} path="/intake" component={PreIntake} />
      {/* <PrivateRoute path="/dashboard" component={DashboardPage} /> */}
      <PrivateRoute path="/builder" component={BuilderPage} />
      <PrivateRoute path="/census" component={MyPage} />
      <PrivateRoute path="/my-page" component={MyPage} />
      <PrivateRoute path="/outcomes-and-trials" component={DashboardPage} />
      <PrivateRoute
        path="/programs-services/clinical/detox"
        component={MyPage2}
      />
      <PrivateRoute path="/dashboardNew/inHouse" component={MyPage3} />
      <PrivateRoute
        path="/programs-services/small-groups"
        component={MyPage4}
      />
      <PrivateRoute path="/member-trends" component={MemberTrends} />
      <PrivateRoute
        exact={true}
        path="/breathlyzer/list"
        component={BreathlyzerComponent}
      />
      <PrivateRoute
        exact={true}
        path="/breathlyzer/new"
        component={BreathlyzerTestForm}
      />
      <PrivateRoute
        exact={true}
        path="/breathlyzer/edit"
        component={BreathlyzerTestForm}
      />
      <PrivateRoute
        exact={true}
        path="/admin/courses/list"
        component={CourseComponent}
      />
      <PrivateRoute
        exact={true}
        path="/admin/courses/new"
        component={CourseForm}
      />
      <PrivateRoute
        exact={true}
        path="/admin/courses/edit"
        component={CourseForm}
      />
      <PrivateRoute
        exact={true}
        path="/admin/staff/list"
        component={StaffComponent}
      />
      <PrivateRoute
        exact={true}
        path="/admin/staff/new"
        component={StaffForm}
      />
      <PrivateRoute
        exact={true}
        path="/admin/staff/edit-form"
        component={StaffForm}
      />
      <PrivateRoute
        exact={true}
        path="/admin/centers/list"
        component={Centers}
      />
      <PrivateRoute
        exact={true}
        path="/admin/centers/new"
        component={CenterForm}
      />
      <PrivateRoute
        exact={true}
        path="/admin/centers/edit"
        component={CenterForm}
      />
      <PrivateRoute
        exact={true}
        path="/admin/coupon/list"
        component={CouponList}
      />
      <PrivateRoute
        exact={true}
        path="/admin/coupon/new"
        component={CouponForm}
      />
      <PrivateRoute
        exact={true}
        path="/admin/coupon/edit"
        component={CouponForm}
      />
      <PrivateRoute
        exact={true}
        path="/growth-plan/list"
        component={GrowthPlan}
      />
      <PrivateRoute exact={true} path="/growth-plan" component={GrowthPlan} />
      <PrivateRoute exact={true} path="/Template/new" component={Templates} />
      <PrivateRoute exact={true} path="/Template/edit" component={Templates} />
      <PrivateRoute exact={true} path="/assessments" component={Assessments} />
      <PrivateRoute
        exact={true}
        path="/admin/miscellaneous"
        component={Miscellaneous}
      />
      <PrivateRoute
        exact={true}
        path="/admin/miscnotetypes/new"
        component={AddNoteType}
      />
      <PrivateRoute
        exact={true}
        path="/admin/miscpredefinedtasks/new"
        component={AddPredinedTask}
      />
      <PrivateRoute
        exact={true}
        path="/appointments"
        component={Appointments}
      />
      <Route path="*" component={ErrorPage} />
    </Switch>
  );
}
