export const studentsList = [
  {
    id: 1,
    image: 'images/student-list-01.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Jan 5th, 1998/20',
    gender: 'Female',
    admissionDate: 'Jun 10th, 2018',
    dismissionDate: '',
    status: '2 Overdue',
    statusColor: 'red-text',
  },
  {
    id: 2,
    image: 'images/pro-pic-02.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: '1 Overdue',
    statusColor: 'red-text',
  },
  {
    id: 3,
    image: 'images/pro-pic-03.jpg',
    firstName: 'Tierna',
    lastName: 'Davidson',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: '1 Overdue',
    statusColor: 'red-text',
  },
  {
    id: 4,
    image: 'images/student-list-02.jpg',
    firstName: 'Andrija',
    lastName: 'Novakovich',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: 'Dec 1st,2012',
    status: '',
    statusColor: 'red-text',
  },
  {
    id: 5,
    image: 'images/student-list-01.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Jan 5th, 1998/20',
    gender: 'Female',
    admissionDate: 'Jun 10th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 6,
    image: 'images/pro-pic-02.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 7,
    image: 'images/pro-pic-03.jpg',
    firstName: 'Tierna',
    lastName: 'Davidson',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 8,
    image: 'images/student-list-02.jpg',
    firstName: 'Andrija',
    lastName: 'Novakovich',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: 'Dec 1st,2012',
    status: '',
    statusColor: '',
  },
  {
    id: 9,
    image: 'images/student-list-01.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Jan 5th, 1998/20',
    gender: 'Female',
    admissionDate: 'Jun 10th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 10,
    image: 'images/pro-pic-02.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 11,
    image: 'images/pro-pic-03.jpg',
    firstName: 'Tierna',
    lastName: 'Davidson',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 12,
    image: 'images/student-list-02.jpg',
    firstName: 'Andrija',
    lastName: 'Novakovich',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
];
