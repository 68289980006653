import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { getAllJournal, getAllJournalComments } from "../../../redux/actions";
import ChatComments from "./forms/chatComments";
import { JournalCard } from "../../components/custom-cards";
const Journal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [drawerState, setDrawerState] = useState({
    right: false,
  });

  const chatDataGet = (ClientJournalGuid) => {
    let data = {
      Journal: [
        {
          ClientId: props.ID,
          ClientJournalGuid: ClientJournalGuid,
        },
      ],
    };

    dispatch(getAllJournalComments(data));
  };

  const toggleDrawer = (side, open, ClientJournalGuid) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (typeof ClientJournalGuid === "string") {
      chatDataGet(ClientJournalGuid);
    }
    setDrawerState({ ...drawerState, [side]: open });
  };

  useEffect(() => {
    let data = {
      Journal: [
        {
          ClientId: props.ID,
        },
      ],
    };

    dispatch(getAllJournal(data));
  }, []);

  const { journalData } = useSelector(
    ({ get_all_journal: { data, isFetching, error } }) => {
      const { Data, Message } = data || {};

      return {
        journalData: Data && Data.Journals,
      };
    },
    shallowEqual
  );

  return (
    <div className="card card-custom w-100">
      <div className="card-body pt-5">
        <div className="journal-tabs">
          <div className="posts">
            {journalData &&
              journalData.map((item, index) => {
                return (
                  <>
                    <JournalCard
                      data={item}
                      index={index}
                      toggleDrawer={toggleDrawer}
                      key={uuid()}
                    />
                  </>
                );
              })}
          </div>
          {drawerState.right && (
            <Drawer
              anchor="right"
              open={drawerState.right}
              onClose={toggleDrawer("right", false)}
            >
              <div role="presentation" style={{ width: "800px" }}>
                <ChatComments toggleDrawer={toggleDrawer} ID={props.ID} />
              </div>
            </Drawer>
          )}
        </div>
      </div>
    </div>
  );
};
export default Journal;
