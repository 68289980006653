import React, { useEffect, useState, useCallback } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import { useDropzone } from "react-dropzone";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from "redux-form";
import {
  getFormFieldMetaData,
  smallGroupUpsert,
  resetSmallGroupUpsert,
  smallGroupGet,
  resetSmallGroupGet,
} from "../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";
import formDataHandler from "../../util/formDataHandler";
import _blank from "../../images/blank-profile.png";

const img = {
  height: "60px",
  width: "60px",
  borderRadius: "50%",
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const DetailForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const suhbeader = useSubheader();
  const [formFields, setFormFields] = useState([]);
  const [GLPPTypeValue, setGLPPType] = useState("E");
  const [files, setFiles] = useState([]);
  suhbeader.setTitle(
    props.SmallGroupGuid ? "Edit Small Group" : "New Small Group"
  );
  const selector = formValueSelector("SGDetailForm");
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);
  const thumbs = files.map((file) => (
    <div key={file.name}>
      <img src={file.preview} style={img} />
    </div>
  ));
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".jpg,.jpeg,.png",
    maxSize: 2097152,
    maxFiles: 1,
    multiple: false,
    onDrop: onDrop,
  });

  const { genericFormData, isFetching, currentCountryValue } = useSelector(
    (state) => {
      const {
        get_form_field_meta_data: { data },
      } = state;
      return {
        genericFormData: data?.Data?.FormFieldMetaData || [],
        currentCountryValue: selector(state, "CountryISO"),
      };
    },
    shallowEqual
  );
  const handleGLPictureChange = (event) => {
    setGLPPType(event.target.value);
  };
  useEffect(() => {
    if (genericFormData && genericFormData.length) {
      let validationConversion = genericFormData;
      validationConversion =
        validationConversion &&
        validationConversion.map((item, index) => {
          if (item.name == "StateOrProvince") {
            let currentOptions = item.DependentOptions.filter(
              (countryStateObj) => {
                return countryStateObj.Parent == currentCountryValue;
              }
            );
            let currentOptionsValue = currentOptions?.[0]?.Child;

            return {
              ...item,
              options: currentOptionsValue || [],
            };
          } else {
            return item;
          }
        });
      setFormFields(validationConversion);
    }
  }, [genericFormData, currentCountryValue]);

  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    SmallGroupGuid,
  } = props;

  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="enrollment-form small-group-form pt-2 pb-2">
                  <div className="row pb-5">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="small-group-picture-box">
                        <h6>Add Small Group Picture</h6>
                        <div className="upload-small-group-picture">
                          <div>{thumbs}</div>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>Drop the files here ...</p>
                            ) : (
                              <>
                                <div className="d-flex align-items-baseline justify-content-center">
                                  <p
                                    className="dropzone-upload-drag-icon mr-2"
                                    style={{ marginBottom: "0" }}
                                  >
                                    {/* <Icon type="upload" /> */}
                                  </p>
                                  <p
                                    className="dropzone-upload-text"
                                    style={{ marginBottom: "0" }}
                                  >
                                    Upload Small Group Picture
                                  </p>
                                </div>
                                <p>
                                  Drag and Drop Here or
                                  <span
                                    class="d-inline ml-2"
                                    style={{
                                      color: "#4ab0fe",
                                      cursor: "pointer",
                                      marginBottom: "0",
                                    }}
                                  >
                                    Browse Files
                                  </span>
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="group-leader-profile-picture-box">
                        <h6>Group Leader Profile Picture</h6>
                        <div className="upload-small-group-leader">
                          <div className="upload-new-profile-picture">
                            <div className="existing-picture">
                              <img
                                src={_blank}
                                className="rounded-circle face-fit-cover"
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>

                            <span className="d-block">
                              Use Existing Picture
                            </span>
                            <Radio
                              checked={GLPPTypeValue === "E"}
                              onChange={handleGLPictureChange}
                              value="E"
                              name="GLPType"
                              inputProps={{ "aria-label": "E" }}
                            />
                          </div>
                          <span>OR</span>
                          <div className="use-existing-picture">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {isDragActive ? (
                                <p>Drop the files here ...</p>
                              ) : (
                                <>
                                  <div className="d-flex align-items-baseline justify-content-center">
                                    <p className="dropzone-upload-drag-icon mr-2">
                                      {/* <Icon type="upload" /> */}
                                    </p>
                                    <p className="dropzone-upload-text">
                                      Upload New Profile Picture
                                    </p>
                                  </div>
                                  <p>
                                    Drag and Drop here or
                                    <span
                                      class="d-inline ml-2"
                                      style={{
                                        color: "#4ab0fe",
                                        cursor: "pointer",
                                        marginBottom: "0",
                                      }}
                                    >
                                      Browse Files
                                    </span>
                                  </p>
                                </>
                              )}
                            </div>
                            <Radio
                              checked={GLPPTypeValue === "U"}
                              onChange={handleGLPictureChange}
                              value="U"
                              name="GLPType"
                              inputProps={{ "aria-label": "U" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pb-5 pl-3 pr-3 pt-5">
                    <DynamicForm formFields={formFields} disable={false} />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-left">
                    {!SmallGroupGuid && (
                      <>
                        {activeStep != 0 && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            Back
                          </Button>
                        )}
                        &nbsp;
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={() => {
                            // history.push(
                            //   `/admin/staff/edit-form?tab=roles-and-permissions&id=${props.SmallGroupGuid ||
                            //     props.smallGroupGuid}`
                            // );
                            handleNext();
                          }}
                          disabled={!NextTab}
                        >
                          Next
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="text-right">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      type="submit"
                      disabled={pristine || submitting}
                    >
                      Submit
                    </Button>
                    &nbsp;
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      disabled={pristine || submitting}
                      onClick={reset}
                    >
                      Clear Values
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const ReduxDetailForm = reduxForm({
  form: "SGDetailForm",
  enableReinitialize: true,
})(DetailForm);

const SmallGroupDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(resetSmallGroupGet());
    dispatch(resetSmallGroupUpsert());
    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: "SmallGroup",
          },
        ],
      })
    );

    if (props.SmallGroupGuid || props.SmallGroupGuid === 0) {
      dispatch(
        smallGroupGet({
          SmallGroup: [
            {
              SmallGroupGuid: props.SmallGroupGuid,
            },
          ],
        })
      );
    }
    return () => {
      dispatch(resetSmallGroupGet());
    };
  }, []);

  const onSubmit = (values) => {
    let ObjectData = formDataHandler(values);
    const formData = {
      SmallGroup: [
        {
          ...ObjectData,
          DayOfTheWeek: values.DayOfTheWeek && values.DayOfTheWeek.toString(),
        },
      ],
    };
    dispatch(smallGroupUpsert(formData));
  };

  const {
    smallGroupData,
    isSmallGroupUpsert,
    isFetching,
    smallGroupGuid,
    smallGroupInfo,
  } = useSelector((state) => {
    const {
      small_group_upsert: { data: upsertData },
      small_group_get: { data: smallGroupData },
    } = state;
    return {
      smallGroupData: smallGroupData?.Data?.SmallGroup?.[0] || {},
      smallGroupInfo:
        smallGroupData?.Data?.SmallGroup?.[0]?.SmallGroup?.[0] || {},
      isSmallGroupUpsert: upsertData?.ResponseCode === 1,
      smallGroupGuid: upsertData?.Data?.Id,
    };
  }, shallowEqual);

  useEffect(() => {
    if (isSmallGroupUpsert) {
      history.replace(`/community/smallgroup/edit?id=${smallGroupGuid}`);
      dispatch(resetSmallGroupUpsert());
      props.handleNext();
    }
  }, [isSmallGroupUpsert]);

  return (
    <div className="">
      <ReduxDetailForm
        initialValues={{ CountryISO: "USA", ...smallGroupInfo }}
        SmallGroupGuid={props.SmallGroupGuid}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
        activeStep={props.activeStep}
        NextTab={props.NextTab}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default SmallGroupDetail;
