import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getMultistepTab, resetStaffUpsert } from "../../../redux/actions";
import GenericTab from "../../components/gereric-tabs";
import BasicDetail from "./basicDetail";
import RolesPermissions from "./rolesPermissions";
import AppDetail from "./appDetail";
import Provider from "./provider";

const Staff = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onNext = () => {
    dispatch(
      getMultistepTab({
        Tab: [
          {
            FormName: "Staff",
            FormAction: "Next",
            CurrentTab: "BasicDetails",
          },
        ],
      })
    );
  };
  const getStaffId = () => {
    let param = new URLSearchParams(history.location.search);
    let staffId = param.get("id");
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };

  const getStepContent = (
    step,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    FormName,
    ScreenName,
    ScreenTabId,
    IsDynamicTab
  ) => {
    switch (step) {
      case "Basic Details":
        return (
          <BasicDetail
            handleNext={handleNext}
            handleBack={handleBack}
            StaffId={getStaffId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      case "Roles & Permissions":
        return (
          <RolesPermissions
            handleNext={handleNext}
            handleBack={handleBack}
            StaffId={getStaffId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      case "Mobile Details":
        return (
          <AppDetail
            handleNext={handleNext}
            handleBack={handleBack}
            StaffId={getStaffId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      case "Provider":
        return (
          <Provider
            handleNext={handleNext}
            handleBack={handleBack}
            StaffId={getStaffId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      default:
        return "Loading.......";
    }
  };



  
  return (
    <>
      <div className="staff-page">
        <GenericTab getStepContent={getStepContent} FormName="Staff" />
      </div>
    </>
  );
};
export default Staff;
