import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* panasScaleMoodsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.panasScaleMoodsGet(payload);
    yield put({
      type: ActionTypes.PANAS_SCALE_MOODS_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PANAS_SCALE_MOODS_GET_FAILURE,
    });
  }
}
export function* panasTagUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.panasTagUpsert(payload);
    yield put({
      type: ActionTypes.PANAS_TAG_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PANAS_TAG_UPSERT_FAILURE,
    });
  }
}
export function* panasTagDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.panasTagDelete(payload);
    yield put({
      type: ActionTypes.PANAS_TAG_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PANAS_TAG_DELETE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.PANAS_SCALE_MOODS_GET_REQUEST, panasScaleMoodsGet)]);
  yield all([takeLatest(ActionTypes.PANAS_TAG_UPSERT_REQUEST, panasTagUpsert)]);
  yield all([takeLatest(ActionTypes.PANAS_TAG_DELETE_REQUEST, panasTagDelete)]);
}
