import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* generateCallToken(action) {
  try {
    const { payload } = action;
    const response = yield API.generateCallToken(payload);
    yield put({
      type: ActionTypes.GENERATE_CALL_TOKEN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GENERATE_CALL_TOKEN_FAILURE,
    });
  }
}
export default function* root() {
  yield all([takeLatest(ActionTypes.GENERATE_CALL_TOKEN_REQUEST, generateCallToken)]);
}
