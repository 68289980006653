import React from "react";
import "react-phone-number-input/style.css";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
const CustomPhoneInput = () => {
  return <PhoneInput {...this.props} country="US" />;
};

export default CustomPhoneInput;

const ReduxPhoneInput = ({
  input,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  errorMessage,
  className,
  disabled,
  maxLength,
  mobileFormat,
  tabIndex,
  labelText,
  fieldName,
}) => {
  var { touched, error, warning } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value;
    onChange = input.onChange;
    onBlur = input.onBlur;
  }

  /*if(mobileFormat){
        value = Util.formatPhoneNumber(value);
    }*/

  return (
    <FormControl fullWidth className="formControl" error={touched && error} style={{position:"relative"}}>
      <InputLabel htmlFor={id}>{labelText}</InputLabel>
      <div className="custom-phone-input">
        <PhoneInput
          // country="US"
          {...input}
          // countrySelectProps={{ unicodeFlags: true }}
          countries={["US", "AU", "CA", "RO", "JM"]}
          international
          countryCallingCodeEditable={false}
          flags={flags}
          defaultCountry="US"
          tabIndex={tabIndex}
          name={name}
          id={id}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className="form-control"
          disabled={!!disabled}
          maxLength={maxLength}
          autoComplete="off"
        />
        {touched &&
          ((error && fieldName && (
            <FormHelperText id="component-error-text">
              {" "}
              {fieldName} {error}
            </FormHelperText>
          )) ||
            (warning && <span className="warning-color">{warning}</span>))}
      </div>
    </FormControl>
  );
};

export { ReduxPhoneInput };
