import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import _CommentCount from "../../images/commentCount.svg";
import { DateWithTimeFormatter } from "../column-formatters";
import _GreenCircleTick from "../../images/green-circle-tick.svg";

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
        background: "white",
        "&:hover": {
            background: "white"
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    }
}));
export const CompletedAssessmentsCard = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const { data, index, toggleDrawer, key } = props;
    const { SurveyName, DueDate, Score, AssignedDate, CompletedDate } =
        data || {};
    return (
        <div className="assessments-tabs-card" key={key}>
            <div className="assessments-card">
                <div className="completed">
                    {/* <div className="score">
                          <span>Score :{Score}</span>
                        </div> */}
                    <div className="submitted mb-2">
                        <span>
                            <SVG
                                src={toAbsoluteUrl(_GreenCircleTick)}
                                width={24}
                                // height="auto"
                                title="Submitted"
                                className="mr-2"
                            />
                            Submitted
                        </span>
                    </div>
                    <span className="name">{SurveyName}</span>
                    <div className="block">
                        <div className="d-flex justify-content-between mb-2">
                            <div className="assigned-date text-left">
                                <span className="date-text">Assigned </span>
                                <span className="date-value">
                                    {AssignedDate}
                                </span>
                            </div>
                            <div className="completed-date text-left">
                                <span className="date-text">Completed </span>
                                <span className="completed-date-value">
                                    {CompletedDate}
                                </span>
                            </div>
                        </div>
                        <span className="view-replies mt-2">View Replies</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
