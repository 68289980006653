import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Route, BrowserRouter, Switch, Link } from "react-router-dom";
import { ActiveMaps } from "./activeMaps";
import { Assessment } from "./assessment";
import { Breathlyzer } from "./breathlyzer";
import { EmotionalAffect } from "./emotionalAffect";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const tabLabels = [
  {
    Name: "Emotional Affect",
    Component: () => (
      <EmotionalAffect
      // {...props}
      />
    ),
  },
  {
    Name: "Assessment",
    Component: () => (
      <Assessment
      // {...props}
      />
    ),
  },
  {
    Name: "Breathlyzer",
    Component: () => (
      <Breathlyzer
      // {...props}
      />
    ),
  },
  {
    Name: "Active Maps",
    Component: () => (
      <ActiveMaps
      // {...props}
      />
    ),
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const Tabs1 = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { ClientId } = props;
  const [value, setValue] = React.useState(0);
  const [tabMapText, setTabMapText] = React.useState({
    0: "emotional-affect",
    1: "assessment",
    2: "breathlyzer",
    3: "active-maps",
  });
  const [tabMapValue, setTabMapValue] = React.useState({
    "emotional-affect": 0,
    assessment: 1,
    breathlyzer: 2,
    "active-maps": 3,
  });

  const getTabNumber = (tab) => {
    return tabMapValue[tab];
  };
  useEffect(() => {
    var param = new URLSearchParams(history.location.search);
    var tab = param.get("tab") || "emotional-affect";
    setValue(getTabNumber(tab));
  }, []);

  // const { tabsData } = props;
  function handleChange(event, newValue) {
    {
      newValue == 0 &&
        history.push(
          `/member-trends/?id=${ClientId}&drawer=true&tab=emotional-affect`
        );
    }
    {
      newValue == 1 &&
        history.push(
          `/member-trends/?id=${ClientId}&drawer=true&tab=assessment`
        );
    }
    {
      newValue == 2 &&
        history.push(
          `/member-trends/?id=${ClientId}&drawer=true&tab=breathlyzer`
        );
    }
    {
      newValue == 3 &&
        history.push(
          `/member-trends/?id=${ClientId}&drawer=true&tab=active-maps`
        );
    }

    setValue(newValue);
    event.stopPropagation();
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
  const routes = [
    "member-trends/?drawer=true&tab=emotional-affect",
    "member-trends/?drawer=true&tab=assessment",
    "member-trends/?drawer=true&tab=breathlyzer",
    "member-trends/?drawer=true&tab=active-maps",
  ];
  return (
    <>
      {/* <BrowserRouter>
        <Route
          path="/"
          render={(history) => (
            <Tabs
              value={
                history.location.pathname !== "/"
                  ? history.location.pathname
                  : false
              }
            >
              {console.log(history.location.pathname)}
              <Tab
                value={routes[0]}
                label="Emotional Affect"
                component={Link}
                to={routes[0]}
              />
              <Tab
                value={routes[1]}
                label="Assessment"
                component={Link}
                to={routes[1]}
              />
              <Tab
                value={routes[2]}
                label="Breathlyzer"
                component={Link}
                to={routes[2]}
              />
              <Tab
                value={routes[3]}
                label="Active Maps"
                component={Link}
                to={routes[3]}
              />
            </Tabs>
          )}
        />

        <Switch>
          <Route
            path="/member-trends/?drawer=true&tab=emotional-affect"
            component={EmotionalAffect}
          />
          <Route path="/member-trends/assessment" component={Assessment} />
          <Route path="/member-trends/breathlyzer" component={Breathlyzer} />
          <Route path="/member-trends/active-maps" component={ActiveMaps} />
        </Switch>
      </BrowserRouter> */}{" "}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        className="drawer-tabs"
      >
        {tabLabels &&
          tabLabels.map((tab, index) => {
            let { Name, Component } = tab;
            return <Tab label={Name} />;
          })}
      </Tabs>
      {tabLabels &&
        tabLabels.map((tab, index) => {
          let { Name, Component } = tab;
          return (
            <>
              <TabPanel value={value} index={index}>
                <Component />
              </TabPanel>
            </>
          );
        })}
    </>
  );
};
