import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageTitle from "../../pageTitleConfig";
import NewTemplatesForm from "./newTemplatesForm";

const NewTemplate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>{"Templates"}</title>
      </Helmet>
      <div className="">
        <NewTemplatesForm />
      </div>
    </>
  );
};
export default NewTemplate;
