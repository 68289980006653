import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../_metronic/_helpers";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import _blank from "./../../images/blank-profile.png";
import _Remove from "./../../images/Shape.svg";
import GenericTable from "../../components/generic-table/generic-table";

import {
  smallGroupReferral,
  smallGroupUserListGet,
  dynamicGridColumnConfigurationGet,
} from "../../../redux/actions";
import { ClientListModal } from "./clientListModal";
import _Close from "./../../images/close.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
  button: {
    display: "block",
    margin: "0 auto",
  },
}));
const SmallGroupInvite = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const suhbeader = useSubheader();
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  let checkedArray = [];

  const clientsUpsert = (checkedArray) => {
    // props.toggleDrawer("right", false, {});
    if (checkedArray.toString().length > 0) {
      dispatch(
        smallGroupReferral({
          SmallGroup: [
            {
              SmallGroupGuid: props.SmallGroupGuid,
              Name: null,
              Phone: null,
              Email: null,
              ClientId: checkedArray,
              ReferralType: "R",
            },
          ],
        })
      );
    }
  };

  const handleChecked = (ClientId, event) => {
    // if (event.persist()) {
    event.persist();
    // }
    dispatch(
      smallGroupReferral({
        SmallGroup: [
          {
            SmallGroupGuid: props.SmallGroupGuid,
            Name: null,
            Phone: null,
            Email: null,
            ClientId: event.target.value,
            ReferralType: "R",
          },
        ],
      })
    );
    // if (event.target.checked) {
    //   if (checkedArray.includes(event.target.value)) {
    //     return;
    //   } else {
    //     checkedArray.push(event.target.value);
    //   }
    // } else if (!event.target.checked) {
    //   const index = checkedArray.indexOf(event.target.value);
    //   if (index > -1) {
    //     checkedArray.splice(index, 1);
    //   }
    // }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  suhbeader.setTitle(
    props.SmallGroupGuid ? "Edit Small Group" : "New Small Group"
  );

  const { clientsList, dynamicGridColumnData } = useSelector((state) => {
    const {
      small_group_user_list_get: { data },
      dynamic_grid_column_configuration_get: { data: dynamicData },
    } = state;
    if (data?.Data?.Clients) {
      // debugger;
    }
    return {
      clientsList: data?.Data?.SmallGroupUsers || [],
      dynamicGridColumnData: dynamicData,
      // isFetching: isGroupFetching,
    };
  }, shallowEqual);
  useEffect(() => {
    dispatch(
      smallGroupUserListGet({
        SmallGroup: [
          {
            SmallGroupGuid: props.SmallGroupGuid,
          },
        ],
      })
    );
    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "SmallGroupUser",
          },
        ],
      })
    );
  }, []);

  const {
    PageSize,
    IsRowClick,
    IsFilter,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = clientsList || [];
  let clientArray = [...clientsList];
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody>
              <div className="row">
                <div
                  className={
                    DataSource &&
                    (DataSource.length ? "col-12 d-flex" : "col-12")
                  }
                >
                  <div
                    className={
                      DataSource && (DataSource.length ? "col-6" : "col-12")
                    }
                  >
                    <span className="col-6">Referral Type Invites</span>
                    {/* {DataSource.length > 0 && (
                      <span
                        className="col-6 text-right"
                        // onClick={toggleDrawer("right", true, clientsList)}
                        onClick={() => setOpenDialog(true)}
                        style={{ color: "#009EF7", cursor: "pointer" }}
                      >
                        ADD CLIENT
                      </span>
                    )} */}
                    <div
                      className="invite-box p-4"
                      style={
                        DataSource
                          ? { minHeight: "300px", height: "unset" }
                          : null
                      }
                    >
                      {/* <div className="row">
                        {/* {clientsList &&
                          clientsList.map((item) => (
                            <div
                              class="d-flex align-items-center m-1 p-3"
                              style={{
                                backgroundColor: "#F7F8FA",
                                width: "fit-content",
                              }}
                            >
                              <img
                                src={item.ProfileImgUrl || _blank}
                                alt=""
                                style={{
                                  height: "42px",
                                  width: "42px",
                                  borderRadius: "50%",
                                }}
                              />
                              <p className="ml-2 mb-0">{item.Name}</p>
                              <span
                                style={{ cursor: "pointer" }}
                                // onClick={() =>
                                //   // removeClient(item.ClientId, item.ClientGroupId)
                                //   // removeindividual(
                                //   //   true,
                                //   //   item.ClientId,
                                //   //   item.ClientGroupId
                                //   // )
                                // }
                              >
                                <SVG
                                  className="ml-2"
                                  src={toAbsoluteUrl(_Remove)}
                                />
                              </span>
                            </div>
                          ))} 
                      </div> */}
                      {/* {clientsList.length === 0 && ( */}
                      <div
                        className="h-100 d-flex justify-content-center align-items-center"
                        style={
                          DataSource
                            ? { minHeight: "300px", height: "unset" }
                            : null
                        }
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          onClick={() => setOpenDialog(true)}
                        >
                          Select Client
                        </Button>
                      </div>
                      {/* // )} */}
                    </div>
                  </div>
                  <div
                    className={
                      DataSource && (DataSource.length ? "col-6" : "col-12")
                    }
                  >
                    <span>Voucher (Free) Type Invites</span>
                    <div
                      className="invite-box p-4"
                      style={
                        DataSource
                          ? { minHeight: "300px", height: "unset" }
                          : null
                      }
                    >
                      <div
                        className="h-100 d-flex justify-content-center align-items-center"
                        style={
                          DataSource
                            ? { minHeight: "300px", height: "unset" }
                            : null
                        }
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          onClick={() => setOpenDialog(true)}
                        >
                          Select Client
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
            {clientsList.length > 0 && (
              <div className="pt-5">
                <div className="intake-drawer-tabs">
                  {Columns && DataSource && (
                    <GenericTable
                      // tableTitle="Member Trends"
                      entities={DataSource || []}
                      columns={Columns || []}
                      isCardHeader={false}
                      pagination={true}
                      // isLoading={isFetching}
                      bordered={false}
                      // selectRow={selectRow}
                      // rowEvents={rowEvents}
                      tableOptions={{
                        PageSize,
                        IsRowClick,
                        IsExcelExport,
                        IsColumnSelectionEnable,
                        TableButtons,
                        IsFilter,
                        FileName: "SmallGroupUser",
                      }}
                      // toggleDrawer={toggleDrawer}
                      // onFilter={onFilter}
                    />
                  )}
                </div>
              </div>
            )}
          </Card>
        </div>

        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={"md"}
          maxWidth={"md"}
        >
          <DialogTitle id="alert-dialog-title">
            {"Select Client"}

            <span
              className="btn btn-icon btn-hover-light-primary btn-sm"
              // onClick={props.toggleDrawer("right", false, {})}
              onClick={handleClose}
              style={{ marginLeft: "777px" }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </span>
            {/* </OverlayTrigger> */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ClientListModal
                // toggleDrawer={toggleDrawer}
                // GroupId={props.GroupId}
                // moreItem
                handleClose={handleClose}
                handleChecked={handleChecked}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{
                height: "66px",
                width: "93%",
                backgroundColor: "white",
              }}
            >
              <span
                className="btn btn-primary"
                onClick={() => {
                  handleClose();
                  clientsUpsert(checkedArray.toString());
                  dispatch(
                    smallGroupUserListGet({
                      SmallGroup: [
                        {
                          SmallGroupGuid: props.SmallGroupGuid,
                        },
                      ],
                    })
                  );
                }}
                style={{ cursor: "pointer", width: "16%" }}
              >
                Add
              </span>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default SmallGroupInvite;
