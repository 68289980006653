import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Field, reduxForm } from "redux-form";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { useSubheader } from "../../_metronic/layout";
import formDataHandler from "../util/formDataHandler";
import {
  getFormFieldMetaData,
  courseUpsert,
  courseGet,
  resetCourseUpsert,
  // adminstrationGetCourse,
  resetCourseGet,
} from "../../redux/actions";

import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../core/generic-form";

import GenericTable from "../../app/components/generic-table/generic-table";

import Validator from "../Validator";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const Columns = [
  {
    dataField: "ClientId",
    text: "Client Id",
    sort: true,
    sortCaret: "sortCaret",
    headerSortingClasses: "headerSortingClasses",
    type: "Integer",
    sortField: "ClientId",
    width: 100,
    align: "Right",
    hidden: null,
    Actions: null,
    formatter: null,
    csvExport: false,
  },
  {
    dataField: "FirstName",
    text: "First Name",
    sort: true,
    sortCaret: "sortCaret",
    headerSortingClasses: "headerSortingClasses",
    type: "String",
    sortField: "FirstName",
    width: 100,
    align: "Left",
    hidden: null,
    Actions: null,
    formatter: null,
    csvExport: false,
  },
  {
    dataField: "LastName",
    text: "Last Name",
    sort: true,
    sortCaret: "sortCaret",
    headerSortingClasses: "headerSortingClasses",
    type: "String",
    sortField: "LastName",
    width: 150,
    align: "Left",
    hidden: null,
    Actions: null,
    formatter: null,
    csvExport: false,
  },
  {
    dataField: "DueDate",
    text: "Due Date",
    sort: true,
    sortCaret: "sortCaret",
    headerSortingClasses: "headerSortingClasses",
    type: "String",
    sortField: "DueDate",
    width: 150,
    align: "Left",
    hidden: null,
    Actions: null,
    formatter: null,
    csvExport: false,
  },
  {
    dataField: "UnassignDate",
    text: "Unassign Date",
    sort: true,
    sortCaret: "sortCaret",
    headerSortingClasses: "headerSortingClasses",
    type: "String",
    sortField: "UnassignDate",
    width: 150,
    align: "Left",
    hidden: null,
    Actions: null,
    formatter: null,
    csvExport: false,
  },
  {
    dataField: "CompletedDate",
    text: "Completed Date",
    sort: true,
    sortCaret: "sortCaret",
    headerSortingClasses: "headerSortingClasses",
    type: "String",
    sortField: "CompletedDate",
    width: 150,
    align: "Left",
    hidden: null,
    Actions: null,
    formatter: null,
    csvExport: false,
  },

  {
    dataField: "EnrollDate",
    text: "Enroll Date",
    sort: true,
    sortCaret: "sortCaret",
    headerSortingClasses: "headerSortingClasses",
    type: "String",
    sortField: "EnrollDate",
    width: 150,
    align: "Left",
    hidden: null,
    Actions: null,
    formatter: null,
    csvExport: false,
  },
  {
    dataField: "Score",
    text: "Score",
    sort: true,
    sortCaret: "sortCaret",
    headerSortingClasses: "headerSortingClasses",
    type: "String",
    sortField: "Score",
    width: 150,
    align: "Left",
    hidden: null,
    Actions: null,
    formatter: null,
    csvExport: false,
  },
];

const userOptions = [
  {
    Text: "user1",
    Value: "1",
  },
  {
    Text: "user2",
    Value: "2",
  },
  {
    Text: "user3",
    Value: "3",
  },
  {
    Text: "user4",
    Value: "4",
  },
  {
    Text: "user5",
    Value: "5",
  },
];

const suggestions = [
  { label: "Afghanistan" },
  { label: "Aland Islands" },
  { label: "Albania" },
  { label: "Algeria" },
  { label: "American Samoa" },
  { label: "Andorra" },
  { label: "Angola" },
  { label: "Anguilla" },
  { label: "Antarctica" },
  { label: "Antigua and Barbuda" },
  { label: "Argentina" },
  { label: "Armenia" },
  { label: "Aruba" },
  { label: "Australia" },
  { label: "Austria" },
  { label: "Azerbaijan" },
  { label: "Bahamas" },
  { label: "Bahrain" },
  { label: "Bangladesh" },
  { label: "Barbados" },
  { label: "Belarus" },
  { label: "Belgium" },
  { label: "Belize" },
  { label: "Benin" },
  { label: "Bermuda" },
  { label: "Bhutan" },
  { label: "Bolivia, Plurinational State of" },
  { label: "Bonaire, Sint Eustatius and Saba" },
  { label: "Bosnia and Herzegovina" },
  { label: "Botswana" },
  { label: "Bouvet Island" },
  { label: "Brazil" },
  { label: "British Indian Ocean Territory" },
  { label: "Brunei Darussalam" },
];

let CForm = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle(props.courseId ? "Edit Course" : "Add New Course");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    props.reset();
    // return () => {};
  }, []);





    const { getGenericFormFields } = useSelector(
      ({
        get_form_field_meta_data: { data, isFetching, error },
      }) => {
   
        return {
          getGenericFormFields: data?.Data?.FormFieldMetaData || []
        }
      },
      shallowEqual
    );
  


  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    initialValues,
  } = props;


  debugger;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="enrollment-form staff-form pt-2 pb-2">
                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    {" "}
                    {getGenericFormFields && (
                      <DynamicForm
                        formFields={getGenericFormFields}
                        disable={false}
                      />
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Submit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  {/* <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    disabled={pristine || submitting}
                    onClick={reset}
                  >
                    Clear Values
                  </Button> */}
                </div>
              </form>

              <div className="mt-5">
                {initialValues?.ClientCourse &&
                  initialValues.ClientCourse.length > 0 && (
                    <>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <h1>Enrolled Client List</h1>
                    </>
                  )}

                {initialValues?.ClientCourse &&
                  initialValues.ClientCourse.length > 0 && (
                    <GenericTable
                      // tableTitle="Member Trends"
                      entities={
                        initialValues?.ClientCourse
                          ? initialValues?.ClientCourse
                          : []
                      }
                      columns={Columns || []}
                      isCardHeader={false}
                      pagination={true}
                      isLoading={false}
                      bordered={false}
                      // selectRow={selectRow}
                      // rowEvents={rowEvents}
                      tableOptions={{
                        PageSize: 20,
                        IsRowClick: false,
                        IsFilter: false,
                        IsExcelExport: true,
                        IsColumnSelectionEnable: false,
                        TableButtons: false,
                        FileName: "Course",
                      }}
                      toggleDrawer={false}
                      onFilter={false}
                    />
                  )}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
const ReduxCForm = reduxForm({
  form: "courseForm",
  enableReinitialize: true,
})(CForm);

// const reduxCourseForm = reduxForm(
//   {
//     form: "MaterialUiForm",
//     // initialValues: {
//     //   currentUser: "2",
//     //   date: "2022-02-17",
//     //   dollar: "500",
//     //   firstName: "yh",
//     //   hobbies: [1, 2, 3],
//     //   time: "15:35",
//     //   mobile: "132",
//     //   description: "hello",
//     //   description2: "hello2",
//     // },
//   }(CourseForm)
// );
const CourseForm = (props) => {
  const dispatch = useDispatch();
  const [courseData, setCourseData] = useState({});
  const history = useHistory();
  const getId = () => {
    var param = new URLSearchParams(history.location.search);
    var courseId = param.get("id");
    if (courseId) {
      courseId = parseInt(courseId);
    } else {
      courseId = null;
    }
    return courseId;
  };

  useEffect(() => {
    dispatch(resetCourseGet({}));
  
    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: "Course",
          },
        ],
      })
    );
    if (getId() || getId() === 0) {
      dispatch(courseGet({ Course: { CourseId: getId() } }));
    }
  }, []);
  useEffect(() => {
    return () => {
      dispatch(resetCourseGet());
    };
  }, []);
  const { courseDataObj, isInserted } = useSelector(
    ({ course_get: { data }, course_upsert }) => {
      return {
        courseDataObj: data?.Data?.Courses?.[0],
        isInserted: course_upsert.data && course_upsert.data.success,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (isInserted) {
      toast.success("Course added successfullly");
      dispatch(resetCourseUpsert());
      history.push("/admin/courses/list");
    }
  }, [isInserted]);
  useEffect(() => {
    setCourseData(courseDataObj);
  }, [courseDataObj]);

  const onSubmit = (values) => {
    let courseId = null;
    let paramCourseId = getId();
    if (paramCourseId && paramCourseId > 0) {
      courseId = paramCourseId;
    }
    // let valueArray = Object.keys(values);
    // const valuesObj = {};
    // for (const key of valueArray) {
    //   if (values[key] instanceof Date) {
    //     valuesObj[key] = moment(values[key]).format("MM/DD/YYYY");
    //   } else if (values[key] != null && typeof values[key] == "object") {
    //     valuesObj[key] = values[key].map((item) => item.Value).toString();
    //   } else {
    //     valuesObj[key] = values[key];
    //   }
    // }
    let ObjectData = formDataHandler(values);

    const data = {
      Course: [
        {
          CourseId: courseId?courseId:null,
          // Abstract: abstract,
          // AuthorName: authorName,
          // AuthorStaffId: authorStaffId,
          // CopyrightMsg: copyrightMsg,
          // CourseCode: null,
          // CourseLength: null,
          // CourseLengthUnit: null,
          // CourseTopicId: courseTopic,
          // CourseTypeId: courseType,
          // CourseUrl: courseUrl,
          // Description: description,
          // InactivatedDate: inactivatedDate,
          // LadderAssociation: this.getStringType(values.ladderAssociation),
          // ContractLadderAssociationId: this.getStringType(
          //   values.contractLadderAssociationId
          // ),
          // LanguageISO: language,
          // Name: name,
          // PublishedDate: publishedDate,
          ...ObjectData,
        },
      ],
    };
  

    dispatch(courseUpsert(data));
  };
  return (
    <ReduxCForm
      onSubmit={onSubmit}
      initialValues={courseData}
      courseId={getId()}
    />
  );
};
export default CourseForm;
