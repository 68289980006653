import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* memberTrendsGet(action) {
  try {
    const response = yield API.memberTrendsGet(action.payload);
    yield put({
      type: ActionTypes.MEMBER_TRENDS_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MEMBER_TRENDS_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.MEMBER_TRENDS_GET_REQUEST, memberTrendsGet),
  ]);
}
