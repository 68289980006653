import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { TestHistory } from "../../components/custom-screens/breathlyzerPage/testHistory";

const Breathlyzer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="card card-custom w-100">
      <div className="card-body pt-5">
        <div className="breathlyzer-tabs">
          <TestHistory ID={props.ID} />
        </div>
      </div>
    </div>
  );
};
export default Breathlyzer;
