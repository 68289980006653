import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageTitle from "../../../pageTitleConfig";
import TabsSection from "./tabsSection";

import { useSubheader } from "../../../../_metronic/layout";

const PopulationActivitiesPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Population Activities");

  return (
    <>
      <Helmet>
        <title>{PageTitle.POPULATION_ACTIVITIES_HOME}</title>
      </Helmet>
      <div className="row">
        <div className="col-md-12">
          <TabsSection />
        </div>
      </div>
    </>
  );
};
export default PopulationActivitiesPage;
