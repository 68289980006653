import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
//Define a method to simulate data, this is the method of ApexCharts official website

function generateDayWiseTimeSeries(baseval, count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push([baseval, y, "shubham"]);
    baseval += 86400000;
    i++;
  }
  return series;
}

export const EmotionalAffectChart = ({ graphData }) => {
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    let xyz = [];
    let dateArray = [
      new Date("11 Feb 2022").getTime(),
      new Date("12 Mar 2022").getTime(),
      new Date("13 Apr 2022").getTime(),
      new Date("15 May 2022").getTime(),
      new Date("16 Jun 2022").getTime(),
    ];
    graphData &&
      graphData.map((item, index) => {
        const { Cause, Datetime, IsRedColor, Remarks, TrendLevel } = item;

        // let unixDate = new Date(Datetime).getTime();
        // let unixDate = moment("Aug 12,2020 5:51pm").toDate();
        // let unixDate = moment(Datetime).format("MMMM Do YYYY, h:mm:ss a");

        // unixDate = new Date(unixDate).getTime();
        let unixDate = moment(Datetime, "MMM DD,YYYY h:mm a").format(
          "DD MMM YYYY"
        );
        let data = [new Date(unixDate).getTime(), Cause, Remarks, IsRedColor];
        // let data = [dateArray[index], index, Remarks, IsRedColor];

        xyz.push(data);
        // setSeriesData(]);
      });
    setSeriesData(xyz);
  }, []);

  // const barSeries = [
  //   {
  //     name: "Total Active",
  //     type: "column",
  //     data: [8489, 9322, 10631, 11219, 12564, 13224],
  //   },
  //   {
  //     name: "Newly added",
  //     type: "line",
  //     data: [435, 833, 1309, 588, 1345, 660],
  //     // data: [1000, 3000, 6000, 9000, 12000, 15000]
  //   },
  // ];
  // const barOptions = {
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       dataLabels: {
  //         position: "top",
  //       },
  //       columnWidth: "50%",
  //       // distributed: true
  //     },
  //   },
  //   chart: {
  //     height: 350,
  //     type: "line",
  //     zoom: {
  //       enabled: false,
  //     },
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   stroke: {
  //     width: [0, 4],
  //   },
  //   title: {
  //     text: "Legacy . New",
  //   },
  //   legend: {
  //     show: true,
  //     showForSingleSeries: false,
  //     showForNullSeries: true,
  //     showForZeroSeries: true,
  //     position: "bottom",
  //     horizontalAlign: "center",
  //     floating: false,
  //     fontSize: "14px",
  //     fontFamily: "Helvetica, Arial",
  //     fontWeight: 400,
  //     formatter: undefined,
  //     inverseOrder: false,
  //     width: undefined,
  //     height: undefined,
  //     tooltipHoverFormatter: undefined,
  //     customLegendItems: [],
  //     offsetX: 0,
  //     offsetY: 0,
  //     labels: {
  //       colors: undefined,
  //       useSeriesColors: false,
  //     },
  //     markers: {
  //       width: 12,
  //       height: 12,
  //       strokeWidth: 0,
  //       strokeColor: "#fff",
  //       fillColors: undefined,
  //       radius: 12,
  //       customHTML: undefined,
  //       onClick: undefined,
  //       offsetX: 0,
  //       offsetY: 0,
  //     },
  //     itemMargin: {
  //       horizontal: 5,
  //       vertical: 0,
  //     },
  //     onItemClick: {
  //       toggleDataSeries: true,
  //     },
  //     onItemHover: {
  //       highlightDataSeries: true,
  //     },
  //   },
  //   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  //   xaxis: {
  //     type: "category",
  //   },
  //   yaxis: [
  //     {
  //       title: {
  //         text: "Total",
  //       },
  //     },
  //     {
  //       opposite: true,
  //       title: {
  //         text: "Newly Added",
  //       },
  //     },
  //   ],
  // };
  // const seriesLine2 = [
  //   {
  //     data: generateDayWiseTimeSeries(new Date("11 Feb 2017").getTime(), 20, {
  //       min: 10,
  //       max: 30,
  //     }),
  //   },
  // ];
  // const optionsLine2 = {
  //   chart: {
  //     id: "tw",
  //     group: "social",
  //     type: "line",
  //     height: 160,
  //   },
  //   colors: ["#546E7A"],
  // };
  // const seriesArea = [
  //   {
  //     data: generateDayWiseTimeSeries(new Date("11 Feb 2017").getTime(), 20, {
  //       min: 10,
  //       max: 60,
  //     }),
  //   },
  // ];
  // const optionsArea = {
  //   chart: {
  //     id: "yt",
  //     group: "social",
  //     type: "area",
  //     height: 160,
  //   },
  //   colors: ["#00E396"],
  // };
  // const seriesSmall = [
  //   {
  //     data: generateDayWiseTimeSeries(new Date("11 Feb 2017").getTime(), 20, {
  //       min: 10,
  //       max: 60,
  //     }),
  //   },
  // ];
  // const optionsSmall = {
  //   chart: {
  //     id: "ig",
  //     group: "social",
  //     type: "area",
  //     height: 160,
  //     width: 300,
  //   },
  //   colors: ["#008FFB"],
  // };
  // const seriesSmall2 = [
  //   {
  //     data: generateDayWiseTimeSeries(new Date("11 Feb 2017").getTime(), 20, {
  //       min: 10,
  //       max: 60,
  //     }),
  //   },
  // ];
  // const optionsSmall2 = {
  //   chart: {
  //     id: "li",
  //     group: "social",
  //     type: "area",
  //     height: 160,
  //     width: 300,
  //   },
  //   colors: ["#546E7A"],
  // };

  const series = [
    {
      data: seriesData,
    },
  ];

  const options = {
    chart: {
      id: "fb",
      group: "social",
      type: "line",
      height: 160,

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      followCursor: false,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          "<span>" +
          w.config.series[seriesIndex].data[dataPointIndex][2] +
          // w.globals.labels[dataPointIndex] +
          ": " +
          // series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
    annotations: {
      yaxis: [
        {
          y: 8800,
          borderColor: "#00E396",
          label: {
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396",
            },
            text: "Y-axis annotation on 8800",
          },
        },
      ],
    },
    stroke: {
      curve: "smooth",
    },

    markers: {
      size: 7,
      colors: "#00DC31",
      strokeColors: "#00DC31",
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      // discrete: [
      //   {
      //     seriesIndex: 0,
      //     dataPointIndex: 2,
      //     fillColor: "#FF2C2C",
      //     strokeColor: "#FF2C2C",
      //     size: 7,
      //     shape: "circle", // "circle" | "square" | "rect"
      //   },
      // ],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    xaxis: {
      labels: {
        formatter: function(value, timestamp, opts) {
          // var dateString = moment.unix(timestamp).format("MM/DD/YYYY");
          const dateString = moment(timestamp).format("DD-MMM-YYYY");

          return dateString;
          // return opts.dateFormatter(new Date(timestamp)).format("dd MMM");
        },
      },
    },

    colors: ["#008FFB"],
  };

  return (
    <>
      <div>
        {" "}
        <div
          style={{
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          {/* <ReactApexChart
            options={barOptions}
            series={barSeries}
            type="line"
            height={350}
          /> */}
          <div id="chart-line">
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={300}
            />
          </div>
          <div id="chart-line2">
            {/* <ReactApexChart
              options={optionsLine2}
              series={seriesLine2}
              type="line"
              height={160}
            /> */}
          </div>
          <div id="chart-area">
            {/* <ReactApexChart
              options={optionsArea}
              series={seriesArea}
              type="area"
              height={160}
            /> */}
          </div>
          <div class="columns">
            <div id="chart-small">
              {/* <ReactApexChart
                options={optionsSmall}
                series={seriesSmall}
                type="area"
                height={160}
                width={300}
              /> */}
            </div>
            <div id="chart-small2">
              {/* <ReactApexChart
                options={optionsSmall2}
                series={seriesSmall2}
                type="area"
                height={160}
                width={300}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
