import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { miscellaneousNoteTypeGet } from "../../../../redux/actions";
import _Edit from "../../../images/edit.svg";
import _Close from "../../../images/close.svg";
import _Agreed from "../../../images/agreed.svg";
import _Disagreed from "../../../images/disagreed.svg";

export const ViewNoteTypeDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      miscellaneousNoteTypeGet({
        NoteType: [
          {
            NoteTypeId: props.NoteTypeId,
          },
        ],
      })
    );
  }, []);

  const { noteDetails } = useSelector(
    ({ miscellaneous_note_type_get: { data: noteGet } }) => {
      return {
        noteDetails: noteGet?.Data?.NoteTypes?.[0] || {},
      };
    },
    shallowEqual
  );
  const { NoteTypeId, Description, DateInsertedDDMMMYYYY, Status } =
    noteDetails || {};
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pl-4 pt-5">
          <h3>Note type Details </h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-2 drawer-small-group drawer-details-view">
          <div className="row">
            <div className="col-11">
              {/* <span className="small-group-title">{GroupName}</span> */}
            </div>
            <div className="col-1">
              {/* <div>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="edit-tooltip">Note type details</Tooltip>
                  }
                >
                  <span
                    className="btn btn-icon btn-hover-light-primary btn-sm"
                    onClick={() => {
                      history.push(`/community/group/edit?id=${GroupId}`);
                    }}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl(_Edit)} />
                    </span>
                  </span>
                </OverlayTrigger>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Note type</span>
                  <span className="c-value">{Description}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Note ID</span>
                  <span className="c-value">{NoteTypeId}</span>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Status</span>
                  <span className="c-value">{Status}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Date Inserted</span>
                  <span className="c-value">{DateInsertedDDMMMYYYY}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
