import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";

export function* appSubscribers(action) {
  try {
    const { payload } = action;
    const response = yield API.listAppSubscribers(payload);
    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_FAILURE,
    });
  }
}
export function* getAppSubscriberProfileDashboard(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscriberProfileDashboard(payload);

    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_DASHBOARD_SUCCESS,
      payload: JSON.parse(response.data.data).AppUser[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_DASHBOARD_FAILURE,
    });
  }
}
export function* getAppSubscriberProfile(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscriberProfile(payload);

    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_PROFILE_SUCCESS,
      payload: JSON.parse(response.data.data).AppUserProfile[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_PROFILE_FAILURE,
    });
  }
}
export function* getAppSubscriberChat(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscriberChat(payload);
    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_FAILURE,
    });
  }
}
export function* getAppSubscriberChatCoach(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscriberChatCoach(payload);
    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_COACH_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_COACH_FAILURE,
    });
  }
}
export function* getAppSubscriberProfileActivities(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscriberProfileActivities(payload);
    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_ACTIVITIES_SUCCESS,
      // payload: response.data,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_ACTIVITIES_FAILURE,
    });
  }
}

export function* addAppSubscriberNote(action) {
  try {
    const { payload } = action;
    const response = yield API.addAppSubscriberNote(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_FAILURE,
    });
  }
}
export function* addAppSubscriberTask(action) {
  try {
    const { payload } = action;
    const response = yield API.addAppSubscriberTask(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_ADD_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_ADD_TASK_FAILURE,
    });
  }
}
export function* deleteAppSubscriberTask(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteAppSubscriberTask(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_FAILURE,
    });
  }
}

export function* appSubscribersUserSearch(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscribersUserSearch(payload);
    if (payload.saveSearch) {
      yield put({
        type: ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_SUCCESS,
        payload: {
          data:
            (response.data.data &&
              JSON.parse(response.data.data).AppUserSearch) ||
            [],
          searchStr: payload.SearchStr,
        },
      });
    } else {
      yield put({
        type: ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_SUCCESS,
        payload: JSON.parse(response.data.data || "{}").AppUserSearch,
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_FAILURE,
    });
  }
}
export function* appSubscriberCompleteTask(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscriberCompleteTask(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_FAILURE,
    });
  }
}

export function* addAppSubscriberCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.addAppSubscriberCourse(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_FAILURE,
    });
  }
}
export function* appSubscribersupdateCase(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscribersupdateCase(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_FAILURE,
    });
  }
}
export function* deleteAppSubscriberCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteAppSubscriberCourse(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_FAILURE,
    });
  }
}
export function* appSubscriberCompleteCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscriberCompleteCourse(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_FAILURE,
    });
  }
}
export function* appSubscriberMapHistory(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscriberMapHistory(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_MAP_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data || "{}"),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_MAP_HISTORY_FAILURE,
    });
  }
}

export function* appMarkSystemObservation(action) {
  try {
    const { payload } = action;
    const response = yield API.appMarkSystemObservation(payload);
    yield put({
      type: ActionTypes.APP_MARK_SYSTEM_OBSERVATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_MARK_SYSTEM_OBSERVATION_FAILURE,
    });
  }
}

export function* loadCaseProfile(action) {
  try {
    const { payload } = action;
    const response = yield API.loadCaseProfile(payload);
    yield put({
      type: ActionTypes.LOAD_CASE_PROFILE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_CASE_PROFILE_FAILURE,
    });
  }
}

export function* loadLocationAuthHistory(action) {
  try {
    const { payload } = action;
    const response = yield API.loadLocationAuthHistory(payload);
    yield put({
      type: ActionTypes.LOAD_LOCATION_AUTH_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_LOCATION_AUTH_HISTORY_FAILURE,
    });
  }
}
export function* getUserNotePatient(action) {
  try {
    const { payload } = action;
    const response = yield API.getUserNotePatient(payload);
    yield put({
      type: ActionTypes.GET_USER_NOTE_PATIENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_USER_NOTE_PATIENT_FAILURE,
    });
  }
}
export function* getUserNoteComment(action) {
  try {
    const { payload } = action;
    const response = yield API.getUserNoteComment(payload);
    yield put({
      type: ActionTypes.GET_USER_NOTE_COMMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_USER_NOTE_COMMENT_FAILURE,
    });
  }
}
export function* addUserNoteComment(action) {
  try {
    const { payload } = action;
    const response = yield API.addUserNoteComment(payload);
    yield put({
      type: ActionTypes.ADD_USER_NOTE_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_USER_NOTE_COMMENT_FAILURE,
    });
  }
}
export function* getUserNoteEmoji(action) {
  try {
    const { payload } = action;
    const response = yield API.getUserNoteEmoji(payload);
    yield put({
      type: ActionTypes.GET_USER_NOTE_EMOJI_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_USER_NOTE_EMOJI_FAILURE,
    });
  }
}
export function* addUserNoteEmoji(action) {
  try {
    const { payload } = action;
    const response = yield API.addUserNoteEmoji(payload);
    yield put({
      type: ActionTypes.ADD_USER_NOTE_EMOJI_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_USER_NOTE_EMOJI_FAILURE,
    });
  }
}

export function* getMediaVideoUrl(action) {
  try {
    const { payload } = action;
    const response = yield API.getMediaVideoUrl(payload);
    yield put({
      type: ActionTypes.GET_MEDIA_VIDEO_URL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_MEDIA_VIDEO_URL_FAILURE,
    });
  }
}

export function* chatFriendListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.chatFriendListGet(payload);
    yield put({
      type: ActionTypes.CHAT_FRIEND_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CHAT_FRIEND_LIST_GET_FAILURE,
    });
  }
}

export function* addAppSubscriberSurvey(action) {
  try {
    const { payload } = action;
    const response = yield API.addAppSubscriberSurvey(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_FAILURE,
    });
  }
}

export function* deleteAppSubscriberSurvey(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteAppSubscriberSurvey(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_FAILURE,
    });
  }
}

export function* loadAppSubSurveyDashboard(action) {
  try {
    const { payload } = action;
    const response = yield API.loadAppSubSurveyDashboard(payload);
    yield put({
      type: ActionTypes.LOAD_APP_SUB_SURVEY_DASHBOARD_SUCCESS,
      payload: JSON.parse(response.data.data).User[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_APP_SUB_SURVEY_DASHBOARD_FAILURE,
    });
  }
}

export function* appSubSurveyAnsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubSurveyAnsGet(payload);
    yield put({
      type: ActionTypes.APP_SUB_SURVEY_ANS_GET_SUCCESS,
      payload: JSON.parse(response.data.result),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUB_SURVEY_ANS_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_APP_SUBSCRIBERS_REQUEST, appSubscribers),
    takeLatest(
      ActionTypes.GET_APP_SUBSCRIBERS_DASHBOARD_REQUEST,
      getAppSubscriberProfileDashboard
    ),
    takeLatest(
      ActionTypes.GET_APP_SUBSCRIBERS_PROFILE_REQUEST,
      getAppSubscriberProfile
    ),
    takeLatest(
      ActionTypes.GET_APP_SUBSCRIBERS_CHAT_REQUEST,
      getAppSubscriberChat
    ),
    takeLatest(
      ActionTypes.GET_APP_SUBSCRIBERS_CHAT_COACH_REQUEST,
      getAppSubscriberChatCoach
    ),
    takeLatest(
      ActionTypes.GET_APP_SUBSCRIBERS_ACTIVITIES_REQUEST,
      getAppSubscriberProfileActivities
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_REQUEST,
      addAppSubscriberNote
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_ADD_TASK_REQUEST,
      addAppSubscriberTask
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_REQUEST,
      deleteAppSubscriberTask
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_REQUEST,
      appSubscribersUserSearch
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_REQUEST,
      appSubscriberCompleteTask
    ),

    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_REQUEST,
      addAppSubscriberCourse
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_REQUEST,
      appSubscribersupdateCase
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_REQUEST,
      deleteAppSubscriberCourse
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_REQUEST,
      appSubscriberCompleteCourse
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_MAP_HISTORY_REQUEST,
      appSubscriberMapHistory
    ),
    takeLatest(
      ActionTypes.APP_MARK_SYSTEM_OBSERVATION_REQUEST,
      appMarkSystemObservation
    ),
    takeLatest(ActionTypes.LOAD_CASE_PROFILE_REQUEST, loadCaseProfile),
    takeLatest(
      ActionTypes.LOAD_LOCATION_AUTH_HISTORY_REQUEST,
      loadLocationAuthHistory
    ),
    takeLatest(ActionTypes.GET_USER_NOTE_PATIENT_REQUEST, getUserNotePatient),
    takeLatest(ActionTypes.GET_USER_NOTE_COMMENT_REQUEST, getUserNoteComment),
    takeLatest(ActionTypes.ADD_USER_NOTE_COMMENT_REQUEST, addUserNoteComment),
    takeLatest(ActionTypes.GET_USER_NOTE_EMOJI_REQUEST, getUserNoteEmoji),
    takeLatest(ActionTypes.ADD_USER_NOTE_EMOJI_REQUEST, addUserNoteEmoji),

    takeLatest(ActionTypes.GET_MEDIA_VIDEO_URL_REQUEST, getMediaVideoUrl),

    takeLatest(ActionTypes.CHAT_FRIEND_LIST_GET_REQUEST, chatFriendListGet),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_REQUEST,
      addAppSubscriberSurvey
    ),
    takeLatest(
      ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_REQUEST,
      deleteAppSubscriberSurvey
    ),
    takeLatest(
      ActionTypes.LOAD_APP_SUB_SURVEY_DASHBOARD_REQUEST,
      loadAppSubSurveyDashboard
    ),
    takeLatest(ActionTypes.APP_SUB_SURVEY_ANS_GET_REQUEST, appSubSurveyAnsGet),
  ]);
}
