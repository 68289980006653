import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { reduxForm } from "redux-form";
import {
  getFormFieldMetaData,
  appointmentUpsert,
} from "../../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../../core/generic-form";
import formDataHandler from "../../../util/formDataHandler";
import SelectPatient from "../../../components/redux-component/selectPatient";
import CustomLoader from "../../../components/custom-component/custom-loader";

const AppointmentExistingClient = (props) => {
  const dispatch = useDispatch();
  const [selectedClient, setSelectedClient] = useState("");
  useEffect(() => {
    let data = {
      DynamicForm: [
        {
          FormName: "AppointmentExistingClient",
        },
      ],
    };
    dispatch(getFormFieldMetaData(data));
  }, []);

  const { isFetching } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data, isFetching },
    } = state;
    return { isFetching };
  }, shallowEqual);

  const getSelectedClient = (value) => {
    setSelectedClient(value);
  };
  const {
    formFields,
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    ClientId,
  } = props;

  const handleCreateAppointment = (values) => {
    let ObjectData = formDataHandler(values);
    const {
      AppointmentDate,
      AppointmentEndTime,
      AppointmentTime,
      OfficeName,
      PlaceOfServiceId,
      PractitionerId,
      ProgramId,
      ReasonForVisit,
      RecurringType,
      ServiceCode,
      Title,
      Status,
    } = ObjectData;

    const formData = {
      Appointment: [
        {
          ClientId: selectedClient ? selectedClient : null,
          Title,
          AppointmentDate,
          AppointmentTime,
          AppointmentEndTime,
          PractitionerId,
          OfficeName,
          ServiceCode,
          ReasonForVisit,
          Status,
          ProgramId,
          PlaceOfServiceId,
          IsRecurring: false,
          // Email,
          // GroupAppointmentRecurring: [
          //   {
          //     GroupAppointmentRecurringId: null,
          //     RecurringType,
          //     WeekDays: "2,5",
          //     MonthlyRecurring: null,
          //     RecurringCount: "3",
          //   },
          // ],
        },
      ],
    };

    dispatch(appointmentUpsert(formData));
  };

  return (
    <>
      {isFetching ? (
        <CustomLoader />
      ) : (
        <form
          autoComplete="off"
          onSubmit={handleSubmit(handleCreateAppointment)}
        >
          <div className="enrollment-form staff-form pt-2 pb-2">
            <div className="pb-5 pl-3 pr-3 pt-3">
              <SelectPatient
                isMulti={false}
                name={"clientList"}
                getSelectedClient={getSelectedClient}

                // handleNext={handleNext}
                // handleBack={handleBack}
                // GroupId={getGroupId()}
                // activeStep={activeStep}
                // NextTab={NextTab}
                // FormName={FormName}
              />
            </div>
            <div className="row pb-5 pl-3 pr-3 pt-3">
              <DynamicForm formFields={formFields} disable={false} />
            </div>
            <div className="text-right">
              <Button
                variant="contained"
                color="secondary"
                //   className={classes.button}
                type="submit"
                //   disabled={pristine || submitting}
              >
                Create Visit
              </Button>
              &nbsp;
            </div>
          </div>
        </form>
      )}
    </>
  );
};

const ReduxAppointmentExistingClient = reduxForm({
  form: "AppointmentExistingClient",
  enableReinitialize: true,
})(AppointmentExistingClient);

export default ReduxAppointmentExistingClient;
