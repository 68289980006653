import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import GenericTable from "../../generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  growthPlanTemplateGet,
  getDynamicGridFilter,
  resetCourseStatusUpdate,
  resetCourseGet,
} from "../../../../redux/actions";
import { TemplateDetailDrawer } from "./Drawers/template-details";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));
export const MyTemplates = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, moreItems) => (event) => {
    debugger;
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };
  useEffect(() => {
    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "GrowthPlanTemplate",
          },
        ],
      })
    );
    dispatch(
      getDynamicGridFilter({
        ComponentGrid: [
          {
            Name: "GrowthPlanTemplate",
          },
        ],
      })
    );
    dispatch(
      growthPlanTemplateGet({
        GrowthPlan: [
          {
            Search: "",
            // filter: "Deactivated",
          },
        ],
      })
    );
  }, []);
  const onFilter = (filterValue, toggleFilterDrawer) => {
    let data = {
      ...filterValue,
    };
    dispatch(growthPlanTemplateGet(data));
    toggleFilterDrawer("right", false)("no needs");
  };
  const {
    dynamicGridColumnData,
    isFetching,
    GrowthPlanListData,
    isCourseStatusChange,
  } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data, isFetching, error },
      growth_plan_template_get: { data: ListData },
      course_status_update,
    }) => {
      return {
        dynamicGridColumnData: data,
        GrowthPlanListData: ListData?.Data?.GrowthPlans,
        isCourseStatusChange: course_status_update?.data?.ResponseCode === 1,
      };
    },
    shallowEqual
  );
  const {
    PageSize,
    IsRowClick,
    IsFilter,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = GrowthPlanListData || [];

  // const { Trend } = advisorMemberTrendsData || {};
  const enableSorting = true;

  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Templates");

  return (
    <div>
      <div className="MyTemplates">
        {Columns && DataSource && (
          <GenericTable
            // tableTitle="Member Trends"
            entities={DataSource || []}
            columns={Columns || []}
            isCardHeader={false}
            pagination={true}
            isLoading={isFetching}
            bordered={false}
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            tableOptions={{
              PageSize,
              IsRowClick,
              IsFilter,
              IsExcelExport,
              IsColumnSelectionEnable,
              TableButtons,
              FileName: "MyTemplates",
            }}
            toggleDrawer={toggleDrawer}
            onFilter={onFilter}
          />
        )}
      </div>
      {state.right && (
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false, {})}
        >
          <div role="presentation" style={{ width: "800px" }}>
            <TemplateDetailDrawer
              toggleDrawer={toggleDrawer}
              TemplateId={moreItem?.GrowthPlanTemplateId}
              moreItem={moreItem}
            />
          </div>
        </Drawer>
      )}
    </div>
  );
};
