import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { TabScrollButton } from "@mui/material";
import Button from "@material-ui/core/Button";
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
}));
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  // padding: "5px",
  // margin: `10px`,

  // change background colour if dragging
  background: isDragging && "red",
  color: isDragging && "red",
  textTransform: "capitalize",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#E4E6EF" : "#fff",
  minHeight: "33px",
});
export default function FullWidthTabs(props) {
  const { currentTab, baseTabUrl } = props;
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(currentTab || 0);
  const [tabs, setTabs] = useState([]);

  const [tabNameToIndex, setTabNameToIndex] = useState({});
  const [indexToTabName, setIndexToTabName] = useState({});
  useEffect(() => {
    const { tabsData, labels } = props;
    let nameToIndex = {};
    let indexToName = {};
    let param = new URLSearchParams(history.location.search);
    let tabName = param.get("tab");
    if (tabsData && tabsData.length > 0) {
      let ComponentTabs = tabsData.map((tab, index) => {
        nameToIndex[tab.Id.toLowerCase()] = index;
        indexToName[index] = tab.Id.toLowerCase();
        return {
          Id: tab.Id,
          Name: tab.TabName,
          Component: labels[tab.Component],
        };
      });
      setTabs(ComponentTabs);
      setTabNameToIndex(nameToIndex);
      setIndexToTabName(indexToName);
      setActiveTab(nameToIndex[tabName] || 0);
    }
  }, [tabsData]);

  const { tabsData, header } = props;
  function handleChange(event, newValue) {
    setActiveTab(newValue);
  }

  function handleChangeIndex(index) {
    setActiveTab(index);
  }
  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const newTabs = Array.from(tabs);
    // const source = newTabs.findIndex((item) => item.Id === result.source.index);
    // const destination = newTabs.findIndex(
    //   (item) => item.Id === result.destination.index
    // );
    // const [removed] = newTabs.splice(source, 1);
    // newTabs.splice(destination, 0, removed);
    const [removed] = newTabs.splice(result.source.index, 1);
    newTabs.splice(result.destination.index, 0, removed);
    setTabs(newTabs);
  };
  // const isDragDisabled = this.props.task.id === "task-1";
  const setActiveTabUrl = (index) => {
    history.push(`${baseTabUrl}${indexToTabName[index]}`);
    setActiveTab(index);
  };
  return (
    <div className={`card card-custom card-stretch gutter-b`}>
      {/* Head */}

      {header && (
        <div className="card-header border-0">
          <h4 className="card-title font-weight-bolder text-dark">{header}</h4>
        </div>
      )}

      {/* Body */}
      <div
        className="card-body position-relative overflow-hidden pt-5 px-2"
        id="dashboard-analytics"
      >
        {/* <AppBar position="static" color="default"> */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tabs" direction="horizontal">
            {(props, snapshot) => (
              <Tabs
                ref={props.innerRef}
                value={activeTab}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="primary"
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
                className="drawer-tabs"
                variant="scrollable"
                scrollButtons="auto"
                selectionFollowsFocus={true}
                style={getListStyle(snapshot.isDraggingOver)}
                {...props.droppableProps}
              >
                {tabs &&
                  tabs.map(({ Id, Name }, index) => (
                    <Draggable
                      key={Id}
                      draggableId={Id} // must be a string
                      index={index}
                      disableInteractiveElementBlocking={true}

                      // isDragDisabled={isDragDisabled}
                    >
                      {(props, snapshot) => (
                        <Tab
                          ref={props.innerRef}
                          {...props.draggableProps}
                          {...props.dragHandleProps}
                          label={Name}
                          onClick={() => {
                            setActiveTabUrl(index);
                          }} // Set active tab like this
                          selected={activeTab == index ? "Mui-selected" : ""}
                          style={getItemStyle(
                            snapshot.isDragging,
                            props.draggableProps.style
                          )}
                          // isDragging={snapshot.isDragging}
                          // isDragDisabled={isDragDisabled}
                        />
                      )}
                    </Draggable>
                  ))}
              </Tabs>
            )}
          </Droppable>
        </DragDropContext>

        {/* {tabs &&
            tabs.map((tab, index) => {
              let { Name, Component } = tab;
              return <Tab label={Name} />;
            })} */}

        {/* </AppBar> */}
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeTab}
          onChangeIndex={handleChangeIndex}
        >
          {tabs &&
            tabs.map((tab, index) => {
              let { Name, Component } = tab;
              return (
                <div className="mt-5" id={index} key={uuid()}>
                  <TabContainer dir={theme.direction} id={Name}>
                    {activeTab == index && <Component />}
                  </TabContainer>
                </div>
              );
            })}
        </SwipeableViews>
      </div>
    </div>
  );
}
