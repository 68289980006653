import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  ReduxInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxDateInput,
  ReduxTimeInput,
  ReduxDollarInput,
  ReduxMultiSelect,
  ReduxToggle,
  ReduxCheckbox,
  ReduxAutoComplete,
  ReduxPhoneInput,
} from "../components/redux-component/index";
import Button from "@material-ui/core/Button";
import { Field, reduxForm } from "redux-form";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";

import Validator from "../Validator";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const userOptions = [
  {
    Text: "user1",
    Value: "1",
  },
  {
    Text: "user2",
    Value: "2",
  },
  {
    Text: "user3",
    Value: "3",
  },
  {
    Text: "user4",
    Value: "4",
  },
  {
    Text: "user5",
    Value: "5",
  },
];

const suggestions = [
  { label: "Afghanistan" },
  { label: "Aland Islands" },
  { label: "Albania" },
  { label: "Algeria" },
  { label: "American Samoa" },
  { label: "Andorra" },
  { label: "Angola" },
  { label: "Anguilla" },
  { label: "Antarctica" },
  { label: "Antigua and Barbuda" },
  { label: "Argentina" },
  { label: "Armenia" },
  { label: "Aruba" },
  { label: "Australia" },
  { label: "Austria" },
  { label: "Azerbaijan" },
  { label: "Bahamas" },
  { label: "Bahrain" },
  { label: "Bangladesh" },
  { label: "Barbados" },
  { label: "Belarus" },
  { label: "Belgium" },
  { label: "Belize" },
  { label: "Benin" },
  { label: "Bermuda" },
  { label: "Bhutan" },
  { label: "Bolivia, Plurinational State of" },
  { label: "Bonaire, Sint Eustatius and Saba" },
  { label: "Bosnia and Herzegovina" },
  { label: "Botswana" },
  { label: "Bouvet Island" },
  { label: "Brazil" },
  { label: "British Indian Ocean Territory" },
  { label: "Brunei Darussalam" },
];

const MyPage3 = (props) => {
  const classes = useStyles();

  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <>
      {" "}
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader title={"Title"}></CardHeader>
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit((data) => {})}>
                <div className="row mb-5">
                  <div className="col-md-4">
                    <Field
                      id={"firstName"}
                      name={`firstName`}
                      component={ReduxInput}
                      // placeholder="First Name"
                      fieldName="First Name"
                      type="text"
                      validate={Validator.required}
                      labelText="First Name*"
                    />
                  </div>
                  {/* <div className="col-md-4">
                <Field
                  id={"lastName"}
                  name={`lastName`}
                  component={ReduxInput}
                  // placeholder="Last Name"
                  fieldName="Last Name"
                  type="text"
                  validate={Validator.required}
                  labelText="Last Name*"
                />
              </div> */}
                  <div className="col-md-4">
                    <Field
                      id={"mobilephone"}
                      name={`mobilephone`}
                      component={ReduxPhoneInput}
                      // placeholder="Last Name"
                      fieldName="Mobile Number"
                      // validate={Validator.required}
                      // type="number"
                      labelText="Mobile Number"
                    />
                  </div>

                  <div className="col-md-4">
                    <Field
                      id={"mobile"}
                      name={`mobile`}
                      component={ReduxInput}
                      // placeholder="Last Name"
                      fieldName="Mobile Number"
                      type="number"
                      // labelText="Mobile Number"
                    />
                  </div>
                  <div className="col-md-4">
                    {/* <Field
                  id={"gender"}
                  name={`gender`}
                  component={ReduxSelect}
                  // placeholder="Last Name"
                  fieldName="gender"
                  labelText="Gender"
                  options={[
                    { Value: "1", Text: "one" },
                    { Value: "2", Text: "two" },
                  ]}
                /> */}
                    <Field
                      name="currentUser"
                      component={ReduxSelect}
                      options={userOptions}
                      fieldName="Current User"
                      labelText={"Current User*"}
                      // validate={Validator.required}
                      isReturnText={false}
                      multiple={true}
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <Field
                      name="description"
                      component={ReduxTextarea}
                      fieldName="Description"
                      labelText={"Description*"}
                      rows={"4"}
                      multiline={true}
                      // validate={Validator.required}
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <Field
                      name="description2"
                      component={ReduxTextarea}
                      fieldName="Description"
                      labelText={"Description*"}
                      // validate={Validator.required}
                      multiline={true}
                      rowsMax={"50"}
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <Field
                      name="date"
                      component={ReduxDateInput}
                      fieldName="date"
                      labelText={"Date*"}
                      validate={Validator.required}
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <Field
                      name="time"
                      component={ReduxTimeInput}
                      fieldName="time"
                      labelText={"Time*"}
                      validate={Validator.required}
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <Field
                      name="dollar"
                      component={ReduxDollarInput}
                      fieldName="Dollar"
                      labelText={"Dollar*"}
                      type="number"
                      // validate={Validator.required}
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    {/* <Field
                  id={"gender"}
                  name={`gender`}
                  component={ReduxSelect}
                  // placeholder="Last Name"
                  fieldName="gender"
                  labelText="Gender"
                  options={[
                    { Value: "1", Text: "one" },
                    { Value: "2", Text: "two" },
                  ]}
                /> */}
                    <Field
                      name="hobbies"
                      component={ReduxMultiSelect}
                      // options={["Guitar", "Cycling", "Hiking"]}
                      options={[
                        { Value: 1, Text: "Guitar" },
                        { Value: 2, Text: "Cycling" },
                        { Value: 3, Text: "Hiking" },
                      ]}
                      fieldName="hobbies"
                      labelText={"Hobbies*"}
                      // validate={Validator.required}
                      isReturnText={false}
                      // multiple={true}
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <Field
                      name="isMain"
                      type="text"
                      component={ReduxToggle}
                      color="primary"
                      labelText={"Is Main"}
                      validate={Validator.required}
                      fieldName={"Is Main"}
                    />
                  </div>
                  <div class="col-12 col-md-12 col-lg-12 mt-3">
                    <div class="form-group">
                      <Field
                        className="mr-2"
                        name="isPublished"
                        component={ReduxCheckbox}
                        fieldName={"isPublished"}
                        labelText={"Publish on Sober Peer"}
                        validate={Validator.required}
                        color="primary"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-12 col-lg-12 mt-3">
                    <div class="form-group">
                      <Field
                        className="mr-2"
                        name="autoComplete"
                        component={ReduxAutoComplete}
                        fieldName={"AutoComplete"}
                        labelText={"Auto Complete"}
                        validate={Validator.required}
                        color="primary"
                        suggestions={suggestions}
                        placeholder="Auto Complete"
                      />
                    </div>
                  </div>

                  {/* <div class="form-group">
                <label class="custom-control-label" for="is-emergency-contact">
                  {"   "}
                  Lead Story
                </label>
              </div> */}
                </div>

                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Submit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    disabled={pristine || submitting}
                    onClick={reset}
                  >
                    Clear Values
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "MaterialUiForm",
  // initialValues: {
  //   currentUser: "2",
  //   date: "2022-02-17",
  //   dollar: "500",
  //   firstName: "yh",
  //   hobbies: [1, 2, 3],
  //   time: "15:35",
  //   mobile: "132",
  //   description: "hello",
  //   description2: "hello2",
  // },
})(connect()(MyPage3));
