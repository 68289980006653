import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import SVG from "react-inlinesvg";
import Drawer from "@material-ui/core/Drawer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
    getAllJournal,
    getAllJournalComments
} from "../../../../redux/actions";
import _Close from "../../../images/close.svg";
import ChatComments from "../../../pages/clientChartTabs/forms/chatComments";
import { JournalCard } from "../../../components/custom-cards";

export const JournalDrawer = props => {
    const dispatch = useDispatch();

    const [drawerState, setDrawerState] = useState({
        right: false
    });

    const chatDataGet = ClientJournalGuid => {
        let data = {
            Journal: [
                {
                    ClientId: props.ID,
                    ClientJournalGuid: ClientJournalGuid
                }
            ]
        };

        dispatch(getAllJournalComments(data));
    };

    const toggleCommentDrawer = (side, open, ClientJournalGuid) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        if (typeof ClientJournalGuid === "string") {
            chatDataGet(ClientJournalGuid);
        }
        setDrawerState({ ...drawerState, [side]: open });
    };

    useEffect(() => {
        let data = {
            Journal: [
                {
                    ClientId: props.ID
                }
            ]
        };

        dispatch(getAllJournal(data));
    }, []);

    const { journalList } = useSelector(
        ({ get_all_journal: { data: journalData, isFetching, error } }) => {
            return {
                journalList: journalData?.Data?.Journals
            };
        },
        shallowEqual
    );

    return (
        <>
            <div className={`card card-custom custom-tabs`}>
                {/* Head */}
                <div className="card-header border-1">
                    <h3>Journal Drawer</h3>
                    <OverlayTrigger
                        overlay={<Tooltip id="close-tooltip">Close</Tooltip>}
                    >
                        <a
                            className="btn btn-icon btn-hover-light-primary btn-sm"
                            onClick={props.toggleDrawer("right", false, {})}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl(_Close)} />
                            </span>
                        </a>
                    </OverlayTrigger>
                </div>
                {/* Body */}
                <div className="card-body pt-2">
                    <div className="journal-tabs">
                        <div className="posts">
                            {journalList &&
                                journalList.map((item, index) => {
                                    return (
                                        <>
                                            <JournalCard
                                                data={item}
                                                index={index}
                                                toggleDrawer={
                                                    toggleCommentDrawer
                                                }
                                                key={uuid()}
                                            />
                                        </>
                                    );
                                })}
                        </div>
                        {drawerState.right && (
                            <Drawer
                                anchor="right"
                                open={drawerState.right}
                                onClose={toggleCommentDrawer("right", false)}
                            >
                                <div
                                    role="presentation"
                                    style={{ width: "800px" }}
                                >
                                    <ChatComments
                                        toggleDrawer={toggleCommentDrawer}
                                        ID={props.ID}
                                    />
                                </div>
                            </Drawer>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
