import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* assignedCourseListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.assignedCourseListGet(payload);
    yield put({
      type: ActionTypes.ASSIGNED_COURSE_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ASSIGNED_COURSE_LIST_GET_FAILURE,
    });
  }
}

export function* courseAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.courseAssign(payload);
    yield put({
      type: ActionTypes.COURSE_ASSIGN_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_ASSIGN_FAILURE,
    });
  }
}

export function* courseUnassign(action) {
  try {
    const { payload } = action;
    const response = yield API.courseUnassign(payload);
    yield put({
      type: ActionTypes.COURSE_UNASSIGN_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_UNASSIGN_FAILURE,
    });
  }
}

export function* courseMarkAsComplete(action) {
  try {
    const { payload } = action;
    const response = yield API.courseMarkAsComplete(payload);
    yield put({
      type: ActionTypes.COURSE_MARK_AS_COMPLETE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_MARK_AS_COMPLETE_FAILURE,
    });
  }
}

export function* courseOptionGet(action) {
  try {
    const { payload } = action;
    const response = yield API.courseOptionGet(payload);
    yield put({
      type: ActionTypes.COURSE_OPTION_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_OPTION_GET_FAILURE,
    });
  }
}

export function* courseUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.courseUpsert(payload);
    yield put({
      type: ActionTypes.COURSE_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_UPSERT_FAILURE,
    });
  }
}

export function* courseGet(action) {
  try {
    const { payload } = action;
    const response = yield API.courseGet(payload);
    yield put({
      type: ActionTypes.COURSE_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_GET_FAILURE,
    });
  }
}

export function* courseListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.courseListGet(payload);
    yield put({
      type: ActionTypes.COURSE_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_LIST_GET_FAILURE,
    });
  }
}

export function* courseStatusUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.courseStatusUpdate(payload);
    yield put({
      type: ActionTypes.COURSE_STATUS_UPDATE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_STATUS_UPDATE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.ASSIGNED_COURSE_LIST_GET_REQUEST,
      assignedCourseListGet
    ),
    takeLatest(ActionTypes.COURSE_ASSIGN_REQUEST, courseAssign),
    takeLatest(ActionTypes.COURSE_UNASSIGN_REQUEST, courseUnassign),
    takeLatest(
      ActionTypes.COURSE_MARK_AS_COMPLETE_REQUEST,
      courseMarkAsComplete
    ),
    takeLatest(ActionTypes.COURSE_OPTION_GET_REQUEST, courseOptionGet),
    takeLatest(ActionTypes.COURSE_UPSERT_REQUEST, courseUpsert),
    takeLatest(ActionTypes.COURSE_GET_REQUEST, courseGet),
    takeLatest(ActionTypes.COURSE_LIST_GET_REQUEST, courseListGet),
    takeLatest(ActionTypes.COURSE_STATUS_UPDATE_REQUEST, courseStatusUpdate),
  ]);
}
