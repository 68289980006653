import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../../../_metronic/_partials/dropdowns";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";

import GenericTable from "../../../components/generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  dynamicGridDataGet,
} from "../../../../redux/actions";
import { MemberTrendsFormatter } from "../../column-formatters";
// import { Chat } from "../../custom-component/Chating";
// import { TwilioCalling } from "../../custom-component/TwilioCalling";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";
import { MemberTrendDrawer } from "./member-drawer";
const { SearchBar, ClearSearchButton } = Search;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const MemberTrends = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (side, open, row) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    if (open == true) {
      history.push(
        `/member-trends/?id=${row.UserGuid}&drawer=true&tab=emotional-affect`
      );
      // history.push("/member-trends/?drawer=true&tab=emotional-affect");
    } else {
      history.push("/member-trends");
    }
  };

  useEffect(() => {
    var param = new URLSearchParams(history.location.search);
    var isDrawerOpen = param.get("drawer");

    if (isDrawerOpen == "true") {
      setState({
        right: true,
      });
    }
    dispatch(
      dynamicGridDataGet({
        Json: JSON.stringify({
          ComponentGrid: [
            {
              Name: "MemberTrend",
            },
          ],
        }),
      })
    );

    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "MemberTrend",
          },
        ],
      })
    );
  }, []);

  const { dynamicGridColumnData, isFetching, dynamicGridData } = useSelector(
    (state) => {
      return {
        dynamicGridData:
          state.dynamic_grid_data_get.data &&
          state.dynamic_grid_data_get.data.Data,

        isFetching: state.dynamic_grid_data_get.isFetching,

        dynamicGridColumnData:
          state.dynamic_grid_column_configuration_get.data &&
          state.dynamic_grid_column_configuration_get.data[0],
      };
    },
    shallowEqual
  );
  const {
    PageSize,
    IsRowClick,
    IsExcelExport,
    IsColumnSelectionEnable,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = dynamicGridData || [];

  // const { Trend } = advisorMemberTrendsData || {};
  const enableSorting = true;

  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Member Trends");

  return (
    <>
      <div className="container">
        <div className="member-trend">
          {Columns && DataSource && (
            <GenericTable
              // tableTitle="Member Trends"
              entities={DataSource || []}
              columns={Columns || []}
              isCardHeader={false}
              pagination={true}
              isLoading={isFetching}
              bordered={false}
              // selectRow={selectRow}
              // rowEvents={rowEvents}
              tableOptions={{
                PageSize,
                IsRowClick,
                IsExcelExport,
                IsColumnSelectionEnable,
              }}
              toggleDrawer={toggleDrawer}
            />
          )}
        </div>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          <div role="presentation" style={{ width: "800px" }}>
            <MemberTrendDrawer />
          </div>
        </Drawer>
      </div>
    </>
  );
};
