import { ActionTypes } from "../constants";
import API from "../requests";

const listStudents = (payload) => {
  return {
    type: ActionTypes.STUDENTS_REQUEST,
    payload: payload,
  };
};

const resetAddRequest = () => {
  return {
    type: ActionTypes.STUDENT_ADD_RESET,
  };
};

const forgetPassword = (payload) => {
  return {
    type: ActionTypes.FORGET_PASSWORD_REQUEST,
    payload: payload,
  };
};

const resetPassword = (payload) => {
  return {
    type: ActionTypes.RESET_PASSWORD_REQUEST,
    payload: payload,
  };
};

const addStudent = (payload) => {
  return {
    type: ActionTypes.STUDENT_ADD_REQUEST,
    payload: payload,
  };
};

const addStudentNote = (payload) => {
  return {
    type: ActionTypes.STUDENT_NOTE_ADD_REQUEST,
    payload: payload,
    method: API["addStudentNote"],
    successType: ActionTypes.STUDENT_NOTE_ADD_SUCCESS,
    failureType: ActionTypes.STUDENT_NOTE_ADD_FAILURE,
  };
};

const resetStudentNote = (payload) => {
  return {
    type: ActionTypes.STUDENT_NOTE_ADD_RESET,
    payload: payload,
  };
};

const upsertStudentPin = (payload) => {
  return {
    type: ActionTypes.STUDENT_PIN_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetUpsertPinRequest = () => {
  return {
    type: ActionTypes.STUDENT_PIN_UPSERT_RESET,
  };
};

const validateStudentPin = (payload) => {
  return {
    type: ActionTypes.STUDENT_VALIDATE_PIN_REQUEST,
    payload: payload,
  };
};

const resetValidatePin = (payload) => {
  return {
    type: ActionTypes.STUDENT_VALIDATE_PIN_RESET,
    payload: payload,
  };
};

const resetGetStudent = () => {
  return {
    type: ActionTypes.STUDENT_GET_RESET,
  };
};

const getStudent = (id) => {
  return {
    type: ActionTypes.STUDENT_GET_REQUEST,
    payload: { studentId: id },
  };
};

const getDashboard = () => {
  return {
    type: ActionTypes.DASHBOARD_GET_REQUEST,
    payload: {},
  };
};

const resetLogin = (payload) => {
  return {
    type: ActionTypes.LOGIN_RESET,
    payload: payload,
  };
};

const listCombos = (payload) => {
  return {
    type: ActionTypes.COMBOS_REQUEST,
    payload: payload,
  };
};

const listFilterCombo = (payload) => {
  return {
    type: ActionTypes.FILTER_COMBO_REQUEST,
    payload: payload,
  };
};
const listSmartCombos = (payload) => {
  return {
    type: ActionTypes.SMARTCOMBOS_REQUEST,
    payload: payload,
  };
};

const listFullCombos = (payload) => {
  return {
    type: "COMBOS_FULL_REQUEST",
    payload: payload,
  };
};

const listTodos = () => {
  return {
    type: ActionTypes.TODO_GET_ALL_REQUEST,
  };
};

const listTodosByStudent = (payload) => {
  return {
    type: ActionTypes.TODO_GET_ALL_BY_STUDENT_REQUEST,
    payload: payload,
  };
};

const addTodo = (payload) => {
  return {
    type: ActionTypes.STUDENT_ADD_REQUEST,
    payload: payload,
  };
};

const studentDischarge = (payload) => {
  return {
    type: ActionTypes.STUDENT_DISCHARGE_REQUEST,
    payload: payload,
  };
};
const resetStudentDischarge = (payload) => {
  return {
    type: ActionTypes.STUDENT_DISCHARGE_RESET,
    payload: payload,
  };
};
const studentReinstate = (payload) => {
  return {
    type: ActionTypes.STUDENT_REINSTATE_REQUEST,
    payload: payload,
  };
};
const resetStudentReinstate = (payload) => {
  return {
    type: ActionTypes.STUDENT_REINSTATE_RESET,
    payload: payload,
  };
};
const listActivities = () => {
  return {
    type: ActionTypes.ACTIVITY_GET_ALL_REQUEST,
  };
};

const listActivitiesByStudent = (payload) => {
  return {
    type: ActionTypes.ACTIVITY_GET_ALL_BY_STUDENT_REQUEST,
    payload: payload,
  };
};

const studentUnAssign = (payload) => {
  return {
    type: ActionTypes.STUDENT_UNASSIGN_REQUEST,
    payload: payload,
  };
};

const resetStudentUnAssign = (payload) => {
  return {
    type: ActionTypes.STUDENT_UNASSIGN_RESET,
    payload: payload,
  };
};

const studentMarkComplete = (payload) => {
  return {
    type: ActionTypes.STUDENT_MARK_COMPLETE_REQUEST,
    payload: payload,
  };
};

const resetStudentMarkComplete = (payload) => {
  return {
    type: ActionTypes.STUDENT_MARK_COMPLETE_RESET,
    payload: payload,
  };
};

const getReportData = () => {
  return {
    type: ActionTypes.GET_REPORT_DATA_REQUEST,
  };
};

const adminstrationStaffDelete = (payload) => {
  return {
    type: ActionTypes.STAFF_DELETE_REQUEST,
    payload: payload,
  };
};

const resetAdminstrationStaffDelete = (payload) => {
  return {
    type: ActionTypes.STAFF_DELETE_RESET,
    payload: payload,
  };
};

const staffListGet = (payload) => {
  return {
    type: ActionTypes.STAFF_LIST_GET_REQUEST,
    payload: payload,
  };
};

const staffGet = (payload) => {
  return {
    type: ActionTypes.STAFF_GET_REQUEST,
    payload: payload,
  };
};

const resetStaffGet = (payload) => {
  return {
    type: ActionTypes.STAFF_GET_RESET,
    payload: payload,
  };
};

const staffUpsert = (payload) => {
  return {
    type: ActionTypes.STAFF_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetStaffUpsert = (payload) => {
  return {
    type: ActionTypes.STAFF_UPSERT_RESET,
    payload: payload,
  };
};

const staffMobileAppInviteSend = (payload) => {
  return {
    type: ActionTypes.STAFF_MOBILE_APP_INVITE_SEND_REQUEST,
    payload: payload,
  };
};

const staffDelete = (payload) => {
  return {
    type: ActionTypes.STAFF_DELETE_REQUEST,
    payload: payload,
  };
};
const resetStaffDelete = () => {
  return {
    type: ActionTypes.STAFF_DELETE_RESET,
  };
};

const updateStaffEmail = (payload) => {
  return {
    type: ActionTypes.UPDATE_STAFF_EMAIL_REQUEST,
    payload: payload,
  };
};

const updateStaffPassword = (payload) => {
  return {
    type: ActionTypes.UPDATE_STAFF_PASSWORD_REQUEST,
    payload: payload,
  };
};

const resetStaffEmail = (payload) => {
  return {
    type: ActionTypes.UPDATE_STAFF_EMAIL_RESET,
    payload: payload,
  };
};

const resetStaffPassword = (payload) => {
  return {
    type: ActionTypes.UPDATE_STAFF_PASSWORD_RESET,
    payload: payload,
  };
};

const updateStaffCenter = (payload) => {
  return {
    type: ActionTypes.UPDATE_STAFF_CENTER_REQUEST,
    payload: payload,
  };
};

const resetStaffCenter = (payload) => {
  return {
    type: ActionTypes.UPDATE_STAFF_CENTER_RESET,
    payload: payload,
  };
};

const uploadStaffImage = (payload) => {
  return {
    type: ActionTypes.UPLOAD_STAFF_IMAGE_REQUEST,
    payload: payload,
  };
};

const getStaffShiftLog = (payload) => {
  return {
    type: ActionTypes.GET_STAFF_SHIFT_LOG_REQUEST,
    payload: payload,
  };
};

const addStaffShiftLog = (payload) => {
  return {
    type: ActionTypes.ADD_STAFF_SHIFT_LOG_REQUEST,
    payload: payload,
  };
};

const resetStaffShiftLog = (payload) => {
  return {
    type: ActionTypes.ADD_STAFF_SHIFT_LOG_RESET,
    payload: payload,
  };
};

const staffReactivate = (payload) => {
  return {
    type: ActionTypes.STAFF_REACTIVATE_REQUEST,
    payload: payload,
  };
};
const resetStaffReactivate = () => {
  return {
    type: ActionTypes.STAFF_REACTIVATE_RESET,
  };
};

const isStaffProBio = (payload) => {
  return {
    type: ActionTypes.CHECK_STAFF_PRO_BIO_REQUEST,
    payload: payload,
  };
};
const updateStaffProBio = (payload) => {
  return {
    type: ActionTypes.UPDATE_STAFF_PRO_BIO_REQUEST,
    payload: payload,
  };
};
const resetStaffProBio = () => {
  return {
    type: ActionTypes.UPDATE_STAFF_PRO_BIO_RESET,
  };
};

const staffPermission = (payload) => {
  return {
    type: ActionTypes.GET_STAFF_PERMISSION_REQUEST,
    payload: payload,
  };
};

const updateStaffPermission = (payload) => {
  return {
    type: ActionTypes.UPDATE_STAFF_PERMISSION_REQUEST,
    payload: payload,
  };
};

const resetUpdateStaffPermission = (payload) => {
  return {
    type: ActionTypes.UPDATE_STAFF_PERMISSION_RESET,
    payload: payload,
  };
};

const logDevice = (payload) => {
  return {
    type: ActionTypes.LOG_DEVICE_REQUEST,
    payload: payload,
  };
};

const logError = (payload) => {
  return {
    type: ActionTypes.LOG_ERROR_REQUEST,
    payload: payload,
  };
};

const announcementGetAll = (payload) => {
  return {
    type: ActionTypes.ANNOUNCEMENT_GET_ALL_REQUEST,
    payload: payload,
  };
};

const announcementGetJson = (payload) => {
  return {
    type: ActionTypes.ANNOUNCEMENT_GET_JSON_REQUEST,
    payload: payload,
  };
};

const announcementGetJsonReset = (payload) => {
  return {
    type: ActionTypes.ANNOUNCEMENT_GET_JSON_RESET,
    payload: payload,
  };
};

const announcementUpsert = (payload) => {
  return {
    type: ActionTypes.ANNOUNCEMENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetAnnouncementUpsert = (payload) => {
  return {
    type: ActionTypes.ANNOUNCEMENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const announcementDelete = (payload) => {
  return {
    type: ActionTypes.ANNOUNCEMENT_DELETE_REQUEST,
    payload: payload,
  };
};

const resetAnnouncementDelete = () => {
  return {
    type: ActionTypes.ANNOUNCEMENT_DELETE_RESET,
  };
};

const loadMenu = (payload) => {
  return {
    type: ActionTypes.LOAD_MENU_REQUEST,
    payload: payload,
  };
};

const logOut = (payload) => {
  return {
    type: ActionTypes.LOGOUT_REQUEST,
    payload: payload,
  };
};

const getStudentProfileDashboardData = (payload) => {
  return {
    type: ActionTypes.STUDENT_GET_PROFILE_DASHBOARD_DATA_REQUEST,
    payload: payload,
  };
};

const getStudentsSearch = (payload) => {
  return {
    type: ActionTypes.STUDENTS_SEARCH_REQUEST,
    payload: payload,
  };
};

const resetStudentsSearch = (payload) => {
  return {
    type: ActionTypes.STUDENTS_SEARCH_RESET,
    payload: payload,
  };
};

const getUserRole = (payload) => {
  return {
    type: ActionTypes.USER_ROLE_LIST_REQUEST,
    payload: payload,
  };
};

const getListJobTitle = (payload) => {
  return {
    type: ActionTypes.LIST_JOB_TITLE_REQUEST,
    payload: payload,
  };
};

const updateJobTitle = (payload) => {
  return {
    type: ActionTypes.UPDATE_JOB_TITLE_REQUEST,
    payload: payload,
  };
};

const updateRole = (payload) => {
  return {
    type: ActionTypes.UPDATE_ROLE_REQUEST,
    payload: payload,
  };
};

const resetUpdateJobTitle = (payload) => {
  return {
    type: ActionTypes.UPDATE_JOB_TITLE_RESET,
    payload: payload,
  };
};

const resetUpdateRole = (payload) => {
  return {
    type: ActionTypes.UPDATE_ROLE_RESET,
    payload: payload,
  };
};

const deleteRole = (payload) => {
  return {
    type: ActionTypes.DELETE_ROLE_REQUEST,
    payload: payload,
  };
};

const deleteJobTitle = (payload) => {
  return {
    type: ActionTypes.DELETE_JOB_TITLE_REQUEST,
    payload: payload,
  };
};

const resetDeleteRole = (payload) => {
  return {
    type: ActionTypes.DELETE_ROLE_RESET,
    payload: payload,
  };
};

const resetDeleteJobTitle = (payload) => {
  return {
    type: ActionTypes.DELETE_JOB_TITLE_RESET,
    payload: payload,
  };
};

const updateRolePermissions = (payload) => {
  return {
    type: ActionTypes.UPDATE_ROLE_PERMISSIONS_REQUEST,
    payload: payload,
  };
};

const updateJobTitleSync = (payload) => {
  return {
    type: ActionTypes.UPDATE_JOB_TITLE_SYNC_REQUEST,
    payload: payload,
  };
};

const resetUpdateRolePermissions = (payload) => {
  return {
    type: ActionTypes.UPDATE_ROLE_PERMISSIONS_RESET,
    payload: payload,
  };
};

const resetUpdateJobTitleSync = (payload) => {
  return {
    type: ActionTypes.UPDATE_JOB_TITLE_SYNC_RESET,
    payload: payload,
  };
};

const userFeedback = (payload) => {
  return {
    type: ActionTypes.USER_FEEDBACK_REQUEST,
    payload: payload,
  };
};

const removeUserFeedback = (payload) => {
  return {
    type: ActionTypes.USER_FEEDBACK_RESET,
    payload: payload,
  };
};

const isLicenseAvailable = (payload) => {
  return {
    type: ActionTypes.IS_LICENSE_AVAILABLE_REQUEST,
    payload: payload,
  };
};

const getDashboardLoadAppUsers = (payload) => {
  return {
    type: ActionTypes.GET_DASHBOARD_LOAD_APP_USERS_REQUEST,
    payload: payload,
  };
};

const getValidateImage = (payload) => {
  return {
    type: ActionTypes.GET_VALIDATE_IMAGE_REQUEST,
    payload: payload,
  };
};

const resetGetValidateImage = (payload) => {
  return {
    type: ActionTypes.GET_VALIDATE_IMAGE_RESET,
    payload: payload,
  };
};

const getLicenseActivites = (payload) => {
  return {
    type: ActionTypes.GET_LICENSE_ACTIVITES_REQUEST,
    payload: payload,
  };
};

const getCurrentLicenses = (payload) => {
  return {
    type: ActionTypes.GET_CURRENT_LICENSES_REQUEST,
    payload: payload,
  };
};
const getLicensesInvoice = (payload) => {
  return {
    type: ActionTypes.GET_LICENSES_INVOICE_REQUEST,
    payload: payload,
  };
};
const getLicensesReceipt = (payload) => {
  return {
    type: ActionTypes.GET_LICENSES_RECEIPT_REQUEST,
    payload: payload,
  };
};
const setCurrentLicenses = (payload) => {
  return {
    type: ActionTypes.GET_CURRENT_LICENSES_SET,
    payload,
  };
};

const addLicenses = (payload) => {
  return {
    type: ActionTypes.ADD_LICENSES_REQUEST,
    payload,
  };
};
const resetAddLicenses = (payload) => {
  return {
    type: ActionTypes.GET_PAYMENT_INFO_RESET,
    payload: payload,
  };
};
const getPaymentInfo = (payload) => {
  return {
    type: ActionTypes.GET_PAYMENT_INFO_REQUEST,
    payload,
  };
};
const isAchValidation = (payload) => {
  return {
    type: ActionTypes.IS_ACH_VALIDATION_REQUEST,
    payload,
  };
};
const achValidate = (payload) => {
  return {
    type: ActionTypes.ACH_VALIDATE_REQUEST,
    payload,
  };
};
const getBillingInfo = (payload) => {
  return {
    type: ActionTypes.GET_BILLING_INFO_REQUEST,
    payload,
  };
};
const updatePaymentMethode = (payload) => {
  return {
    type: ActionTypes.UPDATE_PAYMENT_METHODE_REQUEST,
    payload,
  };
};
const resetPaymentMethode = (payload) => {
  return {
    type: ActionTypes.UPDATE_PAYMENT_METHODE_RESET,
    payload,
  };
};
const addNewApplicant = (payload) => {
  return {
    type: ActionTypes.ADD_NEW_APPLICANT_REQUEST,
    payload,
  };
};

const getAppSubscribers = (payload) => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_REQUEST,
    payload,
  };
};
const getAppSubscribersDashboard = (payload) => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_DASHBOARD_REQUEST,
    payload,
  };
};
const getAppSubscribersProfile = (payload) => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_PROFILE_REQUEST,
    payload,
  };
};
const getAppSubscribersChat = (payload) => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_REQUEST,
    payload,
  };
};
const getAppSubscribersChatCoach = (payload) => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_COACH_REQUEST,
    payload,
  };
};
const getAppSubscribersActivities = (payload) => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_ACTIVITIES_REQUEST,
    payload,
  };
};

const addAppSubscribersNote = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_REQUEST,
    payload,
  };
};
const resetAddAppSubscribersNote = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_RESET,
    payload,
  };
};
const addAppSubscribersTask = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_ADD_TASK_REQUEST,
    payload,
  };
};
const deleteAppSubscribersTask = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_REQUEST,
    payload,
  };
};
const resetDeleteAppSubscribersTask = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_RESET,
    payload,
  };
};
const resetAppSubscribersTask = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_ADD_TASK_RESET,
    payload,
  };
};
const appSubscribersUserSearch = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_REQUEST,
    payload,
  };
};
const AppSubscribersCompleteTask = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_REQUEST,
    payload,
  };
};
const resetAppSubscribersCompleteTask = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_RESET,
    payload,
  };
};

const appSubscribersupdateCase = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_REQUEST,
    payload,
  };
};
const resetAppSubscribersupdateCase = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_RESET,
    payload,
  };
};

const listMobileAppRoleGet = (payload) => {
  return {
    type: ActionTypes.GET_LIST_MOBILE_APP_ROLE_REQUEST,
    payload: payload,
  };
};
const spListCombos = (payload) => {
  return {
    type: ActionTypes.SP_LIST_COMBOS_REQUEST,
    payload: payload,
  };
};
const getDynamicComos = (payload) => {
  return {
    type: ActionTypes.COMBOS_DYNAMIC_REQUEST,
    payload: payload,
  };
};
const appSubscribersMapHistory = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_MAP_HISTORY_REQUEST,
    payload: payload,
  };
};
const appInvite = (payload) => {
  return {
    type: ActionTypes.APP_INVITE_REQUEST,
    payload: payload,
  };
};

const getMobileAppDownloadStatus = (payload) => {
  return {
    type: ActionTypes.GET_MOBILE_APP_DOWNLOAD_STATUS_REQUEST,
    payload: payload,
  };
};
const resetGetMobileAppDownloadStatus = (payload) => {
  return {
    type: ActionTypes.GET_MOBILE_APP_DOWNLOAD_STATUS_RESET,
    payload: payload,
  };
};

const updateMobileAppRole = (payload) => {
  return {
    type: ActionTypes.UPDATE_MOBILE_APP_ROLE_REQUEST,
    payload: payload,
  };
};
const resetupdateMobileAppRole = (payload) => {
  return {
    type: ActionTypes.UPDATE_MOBILE_APP_ROLE_RESET,
    payload: payload,
  };
};
const addNoteType = (payload) => {
  return {
    type: ActionTypes.ADD_NOTE_TYPE_REQUEST,
    payload: payload,
  };
};
const resetAddNoteType = (payload) => {
  return {
    type: ActionTypes.ADD_NOTE_TYPE_RESET,
    payload: payload,
  };
};
const changeAppRole = (payload) => {
  return {
    type: ActionTypes.CHANGE_APP_ROLE_REQUEST,
    payload: payload,
  };
};
const resetChangeAppRole = (payload) => {
  return {
    type: ActionTypes.CHANGE_APP_ROLE_RESET,
    payload: payload,
  };
};
const twoStepAuth = (payload) => {
  return {
    type: ActionTypes.TWO_STEP_AUTH_REQUEST,
    payload: payload,
  };
};
const validatePasscode = (payload) => {
  return {
    type: ActionTypes.VALIDATE_PASS_CODE_REQUEST,
    payload: payload,
  };
};
const appInviteDashboard = (payload) => {
  return {
    type: ActionTypes.APP_INVITE_DASHBOARD_REQUEST,
    payload: payload,
  };
};

const getAllCallSession = (payload) => {
  return {
    type: ActionTypes.GET_ALL_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const addCallSession = (payload) => {
  return {
    type: ActionTypes.ADD_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const resetAddCallSession = (payload) => {
  return {
    type: ActionTypes.ADD_CALL_SESSION_RESET,
    payload: payload,
  };
};
const getAllVacation = (payload) => {
  return {
    type: ActionTypes.GET_ALL_VACATION_REQUEST,
    payload: payload,
  };
};
const addVacationDays = (payload) => {
  return {
    type: ActionTypes.ADD_VACATION_DAYS_REQUEST,
    payload: payload,
  };
};
const resetAddVacationDays = (payload) => {
  return {
    type: ActionTypes.ADD_VACATION_DAYS_RESET,
    payload: payload,
  };
};
const getOneCallTimeSlots = (payload) => {
  return {
    type: ActionTypes.GET_ONE_CALL_TIMESLOTS_REQUEST,
    payload: payload,
  };
};
const getOneCallSession = (payload) => {
  return {
    type: ActionTypes.GET_ONE_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const inviteOneCallSession = (payload) => {
  return {
    type: ActionTypes.INVITE_ONE_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const resetinviteOneCallSession = (payload) => {
  return {
    type: ActionTypes.INVITE_ONE_CALL_SESSION_RESET,
    payload: payload,
  };
};
const cancelOneCallSession = (payload) => {
  return {
    type: ActionTypes.CANCEL_ONE_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const resetCancelOneCallSession = (payload) => {
  return {
    type: ActionTypes.CANCEL_ONE_CALL_SESSION_RESET,
    payload: payload,
  };
};
const getStripeApiKey = (payload) => {
  return {
    type: ActionTypes.GET_STRIPE_API_KEY_REQUEST,
    payload: payload,
  };
};
const editGroupCallSession = (payload) => {
  return {
    type: ActionTypes.EDIT_GROUP_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const getCallSessionVideo = (payload) => {
  return {
    type: ActionTypes.GET_CALL_SESSION_VIDEO_REQUEST,
    payload: payload,
  };
};
const getCallSessionVideoUrl = (payload) => {
  return {
    type: ActionTypes.GET_CALL_SESSION_VIDEO_URL_REQUEST,
    payload: payload,
  };
};

const getReports = (payload) => {
  return {
    type: ActionTypes.GET_REPORTS_REQUEST,
    payload: payload,
  };
};
const getPromoCode = (payload) => {
  return {
    type: ActionTypes.GET_PROMO_CODE_REQUEST,
    payload: payload,
  };
};

const getCounselor = (payload) => {
  return {
    type: ActionTypes.GET_COUNSELOR_REQUEST,
    payload: payload,
  };
};
const getClientCounselor = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_COUNSELOR_REQUEST,
    payload: payload,
  };
};
const sendVoucherCode = (payload) => {
  return {
    type: ActionTypes.SEND_VOUCHER_CODE_REQUEST,
    payload: payload,
  };
};

const getSmallGroupUsers = (payload) => {
  return {
    type: ActionTypes.GET_SMALL_GROUP_USERS_REQUEST,
    payload: payload,
  };
};
const uploadLeaderPhoto = (payload) => {
  return {
    type: ActionTypes.UPLOAD_LEADER_PHOTO_REQUEST,
    payload: payload,
  };
};
const markSystemObservation = (payload) => {
  return {
    type: ActionTypes.MARK_SYSTEM_OBSERVATION_REQUEST,
    payload: payload,
  };
};
const appMarkSystemObservation = (payload) => {
  return {
    type: ActionTypes.APP_MARK_SYSTEM_OBSERVATION_REQUEST,
    payload: payload,
  };
};
const getAnalytics = (payload) => {
  return {
    type: ActionTypes.GET_ANALYTICS_REQUEST,
    payload: payload,
  };
};
const getActiveClient = (payload) => {
  return {
    type: ActionTypes.GET_ACTIVE_CLIENT_REQUEST,
    payload: payload,
  };
};
const isManageLicenseRequired = (payload) => {
  return {
    type: ActionTypes.IS_MANAGE_LICENSE_REQUIRED_REQUEST,
    payload: payload,
  };
};

const getNotes = (payload) => {
  return {
    type: ActionTypes.GET_NOTES_REQUEST,
    payload: payload,
  };
};
const getCategoryClient = (payload) => {
  return {
    type: ActionTypes.GET_CATEGORY_CLIENT_REQUEST,
    payload: payload,
  };
};
const getClientDemographic = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_DEMOGRAPHIC_REQUEST,
    payload: payload,
  };
};
const getCostOfCare = (payload) => {
  return {
    type: ActionTypes.GET_COST_OF_CARE_REQUEST,
    payload: payload,
  };
};
const getBehavioralRating = (payload) => {
  return {
    type: ActionTypes.GET_BEHAVIORAL_RATING_REQUEST,
    payload: payload,
  };
};
const getScoreImprovement = (payload) => {
  return {
    type: ActionTypes.GET_SCORE_IMPROVEMENT_REQUEST,
    payload: payload,
  };
};
const getProviderPerformance = (payload) => {
  return {
    type: ActionTypes.GET_PROVIDER_PERFORMANCE_REQUEST,
    payload: payload,
  };
};
const getTriggerLocationActivity = (payload) => {
  return {
    type: ActionTypes.GET_TRIGGER_LOCATION_REQUEST,
    payload: payload,
  };
};
const getServiceDelivered = (payload) => {
  return {
    type: ActionTypes.GET_SERVICE_DELIVERED_REQUEST,
    payload: payload,
  };
};
const getReferralSource = (payload) => {
  return {
    type: ActionTypes.GET_REFERRAL_SOURCE_REQUEST,
    payload: payload,
  };
};
const getStudentDocumentList = (payload) => {
  return {
    type: ActionTypes.GET_DOCUMENT_LIST_REQUEST,
    payload: payload,
  };
};
const archiveStudentDocument = (payload) => {
  return {
    type: ActionTypes.ARCHIVE_STUDENT_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const archiveDocumentReset = (payload) => {
  return {
    type: ActionTypes.ARCHIVE_STUDENT_DOCUMENT_RESET,
    payload: payload,
  };
};
const uploadDocument = (payload) => {
  return {
    type: ActionTypes.UPLOAD_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const uploadDocumentReset = (payload) => {
  return {
    type: ActionTypes.UPLOAD_DOCUMENT_RESET,
    payload: payload,
  };
};
const downloadStudentDocument = (payload) => {
  return {
    type: ActionTypes.DOWNLOAD_DOCUMENT_REQUEST,
    payload: payload,
  };
};

const resetNewsUpsert = (payload) => {
  return {
    type: ActionTypes.NEWS_UPSERT_RESET,
    payload: payload,
  };
};
const newsUpsert = (payload) => {
  return {
    type: ActionTypes.NEWS_UPSERT_REQUEST,
    payload: payload,
  };
};
const getListNews = (payload) => {
  return {
    type: ActionTypes.GET_LIST_NEWS_REQUEST,
    payload: payload,
  };
};
const getNews = (payload) => {
  return {
    type: ActionTypes.GET_NEWS_REQUEST,
    payload: payload,
  };
};
const deleteNews = (payload) => {
  return {
    type: ActionTypes.DELETE_NEWS_REQUEST,
    payload: payload,
  };
};
const getEmbedInfo = (payload) => {
  return {
    type: ActionTypes.GET_EMBED_INFO_REQUEST,
    payload: payload,
  };
};
const getPatientList = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_LIST_REQUEST,
    payload: payload,
  };
};
const getPatientDetail = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_DETAIL_REQUEST,
    payload: payload,
  };
};
const upsertPatientEnrollment = (payload) => {
  return {
    type: ActionTypes.UPSERT_PATIENT_ENROLLMENT_REQUEST,
    payload: payload,
  };
};
const resetUpsertPatientEnrollment = (payload) => {
  return {
    type: ActionTypes.UPSERT_PATIENT_ENROLLMENT_RESET,
    payload: payload,
  };
};
const upsertClientInsurance = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_INSURANCE_REQUEST,
    payload: payload,
  };
};
const resetUpsertClientInsurance = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_INSURANCE_RESET,
    payload: payload,
  };
};
const upsertClientMedical = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_MEDICAL_REQUEST,
    payload: payload,
  };
};
const resetUpsertClientMedical = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_MEDICAL_RESET,
    payload: payload,
  };
};
const upsertClientPayment = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_PAYMENT_REQUEST,
    payload: payload,
  };
};
const resetUpsertClientPayment = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_PAYMENT_RESET,
    payload: payload,
  };
};

const upsertProviderEnrollment = (payload) => {
  return {
    type: ActionTypes.UPSERT_PROVIDER_ENROLLMENT_REQUEST,
    payload: payload,
  };
};

const resetUpsertProviderEnrollment = (payload) => {
  return {
    type: ActionTypes.UPSERT_PROVIDER_ENROLLMENT_RESET,
    payload: payload,
  };
};

const upsertAppointment = (payload) => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const listAppointment = (payload) => {
  return {
    type: ActionTypes.LIST_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const getAppointment = (payload) => {
  return {
    type: ActionTypes.GET_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const deleteAppointment = (payload) => {
  return {
    type: ActionTypes.DELETE_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const medicateCombos = (payload) => {
  return {
    type: ActionTypes.COMBOS_MEDICATE_REQUEST,
    payload: payload,
  };
};

const practitionerCombos = (payload) => {
  return {
    type: ActionTypes.COMBOS_PRACTITIONER_REQUEST,
    payload: payload,
  };
};
const practitionerCombosOffice = (payload) => {
  return {
    type: ActionTypes.COMBOS_PRACTITIONER_OFFICE_REQUEST,
    payload: payload,
  };
};

const upsertGroup = (payload) => {
  return {
    type: ActionTypes.UPSERT_GROUP_REQUEST,
    payload: payload,
  };
};
const listGroup = (payload) => {
  return {
    type: ActionTypes.LIST_GROUP_REQUEST,
    payload: payload,
  };
};

const getGroup = (payload) => {
  return {
    type: ActionTypes.GET_GROUP_REQUEST,
    payload: payload,
  };
};

const upsertClientGroup = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_GROUP_REQUEST,
    payload: payload,
  };
};
const getClientGroup = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_GROUP_REQUEST,
    payload: payload,
  };
};
const resetGetClientGroup = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_GROUP_RESET,
    payload: payload,
  };
};

const upsertGroupAppointment = (payload) => {
  return {
    type: ActionTypes.UPSERT_GROUP_APPOINTMENT_REQUEST,
    payload: payload,
  };
};

const upsertGroupAppointmentGroupAllInfoJson = (payload) => {
  return {
    type: ActionTypes.UPSERT_GROUP_APPOINTMENT_GROUP_ALL_INFO_JSON_REQUEST,
    payload: payload,
  };
};
const deleteGroup = (payload) => {
  return {
    type: ActionTypes.DELETE_GROUP_REQUEST,
    payload: payload,
  };
};

const getListClientGroup = (payload) => {
  return {
    type: ActionTypes.GET_LIST_CLIENT_GROUP_REQUEST,
    payload: payload,
  };
};

const patientComboGroup = (payload) => {
  return {
    type: ActionTypes.PATIENT_COMBO_GROUP_REQUEST,
    payload: payload,
  };
};

const deactivateGroupAppointment = (payload) => {
  return {
    type: ActionTypes.DEACTIVATE_GROUP_APPOINTMENT_REQUEST,
    payload: payload,
  };
};

const upsertClientConsent = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const listClientConsent = (payload) => {
  return {
    type: ActionTypes.LIST_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const deactivateClientConsent = (payload) => {
  return {
    type: ActionTypes.DEACTIVATE_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const deactivateClientScreening = (payload) => {
  return {
    type: ActionTypes.DEACTIVATE_CLIENT_SCREENING_REQUEST,
    payload: payload,
  };
};
const loadJudicialDashboard = (payload) => {
  return {
    type: ActionTypes.LOAD_JUDICIAL_DASHBOARD_REQUEST,
    payload: payload,
  };
};
const getJudicialUserList = (payload) => {
  return {
    type: ActionTypes.GET_JUDICIAL_USER_LIST_REQUEST,
    payload: payload,
  };
};
const UploadDocumentUsingMultiPart = (payload) => {
  return {
    type: ActionTypes.UPLOAD_DOCUMENT_USING_MULTIPART_REQUEST,
    payload: payload,
  };
};
const upsertClientDocument = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const upsertClientDocumentReset = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_DOCUMENT_RESET,
    payload: payload,
  };
};
const listClientDocument = (payload) => {
  return {
    type: ActionTypes.LIST_CLIENT_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const downloadClientDocument = (payload) => {
  return {
    type: ActionTypes.DOWNLOAD_CLIENT_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const archiveClientDocument = (payload) => {
  return {
    type: ActionTypes.ARCHIVE_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const listGroupMembership = (payload) => {
  return {
    type: ActionTypes.LIST_GROUP_MEMBERSHIP_REQUEST,
    payload: payload,
  };
};

const getConsentFormTemplate = (payload) => {
  return {
    type: ActionTypes.GET_CONSENT_FORM_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const getComboFacility = (payload) => {
  return {
    type: ActionTypes.GET_COMBO_FACEILITY_REQUEST,
    payload: payload,
  };
};

const downloadClientConsent = (payload) => {
  return {
    type: ActionTypes.DOWNLOAD_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const comboPractitionerAll = (payload) => {
  return {
    type: ActionTypes.COMBO_PRACTITIONER_ALL_REQUEST,
    payload: payload,
  };
};
const comboFacilityAll = (payload) => {
  return {
    type: ActionTypes.COMBO_FACILITY_ALL_REQUEST,
    payload: payload,
  };
};
const saveClientConsent = (payload) => {
  return {
    type: ActionTypes.SAVE_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const getTreatmentPlan = (payload) => {
  return {
    type: ActionTypes.GET_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const getDiagnosis = (payload) => {
  return {
    type: ActionTypes.GET_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const upsertTreatmentPlanProblem = (payload, treatmentPlanDimensionId) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_PROBLEM_REQUEST,
    payload: payload,
    treatmentPlanDimensionId,
  };
};
const upsertMedicalAnalysis = (payload) => {
  return {
    type: ActionTypes.UPSERT_MEDICAL_ANALYSIS_REQUEST,
    payload: payload,
  };
};
const resetUpsertMedicalAnalysis = (payload) => {
  return {
    type: ActionTypes.UPSERT_MEDICAL_ANALYSIS_RESET,
    payload: payload,
  };
};
const upsertTreatmentPlanGoal = (payload, treatmentPlanDimensionId) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_GOAL_REQUEST,
    payload: payload,
    treatmentPlanDimensionId,
  };
};
const upsertTreatmentPlanObjective = (payload, treatmentPlanDimensionId) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_OBJECTIVE_REQUEST,
    payload: payload,
    treatmentPlanDimensionId,
  };
};
const upsertTreatmentPlanIntervention = (payload, treatmentPlanDimensionId) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_INTERVENTION_REQUEST,
    payload: payload,
    treatmentPlanDimensionId,
  };
};
const deleteTreatmentPlan = (payload) => {
  return {
    type: ActionTypes.DELETE_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const getDiagnosisDescription = (payload) => {
  return {
    type: ActionTypes.GET_DIAGNOSIS_DESCRIPTION_REQUEST,
    payload: payload,
  };
};
const getDiagnosisCode = (payload) => {
  return {
    type: ActionTypes.GET_DIAGNOSIS_CODE_REQUEST,
    payload: payload,
  };
};
const listTreatmentPlan = (payload) => {
  return {
    type: ActionTypes.LIST_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const resetListTreatmentPlan = (payload) => {
  return {
    type: ActionTypes.LIST_TREATMENT_PLAN_RESET,
    payload: payload,
  };
};
const comboProblemTemplate = (payload) => {
  return {
    type: ActionTypes.COMBO_PROBLEM_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const comboGoalTemplate = (payload) => {
  return {
    type: ActionTypes.COMBO_GOAL_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const recurringCount = (payload) => {
  return {
    type: ActionTypes.RECURRING_COUNT_REQUEST,
    payload: payload,
  };
};
const loadCaseProfile = (payload) => {
  return {
    type: ActionTypes.LOAD_CASE_PROFILE_REQUEST,
    payload: payload,
  };
};
const loadLocationAuthHistory = (payload) => {
  return {
    type: ActionTypes.LOAD_LOCATION_AUTH_HISTORY_REQUEST,
    payload: payload,
  };
};
const screenAuthorization = (payload) => {
  return {
    type: ActionTypes.SCREEN_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const listConsentForm = (payload) => {
  return {
    type: ActionTypes.LIST_CONSENT_FORM_REQUEST,
    payload: payload,
  };
};
const upsertTreatmentPlanDimension = (payload) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_DIMENSION_REQUEST,
    payload: payload,
  };
};
const saveConsentSign = (payload) => {
  return {
    type: ActionTypes.SAVE_CONSENT_SIGN_REQUEST,
    payload: payload,
  };
};
const resetSaveConsentSign = (payload) => {
  return {
    type: ActionTypes.SAVE_CONSENT_SIGN_RESET,
    payload: payload,
  };
};

const consentFormDownload = (payload) => {
  return {
    type: ActionTypes.CONSENT_FORM_DOWNLOAD_REQUEST,
    payload: payload,
  };
};

const listMeeting = (payload) => {
  return {
    type: ActionTypes.LIST_MEETING_REQUEST,
    payload: payload,
  };
};
const upsertProgressGroupNote = (payload) => {
  return {
    type: ActionTypes.UPSERT_PROGRESS_GROUP_NOTE_REQUEST,
    payload: payload,
  };
};
const resetUpsertProgressGroupNote = (payload) => {
  return {
    type: ActionTypes.UPSERT_PROGRESS_GROUP_NOTE_RESET,
    payload: payload,
  };
};

const getProgressNoteGroup = (payload) => {
  return {
    type: ActionTypes.GET_PROGRESS_NOTE_GROUP_REQUEST,
    payload: payload,
  };
};
const getUserNotePatient = (payload) => {
  return {
    type: ActionTypes.GET_USER_NOTE_PATIENT_REQUEST,
    payload: payload,
  };
};
const getUserNoteComment = (payload) => {
  return {
    type: ActionTypes.GET_USER_NOTE_COMMENT_REQUEST,
    payload: payload,
  };
};
const addUserNoteComment = (payload) => {
  return {
    type: ActionTypes.ADD_USER_NOTE_COMMENT_REQUEST,
    payload: payload,
  };
};
const getUserNoteEmoji = (payload) => {
  return {
    type: ActionTypes.GET_USER_NOTE_EMOJI_REQUEST,
    payload: payload,
  };
};
const addUserNoteEmoji = (payload) => {
  return {
    type: ActionTypes.ADD_USER_NOTE_EMOJI_REQUEST,
    payload: payload,
  };
};
const comboInterventionTemplate = (payload) => {
  return {
    type: ActionTypes.COMBO_INTERVENTION_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const getProgressNoteForIntervention = (payload) => {
  return {
    type: ActionTypes.GET_PROGRESS_NOTE_FOR_INTERVENTION_REQUEST,
    payload: payload,
  };
};
const upsertPayer = (payload) => {
  return {
    type: ActionTypes.UPSERT_PAYER_REQUEST,
    payload: payload,
  };
};
const resetUpsertPayer = (payload) => {
  return {
    type: ActionTypes.UPSERT_PAYER_RESET,
    payload: payload,
  };
};
const listPayer = (payload) => {
  return {
    type: ActionTypes.LIST_PAYER_REQUEST,
    payload: payload,
  };
};
const deletePayer = (payload) => {
  return {
    type: ActionTypes.DELETE_PAYER_REQUEST,
    payload: payload,
  };
};
const getPayer = (payload) => {
  return {
    type: ActionTypes.GET_PAYER_REQUEST,
    payload: payload,
  };
};
const comboPayer = (payload) => {
  return {
    type: ActionTypes.COMBO_PAYER_REQUEST,
    payload: payload,
  };
};
const upsertServiceCode = (payload) => {
  return {
    type: ActionTypes.UPSERT_SERVICE_CODE_REQUEST,
    payload: payload,
  };
};
const resetUpsertServiceCode = (payload) => {
  return {
    type: ActionTypes.UPSERT_SERVICE_CODE_RESET,
    payload: payload,
  };
};
const listServiceCode = (payload) => {
  return {
    type: ActionTypes.LIST_SERVICE_CODE_REQUEST,
    payload: payload,
  };
};
const deleteServiceCode = (payload) => {
  return {
    type: ActionTypes.DELETE_SERVICE_CODE_REQUEST,
    payload: payload,
  };
};
const getServiceCode = (payload) => {
  return {
    type: ActionTypes.GET_SERVICE_CODE_REQUEST,
    payload: payload,
  };
};

const comboCode = (payload) => {
  return {
    type: ActionTypes.COMBO_CODE_REQUEST,
    payload: payload,
  };
};
const getCodeDescription = (payload) => {
  return {
    type: ActionTypes.GET_CODE_DESCRIPTION_REQUEST,
    payload: payload,
  };
};
const appointmentNoteList = (payload) => {
  return {
    type: ActionTypes.APPOINTMENT_NOTE_LIST_REQUEST,
    payload: payload,
  };
};
const upsertAppointmentNote = (payload) => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const resetUpsertAppointmentNote = (payload) => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_NOTE_RESET,
    payload: payload,
  };
};

const getAppointmentNote = (payload) => {
  return {
    type: ActionTypes.GET_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};
const comboPlaceOfService = (payload) => {
  return {
    type: ActionTypes.COMBO_PLACE_OF_SERVICE_REQUEST,
    payload: payload,
  };
};
const listServiceLine = (payload) => {
  return {
    type: ActionTypes.LIST_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const getServiceLine = (payload) => {
  return {
    type: ActionTypes.GET_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const upsertServiceLine = (payload) => {
  return {
    type: ActionTypes.UPSERT_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const resetUpsertServiceLine = (payload) => {
  return {
    type: ActionTypes.UPSERT_SERVICE_LINE_RESET,
    payload: payload,
  };
};
const upsertAppointmentNoteAddendum = (payload) => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_NOTE_ADDENDUM_REQUEST,
    payload: payload,
  };
};

const listGroupAppointmentNote = (payload) => {
  return {
    type: ActionTypes.LIST_GROUP_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const getGroupAppointmentNote = (payload) => {
  return {
    type: ActionTypes.GET_GROUP_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const comboTreatmentPlanIntervention = (payload) => {
  return {
    type: ActionTypes.COMBO_TREATMENT_PLAN_INTERVENTION_REQUEST,
    payload: payload,
  };
};

const listFeeSchedule = (payload) => {
  return {
    type: ActionTypes.LIST_FEE_SCHEDULE_REQUEST,
    payload: payload,
  };
};
const getFeeSchedule = (payload) => {
  return {
    type: ActionTypes.GET_FEE_SCHEDULE_REQUEST,
    payload: payload,
  };
};
const upsertFeeSchedule = (payload) => {
  return {
    type: ActionTypes.UPSERT_FEE_SCHEDULE_REQUEST,
    payload: payload,
  };
};
const resetUpsertFeeSchedule = (payload) => {
  return {
    type: ActionTypes.UPSERT_FEE_SCHEDULE_RESET,
    payload: payload,
  };
};
const getTreatmentPlanGraph = (payload) => {
  return {
    type: ActionTypes.GET_TREATMENT_PLAN_GRAPH_REQUEST,
    payload: payload,
  };
};
const listGroupServiceLine = (payload) => {
  return {
    type: ActionTypes.LIST_GROUP_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const getGroupServiceLine = (payload) => {
  return {
    type: ActionTypes.GET_GROUP_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const getGroupBanner = (payload) => {
  return {
    type: ActionTypes.GET_GROUP_BANNER_REQUEST,
    payload: payload,
  };
};
const upsertGroupServiceLine = (payload) => {
  return {
    type: ActionTypes.UPSERT_GROUP_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const resetUpsertGroupServiceLine = (payload) => {
  return {
    type: ActionTypes.UPSERT_GROUP_SERVICE_LINE_RESET,
    payload: payload,
  };
};
const upsertGroupAppointmentNote = (payload) => {
  return {
    type: ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const upsertGroupAppointmentNoteAddendum = (payload) => {
  return {
    type: ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_ADDENDUM_REQUEST,
    payload: payload,
  };
};

const upsertAppointmentNoteIntervention = (payload) => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_NOTE_INTERVENTION_REQUEST,
    payload: payload,
  };
};
const markAsComplete = (payload) => {
  return {
    type: ActionTypes.MARK_AS_COMPLETE_REQUEST,
    payload: payload,
  };
};
const getServiceLineCost = (payload) => {
  return {
    type: ActionTypes.GET_SERVICE_LINE_COST_REQUEST,
    payload: payload,
  };
};

const upsertPin = (payload) => {
  return {
    type: ActionTypes.UPSERT_PIN_REQUEST,
    payload: payload,
  };
};

const validateStaffPin = (payload) => {
  return {
    type: ActionTypes.VALIDATE_STAFF_PIN_REQUEST,
    payload: payload,
  };
};

const resetValidateStaffPin = (payload) => {
  return {
    type: ActionTypes.VALIDATE_STAFF_PIN_RESET,
    payload: payload,
  };
};

const getStaffPractitioner = (payload) => {
  return {
    type: ActionTypes.GET_STAFF_PRACTITIONER_REQUEST,
    payload: payload,
  };
};

const clinicalCenterGet = (payload) => {
  return {
    type: ActionTypes.CLINICAL_CENTER_GET_REQUEST,
    payload: payload,
  };
};

const loadSmallGroupDashboard = (payload) => {
  return {
    type: ActionTypes.LOAD_SMALLGROUP_DASHBOARD_REQUEST,
    payload: payload,
  };
};
const upsertTreatmentPlan = (payload) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const listClientDiagnosis = (payload) => {
  return {
    type: ActionTypes.LIST_CLIENT_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const getClientDiagnosis = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const deleteClientDiagnosis = (payload) => {
  return {
    type: ActionTypes.DELETE_CLIENT_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const validatePinExists = (payload) => {
  return {
    type: ActionTypes.VALIDATE_PIN_EXISTS_REQUEST,
    payload: payload,
  };
};
const cloneDiagnosis = (payload) => {
  return {
    type: ActionTypes.CLONE_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const cloneTreatmentPlan = (payload) => {
  return {
    type: ActionTypes.CLONE_TREATMENT_PLAN_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};

const rcmClientMerge = (payload) => {
  return {
    type: ActionTypes.RCM_CLIENT_MERGE_REQUEST,
    payload: payload,
  };
};

const preValidatePin = (payload) => {
  return {
    type: ActionTypes.PRE_VALIDATE_PIN_REQUEST,
    payload: payload,
  };
};
const validateClient = (payload) => {
  return {
    type: ActionTypes.VALIDATE_CLIENT_REQUEST,
    payload: payload,
  };
};
const getAppAdvisor = (payload) => {
  return {
    type: ActionTypes.GET_APP_ADVISOR_REQUEST,
    payload: payload,
  };
};
const getMediaVideoUrl = (payload) => {
  return {
    type: ActionTypes.GET_MEDIA_VIDEO_URL_REQUEST,
    payload: payload,
  };
};
const getDashboardMenu = (payload) => {
  return {
    type: ActionTypes.GET_DASHBOARD_MENU_REQUEST,
    payload: payload,
  };
};

const listServiceCodeBundle = (payload) => {
  return {
    type: ActionTypes.LIST_SERVICE_CODE_BUNDLE_REQUEST,
    payload: payload,
  };
};
const getServiceCodeBundle = (payload) => {
  return {
    type: ActionTypes.GET_SERVICE_CODE_BUNDLE_REQUEST,
    payload: payload,
  };
};
const getStudentNote = (payload) => {
  return {
    type: ActionTypes.GET_STUDENT_NOTE_REQUEST,
    payload: payload,
  };
};
const getStudentNoteComment = (payload) => {
  return {
    type: ActionTypes.GET_STUDENT_NOTE_COMMENT_REQUEST,
    payload: payload,
  };
};
const addStudentNoteComment = (payload) => {
  return {
    type: ActionTypes.ADD_STUDENT_NOTE_COMMENT_REQUEST,
    payload: payload,
  };
};
const addStudentNoteEmoji = (payload) => {
  return {
    type: ActionTypes.ADD_STUDENT_NOTE_EMOJI_REQUEST,
    payload: payload,
  };
};
const getStudentNoteEmoji = (payload) => {
  return {
    type: ActionTypes.GET_STUDENT_NOTE_EMOJI_REQUEST,
    payload: payload,
  };
};
const getTreatmentPlanAccordin = (payload) => {
  return {
    type: ActionTypes.GET_TREATMENT_PLAN_ACCORDIN_REQUEST,
    payload: payload,
  };
};

const getAdvisorCost = (payload) => {
  return {
    type: ActionTypes.GET_ADVISOR_COST_REQUEST,
    payload: payload,
  };
};
const upsertAdvisorCost = (payload) => {
  return {
    type: ActionTypes.UPSERT_ADVISOR_COST_REQUEST,
    payload: payload,
  };
};
const resetUpsertAdvisorCost = (payload) => {
  return {
    type: ActionTypes.UPSERT_ADVISOR_COST_RESET,
    payload: payload,
  };
};

const claimsPdfDownload = (payload) => {
  return {
    type: ActionTypes.CLAIMS_PDF_DOWNLOAD_REQUEST,
    payload: payload,
  };
};
const getClientService = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_SERVICE_REQUEST,
    payload: payload,
  };
};

const getDashboardDailySchedule = (payload) => {
  return {
    type: ActionTypes.GET_DASHBOARD_DAILY_SCHEDULES_REQUEST,
    payload: payload,
  };
};
const getDashboardDailyCaseLoad = (payload) => {
  return {
    type: ActionTypes.GET_DASHBOARD_DAILY_CASE_LOAD_REQUEST,
    payload: payload,
  };
};
const getDashboardAlert = (payload) => {
  return {
    type: ActionTypes.GET_DASHBOARD_ALERT_REQUEST,
    payload: payload,
  };
};

const comboCenterConsent = (payload) => {
  return {
    type: ActionTypes.COMBO_CENTER_CONSENT_REQUEST,
    payload: payload,
  };
};

const centerProgramTypeGet = (payload) => {
  return {
    type: ActionTypes.CENTER_PROGRAM_TYPE_GET_REQUEST,
    payload: payload,
  };
};

const adminstrationListCenter = (payload) => {
  return {
    type: ActionTypes.CENTER_GET_ALL_REQUEST,
    payload: payload,
  };
};

const adminstrationGetCenter = (payload) => {
  return {
    type: ActionTypes.CENTER_GET_REQUEST,
    payload: payload,
  };
};

const adminstrationCenterDelete = (payload) => {
  return {
    type: ActionTypes.CENTER_DELETE_REQUEST,
    payload: payload,
  };
};

const resetAdminstrationCenterDelete = () => {
  return {
    type: ActionTypes.CENTER_DELETE_RESET,
  };
};

const resetCenterGet = () => {
  return {
    type: ActionTypes.CENTER_GET_RESET,
  };
};

const centerDelete = (payload) => {
  return {
    type: ActionTypes.CENTER_DELETE_REQUEST,
    payload: payload,
  };
};
const resetCenterDelete = () => {
  return {
    type: ActionTypes.CENTER_DELETE_RESET,
  };
};

const getCenterAdvisorDefaultCosts = (payload) => {
  return {
    type: ActionTypes.GET_CENTER_ADVISOR_DEFAULT_COSTS_REQUEST,
    payload: payload,
  };
};
const updateCenterAdvisorDefaultCost = (payload) => {
  return {
    type: ActionTypes.UPDATE_CENTER_ADVISOR_DEFAULT_COST_REQUEST,
    payload: payload,
  };
};
const resetUpdateCenterAdvisorDefaultCost = (payload) => {
  return {
    type: ActionTypes.UPDATE_CENTER_ADVISOR_DEFAULT_COST_RESET,
    payload: payload,
  };
};

const centerReactivate = (payload) => {
  return {
    type: ActionTypes.CENTER_REACTIVATE_REQUEST,
    payload: payload,
  };
};
const resetCenterReactivate = (payload) => {
  return {
    type: ActionTypes.CENTER_REACTIVATE_RESET,
    payload: payload,
  };
};

const addClientInsuranceEligibility = (payload) => {
  return {
    type: ActionTypes.ADD_CLIENT_INSURANCE_ELIGIBILITY_REQUEST,
    payload: payload,
  };
};
const editClientInsuranceEligibility = (payload) => {
  return {
    type: ActionTypes.EDIT_CLIENT_INSURANCE_ELIGIBILITY_REQUEST,
    payload: payload,
  };
};
const deleteClientInsuranceEligibility = (payload) => {
  return {
    type: ActionTypes.DELETE_CLIENT_INSURANCE_ELIGIBILITY_REQUEST,
    payload: payload,
  };
};

const dischargeSummarySave = (payload) => {
  return {
    type: ActionTypes.DISCHARGE_SUMMERY_SAVE_REQUEST,
    payload: payload,
  };
};
const getClientAppointment = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const getGroupAppointment = (payload) => {
  return {
    type: ActionTypes.GET_GROUP_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const unsignForm = (payload) => {
  return {
    type: ActionTypes.UNSIGN_FORM_REQUEST,
    payload: payload,
  };
};
const getFilters = (payload) => {
  return {
    type: ActionTypes.GET_FILTERS_REQUEST,
    payload: payload,
  };
};
const listLabOrder = (payload) => {
  return {
    type: ActionTypes.LIST_LAB_ORDER_REQUEST,
    payload: payload,
  };
};
const getLabOrder = (payload) => {
  return {
    type: ActionTypes.GET_LAB_ORDER_REQUEST,
    payload: payload,
  };
};
const upsertLabOrder = (payload) => {
  return {
    type: ActionTypes.UPSERT_LAB_ORDER_REQUEST,
    payload: payload,
  };
};
const comboDiagnosisList = (payload) => {
  return {
    type: ActionTypes.COMBO_DIAGNOSIS_LIST_REQUEST,
    payload: payload,
  };
};
const getMedicationNote = (payload) => {
  return {
    type: ActionTypes.GET_MEDICATION_NOTE_REQUEST,
    payload: payload,
  };
};
const upsertClientGroupAppointment = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_GROUP_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const signGroupAppointmentNote = (payload) => {
  return {
    type: ActionTypes.SIGN_GROUP_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const listServiceLinePayment = (payload) => {
  return {
    type: ActionTypes.LIST_SERVICE_LINE_PAYMENT_REQUEST,
    payload: payload,
  };
};
const getServiceLinePayment = (payload) => {
  return {
    type: ActionTypes.GET_SERVICE_LINE_PAYMENT_REQUEST,
    payload: payload,
  };
};
const upsertServiceLinePayment = (payload) => {
  return {
    type: ActionTypes.UPSERT_SERVICE_LINE_PAYMENT_REQUEST,
    payload: payload,
  };
};
const deleteServiceLinePayment = (payload) => {
  return {
    type: ActionTypes.DELETE_SERVICE_LINE_PAYMENT_REQUEST,
    payload: payload,
  };
};
const comboSupervisor = (payload) => {
  return {
    type: ActionTypes.COMBO_SUPERVISOR_REQUEST,
    payload: payload,
  };
};
const locationDayHistory = (payload) => {
  return {
    type: ActionTypes.LOCATION_DAY_HISTORY_REQUEST,
    payload: payload,
  };
};
const upsertPaymentClient = (payload) => {
  return {
    type: ActionTypes.UPSERT_PAYMENT_CLIENT_REQUEST,
    payload: payload,
  };
};
const resetUpsertPaymentClient = (payload) => {
  return {
    type: ActionTypes.UPSERT_PAYMENT_CLIENT_RESET,
    payload: payload,
  };
};
const getInsurancePriorityOrder = (payload) => {
  return {
    type: ActionTypes.GET_INSURANCE_PRIORITY_ORDER_REQUEST,
    payload: payload,
  };
};
const upsertInsurancePriorityOrder = (payload) => {
  return {
    type: ActionTypes.UPSERT_INSURANCE_PRIORITY_ORDER_REQUEST,
    payload: payload,
  };
};
const listCWPayment = (payload) => {
  return {
    type: ActionTypes.LIST_CW_PAYMENT_REQUEST,
    payload: payload,
  };
};
const getCWPayment = (payload) => {
  return {
    type: ActionTypes.GET_CW_PAYMENT_REQUEST,
    payload: payload,
  };
};
const deleteCWPayment = (payload) => {
  return {
    type: ActionTypes.DELETE_CW_PAYMENT_REQUEST,
    payload: payload,
  };
};
const pastMeetingInfo = (payload) => {
  return {
    type: ActionTypes.PAST_MEETING_INFO_REQUEST,
    payload: payload,
  };
};
const listPastMeetingInfo = (payload) => {
  return {
    type: ActionTypes.LIST_PAST_MEETING_INFO_REQUEST,
    payload: payload,
  };
};

const comboAppointmentTreatmentPlan = (payload) => {
  return {
    type: ActionTypes.COMBO_APPOINTMENT_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const linkAppointment = (payload) => {
  return {
    type: ActionTypes.LINK_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const appointmentLinkGet = (payload) => {
  return {
    type: ActionTypes.APPOINTMENT_LINK_GET_REQUEST,
    payload: payload,
  };
};
const getBasicProfile = (payload) => {
  return {
    type: ActionTypes.GET_BASIC_PROFILE_REQUEST,
    payload: payload,
  };
};
const getInvitedList = (payload) => {
  return {
    type: ActionTypes.GET_INVITED_LIST_REQUEST,
    payload: payload,
  };
};
const inviteResend = (payload) => {
  return {
    type: ActionTypes.INVITE_RESEND_REQUEST,
    payload: payload,
  };
};
const suspiciousTermAck = (payload) => {
  return {
    type: ActionTypes.SUSPICIOUS_TERM_ACK_REQUEST,
    payload: payload,
  };
};
const getBroadcastFilter = (payload) => {
  return {
    type: ActionTypes.GET_BROADCAST_FILTER_REQUEST,
    payload: payload,
  };
};
const sendBroadcast = (payload) => {
  return {
    type: ActionTypes.SEND_BROADCAST_REQUEST,
    payload: payload,
  };
};
const getServiceLineDiagnosis = (payload) => {
  return {
    type: ActionTypes.GET_SERVICE_LINE_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const upsertServiceLineDiagnosis = (payload) => {
  return {
    type: ActionTypes.UPSERT_SERVICE_LINE_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const comboBroadcastTemplate = (payload) => {
  return {
    type: ActionTypes.COMBO_BROADCAST_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const deleteNoteType = (payload) => {
  return {
    type: ActionTypes.DELETE_NOTE_TYPE_REQUEST,
    payload: payload,
  };
};
const listClientAuthorization = (payload) => {
  return {
    type: ActionTypes.LIST_CLIENT_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const getClientAuthorization = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const deleteClientAuthorization = (payload) => {
  return {
    type: ActionTypes.DELETE_CLIENT_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const upsertClientAuthorization = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const getNoteTypeArchive = (payload) => {
  return {
    type: ActionTypes.GET_NOTE_TYPE_ARCHIVE_REQUEST,
    payload: payload,
  };
};
const getNoteTypeList = (payload) => {
  return {
    type: ActionTypes.GET_NOTE_TYPE_LIST_REQUEST,
    payload: payload,
  };
};

const generateCallToken = (payload) => {
  return {
    type: ActionTypes.GENERATE_CALL_TOKEN_REQUEST,
    payload: payload,
  };
};

const badgeComboGet = (payload) => {
  return {
    type: ActionTypes.BADGE_COMBO_GET_REQUEST,
    payload: payload,
  };
};
const badgeUpdate = (payload) => {
  return {
    type: ActionTypes.BADGE_UPDATE_REQUEST,
    payload: payload,
  };
};
const panasScaleMoodsGet = (payload) => {
  return {
    type: ActionTypes.PANAS_SCALE_MOODS_GET_REQUEST,
    payload: payload,
  };
};
const panasTagUpsert = (payload) => {
  return {
    type: ActionTypes.PANAS_TAG_UPSERT_REQUEST,
    payload: payload,
  };
};
const bedAssignmentGetAll = (payload) => {
  return {
    type: ActionTypes.BED_ASSIGNMENT_GET_ALL_REQUEST,
    payload: payload,
  };
};
const bedAssignmentUpsert = (payload) => {
  return {
    type: ActionTypes.BED_ASSIGNMENT_UPSERT_REQUEST,
    payload: payload,
  };
};
const upcomingDischargesGetAll = (payload) => {
  return {
    type: ActionTypes.UPCOMING_DISCHARGES_GET_ALL_REQUEST,
    payload: payload,
  };
};
const upcomingAdmissions = (payload) => {
  return {
    type: ActionTypes.UPCOMING_ADMISSIONS_REQUEST,
    payload: payload,
  };
};
const admissionCancel = (payload) => {
  return {
    type: ActionTypes.ADMISSION_CANCEL_REQUEST,
    payload: payload,
  };
};
const panasTagDelete = (payload) => {
  return {
    type: ActionTypes.PANAS_TAG_DELETE_REQUEST,
    payload: payload,
  };
};
const dynamicGridList = (payload) => {
  return {
    type: ActionTypes.DYNAMIC_GRID_LIST_REQUEST,
    payload: payload,
  };
};
const getGrid = (payload) => {
  return {
    type: ActionTypes.GET_GRID_REQUEST,
    payload: payload,
  };
};
const getGridData = (payload) => {
  return {
    type: ActionTypes.GET_GRID_DATA_REQUEST,
    payload: payload,
  };
};
const upsertFormData = (payload) => {
  return {
    type: ActionTypes.UPSERT_FORM_DATA_REQUEST,
    payload: payload,
  };
};
const formDataGet = (payload) => {
  return {
    type: ActionTypes.FORM_DATA_GET_REQUEST,
    payload: payload,
  };
};
const resetFormDataGet = (payload) => {
  return {
    type: ActionTypes.FORM_DATA_GET_RESET,
    payload: payload,
  };
};

const dynamicGridDataGet = (payload) => {
  return {
    type: ActionTypes.DYNAMIC_GRID_DATA_GET_REQUEST,
    payload: payload,
  };
};
const memberTrendsGet = (payload) => {
  return {
    type: ActionTypes.MEMBER_TRENDS_GET_REQUEST,
    payload: payload,
  };
};
const advisorMemberTrendsHistoryGet = (payload) => {
  return {
    type: ActionTypes.ADVISOR_MEMBER_TRENDS_HISTORY_GET_REQUEST,
    payload: payload,
  };
};
const dashboardAnalyticsGet = (payload) => {
  return {
    type: ActionTypes.DASHBOARD_ANALYTICS_GET_REQUEST,
    payload: payload,
  };
};

const userActivityGet = (payload) => {
  return {
    type: ActionTypes.USER_ACTIVITY_GET_REQUEST,
    payload: payload,
  };
};

const userSuspiciousWordGet = (payload) => {
  return {
    type: ActionTypes.USER_SUSPICIOUS_WORD_GET_REQUEST,
    payload: payload,
  };
};

const userNotificationGet = (payload) => {
  return {
    type: ActionTypes.USER_NOTIFICATION_GET_REQUEST,
    payload: payload,
  };
};

const userMemberTrendHistory = (payload) => {
  return {
    type: ActionTypes.USER_MEMBER_TREND_HISTORY_REQUEST,
    payload: payload,
  };
};

const upsertClientNote = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_NOTE_REQUEST,
    payload: payload,
  };
};

const getPreviousNotes = (payload) => {
  return {
    type: ActionTypes.GET_PREVIOUS_NOTE_REQUEST,
    payload: payload,
  };
};

const resetUpsertNote = (payload) => {
  return {
    type: ActionTypes.UPSERT_CLIENT_NOTE_RESET,
    payload: payload,
  };
};

const getDynamicGridFilter = (payload) => {
  return {
    type: ActionTypes.GET_DYNAMIC_GRID_FILTER_REQUEST,
    payload: payload,
  };
};

const resetDynamicGridFilter = (payload) => {
  return {
    type: ActionTypes.GET_DYNAMIC_GRID_FILTER_RESET,
    payload: payload,
  };
};

const memberTrendTriggerHistory = (payload) => {
  return {
    type: ActionTypes.MEMBER_TREND_TRIGGER_HISTORY_REQUEST,
    payload: payload,
  };
};
const memberHeaderInfoGet = (payload) => {
  return {
    type: ActionTypes.MEMBER_HEADER_INFO_GET_REQUEST,
    payload: payload,
  };
};

const getAssessmentDetail = (payload) => {
  return {
    type: ActionTypes.GET_ASSESSMENT_DETAIL_LIST_V2_REQUEST,
    payload: payload,
  };
};

const resetGetAssessmentDetail = (payload) => {
  return {
    type: ActionTypes.GET_ASSESSMENT_DETAIL_LIST_V2_RESET,
    payload: payload,
  };
};

const assignMultipleV2 = (payload) => {
  return {
    type: ActionTypes.ASSIGN_MULTIPLE_V2_REQUEST,
    payload: payload,
  };
};

const chatFriendListGet = (payload) => {
  return {
    type: ActionTypes.CHAT_FRIEND_LIST_GET_REQUEST,
    payload: payload,
  };
};

const listClientV2 = (payload) => {
  return {
    type: ActionTypes.LIST_CLIENT_V2_REQUEST,
    payload: payload,
  };
};

const addStudentCourse = (payload) => {
  return {
    type: ActionTypes.STUDENT_COURSE_ASSIGN_REQUEST,
    payload: payload,
  };
};

const resetAddStudentCourse = () => {
  return {
    type: ActionTypes.STUDENT_COURSE_ASSIGN_RESET,
  };
};

const listCoursesByStudent = (payload) => {
  return {
    type: ActionTypes.STUDENT_GET_ALL_COURSES_REQUEST,
    payload: payload,
  };
};

const adminstrationListCourse = (payload) => {
  return {
    type: ActionTypes.COURSE_GET_ALL_REQUEST,
    payload: payload,
  };
};

// const adminstrationGetCourse = (payload) => {
//   return {
//     type: ActionTypes.COURSE_GET_REQUEST,
//     payload: payload,
//   };
// };

// const adminstrationCourseUpsert = (payload) => {
//   return {
//     type: ActionTypes.COURSE_UPSERT_REQUEST,
//     payload: payload,
//   };
// };

// const resetAdminstrationCourseUpsert = (payload) => {
//   return {
//     type: ActionTypes.COURSE_UPSERT_RESET,
//     payload: payload,
//   };
// };

const resetCourseUpsert = (payload) => {
  return {
    type: ActionTypes.COURSE_UPSERT_RESET,
    payload: payload,
  };
};

const adminstrationCourseDelete = (payload) => {
  return {
    type: ActionTypes.COURSE_DELETE_REQUEST,
    payload: payload,
  };
};

const resetAdminstrationCourseDelete = () => {
  return {
    type: ActionTypes.COURSE_DELETE_RESET,
  };
};

// const adminstrationResetCourse = () => {
//   return {
//     type: ActionTypes.COURSE_GET_RESET,
//   };
// };

const resetCourseGet = () => {
  return {
    type: ActionTypes.COURSE_GET_RESET,
  };
};

const courseDelete = (payload) => {
  return {
    type: ActionTypes.COURSE_DELETE_REQUEST,
    payload: payload,
  };
};
const resetCourseDelete = () => {
  return {
    type: ActionTypes.COURSE_DELETE_RESET,
  };
};

const addAppSubscribersCourse = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_REQUEST,
    payload,
  };
};
const deleteAppSubscribersCourse = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_REQUEST,
    payload,
  };
};
const appSubscribersCompleteCourse = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_REQUEST,
    payload,
  };
};
const resetAddAppSubscribersCourse = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_RESET,
    payload,
  };
};
const resetDeleteAppSubscribersCourse = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_RESET,
    payload,
  };
};
const resetAppSubscribersCompleteCourse = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_RESET,
    payload,
  };
};

const getContract = (payload) => {
  return {
    type: ActionTypes.GET_CONTRACT_REQUEST,
    payload: payload,
  };
};

const resetGetContract = (payload) => {
  return {
    type: ActionTypes.GET_CONTRACT_RESET,
    payload: payload,
  };
};

const upsertContract = (payload) => {
  return {
    type: ActionTypes.UPSERT_CONTRACT_REQUEST,
    payload: payload,
  };
};

const resetUpsertContract = (payload) => {
  return {
    type: ActionTypes.UPSERT_CONTRACT_RESET,
    payload: payload,
  };
};

const deleteContract = (payload) => {
  return {
    type: ActionTypes.DELETE_CONTRACT_REQUEST,
    payload: payload,
  };
};

const resetDeleteContract = (payload) => {
  return {
    type: ActionTypes.DELETE_CONTRACT_RESET,
    payload: payload,
  };
};

const getContractTemplate = (payload) => {
  return {
    type: ActionTypes.GET_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const upsertContractTemplate = (payload) => {
  return {
    type: ActionTypes.UPSERT_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const getStudentContractTemplate = (payload) => {
  return {
    type: ActionTypes.GET_STUDENT_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const resetGetContractTemplate = (payload) => {
  return {
    type: ActionTypes.GET_CONTRACT_TEMPLATE_RESET,
    payload: payload,
  };
};

const resetUpsertContractTemplate = (payload) => {
  return {
    type: ActionTypes.UPSERT_CONTRACT_TEMPLATE_RESET,
    payload: payload,
  };
};

const createContractFromTemplate = (payload) => {
  return {
    type: ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const resetCreateContractFromTemplate = (payload) => {
  return {
    type: ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_RESET,
    payload: payload,
  };
};

const closeContract = (payload) => {
  return {
    type: ActionTypes.CLOSE_CONTRACT_REQUEST,
    payload: payload,
  };
};

const menuContractTemplate = (payload) => {
  return {
    type: ActionTypes.MENU_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const archiveContractTemplate = (payload) => {
  return {
    type: ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const resetArchiveContractTemplate = (payload) => {
  return {
    type: ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_RESET,
    payload: payload,
  };
};

const addFavoriteTemplate = (payload) => {
  return {
    type: ActionTypes.ADD_FAVORITE_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const resetAddFavoriteTemplate = (payload) => {
  return {
    type: ActionTypes.ADD_FAVORITE_TEMPLATE_RESET,
    payload: payload,
  };
};

const removeFavoriteTemplate = (payload) => {
  return {
    type: ActionTypes.REMOVE_FAVORITE_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const resetRemoveFavoriteTemplate = (payload) => {
  return {
    type: ActionTypes.REMOVE_FAVORITE_TEMPLATE_RESET,
    payload: payload,
  };
};

const getSurveys = (payload) => {
  return {
    type: ActionTypes.SURVEY_STUDENTS_GET_ALL_PENDING_REQUEST,
    payload: payload,
  };
};

const getSurveyQuestionMultiple = (surveyId, includeAnswered) => {
  return {
    type: ActionTypes.SURVEY_QUESTION_GET_MULTIPLE_REQUEST,
    payload: { surveyId: surveyId, includeAnswered: includeAnswered },
  };
};

const surveyGetToAssign = (payload) => {
  return {
    type: ActionTypes.SURVEY_GET_TO_ASSIGN_REQUEST,
    payload: payload,
  };
};

const surveyAssign = (payload) => {
  return {
    type: ActionTypes.SURVEY_ASSIGN_REQUEST,
    payload: payload,
  };
};

const resetSurveyAssign = (payload) => {
  return {
    type: ActionTypes.SURVEY_ASSIGN_RESET,
    payload: payload,
  };
};

const surveyTaskMarkInComplete = (payload) => {
  return {
    type: ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_REQUEST,
    payload: payload,
  };
};

const resetSurveyTaskMarkInComplete = (payload) => {
  return {
    type: ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_REQUEST,
    payload: payload,
  };
};

const getStudentSurveyDashboardData = (payload) => {
  return {
    type: ActionTypes.STUDENT_SURVEY_DASHBOARD_GET_JSON_REQUEST,
    payload: { studentId: payload },
  };
};

const resetSurveyAnswersGet = (payload) => {
  return {
    type: ActionTypes.SURVEY_ANSWERS_GET_RESET,
    payload: payload,
  };
};

const surveyAnswersGet = (payload) => {
  return {
    type: ActionTypes.SURVEY_ANSWERS_GET_REQUEST,
    payload: payload,
  };
};

const addAppSubscribersSurvey = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_REQUEST,
    payload,
  };
};
const deleteAppSubscribersSurvey = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_REQUEST,
    payload,
  };
};
const resetAppSubscribersUnassignSurvey = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_RESET,
    payload,
  };
};
const resetAppSubscribersSurvey = (payload) => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_RESET,
    payload,
  };
};

const listSurveyQuestionCategory = (payload) => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_REQUEST,
    payload: payload,
  };
};
const listSurveyQuestionCategoryAssessment = (payload) => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_ASSESSMENT_REQUEST,
    payload: payload,
  };
};
const listSurveyQuestionAssessment = (payload) => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_ASSESSMENT_REQUEST,
    payload: payload,
  };
};

const getSurveyScore = (payload) => {
  return {
    type: ActionTypes.GET_SURVEY_SCORE_REQUEST,
    payload: payload,
  };
};

const loadAppSubSurveyDashboard = (payload) => {
  return {
    type: ActionTypes.LOAD_APP_SUB_SURVEY_DASHBOARD_REQUEST,
    payload: payload,
  };
};
const appSubSurveyAnsGet = (payload) => {
  return {
    type: ActionTypes.APP_SUB_SURVEY_ANS_GET_REQUEST,
    payload: payload,
  };
};

const resetAppSubSurveyAnsGet = (payload) => {
  return {
    type: ActionTypes.APP_SUB_SURVEY_ANS_GET_RESET,
    payload: payload,
  };
};

const listSurveyQuestionDischarge = (payload) => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_DISCHARGE_REQUEST,
    payload: payload,
  };
};

const getSurveysV2 = (payload) => {
  return {
    type: ActionTypes.GET_SURVEYS_V2_REQUEST,
    payload: payload,
  };
};

//youu started

const login = (payload) => {
  return {
    type: ActionTypes.LOGIN_REQUEST,
    payload: payload,
  };
};
const loadDefaultValues = (payload) => {
  return {
    type: ActionTypes.LOAD_DEFAULT_VALUES_REQUEST,
    payload: payload,
  };
};
const userDataGet = (payload) => {
  return {
    type: ActionTypes.USER_DATA_GET_REQUEST,
    payload: payload,
  };
};

const resetUserDataGet = (payload) => {
  return {
    type: ActionTypes.USER_DATA_GET_RESET,
    payload: payload,
  };
};
const sideBarMenuGet = (payload) => {
  return {
    type: ActionTypes.SIDE_BAR_MENU_GET_REQUEST,
    payload: payload,
  };
};

const allGet = (payload) => {
  return {
    type: ActionTypes.ALL_GET_REQUEST,
    payload: payload,
  };
};

const getDynamicScreenTab = (payload) => {
  return {
    type: ActionTypes.GET_DYNAMIC_SCREEN_TAB_REQUEST,
    payload: payload,
  };
};

const resetGetDynamicScreenTab = (payload) => {
  return {
    type: ActionTypes.GET_DYNAMIC_SCREEN_TAB_RESET,
    payload: payload,
  };
};
const getFormFieldMetaData = (payload) => {
  return {
    type: ActionTypes.GET_FORM_FIELD_META_DATA_REQUEST,
    payload: payload,
  };
};
const dynamicGridColumnConfigurationGet = (payload) => {
  return {
    type: ActionTypes.DYNAMIC_GRID_COLUMN_CONFIGURATION_GET_REQUEST,
    payload: payload,
  };
};
const getMultistepTab = (payload) => {
  return {
    type: ActionTypes.GET_MULTISTEP_TAB_REQUEST,
    payload: payload,
  };
};

const resetGetMultistepTab = (payload) => {
  return {
    type: ActionTypes.GET_MULTISTEP_TAB_RESET,
    payload: payload,
  };
};

//Combo API's

const comboListGet = (payload) => {
  return {
    type: ActionTypes.COMBO_LIST_GET_REQUEST,
    payload: payload,
  };
};

const serviceCodeListGet = (payload) => {
  return {
    type: ActionTypes.SERVICE_CODE_LIST_GET_REQUEST,
    payload: payload,
  };
};

const counselorListGet = (payload) => {
  return {
    type: ActionTypes.COUNSELOR_LIST_GET_REQUEST,
    payload: payload,
  };
};

const facilityListGet = (payload) => {
  return {
    type: ActionTypes.FACILITY_LIST_GET_REQUEST,
    payload: payload,
  };
};

const clientListDropdown = (payload) => {
  return {
    type: ActionTypes.CLIENT_LIST_DROPDOWN_REQUEST,
    payload: payload,
  };
};

//Profile API's

const getUserInfo = (payload) => {
  return {
    type: ActionTypes.GET_USER_INFO_REQUEST,
    payload: payload,
  };
};

const getClientAlbum = (payload) => {
  return {
    type: ActionTypes.GET_CLIENT_ALBUM_REQUEST,
    payload: payload,
  };
};

const postListGet = (payload) => {
  return {
    type: ActionTypes.POST_LIST_GET_REQUEST,
    payload: payload,
  };
};

const clientFriendAllGet = (payload) => {
  return {
    type: ActionTypes.CLIENT_FRIEND_ALL_GET_REQUEST,
    payload: payload,
  };
};

//Course Actions

const assignedCourseListGet = (payload) => {
  return {
    type: ActionTypes.ASSIGNED_COURSE_LIST_GET_REQUEST,
    payload: payload,
  };
};

const courseAssign = (payload) => {
  return {
    type: ActionTypes.COURSE_ASSIGN_REQUEST,
    payload: payload,
  };
};

const courseUnassign = (payload) => {
  return {
    type: ActionTypes.COURSE_UNASSIGN_REQUEST,
    payload: payload,
  };
};

const courseMarkAsComplete = (payload) => {
  return {
    type: ActionTypes.COURSE_MARK_AS_COMPLETE_REQUEST,
    payload: payload,
  };
};

const courseOptionGet = (payload) => {
  return {
    type: ActionTypes.COURSE_OPTION_GET_REQUEST,
    payload: payload,
  };
};

const courseUpsert = (payload) => {
  return {
    type: ActionTypes.COURSE_UPSERT_REQUEST,
    payload: payload,
  };
};

const courseGet = (payload) => {
  return {
    type: ActionTypes.COURSE_GET_REQUEST,
    payload: payload,
  };
};

const courseListGet = (payload) => {
  return {
    type: ActionTypes.COURSE_LIST_GET_REQUEST,
    payload: payload,
  };
};

const courseStatusUpdate = (payload) => {
  return {
    type: ActionTypes.COURSE_STATUS_UPDATE_REQUEST,
    payload: payload,
  };
};

const resetCourseStatusUpdate = (payload) => {
  return {
    type: ActionTypes.COURSE_STATUS_UPDATE_RESET,
    payload: payload,
  };
};

//Center Actions

const centerUpsert = (payload) => {
  return {
    type: ActionTypes.CENTER_UPSERT_REQUEST,
    payload: payload,
  };
};

const centerListGet = (payload) => {
  return {
    type: ActionTypes.CENTER_LIST_GET_REQUEST,
    payload: payload,
  };
};

const centerGet = (payload) => {
  return {
    type: ActionTypes.CENTER_GET_REQUEST,
    payload: payload,
  };
};

const centerStatusUpdate = (payload) => {
  return {
    type: ActionTypes.CENTER_STATUS_UPDATE_REQUEST,
    payload: payload,
  };
};

const resetCenterStatusUpdate = (payload) => {
  return {
    type: ActionTypes.CENTER_STATUS_UPDATE_RESET,
    payload: payload,
  };
};

const centerOptionGet = (payload) => {
  return {
    type: ActionTypes.CENTER_OPTION_GET_REQUEST,
    payload: payload,
  };
};

//Journal Actions

const getAllJournalComments = (payload) => {
  return {
    type: ActionTypes.GET_ALL_JOURNAL_COMMENTS_REQUEST,
    payload: payload,
  };
};

const getAllJournal = (payload) => {
  return {
    type: ActionTypes.GET_ALL_JOURNAL_REQUEST,
    payload: payload,
  };
};

//Task Actions

const taskListGet = (payload) => {
  return {
    type: ActionTypes.TASK_LIST_GET_REQUEST,
    payload: payload,
  };
};

const taskUnAssign = (payload) => {
  return {
    type: ActionTypes.TASK_UNASSIGN_REQUEST,
    payload: payload,
  };
};

const resetTaskUnAssign = (payload) => {
  return {
    type: ActionTypes.TASK_UNASSIGN_RESET,
    payload: payload,
  };
};

const taskMarkComplete = (payload) => {
  return {
    type: ActionTypes.TASK_MARK_COMPLETE_REQUEST,
    payload: payload,
  };
};

const resetTaskMarkComplete = (payload) => {
  return {
    type: ActionTypes.TASK_MARK_COMPLETE_RESET,
    payload: payload,
  };
};

const taskAssign = (payload) => {
  return {
    type: ActionTypes.TASK_ASSIGN_REQUEST,
    payload: payload,
  };
};

const taskOptionGet = (payload) => {
  return {
    type: ActionTypes.TASK_OPTIONS_GET_REQUEST,
    payload: payload,
  };
};

const timeTravelListGet = (payload) => {
  return {
    type: ActionTypes.TIME_TRAVEL_LIST_GET_REQUEST,
    payload: payload,
  };
};
const triggersListGet = (payload) => {
  return {
    type: ActionTypes.TRIGGERS_LIST_GET_REQUEST,
    payload: payload,
  };
};

const populationActivitiesClientListGet = (payload) => {
  return {
    type: ActionTypes.POPULATION_ACTIVITIES_CLIENT_LIST_GET_REQUEST,
    payload: payload,
  };
};
const resetPopulationActivitiesClientListGet = (payload) => {
  return {
    type: ActionTypes.POPULATION_ACTIVITIES_CLIENT_LIST_GET_RESET,
    payload: payload,
  };
};

//Assessments Actions

const assessmentsListGet = (payload) => {
  return {
    type: ActionTypes.ASSESSMENTS_LIST_GET_REQUEST,
    payload: payload,
  };
};

const resetAssessmentsListGet = (payload) => {
  return {
    type: ActionTypes.ASSESSMENTS_LIST_GET_RESET,
    payload: payload,
  };
};

const assessmentsMarkAsDiscard = (payload) => {
  return {
    type: ActionTypes.ASSESSMENTS_MARK_AS_DISCARD_REQUEST,
    payload: payload,
  };
};

const assessmentsMultipleAssign = (payload) => {
  return {
    type: ActionTypes.ASSESSMENTS_MULTIPLE_ASSIGN_REQUEST,
    payload: payload,
  };
};

//Intake Actions

const intakeClientUpsert = (payload) => {
  return {
    type: ActionTypes.INTAKE_CLIENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetIntakeClientUpsert = (payload) => {
  return {
    type: ActionTypes.INTAKE_CLIENT_UPSERT_RESET,
    payload: payload,
  };
};

const intakeInsuranceUpsert = (payload) => {
  return {
    type: ActionTypes.INTAKE_INSURANCE_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetIntakeInsuranceUpsert = (payload) => {
  return {
    type: ActionTypes.INTAKE_INSURANCE_UPSERT_RESET,
    payload: payload,
  };
};

const intakeInsurancePriorityOrderGet = (payload) => {
  return {
    type: ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_GET_REQUEST,
    payload: payload,
  };
};

const resetIntakeInsurancePriorityOrderGet = (payload) => {
  return {
    type: ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_GET_RESET,
    payload: payload,
  };
};

const intakeInsurancePriorityOrderUpdate = (payload) => {
  return {
    type: ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_UPDATE_REQUEST,
    payload: payload,
  };
};

const resetIntakeInsurancePriorityOrderUpdate = (payload) => {
  return {
    type: ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_UPDATE_RESET,
    payload: payload,
  };
};

const intakeMedicalHistoryUpsert = (payload) => {
  return {
    type: ActionTypes.INTAKE_MEDICAL_HISTORY_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetIntakeMedicalHistoryUpsert = (payload) => {
  return {
    type: ActionTypes.INTAKE_MEDICAL_HISTORY_UPSERT_RESET,
    payload: payload,
  };
};

const intakeFormDataGet = (payload) => {
  return {
    type: ActionTypes.INTAKE_FORM_DATA_GET_REQUEST,
    payload: payload,
  };
};

const resetIntakeFormDataGet = (payload) => {
  return {
    type: ActionTypes.INTAKE_FORM_DATA_GET_RESET,
    payload: payload,
  };
};

const intakeEnrollNow = (payload) => {
  return {
    type: ActionTypes.INTAKE_ENROLL_NOW_REQUEST,
    payload: payload,
  };
};

const resetIntakeEnrollNow = (payload) => {
  return {
    type: ActionTypes.INTAKE_ENROLL_NOW_RESET,
    payload: payload,
  };
};

const preIntakeGridReports = (payload) => {
  return {
    type: ActionTypes.PRE_INTAKE_GRID_REPORTS_REQUEST,
    payload: payload,
  };
};

const preIntakeDefaultAssessmentGet = (payload) => {
  return {
    type: ActionTypes.PRE_INTAKE_DEFAULT_ASSESSMENT_GET_REQUEST,
    payload: payload,
  };
};

const journalAdd = (payload) => {
  return {
    type: ActionTypes.JOURNAL_ADD_REQUEST,
    payload: payload,
  };
};

const journalCommentUpsert = (payload) => {
  return {
    type: ActionTypes.JOURNAL_COMMENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const trendsListGet = (payload) => {
  return {
    type: ActionTypes.TRENDS_LIST_GET_REQUEST,
    payload: payload,
  };
};

const surveyQuestionCategoryOptionGet = (payload) => {
  return {
    type: ActionTypes.SURVEY_QUESTION_CATEGORY_OPTION_GET_REQUEST,
    payload: payload,
  };
};

//Growth Plan Actions

const growthPlanTemplateUpsert = (payload) => {
  return {
    type: ActionTypes.GROWTH_PLAN_TEMPLATE_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetGrowthPlanTemplateUpsert = (payload) => {
  return {
    type: ActionTypes.GROWTH_PLAN_TEMPLATE_UPSERT_RESET,
    payload: payload,
  };
};

const growthPlanTemplateMenuGet = (payload) => {
  return {
    type: ActionTypes.GROWTH_PLAN_TEMPLATE_MENU_GET_REQUEST,
    payload: payload,
  };
};

const growthPlanTemplateGet = (payload) => {
  return {
    type: ActionTypes.GROWTH_PLAN_TEMPLATE_GET_REQUEST,
    payload: payload,
  };
};

const resetGrowthPlanTemplateGet = (payload) => {
  return {
    type: ActionTypes.GROWTH_PLAN_TEMPLATE_GET_RESET,
    payload: payload,
  };
};

const growthPlanTemplateFavoriteAdd = (payload) => {
  return {
    type: ActionTypes.GROWTH_PLAN_TEMPLATE_FAVORITE_ADD_REQUEST,
    payload: payload,
  };
};

const growthPlanTemplateFavoriteDelete = (payload) => {
  return {
    type: ActionTypes.GROWTH_PLAN_TEMPLATE_FAVORITE_DELETE_REQUEST,
    payload: payload,
  };
};

const growthPlanTemplateDelete = (payload) => {
  return {
    type: ActionTypes.GROWTH_PLAN_TEMPLATE_DELETE_REQUEST,
    payload: payload,
  };
};

const clientGrowthPlanUpsert = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROWTH_PLAN_UPSERT_REQUEST,
    payload: payload,
  };
};

const clientGrowthPlanListGet = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROWTH_PLAN_LIST_GET_REQUEST,
    payload: payload,
  };
};

const clientGrowthPlanGet = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROWTH_PLAN_GET_REQUEST,
    payload: payload,
  };
};

const clientGrowthPlanDelete = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROWTH_PLAN_DELETE_REQUEST,
    payload: payload,
  };
};

const clientGrowthPlanFromTemplateInsert = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROWTH_PLAN_FROM_TEMPLATE_INSERT_REQUEST,
    payload: payload,
  };
};

const clientGrowthPlanClose = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROWTH_PLAN_CLOSE_REQUEST,
    payload: payload,
  };
};
const getSmallGroupList = (payload) => {
  return {
    type: ActionTypes.GET_SMALL_GROUP_LIST_REQUEST,
    payload: payload,
  };
};
const smallGroupGet = (payload) => {
  return {
    type: ActionTypes.SMALL_GROUP_GET_REQUEST,
    payload: payload,
  };
};
const resetSmallGroupGet = (payload) => {
  return {
    type: ActionTypes.SMALL_GROUP_GET_RESET,
    payload: payload,
  };
};
const smallGroupUpsert = (payload) => {
  return {
    type: ActionTypes.SMALL_GROUP_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetSmallGroupUpsert = () => {
  return {
    type: ActionTypes.SMALL_GROUP_UPSERT_RESET,
  };
};
const resetGroupUpsert = () => {
  return {
    type: ActionTypes.GROUP_UPSERT_RESET,
  };
};

const smallGroupReferral = (payload) => {
  return {
    type: ActionTypes.SMALL_GROUP_REFERRAL_REQUEST,
    payload: payload,
  };
};

const smallGroupUserListGet = (payload) => {
  return {
    type: ActionTypes.SMALL_GROUP_USER_LIST_GET_REQUEST,
    payload: payload,
  };
};

const smallGroupUserRemove = (payload) => {
  return {
    type: ActionTypes.SMALL_GROUP_USER_REMOVE_REQUEST,
    payload: payload,
  };
};

const smallGroupLocationGet = (payload) => {
  return {
    type: ActionTypes.SMALL_GROUP_LOCATION_GET_REQUEST,
    payload: payload,
  };
};

const smallGroupLocationUpdate = (payload) => {
  return {
    type: ActionTypes.SMALL_GROUP_LOCATION_UPDATE_REQUEST,
    payload: payload,
  };
};

const smallGroupImagesGet = (payload) => {
  return {
    type: ActionTypes.SMALL_GROUP_IMAGES_GET_REQUEST,
    payload: payload,
  };
};

const upsertBroadcastTemplate = (payload) => {
  return {
    type: ActionTypes.UPSERT_BROADCAST_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const broadcastSend = (payload) => {
  return {
    type: ActionTypes.BROADCAST_SEND_REQUEST,
    payload: payload,
  };
};

const surveyAssignMultiple = (payload) => {
  return {
    type: ActionTypes.SURVEY_ASSIGN_MULTIPLE_REQUEST,
    payload: payload,
  };
};

const resetSurveyAssignMultiple = (payload) => {
  return {
    type: ActionTypes.SURVEY_ASSIGN_MULTIPLE_RESET,
    payload: payload,
  };
};

const surveyAnswerUpsert = (payload) => {
  return {
    type: ActionTypes.SURVEY_ANSWER_UPSERT_REQUEST,
    payload: payload,
  };
};

const clientSurveyListGet = (payload) => {
  return {
    type: ActionTypes.CLIENT_SURVEY_LIST_GET_REQUEST,
    payload: payload,
  };
};

const surveyQuestionCategoryListGet = (payload) => {
  return {
    type: ActionTypes.SURVEY_QUESTION_CATEGORY_LIST_GET_REQUEST,
    payload: payload,
  };
};

const surveyQuestionHierarchialGet = (payload) => {
  return {
    type: ActionTypes.SURVEY_QUESTION_HIERARCHIAL_GET_REQUEST,
    payload: payload,
  };
};

const surveyMarkAsDiscard = (payload) => {
  return {
    type: ActionTypes.SURVEY_MARK_AS_DISCARD_REQUEST,
    payload: payload,
  };
};

const surveyAssignedListGet = (payload) => {
  return {
    type: ActionTypes.SURVEY_ASSIGNED_LIST_GET_REQUEST,
    payload: payload,
  };
};

//CLINICAL GROUPS API's

const groupUpsert = (payload) => {
  return {
    type: ActionTypes.GROUP_UPSERT_REQUEST,
    payload: payload,
  };
};

const groupGet = (payload) => {
  return {
    type: ActionTypes.GROUP_GET_REQUEST,
    payload: payload,
  };
};

const resetGroupGet = (payload) => {
  return {
    type: ActionTypes.GROUP_GET_RESET,
    payload: payload,
  };
};

const groupListGet = (payload) => {
  return {
    type: ActionTypes.GROUP_LIST_GET_REQUEST,
    payload: payload,
  };
};

const clientGroupUpsert = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROUP_UPSERT_REQUEST,
    payload: payload,
  };
};

const clientsNotParticipatingInGroup = (payload) => {
  return {
    type: ActionTypes.CLIENTS_NOT_PARTICIPATING_IN_GROUP_REQUEST,
    payload: payload,
  };
};

const clientGroupGet = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROUP_GET_REQUEST,
    payload: payload,
  };
};
const resetClientGroupGet = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROUP_GET_RESET,
    payload: payload,
  };
};

const groupAppointmentGroupGet = (payload) => {
  return {
    type: ActionTypes.GROUP_APPOINTMENT_GROUP_GET_REQUEST,
    payload: payload,
  };
};

const groupAppointmentGroupNotesGet = (payload) => {
  return {
    type: ActionTypes.GROUP_APPOINTMENT_GROUP_NOTES_GET_REQUEST,
    payload: payload,
  };
};

const groupServiceLinesGet = (payload) => {
  return {
    type: ActionTypes.GROUP_SERVICE_LINES_GET_REQUEST,
    payload: payload,
  };
};

const groupDeactivate = (payload) => {
  return {
    type: ActionTypes.GROUP_DEACTIVATE_REQUEST,
    payload: payload,
  };
};

// Coupon
const couponListGet = (payload) => {
  return {
    type: ActionTypes.COUPON_LIST_GET_REQUEST,
    payload: payload,
  };
};

const upsertCouponCode = (payload) => {
  return {
    type: ActionTypes.UPSERT_COUPON_CODE_REQUEST,
    payload: payload,
  };
};

const resetUpsertCouponCode = (payload) => {
  return {
    type: ActionTypes.UPSERT_COUPON_CODE_RESET,
    payload: payload,
  };
};

const deleteCouponCode = (payload) => {
  return {
    type: ActionTypes.DELETE_COUPON_CODE_REQUEST,
    payload: payload,
  };
};

const resetDeleteCouponCode = (payload) => {
  return {
    type: ActionTypes.DELETE_COUPON_CODE_RESET,
    payload: payload,
  };
};

const getCouponCode = (payload) => {
  return {
    type: ActionTypes.GET_COUPON_CODE_REQUEST,
    payload: payload,
  };
};

const resetGetCouponCode = (payload) => {
  return {
    type: ActionTypes.GET_COUPON_CODE_RESET,
    payload: payload,
  };
};

// Breathalyzer
const getBreathalyzerSchedule = (payload) => {
  return {
    type: ActionTypes.GET_BREATHALYZER_SCHEDULE_REQUEST,
    payload: payload,
  };
};

const resetGetBreathalyzerSchedule = (payload) => {
  return {
    type: ActionTypes.GET_BREATHALYZER_SCHEDULE_RESET,
    payload: payload,
  };
};

const listBreathalyzerSchedule = (payload) => {
  return {
    type: ActionTypes.LIST_BREATHALYZER_SCHEDULE_REQUEST,
    payload: payload,
  };
};

const upsertBreathalyzerSchedule = (payload) => {
  return {
    type: ActionTypes.UPSERT_BREATHALYZER_SCHEDULE_REQUEST,
    payload: payload,
  };
};

const resetUpsertBreathalyzerSchedule = (payload) => {
  return {
    type: ActionTypes.UPSERT_BREATHALYZER_SCHEDULE_RESET,
    payload: payload,
  };
};

const breathalyzerEnable = (payload) => {
  return {
    type: ActionTypes.BREATHALYZER_ENABLE_REQUEST,
    payload: payload,
  };
};

const getBreathalyzerTestResult = (payload) => {
  return {
    type: ActionTypes.GET_BREATHALYZER_TEST_RESULT_REQUEST,
    payload: payload,
  };
};

const getBreathalyzerTestUpcoming = (payload) => {
  return {
    type: ActionTypes.GET_BREATHALYZER_TEST_UPCOMING_REQUEST,
    payload: payload,
  };
};

const breathalyzerTestUpcomingToggle = (payload) => {
  return {
    type: ActionTypes.BREATHALYZER_TEST_UPCOMING_TOGGLE_REQUEST,
    payload: payload,
  };
};

const getStudentBreathalyzerTestResult = (payload) => {
  return {
    type: ActionTypes.GET_STUDENT_BREATHALYZER_TEST_RESULT_REQUEST,
    payload: payload,
  };
};

const breathalyzerPushATest = (payload) => {
  return {
    type: ActionTypes.BREATHALYZER_PUSH_A_TEST_REQUEST,
    payload: payload,
  };
};

const resetBreathalyzerPushATest = (payload) => {
  return {
    type: ActionTypes.BREATHALYZER_PUSH_A_TEST_RESET,
    payload: payload,
  };
};

const resetBreathalyzerTestUpcomingToggle = (payload) => {
  return {
    type: ActionTypes.BREATHALYZER_TEST_UPCOMING_TOGGLE_RESET,
    payload: payload,
  };
};

//Miscellaneous
const miscellaneousTaskGet = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_TASK_GET_REQUEST,
    payload: payload,
  };
};

const miscellaneousTaskListGet = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_TASK_LIST_GET_REQUEST,
    payload: payload,
  };
};

const miscellaneousTaskDelete = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_TASK_DELETE_REQUEST,
    payload: payload,
  };
};

const resetMiscellaneousTaskDelete = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_TASK_DELETE_RESET,
    payload: payload,
  };
};

const miscellaneousTaskUpsert = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_TASK_UPSERT_REQUEST,
    payload: payload,
  };
};

const miscellaneousNoteTypeInsert = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_NOTE_TYPE_INSERT_REQUEST,
    payload: payload,
  };
};

const resetMiscellaneousNoteTypeInsert = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_NOTE_TYPE_INSERT_RESET,
    payload: payload,
  };
};

const resetMiscellaneousTaskUpsert = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_TASK_UPSERT_RESET,
    payload: payload,
  };
};

const miscellaneousComboNoteTypesListGet = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_COMBO_NOTE_TYPES_LIST_GET_REQUEST,
    payload: payload,
  };
};

const miscellaneousNoteTypeDelete = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_NOTE_TYPE_DELETE_REQUEST,
    payload: payload,
  };
};

const resetMiscellaneousNoteTypeDelete = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_NOTE_TYPE_DELETE_RESET,
    payload: payload,
  };
};

const miscellaneousNoteTypesListGet = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_NOTE_TYPES_LIST_GET_REQUEST,
    payload: payload,
  };
};

const miscellaneousNoteTypeGet = (payload) => {
  return {
    type: ActionTypes.MISCELLANEOUS_NOTE_TYPE_GET_REQUEST,
    payload: payload,
  };
};

//Appointments

const practitionerOptionsGet = (payload) => {
  return {
    type: ActionTypes.PRACTITIONER_OPTIONS_GET_REQUEST,
    payload: payload,
  };
};

const facilityOptionsGet = (payload) => {
  return {
    type: ActionTypes.FACILITY_OPTIONS_GET_REQUEST,
    payload: payload,
  };
};

const appointmentListGet = (payload) => {
  return {
    type: ActionTypes.APPOINTMENT_LIST_GET_REQUEST,
    payload: payload,
  };
};

const appointmentUpsert = (payload) => {
  return {
    type: ActionTypes.APPOINTMENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const groupAppointmentUpsert = (payload) => {
  return {
    type: ActionTypes.GROUP_APPOINTMENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const blockAppointmentUpsert = (payload) => {
  return {
    type: ActionTypes.BLOCK_APPOINTMENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const staffPinValidate = (payload) => {
  return {
    type: ActionTypes.STAFF_PIN_VALIDATE_REQUEST,
    payload: payload,
  };
};

const appointmentNoteGet = (payload) => {
  return {
    type: ActionTypes.APPOINTMENT_NOTE_GET_REQUEST,
    payload: payload,
  };
};

const appointmentNoteUpsert = (payload) => {
  return {
    type: ActionTypes.APPOINTMENT_NOTE_UPSERT_REQUEST,
    payload: payload,
  };
};

const appointmentNoteListGet = (payload) => {
  return {
    type: ActionTypes.APPOINTMENT_NOTE_LIST_GET_REQUEST,
    payload: payload,
  };
};

const appointmentNoteAddendumUpsert = (payload) => {
  return {
    type: ActionTypes.APPOINTMENT_NOTE_ADDENDUM_UPSERT_REQUEST,
    payload: payload,
  };
};

const groupAppointmentNoteGet = (payload) => {
  return {
    type: ActionTypes.GROUP_APPOINTMENT_NOTE_GET_REQUEST,
    payload: payload,
  };
};

const groupAppointmentNoteUpsert = (payload) => {
  return {
    type: ActionTypes.GROUP_APPOINTMENT_NOTE_UPSERT_REQUEST,
    payload: payload,
  };
};

const comboPractitioner = (payload) => {
  return {
    type: ActionTypes.COMBO_PRACTITIONER_REQUEST,
    payload: payload,
  };
};

const comboFacility = (payload) => {
  return {
    type: ActionTypes.COMBO_FACILITY_REQUEST,
    payload: payload,
  };
};

const groupOptionGet = (payload) => {
  return {
    type: ActionTypes.GROUP_OPTION_GET_REQUEST,
    payload: payload,
  };
};

const clientGroupOptionsGet = (payload) => {
  return {
    type: ActionTypes.CLIENT_GROUP_OPTIONS_GET_REQUEST,
    payload: payload,
  };
};

export {
  resetLogin,
  forgetPassword,
  resetPassword,
  getDashboard,
  listStudents,
  resetAddRequest,
  addStudent,
  addStudentNote,
  resetStudentNote,
  getStudent,
  upsertStudentPin,
  resetValidatePin,
  resetUpsertPinRequest,
  validateStudentPin,
  resetGetStudent,
  listCombos,
  listFilterCombo,
  listSmartCombos,
  listFullCombos,
  listTodos,
  listTodosByStudent,
  addTodo,
  listActivities,
  listActivitiesByStudent,
  studentDischarge,
  studentReinstate,
  resetStudentReinstate,
  studentUnAssign, // student profile
  studentMarkComplete, // student profile
  getReportData,
  adminstrationListCenter,
  adminstrationGetCenter,
  adminstrationCenterDelete,
  resetCenterGet,
  resetAdminstrationCenterDelete,
  resetCenterDelete,
  comboCenterConsent,
  getCenterAdvisorDefaultCosts,
  updateCenterAdvisorDefaultCost,
  resetUpdateCenterAdvisorDefaultCost,
  centerDelete,
  centerProgramTypeGet,
  centerReactivate,
  resetCenterReactivate,
  centerOptionGet,
  centerUpsert,
  centerListGet,
  centerGet,
  centerStatusUpdate,
  resetCenterStatusUpdate,
  uploadStaffImage,
  resetStaffGet,
  adminstrationStaffDelete,
  resetStaffUpsert,
  resetStaffDelete,
  updateStaffEmail,
  updateStaffPassword,
  updateStaffCenter,
  resetStaffEmail,
  resetStaffPassword,
  resetStaffCenter,
  resetAdminstrationStaffDelete,
  getStaffShiftLog,
  addStaffShiftLog,
  resetStaffShiftLog,
  resetStaffReactivate,
  isStaffProBio,
  updateStaffProBio,
  resetStaffProBio,
  updateStaffPermission,
  resetUpdateStaffPermission,
  getStaffPractitioner,
  validateStaffPin,
  resetValidateStaffPin,
  clinicalCenterGet,
  logDevice,
  logError,
  announcementGetAll,
  announcementGetJson,
  announcementUpsert,
  announcementDelete,
  announcementGetJsonReset,
  resetAnnouncementUpsert,
  resetAnnouncementDelete,
  loadMenu,
  logOut,
  resetStudentUnAssign,
  resetStudentDischarge,
  getStudentsSearch,
  getUserRole,
  resetStudentsSearch,
  getListJobTitle,
  updateJobTitle,
  updateRole,
  deleteRole,
  deleteJobTitle,
  updateRolePermissions,
  updateJobTitleSync,
  resetUpdateJobTitle,
  resetUpdateRole,
  resetDeleteRole,
  resetStudentMarkComplete,
  resetDeleteJobTitle,
  resetUpdateRolePermissions,
  resetUpdateJobTitleSync,
  userFeedback,
  removeUserFeedback,
  isLicenseAvailable,
  getDashboardLoadAppUsers,
  getValidateImage,
  resetGetValidateImage,
  getLicenseActivites,
  getCurrentLicenses,
  getLicensesInvoice,
  getLicensesReceipt,
  setCurrentLicenses,
  addLicenses,
  getPaymentInfo,
  updatePaymentMethode,
  resetPaymentMethode,
  resetAddLicenses,
  isAchValidation,
  achValidate,
  getBillingInfo,
  addNewApplicant,
  getAppSubscribers,
  getAppSubscribersDashboard,
  getAppSubscribersProfile,
  getAppSubscribersChat,
  getAppSubscribersChatCoach,
  getAppSubscribersActivities,
  addAppSubscribersNote,
  addAppSubscribersTask,
  deleteAppSubscribersTask,
  appSubscribersUserSearch,
  resetAppSubscribersTask,
  AppSubscribersCompleteTask,
  resetDeleteAppSubscribersTask,
  resetAppSubscribersCompleteTask,
  resetAddAppSubscribersNote,
  listMobileAppRoleGet,
  spListCombos,
  appSubscribersMapHistory,
  appInvite,
  getMobileAppDownloadStatus,
  updateMobileAppRole,
  resetupdateMobileAppRole,
  addNoteType,
  resetAddNoteType,
  changeAppRole,
  resetChangeAppRole,
  twoStepAuth,
  validatePasscode,
  appInviteDashboard,
  getAllCallSession,
  addCallSession,
  resetAddCallSession,
  getAllVacation,
  addVacationDays,
  resetAddVacationDays,
  getOneCallTimeSlots,
  getOneCallSession,
  inviteOneCallSession,
  resetinviteOneCallSession,
  cancelOneCallSession,
  resetCancelOneCallSession,
  getStripeApiKey,
  editGroupCallSession,
  getCallSessionVideo,
  getCallSessionVideoUrl,
  getReports,
  getPromoCode,
  getCounselor,
  getClientCounselor,
  getDynamicComos,
  sendVoucherCode,
  uploadLeaderPhoto,
  markSystemObservation,
  appMarkSystemObservation,
  getAnalytics,
  getActiveClient,
  isManageLicenseRequired,
  getSmallGroupUsers,
  getNotes,
  getCategoryClient,
  getClientDemographic,
  getCostOfCare,
  getBehavioralRating,
  getScoreImprovement,
  getProviderPerformance,
  getTriggerLocationActivity,
  getServiceDelivered,
  getReferralSource,
  getStudentDocumentList,
  archiveStudentDocument,
  uploadDocument,
  downloadStudentDocument,
  uploadDocumentReset,
  archiveDocumentReset,
  newsUpsert,
  getListNews,
  getNews,
  deleteNews,
  resetNewsUpsert,
  getEmbedInfo,
  getPatientList,
  getPatientDetail,
  upsertPatientEnrollment,
  resetUpsertPatientEnrollment,
  upsertClientInsurance,
  resetUpsertClientInsurance,
  upsertClientMedical,
  resetUpsertClientMedical,
  upsertClientPayment,
  resetUpsertClientPayment,
  upsertProviderEnrollment,
  resetUpsertProviderEnrollment,
  upsertAppointment,
  listAppointment,
  getAppointment,
  deleteAppointment,
  medicateCombos,
  practitionerCombos,
  practitionerCombosOffice,
  upsertGroup,
  listGroup,
  getGroup,
  upsertClientGroup,
  getClientGroup,
  upsertGroupAppointment,
  upsertGroupAppointmentGroupAllInfoJson,
  deleteGroup,
  getListClientGroup,
  patientComboGroup,
  deactivateGroupAppointment,
  upsertClientConsent,
  listClientConsent,
  deactivateClientConsent,
  deactivateClientScreening,
  loadJudicialDashboard,
  getJudicialUserList,
  UploadDocumentUsingMultiPart,
  upsertClientDocument,
  upsertClientDocumentReset,
  listClientDocument,
  downloadClientDocument,
  archiveClientDocument,
  listGroupMembership,
  getConsentFormTemplate,
  resetGetClientGroup,
  getComboFacility,
  downloadClientConsent,
  comboPractitionerAll,
  comboFacilityAll,
  saveClientConsent,
  getTreatmentPlan,
  getDiagnosis,
  upsertTreatmentPlanProblem,
  resetGetMobileAppDownloadStatus,
  upsertMedicalAnalysis,
  resetUpsertMedicalAnalysis,
  upsertTreatmentPlanGoal,
  upsertTreatmentPlanObjective,
  upsertTreatmentPlanIntervention,
  deleteTreatmentPlan,
  getDiagnosisDescription,
  getDiagnosisCode,
  listTreatmentPlan,
  comboProblemTemplate,
  comboGoalTemplate,
  resetListTreatmentPlan,
  recurringCount,
  loadCaseProfile,
  loadLocationAuthHistory,
  screenAuthorization,
  listConsentForm,
  upsertTreatmentPlanDimension,
  saveConsentSign,
  resetSaveConsentSign,
  consentFormDownload,
  listMeeting,
  upsertProgressGroupNote,
  resetUpsertProgressGroupNote,
  getProgressNoteGroup,
  getUserNotePatient,
  getUserNoteComment,
  addUserNoteComment,
  getUserNoteEmoji,
  addUserNoteEmoji,
  comboInterventionTemplate,
  getProgressNoteForIntervention,
  upsertPayer,
  resetUpsertPayer,
  listPayer,
  deletePayer,
  getPayer,
  comboPayer,
  upsertServiceCode,
  resetUpsertServiceCode,
  listServiceCode,
  deleteServiceCode,
  getServiceCode,
  comboCode,
  getCodeDescription,
  appointmentNoteList,
  upsertAppointmentNote,
  getAppointmentNote,
  comboPlaceOfService,
  listServiceLine,
  getServiceLine,
  upsertServiceLine,
  resetUpsertServiceLine,
  upsertAppointmentNoteAddendum,
  resetUpsertAppointmentNote,
  listGroupAppointmentNote,
  getGroupAppointmentNote,
  comboTreatmentPlanIntervention,
  listFeeSchedule,
  getFeeSchedule,
  upsertFeeSchedule,
  resetUpsertFeeSchedule,
  getTreatmentPlanGraph,
  listGroupServiceLine,
  getGroupServiceLine,
  getGroupBanner,
  upsertGroupServiceLine,
  resetUpsertGroupServiceLine,
  upsertGroupAppointmentNote,
  upsertGroupAppointmentNoteAddendum,
  upsertAppointmentNoteIntervention,
  markAsComplete,
  getServiceLineCost,
  upsertPin,
  loadSmallGroupDashboard,
  upsertTreatmentPlan,
  listClientDiagnosis,
  getClientDiagnosis,
  deleteClientDiagnosis,
  validatePinExists,
  cloneDiagnosis,
  cloneTreatmentPlan,
  rcmClientMerge,
  preValidatePin,
  validateClient,
  getAppAdvisor,
  getMediaVideoUrl,
  getDashboardMenu,
  listServiceCodeBundle,
  getServiceCodeBundle,
  getStudentNote,
  getStudentNoteComment,
  addStudentNoteComment,
  addStudentNoteEmoji,
  getStudentNoteEmoji,
  getTreatmentPlanAccordin,
  getAdvisorCost,
  upsertAdvisorCost,
  resetUpsertAdvisorCost,
  claimsPdfDownload,
  getClientService,
  getDashboardDailySchedule,
  getDashboardDailyCaseLoad,
  getDashboardAlert,
  addClientInsuranceEligibility,
  editClientInsuranceEligibility,
  deleteClientInsuranceEligibility,
  dischargeSummarySave,
  getClientAppointment,
  getGroupAppointment,
  unsignForm,
  getFilters,
  listLabOrder,
  getLabOrder,
  upsertLabOrder,
  comboDiagnosisList,
  getMedicationNote,
  upsertClientGroupAppointment,
  signGroupAppointmentNote,
  listServiceLinePayment,
  getServiceLinePayment,
  upsertServiceLinePayment,
  deleteServiceLinePayment,
  comboSupervisor,
  locationDayHistory,
  upsertPaymentClient,
  resetUpsertPaymentClient,
  getInsurancePriorityOrder,
  upsertInsurancePriorityOrder,
  listCWPayment,
  getCWPayment,
  deleteCWPayment,
  pastMeetingInfo,
  listPastMeetingInfo,
  comboAppointmentTreatmentPlan,
  linkAppointment,
  appointmentLinkGet,
  getBasicProfile,
  getInvitedList,
  inviteResend,
  suspiciousTermAck,
  getBroadcastFilter,
  sendBroadcast,
  getServiceLineDiagnosis,
  upsertServiceLineDiagnosis,
  comboBroadcastTemplate,
  deleteNoteType,
  listClientAuthorization,
  getClientAuthorization,
  deleteClientAuthorization,
  upsertClientAuthorization,
  getNoteTypeArchive,
  getNoteTypeList,
  generateCallToken,
  badgeComboGet,
  badgeUpdate,
  panasScaleMoodsGet,
  panasTagUpsert,
  bedAssignmentGetAll,
  bedAssignmentUpsert,
  upcomingDischargesGetAll,
  upcomingAdmissions,
  admissionCancel,
  panasTagDelete,
  dynamicGridList,
  getGrid,
  getGridData,
  upsertFormData,
  formDataGet,
  resetFormDataGet,
  taskOptionGet,
  dynamicGridDataGet,
  memberTrendsGet,
  advisorMemberTrendsHistoryGet,
  dashboardAnalyticsGet,
  userActivityGet,
  userSuspiciousWordGet,
  userNotificationGet,
  userMemberTrendHistory,
  upsertClientNote,
  getPreviousNotes,
  resetUpsertNote,
  getDynamicGridFilter,
  resetDynamicGridFilter,
  memberTrendTriggerHistory,
  memberHeaderInfoGet,
  postListGet,
  getAssessmentDetail,
  resetGetAssessmentDetail,
  assignMultipleV2,
  chatFriendListGet,
  listClientV2,
  populationActivitiesClientListGet,
  resetPopulationActivitiesClientListGet,
  getStudentProfileDashboardData,
  appSubscribersupdateCase,
  resetAppSubscribersupdateCase,
  addStudentCourse,
  resetAddStudentCourse,
  courseDelete,
  resetCourseDelete,
  addAppSubscribersCourse,
  resetAddAppSubscribersCourse,
  deleteAppSubscribersCourse,
  resetDeleteAppSubscribersCourse,
  appSubscribersCompleteCourse,
  resetAppSubscribersCompleteCourse,
  listCoursesByStudent,
  adminstrationListCourse,
  // adminstrationGetCourse,
  // adminstrationCourseUpsert,
  // resetAdminstrationCourseUpsert,
  // adminstrationResetCourse,
  adminstrationCourseDelete,
  resetAdminstrationCourseDelete,
  getContract,
  upsertContract,
  resetUpsertContract,
  resetGetContract,
  deleteContract,
  resetDeleteContract,
  getContractTemplate,
  upsertContractTemplate,
  getStudentContractTemplate,
  resetGetContractTemplate,
  resetUpsertContractTemplate,
  createContractFromTemplate,
  resetCreateContractFromTemplate,
  closeContract,
  menuContractTemplate,
  archiveContractTemplate,
  resetArchiveContractTemplate,
  addFavoriteTemplate,
  resetAddFavoriteTemplate,
  removeFavoriteTemplate,
  resetRemoveFavoriteTemplate,
  getSurveys,
  getSurveyQuestionMultiple,
  surveyGetToAssign, // for list of surveys
  surveyAssign, // assign survey
  surveyTaskMarkInComplete,
  resetSurveyTaskMarkInComplete,
  getStudentSurveyDashboardData,
  resetSurveyAnswersGet,
  surveyAnswersGet,
  resetSurveyAssign,
  addAppSubscribersSurvey,
  resetAppSubscribersSurvey,
  deleteAppSubscribersSurvey,
  resetAppSubscribersUnassignSurvey,
  listSurveyQuestionCategory,
  listSurveyQuestionCategoryAssessment,
  listSurveyQuestionAssessment,
  getSurveyScore,
  loadAppSubSurveyDashboard,
  appSubSurveyAnsGet,
  resetAppSubSurveyAnsGet,
  listSurveyQuestionDischarge,
  getSurveysV2,
  //youu started
  login,
  loadDefaultValues,
  userDataGet,
  resetUserDataGet,
  sideBarMenuGet,
  allGet,
  getDynamicScreenTab,
  resetGetDynamicScreenTab,
  getFormFieldMetaData,
  dynamicGridColumnConfigurationGet,
  getMultistepTab,
  resetGetMultistepTab,
  comboListGet,
  serviceCodeListGet,
  counselorListGet,
  facilityListGet,
  clientListDropdown,
  getUserInfo,
  getClientAlbum,
  clientFriendAllGet,
  assignedCourseListGet,
  courseOptionGet,
  courseAssign,
  courseUnassign,
  courseMarkAsComplete,
  courseUpsert,
  resetCourseUpsert,
  courseGet,
  resetCourseGet,
  courseListGet,
  courseStatusUpdate,
  resetCourseStatusUpdate,
  getAllJournalComments,
  getAllJournal,
  journalAdd,
  journalCommentUpsert,
  taskListGet,
  taskAssign,
  taskUnAssign,
  resetTaskUnAssign,
  taskMarkComplete,
  resetTaskMarkComplete,
  timeTravelListGet,
  triggersListGet,
  assessmentsListGet,
  resetAssessmentsListGet,
  assessmentsMarkAsDiscard,
  assessmentsMultipleAssign,
  surveyQuestionCategoryOptionGet,
  intakeClientUpsert,
  intakeInsuranceUpsert,
  intakeInsurancePriorityOrderGet,
  intakeInsurancePriorityOrderUpdate,
  intakeMedicalHistoryUpsert,
  resetIntakeClientUpsert,
  resetIntakeInsuranceUpsert,
  resetIntakeInsurancePriorityOrderGet,
  resetIntakeInsurancePriorityOrderUpdate,
  resetIntakeMedicalHistoryUpsert,
  intakeFormDataGet,
  resetIntakeFormDataGet,
  intakeEnrollNow,
  resetIntakeEnrollNow,
  preIntakeGridReports,
  preIntakeDefaultAssessmentGet,
  trendsListGet,
  staffListGet,
  staffGet,
  staffUpsert,
  staffDelete,
  staffReactivate,
  staffPermission,
  staffMobileAppInviteSend,
  growthPlanTemplateUpsert,
  resetGrowthPlanTemplateUpsert,
  growthPlanTemplateMenuGet,
  growthPlanTemplateGet,
  resetGrowthPlanTemplateGet,
  growthPlanTemplateFavoriteAdd,
  growthPlanTemplateFavoriteDelete,
  growthPlanTemplateDelete,
  clientGrowthPlanUpsert,
  clientGrowthPlanListGet,
  clientGrowthPlanGet,
  clientGrowthPlanDelete,
  clientGrowthPlanFromTemplateInsert,
  clientGrowthPlanClose,
  getSmallGroupList,
  smallGroupGet,
  resetSmallGroupGet,
  smallGroupUpsert,
  resetSmallGroupUpsert,
  smallGroupReferral,
  smallGroupUserListGet,
  smallGroupUserRemove,
  smallGroupLocationGet,
  smallGroupLocationUpdate,
  smallGroupImagesGet,
  upsertBroadcastTemplate,
  broadcastSend,
  surveyAssignMultiple,
  resetSurveyAssignMultiple,
  surveyAnswerUpsert,
  clientSurveyListGet,
  surveyQuestionCategoryListGet,
  surveyQuestionHierarchialGet,
  surveyMarkAsDiscard,
  surveyAssignedListGet,
  groupUpsert,
  groupGet,
  groupListGet,
  clientGroupUpsert,
  clientsNotParticipatingInGroup,
  clientGroupGet,
  resetClientGroupGet,
  groupAppointmentGroupGet,
  groupAppointmentGroupNotesGet,
  groupServiceLinesGet,
  groupDeactivate,
  resetGroupUpsert,
  resetGroupGet,
  couponListGet,
  upsertCouponCode,
  resetUpsertCouponCode,
  deleteCouponCode,
  resetDeleteCouponCode,
  getCouponCode,
  resetGetCouponCode,
  breathalyzerEnable,
  getBreathalyzerTestResult,
  getBreathalyzerTestUpcoming,
  breathalyzerTestUpcomingToggle,
  resetBreathalyzerPushATest,
  resetBreathalyzerTestUpcomingToggle,
  getStudentBreathalyzerTestResult,
  upsertBreathalyzerSchedule,
  resetUpsertBreathalyzerSchedule,
  listBreathalyzerSchedule,
  getBreathalyzerSchedule,
  breathalyzerPushATest,
  resetGetBreathalyzerSchedule,
  miscellaneousTaskGet,
  miscellaneousTaskListGet,
  miscellaneousTaskDelete,
  resetMiscellaneousTaskDelete,
  miscellaneousTaskUpsert,
  miscellaneousNoteTypeInsert,
  miscellaneousComboNoteTypesListGet,
  miscellaneousNoteTypeDelete,
  miscellaneousNoteTypesListGet,
  miscellaneousNoteTypeGet,
  resetMiscellaneousNoteTypeInsert,
  resetMiscellaneousNoteTypeDelete,
  resetMiscellaneousTaskUpsert,
  practitionerOptionsGet,
  facilityOptionsGet,
  appointmentListGet,
  appointmentUpsert,
  groupAppointmentUpsert,
  blockAppointmentUpsert,
  staffPinValidate,
  appointmentNoteGet,
  appointmentNoteUpsert,
  appointmentNoteListGet,
  appointmentNoteAddendumUpsert,
  groupAppointmentNoteGet,
  groupAppointmentNoteUpsert,
  comboPractitioner,
  comboFacility,
  groupOptionGet,
  clientGroupOptionsGet,
};
