import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
// import PageTitle from "../../../pageTitleConfig";
import Drawer from "@material-ui/core/Drawer";

import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _Location from "../../../images/location.svg";
import { timeTravelListGet, getFilters } from "../../../../redux/actions";
import { useSubheader } from "../../../../_metronic/layout";
import InfiniteScroll from "react-infinite-scroll-component";
import renderHTML from "react-render-html";
import { timeConvert } from "../../../util/time";
import { EventViewDrawer } from "./eventView";

const Timeline = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Timeline");

  const [hasMoreData, setHasMoreData] = useState(true);
  const [ListArray, setListArray] = useState([]);
  const [eventView, setEventView] = useState({
    right: false,
  });

  const toggleEventDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setEventView({ ...eventView, [side]: open });
  };
  const getData = (LastEventDateTime) => {
    dispatch(
      timeTravelListGet({
        TimeLine: [
          {
            ClientId: 40001649,
            StartDate: null,
            EndDate: null,
            FilterBy: null,
            // StudentId: this.getStudentId(),
            // ClientId: this.getClientId(),
            // FilterBy: sessionStorage.getItem(
            //   "timelineFilterMultiCheckboxInteger"
            // ),
            LastEventDateTime: LastEventDateTime,
            // StartDate: this.state.StartDate,
            // EndDate: this.state.EndDate,
          },
        ],
      })
    );
  };

  const fetchMoreData = (LastEventDateTime) => {
    getData(LastEventDateTime);
  };

  useEffect(() => {
    // dispatch(getFilters({}));
    getData();
  }, []);
  // useEffect(() => {
  //   return () => {
  //     dispatch(adminstrationResetCourse());
  //   };
  // }, []);
  const { TimeTravelList, initDates } = useSelector(
    ({
      time_travel_list_get: { data: TimeTravelListData, isFetching, error },
      get_filters,
      suspicious_term_ack,
    }) => {
      return {
        TimeTravelList: TimeTravelListData?.Data?.TimeTravel,
        initDates: TimeTravelListData?.Data?.TimeTravel?.[0].EventDateRange[0],
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (
      TimeTravelList &&
      JSON.stringify(TimeTravelList) != JSON.stringify(ListArray)
    ) {
      let newData = [...ListArray, ...TimeTravelList];
      setListArray(newData);
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [TimeTravelList]);
  return (
    <div class="container-fluid">
      <div className="row">
        <div class="col-12 timeline px-0">
          {ListArray && ListArray.length > 0 ? (
            <div class="pt-4">
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={ListArray.length}
                scrollThreshold="200px"
                next={() => {
                  let { LastEventDateTime } = ListArray[ListArray.length - 1];
                  fetchMoreData(LastEventDateTime);
                }}
                hasMore={hasMoreData}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {ListArray.map((item, index) => {
                  const {
                    EventDateForSort,
                    EventDate,
                    EventTime,
                    LastEventDateTime,
                  } = item;
                  return (
                    <div className="box" key={index}>
                      <div className="row align-items-center">
                        <div className="col-md-4 col-xl-3">
                          {EventDate && <div className="date">{EventDate}</div>}
                        </div>
                      </div>
                      {EventTime &&
                        EventTime.map((event) => {
                          const {
                            EventTime,
                            EventTimeForSort,
                            EventTypeId,
                            EventTypeName,
                            EventIcon,
                            EventTitle,
                            EventDetail,
                            EventWarningTags,
                            EventInfoTags,
                            EventRowClass,
                            EventJson,
                            MiscJson,
                          } = event;
                          let DurationSeconds;
                          let DurationInSeconds;
                          let BreathalyzerScore;
                          let ResultClassBlock;
                          let MoodEmoji;
                          let MoodEmojiPanas;
                          let MiscData;
                          let SparkLineScale;
                          let PanasScale;
                          let PanasScaleData;
                          if (EventTypeId === 3) {
                            let EventJsonData =
                              EventJson && JSON.parse(EventJson);
                            let MiscJsonData = MiscJson && JSON.parse(MiscJson);
                            SparkLineScale =
                              EventJsonData.SurveyUser &&
                              EventJsonData.SurveyUser[0].SparkLineScale;
                            MiscData = MiscJsonData && MiscJsonData.SurveyMisc;
                          }
                          if (EventTypeId === 5) {
                            let EventJsonData =
                              EventJson && JSON.parse(EventJson);
                            DurationInSeconds =
                              EventJsonData.SmallGroup &&
                              EventJsonData.SmallGroup[0].DurationInSeconds.toString();
                          }
                          if (EventTypeId === 8) {
                            let EventJsonData =
                              EventJson && JSON.parse(EventJson);
                            DurationSeconds =
                              EventJsonData.VMCall &&
                              EventJsonData.VMCall[0].DurationSeconds;
                          }
                          if (EventTypeId === 7) {
                            let EventJsonData =
                              EventJson && JSON.parse(EventJson);
                            BreathalyzerScore =
                              EventJsonData.BreathalyzerTestResult &&
                              EventJsonData.BreathalyzerTestResult[0].Result;
                            ResultClassBlock =
                              EventJsonData.BreathalyzerTestResult &&
                              EventJsonData.BreathalyzerTestResult[0]
                                .ResultClassBlock;
                          }
                          if (EventTypeId === 14) {
                            let EventJsonData =
                              EventJson && JSON.parse(EventJson);
                            let MoodMiscData = MiscJson && JSON.parse(MiscJson);
                            MoodEmoji =
                              EventJsonData.Mood &&
                              EventJsonData.Mood[0].MoodEmoji;
                            SparkLineScale =
                              EventJsonData.Mood &&
                              EventJsonData.Mood[0].SparkLineScale;
                            MiscData = MoodMiscData && MoodMiscData.MoodMisc;
                          }
                          if (EventTypeId === 26) {
                            let EventJsonData =
                              EventJson && JSON.parse(EventJson).Mood;
                            let MoodMiscData =
                              MiscJson && JSON.parse(MiscJson).PANASScale;
                            MoodEmojiPanas =
                              MoodMiscData &&
                              MoodMiscData[MoodMiscData.length - 1].MoodEmoji;
                            PanasScale = EventJsonData;
                            PanasScaleData = MoodMiscData;
                          }

                          // headerFlags: [
                          //   {
                          //     text: 'Alcohol',
                          //     uppercase: true,
                          //     warning: true,
                          //     icon: true,
                          //     iconPath: 'images/InterventionComplete.svg',
                          //   },
                          // ],

                          return (
                            <>
                              <div
                                className={`row align-items-center mt-2 ${
                                  EventRowClass ? EventRowClass : "row_default"
                                }`}
                              >
                                <div className="col-md-2 time">{EventTime}</div>
                                <div className="col-md-2 d-flex justify-content-center">
                                  <div className="icon">
                                    <img src={_Location} />
                                    {/* <img src={`images/timeline/${EventIcon}`} /> */}
                                  </div>
                                </div>
                                <div className="col-md-8 event-details">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div
                                      className="event-left-item"
                                      style={{
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      <div className="header-content ">
                                        {" "}
                                        <span
                                          style={{
                                            cursor: EventJson && "pointer",
                                          }}
                                          // onClick={(e) => {
                                          //   e.stopPropagation();
                                          //   EventJson &&
                                          //     this.setState({
                                          //       eventTriggerModal: true,
                                          //       EventJsonData: JSON.parse(
                                          //         EventJson
                                          //       ),
                                          //       EventTitle: EventTitle,
                                          //       EventTypeName: EventTypeName,
                                          //       EventTypeId: EventTypeId,
                                          //     });
                                          // }}
                                          // onClick={(e) => {
                                          //   // e.stopPropagation();
                                          //   toggleEventDrawer("right", true);
                                          // }}
                                          onClick={toggleEventDrawer(
                                            "right",
                                            true
                                          )}
                                        >
                                          {EventTitle}
                                        </span>{" "}
                                        {EventWarningTags && (
                                          <span
                                            className="text-uppercase"
                                            style={{
                                              backgroundColor: `#FF7979`,
                                              color: `white`,
                                              fontSize: "14px",
                                              fontWeight: 400,
                                              borderRadius: "2px",
                                              padding: "0px 7px",
                                              marginLeft: "23px",
                                              display: "inline-flex",
                                            }}
                                          >
                                            {/* {icon && (
                                                <img
                                                  src={`images/timeline/${iconPath}`}
                                                  style={{ paddingRight: '5px' }}
                                                />
                                              )} */}
                                            <span>{EventWarningTags}</span>
                                          </span>
                                        )}
                                        {EventInfoTags && (
                                          <span
                                            className="text-uppercase"
                                            style={{
                                              backgroundColor: `#4facfe`,
                                              color: `white`,
                                              fontSize: "16px",
                                              borderRadius: "2px",
                                              padding: "0px 7px",
                                              marginLeft: "23px",
                                              display: "inline-flex",
                                            }}
                                          >
                                            {/* {icon && (
                                                <img
                                                  src={`images/timeline/${iconPath}`}
                                                  style={{ paddingRight: '5px' }}
                                                />
                                              )} */}
                                            <span>{EventInfoTags}</span>
                                          </span>
                                        )}
                                      </div>
                                      <div className="desc mt-2">
                                        {MoodEmoji && (
                                          <div className="mr-2">
                                            <img
                                              src={`images/timeline/emoji/${MoodEmoji}`}
                                              height="30"
                                            />
                                          </div>
                                        )}
                                        {MoodEmojiPanas && (
                                          <div className="mr-2">
                                            <img
                                              src={MoodEmojiPanas}
                                              height="60"
                                              borderRadius="50%"
                                            />
                                          </div>
                                        )}
                                        {EventDetail && renderHTML(EventDetail)}
                                      </div>
                                      {DurationInSeconds && (
                                        <div className="desc mt-2">
                                          <span>
                                            Small Group Meeting Duration :{" "}
                                            <strong>
                                              {timeConvert(
                                                parseInt(DurationInSeconds)
                                              )}
                                            </strong>
                                          </span>
                                        </div>
                                      )}
                                      {DurationSeconds && (
                                        <div className="desc mt-2">
                                          <span>
                                            Meeting Duration :{" "}
                                            <strong>
                                              {timeConvert(DurationSeconds)}
                                            </strong>
                                          </span>
                                        </div>
                                      )}
                                      {BreathalyzerScore && (
                                        <div className="desc mt-2 time-line-table ">
                                          <span>
                                            Breathalyzer Test Result :{" "}
                                          </span>
                                          <span className={ResultClassBlock}>
                                            {" "}
                                            <strong>{BreathalyzerScore}</strong>
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    {/* <div className="event-right-item">
                                          {MiscData && (
                                            <SparkLineComponent
                                              MiscData={MiscData}
                                              SparkLineScale={SparkLineScale}
                                            />
                                          )}
                                          {PanasScaleData && (
                                            <PanasScaleChart
                                              MiscData={PanasScaleData}
                                              PanasScale={PanasScale}
                                            />
                                          )}
                                        </div> */}
                                  </div>

                                  {/* {EventJson && (
                                            <div className="event-hyperlink">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    eventTriggerModal: true,
                                                    EventJsonData: JSON.parse(EventJson),
                                                    EventTitle: EventTitle,
                                                    EventTypeName: EventTypeName,
                                                    EventTypeId: EventTypeId,
                                                  });
                                                }}
                                              >
                                                Click here to view
                                              </span>
                                            </div>
                                          )} */}
                                </div>

                                {/* <div123 className="col-md-8 event-details">
                                            <div className="header-content ">
                                              {' '}
                                              {header}
                                              {headerFlags.map(headerFlagContent => {
                                                const {
                                                  text,
                                                  uppercase,
                                                  warning,
                                                  icon,
                                                  iconPath,
                                                } = headerFlagContent;
                                                return (
                                                  <span
                                                    className={
                                                      uppercase
                                                        ? `text-uppercase `
                                                        : `text-lowercase`
                                                    }
                                                    style={{
                                                      backgroundColor: warning
                                                        ? `#FF7979`
                                                        : `#E0E0E0`,
                                                      color: warning == true ? `white` : `#515151`,
                                                      fontSize: '16px',
                                                      borderRadius: '2px',
                                                      padding: '0px 7px',
                                                      marginLeft: '23px',
                                                      display: !warning
                                                        ? 'inline-flex'
                                                        : 'inline-block',
                                                    }}
                                                  >
                                                    {icon && (
                                                      <img
                                                        src={`images/timeline/${iconPath}`}
                                                        style={{ paddingRight: '5px' }}
                                                      />
                                                    )}
                                                    <span>{text}</span>
                                                  </span>
                                                );
                                              })}
                                            </div>
                                            <div className="desc">{desc}</div>
                                          </div123>*/}
                              </div>
                            </>
                          );
                        })}
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          ) : (
            <div class="col-12 text-center mb-3">
              <h4>No Time Travel Events</h4>
            </div>
          )}
        </div>
      </div>
      {eventView.right && (
        <Drawer
          transitionDuration={{ enter: 500, exit: 1000 }}
          anchor="right"
          open={eventView.right}
          onClose={toggleEventDrawer("right", false)}
        >
          <div role="presentation" style={{ width: "800px" }}>
            <EventViewDrawer
              toggleEventDrawer={toggleEventDrawer}
              // onFilter={onFilter}
            />
          </div>
        </Drawer>
      )}
    </div>
  );
};
export default Timeline;
