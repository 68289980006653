import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { clientGroupOptionsGet } from "../../../../redux/actions";
import { ViewNonParticipantsDrawer } from "./drawer/ViewNonParticipantsDrawer";
import _Remove from "../../../images/Shape.svg";
import _blank from "../../../images/blank-profile.png";
import _Close from "../../../images/close.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
  button: {
    display: "block",
    margin: "0 auto",
  },
}));

const SelectPatient = (props) => {
  const { isMulti, name } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openRemoveClientDialog, setOpenRemoveClientDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [checkedArray, setCheckedArray] = useState([]);
  const [clientToRemove, setClientToRemove] = useState("");

  //Will use for checkbox
  const handleChecked = (value, event) => {
    event.persist();
    if (event.target.checked) {
      if (checkedArray.includes(event.target.value)) {
        return;
      } else {
        setCheckedArray([...checkedArray, event.target.value]);
      }
    } else if (!event.target.checked) {
      const index = checkedArray.indexOf(event.target.value);
    }
    setSelectedClient([...checkedArray, event.target.value]);
    props.getSelectedClient([...checkedArray, event.target.value].toString());
  };

  const handleRadioChange = (value) => {
    setSelectedClient(value);
    props.getSelectedClient(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenRemoveClientDialog(false);
  };

  const removeindividual = (isOpen, userId) => {
    if (isOpen) {
      setOpenRemoveClientDialog(isOpen);
    }
    setClientToRemove(userId);
  };

  const { clientGroupOptionsGetData } = useSelector(
    ({ client_group_options_get: { data } }) => {
      return {
        clientGroupOptionsGetData: data?.Data?.Clients,
      };
    },
    shallowEqual
  );

  return (
    <>
      <div className="row form-group">
        <span className="col-6">Select Client</span>

        <div className="col-12">
          <div
            className="p-4"
            style={{
              border: "1px solid grey",
              maxHeight: "155px",
              overflowY: "auto",
            }}
          >
            {!isMulti && (
              <div class="row">
                {selectedClient &&
                  clientGroupOptionsGetData &&
                  clientGroupOptionsGetData.map((item) => {
                    if (item.Value === selectedClient) {
                      return (
                        <>
                          {" "}
                          <div
                            class="d-flex align-items-center m-1 p-3"
                            style={{
                              backgroundColor: "#F7F8FA",
                              width: "fit-content",
                              borderRadius: "2px",
                            }}
                          >
                            <img
                              src={item.ProfileImageUrl || _blank}
                              alt=""
                              style={{
                                height: "42px",
                                width: "42px",
                                borderRadius: "50%",
                              }}
                            />
                            <p className="ml-2 mb-0">{item.Text}</p>
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                removeindividual(true, item.Value);
                              }}
                            >
                              <SVG
                                className="ml-2"
                                src={toAbsoluteUrl(_Remove)}
                              />
                            </span>
                          </div>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            )}
            {isMulti && (
              <div class="row">
                {selectedClient &&
                  clientGroupOptionsGetData &&
                  clientGroupOptionsGetData.map((item) => {
                    if (selectedClient.includes(item.Value.toString())) {
                      return (
                        <>
                          {" "}
                          <div
                            class="d-flex align-items-center m-1 p-3"
                            style={{
                              backgroundColor: "#F7F8FA",
                              width: "fit-content",
                              borderRadius: "2px",
                            }}
                          >
                            <img
                              src={item.ProfileImageUrl || _blank}
                              alt=""
                              style={{
                                height: "42px",
                                width: "42px",
                                borderRadius: "50%",
                              }}
                            />
                            <p className="ml-2 mb-0">{item.Text}</p>
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => removeindividual(true, item.Value)}
                            >
                              <SVG
                                className="ml-2"
                                src={toAbsoluteUrl(_Remove)}
                              />
                            </span>
                          </div>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            )}

            <div className="d-flex justify-content-center align-items-center">
              {selectedClient?.length === 0 && (
                <div>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={isMulti && !props.isGroupSelected}
                    className={classes.button}
                    onClick={() => {
                      setOpenDialog(true);
                      dispatch(
                        clientGroupOptionsGet({
                          ClientGroup: [
                            {
                              Search: null,
                              GroupId: props?.GroupId || null,
                            },
                          ],
                        })
                      );
                    }}
                  >
                    Select Client
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={"md"}
          maxWidth={"md"}
        >
          <DialogTitle id="alert-dialog-title">
            {"Select Client"}

            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={() => {
                setSelectedClient("");
                handleClose();
              }}
              style={{ marginLeft: "777px" }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ViewNonParticipantsDrawer
                isMulti={isMulti}
                name={name}
                GroupId={props.GroupId}
                moreItem
                handleClose={handleClose}
                handleChecked={handleChecked}
                handleRadioChange={handleRadioChange}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{
                height: "66px",
                width: "93%",
                backgroundColor: "white",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                disabled={selectedClient.length <= 0}
                className="ml-5"
                onClick={() => {
                  handleClose();
                  props.getSelectedClient(selectedClient);
                  setCheckedArray("");
                }}
              >
                {isMulti ? "Select Group Members" : "Select Client"}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openRemoveClientDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove this client?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="ml-5"
              onClick={() => {
                setOpenRemoveClientDialog(false);
                if (!isMulti) {
                  setSelectedClient("");
                } else {
                  let a =
                    selectedClient &&
                    selectedClient.filter(
                      (item) => item != clientToRemove.toString()
                    );
                  setSelectedClient(a);
                }
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default SelectPatient;
