import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import formDataHandler from "../../util/formDataHandler";
import { ReduxToggle } from "../../components/redux-component";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from "redux-form";
import {
  getFormFieldMetaData,
  formDataGet,
  resetFormDataGet,
  upsertFormData,
  staffReactivate,
  staffDelete,
  staffUpsert,
  resetStaffUpsert,
  listCombos,
  appInvite,
  staffGet,
  resetStaffGet
} from "../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));



const AppForm = (props) => {
  const [formFields, setFormFields] = useState([]);
  const [isProvider, setIsProvider] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const suhbeader = useSubheader();
  suhbeader.setTitle(props.StaffId ? "Edit Staff" : "Add New Staff");
  const selector = formValueSelector("appForm");

  const {
    genericFormData,
    isFetching,
    IsProviderValue,
    MobileAppRoleValue,
  } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data },
    } = state;
    return {
      genericFormData: data?.Data?.FormFieldMetaData || [],
      IsProviderValue: selector(state, "IsProvider"),
      MobileAppRoleValue: selector(state, "MobileAppRole"),
    };
  }, shallowEqual);

  useEffect(() => {
    if (genericFormData && genericFormData.length) {
      setFormFields(genericFormData);
    }
  }, [genericFormData]);

  useEffect(() => {
    setIsProvider(IsProviderValue);
  }, [IsProviderValue]);

  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    StaffId,
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="staff-form pt-2 pb-2">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      {/* {!isPhone && (
                        <span className="">
                          {" "}
                          <i class="fas fa-info-circle"></i> Please enter mobile
                          phone number in basic details to enable.
                        </span>
                      )} */}
                      <div class="form-group mt-2">
                        <Field
                          name={`IsProvider`}
                          fieldName={`Is Provider`}
                          type="text"
                          component={ReduxToggle}
                          // disabled={isDeactivated || !isPhone}
                          labelText={"Mobile platform user?"}
                        />
                      </div>
                    </div>
                  </div>
                  {isProvider && (
                    <>
                      <div className="col-12 mb-4">
                        <div
                          className="row p-4 d-none"
                          style={{ backgroundColor: "#F8F8F8" }}
                        >
                          {/* <Loader2 loading={this.props.isFetching} /> */}
                          <div className="col-md-8">
                            <h5 className="col-12 col-md-6 col-lg-4">
                              Mobile App Invite
                            </h5>
                            {/* <div className="col-12">
                              {MobileAppInviteMsg && (
                                <img src="images/icons/info.svg" />
                              )}{" "}
                              {MobileAppInviteMsg}
                            </div> */}
                          </div>
                          <div className="col-md-4 text-center">
                            {MobileAppRoleValue && (
                              <>
                                {false ? (
                                  <div className="d-flex align-items-end flex-column">
                                    <span style={{ color: "#2FE192" }}>
                                      Invitation sent
                                    </span>
                                    <span
                                      style={{
                                        color: "#009EF7",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() => {
                                        dispatch(
                                          appInvite({
                                            StaffId: StaffId,
                                            MobileAppRole: MobileAppRoleValue,
                                            // PhoneNumber: '214-555-7868',
                                          })
                                        );
                                      }}
                                    >
                                      Click here to resend the invite
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <span
                                      className="btn btn-outline-primary"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        dispatch(
                                          appInvite({
                                            StaffId: StaffId,
                                            MobileAppRole: MobileAppRoleValue,
                                            // PhoneNumber: '214-555-7868',
                                          })
                                        );
                                      }}
                                    >
                                      Invite
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row pb-5 pl-3 pr-3 pt-3">
                        <DynamicForm formFields={formFields} disable={false} />
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-left">
                    {StaffId && (
                      <>
                        {activeStep != 0 && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                              history.push(
                                `/admin/staff/edit-form?tab=roles-and-permissions&id=${props.StaffId ||
                                  props.staffId}`
                              );
                              handleBack();
                            }}
                            disabled={activeStep === 0}
                          >
                            Back
                          </Button>
                        )}
                        &nbsp;
                        {NextTab && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                              history.push(
                                `/admin/staff/edit-form?tab=provider&id=${props.StaffId}`
                              );
                              handleNext();
                            }}
                            disabled={!NextTab}
                          >
                            Next
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <div className="text-right">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      type="submit"
                      disabled={pristine || submitting}
                    >
                      Save
                    </Button>
                    &nbsp;
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      disabled={pristine || submitting}
                      onClick={reset}
                    >
                      Clear Values
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const ReduxAppForm = reduxForm({
  form: "appForm",
  enableReinitialize: true,
})(AppForm);

const AppDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getStaffId = () => {
    let param = new URLSearchParams(history.location.search);
    let staffId = param.get("id");
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };
  
  const { staffData, isStaffUpsert, isFetching, staffId,isProviderTabComing} = useSelector(
      (state) => {
        const {
          staff_upsert: { data: upsertData },
          staff_get: { data: staffUser },
          get_multistep_tab: { data: tabData },
        } = state;
        return {
          staffData: staffUser?.Data?.Staffs?.[0] || {},
          isStaffUpsert: upsertData?.ResponseCode === 1,
          staffId: parseInt(upsertData?.Data?.Id),
          isProviderTabComing:tabData?.Data?.ScreenTabs?.[0]?.NextTab?.[0]?.TabName=="Provider" ? true:false
        };
      },
      shallowEqual
    );

  useEffect(() => {
    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: "Staff_MobileAppDetails",
            Id: props.StaffId,
            FormDataId: props.StaffId,
          },
        ],
      })
    );

    if (props.StaffId || props.StaffId === 0) {
      dispatch(
        staffGet({
          Staff: [
            {
              StaffId: props.StaffId,
            },
          ],
        })
      );
    }
    return () => {
      dispatch(resetStaffGet({}));
    };
  }, []);

  const onSubmit = (values) => {
    let ObjectData = formDataHandler(values);
    const formData = {
      Staff: [
        {
          ...staffData,
          IsProvider: values.IsProvider,
          InviteDetails: null,
          Misc: [{ ...ObjectData }],
        },
      ],
    };
    console.log("appDetails", formData);
    dispatch(staffUpsert(formData));
  };

  useEffect(() => {
    if (isStaffUpsert) {

      if (isProviderTabComing) {
        history.push(`/admin/staff/edit-form?tab=provider&id=${props.StaffId}`);
          dispatch(resetStaffUpsert());
          props.handleNext();
      } else {
        history.push(`/admin/staff/list`);
          dispatch(resetStaffUpsert());
      }

      // dispatch(resetStaffUpsert());
      // props.handleNext();
    }
  }, [isStaffUpsert]);
  return (
    <div className="">
      <ReduxAppForm
        initialValues={
          staffData &&
          staffData.Misc && {
            IsProvider: staffData.IsProvider,
            ...staffData.Misc[0],
          }
        }
        StaffId={getStaffId()}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
        activeStep={props.activeStep}
        NextTab={props.NextTab}
        onSubmit={onSubmit}
        // onCancel={() =>
        //   this.props.history.push("/manage/staff-management/staff-list")
        // }
        // updateStep={this.props.updateStep}
        // formData={this.props.initialValues}
        // getStaffStatus={this.props.getStaffStatus}
      />
    </div>
  );
};

export default AppDetail;
