import React, { Component, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
const ModalData = (props) => {
  const { memberTrendTriggerHistoryData } = useSelector(
    ({ member_trend_trigger_history }) => {
      return {
        memberTrendTriggerHistoryData:
          (member_trend_trigger_history &&
            member_trend_trigger_history.data &&
            member_trend_trigger_history.data.Trigger &&
            member_trend_trigger_history.data.Trigger[0].TriggerHistory) ||
          [],
      };
    },
    shallowEqual
  );
  return (
    <>
      {memberTrendTriggerHistoryData &&
        memberTrendTriggerHistoryData.length > 0 &&
        memberTrendTriggerHistoryData.map((item) => {
          return <p key={uuid()}>{item.DisplayName}</p>;
        })}
    </>
  );
};
export default ModalData;
