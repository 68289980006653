import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* trendsListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.trendsListGet(payload);
    yield put({
      type: ActionTypes.TRENDS_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TRENDS_LIST_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.TRENDS_LIST_GET_REQUEST, trendsListGet)]);
}
