import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";

const ReduxInput = ({
	input,
	type,
	name,
	id,
	value,
	onChange,
	onBlur,
	placeholder,
	defaultValue,
	meta,
	className,
	disabled,
	maxLength,
	mobileFormat,
	tabIndex,
	style,
	fieldName,
	min,
	max,
	controlledMaxAge,
	negativeNumberBlocked,
	showPlaceHolder,
	labelText,
}) => {
	var { touched, error, warning, active } = meta || {};
	if (input) {
		defaultValue = input.defaultValue;
		// value = input.value.toString();
		value = input.value && input.value.toString();
		onChange = input.onChange;
		onBlur = input.onBlur;
		name = input.name;
	}

	/*if(mobileFormat){
        value = Util.formatPhoneNumber(value);
    }*/

	return (
		<>
			<FormControl
				fullWidth
				className="formControl"
				error={touched && error}
			>
				<InputLabel htmlFor={id} variant="filled">
					{labelText}
				</InputLabel>
				{!controlledMaxAge ? (
					!negativeNumberBlocked ? (
						<OutlinedInput
							{...input}
							style={{ height: "36px", marginTop: "4px" }}
							tabIndex={tabIndex}
							type={type}
							name={name}
							id={id}
							defaultValue={defaultValue}
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							// placeholder={
							//   showPlaceHolder ? placeholder : active && placeholder
							// }
							// className={`${className} ${value &&
							// 	"not-empty"} ${type == "textarea" &&
							// 	"textarea-height"}`}
							disabled={!!disabled}
							maxLength={maxLength}
							autoComplete="off"
							// placeholder={`Enter ${labelText}`}
							// disableUnderline={true}
						/>
					) : (
						<OutlinedInput
							{...input}
							style={{ height: "36px", marginTop: "4px" }}
							tabIndex={tabIndex}
							type={type}
							name={name}
							id={id}
							defaultValue={defaultValue}
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							// placeholder={active && placeholder}
							className={`${className} ${value && "not-empty"}`}
							disabled={!!disabled}
							maxLength={maxLength}
							autoComplete="off"
							min={min}
							max={max}
						/>
					)
				) : (
					<OutlinedInput
						{...input}
						style={{ height: "36px", marginTop: "4px" }}
						tabIndex={tabIndex}
						type={type}
						name={name}
						id={id}
						defaultValue={defaultValue}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						// placeholder={active && placeholder}
						className={`${className} ${value && "not-empty"}`}
						disabled={!!disabled}
						maxLength={maxLength}
						autoComplete="off"
						min={min}
						max={max}
						//   onKeyPress={function(evt) {
						//     evt.preventDefault();
						//   }
						// }
					/>
				)}

				{touched &&
					((error && fieldName && (
						<FormHelperText id="component-error-text">
							{" "}
							{fieldName} {error}
						</FormHelperText>
					)) ||
						(warning && (
							<span className="warning-color">{warning}</span>
						)))}
			</FormControl>
		</>
	);
};

export { ReduxInput };
