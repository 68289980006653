import React from "react";
import moment from "moment";
export const DateFormatter = (cell, row, rowIndex, formatExtraData) => {
  return (
    <div
      className="d-flex align-items-center"
      style={{ minWidth: "180px", maxWidth: "200px" }}
    >
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <div>{cell && <span>{moment(cell).format("DD-MMM-YYYY")}</span>}</div>
        </div>
      </div>
    </div>
  );
};
