import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { getFormFieldMetaData } from "../../../redux/actions";

import {
  ReduxAppointmentNewClient,
  ReduxAppointmentExistingClient,
  ReduxGroupAppointment,
  ReduxBlockAppointment,
} from "./forms";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const AppointmentModal = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("NewClient");
  const { getCurrentAppointmentType } = props;
  const handleChange = (event) => {
    setValue(event.target.value);
    getCurrentAppointmentType(event.target.value);
  };

  const { formFields } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Appointment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Participant Type</FormLabel>
            <RadioGroup
              aria-label="participantType"
              name="participantType"
              className={classes.group}
              value={value}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="NewClient"
                control={<Radio />}
                label="New Client"
              />
              <FormControlLabel
                value="ExistingClient"
                control={<Radio />}
                label="Existing Client"
              />
              <FormControlLabel
                value="GroupAppointment"
                control={<Radio />}
                label="Group Appointment"
              />{" "}
              <FormControlLabel
                value="BlockAppointment"
                control={<Radio />}
                label="Block Appointment"
              />
            </RadioGroup>
          </FormControl>
          <div style={{ minHeight: "900px" }}>
            {value == "NewClient" && (
              <ReduxAppointmentNewClient
                formFields={formFields}
                initialValues={{
                  RecurringType: "None",
                }}
              />
            )}
            {value == "ExistingClient" && (
              <ReduxAppointmentExistingClient
                formFields={formFields}
                initialValues={{
                  RecurringType: "None",
                }}
              />
            )}
            {value == "GroupAppointment" && (
              <ReduxGroupAppointment
                formFields={formFields}
                initialValues={{
                  RecurringType: "None",
                }}
              />
            )}
            {value == "BlockAppointment" && (
              <ReduxBlockAppointment
                formFields={formFields}
                initialValues={{
                  RecurringType: "None",
                }}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.onHide}
          >
            Add
          </Button>
        </Modal.Footer> */}
    </Modal>
  );
};

export default AppointmentModal;
