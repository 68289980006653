import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import formDataHandler from "../../util/formDataHandler";
import {
  getFormFieldMetaData,
  upsertCouponCode,
  resetUpsertCouponCode,
  getCouponCode,
  resetGetCouponCode,
} from "../../../redux/actions";

import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

let CForm = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    props.couponId ? "Edit Referral Codes" : "Add New Referral Codes"
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    props.reset();
    // return () => {};
  }, []);

  const selector = formValueSelector("couponForm");

  const { genericFormData } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data, isFetching, error },
    } = state;
    return {
      genericFormData: data?.Data?.FormFieldMetaData || [],
    };
  }, shallowEqual);

  const { handleSubmit, pristine, reset, submitting, onSubmit } = props;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="enrollment-form staff-form pt-2 pb-2">
                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    <DynamicForm formFields={genericFormData} disable={false} />
                  </div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Submit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
const ReduxCForm = reduxForm({
  form: "couponForm",
  enableReinitialize: true,
})(CForm);

const CouponForm = (props) => {
  const dispatch = useDispatch();
  const [couponDataInit, setCouponDataInit] = useState({});
  const history = useHistory();
  const getId = () => {
    var param = new URLSearchParams(history.location.search);
    var couponId = param.get("id");
    if (couponId) {
      couponId = parseInt(couponId);
    } else {
      couponId = null;
    }
    return couponId;
  };
  useEffect(() => {
    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: "ReferralCodes",
          },
        ],
      })
    );
    if (getId()) {
      dispatch(getCouponCode({ Coupon: { CouponId: getId() } }));
    }
    return () => {
      dispatch(resetGetCouponCode());
      dispatch(reset("couponForm"));
    };
  }, []);
  const { couponDataObj, isInserted } = useSelector(
    ({
      get_coupon_code: { data },
      upsert_coupon_code: { data: upsertData },
    }) => {
      return {
        couponDataObj: data?.Data?.Coupon?.[0],
        isInserted: upsertData?.ResponseCode === 1,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (isInserted) {
      dispatch(resetUpsertCouponCode());
      history.push("/admin/coupon/list");
    }
  }, [isInserted]);

  useEffect(() => {
    if (couponDataObj) {
      setCouponDataInit(couponDataObj);
    }
  }, [couponDataObj]);

  const onSubmit = (values) => {
    let couponId = null;
    let paramCouponId = getId();
    if (paramCouponId && paramCouponId > 0) {
      couponId = paramCouponId;
    }
    let ObjectData = formDataHandler(values);

    const data = {
      Coupon: [
        {
          CouponId: couponId ? couponId : null,
          ...ObjectData,
        },
      ],
    };
    dispatch(upsertCouponCode(data));
  };
  return (
    <ReduxCForm
      onSubmit={onSubmit}
      initialValues={couponDataInit}
      couponId={getId()}
    />
  );
};
export default CouponForm;
