import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* groupUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.groupUpsert(payload);
    yield put({
      type: ActionTypes.GROUP_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_UPSERT_FAILURE,
    });
  }
}

export function* groupGet(action) {
  try {
    const { payload } = action;
    const response = yield API.groupGet(payload);
    yield put({
      type: ActionTypes.GROUP_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_GET_FAILURE,
    });
  }
}

export function* groupListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.groupListGet(payload);
    yield put({
      type: ActionTypes.GROUP_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_LIST_GET_FAILURE,
    });
  }
}

export function* clientGroupUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.clientGroupUpsert(payload);
    yield put({
      type: ActionTypes.CLIENT_GROUP_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_GROUP_UPSERT_FAILURE,
    });
  }
}

export function* clientsNotParticipatingInGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.clientsNotParticipatingInGroup(payload);
    yield put({
      type: ActionTypes.CLIENTS_NOT_PARTICIPATING_IN_GROUP_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENTS_NOT_PARTICIPATING_IN_GROUP_FAILURE,
    });
  }
}

export function* clientGroupGet(action) {
  try {
    const { payload } = action;
    const response = yield API.clientGroupGet(payload);
    yield put({
      type: ActionTypes.CLIENT_GROUP_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_GROUP_GET_FAILURE,
    });
  }
}

export function* groupAppointmentGroupGet(action) {
  try {
    const { payload } = action;
    const response = yield API.groupAppointmentGroupGet(payload);
    yield put({
      type: ActionTypes.GROUP_APPOINTMENT_GROUP_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_APPOINTMENT_GROUP_GET_FAILURE,
    });
  }
}

export function* groupAppointmentGroupNotesGet(action) {
  try {
    const { payload } = action;
    const response = yield API.groupAppointmentGroupNotesGet(payload);
    yield put({
      type: ActionTypes.GROUP_APPOINTMENT_GROUP_NOTES_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_APPOINTMENT_GROUP_NOTES_GET_FAILURE,
    });
  }
}

export function* groupServiceLinesGet(action) {
  try {
    const { payload } = action;
    const response = yield API.groupServiceLinesGet(payload);
    yield put({
      type: ActionTypes.GROUP_SERVICE_LINES_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_SERVICE_LINES_GET_FAILURE,
    });
  }
}

export function* groupDeactivate(action) {
  try {
    const { payload } = action;
    const response = yield API.groupDeactivate(payload);
    yield put({
      type: ActionTypes.GROUP_DEACTIVATE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_DEACTIVATE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GROUP_UPSERT_REQUEST, groupUpsert),
    takeLatest(ActionTypes.GROUP_GET_REQUEST, groupGet),
    takeLatest(ActionTypes.GROUP_LIST_GET_REQUEST, groupListGet),
    takeLatest(ActionTypes.CLIENT_GROUP_UPSERT_REQUEST, clientGroupUpsert),
    takeLatest(
      ActionTypes.CLIENTS_NOT_PARTICIPATING_IN_GROUP_REQUEST,
      clientsNotParticipatingInGroup
    ),
    takeLatest(ActionTypes.CLIENT_GROUP_GET_REQUEST, clientGroupGet),
    takeLatest(
      ActionTypes.GROUP_APPOINTMENT_GROUP_GET_REQUEST,
      groupAppointmentGroupGet
    ),
    takeLatest(
      ActionTypes.GROUP_APPOINTMENT_GROUP_NOTES_GET_REQUEST,
      groupAppointmentGroupNotesGet
    ),
    takeLatest(
      ActionTypes.GROUP_SERVICE_LINES_GET_REQUEST,
      groupServiceLinesGet
    ),
    takeLatest(ActionTypes.GROUP_DEACTIVATE_REQUEST, groupDeactivate),
  ]);
}
