import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  reset,
} from "redux-form";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import formDataHandler from "../../util/formDataHandler";
import {
  getFormFieldMetaData,
  growthPlanTemplateUpsert,
  resetGrowthPlanTemplateUpsert,
  growthPlanTemplateGet,
  resetGrowthPlanTemplateGet,
  comboListGet,
} from "../../../redux/actions";
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxInput,
  ReduxTextarea,
} from "../../components/redux-component";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const FieldConfigrations = [
  {
    name: "MinorThemes",
    data: {
      title: "Minor Theme Record",
      btnText: "MINOR THEME",
      labelText: "Minor Theme(s)",
      fieldName: "Minor Theme(s)",
      smallInfo: "Doubles as the “name” of growth plan",
    },
  },
  {
    name: "Goals",
    data: {
      title: "Goal Record",
      btnText: "Goal",
      labelText: "Goal(s)",
      fieldName: "Goal(s)",
      smallInfo: " Provide a specific list of goals to be achieved",
    },
  },
  {
    name: "BibleMemorizations",
    data: {
      title: "Bible Memorization Record",
      btnText: "Bible Memorization",
      labelText: "Bible Memorization",
      fieldName: "Bible Memorization",
      smallInfo: "Two or more scripture verses",
    },
  },
  {
    name: "BibleReading",
    data: {
      title: "Bible Reading Record",
      btnText: "Bible Reading",
      labelText: "Bible Reading",
      fieldName: "Bible Reading",
      smallInfo: "One or more books",
    },
  },
  {
    name: "PersonalReadingRecord",
    data: {
      title: "Personal Reading Record",
      btnText: "Personal Reading",
      labelText: "Personal Reading",
      fieldName: "Personal Reading",
      smallInfo: "One or more Reading",
    },
  },
  {
    name: "CQProjectIds",
    data: {
      title: "CQ Project Record",
      btnText: "CQ Project Record",
      labelText: "CQ Project Record",
      fieldName: "CQ Project Record",
      smallInfo: "One or more CQ Project Record",
    },
  },
  {
    name: "SpecialProjects",
    data: {
      title: "Special Projects Record ",
      btnText: "Special Projects",
      labelText: "Special Project(s)",
      fieldName: "Special Project(s)",
      smallInfo: "Special project (optional)",
    },
  },
];

const FieldArrayComponent = ({ fields, viewMode, ...props }) => {
  const { title, btnText, labelText, fieldName, smallInfo } = props;
  return (
    <>
      {" "}
      <div className="row my-2">
        <div class="w-100" style={{ position: "relative", left: "10px" }}>
          <span className="record-title">{title}</span>
          <button
            disabled={viewMode}
            type="button"
            class="btn-newCon"
            onClick={() => fields.push()}
          >
            + Add{" "}
            {fields.length == 0
              ? `${btnText ? btnText : 1}`
              : `ANOTHER ${btnText}`}
          </button>
        </div>

        <div className="col-12 col-md-6 col-lg-12 pr-xl-5 my-3">
          <div className="row">
            {fields.length > 0 ? (
              fields.map((item, index) => (
                <div key={index} className="col-4 field-array-item form-group">
                  <div className="d-flex justify-content-end">
                    <span>
                      <a
                        class="delete-contract"
                        onClick={() => {
                          if (fields.length && fields.length > 0) {
                            fields.remove(index);
                          }
                        }}
                      >
                        {!viewMode && (
                          <>
                            <i class="fas fa-trash" /> Delete
                          </>
                        )}
                      </a>
                    </span>
                  </div>

                  <Field
                    name={item}
                    class="form-control"
                    type="text"
                    disabled={viewMode}
                    component={ReduxInput}
                    labelText={labelText}
                    fieldName={fieldName}
                  />

                  <div className="d-flex justify-content-between">
                    <span class="small-info">{smallInfo}</span>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-9 field-array-item">
                <div class="form-group">
                  <h6>{title} (empty)</h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

let TemplatesForm = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle(props.templateId ? "Edit Templates" : "Add New Templates");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const selector = formValueSelector("TemplatesForm");

  const {
    GrowthPlanLadderAssociation,
    GrowthPlanMajorTheme,
    GrowthPlanTemplateType,
  } = useSelector((state) => {
    const {
      combo_list_get: { data, isFetching, error },
    } = state;
    return {
      GrowthPlanLadderAssociation:
        data?.ComboList[0]?.GrowthPlanLadderAssociation,
      GrowthPlanMajorTheme: data?.ComboList[0]?.GrowthPlanMajorTheme,
      GrowthPlanTemplateType: data?.ComboList[0]?.GrowthPlanTemplateType,
    };
  }, shallowEqual);

  const { handleSubmit, pristine, reset, submitting, onSubmit } = props;

  return (
    <>
      <div className="row templates-form">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                autocomplete="off"
              >
                <div className="pb-2">
                  <div className="row">
                    <div class="col-12 col-md-4 pr-xl-5 form-group">
                      <Field
                        id={"templateName"}
                        name="TemplateName"
                        type="text"
                        errorMessage="Required"
                        component={ReduxInput}
                        //   disabled={viewMode}
                        //   validate={Validator.required}
                        labelText={"Template Name"}
                        fieldName={"Template Name"}
                      />
                      <span class="small-info">
                        A good practice is to make this the same thing as the
                        Minor Theme.
                      </span>
                    </div>
                    <div className="col-12 col-md-4 pr-xl-5 form-group">
                      <Field
                        id="startDate"
                        name="StartDate"
                        component={ReduxDateInput}
                        fieldName="Start Date"
                        labelText={"Start Date*"}
                        // validate={Validator.required}
                      />
                    </div>
                    <div className="col-12 col-md-4 pr-xl-5 form-group">
                      <Field
                        id="dueDate"
                        name="DueDate"
                        component={ReduxDateInput}
                        fieldName="Due Date"
                        labelText={"Due Date*"}
                        // validate={Validator.required}
                      />
                    </div>
                    <div className="col-12 col-md-4 pr-xl-5 form-group">
                      <Field
                        name="Description"
                        component={ReduxTextarea}
                        fieldName="Description"
                        labelText={"Template Description*"}
                        // rows={"4"}
                        // multiline={true}
                        // validate={Validator.required}
                      />
                      <span class="small-info">
                        Please write a detailed explanation for this template.
                      </span>
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5 form-group">
                      <Field
                        name="LadderAssociationId"
                        component={ReduxSelect}
                        options={GrowthPlanLadderAssociation}
                        fieldName="Ladder Associated"
                        labelText={"Ladder Associated*"}
                        // validate={Validator.required}
                        // isReturnText={false}
                        // multiple={true}
                      />
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5 form-group">
                      <Field
                        name="MajorThemeId"
                        component={ReduxSelect}
                        options={GrowthPlanMajorTheme}
                        fieldName="Major Theme"
                        labelText={"Major Theme*"}
                        // validate={Validator.required}
                        // isReturnText={false}
                        // multiple={true}
                      />
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5 form-group">
                      <Field
                        name="GrowthPlanTemplateTypeId"
                        component={ReduxSelect}
                        options={GrowthPlanTemplateType}
                        fieldName="Template Type"
                        labelText={"Template Type*"}
                        // validate={Validator.required}
                        // isReturnText={false}
                        // multiple={true}
                      />
                    </div>
                  </div>

                  <>
                    {FieldConfigrations &&
                      FieldConfigrations.map((item) => {
                        const { name, data } = item;
                        return (
                          <FieldArray
                            name={name}
                            // viewMode={viewMode}
                            component={FieldArrayComponent}
                            props={data}
                          />
                        );
                      })}
                  </>
                  {/*
                  <FieldArray
                    name="CQProjectIds"
                    component={CQProjectRecord}
                    // viewMode={viewMode}
                    // courseOptions={CQProjectRecord}
                  /> */}
                  {/* <FieldArray
                    name="cqProject"
                    component={CQProject}
                    // viewMode={viewMode}
                    ContractCharacterQuality={ContractCharacterQuality}
                    ContractCQProject={ContractCQProject}
                  /> */}

                  <div className="col-12 col-md-4 px-0 form-group">
                    <Field
                      name="AdminNotes"
                      component={ReduxTextarea}
                      fieldName="Description"
                      labelText={"Administrative Notes*"}
                      // rows={"4"}
                      // multiline={true}
                      // validate={Validator.required}
                    />
                    <span class="small-info">
                      Please write a detailed explanation for this template.
                    </span>
                  </div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Submit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
const ReduxTemplatesForm = reduxForm({
  form: "TemplatesForm",
  enableReinitialize: true,
})(TemplatesForm);

const NewTemplatesForm = (props) => {
  const dispatch = useDispatch();
  const [templateDataInit, setTemplateDataInit] = useState({});
  const history = useHistory();
  const getId = () => {
    var param = new URLSearchParams(history.location.search);
    var templateId = param.get("id");
    if (templateId) {
      templateId = parseInt(templateId);
    } else {
      templateId = null;
    }
    return templateId;
  };
  useEffect(() => {
    setTemplateDataInit({});
    dispatch(resetGrowthPlanTemplateGet({}));
    dispatch(reset("TemplatesForm"));
    dispatch(
      comboListGet({
        Lookup: [
          {
            LookupDataType:
              "GrowthPlanMajorTheme,GrowthPlanLadderAssociation,GrowthPlanTemplateType,GrowthPlanCQProject,GrowthPlanCharacterQuality",
          },
        ],
      })
    );
    if (getId() || getId() === 0) {
      dispatch(
        growthPlanTemplateGet({ GrowthPlan: { GrowthPlanTemplateId: getId() } })
      );
    }
    return () => {
      setTemplateDataInit({});
      dispatch(resetGrowthPlanTemplateGet({}));
      dispatch(reset("TemplatesForm"));
    };
  }, []);
  const { templateDataObj, isInserted } = useSelector(
    ({ growth_plan_template_get: { data }, growth_plan_template_upsert }) => {
      return {
        templateDataObj: data?.Data?.GrowthPlans?.[0],
        isInserted: growth_plan_template_upsert.data?.ResponseCode === 1,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (isInserted) {
      setTemplateDataInit({});
      dispatch(resetGrowthPlanTemplateGet({}));
      dispatch(resetGrowthPlanTemplateUpsert({}));
      history.push("/growth-plan");
    }
  }, [isInserted]);

  useEffect(() => {
    if (templateDataObj) {
      const {
        // Actions,
        // CenterId,
        // CharacterQuality,
        // CharacterQualityIds,
        // CourseIds,
        // CreatedByStaffId,
        // DateInserted,
        // Days,
        // DiscardedDate,
        // IsArchived,
        // IsFavorite,
        // IsMyTemplate,
        // IsPublic,
        // LastUpdatedBy,
        AdminNotes,
        BibleMemorizations,
        BibleReading,
        CQProjectIds,
        Description,
        DueDate,
        FromClientGrowthPlanId,
        Goals,
        GrowthPlanTemplateId,
        GrowthPlanTemplateType,
        GrowthPlanTemplateTypeId,
        LadderAssociated,
        LadderAssociationId,
        MajorTheme,
        MajorThemeId,
        MinorThemes,
        PersonalReadingRecord,
        SpecialProjects,
        StartDate,
        TemplateName,
      } = templateDataObj;
      let data = {
        GrowthPlanTemplateId,
        FromClientGrowthPlanId,
        TemplateName,
        StartDate,
        DueDate,
        Description,
        LadderAssociated,
        LadderAssociationId,
        MajorTheme,
        MajorThemeId,
        GrowthPlanTemplateType,
        GrowthPlanTemplateTypeId:
          GrowthPlanTemplateTypeId && GrowthPlanTemplateTypeId.toString(),
        MinorThemes: (MinorThemes && MinorThemes.split("|")) || [""],
        Goals: (Goals && Goals.split("|")) || [""],
        BibleMemorizations: (BibleMemorizations &&
          BibleMemorizations.split("|")) || [""],
        BibleReading: (BibleReading && BibleReading.split("|")) || [""],
        PersonalReadingRecord: (PersonalReadingRecord &&
          PersonalReadingRecord.split("|")) || [""],
        CQProjectIds: (CQProjectIds && CQProjectIds.split("|")) || [""],
        SpecialProjects: (SpecialProjects && SpecialProjects.split("|")) || [
          "",
        ],
        AdminNotes,
      };
      setTemplateDataInit(data);
    }
  }, [templateDataObj]);

  const onSubmit = (values) => {
    let growthPlanTemplateId = null;
    let fromClientGrowthPlanId = null;
    // let paramCenterId = getId();
    // if (paramCenterId && paramCenterId > 0) {
    //   templateId = paramCenterId;
    // }
    let ObjectData = formDataHandler(values);
    const data = {
      GrowthPlan: [
        {
          GrowthPlanTemplateId: null,
          FromClientGrowthPlanId: null,
          ...ObjectData,
          MinorThemes: (values.MinorThemes &&
            values.MinorThemes.toString().replace(/,/g, "|")) || [""],
          Goals: (values.Goals &&
            values.Goals.toString().replace(/,/g, "|")) || [""],
          BibleMemorizations: (values.BibleMemorizations &&
            values.BibleMemorizations.toString().replace(/,/g, "|")) || [""],
          BibleReading: (values.BibleReading &&
            values.BibleReading.toString().replace(/,/g, "|")) || [""],
          CQProjectIds: (values.CQProjectIds &&
            values.CQProjectIds.toString().replace(/,/g, "|")) || [""],
          PersonalReadingRecord: (values.PersonalReadingRecord &&
            values.PersonalReadingRecord.toString().replace(/,/g, "|")) || [""],
          SpecialProjects: (values.SpecialProjects &&
            values.SpecialProjects.toString().replace(/,/g, "|")) || [""],
        },
      ],
    };
    dispatch(growthPlanTemplateUpsert(data));
  };
  return (
    <ReduxTemplatesForm
      onSubmit={onSubmit}
      // initialValues={templateDataInit}
      initialValues={getId() > 0 ? templateDataInit : {}}
      templateId={getId()}
    />
  );
};
export default NewTemplatesForm;
