import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageTitle from "../../../pageTitleConfig";
import TabsSection from "./tabsSection";

import { useSubheader } from "../../../../_metronic/layout";

const ClientRosterPage = (props) => {
  const history = useHistory();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Client Roster");

  return (
    <>
      <Helmet>
        <title>{PageTitle.CLIENT_ROSTER_HOME}</title>
      </Helmet>
      <div className="row">
        <div className="col-md-12">
          <TabsSection />
        </div>
      </div>
    </>
  );
};
export default ClientRosterPage;
