import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";

import {
  staffGet,
} from "../../../../redux/actions";
import _Edit from "../../../images/edit.svg";
import _Close from "../../../images/close.svg";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const StaffDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const enableSorting = true;
  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom page");

  useEffect(() => {
    dispatch(
      staffGet({
        Staff: [
          {
            StaffId: props.StaffId,
          },
        ],
      })
    );
  }, []);

  const { StaffInfo } = useSelector(({ staff_get: { data: staffData } }) => {
    return {
      StaffInfo: staffData?.Data?.Staffs?.[0],
    };
  }, shallowEqual);
  const {
    StaffId,
    CenterId,
    FirstName,
    MiddleName,
    LastName,
    DateofBirth,
    Gender,
    StaffRoleId,
    PayTypeId,
    AddressLine1,
    AddressLine2,
    AddressLine3,
    City,
    StateOrProvince,
    ZipCode,
    CountryISO,
    PrimaryLanguageISO,
    PhoneMobile,
    PhoneHome,
    EmailWork,
    EmailHome,
    RegistrationStatus,
    AuthenticationType,
    IsProvider,
    IsFinalSubmit,
    IsDeactivated,
    IsStaffRegisted,
    AdditionalCenter,
    Misc,
    AdditionalCenterNameText,
    DateOfBirthDDMMMYYYY,
    StaffRoleText,
    IsProviderText,
    IsDeactivatedText,
  } = StaffInfo || {};
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <h3>Staff Details</h3>
          <span onClick={props.toggleDrawer("right", false, {})}>
            {" "}
            <img
              src={_Close}
              alt="Close"
              style={{ cursor: "pointer", alignSelf: "baseline" }}
            />
          </span>
        </div>
        {/* Body */}
        <div className="card-body pt-5 drawer-course">
          <div className="row pb-5">
            <div className="col-11">
              <span className="course-title">
                {FirstName} {MiddleName} {LastName}
              </span>
            </div>
            <div className="col-1">
              <span
                onClick={() => {
                  history.push(
                    `/admin/staff/edit-form?tab=basic-detail&id=${props.StaffId}`
                  );
                }}
              >
                <img
                  src={_Edit}
                  alt="Edit Course"
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="c-item">
                  <span className="c-label">Staff Role</span>
                  <span className="c-value">{StaffRoleText}</span>
                </div>
                <div className="c-item">
                  <span className="c-label">Gender</span>
                  <span className="c-value">{Gender}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="c-item">
                  <span className="c-label">Date of Birth</span>
                  <span className="c-value">{DateOfBirthDDMMMYYYY}</span>
                </div>
                <div className="c-item">
                  <span className="c-label">Pay Type </span>
                  <span className="c-value">{PayTypeId}</span>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="d-flex mb-5">
                <div className="c-item">
                  <span className="c-label">Address</span>
                  <span className="c-value">
                    {AddressLine1}
                    {AddressLine2}
                    {AddressLine3}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="c-item">
                  <span className="c-label">City</span>
                  <span className="c-value">{City}</span>
                </div>
                <div className="c-item">
                  <span className="c-label">Zip Code</span>
                  <span className="c-value">{ZipCode}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="c-item">
                  <span className="c-label">Country</span>
                  <span className="c-value">{CountryISO}</span>
                </div>
                <div className="c-item">
                  <span className="c-label">State Or Province</span>
                  <span className="c-value">{StateOrProvince}</span>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="c-item">
                  <span className="c-label">Phone Mobile</span>
                  <span className="c-value">{PhoneMobile}</span>
                </div>
                <div className="c-item">
                  <span className="c-label">Phone Home</span>
                  <span className="c-value">{PhoneHome}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="c-item">
                  <span className="c-label">Email Work</span>
                  <span className="c-value">{EmailWork}</span>
                </div>
                <div className="c-item">
                  <span className="c-label">Email Home</span>
                  <span className="c-value">{EmailHome}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="c-item">
                  <span className="c-label">Registration Status</span>
                  <span className="c-value">{RegistrationStatus}</span>
                </div>
                <div className="c-item">
                  <span className="c-label">IsProvider</span>
                  <span className="c-value">{IsProviderText}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="c-item">
                  <span className="c-label">Additional Center</span>
                  <span className="c-value">{AdditionalCenterNameText}</span>
                </div>
                <div className="c-item">
                  <span className="c-label">IsDeactivated</span>
                  <span className="c-value">{IsDeactivatedText}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="c-item">
                  <span className="c-label">Primary Language</span>
                  <span className="c-value">{PrimaryLanguageISO}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            style={{
              boxShadow: " 0px 0px 10px 4px rgba(115, 116, 119, 0.06)",
              bordeRadius: "4px",
              padding: "15px 15px",
            }}
          >
            <h4>Summary</h4>
          </div> */}
        </div>
      </div>
    </>
  );
};
