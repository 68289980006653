import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  assessmentsListGet,
} from "../../../../redux/actions";
import _Edit from "../../../images/edit.svg";
import _Close from "../../../images/close.svg";
import _Archived from "../../../images/archived.svg";
import _GreenCircleTick from "../../../images/green-circle-tick.svg";
import { MoreItemDropdown } from "../../../components/custom-component";
import {
  OverdueAssessmentsCard,
  CompletedAssessmentsCard,
  ArchivedAssessmentsCard,
  AssigedAssessmentsCard,
} from "../../../components/custom-cards";

export const AssessmentsDrawer = (props) => {
  const dispatch = useDispatch();
  const [ListCompleted, setCompleted] = useState([]);
  const [ListArchived, setArchived] = useState([]);
  const [ListNew, setNew] = useState([]);

  useEffect(() => {
    // {"Assessment":[{"Search" : "","studentId":null,"showOnlyOverdue": 0,"IsForm":true, "FormType" : "Screening Tools", "FilterBy" : "Due","UserGuid":"4833E380-FF86-4360-A0A0-80CE95BE8C5B","StudentId":"1000656"}]}
    dispatch(
      assessmentsListGet({
        Json: JSON.stringify({
          Assessment: [
            {
              // UserGuid:'',
              StudentId: "1000656",
              // Search: "Assessments",
              // showOnlyOverdue: '',
              // IsForm: '',
              // FormType: '',
              // FilterBy:'',
            },
          ],
        }),
      })
    );
  }, []);

  const { AssessmentList } = useSelector(({ get_assessment_detail_list }) => {
    return {
      AssessmentList:
        get_assessment_detail_list.data && get_assessment_detail_list.data.data,
    };
  }, shallowEqual);
  useEffect(() => {
    // AssessmentList;
    let ListCompleted = [],
      ListArchived = [],
      ListNew = [];
    if (AssessmentList && AssessmentList.length > 0) {
      AssessmentList.map((item) => {
        if (item.IsSurveyCompleted) {
          ListCompleted.push(item);
        } else if (item.IsSurveyArchived) {
          ListArchived.push(item);
        } else {
          ListNew.push(item);
        }
      });
      setCompleted(ListCompleted);
      setArchived(ListArchived);
      setNew(ListNew);
    }
  }, [AssessmentList]);
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <h3>Assessments Drawer</h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-5 drawer-course assessments-section">
          <div className="row mb-2">
            {ListNew.length > 0 &&
              ListNew.map((newItem, index) => {
                return (
                  <div className="col-4" key={index}>
                    <AssigedAssessmentsCard
                      data={newItem}
                      index={index}
                      key={uuid()}
                    />
                  </div>
                );
              })}
          </div>
          <div className="row mb-2">
            {ListCompleted.length > 0 &&
              ListCompleted.map((completedItem, index) => {
                return (
                  <div className="col-4" key={index}>
                    <CompletedAssessmentsCard
                      data={completedItem}
                      index={index}
                      key={uuid()}
                    />
                  </div>
                );
              })}
          </div>
          <div className="row mb-2">
            {ListArchived.length > 0 &&
              ListArchived.map((archivedItem, index) => {
                return (
                  <div className="col-4" key={index}>
                    <ArchivedAssessmentsCard
                      data={archivedItem}
                      index={index}
                      key={uuid()}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
