import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* getFormFieldMetaData(action) {
    try {
        const { payload } = action;
        const response = yield API.getFormFieldMetaData(payload);
        yield put({
            type: ActionTypes.GET_FORM_FIELD_META_DATA_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.GET_FORM_FIELD_META_DATA_FAILURE
        });
    }
}
export function* upsertFormData(action) {
    try {
        const { payload } = action;
        const response = yield API.upsertFormData(payload);
        yield put({
            type: ActionTypes.UPSERT_FORM_DATA_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.UPSERT_FORM_DATA_FAILURE
        });
    }
}

export function* dynamicGridList(action) {
    try {
        const { payload } = action;
        const response = yield API.dynamicGridList(payload);
        yield put({
            type: ActionTypes.DYNAMIC_GRID_LIST_SUCCESS,
            payload: JSON.parse(response.data.data)[0]
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.DYNAMIC_GRID_LIST_FAILURE
        });
    }
}
export function* getGrid(action) {
    try {
        const { payload } = action;
        const response = yield API.getGrid(payload);
        yield put({
            type: ActionTypes.GET_GRID_SUCCESS,
            payload: JSON.parse(response.data.data)[0]
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.GET_GRID_FAILURE
        });
    }
}
export function* getGridData(action) {
    try {
        const { payload } = action;
        const response = yield API.getGridData(payload);
        yield put({
            type: ActionTypes.GET_GRID_DATA_SUCCESS,
            payload: JSON.parse(response.data.data)[0]
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.GET_GRID_DATA_FAILURE
        });
    }
}
export function* formDataGet(action) {
    try {
        const { payload } = action;
        const response = yield API.formDataGet(payload);
        yield put({
            type: ActionTypes.FORM_DATA_GET_SUCCESS,
            payload: JSON.parse(response.data.data)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.FORM_DATA_GET_FAILURE
        });
    }
}
export function* dynamicGridColumnConfigurationGet(action) {
    try {
        const { payload } = action;
        const response = yield API.dynamicGridColumnConfigurationGet(payload);
        yield put({
            type: ActionTypes.DYNAMIC_GRID_COLUMN_CONFIGURATION_GET_SUCCESS,
            payload: response.data.Data && response.data.Data.GridManifests[0]
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction:
                ActionTypes.DYNAMIC_GRID_COLUMN_CONFIGURATION_GET_FAILURE
        });
    }
}
export function* dynamicGridDataGet(action) {
    try {
        const { payload } = action;
        const response = yield API.dynamicGridDataGet(payload);
        yield put({
            type: ActionTypes.DYNAMIC_GRID_DATA_GET_SUCCESS,
            payload: JSON.parse(response.data.data)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.DYNAMIC_GRID_DATA_GET_FAILURE
        });
    }
}
export function* getDynamicGridFilter(action) {
    try {
        const { payload } = action;
        const response = yield API.getDynamicGridFilter(payload);
        yield put({
            type: ActionTypes.GET_DYNAMIC_GRID_FILTER_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.GET_DYNAMIC_GRID_FILTER_FAILURE
        });
    }
}
export function* getMultistepTab(action) {
    try {
        const { payload } = action;
        const response = yield API.getMultistepTab(payload);
        yield put({
            type: ActionTypes.GET_MULTISTEP_TAB_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.GET_MULTISTEP_TAB_FAILURE
        });
    }
}
export function* getDynamicScreenTab(action) {
    try {
        const { payload } = action;
        const response = yield API.getDynamicScreenTab(payload);
        yield put({
            type: ActionTypes.GET_DYNAMIC_SCREEN_TAB_SUCCESS,
            payload: response.data.Data
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.GET_DYNAMIC_SCREEN_TAB_FAILURE
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(
            ActionTypes.GET_FORM_FIELD_META_DATA_REQUEST,
            getFormFieldMetaData
        ),
        takeLatest(ActionTypes.UPSERT_FORM_DATA_REQUEST, upsertFormData),
        takeLatest(ActionTypes.FORM_DATA_GET_REQUEST, formDataGet),
        takeLatest(ActionTypes.DYNAMIC_GRID_LIST_REQUEST, dynamicGridList),
        takeLatest(ActionTypes.GET_GRID_REQUEST, getGrid),
        takeLatest(ActionTypes.GET_GRID_DATA_REQUEST, getGridData),
        takeLatest(
            ActionTypes.DYNAMIC_GRID_COLUMN_CONFIGURATION_GET_REQUEST,
            dynamicGridColumnConfigurationGet
        ),
        takeLatest(
            ActionTypes.DYNAMIC_GRID_DATA_GET_REQUEST,
            dynamicGridDataGet
        ),
        takeLatest(
            ActionTypes.GET_DYNAMIC_GRID_FILTER_REQUEST,
            getDynamicGridFilter
        ),
        takeLatest(ActionTypes.GET_MULTISTEP_TAB_REQUEST, getMultistepTab),
        takeLatest(
            ActionTypes.GET_DYNAMIC_SCREEN_TAB_REQUEST,
            getDynamicScreenTab
        )
    ]);
}
