import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

// class CheckboxGroup extends React.Component {
//   checkboxGroup() {
//     let {
//       label,
//       required,
//       options,
//       input,
//       meta,
//       col,
//       offset,
//       justify,
//       blockDays,
//       fullDescription,
//       title,
//       disabled,
//     } = this.props;
//     return (
//       <FormControl
//         component="fieldset"
//         variant="standard"
//         className="flex-row flex-wrap"
//       >
//         <FormLabel component="legend">{label}</FormLabel>
//         {options.map((option, index) => {
//           return (
//             <>
//               <FormGroup aria-label="position" row>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       className={"d-flex align-items-center"}
//                       name={`${input.name}[${index}]`}
//                       value={option.value}
//                       disabled={option.value === blockDays || disabled}
//                       checked={
//                         (input.value &&
//                           input.value.indexOf(option.value) !== -1) ||
//                         option.value === blockDays
//                       }
//                       onChange={(event) => {
//                         const newValue = [...input.value];
//                         if (event.target.checked) {
//                           newValue.push(option.value);
//                         } else {
//                           newValue.splice(newValue.indexOf(option.value), 1);
//                         }

//                         return input.onChange(newValue);
//                       }}
//                     />
//                   }
//                   label={option.label}
//                   // labelPlacement="top"
//                 />
//               </FormGroup>
//               {/* <FormHelperText>Be careful</FormHelperText> */}

//               {/* {fullDescription && (
//               <span style={{ marginLeft: "30px" }}>{option.description}</span>
//             )} */}
//             </>
//           );
//         })}
//       </FormControl>
//     );
//   }

//   render() {
//     return (
//       // <Row justify={this.props.justify} className={this.props.widthClassName}>
//       <>{this.checkboxGroup()}</>
//       // </Row>
//     );
//   }
// }

// export default CheckboxGroup;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const ReduxRadioButtonGroup = (props) => {
  let {
    label,
    required,
    options,
    input,
    meta,
    col,
    offset,
    justify,
    blockDays,
    fullDescription,
    title,
    disabled,
    radioOptions,
    onChange,
    value,
  } = props;
  const classes = useStyles();
   var { touched, error, warning } = meta || {};
  //  if (input) {
  //    defaultValue = input.defaultValue;
  //    value = input.value;
  //    onChange = input.onChange;
  //    onBlur = input.onBlur;
  //  }

  
  return (
    <div className={classes.root}>
      <FormControl
        component="fieldset"
        // className={classes.formControl}
      >
        <FormLabel component="legend">{label}</FormLabel>

        <RadioGroup
          aria-label={label}
          name={label}
          className={classes.group}
          value={input.value}
          onChange={input.onChange}
          row
        >
          {radioOptions?.map((item) => {
            const { text, value } = item;
            return (
              <FormControlLabel
                value={value}
                control={<Radio />}
                label={text}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default ReduxRadioButtonGroup;
