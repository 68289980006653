import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { postListGet } from "../../../redux/actions";
import _LikeFilled from "../../images/commentLikeBtn.svg";
import _CommentCount from "../../images/commentCount.svg";
const Posts = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    let data = {
      Post: [
        {
          ClientId: props.ID,
        },
      ],
    };
    dispatch(postListGet(data));
  }, []);

  const { postListData } = useSelector(
    ({ post_list_get: { data, isFetching, error } }) => {
      const { Data, Message } = data || {};
      return {
        postListData: Data && Data.Posts,
      };
    },
    shallowEqual
  );

  // const {} = postListData;
  return (
    <div className="card card-custom w-100">
      <div className="card-body pt-5">
        <div className="chart-posts-tab">
          {postListData &&
            postListData.map((item, index) => {
              const {
                FriendUserGuid,
                FullName,
                PreferredName,
                FriendStatusId,
                FriendStatus,
                Badge,
                FriendProfileImageUrl,
                PostGuid,
                TextContent,
                PostDateTime,
                AlreadyCommented,
                CommentCount,
                PostMedia,
                PostComment,
                LikeCount,
                AlreadyLiked,
                EmojiCount,
                EmojiString,
                TaggedUser,
              } = item;
              return (
                <>
                  <div className="posts p-4 mt-4" key={uuid()}>
                    <div
                      className="mt-5 mb-5 pb-5 d-flex align-items-center"
                      // style={{ justifyContent: "space-around" }}
                    >
                      <div className="d-flex">
                        <img
                          src={FriendProfileImageUrl || ""}
                          className="img-circle image"
                          height="40"
                          width="40"
                          style={{
                            borderRadius: "50%",
                            padding: "2px",
                            objectFit: "cover",
                          }}
                        />
                        &nbsp;
                        <div className="d-flex flex-column ">
                          <span className="name">{PreferredName}</span>
                          <span className="date">{PostDateTime}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p>{TextContent}</p>

                      {PostMedia &&
                        PostMedia.PostMedia &&
                        PostMedia.PostMedia.map((item, index) => {
                          const { MediaUrl } = item;
                          return (
                            <>
                              <img
                                src={MediaUrl || ""}
                                style={{ width: "50%" }}
                                className="img-fluid"
                              />
                            </>
                          );
                        })}
                      <div className="mt-3">
                        <span>
                          <img src={_LikeFilled} className="mr-2" />
                          {LikeCount} Likes
                        </span>{" "}
                        &nbsp;
                        <span>
                          <img src={_CommentCount} className="mr-2" />
                          {CommentCount} Comments
                        </span>
                      </div>
                      {/* <div>
                        {PostComment &&
                          PostComment.PostComment &&
                          PostComment.PostComment.map((item, index) => {
                            const {
                              PostCommentGuid,
                              UserGuid,
                              FullName,
                              DisplayName,
                              CommentFriendProfileImageUrl,
                              Comment,
                              CommentDateTime,
                              LikeCount,
                              AlreadyLiked,
                              EmojiCount,
                              EmojiString,
                              TaggedUser,
                            } = item;

                            return (
                              <>
                                <div className="d-flex">
                                  <img
                                    src={CommentFriendProfileImageUrl || ""}
                                    className="img-circle image"
                                    height="40"
                                    width="40"
                                    style={{
                                      borderRadius: "50%",
                                      padding: "2px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  &nbsp;
                                  <div className="d-flex flex-column ">
                                    <span className="name">{DisplayName}</span>
                                    <span className="date">
                                      {CommentDateTime}
                                    </span>
                                  </div>
                                </div>
                                <div>{Comment}</div>
                                <div>LikeCount :{LikeCount}</div>
                              </>
                            );
                          })}
                      </div> */}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Posts;
