import React, { Component, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import GoogleMapReact from "google-map-react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Dropdown } from "react-bootstrap";

import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../../../_metronic/_partials/dropdowns";
const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-hover-light-primary btn-sm btn-icon"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-ver" />
        </Dropdown.Toggle>
        {/* <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu> */}
      </Dropdown>
    </>
  );
};

export const ListView = ({ userTriggerHistoryData }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="row" style={{
        maxHeight: "300px",
        // overflow: "scroll",
        width: "100%"
      }}>
        {userTriggerHistoryData &&
          userTriggerHistoryData.map((item) => {
            // const {
            //   DidThisUserTag,
            //   DisplayName,
            //   FullAddress,
            //   IsPositive,
            //   Latitude,
            //   Longitude,
            //   OtherUsersTaggedThisPlaceCount,
            //   Service,
            //   ServiceTags,
            //   TriggerAddress,
            //   TriggerGuid,
            //   TriggerSign,
            //   TriggerTypeId,
            //   TriggerTypeImgUrl,
            //   TriggerTypeValue,
            //   TriggerVisit,
            //   UserGuid,
            //   ProfileImageUrl,
            //   VisitCount,
            // } = item;

            const {
              TriggerGuid,
              Latitude,
              Longitude,
              TriggerTypeId,
              TriggerTypeValue,
              IsPositive,
              TriggerSign,
              TriggerTypeImgUrl,
              VisitCount,
              DidThisUserTag,
              OtherUsersTaggedThisPlaceCount,
              FullAddress,
              TriggerVisit,
              PreferredName,
              ProfileImageUrl,
              Service,
              ServiceTags,
              TriggerAddress,
              Phone,
            } = item;
            return (
              <div className="col-12" style={{ height: "120px" }} key={uuid()}>
                <div className="row">
                  <div className="col-9">
                    <div className="d-flex">
                      <div
                        class="student-list-pic"
                        // style={{ flex: "0 0 8%" }}
                      >
                        <img
                          src={ProfileImageUrl || _blank}
                          // alt="student"
                          class="img-circle"
                          height="40"
                          width="40"
                          style={{
                            borderRadius: "50%",
                            padding: "2px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="flex-column">
                        <div>
                          <span className="member-name">{PreferredName}</span>{" "}
                          <span className="member-service">{Service}</span>
                        </div>
                        <div>
                          {ServiceTags.split(",").map((tag) => {
                            return (
                              <span className="member-tag" key={uuid()}>
                                {tag}
                              </span>
                            );
                          })}
                          {/* <p className="member-tag">{}</p> */}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        marginTop: "11px",
                      }}
                    >
                      <img
                        src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                        height="20"
                        width="20"
                      />
                      &nbsp;&nbsp;
                      <span
                        style={{
                          background: "#F6E4E5",
                          // display: "flex",
                          // flexDirection: "row",
                          // alignItems: "flex-start",
                          padding: "2px 4px",
                          borderRadius: "2px",
                          color: "#FF2C2C",
                        }}
                      >
                        {TriggerTypeValue}
                      </span>
                      &nbsp;
                      {FullAddress}&nbsp;
                    </div>
                  </div>
                  <div className="col-3 px-1 d-none">
                    <div
                      style={{
                        width: "200px",
                        display: "flex",
                        alignitems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          style={{ cursor: "pointer", display: "block" }}
                          src={_Phone}
                          alt="Calling Allowed"
                          width="15px"
                        />{" "}
                      </div>
                      <div className="d-flex align-items-center">
                        <img
                          style={{ cursor: "pointer", display: "block" }}
                          src={_Chat}
                          alt="Chat"
                          width="15px"
                        />{" "}
                      </div>
                      <div className="">
                        <ItemDropdown item="MoreAction" />
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
              </div>
            );
          })}
      </div>
    </>
  );
};
