import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from "redux-form";
import {
  getFormFieldMetaData,
  intakeFormDataGet,
  resetFormDataGet,
  intakeMedicalHistoryUpsert,
  resetIntakeMedicalHistoryUpsert,
  intakeEnrollNow,
  resetIntakeEnrollNow,
} from "../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";
import formDataHandler from "../../util/formDataHandler";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const IntakeMedicalHistory = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: props.FormName,
          },
        ],
      })
    );
    if (props.ID || props.ID === 0) {
      dispatch(
        intakeFormDataGet({
          Client: [
            {
              ClientId: props.ID,
            },
          ],
        })
      );
    }
    return () => {
      dispatch(resetFormDataGet());
      dispatch(reset("intakeMedicalHistory"));
    };
  }, []);

  const { clientData, isSuccess, ClientMedical } = useSelector((state) => {
    const {
      intake_insurance_upsert: { data: upsertData },
      intake_form_data_get: { data },
    } = state;
    return {
      clientData: data?.Data?.IntakeFormData?.[0],
      ClientMedical: data?.Data?.IntakeFormData?.[0]?.ClientMedical?.[0],
      isSuccess: upsertData?.ResponseCode === 1,
    };
  }, shallowEqual);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetIntakeMedicalHistoryUpsert());
      props.handleNext();
    }
  }, [isSuccess]);
  const onSubmit = (values) => {
    let ObjectData = formDataHandler(values);

    const data = {
      ClientMedical: [
        {
          ClientMedicalId: ClientMedical?.ClientMedicalId || null,
          ClientId: props.ID,
          ...ObjectData,
        },
      ],
    };

    dispatch(intakeMedicalHistoryUpsert(data));
  };
  return (
    <div className="">
      <ReduxIntakeMedicalHistory
        initialValues={{ ...ClientMedical }}
        ClientId={props.ID}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
        activeStep={props.activeStep}
        NextTab={props.NextTab}
        onSubmit={onSubmit}
        // onCancel={() =>
        //   this.props.history.push("/manage/staff-management/staff-list")
        // }
        // updateStep={this.props.updateStep}
        // formData={this.props.initialValues}
        // getStaffStatus={this.props.getStaffStatus}
      />
    </div>
  );
};

export default IntakeMedicalHistory;

const IntakeMedicalHistoryForm = (props) => {
  const [formFields, setFormFields] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const suhbeader = useSubheader();
  suhbeader.setTitle(props.ClientId ? "Edit Insurance" : "Add Insurance");
  const selector = formValueSelector("intakeMedicalHistory");
  const {
    genericFormData,
    isFetching,
    currentCountryValue,
    isEnrolled,
  } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data },
      intake_enroll_now: { data: enrollData },
    } = state;
    return {
      isEnrolled: enrollData?.ResponseCode === 1,
      genericFormData: data?.Data?.FormFieldMetaData,
      currentCountryValue: selector(state, "CountryISO"),
    };
  }, shallowEqual);
  useEffect(() => {
    if (genericFormData && genericFormData.length) {
      let validationConversion = genericFormData;
      validationConversion =
        validationConversion &&
        validationConversion.map((item, index) => {
          if (item.name == "StateOrProvince") {
            let currentOptions = item.DependentOptions.filter((item) => {
              return item.Parent == "USA";
              //   return item.Parent == currentCountryValue;
            })[0].Child;
            return {
              ...item,
              options: currentOptions || [],
            };
          } else {
            return item;
          }
          //   return item;
        });

      setFormFields(validationConversion);
    }
  }, [genericFormData]);
  useEffect(() => {
    if (genericFormData && genericFormData.length) {
      let newFormFields =
        formFields &&
        formFields.map((item, index) => {
          if (item.name == "StateOrProvince") {
            let currentOptions = item.DependentOptions.filter((item) => {
              return item.Parent == "USA";
            })[0].Child;

            return {
              ...item,
              options: currentOptions || [],
            };
          } else {
            return item;
          }
          //   return item;
        });

      setFormFields(newFormFields);
    }
  }, [currentCountryValue]);
  useEffect(() => {
    if (isEnrolled) {
      history.push("/client-roster");
      dispatch(resetIntakeEnrollNow());
    }
  }, [isEnrolled]);
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    ClientId,
  } = props;

  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="enrollment-form staff-form pt-2 pb-2">
                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    <DynamicForm formFields={formFields} disable={false} />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-left">
                    {ClientId && (
                      <>
                        {activeStep != 0 && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            Back
                          </Button>
                        )}
                        &nbsp;
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={handleNext}
                          disabled={!NextTab}
                        >
                          Next
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="text-right">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      type="submit"
                      disabled={pristine || submitting}
                    >
                      Save
                    </Button>
                    &nbsp;
                    {/* <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      disabled={pristine || submitting}
                      onClick={reset}
                    >
                      Clear Values
                    </Button> */}
                    {!NextTab && (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => {
                          debugger;
                          dispatch(
                            intakeEnrollNow({
                              Client: [
                                {
                                  ClientId: ClientId,
                                  IsIntake: true,
                                  IsPreIntake: false,
                                },
                              ],
                            })
                          );
                        }}
                      >
                        Enroll Now
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const ReduxIntakeMedicalHistory = reduxForm({
  form: "intakeMedicalHistory",
  enableReinitialize: true,
})(IntakeMedicalHistoryForm);
