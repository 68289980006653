import React, { Component } from "react";
import clsx from "clsx";
import Select from "react-select";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
// import { isEmpty } from 'lodash';
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: 250,
	},
	input: {
		display: "flex",
		padding: 0,
		height: "auto",
		maxHeight: "41px",
	},
	valueContainer: {
		display: "flex",
		flexWrap: "wrap",
		flex: 1,
		alignItems: "center",
		overflowY: "auto",
	},
	chip: {
		margin: theme.spacing(0.5, 0.25),
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === "light"
				? theme.palette.grey[300]
				: theme.palette.grey[700],
			0.08
		),
	},
	noOptionsMessage: {
		padding: theme.spacing(1, 2),
	},
	singleValue: {
		fontSize: 16,
	},
	placeholder: {
		position: "absolute",
		left: 2,
		bottom: 6,
		fontSize: 16,
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
	},
	divider: {
		height: theme.spacing(2),
	},
}));

function NoOptionsMessage(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

NoOptionsMessage.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
	inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
	const {
		children,
		innerProps,
		innerRef,
		selectProps: { classes, TextFieldProps },
	} = props;

	return (
		<TextField
			fullWidth
			variant="outlined"
			style={{ height: "36px", paddingTop: "4px" }}
			InputProps={{
				inputComponent,
				inputProps: {
					className: classes.input,
					ref: innerRef,
					children,
					...innerProps,
				},
			}}
			{...TextFieldProps}
		/>
	);
}

Control.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	selectProps: PropTypes.object.isRequired,
};

function Option(props) {
	return (
		<MenuItem
			ref={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400,
			}}
			{...props.innerProps}
		>
			{props.children}
		</MenuItem>
	);
}

Option.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	isFocused: PropTypes.bool,
	isSelected: PropTypes.bool,
};

function Placeholder(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.placeholder}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

Placeholder.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
	return (
		<Typography
			className={props.selectProps.classes.singleValue}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

SingleValue.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
	return (
		<div className={props.selectProps.classes.valueContainer}>
			{props.children}
		</div>
	);
}

ValueContainer.propTypes = {
	children: PropTypes.node,
	selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
	return (
		<Chip
			tabIndex={-1}
			label={props.children}
			className={clsx(props.selectProps.classes.chip, {
				[props.selectProps.classes.chipFocused]: props.isFocused,
			})}
			onDelete={props.removeProps.onClick}
			deleteIcon={<CancelIcon {...props.removeProps} />}
		/>
	);
}

MultiValue.propTypes = {
	children: PropTypes.node,
	isFocused: PropTypes.bool,
	removeProps: PropTypes.object.isRequired,
	selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
	return (
		<Paper
			square
			className={props.selectProps.classes.paper}
			{...props.innerProps}
		>
			{props.children}
		</Paper>
	);
}

Menu.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	selectProps: PropTypes.object,
};

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	// Placeholder,
	// SingleValue,
	ValueContainer,
};

const getOptionLabel = (option) => option.Text;
const getOptionValue = (option, isMulti) => {
	if (isMulti) {
		if (option && option[0]) {
			var value = [];
			for (var o of option) {
				value.push(o);
			}
			return value;
		} else {
			return [];
		}
	}

	return option.Value;
};

/**
 * Converts simple value to object for consumption by react-select
 * @param {any} value simple value such as id or option such as {Value: 5}
 * @param {*} options options
 */
const getValue = (value, options, isMulti) => {
	let match;
	if (value === undefined || options === undefined) {
		return "";
	}
	if (!options) {
		return value;
	}

	if (typeof value === "object") {
		return value;
	}
	if (isMulti) {
		let val = value.split(",");

		match = options.filter((f) =>
			val.includes(f.Value && f.Value.toString())
		);
	} else {
		match = options.find((t) => t.Value === value);
	}
	if (match) {
		return match;
	}
	return value;
};

class CustomSelect extends Select {
	constructor(props) {
		super(props);
	}
}

CustomSelect.defaultProps = {
	className: "basic-single",
	classNamePrefix: "select",
	getOptionLabel: getOptionLabel,
	getOptionValue: getOptionValue,
};

export default CustomSelect;

const ReduxSelect = ({
	input,
	options,
	name,
	id,
	value,
	onChange,
	onBlur,
	meta,
	errorMessage,
	disabled,
	tabIndex,
	menuPlacement,
	isMulti,
	focus,
	isClearable,
	placeholder: label,
	fieldName,
	viewMode,
	labelText,
	helperText,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	var { touched, error, warning, active } = meta || {};
	if (input) {
		value = input.value;
		// value = isNaN(input.value) || !typeof input.value === 'string' ? null : input.value;
		onChange = input.onChange;

		onBlur = () => input.onBlur;
	}

	const getValues = (values) => {
		if (typeof values == "string" && values !== "") {
			return values.split(/,/g);
		} else if (typeof values == "object") {
			return values;
		}
		return [];
	};

	const values = getValues(value);
	const selectStyles = {
		input: (base) => ({
			...base,
			color: theme.palette.text.primary,
			"& input": {
				font: "inherit",
			},
		}),
	};
	return (
		<div>
			{/* {value != 0 && value != null && value ? (
        <label
          className="placeholder-label"
          style={{
            zIndex: 1,
            fontSize: !!disabled || value || active ? "11px" : "16px",
            top: !!disabled || value || active ? "-5px" : "24px",
            position: "absolute",
          }}
        >
          {label}
        </label>
      ) : null} */}
			{viewMode && isMulti ? (
				<div
					style={{
						paddingTop: "10px",
						minHeight: "40px",
						paddingBottom: "15px",
					}}
				>
					{values &&
						options &&
						values.map((item) =>
							options.map((optItem) => {
								if (item == optItem.Value)
									return optItem.Text + ",";
							})
						)}
				</div>
			) : (
				<FormControl
					fullWidth
					className="formControl"
					error={touched && error}
				>
					<CustomSelect
						{...input}
						isMulti={isMulti}
						tabIndex={tabIndex}
						id={id}
						style={{ height: "36px", marginTop: "4px" }}
						name={name}
						options={options}
						isDisabled={!!disabled}
						menuPlacement={menuPlacement || "auto"}
						value={getValue(
							value,
							options,
							isMulti
						)} /* Convert simple value to object */
						onChange={(value) => {
							return onChange(getOptionValue(value, isMulti));
						}} /* Redux handling and convert object to simple value */
						onBlur={onBlur}
						isClearable={isClearable}
						autoFocus={!!focus}
						// placeholder={label ? label : ""}
						classes={classes}
						styles={selectStyles}
						inputId={
							isMulti
								? "react-select-multiple"
								: "react-select-single"
						}
						TextFieldProps={{
							label: labelText,
							error: touched && error,
							InputLabelProps: {
								htmlFor: isMulti
									? "react-select-multiple"
									: "react-select-single",
								shrink: true,
								error: touched && error,
							},
							placeholder: "Select multiple countries",
						}}
						components={components}
					/>
					{touched ? (
						""
					) : (
						<FormHelperText>{helperText}</FormHelperText>
					)}
					{touched &&
						((error && (
							<FormHelperText className="component-error-text">
								{fieldName} {error}
							</FormHelperText>
						)) ||
							(warning && (
								<span className="warning-color">{warning}</span>
							)))}
				</FormControl>
			)}
		</div>
	);
};

const ReduxTextSelect = ({
	input,
	options,
	name,
	id,
	value,
	onChange,
	onBlur,
	meta,
	errorMessage,
	disabled,
	tabIndex,
	menuPlacement,
	isMulti,
	focus,
	isClearable,
}) => {
	var { touched, error, warning } = meta || {};

	if (input) {
		value = input.value;
		onChange = input.onChange;
		onBlur = () => input.onBlur;
	}

	var selectOptions = [];

	if (!options) {
		options = [];
	}
	for (var item of options) {
		if (item.Text == value) {
			selectOptions.push(
				<option value={item.Text} selected>
					{item.Text}
				</option>
			);
		} else {
			selectOptions.push(<option value={item.Text}>{item.Text}</option>);
		}
	}

	return (
		<div>
			<select class="simple-basic-single" onChange={onChange}>
				{selectOptions}
			</select>
			{touched &&
				((error && <span className="error-text">{error}</span>) ||
					(warning && (
						<span className="warning-color">{warning}</span>
					)))}
		</div>
	);
};

const SuffixReduxSelect = (props) => {
	const data = [
		{ Value: 0, Text: " <Select>" },
		{ Value: 1, Text: "Sr." },
		{ Value: 2, Text: "Jr." },
	];
	return <ReduxSelect {...props} options={data} />;
};

const FrequencyReduxSelect = (props) => {
	const data = [
		{ Value: 0, Text: " <Select>" },
		{ Value: 1, Text: "One Time" },
		{ Value: 2, Text: "Multiple Times" },
	];
	return <ReduxSelect {...props} options={data} />;
};

export { ReduxSelect, ReduxTextSelect, FrequencyReduxSelect };
