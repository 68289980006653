import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import GenericTable from "../../generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  staffListGet,
} from "../../../../redux/actions";
import { MemberTrendsFormatter } from "../../column-formatters";
import { StaffDrawer } from "./staff-drawer";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const Staff = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, moreItems) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };

  const getCenterId = () => {
    let param = new URLSearchParams(history.location.search);
    let centerId = param.get("cid");
    if (centerId) {
      centerId = parseInt(centerId);
    } else {
      centerId = null;
    }
    return centerId;
  };
  useEffect(() => {
    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "Staff",
          },
        ],
      })
    );
    dispatch(
      staffListGet({
        filterCenterId: getCenterId(),
        searchStr: null,
      })
    );
  }, []);

  const { dynamicGridColumnData, isFetching, dynamicGridData } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data },
      staff_list_get: { data: staffData },
    }) => {
      return {
        dynamicGridData: staffData?.Data?.Staffs,
        dynamicGridColumnData: data,
      };
    },
    shallowEqual
  );
  const {
    PageSize,
    IsRowClick,
    IsFilter,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = dynamicGridData || [];
  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Staff List");

  return (
    <>
      <div className="member-trend">
        {Columns && DataSource && (
          <GenericTable
            // tableTitle="Member Trends"
            entities={DataSource || []}
            columns={Columns || []}
            isCardHeader={false}
            pagination={true}
            isLoading={isFetching}
            bordered={false}
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            tableOptions={{
              PageSize,
              IsRowClick,
              IsExcelExport,
              IsColumnSelectionEnable,
              TableButtons,
              IsFilter,
              FileName: "Staff",
            }}
            toggleDrawer={toggleDrawer}
          />
        )}
      </div>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false, {})}
      >
        <div role="presentation" style={{ width: "600px" }}>
          <StaffDrawer
            toggleDrawer={toggleDrawer}
            StaffId={moreItem.StaffId}
            moreItem
          />
        </div>
      </Drawer>
    </>
  );
};
