import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* getBreathalyzerSchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.getBreathalyzerSchedule(payload);
    yield put({
      type: ActionTypes.GET_BREATHALYZER_SCHEDULE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BREATHALYZER_SCHEDULE_FAILURE,
    });
  }
}
export function* listBreathalyzerSchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.listBreathalyzerSchedule(payload);
    yield put({
      type: ActionTypes.LIST_BREATHALYZER_SCHEDULE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_BREATHALYZER_SCHEDULE_FAILURE,
    });
  }
}
export function* upsertBreathalyzerSchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertBreathalyzerSchedule(payload);
    yield put({
      type: ActionTypes.UPSERT_BREATHALYZER_SCHEDULE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_BREATHALYZER_SCHEDULE_FAILURE,
    });
  }
}
export function* breathalyzerPushATest(action) {
  try {
    const { payload } = action;
    const response = yield API.breathalyzerPushATest(payload);
    yield put({
      type: ActionTypes.BREATHALYZER_PUSH_A_TEST_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BREATHALYZER_PUSH_A_TEST_FAILURE,
    });
  }
}
export function* breathalyzerEnable(action) {
  try {
    const { payload } = action;
    const response = yield API.breathalyzerEnable(payload);
    yield put({
      type: ActionTypes.BREATHALYZER_ENABLE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BREATHALYZER_ENABLE_FAILURE,
    });
  }
}
export function* getBreathalyzerTestResult(action) {
  try {
    const { payload } = action;
    const response = yield API.getBreathalyzerTestResult(payload);
    yield put({
      type: ActionTypes.GET_BREATHALYZER_TEST_RESULT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BREATHALYZER_TEST_RESULT_FAILURE,
    });
  }
}
export function* getBreathalyzerTestUpcoming(action) {
  try {
    const { payload } = action;
    const response = yield API.getBreathalyzerTestUpcoming(payload);
    yield put({
      type: ActionTypes.GET_BREATHALYZER_TEST_UPCOMING_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BREATHALYZER_TEST_UPCOMING_FAILURE,
    });
  }
}
export function* breathalyzerTestUpcomingToggle(action) {
  try {
    const { payload } = action;
    const response = yield API.breathalyzerTestUpcomingToggle(payload);
    yield put({
      type: ActionTypes.BREATHALYZER_TEST_UPCOMING_TOGGLE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BREATHALYZER_TEST_UPCOMING_TOGGLE_FAILURE,
    });
  }
}
export function* getStudentBreathalyzerTestResult(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentBreathalyzerTestResult(payload);
    yield put({
      type: ActionTypes.GET_STUDENT_BREATHALYZER_TEST_RESULT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_BREATHALYZER_TEST_RESULT_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.GET_BREATHALYZER_SCHEDULE_REQUEST,
      getBreathalyzerSchedule
    ),
    takeLatest(
      ActionTypes.LIST_BREATHALYZER_SCHEDULE_REQUEST,
      listBreathalyzerSchedule
    ),
    takeLatest(
      ActionTypes.UPSERT_BREATHALYZER_SCHEDULE_REQUEST,
      upsertBreathalyzerSchedule
    ),
    takeLatest(
      ActionTypes.BREATHALYZER_PUSH_A_TEST_REQUEST,
      breathalyzerPushATest
    ),
    takeLatest(ActionTypes.BREATHALYZER_ENABLE_REQUEST, breathalyzerEnable),
    takeLatest(
      ActionTypes.GET_BREATHALYZER_TEST_RESULT_REQUEST,
      getBreathalyzerTestResult
    ),
    takeLatest(
      ActionTypes.GET_BREATHALYZER_TEST_UPCOMING_REQUEST,
      getBreathalyzerTestUpcoming
    ),
    takeLatest(
      ActionTypes.BREATHALYZER_TEST_UPCOMING_TOGGLE_REQUEST,
      breathalyzerTestUpcomingToggle
    ),
    takeLatest(
      ActionTypes.GET_STUDENT_BREATHALYZER_TEST_RESULT_REQUEST,
      getStudentBreathalyzerTestResult
    ),
  ]);
}
