import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import Twilio from "../twilio";

export const TwilioCalling = (Data) => {
  const [modalShow, setModalShowIds] = useState(false);
  let modalClose = () => setModalShowIds(false);
  return (
    <div className="">
      <OverlayTrigger overlay={<Tooltip id="calling-tooltip">Calling</Tooltip>}>
        <a
          className="btn btn-icon btn-hover-light-primary btn-sm"
          onClick={() => {
            setModalShowIds(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl(_Phone)} />
          </span>
        </a>
      </OverlayTrigger>
      {modalShow && (
        <Twilio show={modalShow} onHide={modalClose} userData={Data.userData} />
      )}
    </div>
  );
};
