import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../../../_metronic/_partials/dropdowns";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {
  userSuspiciousWordGet,
  userMemberTrendHistory,
} from "../../../../redux/actions";
import { MemberTrendsFormatter } from "../../column-formatters";
// import { Chat } from "../../custom-component/Chating";
// import { TwilioCalling } from "../../custom-component/TwilioCalling";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";
import _GreenUpArrow from "../../../images/greenUpArrow.svg";
import _RedDownArrow from "../../../images/redDownArrow.svg";
import { Tabs1 } from "./tabs";
import { UserHeader } from "../../custom-component/userHeader";
import { RedGreenBGFormatter } from "../../column-formatters/RedGreenBGFormatter";
// import { MemberTrendDrawer } from "./member-drawer";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { getClientId } from "../../../util/getParam";
const { SearchBar, ClearSearchButton } = Search;

// const list = [
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
// ];

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const MemberTrendDrawer = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const enableSorting = true;

  // const getClientId = () => {
  //   var param = new URLSearchParams(history.location.search);
  //   var clientId = param.get("id") || param.get("Id");
  //   if (clientId) {
  //     clientId = clientId;
  //   } else {
  //     clientId = null;
  //   }
  //   return clientId;
  // };

  const columns = [
    {
      dataField: "Activity",
      text: "Activity",
      sort: enableSorting,
      sortCaret: sortCaret,
      headerSortingClasses: headerSortingClasses,
      style: {
        whiteSpace: "nowrap",
        width: "150px",
      },
      formatter: (cell, row) => {
        return (
          <span className="w-100 d-flex align-items-center justify-content-left">
            {row.IsRedColor ? (
              <img
                style={{
                  cursor: "pointer",
                  display: "block",
                  marginRight: "5px",
                }}
                src={_RedDownArrow}
                alt="Chat"
                // width="15px"
              />
            ) : (
              <img
                style={{
                  cursor: "pointer",
                  display: "block",
                  marginRight: "5px",
                }}
                src={_GreenUpArrow}
                alt="Chat"
                // width="15px"
              />
            )}{" "}
            {row.Activity}
          </span>
        );
      },
    },
    {
      dataField: "LastTrendUpdateDate",
      text: "Date",
      sort: enableSorting,
      sortCaret: sortCaret,
      headerSortingClasses: headerSortingClasses,
      style: {
        whiteSpace: "nowrap",
      },

      // width: "550px",
      classes: "date-width",
    },

    {
      dataField: "Cause",
      text: "cause",
      sort: enableSorting,
      sortCaret: sortCaret,
      headerSortingClasses: headerSortingClasses,
      formatter: RedGreenBGFormatter,
      classes: "cause-width",
      style: {
        whiteSpace: "nowrap",
      },
    },
    {
      dataField: "Remarks",
      text: "Remarks",
      sort: enableSorting,
      sortCaret: sortCaret,
      headerSortingClasses: headerSortingClasses,
    },
  ];
  const classes = useStyles();
  const suhbeader = useSubheader();

  useEffect(() => {
    // dispatch(
    //   userMemberTrendHistory({
    //     Json: JSON.stringify({
    //       Trends: [
    //         {
    //           // UserGuid: getClientId(),
    //           UserGuid: "8A7E1530-1EF4-459B-A84F-E6ABD1381AEF",
    //         },
    //       ],
    //     }),
    //   })
    // );
    // dispatch(userSuspiciousWordGet({}));
  }, []);

  const { HeaderInfo, Summary } = useSelector(
    ({ user_member_trend_history }) => {
      return {
        HeaderInfo:
          (user_member_trend_history &&
            user_member_trend_history.data &&
            user_member_trend_history.data.Data &&
            user_member_trend_history.data.Data[0] &&
            user_member_trend_history.data.Data[0].HeaderInfo[0]) ||
          {},
        Summary:
          user_member_trend_history &&
          user_member_trend_history.data &&
          user_member_trend_history.data.Data &&
          user_member_trend_history.data.Data[0] &&
          user_member_trend_history.data.Data[0].Summary,
      };
    },
    shallowEqual
  );

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { More, Phone, chat } = row.Actions[0];
    return (
      <div className="d-flex align-items-center justify-content-around">
        <div className="">
          {/* <TwilioCalling row={row} /> */}
          <div className="">
            <img
              style={{
                cursor: "pointer",
                display: "block",
              }}
              src={_Phone}
              alt="Calling Allowed"
              width="15px"
            />{" "}
          </div>
        </div>
        <div className="">
          {/* <Chat row={row} /> */}
          <div className="">
            <img
              style={{
                cursor: "pointer",
                display: "block",
              }}
              src={_Chat}
              alt="Chat"
              width="15px"
            />{" "}
          </div>
        </div>
        <div className="">
          <ItemDropdown item="MoreAction" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        {/* <div className="card-header border-0"></div> */}
        {/* Body */}
        <div className="card-body pt-5 member-trend">
          <div className="mt-5 mb-5">
            <UserHeader userInfo={HeaderInfo} />
          </div>
          <div
            style={{
              boxShadow: " 0px 0px 10px 4px rgba(115, 116, 119, 0.06)",
              bordeRadius: "4px",
              padding: "15px 15px",
            }}
          >
            <h4>Summary</h4>

            {Summary && (
              <ToolkitProvider
                keyField="id"
                data={Summary || []}
                columns={columns}
                // search
              >
                {(props) => (
                  <div>
                    {/* <SearchBar
                    {...props.searchProps}
                    className="custome-search-field"
                    style={{ color: "black" }}
                    delay={500}
                    placeholder="Search"
                  /> */}
                    {/* <ClearSearchButton {...props.searchProps} /> */}
                    <BootstrapTable
                      wrapperClasses="table-responsive member-trend-table"
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4={true}
                      bordered={false}
                      rowStyle={{
                        backgroundColor: "#F7F8FA",
                        margin: "12px 0px",
                        boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                        borderRadius: "2px",
                      }}
                      // hover
                      // defaultSorted={defaultSorted}
                      {...props.baseProps}
                      // style={{ height: "365px" }}
                    />
                  </div>
                )}
              </ToolkitProvider>
            )}
          </div>

          <div className="custom-tabs mt-5">
            <Tabs1 ClientId={getClientId()} />
          </div>
        </div>
      </div>
    </>
  );
};

const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-hover-light-primary btn-sm btn-icon"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-ver" />
        </Dropdown.Toggle>
        {/* <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu> */}
      </Dropdown>
    </>
  );
};
