import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
// import Timeline from "../../components/custom-component/timelineComponent";
import TimeTravelEvents from "../../components/custom-component/time-travel";

const TimeTravel = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="card card-custom w-100">
      <div className="card-body pt-5 px-0">
        <div className="timetravel-tabs">
          <TimeTravelEvents ID={props.ID} />
        </div>
      </div>
    </div>
  );
};
export default TimeTravel;
