import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageTitle from "../../pageTitleConfig";
// import { getMultistepTab } from "../../../redux/actions";
import GenericTab from "../../components/gereric-tabs";
import Demographics from "./demographics";
import Insurance from "./insurance";
import MedicalHistory from "./medicalHistory";
import { getClientId } from "../../util/getParam";

const Intakeform = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getStepContent = (
    step,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    FormName,
    ScreenName,
    ScreenTabId,
    IsDynamicTab
  ) => {
    switch (step) {
      case "Demographics":
        return (
          <Demographics
            handleNext={handleNext}
            handleBack={handleBack}
            ID={getClientId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      case "Insurance":
        return (
          <Insurance
            handleNext={handleNext}
            handleBack={handleBack}
            ID={getClientId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      case "Medical History":
        return (
          <MedicalHistory
            handleNext={handleNext}
            handleBack={handleBack}
            ID={getClientId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      default:
        return "Unknown step";
    }
  };
  return (
    <>
      <Helmet>
        <title>{PageTitle.INTAKE_REGISTRATION}</title>
      </Helmet>
      <div className="staff-page">
        <GenericTab getStepContent={getStepContent} FormName="Intake" />
      </div>
    </>
  );
};
export default Intakeform;
