import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* smallGroupGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smallGroupGet(payload);
    yield put({
      type: ActionTypes.SMALL_GROUP_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMALL_GROUP_GET_FAILURE,
    });
  }
}

export function* smallGroupUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.smallGroupUpsert(payload);
    yield put({
      type: ActionTypes.SMALL_GROUP_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMALL_GROUP_UPSERT_FAILURE,
    });
  }
}

export function* getSmallGroupList(action) {
  try {
    const { payload } = action;
    const response = yield API.getSmallGroupList(payload);
    yield put({
      type: ActionTypes.GET_SMALL_GROUP_LIST_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SMALL_GROUP_LIST_FAILURE,
    });
  }
}

export function* getSmallGroupUsers(action) {
  try {
    const { payload } = action;
    const response = yield API.getSmallGroupUsers(payload);
    yield put({
      type: ActionTypes.GET_SMALL_GROUP_USERS_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SMALL_GROUP_USERS_FAILURE,
    });
  }
}

export function* uploadLeaderPhoto(action) {
  try {
    const { payload } = action;
    const response = yield API.uploadLeaderPhoto(payload);
    yield put({
      type: ActionTypes.UPLOAD_LEADER_PHOTO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPLOAD_LEADER_PHOTO_FAILURE,
    });
  }
}

export function* isStaffProBio(action) {
  try {
    const { payload } = action;
    const response = yield API.isStaffProBio(payload);
    yield put({
      type: ActionTypes.CHECK_STAFF_PRO_BIO_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CHECK_STAFF_PRO_BIO_FAILURE,
    });
  }
}

export function* updateStaffProBio(action) {
  try {
    const { payload } = action;
    const response = yield API.updateStaffProBio(payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_PRO_BIO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_PRO_BIO_FAILURE,
    });
  }
}

export function* pastMeetingInfo(action) {
  try {
    const { payload } = action;
    const response = yield API.pastMeetingInfo(payload);
    yield put({
      type: ActionTypes.PAST_MEETING_INFO_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAST_MEETING_INFO_FAILURE,
    });
  }
}

export function* listPastMeetingInfo(action) {
  try {
    const { payload } = action;
    const response = yield API.listPastMeetingInfo(payload);
    yield put({
      type: ActionTypes.LIST_PAST_MEETING_INFO_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_PAST_MEETING_INFO_FAILURE,
    });
  }
}

export function* smallGroupReferral(action) {
  try {
    const { payload } = action;
    const response = yield API.smallGroupReferral(payload);
    yield put({
      type: ActionTypes.SMALL_GROUP_REFERRAL_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMALL_GROUP_REFERRAL_FAILURE,
    });
  }
}

export function* smallGroupUserListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smallGroupUserListGet(payload);
    yield put({
      type: ActionTypes.SMALL_GROUP_USER_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMALL_GROUP_USER_LIST_GET_FAILURE,
    });
  }
}

export function* smallGroupUserRemove(action) {
  try {
    const { payload } = action;
    const response = yield API.smallGroupUserRemove(payload);
    yield put({
      type: ActionTypes.SMALL_GROUP_USER_REMOVE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMALL_GROUP_USER_REMOVE_FAILURE,
    });
  }
}

export function* smallGroupLocationGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smallGroupLocationGet(payload);
    yield put({
      type: ActionTypes.SMALL_GROUP_LOCATION_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMALL_GROUP_LOCATION_GET_FAILURE,
    });
  }
}

export function* smallGroupLocationUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.smallGroupLocationUpdate(payload);
    yield put({
      type: ActionTypes.SMALL_GROUP_LOCATION_UPDATE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMALL_GROUP_LOCATION_UPDATE_FAILURE,
    });
  }
}

export function* smallGroupImagesGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smallGroupImagesGet(payload);
    yield put({
      type: ActionTypes.SMALL_GROUP_IMAGES_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMALL_GROUP_IMAGES_GET_FAILURE,
    });
  }
}

export function* upsertBroadcastTemplate(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertBroadcastTemplate(payload);
    yield put({
      type: ActionTypes.UPSERT_BROADCAST_TEMPLATE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_BROADCAST_TEMPLATE_FAILURE,
    });
  }
}

export function* broadcastSend(action) {
  try {
    const { payload } = action;
    const response = yield API.broadcastSend(payload);
    yield put({
      type: ActionTypes.BROADCAST_SEND_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BROADCAST_SEND_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.SMALL_GROUP_GET_REQUEST, smallGroupGet),
    takeLatest(ActionTypes.SMALL_GROUP_UPSERT_REQUEST, smallGroupUpsert),
    takeLatest(ActionTypes.GET_SMALL_GROUP_LIST_REQUEST, getSmallGroupList),
    takeLatest(ActionTypes.GET_SMALL_GROUP_USERS_REQUEST, getSmallGroupUsers),
    takeLatest(ActionTypes.UPLOAD_LEADER_PHOTO_REQUEST, uploadLeaderPhoto),
    takeLatest(ActionTypes.CHECK_STAFF_PRO_BIO_REQUEST, isStaffProBio),
    takeLatest(ActionTypes.UPDATE_STAFF_PRO_BIO_REQUEST, updateStaffProBio),
    takeLatest(ActionTypes.PAST_MEETING_INFO_REQUEST, pastMeetingInfo),
    takeLatest(ActionTypes.LIST_PAST_MEETING_INFO_REQUEST, listPastMeetingInfo),
       takeLatest(ActionTypes.SMALL_GROUP_REFERRAL_REQUEST, smallGroupReferral),
    takeLatest(
      ActionTypes.SMALL_GROUP_USER_LIST_GET_REQUEST,
      smallGroupUserListGet
    ),
    takeLatest(
      ActionTypes.SMALL_GROUP_USER_REMOVE_REQUEST,
      smallGroupUserRemove
    ),
    takeLatest(
      ActionTypes.SMALL_GROUP_LOCATION_GET_REQUEST,
      smallGroupLocationGet
    ),
    takeLatest(
      ActionTypes.SMALL_GROUP_LOCATION_UPDATE_REQUEST,
      smallGroupLocationUpdate
    ),
    takeLatest(
      ActionTypes.SMALL_GROUP_IMAGES_GET_REQUEST,
      smallGroupImagesGet
    ),
    takeLatest(
      ActionTypes.UPSERT_BROADCAST_TEMPLATE_REQUEST,
      upsertBroadcastTemplate
    ),
    takeLatest(ActionTypes.BROADCAST_SEND_REQUEST, broadcastSend),
  ]);
}
