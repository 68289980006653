import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import { useSubheader } from "../../../../_metronic/layout";
import {
  miscellaneousNoteTypeInsert,
  resetMiscellaneousNoteTypeInsert,
} from "../../../redux/actions";

// import {
//   ReduxInput,
//   ReduxSelect,
//   ReduxTextarea,
//   ReduxDateInput,
//   ReduxTimeInput,
//   ReduxDollarInput,
//   ReduxMultiSelect,
//   ReduxToggle,
//   ReduxCheckbox,
//   ReduxAutoComplete,
//   CheckboxGroup,
// } from "../../../components/redux-component/index";
// import Validator from "../../../Validator";
// import { getClientId } from "../../../util/getParam";

const AddNoteType = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(resetMiscellaneousNoteTypeInsert());
  }, []);

  const [noteTitle, setNoteTitle] = useState("");
  const handleNotes = (e) => {
    setNoteTitle(e.target.value);
  };

  const { noteDetails } = useSelector((state) => {
    const {
      miscellaneous_note_type_insert: { data: noteGet },
    } = state;
    return {
      noteDetails: noteGet?.ResponseCode === 1,
    };
  }, shallowEqual);

  useEffect(() => {
    if (noteDetails) {
      history.push("/admin/miscellaneous?tab=managenotes");
      dispatch(resetMiscellaneousNoteTypeInsert());
    }
  }, [noteDetails]);

  const insertNotes = () => {
    if (noteTitle) {
      dispatch(
        miscellaneousNoteTypeInsert({
          NoteType: [
            {
              Note: noteTitle,
              IsNoteRestore: 0,
            },
          ],
        })
      );
      setNoteTitle("");
    }
  };
  return (
    <Card>
      <CardBody className="d-flex flex-column">
        <div className="addNotes">
          <TextField
            // id="note-text"
            label="Enter Note Type Title"
            value={noteTitle}
            onChange={handleNotes}
            //   className={classes.textField}
            margin="normal"
            style={{
              width: "300px",
            }}
            // helperText="hello"
            variant="outlined"
          />
        </div>
        <div>
          <Button
            variant="outlined"
            color="secondary"
            style={{
              marginTop: "18px",
              width: "100px",
              marginBottom: "15px",
            }}
            onClick={() => history.push("/admin/miscellaneous?tab=managenotes")}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "15px", width: "100px" }}
            onClick={insertNotes}
            disabled={noteTitle ? false : true}
          >
            Add
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddNoteType;
