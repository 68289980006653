export const getClientId = () => {
  //   const history = useHistory();
  //   var param = new URLSearchParams(history.location.search);
  const queryString = window.location.search;
  var param = new URLSearchParams(queryString);
  var id = param.get("id") || param.get("Id");
  if (id) {
    id = id;
  } else {
    id = null;
  }
  return id;
};
