import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { staffGet } from "../../../../redux/actions";
import _Edit from "../../../images/edit.svg";
import _Close from "../../../images/close.svg";
import _Agreed from "../../../images/agreed.svg";
import _Disagreed from "../../../images/disagreed.svg";
import { TestHistory } from "../../../components/custom-screens/breathlyzerPage/testHistory";
import { Tabs1 } from "../../../components/custom-screens/breathlyzerPage/tabs";
export const BreathlyzerDrawer = (props) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(staffGet({ StaffId: props.StaffId }));
  // }, []);

  const { HeaderInfo, Summary } = useSelector(
    ({ user_member_trend_history: { data: TrendData } }) => {
      return {
        HeaderInfo: TrendData?.Data?.HeaderInfo[0] || {},
        Summary: TrendData?.Data?.Summary,
      };
    },
    shallowEqual
  );
  debugger;
  return (
    <>
      <div className={`card card-custom custom-tabs`}>
        {/* Head */}
        <div className="card-header border-1 pt-5">
          <h3>Breathlyzer Drawer</h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-5">
          <div className="custom-tabs mt-5">
            <Tabs1
              ClientId={props.ClientId}
              moreItem={props.moreItem}
              baseUrl={"/population-activities"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
