import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { AssessmentChart } from "./assessmentChart";
import CustomTable from "../../custom-component/custom-table";
export const Assessment = (className) => {
  const columns = [
    {
      dataField: "Date",
      text: "Date",
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "Time",
      text: "Time",
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },

    {
      dataField: "Score",
      text: "Score",
      formatter: "RedGreenBGFormatter",
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },

    {
      dataField: "Actions",
      text: "Actions",
      formatter: "ActionsColumnFormatter",
      // formatter: columnFormatters.ActionsColumnFormatter,
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
  ];

  const { AssessmentData } = useSelector(({ user_member_trend_history }) => {
    return {
      AssessmentData:
        (user_member_trend_history &&
          user_member_trend_history.data &&
          user_member_trend_history.data.Data &&
          user_member_trend_history.data.Data[0] &&
          user_member_trend_history.data.Data[0].Assessment &&
          user_member_trend_history.data.Data[0].Assessment[0]) ||
        [],
    };
  }, shallowEqual);

  return (
    <>
      <div className="d-flex flex-column w-100">
        <div className="d-flex " style={{ justifyContent: "space-evenly" }}>
          <div
            style={{
              backgroundColor: "#F7F8FA",
              borderRadius: "4px",
              marginRight: "4px",
            }}
            className="d-flex text-center  flex-column w-100 p-2"
          >
            <span style={{ fontSize: "18px", fontWeight: "700" }}>
              {" "}
              <b>
                {" "}
                {AssessmentData.AssessmentDetail &&
                  AssessmentData.AssessmentDetail[0] &&
                  AssessmentData.AssessmentDetail[0].TotalAssessments}
              </b>
            </span>
            <span>Total Assessments</span>
          </div>
          <div
            style={{
              backgroundColor: "#F7F8FA",
              borderRadius: "4px",
              marginLeft: "4px",
            }}
            className="d-flex text-center flex-column w-100 p-2"
          >
            <span style={{ fontSize: "18px", fontWeight: "700" }}>
              {" "}
              <b>
                {" "}
                {AssessmentData.AssessmentDetail &&
                  AssessmentData.AssessmentDetail[0] &&
                  AssessmentData.AssessmentDetail[0].AvgScore}
              </b>
            </span>
            <span> Total Avg Score</span>
          </div>
        </div>
        <div>
          {AssessmentData && AssessmentData.GraphData && (
            <AssessmentChart graphData={AssessmentData.GraphData} />
          )}
        </div>
        <div>
          {AssessmentData && AssessmentData.TableData && (
            <CustomTable
              // tableTitle="Member Trends"
              entities={AssessmentData.TableData || []}
              columns={columns || []}
              isCardHeader={false}
              pagination={false}
              // isLoading={isFetching}
              bordered={false}
              rowStyle={{
                backgroundColor: "#F7F8FA",
                margin: "12px 0px",
                boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                borderRadius: "2px",
              }}
              // selectRow={selectRow}
              // rowEvents={rowEvents}
              // tableOptions={{
              //   PageSize,
              //   IsRowClick,
              //   IsExcelExport,
              //   IsColumnSelectionEnable,
              // }}
              toggleDrawer={false}
            />
          )}
        </div>
      </div>
    </>
  );
};
