import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../_metronic/layout";
import GenericTable from "../../components/generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  getDynamicGridFilter,
  couponListGet,
} from "../../../redux/actions";
import { CouponDrawer } from "./coupon-drawer";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const CouponList = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, moreItems) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };

  const onFilter = (filterValue, toggleFilterDrawer) => {
    let data = {
      ...filterValue,
    };
    dispatch(couponListGet(data));
    toggleFilterDrawer("right", false)("no needs");
  };

  useEffect(() => {
    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "ReferralCodes",
          },
        ],
      })
    );
    dispatch(
      getDynamicGridFilter({
        ComponentGrid: [
          {
            Name: "ReferralCodes",
          },
        ],
      })
    );

    dispatch(couponListGet({ searchStr: null }));
  }, []);

  const { dynamicGridColumnData, isFetching, couponListData } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data, isFetching, error },
      coupon_list_get: { data: ListData },
    }) => {
      return {
        dynamicGridColumnData: data,
        couponListData: ListData?.Data?.Coupons,
      };
    },
    shallowEqual
  );

  const {
    PageSize,
    IsRowClick,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    IsFilter,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = couponListData || [];
  const enableSorting = true;

  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Referral Codes");

  return (
    <>
      <div>
        <div className="referral-codes">
          {Columns && DataSource && (
            <GenericTable
              // tableTitle="Referral Codes"
              entities={DataSource || []}
              columns={Columns || []}
              isCardHeader={false}
              pagination={true}
              isLoading={isFetching}
              bordered={false}
              // selectRow={selectRow}
              // rowEvents={rowEvents}
              tableOptions={{
                PageSize,
                IsRowClick,
                IsExcelExport,
                IsColumnSelectionEnable,
                TableButtons,
                IsFilter,
                FileName: "Referral Codes",
              }}
              toggleDrawer={toggleDrawer}
              onFilter={onFilter}
            />
          )}
        </div>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false, {})}
        >
          <div role="presentation" style={{ width: "800px" }}>
            <CouponDrawer
              toggleDrawer={toggleDrawer}
              CouponId={moreItem?.CouponId}
              moreItem
            />
          </div>
        </Drawer>
      </div>
    </>
  );
};
