import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
    staffGet,
} from "../../../../redux/actions";
import _Close from "../../../images/close.svg";
import { Posts } from "../../clientChartTabs";
export const FeedDrawer = props => {
    const dispatch = useDispatch();

    // useEffect(() => {
    //   dispatch(staffGet({ StaffId: props.StaffId }));
    // }, []);

    // const { StaffInfo } = useSelector(({ staff_get }) => {
    //   return {
    //     StaffInfo: staff_get.data,
    //   };
    // }, shallowEqual);

    return (
        <>
            <div className={`card card-custom`}>
                {/* Head */}
                <div className="card-header border-0 pt-5">
                    <h3>Feed</h3>
                    <OverlayTrigger
                        overlay={<Tooltip id="close-tooltip">Close</Tooltip>}
                    >
                        <a
                            className="btn btn-icon btn-hover-light-primary btn-sm"
                            onClick={props.toggleDrawer("right", false, {})}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl(_Close)} />
                            </span>
                        </a>
                    </OverlayTrigger>
                </div>
                {/* Body */}
                <div className="card-body pt-5 drawer-course">
                    <div className="row feed-section">
                        <Posts ID={props.ID} />
                    </div>
                </div>
            </div>
        </>
    );
};
