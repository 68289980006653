import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import _Close from "../../../images/close.svg";
import { Tabs1 } from "./tabs";
import { UserHeader } from "../../custom-component/userHeader";
import { getClientId } from "../../../util/getParam";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const BreathlyzerDrawer = (props) => {
  const { HeaderInfo, Summary } = useSelector(
    ({ user_member_trend_history: { data: TrendData } }) => {
      return {
        HeaderInfo: TrendData?.Data?.HeaderInfo[0] || {},
        Summary: TrendData?.Data?.Summary,
      };
    },
    shallowEqual
  );

  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-1">
          <div className="mt-5 pb-5">
            <UserHeader userInfo={HeaderInfo} />
          </div>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-5 member-trend">
          <div className="custom-tabs mt-5">
            <Tabs1
              ClientId={getClientId() || props.moreItem.ClientId}
              moreItem={props.moreItem}
              baseUrl={"/breathlyzer/list"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
