import moment from "moment";
import { v4 as uuid } from "uuid";
const formDataHandler = (values) => {
  let valueArray = Object.keys(values);
  const valuesObj = {};
  for (const key of valueArray) {
    if (values[key] instanceof Date) {
      valuesObj[key] = moment(values[key]).format("MM/DD/YYYY");
    } else if (values[key] != null && typeof values[key] == "object") {
      valuesObj[key] = values[key].map((item) => item.Value).toString();
    } else {
      valuesObj[key] = values[key];
    }
  }
  return valuesObj;
};
export default formDataHandler;
