import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { toast } from "react-toastify";
import Drawer from "@material-ui/core/Drawer";
import {
  triggersListGet,
} from "../../../../redux/actions";
import _Close from "../../../images/close.svg";
import { Triggers } from "../../../components/custom-screens/triggers/index";

export const TriggerDrawer = (props) => {
  debugger;
  const dispatch = useDispatch();
  const history = useHistory();

  const [drawerState, setDrawerState] = useState({
    right: false,
  });
  const getLatestData = () => {
    dispatch(
      triggersListGet({
        Trigger: [
          {
            ClientId: props.ID,
          },
        ],
      })
    );
  };
  useEffect(() => {
    getLatestData();
  }, []);
  // const { triggerList } = useSelector(
  //   ({ triggers_list_get: { data: triggerData } }) => {
     
  //     return {
  //       triggerList: triggerData?.Data?.Triggers,
  //     };
  //   },
  //   shallowEqual
  // );
  const toggleDrawer = (side, open, task) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
  };
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-1">
          <h3>Trigger Drawer</h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-5 drawer-course">
          <div className="row task-drawer-section">
            <div className="col-12">
              <div className="d-flex justify-content-end">
                <Button
                  variant="outlined"
                  color={"secondary"}
                  className="mr-2 px-5"
                >
                  Filter
                </Button>
              </div>
            </div>
            <div className="col-12">
              <div className="survey-list-section mt-5 mb-5 custom-tabs member-trend">
                <Triggers />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
