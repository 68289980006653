import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { centerGet, centerStatusUpdate } from "../../../../redux/actions";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Edit from "../../../images/edit.svg";
import _PhoneNumber from "../../../images/phone-number.svg";
import _Close from "../../../images/close.svg";
import { UserHeader } from "../../custom-component/userHeader";
const { SearchBar, ClearSearchButton } = Search;

const useStyles = makeStyles((theme) => ({
  activateButton: {
    margin: theme.spacing(1),
    color: "red",
    background: "transparent",
    border: "1px solid red",
  },
  deActivateButton: {
    margin: theme.spacing(1),
    color: "white",
    background: "blue",
    "&:hover, &:focus": {
      backgroundColor: "blue",
    },
  },
  input: {
    display: "none",
  },
}));

export const CenterDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const enableSorting = true;
  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom page");

  // getCourseId = () => {
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   var courseId = param.get("courseId") || param.get("courseid");
  //   if (courseId) {
  //     courseId = parseInt(courseId);
  //   } else {
  //     courseId = null;
  //   }
  //   return courseId;
  // };
  useEffect(() => {
    dispatch(centerGet({ Center: { CenterId: props.CenterId } }));
  }, []);



  const { CenterInfo } = useSelector(({ center_get: { data } }) => {
    return {
        CenterInfo: data?.Data?.Centers?.[0] || {},
      };
    }, shallowEqual);
  
  
  
  const {
    CenterId,
    Name,
    ParentCenterId,
    BedsAvailable,
    CostPerDay,
    AddressLine1,
    AddressLine2,
    City,
    StateOrProvince,
    CountryISO,
    PostalCode,
    SubRegion,
    Email,
    Phone,
    Phone2,
    Website,
    PrimaryStaffId,
    SecondaryStaffId,
    CenterProgramTypeCenter,
    CenterPopulationTypeCenter,
    CenterServiceTypeCenter,
    Region,
    IsArchived,
    CenterProgramTypeCenterValue,
    ParentCenterName,
    CenterPopulationTypeCenterValue,
    CenterServiceTypeCenterValue,
    IsDeactivated,
  } = CenterInfo;
  
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <h3>Center Details</h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-5 drawer-center">
          <div className="row pb-5">
            <div className="col-11">
              <span className="center-title">{Name}</span>
              <br />
              <div className="center-phone">
                <img src={_PhoneNumber} className="mr-2" />
                <span className="phone-text">{Phone}</span>
              </div>
            </div>
            <div className="col-1">
              <div>
                <OverlayTrigger
                  overlay={<Tooltip id="edit-tooltip">Edit Center</Tooltip>}
                >
                  <a
                    className="btn btn-icon btn-hover-light-primary btn-sm"
                    onClick={() => {
                      history.push(`/admin/centers/edit?id=${props.CenterId}`);
                    }}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl(_Edit)} />
                    </span>
                  </a>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Center ID</span>
                  <span className="c-value">{CenterId && CenterId}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">City</span>
                  <span className="c-value">{City && City}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">State</span>
                  <span className="c-value">
                    {StateOrProvince && StateOrProvince}
                  </span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Parent Center</span>
                  <span className="c-value">{ParentCenterName}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Email</span>
                  <span className="c-value">{Email}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Zip Code</span>
                  <span className="c-value">{PostalCode}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Website</span>
                  <span className="c-value">{Website}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Status</span>
                  <span className="c-value">
                    {" "}
                    {IsDeactivated ? "Activate" : "Deactivate"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Total Number of Beds</span>
                  <span className="c-value">{BedsAvailable}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Treatment Cost per Day</span>
                  <span className="c-value">{CostPerDay}</span>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Address</span>
                  <span className="c-value">
                    {`${AddressLine1 ? AddressLine1 : ""} ${
                      AddressLine2 ? AddressLine2 : ""
                    }`}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Population Type</span>
                  <span className="c-value">
                    {CenterPopulationTypeCenterValue}
                  </span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Program Type</span>
                  <span className="c-value">
                    {CenterProgramTypeCenterValue}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Service Type</span>
                  <span className="c-value">
                    {CenterServiceTypeCenterValue}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Primary Staff ID</span>
                  <span className="c-value">{PrimaryStaffId}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Secondary Staff ID</span>
                  <span className="c-value">{SecondaryStaffId}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            style={{
              boxShadow: " 0px 0px 10px 4px rgba(115, 116, 119, 0.06)",
              bordeRadius: "4px",
              padding: "15px 15px",
            }}
          >
            <h4>Summary</h4>
          </div> */}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="text-right">
            {/* <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button> */}
            &nbsp;
            {CenterId ? (
              <Button
                className={
                  IsDeactivated
                    ? classes.deActivateButton
                    : classes.activateButton
                }
                onClick={() => {
                  dispatch(
                    centerStatusUpdate({
                      Center: [
                        {
                          CenterId,
                        },
                      ],
                    })
                  );
                }}
              >
                {IsDeactivated ? "Activate" : "Deactivate"}
              </Button>
            ) : CenterId == 0 ? (
              <Button
                className={
                  IsDeactivated
                    ? classes.deActivateButton
                    : classes.activateButton
                }
                onClick={() => {
                  dispatch(
                    centerStatusUpdate({
                      Center: [
                        {
                          CenterId,
                        },
                      ],
                    })
                  );
                }}
              >
                {IsDeactivated ? "Activate" : "Deactivate"}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
