import { studentsList } from '../config';

const FeatchUserData = 'FeatchUserData';
const initialState = { students: [] };

export const featchDataActionCreator = () => {
  return { type: FeatchUserData };
};

export const featchReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case FeatchUserData: {
      return {
        ...state,
        students: studentsList,
      };
    }
    default:
      return { ...state };
  }
};
