import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";

export function* listClientV2(action) {
  try {
    const { payload } = action;
    const response = yield API.listClientV2(payload);
    yield put({
      type: ActionTypes.LIST_CLIENT_V2_SUCCESS,
      payload: JSON.parse(response.data.data)[0].Client,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_CLIENT_V2_FAILURE,
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.LIST_CLIENT_V2_REQUEST, listClientV2)]);
}
