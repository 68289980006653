import React from "react";
export const RedGreenTextFormatter = (cellContent, row) => {
  return (
    <span
      style={
        row.IsRedColor
          ? {
              color: "#FF2C2C",
              padding: "2px 4px",
            }
          : {
              color: "#00DE09",
              padding: "2px 4px",
            }
      }
    >
      {cellContent}
    </span>
  );
};
