import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import _Close from "../../../images/close.svg";
import { courseAssign, courseOptionGet } from "../../../../redux/actions";
import {
  ReduxInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxDateInput,
  ReduxTimeInput,
  ReduxDollarInput,
  ReduxMultiSelect,
  ReduxToggle,
  ReduxCheckbox,
  ReduxAutoComplete,
  CheckboxGroup,
} from "../../../components/redux-component/index";

import { getDate, stringDate } from "../../../util/date";
import Validator from "../../../Validator";
import formDataHandler from "../../../util/formDataHandler";
const AssignCourseForm = (props) => {
  const dispatch = useDispatch();
  //   const history = useHistory();
  useEffect(() => {
    dispatch(
      courseOptionGet({
        Course: [
          {
            ClientId: props.ID,
          },
        ],
      })
    );
  }, []);

  const { courseOptions } = useSelector(
    ({ course_option_get: { data: courseOptionData } }) => {
      return {
        courseOptions: courseOptionData?.Data?.Courses,
      };
    },
    shallowEqual
  );

  const onAssignCourse = (values) => {
    let ObjectData = formDataHandler(values);
    const { CourseId, DueDate } = ObjectData;

    var data = {
      Course: [
        {
          ClientId: props.ID,
          CourseId,
          DueDate,
        },
      ],
    };
    dispatch(courseAssign(data));
  };

  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    rowData,
    initialValues,
  } = props;
  const isinitialValuesPresent = initialValues && Object.keys(initialValues).length > 0;
  return (
    <div className={`card card-custom`}>
      {/* Head */}
      <div className="card-header border-1">
        {isinitialValuesPresent ? (
          <h5>Edit Due Date</h5>
        ) : (
          <h5>Assign Course</h5>
        )}

        <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
          <a
            className="btn btn-icon btn-hover-light-primary btn-sm"
            onClick={props.toggleDrawer("right", false)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl(_Close)} />
            </span>
          </a>
        </OverlayTrigger>
      </div>
      {/* Body */}
      <div className="card-body pt-2 drawer-course assessments-section">
        <form autoComplete="off" onSubmit={handleSubmit(onAssignCourse)}>
          <div className="pt-2 pb-2 mx-4">
            <div className="row pb-5 pl-3 pr-3 pt-3">
              {isinitialValuesPresent  ? null : (
                <div className="col-md-12 col-lg-12 mt-2">
                  <Field
                    name="CourseId"
                    component={ReduxSelect}
                    options={courseOptions}
                    fieldName="Course List"
                    labelText={"Course List*"}
                    // validate={Validator.required}
                    isReturnText={false}
                    // isMulti={true}
                  />
                </div>
              )}

              <div className="col-md-12 col-lg-12 mt-2">
                <Field
                  name="DueDate"
                  component={ReduxDateInput}
                  fieldName="Course Due Date"
                  labelText={"Course Due Date*"}
                  validate={Validator.required}
                />
              </div>
            </div>
            <div className="text-right">
              <Button
                variant="outlined"
                color={"secondary"}
                onClick={props.toggleDrawer("right", false)}
                className="mx-2"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                // className={classes.button}
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              &nbsp;
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
const ReduxAssignCourseForm = reduxForm({
  form: "assignCourseForm",
  enableReinitialize: true,
})(AssignCourseForm);
const CourseForm = (props) => {
  const { clientId } = props.rowData || {};
  return (
    <>
      <ReduxAssignCourseForm
        initialValues={props.rowData}
        ID={clientId || props.ID}
        toggleDrawer={props.toggleDrawer}
      />
    </>
  );
};
export default CourseForm;
