import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import LoadingButton from "@mui/lab/LoadingButton";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { browserName, deviceDetect } from "react-device-detect";
import { useHistory } from "react-router-dom";
import {
	ReduxInput,
	ReduxSelect,
	ReduxTextarea,
	ReduxDateInput,
	ReduxTimeInput,
	ReduxDollarInput,
	ReduxMultiSelect,
	ReduxToggle,
	ReduxCheckbox,
	ReduxAutoComplete,
} from "../../../components/redux-component/index";
import {
	login,
	loadDefaultValues,
	userDataGet,
	logDevice,
	getGrid,
	getGridData,
	resetLogin,
} from "../../../../redux/actions";
import Validator from "../../../Validator";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	input: {
		margin: theme.spacing(1),
	},
}));

const Login = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();
	const {
		loginData,
		isFetching,
		UserData,
		DefaultData,
		isShowPassword,
		isLoggedIn,
		loginMessage,
	} = useSelector((state) => {
		const {
			login: { data, isFetching },
			user_data_get: { data: userData },
			default_values: { data: defaultValues },
		} = state;
		return {
			loginData: data?.Data,
			isLoggedIn: data?.ResponseCode,
			loginMessage: data?.Message,
			UserData: userData,
			DefaultData: defaultValues,
			isShowPassword: selector(state, "isShowPassword"),
			isFetching: isFetching,
		};
	}, shallowEqual);

	useEffect(() => {
		if (isLoggedIn === 1) {
			history.push("/dashboard");
		} else if (isLoggedIn === 9) {
			// toast.dismiss();
			toast.error(loginMessage, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			dispatch(resetLogin());
		} else {
			// toast.dismiss();
			toast.error(loginMessage, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			dispatch(resetLogin());
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (DefaultData) {
			sessionStorage.setItem(
				"defaultCenter",
				JSON.stringify(DefaultData.defaultDashboard)
			);

			//   loadDefaultDashboard(DefaultData);
		}
	}, [DefaultData]);

	const { handleSubmit, pristine, reset, submitting } = props;

	const loginSuccess = () => {
		const { access_token, refresh_token, staffFirstLogin } = props;
		let data = deviceDetect();
		data.timeZoneOffSet = new Date().getTimezoneOffset();
		data.language =
			window.navigator.userLanguage || window.navigator.language;
		data.token = access_token;
		data.refreshToken = refresh_token;
		dispatch(logDevice(data));
		// dispatch(loadDefaultValues({}));
		// dispatch(loadDefaultValues({}));
		// dispatch(
		//   getGrid({
		//     Json: JSON.stringify({
		//       ComponentGrid: [
		//         {
		//           Name: "Staff",
		//         },
		//       ],
		//     }),
		//   })
		// );
		// dispatch(
		//   getGridData({
		//     Json: JSON.stringify({
		//       ComponentGrid: [
		//         {
		//           Name: "Staff",
		//         },
		//       ],
		//     }),
		//   })
		// );
	};
	const loadDefaultDashboard = (DefaultData) => {
		const { defaultDashboard, centerId, userId } = DefaultData || {};
		let param = new URLSearchParams(props.history.location.search);
		let redirectPath = param.get("redirectPath");
		let dashboard = "/login";
		if (redirectPath && redirectPath != "undefined") {
			dashboard = redirectPath;
		} else if (defaultDashboard) {
			dashboard = defaultDashboard;
		} else {
			dashboard = "/login";
		}
		// history.push("/dashboard");
		history.push(`${dashboard}`);
	};
	const userLogin = (data) => {
		const { Email, Password } = data || {};

		var obj = { Username: Email, Password: Password };

		dispatch(login({ Username: Email, Password: Password }));
	};

	// useEffect(() => {
	//   const { AuthenticationType } = (UserData && UserData.data[0]) || {};
	//   if (AuthenticationType === 13001) {
	//     // debugger;
	//     loginSuccess();
	//   }
	// }, [UserData]);

	return (
		<>
			{" "}
			<div className="row" style={{ width: "300px" }}>
				<div className="col-12 text-center mb-5">
					<h3 className="font-size-h1">
						<FormattedMessage id="AUTH.LOGIN.TITLE" />
					</h3>
					{/* <p className="text-muted font-weight-bold">
            Click on sign in button for demo
          </p> */}
				</div>

				<div className="col-12 mt-5">
					<form
						autoComplete="off"
						onSubmit={handleSubmit((data) => {
							// console.log("data ", data);
							userLogin(data);
						})}
					>
						<div className="row mb-5">
							<div className="col-md-12 form-group">
								<Field
									id={"Email"}
									name={`Email`}
									component={ReduxInput}
									// placeholder="First Name"
									fieldName="Email"
									type="text"
									validate={Validator.required}
									labelText="Email*"
								/>
							</div>
							<div className="col-md-12">
								<Field
									id={"Password"}
									name={`Password`}
									component={ReduxInput}
									// placeholder="Last Name"
									fieldName="Password"
									type={isShowPassword ? "text" : "password"}
									validate={Validator.required}
									labelText="Password*"
								/>
							</div>
							<div className="col-md-12">
								<Field
									id={"isShowPassword"}
									name={`isShowPassword`}
									component={ReduxCheckbox}
									// placeholder="Last Name"
									fieldName="isShowPassword"
									labelText="Show Password"
								/>
							</div>
						</div>

						<div>
							<Button
								variant="contained"
								color="secondary"
								className={classes.button}
								type="submit"
								disabled={isFetching}
							>
								{isFetching ? "Loading" : "Sign In"}
							</Button>
							&nbsp;
							{/* <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button> */}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
const selector = formValueSelector("LoginForm");
const mapStateToProps = (state, props) => {
	const { login, user_data_get, default_values } = state;
	const isShowPassword = selector(state, "isShowPassword");
	const { data, isFetching, error } = login;
	const { data: UserData, isFetching: isUserInfoFetching } = user_data_get;
	const {
		data: DefaultData,
		isFetching: isDefaultDataFetching,
	} = default_values;

	let isSucess = false;
	let errorMessage = "";
	let access_token = "";
	let expires_in = "";
	let refresh_token = "";
	let authenticationType = 0;
	let staffFirstLogin = "";
	let authRoute = "";
	let menuJson = "";
	let showTrialPopup = false;
	let counselorId = "";
	let isFacialAuthenticated = false;
	let isFacialAuthenticationFailed = false;
	let isPasscodeRequired = false;
	let passCodeDisplay = "";
	let isPasscodevarify = false;
	let hasClinicalAccess = false;
	let isPasscodeError = "";
	let isTwoStepAPICallFinished = false;
	let defaultData = null;
	if (data) {
		if (data.access_token) {
			isSucess = true;
			access_token = data.access_token;
			expires_in = data.expires_in;
			refresh_token = data.refresh_token;
		} else {
			errorMessage = "Login Failed";
		}
	} else if (error) {
		errorMessage = error.error_description;
	}
	if (UserData) {
		authenticationType = UserData.data[0].AuthenticationType;
		staffFirstLogin = UserData.data[0].StaffFirstLogin;
		showTrialPopup = UserData.data[0].ShowTrialPopup;
		counselorId = UserData.data[0].CounselorId;
		hasClinicalAccess = UserData.data[0].HasClinicalAccess;
		authRoute = UserData.authRoute;
		menuJson = UserData.menuJson;
	}
	if (default_values && default_values.data) {
		defaultData = default_values.data;
	}
	return {
		isSuccess: isSucess,
		access_token: access_token,
		expires_in: expires_in,
		refresh_token: refresh_token,
		isShowPassword,
		authenticationType,
		DefaultData: defaultData,
	};
};

const ReduxLogin = reduxForm({
	form: "LoginForm",
})(withRouter(Login));
export default ReduxLogin;
