import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getClientAlbum } from "../../../redux/actions";
const Album = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let data = {
      Client: [
        {
          ClientId: props.ID,
        },
      ],
    };
    dispatch(getClientAlbum(data));
  }, []);

  const { albumListData } = useSelector(
    ({ get_client_album: { data, isFetching, error } }) => {
      const { Data, Message } = data || {};
      return {
        albumListData: Data && Data.Albums,
      };
    },
    shallowEqual
  );

  return (
    <div className="card card-custom w-100">
      <div className="card-body pt-5">
        <div className="album-tabs">
          <div className="team-grid">
            <div className="container-fluid">
              <div className="row people">
                {albumListData &&
                  albumListData.map((item) => {
                    const { MediaId, MediaGuid, MediaUrl, DateInserted } = item;
                    return (
                      <>
                        <div className="col-md-4 col-lg-3 item" key={uuid()}>
                          <div
                            className="box"
                            style={{
                              backgroundImage: `url(${MediaUrl})`,
                              borderRadius: "4px",
                            }}
                          >
                            <div className="cover">
                              <p className="title">{DateInserted}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Album;
