import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Button from "@material-ui/core/Button";
import { taskAssign } from "../../../../redux/actions";
import { ReduxDateInput } from "../../../components/redux-component/index";
import formDataHandler from "../../../util/formDataHandler";
import Validator from "../../../Validator";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _Close from "../../../images/close.svg";

const DForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleDueDate,
    invalid,
  } = props;
  return (
    <div className={`card card-custom`}>
      {/* Head */}
      <div className="card-header border-1">
        <h5>Update Due Date For Task</h5>
        <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
          <a
            className="btn btn-icon btn-hover-light-primary btn-sm"
            onClick={props.toggleDrawer("right", false)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl(_Close)} />
            </span>
          </a>
        </OverlayTrigger>
      </div>
      {/* Body */}
      <div className="card-body pt-2 drawer-course assessments-section">
        <form autoComplete="off" onSubmit={handleSubmit(handleDueDate)}>
          <div className="pt-2 pb-2 mx-4">
            <div className="row pb-5 pl-3 pr-3 pt-3 mt-5">
              <div className=" col-md-12 col-lg-12 mt-2">
                <Field
                  name="DueDate"
                  component={ReduxDateInput}
                  fieldName="Task Due Date"
                  labelText={"Task Due Date*"}
                  validate={[
                    Validator.required,
                    Validator.dueDate,
                    Validator.dueDate2,
                  ]}
                />
              </div>
              <div className="col-lg-12 col-md-6 mt-2 text-right mt-5">
                <Button
                  variant="contained"
                  color="secondary"
                  // className={classes.button}
                  type="submit"
                  disabled={submitting || pristine}
                >
                  Submit
                </Button>
                &nbsp;
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const ReduxDForm = reduxForm({
  form: "dueDateForm",
  enableReinitialize: true,
})(DForm);

export const DueDateForm = (props) => {
  const dispatch = useDispatch();
  const handleDueDate = (values) => {
    let ObjectData = formDataHandler(values);
    var data = {
      Task: [
        {
          TaskId: ObjectData.TaskId,
          Title: ObjectData.Title,
          Detail: ObjectData.Detail,
          ClientTask: [
            {
              ClientIds: props.ID,
              ClientTaskId: ObjectData.ClientTaskId,
              AssignedDate: ObjectData.AssignedDate,
              RepeatFrequencyCode: ObjectData.RepeatFrequencyCode,
              DueDate: ObjectData.DueDate,
            },
          ],
        },
      ],
    };

    dispatch(taskAssign(data));
  };

  return (
    <ReduxDForm
      initialValues={props.taskD}
      handleDueDate={handleDueDate}
      toggleDrawer={props.toggleDrawer}
    />
  );
};
