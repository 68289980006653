import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Button from "@material-ui/core/Button";
import PageTitle from "../../pageTitleConfig";
import { makeStyles } from "@material-ui/core/styles";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";
import formDataHandler from "../../util/formDataHandler";
import {
  getFormFieldMetaData,
  centerUpsert,
  // resetAdminstrationCenterUpsert,
  centerGet,
  resetCenterGet,
} from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const DemographicsForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getGenericFormFields, currentCountryValue } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data, isFetching, error },
    } = state;
    return {
      getGenericFormFields: data?.Data?.FormFieldMetaData || [],
    };
  }, shallowEqual);
  const classes = useStyles();
  const { handleSubmit, pristine, reset, submitting, onSubmit } = props;
  return (
    <>
      <Helmet>
        <title>{PageTitle.INTAKE_REGISTRATION}</title>
      </Helmet>
      <div className="row">
        <div className="col-lg-4 col-xxl-12 order-2 order-xxl-2 custom-tabs">
          <div className={`card card-custom`}>
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                Demographics
              </h3>
            </div>
            <div className="card-body pt-2 ">
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="enrollment-form staff-form pt-2 pb-2">
                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    {" "}
                    <DynamicForm
                      formFields={getGenericFormFields}
                      disable={false}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Submit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  {/* <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    disabled={pristine || submitting}
                    onClick={reset}
                  >
                    Clear Values
                  </Button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ReduxDemographicsForm = reduxForm({
  form: "DemographicsForm",
  enableReinitialize: true,
})(DemographicsForm);

const PreIntake = (props) => {
  const dispatch = useDispatch();
  const [centerDataInit, setCenterDataInit] = useState({ CountryISO: "USA" });
  const history = useHistory();
  const getId = () => {
    var param = new URLSearchParams(history.location.search);
    var centerId = param.get("id");
    if (centerId) {
      centerId = parseInt(centerId);
    } else {
      centerId = null;
    }
    return centerId;
  };

  useEffect(() => {
    dispatch(resetCenterGet());

    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: "PreIntakeDemographics",
          },
        ],
      })
    );
  }, []);

  const { centerDataObj, isInserted } = useSelector(
    ({ center_get: { data }, center_upsert }) => {
      return {
        centerDataObj: data?.Data?.Centers?.[0],
        isInserted: center_upsert.data && center_upsert.data.success,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (isInserted) {
      toast.success("Center added successfully");
      // dispatch(resetAdminstrationCenterUpsert());
      history.push("/center/list");
    }
  }, [isInserted]);

  useEffect(() => {
    if (centerDataObj) {
      setCenterDataInit(centerDataObj);
    }
  }, [centerDataObj]);

  const onSubmit = (values) => {
    let centerId = null;
    let paramCenterId = getId();
    if (paramCenterId && paramCenterId > 0) {
      centerId = paramCenterId;
    }
    let ObjectData = formDataHandler(values);

    const data = {
      Center: [
        {
          CenterId: centerId ? centerId : null,
          ...ObjectData,
        },
      ],
    };
    dispatch(centerUpsert(data));
  };
  return (
    <ReduxDemographicsForm
      onSubmit={onSubmit}
      // initialValues={centerDataInit}
      // centerId={getId()}
    />
  );
};
export default PreIntake;
