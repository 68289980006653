import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getSurveys(action) {
  try {
    const { payload } = action;
    const response = yield API.getSurveys(payload);
    yield put({
      type: ActionTypes.SURVEY_STUDENTS_GET_ALL_PENDING_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_STUDENTS_GET_ALL_PENDING_FAILURE,
    });
  }
}

export function* getSurveyQuestionMultiple(action) {
  try {
    const { payload } = action;
    const response = yield API.getSurveyQuestionMultiple(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_GET_MULTIPLE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_GET_MULTIPLE_FAILURE,
    });
  }
}

export function* surveyAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyAssign(payload);
    yield put({
      type: ActionTypes.SURVEY_ASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_ASSIGN_FAILURE,
    });
  }
}

export function* surveyGetToAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyGetToAssign(payload);
    yield put({
      type: ActionTypes.SURVEY_GET_TO_ASSIGN_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_GET_TO_ASSIGN_FAILURE,
    });
  }
}

export function* surveyTaskMarkInComplete(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyTaskMarkInComplete(payload);
    yield put({
      type: ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_FAILURE,
    });
  }
}

export function* getStudentSurveyDashboardData(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentSurveyDashboardData(payload);
    yield put({
      type: ActionTypes.STUDENT_SURVEY_DASHBOARD_GET_JSON_SUCCESS,
      payload: JSON.parse(response.data.data).Student[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_SURVEY_DASHBOARD_GET_JSON_FAILURE,
    });
  }
}

export function* surveyAnswersGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyAnswersGet(payload);
    yield put({
      type: ActionTypes.SURVEY_ANSWERS_GET_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_ANSWERS_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield takeLatest(ActionTypes.SURVEY_STUDENTS_GET_ALL_PENDING_REQUEST, getSurveys);
  yield takeLatest(ActionTypes.SURVEY_QUESTION_GET_MULTIPLE_REQUEST, getSurveyQuestionMultiple);
  yield takeLatest(ActionTypes.SURVEY_ASSIGN_REQUEST, surveyAssign);
  yield takeLatest(ActionTypes.SURVEY_GET_TO_ASSIGN_REQUEST, surveyGetToAssign);
  yield takeLatest(ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_REQUEST, surveyTaskMarkInComplete);
  yield takeLatest(
    ActionTypes.STUDENT_SURVEY_DASHBOARD_GET_JSON_REQUEST,
    getStudentSurveyDashboardData,
  );
  yield takeLatest(ActionTypes.SURVEY_ANSWERS_GET_REQUEST, surveyAnswersGet);
}
