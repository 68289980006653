import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _Chat from "../../../images/bi_chat.svg";

export const Chat = (cell, row, rowIndex, formatExtraData) => (
  <div className="">
    {/* <OverlayTrigger overlay={<Tooltip id="chat-tooltip">Chat</Tooltip>}>
      <a
        className="btn btn-icon btn-hover-light-primary btn-sm"
        // onClick={() => openEditProductPage(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl(_Chat)} />
        </span>
      </a>
    </OverlayTrigger> */}
    <OverlayTrigger overlay={<Tooltip id="chat-tooltip">Chat</Tooltip>}>
      <div
        className="topbar-item"
        data-toggle="tooltip"
        title="chat"
        data-placement="right"
      >
        <div
          className="btn btn-icon  btn-clean btn-md"
          // onClick={() => openEditProductPage(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl(_Chat)} />
          </span>
        </div>
      </div>
    </OverlayTrigger>
  </div>
);
