import React, { useEffect, useState, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, ButtonToolbar } from "react-bootstrap";
import { UserHeader } from "../userHeader";
import TextField from "@material-ui/core/TextField";
import _blank from "../../../images/blank-profile.png";
import DictationComponent from "../Dictation";
import {
  getPreviousNotes,
  upsertClientNote,
  resetUpsertNote,
} from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff3d",
  },
  paper: {
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const AddNoteModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { RowData, show, onHide } = props;
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleDictate = (result) => {
    if (result.result && result.result.transcript) {
      // this.props.change('note', this.props.presentNoteValue + ' ' + result.result.transcript);
      let data;
      if (value) {
        data = `${value} ${result.result.transcript}`;
      } else {
        data = result.result.transcript;
      }
      setValue(data);
    }
  };
  const addNote = () => {
    dispatch(
      upsertClientNote({
        ClientNote: [
          {
            // ClientId: 20000230,
            UserGuid: RowData.UserGuid,
            NoteContent: value,
            NoteType: RowData.TrendType,
            NoteTypeId: RowData.TrendId,
            NoteContextId: RowData.NoteContextId,
          },
        ],
      })
    );
  };
  useEffect(() => {
    dispatch(
      getPreviousNotes({
        ClientNote: [
          {
            UserGuid: "4833E380-FF86-4360-A0A0-80CE95BE8C5B",
            NoteType: RowData.TrendType,
            NoteTypeId: RowData.TrendId,
            NoteContextId: RowData.NoteContextId,
          },
        ],
      })
    );
  }, []);

  const {
    currentNoteInfo,
    previousNotesList,
    upsertClientNoteSuccess,
  } = useSelector(({ get_previous_notes, upsert_client_note }) => {
    return {
      currentNoteInfo:
        (get_previous_notes &&
          get_previous_notes.data &&
          get_previous_notes.data.ClientNote &&
          get_previous_notes.data.ClientNote[0].CurrentNoteInfo &&
          get_previous_notes.data.ClientNote[0].CurrentNoteInfo[0]) ||
        [],
      previousNotesList:
        (get_previous_notes &&
          get_previous_notes.data &&
          get_previous_notes.data.ClientNote &&
          get_previous_notes.data.ClientNote[0].PreviousNotes) ||
        [],
      upsertClientNoteSuccess:
        (upsert_client_note &&
          upsert_client_note.data &&
          upsert_client_note.data.success) ||
        false,
    };
  }, shallowEqual);

  useEffect(() => {
    if (upsertClientNoteSuccess) {
      dispatch(resetUpsertNote({}));
      onHide();
    }
  }, [upsertClientNoteSuccess]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={show}
        onClose={onHide}
        // outline={0}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="p-modal" style={{ outline: "none" }}>
          <div className="member-trend">
            {" "}
            <UserHeader
              userInfo={{
                UserGuid: "4833E380-FF86-4360-A0A0-80CE95BE8C5B",
                DisplayName: "pawan01",
                Phone: 9885064046,
                BadgeId: "Coach",
                ProfileImageUrl:
                  "https://drdevattach.blob.core.windows.net/dr-blob-container-v1/a746cbfc-2e1c-4ed3-b971-659f69c0753f.jpg",
                Age: null,
                TrendLevel: -26,
                Service: "Detox",
                ServiceTags: "WITH CARE TEAM,SUBCRIBER",
              }}
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              background: "#F7F8FA",
              boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
              borderRadius: "2px",
              padding: "16px 10px",
              marginTop: "20px",
            }}
          >
            <div className="d-flex flex-column">
              <span
                style={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                {" "}
                {currentNoteInfo.NoteTypeName}
              </span>
              <span
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#7E8299",
                }}
              >
                {" "}
                {currentNoteInfo.NoteSubType}
              </span>
            </div>
            <div>{currentNoteInfo.Remarks}</div>
          </div>
          <TextField
            // id="note-text"
            label="Add Note"
            multiline
            rowsMax="16"
            rows="2"
            value={value}
            onChange={handleChange}
            //   className={classes.textField}
            margin="normal"
            // helperText="hello"
            variant="outlined"
          />

          <div className="d-flex align-items-center justify-content-end">
            <div
              className="dictation-space mr-5 mt-5"
              style={{ display: "inline-flex", paddingRight: "10px" }}
            >
              <DictationComponent handleDictate={handleDictate} />
            </div>
            <Button variant="secondary" onClick={onHide} className="mr-5 mt-5">
              Close
            </Button>
            <Button variant="primary" onClick={addNote} className=" mt-5">
              Add
            </Button>{" "}
          </div>
          <div
            className="d-flex align-items-center justify-content-between mb-3 mt-5"
            style={{ background: "#fff" }}
          >
            <div>Previous Notes</div>
            <div style={{ color: "#009EF7" }}>View All Notes</div>
          </div>
          <div
            style={{ maxHeight: "250px", overflowY: "scroll", padding: "25px" }}
          >
            {/* debugger; const {(CurrentNoteInfo, PreviousNotes)} = item; const{" "}
            {(NoteTypeName, Remarks, NoteSubType)} = CurrentNoteInfo &&
            CurrentNoteInfo[0]; */}

            {previousNotesList &&
              previousNotesList.map((item) => {
                const { NoteContent, NoteAddedDate, EnteredOn, Remarks } = item;
                return (
                  <div
                    style={{
                      background: "#F7F8FA",
                      boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                      borderRadius: "2px",
                      padding: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                          Entered on {EnteredOn}
                        </span>{" "}
                        <span
                          className="ml-2"
                          style={{ color: "#7F8188", fontSize: "12px" }}
                        >
                          {Remarks}
                        </span>
                      </div>
                      <div style={{ color: "#7F8188" }}>
                        Note added: {NoteAddedDate}
                      </div>
                    </div>
                    <p className="mt-3">{NoteContent}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AddNoteModal;
