import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getMultistepTab, resetStaffUpsert } from "../../../redux/actions";
import CircularProgress from "@material-ui/core/CircularProgress";

import GenericTab from "../../components/gereric-tabs";
import GroupInformation from "./groupinformation";
import Patients from "./patients";

const ClinicalGroupForm = (props) => {
  const history = useHistory();

  const getGroupId = () => {
    let param = new URLSearchParams(history.location.search);
    let GroupId = param.get("id");
    if (GroupId) {
      return GroupId;
    } else {
      GroupId = null;
    }
    return GroupId;
  };

  const getStepContent = (
    step,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    FormName,
    ScreenName,
    ScreenTabId,
    IsDynamicTab
  ) => {
    switch (step) {
      case "Group Information":
        return (
          <GroupInformation
            handleNext={handleNext}
            handleBack={handleBack}
            GroupId={getGroupId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      case "Patients":
        return (
          <Patients
            handleNext={handleNext}
            handleBack={handleBack}
            GroupId={getGroupId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      default:
        return "Loading.......";
      // <CircularProgress />;
    }
  };

  return (
    <>
      <div className="staff-page">
        <GenericTab getStepContent={getStepContent} FormName="ClinicalGroups" />
      </div>
    </>
  );
};
export default ClinicalGroupForm;
