import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const ReduxMultiSelect = (props) => {
  const {
    input,
    options,
    labelText,
    meta,
    fieldName,
    isReturnText,
    // multiple,
    value,
  } = props;
  const [selected, setSelected] = useState([...input.value]);
  const classes = useStyles();

  var { touched, error, warning, active } = meta || {};
  return (
    <>
      <FormControl
        // className={classes.formControl}
        fullWidth
        className="formControl"
        error={touched && error}
      >
        <InputLabel htmlFor="filled-age-simple">{labelText}</InputLabel>
        <Select
          {...input}
          // onChange={(value) => input.onChange(value)}
          // onBlur={() => input.onBlur(input.value)}
          // options={options}
          fullWidth
          multiple={true}
          value={selected}
          // defaultValue={value}
          // onChange={(event) => setSelected(event.target.value)}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            if (typeof value === "string") {
              setSelected(value.split(","));
              return value;
            } else {
              setSelected(value);
              return value;
            }
            // setSelected(
            //   // On autofill we get a stringified value.
            //   typeof value === "string" ? value.split(",") : value
            // );
          }}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}

          {isReturnText
            ? options &&
              options.map((item, index) => {
                const { Text, Value } = item;
                return <MenuItem value={Text}>{Text}</MenuItem>;
              })
            : options &&
              options.map((item, index) => {
                const { Text, Value } = item;
                return <MenuItem value={Value}>{Text}</MenuItem>;
              })}
        </Select>
        {touched &&
          ((error && fieldName && (
            <FormHelperText id="component-error-text">
              {" "}
              {fieldName} {error}
            </FormHelperText>
          )) ||
            (warning && <span className="warning-color">{warning}</span>))}
      </FormControl>
    </>
  );
};
