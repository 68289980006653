const validate = {
  careTeamPrimaryRequired: (value) => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === "string" ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return "At least 1 care team member is required";
  },
  required: (value) => (value ? undefined : "Required"),
  requiredInput: (value) => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : "Required";
  },
  dobStudent: (value) => {
    let validYear = new Date().getFullYear();
    if (value && value.length == 10) {
      let dobArray = value && value.split("/");
      if (
        dobArray[0] < 1 ||
        dobArray[0] > 12 ||
        dobArray[1] < 1 ||
        dobArray[1] > 31 ||
        dobArray[2].length != 4 ||
        dobArray[2] < 1900 ||
        dobArray[2] > validYear
      ) {
        return "Format(MM/DD/YYYY)";
      }
    } else {
      return "Format(MM/DD/YYYY)";
    }
  },
  // ssn: value => {
  //   if (value) {
  //     const onlyNums = value.replace(/[^\d\*]/g, '');
  //     if (onlyNums.length !== 9) {
  //       return 'Please enter 9 digits';
  //     }
  //   } else {
  //     return 'Social Security Number Required';
  //   }
  // },
  ssn9: (value) => {
    if (value) {
      const onlyNums = value.replace(/[^\d\*]/g, "");
      if (onlyNums.length !== 9) {
        return "Please enter 9 digits";
      }
    } else {
      return "Social Security Number Required";
    }
  },
  ssn11: (value) => {
    if (value) {
      const onlyNums = value.replace(/[^\d\*]/g, "");
      if (onlyNums.length !== 11) {
        return "Please enter 11 digits";
      }
    } else {
      return "Social Security Number Required";
    }
  },
  endDate: (value, values, props, name) => {
    if (!value) {
      return "Required";
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split("[")[0];
    var startDate = values[objectName][index].startDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 > date1) {
    } else {
      return "End Date has to be greater than Start Date";
    }
  },
  endDate2: (value, values, props, name) => {
    if (!value) {
      return "Required";
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split("[")[0];
    var startDate = values[objectName][index].startDate;
    var today = new Date();
    var date2 = new Date(value).getTime();
    if (date2 < today) {
    } else {
      return "has not to be greater than Today";
    }
  },
  email: (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Format Invalid"
      : undefined,
  allowNumeric: (value) =>
    value && !/^[0-9]+$/.test(value)
      ? "Please enter numeric values only"
      : undefined,
  allowAlphabet: (value) =>
    value && !/[a-zA-Z]+/g.test(value)
      ? "Please enter alphabets values only"
      : undefined,
  allowNumericMobile: (value) =>
    value && !/^[0-9--]*$/.test(value)
      ? "Please enter numeric values only"
      : undefined,
  NumericMobile: (value) => {
    if (value) {
      const numWithoutChar = value.replace(/[^0-9\-\(\)\s)]+/g, "");
      if (value == numWithoutChar) {
        // const onlyNums = value.replace(/^[\d\(\)\-\s]+$/g, '');
        const onlyNums = numWithoutChar.replace(/\D/g, "");
        if (onlyNums.length != 10) {
          return "Please enter 10 digits";
        }
      } else {
        return "numeric values only";
      }
    }
  },
  LandlineNumber: (value) => {
    if (value) {
      const numWithoutChar = value.replace(/[^0-9\-\(\)\s)]+/g, "");
      if (value == numWithoutChar) {
        // const onlyNums = value.replace(/^[\d\(\)\-\s]+$/g, '');
        const onlyNums = numWithoutChar.replace(/\D/g, "");
        if (onlyNums.length != 10) {
          return "Please enter 10 digits";
        }
      } else {
        return `accepts numbers (0-9), space '-' and '( )' only`;
      }
    }
  },
  allowNumericAge: (value) => {
    if (value) {
      return value < 1 || value > 99 ? "Please enter 1-99 only" : undefined;
    } else {
      return "Field Required";
    }
  },
  usaZip: (value) =>
    value && !/^[0-9]{5}$/.test(value) ? "code is invalid for USA" : undefined,
  // zip: value => (value && !/^[a-zA-Z0-9_ ]{5,9}$/.test(value) ? 'code is invalid' : undefined),
  //allowing hyphens also in zip code now
  zip: (value) =>
    value && !/^[\-a-zA-Z0-9_ ]{5,9}$/.test(value)
      ? "code is invalid"
      : undefined,
  maxCount: (value) => {
    let count;
    if (typeof value === "string") {
      count = value.split(",").length;
    } else {
      count = value.length;
    }
    if (count && count > 4) {
      return "Max 4 allowed";
    }
    return undefined;
  },
  minValue: (value) => {
    if (value && value < 2) {
      return "At least 2 member is required";
    }
    return undefined;
  },
};

const dateofbirthNormalize = (val, prevVal) => {
  if (!val) {
    return val;
  }

  const onlyNums = val.replace(/[^\d]/g, "");
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8
  )}`;
};
const normalizeToYYYYMMDD = (val, prevVal) => {
  if (!val) {
    return val;
  }

  const onlyNums = val.replace(/[^\d]/g, "");
  if (onlyNums.length <= 4) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 4)}/${onlyNums.slice(4)}`;
  }
  return `${onlyNums.slice(0, 4)}/${onlyNums.slice(4, 6)}/${onlyNums.slice(
    6,
    8
  )}`;
};

const groupNumberNormalize = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  return onlyNums;
};

// const ssnNormalize = value => {
//   if (!value) {
//     return value;
//   }

//   const onlyNums = value.replace(/[^\d]/g, '');
//   if (onlyNums.length <= 3) {
//     return onlyNums;
//   }
//   if (onlyNums.length <= 6) {
//     return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
//   }
//   return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 9)}`;
// };

const ssn9Normalize = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    9
  )}`;
};

const ssn11Normalize = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    9
  )}-${onlyNums.slice(9, 11)}`;
};

// const ssnFormatter = onlyNums => {
//   if (!onlyNums) return '';

//   if (onlyNums.indexOf('-') > 0) return onlyNums;

//   if (onlyNums.length <= 3) {
//     return onlyNums;
//   }
//   if (onlyNums.length <= 6) {
//     return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
//   }
//   return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 9)}`;
// };

const ssn9Formatter = (onlyNums) => {
  if (!onlyNums) return "";

  if (onlyNums.indexOf("-") > 0) return onlyNums;

  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    9
  )}`;
};
const ssn11Formatter = (onlyNums) => {
  if (!onlyNums) return "";

  if (onlyNums.indexOf("-") > 0) return onlyNums;

  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    9
  )}-${onlyNums.slice(9, 11)}`;
};

const MobileFormatter = (onlyNums) => {
  if (!onlyNums) return "";

  if (onlyNums.indexOf("-") > 0) return onlyNums;

  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};
const MobileNormalize = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};
export default validate;

export {
  // ssnFormatter,
  ssn9Formatter,
  ssn11Formatter,
  // ssnNormalize,
  ssn9Normalize,
  ssn11Normalize,
  groupNumberNormalize,
  dateofbirthNormalize,
  normalizeToYYYYMMDD,
  MobileFormatter,
  MobileNormalize,
};
