const PageTitle = {
  DASHBOARD: "Dashboard | Youu",
  INTAKE_INTAKE: "Intake | Intake-list",
  INTAKE_PRE_INTAKE: "Pre-Intake | Intake",
  TIME_TRAVEL: "Time Travel | Youu",
  INTAKE_REGISTRATION: "New Registration | Intake ",
  POPULATION_ACTIVITIES_HOME: "Population-Activities | Youu",
  CLIENT_ROSTER_HOME: "Client-Roster | Youu",
  CLIENT_ROSTER_PROFILE_TAB: "Profile | Client-Roster",
  SMALL_GROUP_LIST: "Small Group | List",
  CLINICAL_GROUP_LIST: "Clinical Group | List",
};
export default PageTitle;
