import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

import { useSubheader } from "../../../../_metronic/layout";
import CenteredTabs from "../../custom-component/custom-tabs";
import Clients from "../../../pages/clinicalgroupform/clients";
// import Appointments from "../../../pages/clinicalgroupform/appointementList";
// import Clients from "../../../pages/clinicalgroupform/clients";
// import Clients from "../../../pages/clinicalgroupform/clients";

import {
  getDynamicScreenTab,
  resetGetDynamicScreenTab,
} from "../../../../redux/actions";
import { getClientId } from "../../../util/getParam";
const TabsSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [TabLabels, setTabLabels] = useState([]);
  useEffect(() => {
    let data = {
      Client: [
        {
          ScreenName: "ClinicalGroups",
        },
      ],
    };
    dispatch(getDynamicScreenTab(data));
    return () => {
      dispatch(resetGetDynamicScreenTab());
    };
  }, []);

  const Labels = {
    Clients: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <Clients ID={getClientId()} />
        </>
      );
    },
    Appointments: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <Appointments ID={getClientId()} /> */}
        </>
      );
    },
    Notes: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <Clients ID={getClientId()} /> */}
        </>
      );
    },
    Services: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <Clients ID={getClientId()} /> */}
        </>
      );
    },
  };

  const { TabInfo, Summary, groupInfo } = useSelector(
    ({
      get_dynamic_screen_tab: { data, isFeching },
      // group_get: { data: clinicalGroupData },
    }) => {
      return {
        TabInfo: data?.Tabs,
        // groupInfo: clinicalGroupData?.Data?.ClinicalGroup?.[0] || {},
      };
    },
    shallowEqual
  );

  // useEffect(() => {
  //   if (TabInfo && TabInfo.length > 0) {
  //     let tabs = TabInfo.map((tab, index) => {
  //       return {
  //         Id: tab.TabName,
  //         Name: tab.TabName,
  //         Component: Labels[tab.Component],
  //       };
  //     });
  //     setTabLabels(tabs);
  //   }
  // }, [TabInfo]);

  return (
    // <p>CLINICAL GROUPS</p>
    <div className="dashboard__alerts custom-tabs">
      {TabInfo && TabInfo.length > 0 && (
        <CenteredTabs
          tabsData={TabInfo}
          labels={Labels}
          baseTabUrl={`/group-details/?id=${getClientId()}&tab=`}
        />
      )}
    </div>
  );
};
export default TabsSection;
