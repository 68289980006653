import React, { Component } from "react";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";
import { Device } from "@twilio/voice-sdk";
// import CustomModal from "../../core/modal";
import Axios from "axios";
import _blank from "../../../images/blank-profile.png";

import ReactStopwatch from "react-stopwatch";
import moment from "moment";
class Twilio extends Component {
  constructor(props) {
    super(props);
    this.state = { twilioToken: "Fetching Token ...." };
  }

  componentDidMount() {
    Axios.post("CallToken/Generate", {}).then((response) => {
      if (response && response.data.token) {
        this.setState({
          twilioToken: response.data.token,
          callStartTime: null,
          callEndTime: null,
          userName: response.data.userName,
          email: response.data.email,
          //   callModelVisible: true,
          //   clickedRecord: record,
        });
      }
    });
  }
  updateCallStatus = (status) => {
    console.log(status);
  };

  setupHandlers = (device) => {
    device.on("ready", (_device) => {
      this.updateCallStatus("Ready");
    });

    /* Report any errors to the call status display */
    device.on("error", (error) => {
      this.updateCallStatus("ERROR: " + error.message);
      this.setState({
        isError: true,
      });
      this.handleCallDisconnect();
    });

    /* Callback for when Twilio Client initiates a new connection */
    device.on("connect", (connection) => {
      // Enable the hang up button and disable the call buttons
      //   hangUpButton.prop('disabled', false);
      //   callCustomerButtons.prop('disabled', true);
      //   callSupportButton.prop('disabled', true);
      //   answerButton.prop('disabled', true);

      // If phoneNumber is part of the connection, this is a call from a
      // support agent to a customer's phone
      if ("phoneNumber" in connection.message) {
        this.updateCallStatus("In call with " + connection.message.phoneNumber);
      } else {
        // This is a call from a website user to a support agent
        this.updateCallStatus("In call with support");
      }
    });

    /* Callback for when a call ends */
    device.on("disconnect", (connection) => {
      // Disable the hangup button and enable the call buttons
      //   hangUpButton.prop('disabled', true);
      //   callCustomerButtons.prop('disabled', false);
      //   callSupportButton.prop('disabled', false);

      this.updateCallStatus("Ready");
    });

    /* Callback for when Twilio Client receives a new incoming call */
    device.on("incoming", (connection) => {
      this.updateCallStatus("Incoming support call");

      // Set a callback to be executed when the connection is accepted
      connection.accept(() => {
        this.updateCallStatus("In call with customer");
      });

      // Set a callback on the answer button and enable it
      //   answerButton.click(function() {
      //     connection.accept();
      //   });
      //   answerButton.prop('disabled', false);
    });

    device.on("accept", (call) => {
      console.log("The incoming call was accepted.");
    });
  };

  handleCallConnect = async () => {
    const { userData } = this.props;
    const device = new Device(this.state.twilioToken);
    device.addListener("connect", () => {
      console.log("connected device");
    });
    this.setupHandlers(device);
    this.setState({ device, isDisconnected: false, isError: false });

    let call = await device.connect({
      params: {
        phoneNumber: userData.Phone || userData.PrimaryPhone,
        email: this.state.email,
      },
    });

    call.on("connect", (conn) => {
      console.log("connected call", conn);
    });
    call.on("reject", (conn) => {
      console.log("rejected call", conn);
    });
    call.on("cancel", (conn) => {
      console.log("cancelled call", conn);
    });

    call.on("ringing", (conn) => {
      console.log("ringing call", conn);
      this.setState({ callStartTime: new Date() });
    });

    // call.on('sample', conn => {
    //   // console.log('sample call', conn);
    // });

    call.on("disconnect", (conn) => {
      this.setState({ callEndTime: new Date(), isDisconnected: true }, () => {
        setTimeout(() => {
          this.setState({ isDisconnected: false });
        }, 3000);
      });
      console.log("disconnected call", conn, device.disconnectAll());
      this.setState({ hideCallNow: false });
    });

    call.on("outgoing", (conn) => {
      console.log("outgoing ", conn);
    });

    call.on("transportClose", (conn) => {
      console.log("transportClose ", conn);
    });

    call.on("accept", (conn) => {
      console.log("accepted call", conn);
    });

    // device.on('outgoing', call => {
    //   console.log(call);
    //   // call is a Twilio.Call instance
    //   call.status();
    //   this.updateCallStatus('Ready');
    // });

    // if (call) {
    //   debugger;
    //   console.log(call.status());
    // }

    // or handle an incoming Call
    // call.on('outgoing', call => {
    //   debugger;
    //   console.log(call.status());
    // });
  };

  handleCallDisconnect = () => {
    this.state.device && this.state.device.disconnectAll();
    console.log("The call has ended.");
    this.setState({ hideCallNow: false });
  };
  getDifference(start, end) {
    start = start.split(":");
    end = end.split(":");
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0) hours = hours + 24;

    return (
      (hours <= 9 ? "0" : "") +
      hours +
      ":" +
      (minutes <= 9 ? "0" : "") +
      minutes
    );
  }
  getDuration = (startTime, endTime) => {
    let s = moment(startTime);
    let e = moment(endTime);
    let diff = e.diff(s);
    // let f = moment.utc(diff).format('HH:mm:ss.SSS');
    let f = moment.utc(diff).format("HH:mm:ss");
    return f;
  };
  render() {
    const { userData, isOpen, onHide } = this.props;
    return (
      <>
        <Modal
          // isOpen={isOpen}
          {...this.props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // yesAction={() => {
          //   this.setState({
          //     twilioToken: "",
          //     device: "",
          //   });
          //   // this.props.resetIsOpen();
          // }}
          // yesText="Close"
          // hideOk={true}
          // hideCancel={true}
          // changeMaxWidth="600px"
          // className="px-5 twilio-modal"
        >
          <Modal.Body>
            <div
              style={{
                width: "100%",
                marginBottom: "30px",
                textAlign: "center",
                fontWeight: "bold",
                wordWrap: "wrap",
              }}
            >
              {/* {!this.state.hideCallNow ? (
              <h1 className="header">Are you sure you want to call?</h1>
            ) : (
              <h1 className="header" style={{ color: 'white' }}>
                Are you sure you want to call?
              </h1>
            )} */}

              <div className="d-flex align-items-center justify-content-center">
                <div
                  class="student-list-pic"
                  style={{ flex: "0 0 29%", minWidth: "50px" }}
                >
                  <img
                    src={
                      userData.ProfileImageUrl ||
                      userData.ProfileImgUrl ||
                      _blank
                    }
                    alt="userPhotograph"
                    class="img-circle"
                    // height="80"
                    // width="80"
                    style={{
                      borderRadius: "50%",
                      marginRight: "10px",
                      objectFit: "cover",
                      width: "60px",
                      height: "60px",
                    }}
                  />
                </div>
              </div>
              <p class="col-12 mb-3 user-name">
                {userData.Name || userData.FirstName}
              </p>
              <p class=" col-12 mb-3 user-phone">
                {userData.Phone || userData.PrimaryPhone}
              </p>
              {/* {this.state.twilioToken} */}
            </div>
            <div className="d-flex align-items-center justify-content-center mb-2">
              {this.state.hideCallNow ? (
                <ReactStopwatch
                  seconds={0}
                  minutes={0}
                  hours={0}
                  // limit="00:00:10"
                  onChange={({ hours, minutes, seconds }) => {
                    // do something
                  }}
                  onCallback={() => console.log("Finish")}
                  render={({ formatted, hours, minutes, seconds }) => {
                    return (
                      <div>
                        {/* <p>{formatted}</p>
                      <p>Hours: {hours}:
                        {minutes}:{seconds}</p> */}
                        <p style={{ color: "rgb(0, 121, 253)" }}>
                          {" "}
                          <i class="fas fa-history"></i>&nbsp;{formatted}
                        </p>
                      </div>
                    );
                  }}
                />
              ) : (
                <div style={{ marginBottom: "1em" }}>
                  Are you sure you want to call?
                </div>
              )}
            </div>
            {this.state.isError && (
              <div
                style={{
                  color: "red",
                  "font-weight": "bold",
                  "text-align": "center",
                  "padding-bottom": "10px",
                }}
              >
                Something went wrong, please try again.
              </div>
            )}
            {this.state.isDisconnected && (
              <>
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingBottom: "10px",
                    transition: "all 0.3s ease",
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <i class="fas fa-phone-slash"></i>
                  &nbsp; Call Disconnected
                </div>
                <div
                  style={{ color: "rgb(0, 121, 253)" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <i class="fas fa-history"></i>&nbsp;
                  {this.getDuration(
                    this.state.callStartTime,
                    this.state.callEndTime
                  )}
                  {/* {Math.round(
                  (this.state.callEndTime - this.state.callStartTime) / (1000 * 60 * 60 * 24),
                )} */}
                  {/* {this.getDifference(
                  `${this.state.callStartTime.getHours()}:${this.state.callStartTime.getMinutes()}:${this.state.callStartTime.getSeconds()}`,
                  `${this.state.callEndTime.getHours()}:${this.state.callEndTime.getMinutes()}:${this.state.callEndTime.getSeconds()}`,
                )} */}
                </div>
              </>
            )}
            <div className="d-flex align-items-center justify-content-center mb-2">
              {" "}
              {!this.state.hideCallNow && (
                <button
                  style={{ background: "#2FE192", cursor: "pointer" }}
                  class="btn btn-success"
                  onClick={() => {
                    this.setState({ hideCallNow: true });
                    this.handleCallConnect();
                  }}
                >
                  <i class="fas fa-phone-alt"></i> &nbsp; Call Now
                </button>
              )}
              {this.state.hideCallNow && (
                <button
                  style={{ background: "red", cursor: "pointer" }}
                  class="btn btn-danger"
                  onClick={() => {
                    this.setState({ hideCallNow: false });
                    this.handleCallDisconnect();
                  }}
                >
                  <i class="fas fa-phone-slash"></i> &nbsp; Disconnect
                </button>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              {" "}
              {!this.state.hideCallNow && (
                <button
                  style={{
                    background: "transparent",
                    border: "0.5px solid #0079FD",
                    color: "#0079FD",
                    cursor: "pointer",
                  }}
                  class="btn btn-success"
                  onClick={() => {
                    this.handleCallDisconnect();
                    onHide();
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default Twilio;
