import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  reduxForm,
} from "redux-form";
import {
  getFormFieldMetaData,
  appointmentUpsert,
} from "../../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../../core/generic-form";
import formDataHandler from "../../../util/formDataHandler";
import CustomLoader from "../../../components/custom-component/custom-loader";

const AppointmentNewClient = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let data = {
      DynamicForm: [
        {
          FormName: "AppointmentNewClient",
        },
      ],
    };
    dispatch(getFormFieldMetaData(data));
  }, [formFields]);

  const { isFetching } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data, isFetching },
    } = state;
    return { isFetching };
  }, shallowEqual);

  const handleCreateAppointment = (values) => {
    let ObjectData = formDataHandler(values);
    const {
      AppointmentDate,
      AppointmentEndTime,
      AppointmentTime,
      Email,
      FirstName,
      LastName,
      MiddleName,
      OfficeName,
      PlaceOfServiceId,
      PractitionerId,
      PrimaryPhone,
      ProgramId,
      ReasonForVisit,
      RecurringType,
      ServiceCode,
      Title,
      Status,
    } = ObjectData;

    const formData = {
      Appointment: [
        {
          ClientId: null,
          Title,
          AppointmentDate,
          AppointmentTime,
          AppointmentEndTime,
          PractitionerId,
          OfficeName,
          ServiceCode,
          ReasonForVisit,
          Status,
          PrimaryPhone,
          MiddleName,
          FirstName,
          LastName,
          ProgramId,
          PlaceOfServiceId,
          IsRecurring: false,
          Email,
          // GroupAppointmentRecurring: [
          //   {
          //     GroupAppointmentRecurringId: null,
          //     RecurringType,
          //     WeekDays: "2,5",
          //     MonthlyRecurring: null,
          //     RecurringCount: "3",
          //   },
          // ],
        },
      ],
    };

    dispatch(appointmentUpsert(formData));
  };

  const {
    formFields,
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    ClientId,
  } = props;
  return (
    <>
      {isFetching ? (
        <CustomLoader />
      ) : (
        <form
          autoComplete="off"
          onSubmit={handleSubmit(handleCreateAppointment)}
        >
          <div className="enrollment-form staff-form pt-2 pb-2">
            <div className="row pb-5 pl-3 pr-3 pt-3">
              <DynamicForm formFields={formFields} disable={false} />
            </div>
            <div className="text-right">
              <Button
                variant="contained"
                color="secondary"
                //   className={classes.button}
                type="submit"
                //   disabled={pristine || submitting}
              >
                Create Visit
              </Button>
              &nbsp;
            </div>
          </div>
        </form>
      )}
    </>
  );
};

const ReduxAppointmentNewClient = reduxForm({
  form: "AppointmentNewClient",
  enableReinitialize: true,
})(AppointmentNewClient);

export default ReduxAppointmentNewClient;
