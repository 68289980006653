import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Field, reduxForm } from "redux-form";
import _Close from "../../../images/close.svg";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";

import {
  getFormFieldMetaData,
  adminstrationCenterUpsert,
  resetAdminstrationCenterUpsert,
  adminstrationGetCenter,
} from "../../../../redux/actions";

import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../../core/generic-form";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));
let FilterForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  //   useEffect(() => {}, []);

  const { FilterFormFields } = useSelector(
    ({ get_dynamic_grid_filter: { data, isFetching, error } }) => {
      return {
        FilterFormFields: data?.Data?.Filters || [],
      };
    },
    shallowEqual
  );
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onFilter,
    toggleFilterDrawer,
  } = props;

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          onFilter(values, toggleFilterDrawer);
        })}
      >
        <div className="filter-form pt-2 pb-2">
          <div className="row pb-5 pl-3 pr-3 pt-3">
            {" "}
            <DynamicForm formFields={FilterFormFields} disable={false} />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Filter
          </Button>
          {/* &nbsp;
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            disabled={pristine || submitting}
            onClick={props.toggleFilterDrawer("right", false)}
          >
            Cancel
          </Button> */}
          &nbsp;
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            type="submit"
            disabled={pristine || submitting}
          >
            Apply
          </Button>
        </div>
      </form>
    </>
  );
};
const ReduxFilterForm = reduxForm({
  form: "filterForm",
  enableReinitialize: true,
})(FilterForm);

export const FilterDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom page");
  //   useEffect(() => {
  //     dispatch(adminstrationGetCourse({ CourseId: props.CourseId }));
  //   }, []);

  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-1 pt-5">
          <h3>Filter</h3>
          <span onClick={props.toggleFilterDrawer("right", false)}>
            <img
              src={_Close}
              alt="Close"
              style={{ cursor: "pointer", alignSelf: "baseline" }}
            />
          </span>
        </div>
        {/* Body */}
        <div className="card-body pt-5 drawer-filter">
          <div className="row">
            <div className="col-12">
              <ReduxFilterForm
                toggleFilterDrawer={props.toggleFilterDrawer}
                onFilter={props.onFilter}
                // initialValues={props.FilteredData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
