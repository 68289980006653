import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  staffGet,
} from "../../../../redux/actions";
import _Edit from "../../../images/edit.svg";
import _Close from "../../../images/close.svg";
import _Agreed from "../../../images/agreed.svg";
import _Disagreed from "../../../images/disagreed.svg";

export const IntakeDrawer = (props) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(staffGet({ StaffId: props.StaffId }));
  // }, []);

  // const { StaffInfo } = useSelector(({ staff_get }) => {
  //   return {
  //     StaffInfo: staff_get.data,
  //   };
  // }, shallowEqual);

  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <h3>Intake Drawer </h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-5 drawer-intake">
          <h6>Intake Drawer</h6>
          <div className="row intake-section"></div>
        </div>
      </div>
    </>
  );
};
