import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* taskListGet(action) {
    try {
        const { payload } = action;
        const response = yield API.taskListGet(payload);
        yield put({
            type: ActionTypes.TASK_LIST_GET_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.TASK_LIST_GET_FAILURE
        });
    }
}

export function* taskAssign(action) {
    try {
        const { payload } = action;
        const response = yield API.taskAssign(payload);
        yield put({
            type: ActionTypes.TASK_ASSIGN_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.TASK_ASSIGN_FAILURE
        });
    }
}

export function* taskOptionGet(action) {
    try {
        const { payload } = action;
        const response = yield API.taskOptionGet(payload);
        yield put({
            type: ActionTypes.TASK_OPTIONS_GET_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.TASK_OPTIONS_GET_FAILURE
        });
    }
}

export function* taskMarkComplete(action) {
    try {
        const { payload } = action;
        const response = yield API.taskMarkComplete(payload);
        yield put({
            type: ActionTypes.TASK_MARK_COMPLETE_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.TASK_MARK_COMPLETE_FAILURE
        });
    }
}

export function* taskUnAssign(action) {
    try {
        const { payload } = action;
        const response = yield API.taskUnAssign(payload);
        yield put({
            type: ActionTypes.TASK_UNASSIGN_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.TASK_UNASSIGN_FAILURE
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(ActionTypes.TASK_LIST_GET_REQUEST, taskListGet),
        takeLatest(ActionTypes.TASK_ASSIGN_REQUEST, taskAssign),
        takeLatest(ActionTypes.TASK_OPTIONS_GET_REQUEST, taskOptionGet),
        takeLatest(ActionTypes.TASK_MARK_COMPLETE_REQUEST, taskMarkComplete),
        takeLatest(ActionTypes.TASK_UNASSIGN_REQUEST, taskUnAssign)
    ]);
}
