import React, {
  useEffect,
  useMemo,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import { v4 as uuid } from "uuid";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../../_metronic/_partials/dropdowns";
import _filterColumn from "../../../images/filter-column.svg";
export const CustomToggleList = ({
  columns,
  onColumnToggle,
  toggles,
  setTableColumns,
}) => {
  const columnNameList = Object.keys(toggles);
  // let filterToggle = columnNameList.filter((item) => {
  //   return toggles[item];
  // });

  // useEffect(() => {
  //   setColumnName(filterToggle);
  // }, []);
  function handleChange(event) {
    let checkboxValue = event.target.value;
    let isChecked = event.target.checked;
    onColumnToggle(checkboxValue);
    let data = columns.map((col) => {
      if (checkboxValue === col.dataField) {
        if (col.dataField === "Actions") {
          return {
            ...col,
            toggle: !isChecked,
            hidden: !isChecked,
            csvExport: false,
          };
        } else
          return {
            ...col,
            toggle: !isChecked,
            hidden: !isChecked,
            csvExport: isChecked,
          };
      } else {
        return { ...col };
      }
    });
    setTableColumns(data);
  }
  return (
    <Dropdown
      className="dropdown-inline"
      style={{ marginLeft: "15px" }}
      alignRight
    >
      <Dropdown.Toggle
        variant="transparent"
        id="dropdown-toggle-top"
        className="btn btn-hover-light-primary btn-md btn-icon"
        as={DropdownCustomToggler}
      >
        {/* <i className="ki ki-bold-more-ver" /> */}
        <img src={_filterColumn} height="25" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-md dropdown-menu-right">
        <div className="navi-header font-weight-bold p-2 text-center">
          <span className="font-size-lg">Select Column</span>
        </div>
        {columns &&
          columns.map((column) => {
            let colName = column.dataField;
            return (
              <MenuItem key={colName} value={colName}>
                <Checkbox
                  checked={toggles[colName]}
                  value={colName}
                  onChange={handleChange}
                />
                <ListItemText primary={column.text} />
              </MenuItem>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
