import React from "react";
import { v4 as uuid } from "uuid";
import _blank from "../../images/blank-profile.png";
export const MemberTrendsFormatter = (cell, row, rowIndex, formatExtraData) => {
  // const { More, Phone, chat } = row.Actions[0];
  return (
    <div
      className="d-flex align-items-center"
      style={{ minWidth: "250px", maxWidth: "300px" }}
    >
      {/* <div className="col-3 d-flex align-items-center">
        <div
          className={
            row.TrendLevel == 0
              ? "pic-circle-neutral"
              : row.TrendLevel == 1
              ? "pic-circle-arrow-up"
              : "pic-circle-arrow-down"
          }
        >
          <div class="student-list-pic" style={{ flex: "0 0 29%" }}>
            <img
              src={row.ProfileImageUrl || _blank}
              // alt="student"
              class="img-circle"
              height="40"
              width="40"
              style={{
                borderRadius: "50%",
                padding: "2px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div> */}

      <div className="col-12 d-flex align-items-center">
        <div className="d-flex flex-column">
          <div>
            <span className="member-name">
              {row.DisplayName || row.MemberName}
            </span>{" "}
            {/* <span className="member-service">{row.Service}</span> */}
          </div>
          <div style={{ wordBreak: "break-all" }}>
            {row.ServiceTags &&
              row.ServiceTags.split(",").map((tag) => {
                return (
                  <span className="member-tag" key={uuid()}>
                    {tag}
                  </span>
                );
              })}
            {/* <p className="member-tag">{}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};
