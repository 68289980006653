import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { makeStyles } from "@material-ui/core/styles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { smallGroupGet } from "../../../../redux/actions";
import _Edit from "../../../images/edit.svg";
import _Close from "../../../images/close.svg";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));
export const ViewSmallGroupDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      smallGroupGet({
        SmallGroup: [
          {
            SmallGroupGuid: props.SmallGroupGuid,
          },
        ],
      })
    );
  }, []);

  const { smallGroupInfo, SmallGroupData } = useSelector(
    ({ small_group_get: { data: smallGroupData } }) => {
      return {
        SmallGroupData: smallGroupData?.Data?.SmallGroup?.[0] || {},
        smallGroupInfo:
          smallGroupData?.Data?.SmallGroup?.[0]?.SmallGroup?.[0] || {},
      };
    },
    shallowEqual
  );
  const {
    SmallGroupGuid,
    GroupName,
    GroupReferralCode,
    Description,
    WhatYouWillLearn,
    StartDate,
    EndDate,
    GroupCapacity,
    IsPrivate,
    IsDeActivated,
    GroupTags,
    GroupLeaderText,
    GroupTagsText,
    MeetingInfoText,
    GroupTypeText,
    DateRangeDDMMMYYYY,
    GroupPrivacyText,
  } = smallGroupInfo || {};
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-1">
          <h3>Small Group Details</h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-2 drawer-small-group drawer-details-view">
          <div className="row">
            <div className="col-11">
              <span className="small-group-title">{GroupName}</span>
            </div>
            <div className="col-1">
              <div>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="edit-tooltip">Edit Small Group</Tooltip>
                  }
                >
                  <a
                    className="btn btn-icon btn-hover-light-primary btn-sm"
                    onClick={() => {
                      history.push(
                        `/community/smallgroup/edit?id=${SmallGroupGuid}`
                      );
                    }}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl(_Edit)} />
                    </span>
                  </a>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Date</span>
                  <span className="c-value">{DateRangeDDMMMYYYY}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Group Leader</span>
                  <span className="c-value">{GroupLeaderText}</span>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Group Tags</span>
                  <span className="c-value">{GroupTagsText}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Meeting Info</span>
                  <span className="c-value">{MeetingInfoText}</span>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Group Capacity</span>
                  <span className="c-value">{GroupCapacity}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Clients Enrolled</span>
                  <span className="c-value"></span>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Last Meeting</span>
                  <span className="c-value">{StartDate}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Next Meeting</span>
                  <span className="c-value">{StartDate}</span>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Last Updated</span>
                  <span className="c-value">{StartDate}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Group Privacy</span>
                  <span className="c-value">{GroupPrivacyText}</span>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Group Type</span>
                  <span className="c-value">{GroupTypeText}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Group Status</span>
                  <span className="c-value">
                    {IsDeActivated ? "InActive" : "Active"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
