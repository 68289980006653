const Validator = {
  careTeamPrimaryRequired: (value) => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === "string" ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return "At least 1 care team member is required";
  },
  required: (value) => (value ? undefined : "Required"),
  email: (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Invalid email address"
      : undefined,
  allowNumeric: (value) =>
    value && !/^[0-9]+$/.test(value)
      ? "Please enter numeric values only"
      : undefined,
  allowAlphabet: (value) =>
    value && !/[a-zA-Z]+/g.test(value)
      ? "Please enter alphabets values only"
      : undefined,
  allowNumericMobile: (value) =>
    value && !/^[0-9--]*$/.test(value)
      ? "Please enter numeric values only"
      : undefined,
  dueDate: (value, values, props, name) => {
    if (!value) {
      return "Required";
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split("[")[0];
    // var AssignedDate = values[objectName][index].AssignedDate;
    var AssignedDate = values && values.AssignedDate;
    var date1 = new Date(AssignedDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 > date1) {
    } else {
      return "has to be greater than Assigned Date";
    }
  },
  dueDate2: (value, values, props, name) => {
    if (!value) {
      return "Required";
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split("[")[0];
    // var startDate = values[objectName][index].startDate;
    var today = new Date().getTime();
    var date2 = new Date(value).getTime();
    debugger;
    if (date2 > today) {
    } else {
      return "has not to be greater than Today";
    }
  },
};
export default Validator;
