import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  assessmentsListGet,
  assessmentsMarkAsDiscard,
} from "../../../redux/actions";
import { ReduxAssignSurveyForm } from "./forms/assignSurveyForm";
import { toast } from "react-toastify";
import _RedCrossIcon from "../../images/redCrossIcon.svg";
import {
  OverdueAssessmentsCard,
  CompletedAssessmentsCard,
  ArchivedAssessmentsCard,
  AssigedAssessmentsCard,
} from "../../components/custom-cards";
import CustomTable from "../../components/custom-component/custom-table";
import ToggleButtons from "../../components/custom-component/ToggleButton";

const Surveys = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ListCompleted, setCompleted] = useState([]);
  const [ListArchived, setArchived] = useState([]);
  const [ListNew, setNew] = useState([]);
  const [ListDue, setDue] = useState([]);
  const [AssessmentList, setAssessmentList] = useState([]);
  const [viewType, setViewType] = useState("tile");

  const [drawerState, setDrawerState] = useState({
    right: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [side]: open });
  };

  const getLatestData = () => {
    let data = {
      Assessment: [
        {
          ClientId: props.ID,
          // ClientId: "40000001",
          SearchStr: null,
          showOnlyOverdue: null,
          IsForm: null,
          FormType: null,
          FilterBy: null,
        },
      ],
    };
    dispatch(assessmentsListGet(data));
  };

  useEffect(() => {
    getLatestData();
  }, []);

  const { AssessmentListData, isSurveyAssign, isSurveyDiscarded } = useSelector(
    ({
      assessments_list_get: { data: assessmentsData },
      assessments_multiple_assign: { data: assignData },
      assessments_mark_as_discard: { data: discardData },
    }) => {
      return {
        AssessmentListData: assessmentsData?.Data?.Assessments,
        isSurveyAssign: assignData?.ResponseCode === 1,
        isSurveyDiscarded: discardData?.ResponseCode === 1,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    let ListCompleted = [],
      ListArchived = [],
      ListNew = [],
      ListDue = [];
    if (AssessmentListData && AssessmentListData.length > 0) {
      AssessmentListData.map((item) => {
        if (item.IsSurveyCompleted) {
          ListCompleted.push(item);
        } else if (item.IsSurveyArchived) {
          ListArchived.push(item);
        } else if (item.IsSurveyOverDue) {
          ListDue.push(item);
        } else {
          ListNew.push(item);
        }
      });
      setCompleted(ListCompleted);
      setArchived(ListArchived);
      setNew(ListNew);
      setDue(ListDue);
      setAssessmentList(AssessmentListData);
    }
  }, [AssessmentListData]);

  useEffect(() => {
    setDrawerState({ right: false });
    if (isSurveyAssign) {
      getLatestData();
    }
  }, [isSurveyAssign]);
  useEffect(() => {
    if (isSurveyDiscarded) {
      getLatestData();
    }
  }, [isSurveyDiscarded]);

  const markAsDiscard = (SurveyId) => {
    var data = {
      Survey: [
        {
          SurveyId,
        },
      ],
    };
    dispatch(assessmentsMarkAsDiscard(data));
  };
  const handelView = (type) => {
    setViewType(type);
  };
  const enableSorting = true;
  const columns = [
    {
      dataField: "SurveyName",
      text: "Assessmentname",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "SurveyName",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "AssignedDate",
      text: "Assigned Date",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Date",
      sortField: "AssignedDate",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "DueDate",
      text: "Due date",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Date",
      sortField: "DueDate",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "CompletedDate",
      text: "Completed Date",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Date",
      sortField: "CompletedDate",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "DueStatus",
      text: "Status",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "DueStatus",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: "RedGreenBGFormatter",
      csvExport: false,
    },
    {
      dataField: "",
      text: "Actions",
      formatter: "ActionsColumnFormatter",
      // sort: false,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
      // formatter: actionFormatter,
      // formatter: (cell, row) => {
      //   debugger;
      //   return <div className="d-flex align-items-center"></div>;
      // },
      // formatExtraData: {
      //   //   openEditProductPage: productsUIProps.openEditProductPage,
      //   //   openDeleteProductDialog: productsUIProps.openDeleteProductDialog,
      // },
      // classes: "text-right pr-0",
      // headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  return (
    <div className="card card-custom w-100">
      <div className="card-body p-5">
        <div className="clientChart-surveys-tab">
          <div className="d-flex justify-content-end">
            <ToggleButtons handelView={handelView} viewType={viewType} />
            <Button
              onClick={toggleDrawer("right", true)}
              className="secondary mx-2"
              variant="contained"
              color="secondary"
              style={{ cursor: "pointer" }}
            >
              + Assign Survey
            </Button>
          </div>
          <div className="col-12 mt-5">
            {viewType === "tile" ? (
              <div className="mt-2 mb-2 assessments-section">
                <div className="row mb-2">
                  {AssessmentList.map((assessment, index) => {
                    if (assessment.IsSurveyCompleted) {
                      return (
                        <div
                          className="col-sm-6 col-md-4 col-xl-3"
                          key={uuid()}
                        >
                          <CompletedAssessmentsCard
                            data={assessment}
                            index={index}
                          />
                        </div>
                      );
                    } else if (assessment.IsSurveyArchived) {
                      return (
                        <div
                          className="col-sm-6 col-md-4 col-xl-3"
                          key={uuid()}
                        >
                          <ArchivedAssessmentsCard
                            data={assessment}
                            index={index}
                          />
                        </div>
                      );
                    } else if (assessment.IsSurveyOverDue) {
                      return (
                        <div
                          className="col-sm-6 col-md-4 col-xl-3"
                          key={uuid()}
                        >
                          <OverdueAssessmentsCard
                            data={assessment}
                            index={index}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="col-sm-6 col-md-4 col-xl-3"
                          key={uuid()}
                        >
                          <AssigedAssessmentsCard
                            data={assessment}
                            index={index}
                            markAsDiscard={markAsDiscard}
                          />
                        </div>
                      );
                    }
                  })}
                </div>

                {/* <div className="row mb-2">
                  {ListNew.length > 0 &&
                    ListNew.map((newItem, index) => {
                      return (
                        <div
                          className="col-sm-6 col-md-4 col-xl-3"
                          key={uuid()}
                        >
                          <AssigedAssessmentsCard
                            data={newItem}
                            index={index}
                            markAsDiscard={markAsDiscard}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="row mb-2">
                  {ListDue.length > 0 &&
                    ListDue.map((overdueItem, index) => {
                      return (
                        <div
                          className="col-sm-6 col-md-4 col-xl-3"
                          key={uuid()}
                        >
                          <OverdueAssessmentsCard
                            data={overdueItem}
                            index={index}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="row mb-2">
                  {ListCompleted.length > 0 &&
                    ListCompleted.map((completedItem, index) => {
                      return (
                        <div
                          className="col-sm-6 col-md-4 col-xl-3"
                          key={uuid()}
                        >
                          <CompletedAssessmentsCard
                            data={completedItem}
                            index={index}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="row mb-2">
                  {ListArchived.length > 0 &&
                    ListArchived.map((archivedItem, index) => {
                      return (
                        <div
                          className="col-sm-6 col-md-4 col-xl-3"
                          key={uuid()}
                        >
                          <ArchivedAssessmentsCard
                            data={archivedItem}
                            index={index}
                          />
                        </div>
                      );
                    })}
                </div> */}
              </div>
            ) : (
              <div className="pt-2">
                {AssessmentList && (
                  <CustomTable
                    // tableTitle="Member Trends"
                    entities={AssessmentList || []}
                    columns={columns || []}
                    isCardHeader={false}
                    pagination={true}
                    bordered={false}
                    // tableOptions={{
                    //   PageSize,
                    //   IsRowClick,
                    //   IsExcelExport,
                    //   IsColumnSelectionEnable,
                    // }}
                    toggleDrawer={false}
                    rowStyle={{
                      backgroundColor: "#F7F8FA",
                      margin: "12px 0px",
                      boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                      borderRadius: "2px",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {drawerState.right && (
          <Drawer
            anchor="right"
            open={drawerState.right}
            onClose={toggleDrawer("right", false)}
          >
            <div role="presentation" style={{ width: "800px" }}>
              <ReduxAssignSurveyForm
                side="right"
                ID={props.ID}
                toggleDrawer={toggleDrawer}
              />
            </div>
          </Drawer>
        )}
      </div>
    </div>
  );
};
export default Surveys;
