import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CenteredTabs from "../../custom-component/custom-tabs";
import {
  Trends,
  Performance,
  Journal,
  Album,
  VideoCallLogs,
  Breathlyzer,
  Activities,
  Posts,
  Friends,
  Chats,
  TimeTravel,
  Surveys,
  Task,
  Course,
  GrowthPlan,
} from "../../../pages/clientChartTabs";

import {
  getDynamicScreenTab,
  resetGetDynamicScreenTab,
} from "../../../../redux/actions";

import { getClientId } from "../../../util/getParam";

const TabsSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let data = {
      Client: [
        {
          ScreenName: "ClientChart",
        },
      ],
    };

    dispatch(getDynamicScreenTab(data));
    return () => {
      dispatch(resetGetDynamicScreenTab());
    };
  }, []);

  const Labels = {
    Trends: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <Trends ID={getClientId()} /> */}
        </>
      );
    },
    Performance: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <Performance ID={getClientId()} /> */}
        </>
      );
    },
    // Activities: () => <Activities ID={getClientId()} />,
    Breathlyzer: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <Breathlyzer ID={getClientId()} />
        </>
      );
    },
    Journal: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <Journal ID={getClientId()} />
        </>
      );
    },
    VideoCallLogs: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <VideoCallLogs ID={getClientId()} /> */}
        </>
      );
    },
    Album: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <Album ID={getClientId()} />
        </>
      );
    },
    Posts: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <Posts ID={getClientId()} />
        </>
      );
    },
    Friends: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <Friends ID={getClientId()} />
        </>
      );
    },
    Chats: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <Chats ID={getClientId()} /> */}
        </>
      );
    },
    TimeTravel: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <TimeTravel ID={getClientId()} />
        </>
      );
    },
    Surveys: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <Surveys ID={getClientId()} />
        </>
      );
    },
    Task: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <Task ID={getClientId()} />
        </>
      );
    },
    Course: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <Course ID={getClientId()} />
        </>
      );
    },
    GrowthPlan: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/*<GrowthPlan ID={getClientId()} /> */}
        </>
      );
    },
    // undefined: () => "Not Defined",
  };

  const { TabInfo, Summary } = useSelector(
    ({ get_dynamic_screen_tab: { data, isFeching } }) => {
      return {
        TabInfo: data && data.Tabs,
      };
    },
    shallowEqual
  );

  return (
    <div className="client-chart-page custom-tabs">
      {TabInfo && TabInfo.length > 0 && (
        <CenteredTabs
          tabsData={TabInfo}
          labels={Labels}
          baseTabUrl={`/client-roster/chart?id=${getClientId()}&tab=`}
        />
      )}
    </div>
  );
};

export default TabsSection;
