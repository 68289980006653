import React from "react";
import moment from "moment";
export const DateWithTimeFormatter = (cell, row, rowIndex, formatExtraData) => {
  return (
    <div
      className="d-flex align-items-center"
      style={{ minWidth: "180px", maxWidth: "200px" }}
    >
      <div className="col-12 d-flex align-items-center">
        <div className="d-flex flex-column">
          <div>
            <span>{moment(cell).format("DD-MMM-YYYY / hh:mm A")}</span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
