import React from "react";
import { Course } from "../../components/custom-screens/course/index";
const CourseComponent = (props) => {
  return (
    <>
      {" "}
      <div>
        <Course />
      </div>
    </>
  );
};
export default CourseComponent;
