import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import _CommentCount from "../../images/commentCount.svg";
import { DateWithTimeFormatter } from "../column-formatters";
import { MoreItemDropdown } from "../../components/custom-component";

// import _RedCross from "../../images/icons/redCross.svg";
// import _BlueCheck from "../../images/icons/blueCheck.svg";
// import _Edit from "../../images/icons/edit.svg";
// import _RedCrossIcon from "../../images/redCrossIcon.svg";
// import _BlueCheckIcon from "../../images/blueCheckIcon.svg";
// import _EditIcon from "../../images/editIcon.svg";
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
export const OverdueAssessmentsCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { data, index, toggleDrawer } = props;
  const { SurveyName, DueDate, AssignedDate } = data || {};
  return (
    <div className="assessments-tabs-card" key={index}>
      <div className="assessments-card">
        <div className="new">
          <div className="overdue mb-2">
            <div className="d-flex align-items-center justify-content-center">
              <span className="overdue-text">Overdue</span>
              <span style={{ position: "absolute", right: "0px" }}>
                {" "}
                <MoreItemDropdown
                  item="MoreAction"
                  More={[{ OnClick: "", ActionName: "Coming Soon.." }]}
                />
              </span>
            </div>
          </div>
          <span className="name">{SurveyName}</span>
          <div className="block">
            <div className="d-flex justify-content-between">
              <div className="assigned-date text-left">
                <span className="date-text">Assigned</span>
                <span className="date-value">{AssignedDate}</span>
              </div>
              <div className="due-date text-left">
                <span className="date-text">Due</span>
                <span className="due-date-value">{DueDate}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
