import React from "react";
import { v4 as uuid } from "uuid";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";

import validate, {
  dateofbirthNormalize,
  // ssnFormatter,
  ssn9Formatter,
  ssn11Formatter,
  // ssnNormalize,
  ssn9Normalize,
  ssn11Normalize,
  MobileFormatter,
  MobileNormalize,
} from "./validation";

// import {
//   ReduxInput,
//   ReduxSsnInput,
//   ReduxDollarInput,
//   ReduxSelect,
//   ReduxToggle,
//   ReduxDateInput,
//   ReduxAutoComplete,
// } from "../../component/custom-redux-components";
import {
  ReduxInput,
  ReduxSsnInput,
  ReduxDollarInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  CheckboxGroup,
  ReduxCheckbox,
  ReduxRadioButtonGroup,
  ReduxTimeInput,
  ReduxMultiSelect,
  CustomDatepicker,
  ReduxPhoneInput,
} from "../../app/components/redux-component";

export const COMPONENT_TYPES = {
         // Toogle: 'Toogle',
         // DatePicker: 'DatePicker',
         // PhoneInput: 'PhoneInput',
         ReduxSelect: "ReduxSelect",
         // ReduxTextSelect: 'ReduxTextSelect',
         ReduxInput: "ReduxInput",
         ReduxSsnInput: "ReduxSsnInput",
         ReduxPhoneInput: 'ReduxPhoneInput',
         ReduxDateInput: "ReduxDateInput",
         ReduxToggle: "ReduxToggle",
         ReduxAutoComplete: "ReduxAutoComplete",
         // FrequencyReduxSelect: 'FrequencyReduxSelect',
         CheckboxGroup: "CheckboxGroup",
         ReduxCheckbox: "ReduxCheckbox",
         // ReduxTimePicker: 'ReduxTimePicker',
         ReduxCurrencyInput: "ReduxCurrencyInput",
         ReduxDollarInput: "ReduxDollarInput",
         ReduxRadioButtonGroup: "ReduxRadioButtonGroup",
         ReduxTimeInput: "ReduxTimeInput",
         ReduxMultiSelect: "ReduxMultiSelect",
         CustomDatepicker: "CustomDatepicker",
       };

export const FORMATTER_TYPES = {
  dateofbirthNormalize: dateofbirthNormalize,
  ssn9Formatter: ssn9Formatter,
  ssn11Formatter: ssn11Formatter,
  ssn9Normalize: ssn9Normalize,
  ssn11Normalize: ssn11Normalize,
  MobileFormatter: MobileFormatter,
  MobileNormalize: MobileNormalize,
};

export const DynamicSection = ({
  sectionKey,
  sections,
  title,
  deleteTitle,
  addTitle,
  formFields,
  onAdd,
  disable,
  rows,
}) => {
  return (
    <>
      {sections.map((item, index) => {
        return (
          <>
            <div class="row mb-3" key={uuid()}>
              <div class="col-12 col-md-6">
                <h6>
                  {title} {index + 1}
                </h6>
              </div>
              <div class="col-12 col-md-6">
                <a
                  class="delete-contact"
                  onClick={() => sections.remove(index)}
                  disabled={disable}
                >
                  <i class="fas fa-trash" /> {deleteTitle}
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <DynamicForm
                formFields={formFields}
                mapper={sectionKey + "[" + index + "]"}
                row={rows[index]}
                disable={disable}
              />
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a class="add-contact" onClick={onAdd} disabled={disable}>
            {sections.length >= 1 && <label> + Add Another {addTitle}</label>}
            {sections.length == 0 && <label> + Add {addTitle}</label>}
          </a>
        </div>
      </div>
    </>
  );
};

// set fieldName: true when we add validations
// fill placeholder for lookups and placeholder != fieldName
// set isRequired: true to see required field
// when isRequired: true, no need to set validate.required
// use condition as a function rather than value
// displayCalenderIcon true to show calender icon for text box. ex: dob
// componentType == COMPONENT_TYPES.ReduxCurrencyInput to get $ icon on left
// displayTitle true to show only title or header. title attribute to fill text

const DynamicForm = ({ formFields, mapper, row, disable }) => {
  return formFields
    ? formFields.length > 0 &&
        formFields.map(
          ({
            allowDebug,
            componentClass,
            componentType,
            isRequired,
            title,
            Title,
            displayCalenderIcon,
            disabled,
            component,
            options,
            Class: className,
            condition,
            for: forMap,
            isDisplayTitle,
            renderComponent,
            smallInfo,
            filterBy,
            defaultFilterBy,
            checkBoxOptions,
            radioOptions,
            ...formField
          }) => {
            if (allowDebug) {
            }
            componentType = componentType || COMPONENT_TYPES.ReduxInput;
            // var label = title || formField.placeholder || formField.fieldName;
            var labelText =
              title ||
              formField.labelText ||
              formField.placeholder ||
              formField.fieldName;
            var isText = true;
            // formField.validate = formField.validate || [];
            formField.validate =
              (formField.validate &&
                JSON.parse(formField.validate).map((item) => {
                  {
                    return validate[item];
                  }
                })) ||
              [];
            className = className || "col-12 col-md-6 col-lg-4";

            if (isRequired) {
              // label = (formField.placeholder || formField.fieldName) + "*";
              formField.labelText =
                (formField.labelText ||
                  formField.placeholder ||
                  formField.fieldName) + "*";

              // if (formField.placeholder) {
              //   formField.placeholder = formField.placeholder + "*";
              // }
              if (formField.validate.length == 0) {
                formField.validate.push(validate.required);
              }
            }

            component = component || ReduxInput;
            if (componentType == COMPONENT_TYPES.ReduxDateInput) {
              component = ReduxDateInput;
              isText = false;
            }

            if (options) {
              isText = false;
              component = ReduxSelect;
              // formField.placeholder = formField.placeholder || label;
              formField.placeholder = formField.placeholder || labelText;
              if (filterBy) {
                var filter = defaultFilterBy;
                if (row && row[filterBy]) {
                  filter = row[filterBy];
                }
                options = options[filter];
              }
            }
            if (condition && typeof condition != "string" && !condition()) {
              return <></>;
            }

            if (mapper) {
              formField.name = mapper + "." + formField.name;
            }

            if (componentType == COMPONENT_TYPES.ReduxToggle) {
              return (
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="custom-control-label">
                      {formField.fieldName} {"   "}
                      <Field
                        name={formField.name}
                        type="text"
                        class="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable || disabled}
                      />
                    </label>
                  </div>
                </div>
              );
            } else if (componentType == COMPONENT_TYPES.ReduxCheckbox) {
              return (
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="custom-control-label">
                      <Field
                        name={formField.name}
                        type="text"
                        class="custom-control-input not-empty"
                        component={ReduxCheckbox}
                        disabled={disable || disabled}
                      />
                      {formField.fieldName}
                    </label>
                  </div>
                </div>
              );
            } else if (componentType == COMPONENT_TYPES.ReduxDollarInput) {
              return (
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      type="text"
                      disabled={disable || disabled}
                      {...formField}
                      component={ReduxDollarInput}
                      // style={{ width: 'auto' }}
                      options={options}
                      // dollarPlaceHolder={label}
                      dollarPlaceHolder={labelText}
                    />
                  </div>
                </div>
              );
            } else if (componentType == COMPONENT_TYPES.ReduxSsnInput) {
              // var ssnLength = formField.FiledLength;
              let v;
              if (
                formField.validate &&
                JSON.parse(formField.validate).length > 0
              ) {
                v = JSON.parse(formField.validate);
                v = v.map((item) => {
                  {
                    return validate[item];
                  }
                });
                // v = v.map(item => {
                //   if (item == 'ssn') {
                //     if (ssnLength == 9) {
                //       return value => {
                //         if (value) {
                //           const onlyNums = value.replace(/[^\d\*]/g, '');
                //           if (onlyNums.length !== 9) {
                //             return 'Please enter 9 digits';
                //           }
                //         } else {
                //           return 'Social Security Number Required';
                //         }
                //       };
                //     } else {
                //       return value => {
                //         if (value) {
                //           const onlyNums = value.replace(/[^\d\*]/g, '');
                //           if (onlyNums.length !== ssnLength) {
                //             return `Please enter ${ssnLength} digits`;
                //           }
                //         } else {
                //           return 'Social Security Number Required';
                //         }
                //       };
                //     }
                //   } else {
                //     return validate[item];
                //   }
                // });
              }

              return (
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    {/* <Field
                type="text"
                disabled={disable || disabled}
                {...formField}
                component={ReduxSsnInput}
                // style={{ width: 'auto' }}
                options={options}
                dollarPlaceHolder={label}
                format={FORMATTER_TYPES[formField.format]}
                normalize={FORMATTER_TYPES[formField.normalize]}
                // validate={v}
              /> */}
                    <label class="placeholder-label">National ID (SSN)*</label>
                  </div>
                </div>
              );
            } else if (isDisplayTitle) {
              return (
                <div class="col-12 mt-2 form-group">
                  <h6>{Title}</h6>
                </div>
              );
            } else if (renderComponent) {
              return (
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">{renderComponent}</div>
                </div>
              );
            }

            if (componentType == COMPONENT_TYPES.ReduxAutoComplete) {
              return (
                <div className="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      name={formField.name}
                      type="text"
                      component={ReduxAutoComplete}
                      options={options}
                      // label={label}
                      label={labelText}
                    />
                  </div>
                </div>
              );
            }
            if (componentType == COMPONENT_TYPES.CheckboxGroup) {
              return (
                <div
                  className={
                    className ? `${className}` : "col-12 col-md-8 col-lg-8"
                  }
                >
                  <div class="form-group">
                    <Field
                      name={formField.name}
                      type="text"
                      component={CheckboxGroup}
                      options={checkBoxOptions}
                      // label={label}
                      label={labelText}
                    />
                  </div>
                </div>
              );
            }
            if (componentType == COMPONENT_TYPES.ReduxTextarea) {
              return (
                <div class={className}>
                  <div className="form-group">
                    <Field
                      name={formField.name}
                      className="form-control textarea-box"
                      rows="4"
                      type="textarea"
                      component={ReduxTextarea}
                      style={{
                        minHeight: "100px",
                      }}
                    />
                    <label class="placeholder-label">Notes</label>
                  </div>
                </div>
              );
            }
            if (componentType == COMPONENT_TYPES.ReduxRadioButtonGroup) {
              return (
                <div class={className}>
                  <div className="form-group">
                    <Field
                      name={formField.name}
                      className="form-control textarea-box"
                      rows="4"
                      type="textarea"
                      component={ReduxRadioButtonGroup}
                      // style={{
                      //   minHeight: "100px",
                      // }}
                      radioOptions={radioOptions}
                      label={labelText}
                    />
                  </div>
                </div>
              );
            }     if (componentType == COMPONENT_TYPES.ReduxPhoneInput) {
                    return (
                      <div className={`${className} form-group`}>
                        <div className="form-group">
                          <Field
                            name={formField.name}
                            className="form-control"
                            type="text"
                            disabled={disable || disabled}
                            component={ReduxPhoneInput}
                            // style={{ width: 'auto' }}
                            label={labelText}
                            {...formField}
                          />
                        </div>
                      </div>
                    );
                  }
                  if (componentType == COMPONENT_TYPES.ReduxTimeInput) {
                    return (
                      <div className={`${className} form-group `}>
                        <div className="form-group">
                          <Field
                            name={formField.name}
                            className="form-control"
                            type="text"
                            disabled={disable || disabled}
                            component={ReduxTimeInput}
                            // style={{ width: 'auto' }}
                            label={labelText}
                            {...formField}
                          />
                        </div>
                      </div>
                    );
                  }
            componentClass = componentClass || "form-group";
            return (
              <div className={`${className} form-group `}>
                <div class={componentClass}>
                  <Field
                    type="text"
                    {...formField}
                    component={component}
                    options={options}
                    disabled={disable || disabled}
                  />
                  {/* {isText && <label class="placeholder-label">{label}</label>} */}
                  {smallInfo && <span class="small-info">{smallInfo}</span>}
                  {displayCalenderIcon && (
                    <i class="far fa-calendar-alt form-control-icon" />
                  )}
                </div>
              </div>
            );
          }
        )
    : "No Form Fields Found";
};

export default DynamicForm;
