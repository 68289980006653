import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Button from "@material-ui/core/Button";
import {
  surveyQuestionCategoryOptionGet,
  assessmentsMultipleAssign,
} from "../../../../redux/actions";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import _Close from "../../../images/close.svg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import {
  ReduxInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxDateInput,
  ReduxTimeInput,
  ReduxDollarInput,
  ReduxMultiSelect,
  ReduxToggle,
  ReduxCheckbox,
  ReduxAutoComplete,
  CheckboxGroup,
} from "../../../components/redux-component/index";

import { getDate, stringDate } from "../../../util/date";
import formDataHandler from "../../../util/formDataHandler";
import Validator from "../../../Validator";
const AssignSurveyForm = (props) => {
  const dispatch = useDispatch();
  //   const history = useHistory();
  useEffect(() => {
    dispatch(
      surveyQuestionCategoryOptionGet({
        Assessment: [
          {
            SurveyQuestionCategoryTypeId: "4",
          },
        ],
      })
    );
  }, []);

  const { surveyOptions } = useSelector(
    ({ survey_question_category_option_get: { data } }) => {
      return {
        surveyOptions: data?.Data?.SurveyQuestionCategories,
      };
    },
    shallowEqual
  );

  const onAssignSurvey = (values) => {
    const { SurveyQuestionCategoryIds, DueDate } = values;

    let ObjectData = formDataHandler(values);
    var data = {
      Survey: [
        {
          ClientIds: props.ID,
          ...ObjectData,
          // SurveyQuestionCategoryIds: "9,86,88",
          // DueDate: "06/16/2022",
          IsSystemAssigned: false,
        },
      ],
    };

    dispatch(assessmentsMultipleAssign(data));
  };

  const { handleSubmit, pristine, reset, submitting, onSubmit } = props;
  return (
    <div className={`card card-custom`}>
      {/* Head */}
      <div className="card-header border-1">
        <h5>Assign Survey</h5>
        <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
          <a
            className="btn btn-icon btn-hover-light-primary btn-sm"
            onClick={props.toggleDrawer("right", false)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl(_Close)} />
            </span>
          </a>
        </OverlayTrigger>
      </div>
      {/* Body */}
      <div className="card-body pt-2 drawer-course assessments-section">
        <form autoComplete="off" onSubmit={handleSubmit(onAssignSurvey)}>
          <div className="pb-2">
            <div className="row pb-5 pl-3 pr-3 pt-3 mt-5">
              <div className="col-md-12 col-lg-12 mt-2 form-group">
                <Field
                  name="SurveyQuestionCategoryIds"
                  component={ReduxSelect}
                  options={surveyOptions}
                  fieldName="surveys"
                  labelText={"Surveys*"}
                  // validate={Validator.required}
                  isReturnText={false}
                  isMulti={true}
                />
              </div>

              <div className="col-md-6 col-lg-12 mt-2">
                <Field
                  name="DueDate"
                  component={ReduxDateInput}
                  fieldName="Survey Due Date"
                  labelText={"Survey Due Date*"}
                  validate={Validator.required}
                />
              </div>
            </div>
            <div className="text-right">
              <Button
                variant="outlined"
                color={"secondary"}
                onClick={props.toggleDrawer("right", false)}
                className="mx-2"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                // className={classes.button}
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              &nbsp;
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export const ReduxAssignSurveyForm = reduxForm({
  form: "assignSurveyForm",
  enableReinitialize: true,
})(AssignSurveyForm);
