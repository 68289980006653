import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  userSuspiciousWordGet,
  userMemberTrendHistory,
} from "../../../redux/actions";

import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";

import _blank from "../../images/blank-profile.png";
import _Phone from "../../images/twilioPhoneAllowed.svg";
import _Chat from "../../images/bi_chat.svg";
import _GreenUpArrow from "../../images/greenUpArrow.svg";
import _RedDownArrow from "../../images/redDownArrow.svg";

import { RedGreenBGFormatter } from "../../components/column-formatters/RedGreenBGFormatter";
import { EmotionalAffect } from "../../components/custom-screens/memberTrendsPage/emotionalAffect";
import { ActiveMaps } from "../../components/custom-screens/memberTrendsPage//activeMaps";
import { Assessment } from "../../components/custom-screens/memberTrendsPage//assessment";
import { Breathlyzer } from "../../components/custom-screens/memberTrendsPage//breathlyzer";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Trends = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const enableSorting = true;

  const columns = [
    {
      dataField: "Activity",
      text: "Activity",
      sort: enableSorting,
      sortCaret: sortCaret,
      headerSortingClasses: headerSortingClasses,
      style: {
        whiteSpace: "nowrap",
        width: "150px",
      },
      formatter: (cell, row) => {
        return (
          <span className="w-100 d-flex align-items-center justify-content-left">
            {row.IsRedColor ? (
              <img
                style={{
                  cursor: "pointer",
                  display: "block",
                  marginRight: "5px",
                }}
                src={_RedDownArrow}
                alt="Chat"
                // width="15px"
              />
            ) : (
              <img
                style={{
                  cursor: "pointer",
                  display: "block",
                  marginRight: "5px",
                }}
                src={_GreenUpArrow}
                alt="Chat"
                // width="15px"
              />
            )}{" "}
            {row.Activity}
          </span>
        );
      },
    },
    {
      dataField: "LastTrendUpdateDate",
      text: "Date",
      sort: enableSorting,
      sortCaret: sortCaret,
      headerSortingClasses: headerSortingClasses,
      style: {
        whiteSpace: "nowrap",
      },

      // width: "550px",
      classes: "date-width",
    },

    {
      dataField: "Cause",
      text: "cause",
      sort: enableSorting,
      sortCaret: sortCaret,
      headerSortingClasses: headerSortingClasses,
      formatter: RedGreenBGFormatter,
      classes: "cause-width",
      style: {
        whiteSpace: "nowrap",
      },
    },
    {
      dataField: "Remarks",
      text: "Remarks",
      sort: enableSorting,
      sortCaret: sortCaret,
      headerSortingClasses: headerSortingClasses,
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const data = [
    {
      label: "Emotional Affect",
      component: <EmotionalAffect ID={props.ID} />,
    },
    {
      label: "Assessments",
      component: <Assessment ID={props.ID} />,
    },
    {
      label: "Breathlyzer",
      component: <Breathlyzer ID={props.ID} />,
    },
    {
      label: "Triggers",
      component: <ActiveMaps ID={props.ID} />,
    },
  ];

  useEffect(() => {
    // dispatch(
    //   userMemberTrendHistory({
    //     Json: JSON.stringify({
    //       Trends: [
    //         {
    //           ClientId: props.ID,
    //         },
    //       ],
    //     }),
    //   })
    // );
    // dispatch(userSuspiciousWordGet({}));
  }, []);

  const { HeaderInfo, Summary } = useSelector(
    ({ user_member_trend_history }) => {
      return {
        HeaderInfo:
          (user_member_trend_history &&
            user_member_trend_history.data &&
            user_member_trend_history.data.Data &&
            user_member_trend_history.data.Data[0] &&
            user_member_trend_history.data.Data[0].HeaderInfo[0]) ||
          {},
        Summary:
          user_member_trend_history &&
          user_member_trend_history.data &&
          user_member_trend_history.data.Data &&
          user_member_trend_history.data.Data[0] &&
          user_member_trend_history.data.Data[0].Summary,
      };
    },
    shallowEqual
  );

  return (
    <div className="card w-100">
      <div className="card-body pt-5">
        <div className="mt-5 mb-5">
          <div
            style={{
              boxShadow: " 0px 0px 10px 4px rgba(115, 116, 119, 0.06)",
              bordeRadius: "4px",
              padding: "15px 15px",
            }}
          >
            <h4>Summary</h4>

            {Summary && (
              <ToolkitProvider
                keyField="id"
                data={Summary || []}
                columns={columns}
                // search
              >
                {(props) => (
                  <div>
                    {/* <SearchBar
                    {...props.searchProps}
                    className="custome-search-field"
                    style={{ color: "black" }}
                    delay={500}
                    placeholder="Search"
                  /> */}
                    {/* <ClearSearchButton {...props.searchProps} /> */}
                    <BootstrapTable
                      wrapperClasses="table-responsive member-trend-table"
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4={true}
                      bordered={false}
                      rowStyle={{
                        backgroundColor: "#F7F8FA",
                        margin: "12px 0px",
                        boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                        borderRadius: "2px",
                      }}
                      // hover
                      // defaultSorted={defaultSorted}
                      {...props.baseProps}
                      // style={{ height: "365px" }}
                    />
                  </div>
                )}
              </ToolkitProvider>
            )}
          </div>
        </div>

        <div className="clientChart-trends-tabs expansion-panel">
          <div className={classes.root}>
            {data &&
              data.map((item, index) => {
                return (
                  <ExpansionPanel
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    elevation={0}
                    key={uuid()}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      elevation={0}
                    >
                      <Typography className={classes.heading}>
                        {item.label}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {item.component}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Trends;
