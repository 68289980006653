import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Button from "@material-ui/core/Button";
import { listCombos, taskAssign } from "../../../../redux/actions";
import _Close from "../../../images/close.svg";
import {
  ReduxInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxDateInput,
  ReduxTimeInput,
  ReduxDollarInput,
  ReduxMultiSelect,
  ReduxToggle,
  ReduxCheckbox,
  ReduxAutoComplete,
  CheckboxGroup,
} from "../../../components/redux-component/index";

import { getDate, stringDate } from "../../../util/date";
import formDataHandler from "../../../util/formDataHandler";
import Validator from "../../../Validator";
const AssignTaskForm = (props) => {
  const dispatch = useDispatch();
  //   const history = useHistory();
  useEffect(() => {
    dispatch(
      listCombos({
        comboTypes: "SurveyOptions,TaskOptions,ExitReason,Courses,NoteType",
        // StudentId: 13,
      })
    );
  }, []);

  const { taskOptions } = useSelector(({ combos }) => {
    return {
      taskOptions: (combos.data && combos.data.TaskOptions) || [],
    };
  }, shallowEqual);

  const onAssignTask = (values) => {
    const { TaskId, RepeatFrequency, AssignDate, DueDate } = values;

    let ObjectData = formDataHandler(values);

    // let TaskIdValues = TaskId.map((item) => {
    //   return item.Value;
    // });
    // let RepeatFrequencyValues = RepeatFrequency.map((item) => {
    //   return item.Value;
    // });

    var data = {
      Task: [
        {
          Title: ObjectData.Title,
          Detail: ObjectData.Details,
          ClientTask: [
            {
              ClientIds: props.ID,
              ClientTaskId: null,
              AssignedDate: ObjectData.AssignedDate,
              RepeatFrequencyCode: ObjectData.RepeatFrequencyCode,
              RepeatDayOfWeek: null,
              DueDate: ObjectData.DueDate,
            },
          ],
        },
      ],
    };
    dispatch(taskAssign(data));
  };

  const { handleSubmit, pristine, reset, submitting, onSubmit } = props;
  return (
    <div className={`card card-custom`}>
      {/* Head */}
      <div className="card-header border-1">
        <h5>Assign New Task</h5>
        <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
          <a
            className="btn btn-icon btn-hover-light-primary btn-sm"
            onClick={props.toggleDrawer("right", false)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl(_Close)} />
            </span>
          </a>
        </OverlayTrigger>
      </div>
      {/* Body */}
      <div className="card-body pt-2 drawer-course assessments-section">
        <form autoComplete="off" onSubmit={handleSubmit(onAssignTask)}>
          <div className="pb-2">
            <div className="row pb-5 pl-3 pr-3 pt-3 mt-5">
              <div className="col-md-12 col-lg-6 mt-2">
                <Field
                  name="Title"
                  component={ReduxInput}
                  fieldName="Title"
                  labelText={"Title*"}
                  // validate={Validator.required}
                  isReturnText={false}
                />
              </div>{" "}
              <div className="col-md-12 col-lg-6 mt-2">
                <Field
                  name="Details"
                  component={ReduxInput}
                  fieldName="Details"
                  labelText={"Details*"}
                  // validate={Validator.required}
                  isReturnText={false}
                />
              </div>
              <div className="col-md-12 col-lg-6 mt-2">
                <Field
                  name="RepeatFrequencyCode"
                  component={ReduxSelect}
                  options={[
                    { Value: "W", Text: "Weekly" },
                    { Value: "O", Text: "One Time" },
                    { Value: "D", Text: "Daily" },
                  ]}
                  fieldName="Repeat Frequency"
                  labelText={"Repeat Frequency*"}
                  // validate={Validator.required}
                  isReturnText={false}
                />
              </div>
              <div className="col-md-12 col-lg-6 mt-2">
                <Field
                  name="AssignedDate"
                  component={ReduxDateInput}
                  fieldName="Task Assign Date"
                  labelText={"Task Assign Date*"}
                  validate={Validator.required}
                />
              </div>
              <div className="col-md-12 col-lg-6 mt-2">
                <Field
                  name="DueDate"
                  component={ReduxDateInput}
                  fieldName="Task Due Date"
                  labelText={"Task Due Date*"}
                  validate={[
                    Validator.required,
                    Validator.dueDate,
                    Validator.dueDate2,
                  ]}
                />
              </div>
            </div>
            <div className="text-right">
              <Button
                variant="outlined"
                color={"secondary"}
                onClick={props.toggleDrawer("right", false)}
                className="mx-2"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                // className={classes.button}
                type="submit"
                disabled={pristine || submitting}
              >
                Assign
              </Button>
              &nbsp;
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export const ReduxTaskForm = reduxForm({
  form: "assignTaskForm",
  enableReinitialize: true,
})(AssignTaskForm);
