import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* appInvite(action) {
  try {
    const { payload } = action;
    const response = yield API.appInvite(payload);
    yield put({
      type: ActionTypes.APP_INVITE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_INVITE_FAILURE,
    });
  }
}

export function* getMobileAppDownloadStatus(action) {
  try {
    const { payload } = action;
    const response = yield API.getMobileAppDownloadStatus(payload);
    yield put({
      type: ActionTypes.GET_MOBILE_APP_DOWNLOAD_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_MOBILE_APP_DOWNLOAD_STATUS_FAILURE,
    });
  }
}
export function* sendVoucherCode(action) {
  try {
    const { payload } = action;
    const response = yield API.sendVoucherCode(payload);
    yield put({
      type: ActionTypes.SEND_VOUCHER_CODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SEND_VOUCHER_CODE_FAILURE,
    });
  }
}
export function* getInvitedList(action) {
  try {
    const { payload } = action;
    const response = yield API.getInvitedList(payload);
    yield put({
      type: ActionTypes.GET_INVITED_LIST_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_INVITED_LIST_FAILURE,
    });
  }
}
export function* inviteResend(action) {
  try {
    const { payload } = action;
    const response = yield API.inviteResend(payload);
    yield put({
      type: ActionTypes.INVITE_RESEND_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.INVITE_RESEND_FAILURE,
    });
  }
}
export function* getBroadcastFilter(action) {
  try {
    const { payload } = action;
    const response = yield API.getBroadcastFilter(payload);
    yield put({
      type: ActionTypes.GET_BROADCAST_FILTER_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BROADCAST_FILTER_FAILURE,
    });
  }
}
export function* sendBroadcast(action) {
  try {
    const { payload } = action;
    const response = yield API.sendBroadcast(payload);
    yield put({
      type: ActionTypes.SEND_BROADCAST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SEND_BROADCAST_FAILURE,
    });
  }
}

export function* comboBroadcastTemplate(action) {
  try {
    const { payload } = action;
    const response = yield API.comboBroadcastTemplate(payload);
    yield put({
      type: ActionTypes.COMBO_BROADCAST_TEMPLATE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_BROADCAST_TEMPLATE_FAILURE,
    });
  }
}
export function* upsertBroadcastTemplate(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertBroadcastTemplate(payload);
    yield put({
      type: ActionTypes.UPSERT_BROADCAST_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_BROADCAST_TEMPLATE_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.APP_INVITE_REQUEST, appInvite),
    takeLatest(ActionTypes.SEND_VOUCHER_CODE_REQUEST, sendVoucherCode),
    takeLatest(ActionTypes.GET_MOBILE_APP_DOWNLOAD_STATUS_REQUEST, getMobileAppDownloadStatus),
    takeLatest(ActionTypes.GET_INVITED_LIST_REQUEST, getInvitedList),
    takeLatest(ActionTypes.INVITE_RESEND_REQUEST, inviteResend),
    takeLatest(ActionTypes.GET_BROADCAST_FILTER_REQUEST, getBroadcastFilter),
    takeLatest(ActionTypes.SEND_BROADCAST_REQUEST, sendBroadcast),
    takeLatest(ActionTypes.COMBO_BROADCAST_TEMPLATE_REQUEST, comboBroadcastTemplate),
    takeLatest(ActionTypes.UPSERT_BROADCAST_TEMPLATE_REQUEST, upsertBroadcastTemplate),
  ]);
}
