// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import * as actions from "../../../_redux/products/productsActions";
import * as uiHelpers from "../ProductsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useProductsUIContext } from "../ProductsUIContext";

export function ProductsTable() {
  // Products UI Context
  // const productsUIContext = useProductsUIContext();
  // const productsUIProps = useMemo(() => {
  //   return {
  //     ids: productsUIContext.ids,
  //     setIds: productsUIContext.setIds,
  //     queryParams: productsUIContext.queryParams,
  //     setQueryParams: productsUIContext.setQueryParams,
  //     openEditProductPage: productsUIContext.openEditProductPage,
  //     openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
  //   };
  // }, [productsUIContext]);

  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.products }),
    shallowEqual
  );
  // const { listLoading } = currentState;
  const listLoading = false;
  const totalCount = 37;
  const entities = [
    {
      id: 26,
      model: "Rio",
      manufacture: "Kia",
      modelYear: 2007,
      mileage: 189651,
      description:
        "The Kia Opirus was an executive car manufactured and marketed by Kia Motors that was launched in April 2003 and was marketed globally under various nameplates, prominently as the Amanti. It was considered to be Kia's flagship vehicle.",
      color: "Indigo",
      price: 44292,
      condition: 1,
      createdDate: "09/01/2017",
      status: 1,
      VINCode: "1C4SDHCT2CC055294",
      _userId: 2,
      _createdDate: "04/09/2018",
      _updatedDate: "04/17/2014",
    },
    {
      id: 5,
      model: "Paseo",
      manufacture: "Toyota",
      modelYear: 1997,
      mileage: 74884,
      description:
        "The Toyota Paseo (known as the Cynos in Japan and other regions) is a sports styled compact car sold from 1991–1999 and was loosely based on the Tercel. It was available as a coupe and in later models as a convertible. Toyota stopped selling the car in the United States in 1997, however the car continued to be sold in Canada, Europe and Japan until 1999, but had no direct replacement. The Paseo, like the Tercel, shares a platform with the Starlet. Several parts are interchangeable between the three",
      color: "Pink",
      price: 24796,
      condition: 1,
      createdDate: "08/13/2017",
      status: 0,
      VINCode: "1D7RB1GP0AS597432",
      _userId: 1,
      _createdDate: "11/21/2016",
      _updatedDate: "10/09/2012",
    },

    {
      id: 10,
      model: "1500 Silverado",
      manufacture: "Chevrolet",
      modelYear: 1999,
      mileage: 195310,
      description:
        "The Chevrolet Silverado, and its mechanically identical cousin, the GMC Sierra, are a series of full-size and heavy-duty pickup trucks manufactured by General Motors and introduced in 1998 as the successor to the long-running Chevrolet C/K line. The Silverado name was taken from a trim level previously used on its predecessor, the Chevrolet C/K pickup truck from 1975 through 1998. General Motors continues to offer a GMC-badged variant of the Chevrolet full-size pickup under the GMC Sierra name, first used in 1987 for its variant of the GMT400 platform trucks.",
      color: "Blue",
      price: 25764,
      condition: 0,
      createdDate: "08/30/2017",
      status: 1,
      VINCode: "1N6AF0LX6EN590806",
      _userId: 2,
      _createdDate: "10/06/2013",
      _updatedDate: "03/27/2017",
    },
  ];
  // Products Redux state
  const dispatch = useDispatch();
  // useEffect(() => {
  //   // clear selections list
  //   productsUIProps.setIds([]);
  //   // server call by queryParams
  //   dispatch(actions.fetchProducts(productsUIProps.queryParams));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [productsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "VINCode",
      text: "VIN Code (ID)",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "manufacture",
      text: "Manufacture",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "model",
      text: "Model",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "modelYear",
      text: "Model Year",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "color",
      text: "Color",
      sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.ColorColumnFormatter,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.PriceColumnFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.StatusColumnFormatter,
    },
    {
      dataField: "condition",
      text: "Condition",
      sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.ConditionColumnFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      // formatter: columnFormatters.ActionsColumnFormatter,
      // formatExtraData: {
      //   openEditProductPage: productsUIProps.openEditProductPage,
      //   openDeleteProductDialog: productsUIProps.openDeleteProductDialog,
      // },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  // const paginationOptions = {
  //   custom: true,
  //   totalSize: totalCount,
  //   sizePerPageList: uiHelpers.sizePerPageList,
  //   sizePerPage: productsUIProps.queryParams.pageSize,
  //   page: productsUIProps.queryParams.pageNumber,
  // };
  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        remote
        keyField="id"
        data={entities}
        columns={columns}
        // defaultSorted={uiHelpers.defaultSorted}
        // onTableChange={getHandlerTableChange(
        //   productsUIProps.setQueryParams
        // )}
        // selectRow={getSelectRow({
        //   entities,
        //   ids: productsUIProps.ids,
        //   setIds: productsUIProps.setIds,
        // })}
        // {...paginationTableProps}
      >
        {/* <PleaseWaitMessage entities={entities} />
        <NoRecordsFoundMessage entities={entities} /> */}
      </BootstrapTable>
    </>
  );
}
