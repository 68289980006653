import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import CustomTable from "../../custom-component/custom-table";
import { MapView } from "../triggers/mapView";
import { toast } from "react-toastify";
import { triggersListGet } from "../../../../redux/actions";

//# ones are present in existing apis.
// DidThisUserTag: false
// DisplayName: "Dj"
// FullAddress: "Trigger placed by other(s) : 1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA"
// # IsPositive: true
// # Latitude: 37.4219983
// # Longitude: -122.084
// OtherUsersTaggedThisPlaceCount: 1
// Phone: 8790315550
// Service: "Detox"
// ServiceTags: "WITH CARE TEAM,SUBCRIBER"
// TriggerAddress: "1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA"
// # TriggerGuid: "039BA9E4-2654-4E10-AACF-D3FA28832075"
// # TriggerSign: 1
// # TriggerTypeId: 9
// # TriggerTypeImgUrl: "https://appmedia.soberpeer.com/public/TriggerTypes/Home.png"
// # TriggerTypeValue: "Home"
// # TriggerVisit: [{DateInserted: "2022-04-21T14:06:04.877"
// # DistanceFromTrigger: "0.0meters"
// # MinutesSpent: "22m"}]
// UserGuid: "F93B7384-8F88-44B7-9391-A6B68B05BB5C"
// UserProfileImageUrl: ""
// VisitCount: 0

export const ActiveMaps = (className) => {
  const columns = [
    {
      dataField: "DateAndTime",
      text: "Date And Time",
      style: {
        whiteSpace: "nowrap",
      },
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "Trigger",
      text: "Trigger",
      style: {
        whiteSpace: "nowrap",
      },
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },

    {
      dataField: "Duration",
      text: "Duration",
      style: {
        whiteSpace: "nowrap",
      },
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "Location",
      text: "Location",
    },

    {
      dataField: "Actions",
      text: "Actions",
      formatter: "ActionsColumnFormatter",
      // formatter: columnFormatters.ActionsColumnFormatter,
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(triggersListGet({}));
  }, []);

  // const { ActiveMaps } = useSelector(({ user_member_trend_history }) => {
  //   return {
  //     ActiveMaps:
  //       (user_member_trend_history &&
  //         user_member_trend_history.data &&
  //         user_member_trend_history.data.Data &&
  //         user_member_trend_history.data.Data[0] &&
  //         user_member_trend_history.data.Data[0]["Active Maps"] &&
  //         user_member_trend_history.data.Data[0]["Active Maps"][0] &&
  //         user_member_trend_history.data.Data[0]["Active Maps"][0].MapData) ||
  //       [],
  //   };
  // }, shallowEqual);

  const {
    userTriggerHistoryData,
    ActiveMapsData,
    upsertClientNoteSuccess,
    // memberTrendTriggerHistoryData,
  } = useSelector(
    ({
      triggers_list_get: { data: TriggersData },
      user_member_trend_history,
      upsert_client_note,
      member_trend_trigger_history,
    }) => {
      return {
        userTriggerHistoryData: TriggersData?.Data?.Triggers,
        ActiveMapsData:
          user_member_trend_history?.data?.Data[0]?.ActiveMaps[0] || [],
        upsertClientNoteSuccess: upsert_client_note?.data?.success || false,
        // memberTrendTriggerHistoryData:
        //   (member_trend_trigger_history &&
        //     member_trend_trigger_history.data &&
        //     member_trend_trigger_history.data.Trigger &&
        //     member_trend_trigger_history.data.Trigger[0].TriggerHistory) ||
        //   [],
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (upsertClientNoteSuccess) {
      toast.success("Note added successfully");
    }
  }, [upsertClientNoteSuccess]);
  return (
    <>
      <div
        className="d-flex  flex-column w-100"
        style={{ justifyContent: "space-evenly" }}
      >
        {/* {ActiveMaps.map((item) => {
   
        return item.TriggerGuid;
      })} */}
        {/* {ActiveMaps && <MapView userTriggerHistoryData={ActiveMaps} />}{" "} */}
        {/* {userTriggerHistoryData && (
        <MapView userTriggerHistoryData={userTriggerHistoryData} />
      )}{" "} */}
        {ActiveMapsData && ActiveMapsData.GraphData && (
          <MapView
            userTriggerHistoryData={ActiveMapsData.GraphData}
            // memberTrendTriggerHistoryData={memberTrendTriggerHistoryData}
          />
        )}{" "}
        {ActiveMapsData && ActiveMapsData.TableData && (
          <CustomTable
            // tableTitle="Member Trends"
            entities={ActiveMapsData.TableData || []}
            columns={columns || []}
            isCardHeader={false}
            pagination={false}
            // isLoading={isFetching}
            bordered={false}
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            // tableOptions={{
            //   PageSize,
            //   IsRowClick,
            //   IsExcelExport,
            //   IsColumnSelectionEnable,
            // }}
            toggleDrawer={false}
            rowStyle={{
              backgroundColor: "#F7F8FA",
              margin: "12px 0px",
              boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
              borderRadius: "2px",
            }}
          />
        )}
      </div>
    </>
  );
};
