import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
// import { Popover, Cascader, Icon, Checkbox } from "antd";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {
  staffPermission,
  updateStaffPermission,
  resetUpdateStaffPermission,
} from "../../../redux/actions";
const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "block",
  // },
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
  },
}));
const RolesPermissions = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [Permissions, setRolesPermissions] = useState([]);
  const [PermissionsIndex, setPermissionsIndex] = useState();

  const getStaffId = () => {
    let param = new URLSearchParams(history.location.search);
    let staffId = param.get("id");
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };
  useEffect(() => {
    if (getStaffId()) {
      dispatch(
        staffPermission({
          Staff: [
            {
              StaffId: getStaffId(),
            },
          ],
        })
      );
    }
  }, []);

  const {
    RolesPermissionsData,
    isFetching,
    isUpdated,
    isDeactivated,
  } = useSelector((state) => {
    const {
      get_staff_permission: { data: permissionData },
      update_staff_permission: { data: updatePermissionData },
      update_user_profile,
    } = state;

    return {
      RolesPermissionsData: permissionData?.Data?.StaffPermissions,
      isUpdated: updatePermissionData?.ResponseCode === 1,
      isDeactivated: false,
      // update_user_profile.data && update_user_profile.data.userProfile,
    };
  }, shallowEqual);

  useEffect(() => {
    if (RolesPermissionsData) {
      setRolesPermissions(RolesPermissionsData);
    }
  }, [RolesPermissionsData]);

  useEffect(() => {
    if (isUpdated) {
      
      dispatch(resetUpdateStaffPermission());
    }
  }, [isUpdated]);

  return (
    <div className="row roles-permissions-section">
      <div className="col-12">
        <Card>
          {/* <CardHeader title={"Add New Course"}></CardHeader> */}
          <CardBody>
            <div className={classes.root}>
              {/* <FormControl component="fieldset" className={classes.formControl}> */}
              {Permissions &&
                Permissions.map((item, index) => {
                  return (
                    <div className="section-block" key={uuid()}>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend">
                          {index + 1}. {item.Description}
                        </FormLabel>
                        {/* <FormHelperText>Be careful</FormHelperText> */}
                        <FormGroup row>
                          {item.Permission &&
                            item.Permission.map((permission, rollIndex) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="permissions"
                                      disabled={
                                        permission.IsPermissionReadOnly ||
                                        isDeactivated
                                      }
                                      checked={
                                        permission.IsStaffPermissionEnabled
                                      }
                                      // onChange={handleChange("checkedA")}
                                      value={permission.Name}
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      onClick={(e) => {
                                        let newPermission = permission;
                                        newPermission.IsStaffPermissionEnabled =
                                          e.target.checked;
                                        let result = item;
                                        result.Permission[
                                          rollIndex
                                        ] = newPermission;
                                        let data = Permissions;
                                        data[index] = result;
                                        setRolesPermissions(data);
                                        dispatch(
                                          updateStaffPermission({
                                            StaffPermission: [
                                              {
                                                StaffId: getStaffId(),
                                                StaffPermissionId:
                                                  permission.StaffPermissionId,
                                                PermissionId:
                                                  permission.PermissionId,
                                                IsStaffPermissionEnabled:
                                                  e.target.checked,
                                              },
                                            ],
                                          })
                                        );
                                      }}
                                    />
                                  }
                                  label={permission.Name}
                                />
                              );
                            })}
                        </FormGroup>
                        {/* <FormHelperText>Be careful</FormHelperText> */}
                      </FormControl>
                    </div>
                  );
                })}
              {/* </FormControl> */}
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-left">
                  {props.StaffId && (
                    <>
                      {props.activeStep != 0 && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          onClick={() => {
                            history.push(
                              `/admin/staff/edit-form?tab=basic-details&id=${props.StaffId ||
                                props.staffId}`
                            );
                            props.handleBack();
                          }}
                          disabled={props.activeStep === 0}
                        >
                          Back
                        </Button>
                      )}
                      &nbsp;
                      {props.NextTab && (
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          // onClick={props.handleNext}
                          onClick={() => {
                            history.push(
                              `/admin/staff/edit-form?tab=mobile-app-details&id=${props.StaffId}`
                            );
                            props.handleNext();
                          }}
                          disabled={!props.NextTab}
                        >
                          Next
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <div className="text-right"></div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default RolesPermissions;
