import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import { toast } from "react-toastify";
import {
  taskListGet,
  taskUnAssign,
  resetTaskUnAssign,
  taskMarkComplete,
  resetTaskMarkComplete,
} from "../../../redux/actions";

import _RedCrossIcon from "../../images/redCrossIcon.svg";
import _BlueCheckIcon from "../../images/blueCheckIcon.svg";
import { ReduxPreDefinedTaskForm } from "./forms/assignPreDefinedTaskForm";
import { ReduxTaskForm } from "./forms/assignTaskForm";
import { DueDateForm } from "./forms/dueDateForm";
import { TaskCard } from "../../components/custom-cards";
const Task = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [drawerState, setDrawerState] = useState({
    right: false,
  });

  const [taskD, setTaskD] = useState();

  const [taskCategory, setTaskCategory] = useState("preDefinedTask");

  const toggleDrawer = (side, open, task) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [side]: open });
    setTaskCategory(task);
  };
  const getLatestData = () => {
    let data = {
      Task: [
        {
          ClientId: props.ID,
        },
      ],
    };

    dispatch(taskListGet(data));
  };
  useEffect(() => {
    getLatestData();
  }, []);

  const { taskData, isCompleted, isDeleted, isTaskSuccess } = useSelector(
    ({
      task_list_get: { data: listData },
      task_unassign: { data: unassignData },
      task_mark_complete: { data: completedData },
      task_upsert: { data: upsertData },
    }) => {
      return {
        taskData: listData?.Data?.Tasks,
        isCompleted: completedData?.ResponseCode === 1,
        isDeleted: unassignData?.ResponseCode === 1,
        isTaskSuccess: upsertData?.ResponseCode === 1,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    getLatestData();
  }, []);

  useEffect(() => {
    if (isCompleted) {
      // toast.dismiss();
      // toast.success(completedMessage);
      resetTaskMarkComplete();
      getLatestData();
    }
  }, [isCompleted]);
  useEffect(() => {
    if (isDeleted) {
      // toast.dismiss();
      // toast.success(unassignMessage);
      resetTaskUnAssign();
      getLatestData();
    }
  }, [isDeleted]);
  useEffect(() => {
    if (isTaskSuccess) {
      toggleDrawer("right", false)("no use");
      // toast.dismiss();

      // if (taskCategory == "dueDate") {
      //     toast.success("Due date changed successfully");
      // } else {
      //     toast.success(upsertMessage);
      // }

      getLatestData();
    }
  }, [isTaskSuccess]);

  const deleteTask = ({ ClientTaskId }) => {
    let data = {
      Task: [
        {
          ClientTaskId,
        },
      ],
    };
    dispatch(taskUnAssign(data));
  };
  const markAsCompleteTask = ({ ClientTaskId }) => {
    let data = {
      Task: [
        {
          ClientTaskId,
        },
      ],
    };
    dispatch(taskMarkComplete(data));
  };
  const editTask = (values) => {
    setTaskD(values);
    toggleDrawer("right", true, "dueDate")("no use");
  };

  return (
    <div className="card card-custom w-100">
      <div className="card-body pt-5">
        <div className="clientChart-task-tab">
          <div className="d-flex justify-content-end">
            <Button
              onClick={toggleDrawer("right", true, "preDefinedTask")}
              className="secondary mx-2"
              variant="contained"
              color="secondary"
            >
              + Assign Pre-defined Task
            </Button>
            <Button
              onClick={toggleDrawer("right", true, "newTask")}
              className="secondary mx-2"
              variant="contained"
              color="secondary"
            >
              + Assign Task
            </Button>
          </div>
          <div className="survey-list-section mt-5 mb-5">
            {taskData &&
              taskData.map((item, index) => {
                return (
                  <TaskCard
                    data={item}
                    handleDeleteTask={deleteTask}
                    handleCompleteTask={markAsCompleteTask}
                    handleEditTask={editTask}
                    key={uuid()}
                  />
                );
              })}
          </div>
          <Drawer
            anchor="right"
            open={drawerState.right}
            onClose={toggleDrawer("right", false)}
          >
            <div role="presentation" style={{ width: "800px" }}>
              {taskCategory == "preDefinedTask" && (
                <ReduxPreDefinedTaskForm
                  side="right"
                  ID={props.ID}
                  toggleDrawer={toggleDrawer}
                />
              )}
              {taskCategory == "newTask" && (
                <ReduxTaskForm
                  side="right"
                  ID={props.ID}
                  toggleDrawer={toggleDrawer}
                />
              )}
              {taskCategory == "dueDate" && (
                <DueDateForm
                  side="right"
                  ID={props.ID}
                  toggleDrawer={toggleDrawer}
                  taskD={taskD}
                />
              )}
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};
export default Task;
