import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import _EN from "../../../images/en.svg";
import _EST from "../../../images/est.svg";
import _Enabled from "../../../images/enabled.svg";
import _Badge from "../../../images/badge.svg";
import _blank from "../../../images/blank-profile.png";

import { useSubheader } from "../../../../_metronic/layout";
import { groupGet } from "../../../../redux/actions";
import { getClientId } from "../../../util/getParam";

const ProfileSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  //   const id = ;

  useEffect(() => {
    let data = {
      ClientGroup: [
        {
          // ClientId: 100000491,
          GroupId: getClientId(),
        },
      ],
    };

    dispatch(groupGet(data));
  }, []);

  const { GroupData } = useSelector((state) => {
    const {
      group_get: { data: GroupData },
    } = state;
    return {
      GroupData: GroupData?.Data?.ClinicalGroup?.[0] || {},
    };
  }, shallowEqual);

  return (
    <>
      {GroupData && (
        <div className="p-4 card card-custom gutter-b row flex-row ">
          <div className="col-5 d-flex flex-column">
            <p>{GroupData.GroupName}</p>
            {/* <div> */}
            <p className="">
              <strong className="mr-3">{GroupData.ClientCount}</strong>Clients
            </p>
            {/* <p>Clients</p> */}
            {/* </div> */}
          </div>
          <div className="col-6 d-flex justify-content-between align-items-center">
            <div className="">
              <p>{"Counsellor"}</p>
              <p className="font-weight-boldest">
                {GroupData.PractitionerName}
              </p>
            </div>
            <div className="">
              <p>{"Servive Code"}</p>
              <p className="font-weight-boldest">{GroupData.ServiceCodeText}</p>
            </div>
            <div className="">
              <p>{"Service Type"}</p>
              <p className="font-weight-boldest">{GroupData.Services}</p>
            </div>
            <div className="">
              <p>{"Office Location"}</p>
              <p className="font-weight-boldest">{GroupData.FacilityName}</p>
            </div>
            <div className="">
              <p>{"Status"}</p>
              <p className="font-weight-boldest">{GroupData.GroupStatus}</p>
            </div>
            <p
              className="font-weight-boldest"
              style={{ color: "#009EF7", cursor: "pointer" }}
              onClick={() => {
                history.push(`/community/group/edit?id=${getClientId()}`);
              }}
            >
              EDIT
            </p>
          </div>
        </div>
      )}
      {/* <h1>clinical groups</h1> */}
    </>
  );
};
export default ProfileSection;
