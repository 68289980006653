/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { useLocation } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { sideBarMenuGet } from "../../../../../redux/actions";
import { connect } from "react-redux";
let AsideMenuList = ({ layoutProps, sideBarMenu }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sideBarMenuGet({}));
  }, []);

  let menuArray = [
    // {
    //   Name: "Dashboard3",
    //   IsIcon: true,
    //   Icon: "/media/svg/icons/Design/Layers.svg",
    //   Path: "/dashboard",
    //   IsSection: false,
    //   ChildMenu: false,
    // },
    // {
    //   Name: null,
    //   IsIcon: null,
    //   Icon: null,
    //   Path: null,
    //   sectionTitle: "Components2",
    //   IsSection: true,
    //   ChildMenu: false,
    // },
    // {
    //   Name: "Material UI",
    //   IsIcon: true,
    //   Icon: "/media/svg/icons/Design/Cap-2.svg",
    //   Path: "/dashboard",
    //   IsSection: false,
    //   ChildMenu: true,
    // },
  ];
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {sideBarMenu &&
          sideBarMenu.map((item, index) => {
            const {
              Name,
              IsIcon,
              Icon,
              Path,
              pathname,
              IsSection,
              sectionTitle,
              ChildMenu,
            } = item;
            if (IsSection) {
              return (
                <>
                  <li className="menu-section ">
                    <h4 className="menu-text">{Name}</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>
                </>
              );
            } else {
              if (ChildMenu && ChildMenu.length > 0) {
                return (
                  <>
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        { Path },
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        className="menu-link menu-toggle"
                        to={Path || "/dashboard"}
                      >
                        {IsIcon && (
                          <span className="svg-icon menu-icon">
                            <SVG
                              src={toAbsoluteUrl(
                                Icon
                                  ? `/media/svg/icons/Design/${Icon}`
                                  : `/media/svg/icons/Design/Layers.svg`
                              )}
                            />
                          </span>
                        )}
                        <span className="menu-text">{Name}</span>
                        <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu ">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                          {ChildMenu &&
                            ChildMenu.map((chidlItem, index) => {
                              const {
                                Name,
                                IsIcon,
                                Icon,
                                Path,
                                pathname,
                                IsSection,
                                sectionTitle,
                                ChildMenu,
                              } = chidlItem;
                              return (
                                <>
                                  {" "}
                                  {/* <li
                                    className="menu-item  menu-item-parent"
                                    aria-haspopup="true"
                                  >
                                    <span className="menu-link">
                                      <span className="menu-text">
                                        Material UI
                                      </span>
                                    </span>
                                  </li> */}
                                  <li
                                    className={`menu-item  ${getMenuItemActive(
                                      Path,
                                      true
                                    )}`}
                                    aria-haspopup="true"
                                    data-menu-toggle="hover"
                                  >
                                    <NavLink className="menu-link" to={Path}>
                                      <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                      </i>
                                      <span className="menu-text">{Name}</span>
                                      {ChildMenu && ChildMenu.length > 0 && (
                                        <i className="menu-arrow" />
                                      )}
                                    </NavLink>
                                    <div className="menu-submenu ">
                                      <i className="menu-arrow" />
                                      <ul className="menu-subnav">
                                        {ChildMenu &&
                                          ChildMenu.map(
                                            (childMenuItem, index) => {
                                              const {
                                                Name,
                                                IsIcon,
                                                Icon,
                                                Path,
                                                pathname,
                                                IsSection,
                                                sectionTitle,
                                                ChildMenu,
                                              } = childMenuItem;

                                              return (
                                                <li
                                                  id={Name}
                                                  className={`menu-item  ${getMenuItemActive(
                                                    { Path }
                                                  )}`}
                                                  aria-haspopup="true"
                                                >
                                                  <NavLink
                                                    className="menu-link"
                                                    to={Path}
                                                  >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                      <span />
                                                    </i>
                                                    <span className="menu-text">
                                                      {Name}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                        </ul>
                      </div>
                    </li>
                  </>
                );
              } else {
                return (
                  <li
                    className={`menu-item ${getMenuItemActive(Path, false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to={Path || "/dashboard"}>
                      {IsIcon && (
                        <span className="svg-icon menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              Icon
                                ? `/media/svg/icons/Design/${Icon}`
                                : `/media/svg/icons/Design/Layers.svg`
                            )}
                          />
                        </span>
                      )}
                      <span className="menu-text">{Name}</span>
                    </NavLink>
                  </li>
                );
              }
            }
          })}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { side_bar_menu_get } = state;
  const { data, isFetching, error } = side_bar_menu_get;

  var sideBarMenu = [
    {
      Name: "Dashboard",
      ToolTip: "Dashboard",
      IsParentMenu: false,
      Sequence: 700,
      SearchTerms: null,
      Path: "/dashboard",
      Component: "ReportsTable",
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: true,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: null,
    },
    {
      Name: "Intake",
      ToolTip: "Intake",
      IsParentMenu: true,
      Sequence: 500,
      SearchTerms: "intake",
      Path: "/intake",
      Component: "ReportsTable",
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: true,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: [
        {
          Name: "Intake",
          ToolTip: "Intake",
          IsParentMenu: false,
          Sequence: 10,
          SearchTerms: "intake",
          Path: "/intake/intake",
          Component: "MyPage",
          MasterPage: "MasterPageAuthenticatedRoute",
          AllowAllAccess: true,
          Exact: false,
          IsIcon: 1,
          Icon: "Layers.svg",
          IsSection: false,
          GrandChildMenu: null,
        },
        {
          Name: "Pre-Intake",
          ToolTip: "Pre-Intake",
          IsParentMenu: false,
          Sequence: 10,
          SearchTerms: "pre-intake",
          Path: "/intake/pre-intake",
          Component: "MyPage",
          MasterPage: "MasterPageAuthenticatedRoute",
          AllowAllAccess: true,
          Exact: false,
          IsIcon: 1,
          Icon: "Layers.svg",
          IsSection: false,
          GrandChildMenu: null,
        },
      ],
    },
    // {
    //   Name: "Member Trends",
    //   ToolTip: "Member Trends",
    //   IsParentMenu: false,
    //   Sequence: 650,
    //   SearchTerms: null,
    //   Path: "/member-trends",
    //   Component: "ReportsTable",
    //   MasterPage: "MasterPageAuthenticatedRoute",
    //   AllowAllAccess: true,
    //   Exact: false,
    //   IsIcon: true,
    //   Icon: "Layers.svg",
    //   IsSection: false,
    //   ChildMenu: null,
    // },
    {
      Name: "Breathlyzer",
      ToolTip: "Breathlyzer",
      IsParentMenu: false,
      Sequence: 650,
      SearchTerms: null,
      Path: "/breathlyzer/list",
      Component: "ReportsTable",
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: true,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: null,
    },
    // {
    //   Name: "Course",
    //   ToolTip: "Course",
    //   IsParentMenu: false,
    //   Sequence: 650,
    //   SearchTerms: null,
    //   Path: "/course/list",
    //   Component: "ReportsTable",
    //   MasterPage: "MasterPageAuthenticatedRoute",
    //   AllowAllAccess: true,
    //   Exact: false,
    //   IsIcon: true,
    //   Icon: "Layers.svg",
    //   IsSection: false,
    //   ChildMenu: null,
    // },
    // {
    //   Name: "Staff",
    //   ToolTip: "Staff",
    //   IsParentMenu: false,
    //   Sequence: 700,
    //   SearchTerms: null,
    //   Path: "/staff/list",
    //   Component: "ReportsTable",
    //   MasterPage: "MasterPageAuthenticatedRoute",
    //   AllowAllAccess: true,
    //   Exact: false,
    //   IsIcon: true,
    //   Icon: "Layers.svg",
    //   IsSection: false,
    //   ChildMenu: null,
    // },
    // {
    //   Name: "Center",
    //   ToolTip: "Center",
    //   IsParentMenu: false,
    //   Sequence: 700,
    //   SearchTerms: null,
    //   Path: "/center/list",
    //   Component: "ReportsTable",
    //   MasterPage: "MasterPageAuthenticatedRoute",
    //   AllowAllAccess: true,
    //   Exact: false,
    //   IsIcon: true,
    //   Icon: "Layers.svg",
    //   IsSection: false,
    //   ChildMenu: null,
    // },
    {
      Name: "Population Activities",
      ToolTip: "Population Activities",
      IsParentMenu: false,
      Sequence: 700,
      SearchTerms: null,
      Path: "/population-activities",
      Component: "ReportsTable",
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: true,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: null,
    },
    {
      Name: "Assessments",
      ToolTip: "Assessments",
      IsParentMenu: false,
      Sequence: 700,
      SearchTerms: null,
      Path: "/assessments",
      Component: "ReportsTable",
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: true,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: null,
    },
    {
      Name: "Client Roster",
      ToolTip: "ClientRoster",
      IsParentMenu: false,
      Sequence: 700,
      SearchTerms: null,
      Path: "/client-roster",
      Component: "ReportsTable",
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: true,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: null,
    },
    {
      Name: "Appointments",
      ToolTip: "Appointments",
      IsParentMenu: false,
      Sequence: 700,
      SearchTerms: null,
      Path: "/appointments",
      Component: "ReportsTable",
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: true,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: null,
    },

    {
      Name: "Community",
      ToolTip: "Community",
      IsParentMenu: true,
      Sequence: 500,
      SearchTerms: "Community",
      Path: "/community",
      Component: "Community",
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: true,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: [
        {
          Name: "Small Group",
          ToolTip: "Small Group",
          IsParentMenu: false,
          Sequence: 10,
          SearchTerms: "Small Groups",
          Path: "/community/small-group-list",
          Component: "SmallGroupList",
          MasterPage: "MasterPageAuthenticatedRoute",
          AllowAllAccess: true,
          Exact: false,
          IsIcon: 1,
          Icon: "Layers.svg",
          IsSection: false,
          GrandChildMenu: null,
        },
        {
          Name: "Clinical Groups",
          ToolTip: "Clinical Groups",
          IsParentMenu: false,
          Sequence: 10,
          SearchTerms: "Clinical Groups",
          Path: "/community/clinical-group-list",
          Component: "ClinicalGroupList",
          MasterPage: "MasterPageAuthenticatedRoute",
          AllowAllAccess: true,
          Exact: false,
          IsIcon: 1,
          Icon: "Layers.svg",
          IsSection: false,
          GrandChildMenu: null,
        },
      ],
    },
    // {
    //   Name: "Claims",
    //   ToolTip: "Claims",
    //   IsParentMenu: true,
    //   Sequence: 500,
    //   SearchTerms: "Claims",
    //   Path: "/claims",
    //   Component: "Claims",
    //   MasterPage: "MasterPageAuthenticatedRoute",
    //   AllowAllAccess: true,
    //   Exact: false,
    //   IsIcon: true,
    //   Icon: "Layers.svg",
    //   IsSection: false,
    //   ChildMenu: [
    //     {
    //       Name: "Service Line",
    //       ToolTip: "Service Line",
    //       IsParentMenu: false,
    //       Sequence: 10,
    //       SearchTerms: "Service Line",
    //       Path: "/claims/service-line-list",
    //       Component: "ServiceLineList",
    //       MasterPage: "MasterPageAuthenticatedRoute",
    //       AllowAllAccess: true,
    //       Exact: false,
    //       IsIcon: 1,
    //       Icon: "Layers.svg",
    //       IsSection: false,
    //       GrandChildMenu: null,
    //     },
    //     {
    //       Name: "Group Service Line",
    //       ToolTip: "Group Service Line",
    //       IsParentMenu: false,
    //       Sequence: 10,
    //       SearchTerms: "Group Service Line",
    //       Path: "/claims/group-service-line-list",
    //       Component: "GroupServiceLineList",
    //       MasterPage: "MasterPageAuthenticatedRoute",
    //       AllowAllAccess: true,
    //       Exact: false,
    //       IsIcon: 1,
    //       Icon: "Layers.svg",
    //       IsSection: false,
    //       GrandChildMenu: null,
    //     },
    //     {
    //       Name: "Bundeled Service",
    //       ToolTip: "Bundeled Service",
    //       IsParentMenu: false,
    //       Sequence: 10,
    //       SearchTerms: "Bundeled Service",
    //       Path: "/claims/bundeled-service-list",
    //       Component: "BundeledServiceList",
    //       MasterPage: "MasterPageAuthenticatedRoute",
    //       AllowAllAccess: true,
    //       Exact: false,
    //       IsIcon: 1,
    //       Icon: "Layers.svg",
    //       IsSection: false,
    //       GrandChildMenu: null,
    //     },
    //     {
    //       Name: "Services By Date",
    //       ToolTip: "Services By Date",
    //       IsParentMenu: false,
    //       Sequence: 10,
    //       SearchTerms: "Services By Date",
    //       Path: "/claims/services-by-date-list",
    //       Component: "ServicesByDateList",
    //       MasterPage: "MasterPageAuthenticatedRoute",
    //       AllowAllAccess: true,
    //       Exact: false,
    //       IsIcon: 1,
    //       Icon: "Layers.svg",
    //       IsSection: false,
    //       GrandChildMenu: null,
    //     },
    //   ],
    // },
    {
      Name: "Growth Plan",
      ToolTip: "GrowthPlan",
      IsParentMenu: false,
      Sequence: 700,
      SearchTerms: null,
      Path: "/growth-plan",
      Component: "ReportsTable",
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: true,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: null,
    },
    // {
    //   Name: "RCM",
    //   ToolTip: "Revenue Cycle Management",
    //   IsParentMenu: true,
    //   Sequence: 500,
    //   SearchTerms: "RCM",
    //   Path: "/rcm",
    //   Component: "RCM",
    //   MasterPage: "MasterPageAuthenticatedRoute",
    //   AllowAllAccess: true,
    //   Exact: false,
    //   IsIcon: true,
    //   Icon: "Layers.svg",
    //   IsSection: false,
    //   ChildMenu: [
    //     {
    //       Name: "Batching",
    //       ToolTip: "Batching",
    //       IsParentMenu: false,
    //       Sequence: 10,
    //       SearchTerms: "Batching",
    //       Path: "/rcm/batching-list",
    //       Component: "BatchingList",
    //       MasterPage: "MasterPageAuthenticatedRoute",
    //       AllowAllAccess: true,
    //       Exact: false,
    //       IsIcon: 1,
    //       Icon: "Layers.svg",
    //       IsSection: false,
    //       GrandChildMenu: null,
    //     },
    //     {
    //       Name: "Fee Schedule",
    //       ToolTip: "Fee Schedule",
    //       IsParentMenu: false,
    //       Sequence: 10,
    //       SearchTerms: "Fee Schedule",
    //       Path: "/rcm/fee-schedule-list",
    //       Component: "FeeScheduleList",
    //       MasterPage: "MasterPageAuthenticatedRoute",
    //       AllowAllAccess: true,
    //       Exact: false,
    //       IsIcon: 1,
    //       Icon: "Layers.svg",
    //       IsSection: false,
    //       GrandChildMenu: null,
    //     },
    //     {
    //       Name: "Payer",
    //       ToolTip: "Payer",
    //       IsParentMenu: false,
    //       Sequence: 10,
    //       SearchTerms: "Payer",
    //       Path: "/rcm/payer-list",
    //       Component: "PayerList",
    //       MasterPage: "MasterPageAuthenticatedRoute",
    //       AllowAllAccess: true,
    //       Exact: false,
    //       IsIcon: 1,
    //       Icon: "Layers.svg",
    //       IsSection: false,
    //       GrandChildMenu: null,
    //     },
    //     {
    //       Name: "Payment",
    //       ToolTip: "Payment",
    //       IsParentMenu: false,
    //       Sequence: 10,
    //       SearchTerms: "Payment",
    //       Path: "/rcm/payment-list",
    //       Component: "PaymentList",
    //       MasterPage: "MasterPageAuthenticatedRoute",
    //       AllowAllAccess: true,
    //       Exact: false,
    //       IsIcon: 1,
    //       Icon: "Layers.svg",
    //       IsSection: false,
    //       GrandChildMenu: null,
    //     },
    //     {
    //       Name: "Service Code",
    //       ToolTip: "Service Code",
    //       IsParentMenu: false,
    //       Sequence: 10,
    //       SearchTerms: "Service Code",
    //       Path: "/rcm/service-code-list",
    //       Component: "ServiceCodeList",
    //       MasterPage: "MasterPageAuthenticatedRoute",
    //       AllowAllAccess: true,
    //       Exact: false,
    //       IsIcon: 1,
    //       Icon: "Layers.svg",
    //       IsSection: false,
    //       GrandChildMenu: null,
    //     },
    //   ],
    // },
    {
      Name: "Admin",
      ToolTip: "Admin",
      IsParentMenu: true,
      Sequence: 100,
      SearchTerms: "admin",
      Path: "/admin",
      Component: null,
      MasterPage: "MasterPageAuthenticatedRoute",
      AllowAllAccess: null,
      Exact: false,
      IsIcon: true,
      Icon: "Layers.svg",
      IsSection: false,
      ChildMenu: [
        {
          Name: "Courses",
          ToolTip: "Courses",
          IsParentMenu: false,
          Sequence: 120,
          SearchTerms: "courses",
          Path: "/admin/courses/list",
          Component: "MyPage",
          MasterPage: "MasterPageAuthenticatedRoute",
          AllowAllAccess: true,
          Exact: false,
          IsIcon: 1,
          Icon: "Layers.svg",
          IsSection: false,
          GrandChildMenu: null,
        },
        {
          Name: "Centers",
          ToolTip: "Centers",
          IsParentMenu: false,
          Sequence: 120,
          SearchTerms: "centers",
          Path: "/admin/centers/list",
          Component: "MyPage",
          MasterPage: "MasterPageAuthenticatedRoute",
          AllowAllAccess: true,
          Exact: false,
          IsIcon: 1,
          Icon: "Layers.svg",
          IsSection: false,
          GrandChildMenu: null,
        },
        {
          Name: "Staff",
          ToolTip: "Staff",
          IsParentMenu: false,
          Sequence: 120,
          SearchTerms: "staff",
          Path: "/admin/staff/list",
          Component: "MyPage",
          MasterPage: "MasterPageAuthenticatedRoute",
          AllowAllAccess: true,
          Exact: false,
          IsIcon: 1,
          Icon: "Layers.svg",
          IsSection: false,
          GrandChildMenu: null,
        },
        {
          Name: "Referral Codes",
          ToolTip: "Referral Codes",
          IsParentMenu: false,
          Sequence: 120,
          SearchTerms: "referral codes",
          Path: "/admin/coupon/list",
          Component: "MyPage",
          MasterPage: "MasterPageAuthenticatedRoute",
          AllowAllAccess: true,
          Exact: false,
          IsIcon: 1,
          Icon: "Layers.svg",
          IsSection: false,
          GrandChildMenu: null,
        },
        {
          Name: "Miscellaneous",
          ToolTip: "Miscellaneous",
          IsParentMenu: false,
          Sequence: 120,
          SearchTerms: "Miscellaneous",
          Path: "/admin/miscellaneous",
          Component: "MyPage",
          MasterPage: "MasterPageAuthenticatedRoute",
          AllowAllAccess: true,
          Exact: false,
          IsIcon: 1,
          Icon: "Layers.svg",
          IsSection: false,
          GrandChildMenu: null,
        },
      ],
    },
    // {
    //   Name: "Shift Logs",
    //   ToolTip: "Shift Logs",
    //   IsParentMenu: false,
    //   Sequence: 700,
    //   SearchTerms: null,
    //   Path: "/shift-logs",
    //   Component: "ShiftLogsList",
    //   MasterPage: "MasterPageAuthenticatedRoute",
    //   AllowAllAccess: true,
    //   Exact: false,
    //   IsIcon: true,
    //   Icon: "Layers.svg",
    //   IsSection: false,
    //   ChildMenu: null,
    // },
  ];
  var errorMessage;
  if (data?.Data?.Menu) {
    // sideBarMenu = data?.Data?.Menu;
  } else if (error) {
    errorMessage = error.error_description;
  }

  return {
    sideBarMenu,
  };
};

export default AsideMenuList = connect(mapStateToProps)(AsideMenuList);
