import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  clientsNotParticipatingInGroup,
  clientGroupUpsert,
} from "../../../../redux/actions";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import _Close from "../../../images/close.svg";
import _blank from "../../../images/blank-profile.png";

export const ViewNonParticipantsDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      clientsNotParticipatingInGroup({
        Client: [
          {
            Search: null,
            GroupId: props.GroupId,
          },
        ],
      })
    );
  }, []);
  const { clientsList, isFetching } = useSelector((state) => {
    const {
      clients_not_participating_in_group: { data },
    } = state;
    if (data?.Data?.Clients) {
      //    debugger;
    }
    return {
      clientsList: data?.Data?.Clients || [],
      // currentCountryValue: selector(state, "CountryISO"),
    };
  }, shallowEqual);
  const { GroupId, ClientId, DisplayName, ProfileImgUrl } = clientsList || {};
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <p>Group Members </p>
          {/* <SearchBar
            // {...props.searchProps}
            className="custome-search-field mr-2"
            style={{ color: "black" }}
            delay={1000}
            placeholder="Search in all fields"
            onKeyUp={() => alert("this is onkey")}
          /> */}
          <input
            type="text"
            style={{
              borderColor: "rgba(0,0,0,0.2)",
              borderRadius: "3px",
              height: "30px",
              outline: "none",
            }}
            placeholder="Search"
            onKeyUp={(e) => {
              e.persist();
              dispatch(
                clientsNotParticipatingInGroup({
                  Client: [
                    {
                      Search: e.target.value,
                      GroupId: props.GroupId,
                    },
                  ],
                })
              );
            }}
          />
        </div>
        {/* Body */}
        <div className="card-body pt-2 drawer-small-group drawer-details-view">
          <div className="row">
            <div className="col-11">
              {/* <span className="small-group-title">{GroupName}</span> */}
            </div>
          </div>
          <div className="">
            {clientsList.map((item) => (
              <div
                className="row align-items-center"
                style={{
                  padding: "10px",
                  margin: "14px 10px",
                  backgroundColor: "#F7F8FA",
                }}
              >
                <Checkbox
                  //   checked={toggles[colName]}
                  value={item.ClientId}
                  onChange={(e) => props.handleChecked(item.ClientGroupId, e)}
                />
                <img
                  src={item.ProfileImgUrl || _blank}
                  alt=""
                  style={{ height: "42px", width: "42px", borderRadius: "50%" }}
                />
                <ListItemText
                  style={{ marginLeft: "8px" }}
                  primary={item.DisplayName}
                />
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
