import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Button from "@material-ui/core/Button";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from "redux-form";
import {
  getFormFieldMetaData,
  formDataGet,
  resetFormDataGet,
  upsertFormData,
  staffReactivate,
  staffDelete,
  staffUpsert,
  resetStaffUpsert,
  listCombos,
  staffGet,
  resetStaffGet,
} from "../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";
import formDataHandler from "../../util/formDataHandler";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));



const StaffForm = (props) => {
  const [formFields, setFormFields] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const suhbeader = useSubheader();
  suhbeader.setTitle(props.StaffId ? "Edit Staff" : "Add New Staff");
  const selector = formValueSelector("basicDetail");

  const CONDITION_TYPE = {
    isCareTeamMemberDependentField: () => {
      return isCareTeamMember == true;
    },
  };
  
  const {
    genericFormData,
    isFetching,
    currentCountryValue,
    isCareTeamMember,
  } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data },
    } = state;
    return {
      genericFormData: data?.Data?.FormFieldMetaData || [],
      currentCountryValue: selector(state, "CountryISO"),
      isCareTeamMember: selector(state, "IsCareTeamMember"),
    };
  }, shallowEqual);



  useEffect(() => {
    if (genericFormData && genericFormData.length) {
      let validationConversion = genericFormData;
      validationConversion =
        validationConversion &&
        validationConversion.map((item, index) => {
          if (item.name == "StateOrProvince") {
            let currentOptions = item.DependentOptions.filter(
              (countryStateObj) => {
                //   return item.Parent == "USA";
                if (countryStateObj.Parent == "USA") {
                  debugger;
                }
                return countryStateObj.Parent == currentCountryValue;
              }
            );

            let currentOptionsValue = currentOptions?.[0]?.Child;

            return {
              ...item,
              options: currentOptionsValue || [],
            };
          } else {
            return item;
          }
          // return item;
        });
      debugger;
      let conditionalConversion =
        validationConversion &&
        validationConversion.map((item, index) => {
          if (item.condition == "isCareTeamMemberDependentField") {
            return {
              ...item,
              condition: CONDITION_TYPE[item.condition],
            };
          } else {
            return item;
          }
          // return item;
        });
      setFormFields(conditionalConversion);
    }
  }, [genericFormData, currentCountryValue, isCareTeamMember]);



  // useEffect(() => {
  //   if (genericFormData && genericFormData.length) {
  //     let validationConversion = genericFormData;
  //     validationConversion =
  //       validationConversion &&
  //       validationConversion.map((item, index) => {
  //         if (item.name == "StateOrProvince") {
  //           let currentOptions = item.DependentOptions.filter((item) => {
  //             return item.Parent == "USA";
  //             //   return item.Parent == currentCountryValue;
  //           })[0].Child;
  //           return {
  //             ...item,
  //             options: currentOptions || [],
  //           };
  //         } else {
  //           return item;
  //         }
  //       });

  //     setFormFields(validationConversion);
  //   }
  // }, [genericFormData]);

  // useEffect(() => {
  //   if (genericFormData && genericFormData.length) {
  //     let newFormFields =
  //       formFields &&
  //       formFields.map((item, index) => {
  //         if (item.name == "StateOrProvince") {
  //           let currentOptions = item.DependentOptions.filter((item) => {
  //             return item.Parent == currentCountryValue;
  //           })[0].Child;

  //           return {
  //             ...item,
  //             options: currentOptions || [],
  //           };
  //         } else {
  //           return item;
  //         }
  //       });

  //     setFormFields(newFormFields);
  //   }
  // }, [currentCountryValue]);

  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    StaffId,
  } = props;

  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="enrollment-form staff-form pt-2 pb-2">
                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    <DynamicForm formFields={formFields} disable={false} />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-left">
                    {StaffId && (
                      <>
                        {activeStep != 0 && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            Back
                          </Button>
                        )}
                        &nbsp;
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={() => {
                              history.push(
                                `/admin/staff/edit-form?tab=roles-and-permissions&id=${props.StaffId ||
                                  props.staffId}`
                              );
                            handleNext();
                          }}
                          disabled={!NextTab}
                        >
                          Next
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="text-right">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      type="submit"
                      disabled={pristine || submitting}
                    >
                      Submit
                    </Button>
                    &nbsp;
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      disabled={pristine || submitting}
                      onClick={reset}
                    >
                      Clear Values
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const ReduxBasicDetail = reduxForm({
  form: "basicDetail",
  enableReinitialize: true,
})(StaffForm);

const BasicDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
     dispatch(resetStaffGet({}));
    dispatch(resetStaffUpsert());
    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: "Staff",
            Id: props.StaffId,
            FormDataId: props.StaffId,
          },
        ],
      })
    );

    if (props.StaffId || props.StaffId === 0) {
      dispatch(
        staffGet({
          Staff: [
            {
              StaffId: props.StaffId,
            },
          ],
        })
      );
    }
    return () => {
      dispatch(resetStaffGet({}));
    };
  }, []);

    const { staffData, isStaffUpsert, isFetching, staffId } = useSelector(
      (state) => {
        const {
          staff_upsert: { data: upsertData },
          staff_get: { data: staffUser },
        } = state;
        return {
          staffData: staffUser?.Data?.Staffs?.[0] || {},
          isStaffUpsert: upsertData?.ResponseCode === 1,
          staffId: parseInt(upsertData?.Data?.Id),
        };
      },
      shallowEqual
  );
  
  const onSubmit = (values) => {
    let ObjectData = formDataHandler(values);
    const formData = {
      Staff: [
        {
          ...staffData,
          ...ObjectData,
        },
      ],
    };
    dispatch(staffUpsert(formData));
  };



  useEffect(() => {
    if (isStaffUpsert) {
      history.push(
        `/admin/staff/edit-form?tab=roles-and-permissions&id=${props.StaffId ||
          props.staffId ||
          staffId}`
      );

      dispatch(resetStaffUpsert());
      props.handleNext();
    }
  }, [isStaffUpsert]);
  const {
    FirstName,
      MiddleName,
      LastName,
      DateofBirth,
      Gender,
      StaffRoleId,
      PayTypeId,
      AddressLine1,
      AddressLine2,
      City,
      StateOrProvince,
      ZipCode,
      CountryISO,
      PhoneMobile,
      PhoneHome,
      EmailWork,
      EmailHome,
      IsCareTeamMember,
      AdditionalCenter,
      CenterId,
      AreaOfFocus,
      Certifications,
      PlansAccepted,
      Affiliations,
      LanguagesSpoken,
  } = staffData;
  const initDataForBasicDetails = {
    FirstName,
    MiddleName,
    LastName,
    DateofBirth,
    Gender,
    StaffRoleId,
    PayTypeId,
    AddressLine1,
    AddressLine2,
    City,
    StateOrProvince,
    ZipCode,
    CountryISO: CountryISO?CountryISO:"USA",
    PhoneMobile,
    PhoneHome,
    EmailWork,
    EmailHome,
    IsCareTeamMember,
    AdditionalCenter,
    CenterId,
    AreaOfFocus,
    Certifications,
    PlansAccepted,
    Affiliations,
    LanguagesSpoken,
  };
 
  return (
    <div className="">
      <ReduxBasicDetail
        initialValues={{ ...initDataForBasicDetails }}
        StaffId={props.StaffId}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
        activeStep={props.activeStep}
        NextTab={props.NextTab}
        onSubmit={onSubmit}

        // onCancel={() =>
        //   this.props.history.push("/manage/staff-management/staff-list")
        // }
        // updateStep={this.props.updateStep}
        // formData={this.props.initialValues}
        // getStaffStatus={this.props.getStaffStatus}
      />
    </div>
  );
};

export default BasicDetail;
