import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* getAllJournalComments(action) {
  try {
    const { payload } = action;
    const response = yield API.getAllJournalComments(payload);
    yield put({
      type: ActionTypes.GET_ALL_JOURNAL_COMMENTS_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ALL_JOURNAL_COMMENTS_FAILURE,
    });
  }
}
export function* getAllJournal(action) {
  try {
    const { payload } = action;
    const response = yield API.getAllJournal(payload);
    yield put({
      type: ActionTypes.GET_ALL_JOURNAL_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ALL_JOURNAL_FAILURE,
    });
  }
}

export function* journalAdd(action) {
  try {
    const { payload } = action;
    const response = yield API.journalAdd(payload);
    yield put({
      type: ActionTypes.JOURNAL_ADD_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.JOURNAL_ADD_FAILURE,
    });
  }
}

export function* journalCommentUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.journalCommentUpsert(payload);
    yield put({
      type: ActionTypes.JOURNAL_COMMENT_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.JOURNAL_COMMENT_UPSERT_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.GET_ALL_JOURNAL_COMMENTS_REQUEST,
      getAllJournalComments
    ),
    takeLatest(ActionTypes.GET_ALL_JOURNAL_REQUEST, getAllJournal),
    takeLatest(ActionTypes.JOURNAL_ADD_REQUEST, journalAdd),
    takeLatest(
      ActionTypes.JOURNAL_COMMENT_UPSERT_REQUEST,
      journalCommentUpsert
    ),
  ]);
}
