import React, {
	useEffect,
	useMemo,
	createContext,
	useContext,
	useState,
	useCallback,
} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Dropdown } from "react-bootstrap";
import exportPdf from "./exportPdf";
import ExportExcel from "./exportExcel";
import { DropdownCustomToggler } from "../../../../_metronic/_partials/dropdowns";
import _filterColumn from "../../../images/filter-column.svg";
import _PDF from "../../../images/pdf.png";
import _EXCEL from "../../../images/excel.svg";
import _CSV from "../../../images/csv.svg";
import _Export from "../../../images/export.svg";

export const ExportData = ({
	tableColumns,
	rowData,
	FileName,
	csvProps,
	IsExcelExport,
}) => {
	return (
		<Dropdown className="dropdown-inline" alignRight>
			<Dropdown.Toggle
				variant="transparent"
				id="dropdown-toggle-top"
				className="btn btn-hover-light-primary btn-sm btn-icon"
				as={DropdownCustomToggler}
			>
				<img src={_Export} height="25" />
			</Dropdown.Toggle>
			<Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
				<div className="navi-header font-weight-bold py-2 pl-5">
					<span className="font-size-lg">
						{/* <img src={_CSV} height="20" /> */}
						Export
						{/* {FileName} */}
					</span>
				</div>

				<MenuItem key={"excel"}>
					<span className="navi-icon px-2">
						<img src={_EXCEL} height="20" />
					</span>
					<span className="navi-text">
						<ExportExcel
							tableColumns={tableColumns}
							rowData={rowData}
							FileName={FileName}
						/>
					</span>
				</MenuItem>
				<MenuItem key={"pdf"}>
					<span className="navi-icon px-2">
						<img src={_PDF} height="20" />
					</span>
					<span
						className="navi-text"
						onClick={() => {
							exportPdf(tableColumns, rowData, FileName);
						}}
					>
						PDF
					</span>
				</MenuItem>
				<MenuItem key={"csv"}>
					<span className="navi-icon px-2">
						<img src={_CSV} height="20" />
					</span>
					<span
						className="navi-text"
						onClick={() => {
							csvProps.onExport();
						}}
					>
						CSV
					</span>
				</MenuItem>
			</Dropdown.Menu>
		</Dropdown>
	);
};
