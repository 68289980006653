import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

import { useSubheader } from "../../../../_metronic/layout";
import CenteredTabs from "../../custom-component/custom-tabs";
import ClientList from "../../../pages/clientRoster/clientList";
import {
  getDynamicScreenTab,
  resetGetDynamicScreenTab,
} from "../../../../redux/actions";

const TabsSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [TabLabels, setTabLabels] = useState([]);
  useEffect(() => {
    let data = {
      Client: [
        {
          ScreenName: "ClientRoster",
        },
      ],
    };
    dispatch(getDynamicScreenTab(data));
    return () => {
      dispatch(resetGetDynamicScreenTab());
    };
  }, []);

  const Labels = {
    AllClients: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <ClientList tabId="ClientRosterAllClient" />
        </>
      );
    },
    IOP: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <ClientList tabId="IOP" /> */}
        </>
      );
    },
    PHP: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <ClientList tabId="PHP" /> */}
        </>
      );
    },
    Detox: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <ClientList tabId="Detox" /> */}
        </>
      );
    },
    Alumni: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <ClientList tabId="Alumni" /> */}
        </>
      );
    },
    Resdiential: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <ClientList tabId="Resdiential" /> */}
        </>
      );
    },
    FamilyServices: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <ClientList tabId="FamilyServices" /> */}
        </>
      );
    },
    CourtSystem: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <ClientList tabId="CourtSystem" /> */}
        </>
      );
    },
    University: () => {
      return (
        <>
          <span>Coming Soon</span>
          {/* <ClientList tabId="University" /> */}
        </>
      );
    },
  };

  const { TabInfo, Summary } = useSelector(
    ({ get_dynamic_screen_tab: { data, isFeching } }) => {
      return {
        TabInfo: data?.Tabs,
      };
    },
    shallowEqual
  );

  // useEffect(() => {
  //   if (TabInfo && TabInfo.length > 0) {
  //     let tabs = TabInfo.map((tab, index) => {
  //       return {
  //         Id: tab.TabName,
  //         Name: tab.TabName,
  //         Component: Labels[tab.Component],
  //       };
  //     });
  //     setTabLabels(tabs);
  //   }
  // }, [TabInfo]);

  return (
    <div className="dashboard__alerts custom-tabs">
      {TabInfo && TabInfo.length > 0 && (
        <CenteredTabs
          tabsData={TabInfo}
          labels={Labels}
          baseTabUrl="/client-roster?tab="
        />
      )}
    </div>
  );
};
export default TabsSection;
