import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import CustomTable from "../../custom-component/custom-table";
import {
  dynamicGridColumnConfigurationGet,
  dynamicGridDataGet,
  getBreathalyzerTestUpcoming,
  resetBreathalyzerTestUpcomingToggle,
} from "../../../../redux/actions";

export const UpComingTest = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(
    //   dynamicGridColumnConfigurationGet({
    //     Json: JSON.stringify({
    //       ComponentGrid: [
    //         {
    //           Name: "BreathalyzerUpcomingTest",
    //         },
    //       ],
    //     }),
    //   })
    // );

    dispatch(
      getBreathalyzerTestUpcoming({
        Breathalyzer: [
          {
            ClientId: props.ClientId,
          },
        ],
      })
    );
  }, []);

  const columns2 = [
    {
      dataField: "Date",
      text: "Date",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "Time",
      text: "Time",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },

    {
      dataField: "Location",
      text: "Location",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "Result",
      text: "Result",
      sort: true,
      formatter: "RedGreenBGFormatter",

      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "Remarks",
      text: "Remarks",
      sort: true,
      formatter: "RedGreenTextFormatter",
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },

    {
      dataField: "Actions",
      text: "Actions",
      formatter: "ActionsColumnFormatter",
      // formatter: columnFormatters.ActionsColumnFormatter,
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
  ];

  const columns = [
    {
      dataField: "TestDate",
      text: "Date",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Date",
      sortField: "TestDateForSort",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: true,
    },
    {
      dataField: "TestTime",
      text: "Time",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "TestTime",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: true,
    },
    {
      dataField: "TestDayOfWeek",
      text: "Day",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "TestDayOfWeek",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: true,
    },
    {
      dataField: "Actions",
      text: "Actions",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Action",
      sortField: "Action",
      width: 80,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: "ActionsColumnFormatter",
      csvExport: false,
    },
  ];

  const { BreathalyzerTestUpcoming, isTestCanceled } = useSelector(
    ({
      get_breathalyzer_test_upcoming: { data: ListData },
      breathalyzer_test_upcoming_toggle: { data },
    }) => {
      return {
        BreathalyzerTestUpcoming: ListData?.Data?.Tests || [],
        isTestCanceled: data?.ResponseCode === 1,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (isTestCanceled) {
      // toggleDrawer("right", false);
      dispatch(
        getBreathalyzerTestUpcoming({
          Breathalyzer: [
            {
              ClientId: props.ClientId,
            },
          ],
        })
      );
      dispatch(resetBreathalyzerTestUpcomingToggle({}));
    }
  }, [isTestCanceled]);
  return (
    <>
      <div className="d-flex" style={{ justifyContent: "space-evenly" }}></div>

      {BreathalyzerTestUpcoming && (
        <div className="member-trend">
          {BreathalyzerTestUpcoming && (
            <CustomTable
              // tableTitle="Member Trends"
              entities={BreathalyzerTestUpcoming || []}
              columns={columns || []}
              isCardHeader={false}
              pagination={true}
              // isLoading={isFetching}
              bordered={false}
              // selectRow={selectRow}
              // rowEvents={rowEvents}
              tableOptions={{
                // PageSize,
                // IsRowClick,
                // IsExcelExport,
                // IsColumnSelectionEnable,
                FileName: "Upcoming History",
              }}
              toggleDrawer={false}
              rowStyle={{
                backgroundColor: "#F7F8FA",
                margin: "12px 0px",
                boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                borderRadius: "2px",
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
