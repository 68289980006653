import React from "react";
import { Redirect } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { Layout } from "../../../_metronic/layout";

const checkAuthorization = () => {
  const storedToken = localStorage.getItem("dr:token");
  if (storedToken) {
    return true;
  }
  return false;
};

export const PrivateRoute = ({ component: Component, ...rest }) =>
  checkAuthorization() ? (
    <ContentRoute
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  ) : (
    <Redirect to="/" />
  );
