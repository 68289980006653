import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";

// import { getDate, getLocalDate } from "../../util/date";
import { getDate, getLocalDate } from "../../util/date";
const CustomDatepicker = ({ onChange, date, value }) => {
	var props = {
		onChange: onChange,
		dateFormat: "dd-MMM-yyyy",
	};
	if (date) {
		props.selected = date; //moment(date, 'YYYY/MM/DD');
	}
	return <DatePicker {...props} />;
};

export default CustomDatepicker;
const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		// marginLeft: theme.spacing(1),
		// marginRight: theme.spacing(1),
		width: "100%",
		// "padding-top": "16px",
	},
}));

const ReduxDateInput = ({
	input,
	name,
	id,
	value,
	onChange,
	meta,
	errorMessage,
	minDate,
	disabled,
	tabIndex,
	label,
	fieldName,
	autoComplete,
	labelText,
}) => {
	const classes = useStyles();
	const datePickerRef = useRef(null);
	const { value: defaultValue } = input || {};
	const { touched, error, warning, active } = meta || {};
	var props = {
		style: { display: "block" },
		value,
	};

	if (input) {
		onChange = input.onChange;
	}

	if (input.value) {
		if (typeof input.value == "object") {
			value = getLocalDate(input.value); //moment(input.value, 'YYYY/MM/DD');
		} else {
			value = getDate(input.value);
		}
	}

	return (
		<div>
			<FormControl
				fullWidth
				className="formControl"
				error={touched && error}
			>
				{/* <InputLabel htmlFor={id}>{labelText}</InputLabel> */}
				{/* <label
          class="placeholder-label datepicker-label"
          style={{
            zIndex: 1,
            fontSize: !!disabled || value || active ? "11px" : "16px",
            top: !!disabled || value || active ? "-5px" : "10px",
          }}
        >
          {label}
        </label> */}
				{/* <TextField
          fullWidth
          {...props}
          id="date"
          label={labelText}
          type="date"
          defaultValue={defaultValue}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChange}
          // selected={value}
          autoComplete={autoComplete}
        /> */}
				<InputLabel htmlFor={id}>{labelText}</InputLabel>
				<DatePicker
					{...props}
					autoComplete={autoComplete}
					name={name}
					tabIndex={tabIndex}
					id={id}
					showOnInputClick
					onChange={onChange}
					selected={value}
					minDate={minDate}
					disabled={disabled}
					showPopperArrow={false}
					showYearDropdown
					popperProps={{
						positionFixed: true,
					}}
					className={classes.textField}
					ref={datePickerRef}
					isClearable
					customInput={
						<InputMask
							mask="99/99/9999"
							className="w-100"
							onClick={() => {
								datePickerRef.current.setOpen(true);
							}}
							onChange={onChange}
						>
							{(inputProps) => {
								return (
									<OutlinedInput
										{...inputProps}
										style={{
											height: "36px",
											// marginTop: "4px",
										}}
									/>
								);
							}}
						</InputMask>
					}
					alwaysShowMask
					// popperPlacement="bottom-end"
					// popperModifiers={{
					//   offset: {
					//     enabled: true,
					//     offset: '5px, 10px',
					//   },
					//   preventOverflow: {
					//     enabled: true,
					//     escapeWithReference: false,
					//     boundariesElement: 'viewport',
					//   },
					// }}
				/>

				{touched &&
					((error && fieldName && (
						<FormHelperText id="component-error-text">
							{" "}
							{fieldName} {error}
						</FormHelperText>
					)) ||
						(warning && (
							<span className="warning-color">{warning}</span>
						)))}
			</FormControl>
		</div>
	);
};

export { ReduxDateInput };
