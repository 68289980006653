import React from "react";
import { v4 as uuid } from "uuid";
export const BreathalyzerTestResultFormatter = (cellContent, row) => {
  return (
    <div>
      {cellContent &&
        cellContent.map((item) => {
          return (
            <span
              style={{
                display: "inline",
              }}
            >
              <span
                className={`${item.ResultClassBlock} block`}
                key={uuid()}
              ></span>
            </span>
          );
        })}
    </div>
  );
};
