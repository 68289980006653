import { all, put, takeLatest, call } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";

export function* listStudents(action) {
  try {
    const { payload } = action;
    const response = yield API.listStudents(payload);
    yield put({
      type: ActionTypes.STUDENTS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENTS_FAILURE,
    });
  }
}

export function* getStudent(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudent(payload);

    yield put({
      type: ActionTypes.STUDENT_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Student[0],
    });

    /*yield put({
      type: ActionTypes.TODO_GET_ALL_BY_STUDENT_REQUEST,
      payload: payload,
    });

    yield put({
      type: ActionTypes.ACTIVITY_GET_ALL_BY_STUDENT_REQUEST,
      payload: payload,
    });

    yield put({
      type: ActionTypes.STUDENT_GET_ALL_COURSES_REQUEST,
      payload: payload,
    });*/
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_GET_FAILURE,
    });
  }
}

export function* addStudent(action) {
  try {
    const { payload } = action;
    const response = yield API.addStudent(payload);
    const studentId = response.data.result.StudentId;
    const surveyId = response.data.result.SurveyId;
    const enrollStatusId = response.data.result.EnrollStatusId;
    const oldEnrollStatusId = response.data.result.OldEnrollStatusId;
    if (oldEnrollStatusId != enrollStatusId && surveyId) {
      /*const response2 = yield API.surveyAssign({
        studentId: parseInt(studentId),
        surveyQuestionCategoryId: 1,
        dueDate: null,
      });
      const surveyId = response2.data.result;*/
      yield put({
        type: ActionTypes.STUDENT_ADD_SUCCESS,
        payload: {
          studentId: studentId,
          surveyId: surveyId,
          success: true,
          enrollStatusId: enrollStatusId,
          oldEnrollStatusId: oldEnrollStatusId,
        },
      });
    } else {
      yield put({
        type: ActionTypes.STUDENT_ADD_SUCCESS,
        payload: {
          studentId: studentId,
          success: true,
          enrollStatusId: enrollStatusId,
          oldEnrollStatusId: oldEnrollStatusId,
        },
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_ADD_FAILURE,
    });
  }
}

export function* studentReinstate(action) {
  try {
    const { payload } = action;
    const response = yield API.studentReinstate(payload);
    yield put({
      type: ActionTypes.STUDENT_REINSTATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_REINSTATE_FAILURE,
    });
  }
}

export function* genericFunction(action) {
  const { payload, method, successType, failureType } = action;
  try {
    const response = yield call(method, payload);
    yield put({
      type: successType,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: failureType,
    });
  }
}

export function* getCourses(action) {
  try {
    const { payload } = action;
    const response = yield API.getCourses(payload);
    yield put({
      type: ActionTypes.STUDENT_GET_ALL_COURSES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_GET_ALL_COURSES_FAILURE,
    });
  }
}

export function* upsertStudentPin(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertStudentPin(payload);
    yield put({
      type: ActionTypes.STUDENT_PIN_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_PIN_UPSERT_FAILURE,
    });
  }
}

export function* validateStudentPin(action) {
  try {
    const { payload } = action;
    const response = yield API.validateStudentPin(payload);
    yield put({
      type: ActionTypes.STUDENT_VALIDATE_PIN_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_VALIDATE_PIN_FAILURE,
    });
  }
}

export function* studentMarkComplete(action) {
  try {
    const { payload } = action;
    const response = yield API.studentMarkComplete(payload);
    yield put({
      type: ActionTypes.STUDENT_MARK_COMPLETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_MARK_COMPLETE_FAILURE,
    });
  }
}

export function* studentUnAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.studentUnAssign(payload);
    yield put({
      type: ActionTypes.STUDENT_UNASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_UNASSIGN_FAILURE,
    });
  }
}

export function* studentDischarge(action) {
  try {
    const { payload } = action;
    const response = yield API.studentDischarge(payload);
    yield put({
      type: ActionTypes.STUDENT_DISCHARGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_DISCHARGE_FAILURE,
    });
  }
}

export function* assignCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.courseAssign(payload);
    yield put({
      type: ActionTypes.STUDENT_COURSE_ASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_COURSE_ASSIGN_FAILURE,
    });
  }
}

export function* getStudentProfileDashboardData(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentProfileDashboardData(payload);
    yield put({
      type: ActionTypes.STUDENT_GET_PROFILE_DASHBOARD_DATA_SUCCESS,
      payload: JSON.parse(response.data.data).Student[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_GET_PROFILE_DASHBOARD_DATA_FAILURE,
    });
  }
}

export function* addFavoriteTemplate(action) {
  try {
    const response = yield API.addFavoriteTemplate(action.payload);
    yield put({
      type: ActionTypes.ADD_FAVORITE_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_FAVORITE_TEMPLATE_FAILURE,
    });
  }
}

export function* removeFavoriteTemplate(action) {
  try {
    const response = yield API.removeFavoriteTemplate(action.payload);
    yield put({
      type: ActionTypes.REMOVE_FAVORITE_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.REMOVE_FAVORITE_TEMPLATE_FAILURE,
    });
  }
}

export function* addNewApplicant(action) {
  try {
    const { payload } = action;
    const response = yield API.addNewApplicant(payload);
    yield put({
      type: ActionTypes.ADD_NEW_APPLICANT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_NEW_APPLICANT_FAILURE,
    });
  }
}

export function* getClientCounselor(action) {
  try {
    const { payload } = action;
    const response = yield API.getClientCounselor(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_COUNSELOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_COUNSELOR_FAILURE,
    });
  }
}

export function* markSystemObservation(action) {
  try {
    const { payload } = action;
    const response = yield API.markSystemObservation(payload);
    yield put({
      type: ActionTypes.MARK_SYSTEM_OBSERVATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MARK_SYSTEM_OBSERVATION_FAILURE,
    });
  }
}

export function* getNotes(action) {
  try {
    const { payload } = action;
    const response = yield API.getNotes(payload);
    yield put({
      type: ActionTypes.GET_NOTES_SUCCESS,
      payload: JSON.parse(response.data.data).Student[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_NOTES_FAILURE,
    });
  }
}

export function* upsertPin(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertPin(payload);
    yield put({
      type: ActionTypes.UPSERT_PIN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_PIN_FAILURE,
    });
  }
}

export function* validateStaffPin(action) {
  try {
    const { payload } = action;
    const response = yield API.validateStaffPin(payload);
    yield put({
      type: ActionTypes.VALIDATE_STAFF_PIN_SUCCESS,
      payload: JSON.parse(response.data.data)[0].Staff[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.VALIDATE_STAFF_PIN_FAILURE,
    });
  }
}
export function* getStudentNote(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentNote(payload);
    yield put({
      type: ActionTypes.GET_STUDENT_NOTE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_NOTE_FAILURE,
    });
  }
}
export function* getStudentNoteComment(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentNoteComment(payload);
    yield put({
      type: ActionTypes.GET_STUDENT_NOTE_COMMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_NOTE_COMMENT_FAILURE,
    });
  }
}
export function* addStudentNoteComment(action) {
  try {
    const { payload } = action;
    const response = yield API.addStudentNoteComment(payload);
    yield put({
      type: ActionTypes.ADD_STUDENT_NOTE_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_STUDENT_NOTE_COMMENT_FAILURE,
    });
  }
}
export function* addStudentNoteEmoji(action) {
  try {
    const { payload } = action;
    const response = yield API.addStudentNoteEmoji(payload);
    yield put({
      type: ActionTypes.ADD_STUDENT_NOTE_EMOJI_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_STUDENT_NOTE_EMOJI_FAILURE,
    });
  }
}
export function* getStudentNoteEmoji(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentNoteEmoji(payload);
    yield put({
      type: ActionTypes.GET_STUDENT_NOTE_EMOJI_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_NOTE_EMOJI_FAILURE,
    });
  }
}

export function* getFilters(action) {
  try {
    const { payload } = action;
    const response = yield API.getFilters(payload);
    yield put({
      type: ActionTypes.GET_FILTERS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_FILTERS__FAILURE,
    });
  }
}

export function* getContract(action) {
  try {
    const response = yield API.getContract(action.payload);
    if (response.data.data)
      yield put({
        type: ActionTypes.GET_CONTRACT_SUCCESS,
        payload: JSON.parse(response.data.data).StudentContract[0],
      });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CONTRACT_FAILURE,
    });
  }
}

export function* upsertContract(action) {
  try {
    const response = yield API.upsertContract(action.payload);
    yield put({
      type: ActionTypes.UPSERT_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CONTRACT_FAILURE,
    });
  }
}

export function* deleteContract(action) {
  try {
    const response = yield API.deleteContract(action.payload);
    yield put({
      type: ActionTypes.DELETE_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_CONTRACT_FAILURE,
    });
  }
}

export function* getContractTemplate(action) {
  try {
    const response = yield API.getContractTemplate(action.payload);
    yield put({
      type: ActionTypes.GET_CONTRACT_TEMPLATE_SUCCESS,
      payload: JSON.parse(response.data.data).ContractTemplate,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}

export function* upsertContractTemplate(action) {
  try {
    const response = yield API.upsertContractTemplate(action.payload);
    yield put({
      type: ActionTypes.UPSERT_CONTRACT_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}

export function* getStudentContractTemplate(action) {
  try {
    const response = yield API.getStudentContractTemplate(action.payload);
    yield put({
      type: ActionTypes.GET_STUDENT_CONTRACT_TEMPLATE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}

export function* createContractFromTemplate(action) {
  try {
    const response = yield API.createContractFromTemplate(action.payload);
    yield put({
      type: ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_FAILURE,
    });
  }
}

export function* closeContract(action) {
  try {
    const { payload } = action;
    const response = yield API.closeContract(payload);
    yield put({
      type: ActionTypes.CLOSE_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLOSE_CONTRACT_FAILURE,
    });
  }
}

export function* menuContractTemplate(action) {
  try {
    const response = yield API.menuContractTemplate(action.payload);
    yield put({
      type: ActionTypes.MENU_CONTRACT_TEMPLATE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MENU_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}

export function* archiveContractTemplate(action) {
  try {
    const response = yield API.archiveContractTemplate(action.payload);
    yield put({
      type: ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.STUDENTS_REQUEST, listStudents),
    takeLatest(ActionTypes.STUDENT_GET_REQUEST, getStudent),
    takeLatest(ActionTypes.STUDENT_ADD_REQUEST, addStudent),
    takeLatest(ActionTypes.STUDENT_NOTE_ADD_REQUEST, genericFunction),
    takeLatest(ActionTypes.STUDENT_PIN_UPSERT_REQUEST, upsertStudentPin),
    takeLatest(ActionTypes.STUDENT_VALIDATE_PIN_REQUEST, validateStudentPin),
    takeLatest(ActionTypes.STUDENT_GET_ALL_COURSES_REQUEST, getCourses),
    takeLatest(ActionTypes.STUDENT_MARK_COMPLETE_REQUEST, studentMarkComplete),
    takeLatest(ActionTypes.STUDENT_UNASSIGN_REQUEST, studentUnAssign),
    takeLatest(ActionTypes.STUDENT_DISCHARGE_REQUEST, studentDischarge),
    takeLatest(ActionTypes.STUDENT_COURSE_ASSIGN_REQUEST, assignCourse),
    takeLatest(
      ActionTypes.STUDENT_GET_PROFILE_DASHBOARD_DATA_REQUEST,
      getStudentProfileDashboardData
    ),
    takeLatest(ActionTypes.ADD_NEW_APPLICANT_REQUEST, addNewApplicant),
    takeLatest(ActionTypes.STUDENT_REINSTATE_REQUEST, studentReinstate),
    takeLatest(ActionTypes.GET_CLIENT_COUNSELOR_REQUEST, getClientCounselor),
    takeLatest(
      ActionTypes.MARK_SYSTEM_OBSERVATION_REQUEST,
      markSystemObservation
    ),
    takeLatest(ActionTypes.GET_NOTES_REQUEST, getNotes),
    takeLatest(ActionTypes.UPSERT_PIN_REQUEST, upsertPin),
    takeLatest(ActionTypes.VALIDATE_STAFF_PIN_REQUEST, validateStaffPin),
    takeLatest(ActionTypes.GET_STUDENT_NOTE_REQUEST, getStudentNote),
    takeLatest(
      ActionTypes.GET_STUDENT_NOTE_COMMENT_REQUEST,
      getStudentNoteComment
    ),
    takeLatest(
      ActionTypes.ADD_STUDENT_NOTE_COMMENT_REQUEST,
      addStudentNoteComment
    ),
    takeLatest(ActionTypes.ADD_STUDENT_NOTE_EMOJI_REQUEST, addStudentNoteEmoji),
    takeLatest(ActionTypes.GET_STUDENT_NOTE_EMOJI_REQUEST, getStudentNoteEmoji),
    takeLatest(ActionTypes.GET_FILTERS_REQUEST, getFilters),
    takeLatest(ActionTypes.GET_CONTRACT_REQUEST, getContract),
    takeLatest(ActionTypes.UPSERT_CONTRACT_REQUEST, upsertContract),
    takeLatest(ActionTypes.DELETE_CONTRACT_REQUEST, deleteContract),
    takeLatest(ActionTypes.GET_CONTRACT_TEMPLATE_REQUEST, getContractTemplate),
    takeLatest(
      ActionTypes.UPSERT_CONTRACT_TEMPLATE_REQUEST,
      upsertContractTemplate
    ),
    takeLatest(
      ActionTypes.GET_STUDENT_CONTRACT_TEMPLATE_REQUEST,
      getStudentContractTemplate
    ),
    takeLatest(
      ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_REQUEST,
      createContractFromTemplate
    ),
    takeLatest(ActionTypes.ADD_FAVORITE_TEMPLATE_REQUEST, addFavoriteTemplate),
    takeLatest(
      ActionTypes.REMOVE_FAVORITE_TEMPLATE_REQUEST,
      removeFavoriteTemplate
    ),
    takeLatest(ActionTypes.CLOSE_CONTRACT_REQUEST, closeContract),
    takeLatest(
      ActionTypes.MENU_CONTRACT_TEMPLATE_REQUEST,
      menuContractTemplate
    ),
    takeLatest(
      ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_REQUEST,
      archiveContractTemplate
    ),
  ]);
}
