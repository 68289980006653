import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* comboListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.comboListGet(payload);
    yield put({
      type: ActionTypes.COMBO_LIST_GET_SUCCESS,
      payload: response.data.Data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_LIST_GET_FAILURE,
    });
  }
}

export function* centerOptionGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerOptionGet(payload);
    yield put({
      type: ActionTypes.CENTER_OPTION_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_OPTION_GET_FAILURE,
    });
  }
}

export function* serviceCodeListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.serviceCodeListGet(payload);
    yield put({
      type: ActionTypes.SERVICE_CODE_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SERVICE_CODE_LIST_GET_FAILURE,
    });
  }
}

export function* counselorListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.counselorListGet(payload);
    yield put({
      type: ActionTypes.COUNSELOR_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COUNSELOR_LIST_GET_FAILURE,
    });
  }
}

export function* facilityListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.facilityListGet(payload);
    yield put({
      type: ActionTypes.FACILITY_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.FACILITY_LIST_GET_FAILURE,
    });
  }
}
export function* getOneCallTimeSlots(action) {
  try {
    const { payload } = action;
    const response = yield API.getOneCallTimeSlots(payload);
    yield put({
      type: ActionTypes.GET_ONE_CALL_TIMESLOTS_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ONE_CALL_TIMESLOTS_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.COMBO_LIST_GET_REQUEST, comboListGet),
    takeLatest(ActionTypes.CENTER_OPTION_GET_REQUEST, centerOptionGet),
    takeLatest(ActionTypes.SERVICE_CODE_LIST_GET_REQUEST, serviceCodeListGet),
    takeLatest(ActionTypes.COUNSELOR_LIST_GET_REQUEST, counselorListGet),
    takeLatest(ActionTypes.FACILITY_LIST_GET_REQUEST, facilityListGet),
    takeLatest(ActionTypes.GET_ONE_CALL_TIMESLOTS_REQUEST, getOneCallTimeSlots),
  ]);
}
