import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
  DropdownCustomToggler,
  DropdownMenu,
} from "../../../../_metronic/_partials/dropdowns";

export const MoreItem = (props) => {
  const history = useHistory();
  const { More } = props;
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-hover-light-primary btn-sm btn-icon"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-ver" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu more={More} />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
