import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ManageSchedule from "./manageSchedule";
import { TestHistory } from "./testHistory";
import { UpComingTest } from "./upcomingTests";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { getClientId } from "../../../util/getParam";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const tabLabels = [
  {
    Name: "Test History",
    Component: () => (
      <TestHistory
        ClientId={getClientId()}
        // {...props}
      />
    ),
  },
  {
    Name: "Upcoming Tests",
    Component: () => (
      <UpComingTest
        ClientId={getClientId()}
        // {...props}
      />
    ),
  },
  {
    Name: "Manage Schedule",
    Component: () => (
      <ManageSchedule
        ClientId={getClientId()}
        // {...props}
      />
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const Tabs1 = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { ClientId, moreItem } = props;
  const [value, setValue] = React.useState(0);
  const [tabMapText, setTabMapText] = React.useState({
    0: "test-history",
    1: "upcoming-tests",
    2: "manage-schedule",
  });
  const [tabMapValue, setTabMapValue] = React.useState({
    "test-history": 0,
    "upcoming-tests": 1,
    "manage-schedule": 2,
  });

  const getTabNumber = (tab) => {
    return tabMapValue[tab];
  };
  useEffect(() => {
    var param = new URLSearchParams(history.location.search);
    var tab = param.get("tab") || "test-history";
    setValue(getTabNumber(tab));
  }, []);
  let TABURL = `${props.baseUrl}/?id=${ClientId ||
    moreItem.ClientId}&drawer=true`;
  debugger;
  function handleChange(event, newValue) {
    {
      newValue == 0 && history.push(`${TABURL}&tab=test-history`);
    }
    {
      newValue == 1 && history.push(`${TABURL}&tab=upcoming-tests`);
    }
    {
      newValue == 2 && history.push(`${TABURL}&tab=manage-schedule`);
    }

    setValue(newValue);
    event.stopPropagation();
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        className="drawer-tabs"
      >
        {tabLabels &&
          tabLabels.map((tab, index) => {
            let { Name, Component } = tab;
            return <Tab label={Name} ID={ClientId || moreItem.ClientId} />;
          })}
      </Tabs>
      {tabLabels &&
        tabLabels.map((tab, index) => {
          let { Name, Component } = tab;
          return (
            <>
              <TabPanel value={value} index={index}>
                <Component
                  moreItem={props.moreItem}
                  ID={ClientId || moreItem.ClientId}
                />
              </TabPanel>
            </>
          );
        })}
    </>
  );
};
