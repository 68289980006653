import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import GenericTable from "../../components/generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  getDynamicGridFilter,
  populationActivitiesClientListGet,
} from "../../../redux/actions";
import { TimeTravelDrawer } from "./drawer";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

const TimeTravel = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, moreItems) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };

  const getCenterId = () => {
    let param = new URLSearchParams(history.location.search);
    let centerId = param.get("id");
    if (centerId) {
      centerId = parseInt(centerId);
    } else {
      centerId = null;
    }
    return centerId;
  };
  useEffect(() => {
    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "TimeLine",
          },
        ],
      })
    );
    dispatch(
      populationActivitiesClientListGet({
        Client: [
          {
            Tab: "TimeLine",
          },
        ],
      })
    );
    dispatch(
      getDynamicGridFilter({
        Json: JSON.stringify({
          ComponentGrid: [
            {
              Name: "TimeLine",
            },
          ],
        }),
      })
    );
  }, []);
  const onFilter = (filterValue, toggleFilterDrawer) => {
    let data = {
      Json: JSON.stringify({
        ComponentGrid: [
          {
            Name: "Staff",
            ...filterValue,
          },
        ],
      }),
    };

    dispatch(dynamicGridColumnConfigurationGet(data));
    toggleFilterDrawer("right", false)("no needs");
  };
  // const { dynamicGridColumnData, isFetching, dynamicGridData } = useSelector(
  //   ({
  //     dynamic_grid_column_configuration_get,
  //     population_activities_client_list_get,
  //   }) => {
  //     return {
  //       dynamicGridData:
  //         population_activities_client_list_get.data &&
  //         population_activities_client_list_get.data,
  //       dynamicGridColumnData:
  //         dynamic_grid_column_configuration_get.data &&
  //         dynamic_grid_column_configuration_get.data[0],
  //     };
  //   },
  //   shallowEqual
  // );
  const { dynamicGridColumnData, isFetching, dynamicGridData } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data: gridColumns },
      population_activities_client_list_get: { data: gridData },
    }) => {
      return {
        dynamicGridColumnData: gridColumns,
        dynamicGridData: gridData?.Activities,
      };
    },
    shallowEqual
  );
  const {
    PageSize,
    IsRowClick,
    IsFilter,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = dynamicGridData || [];
  return (
    <div className="pt-5">
      <div className="time-travel-tabs">
        {Columns && DataSource && (
          <GenericTable
            // tableTitle="Member Trends"
            entities={DataSource || []}
            columns={Columns || []}
            isCardHeader={false}
            pagination={true}
            isLoading={isFetching}
            bordered={false}
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            tableOptions={{
              PageSize,
              IsRowClick,
              IsExcelExport,
              IsColumnSelectionEnable,
              TableButtons,
              IsFilter,
              FileName: "TimeTravel",
            }}
            toggleDrawer={toggleDrawer}
            onFilter={onFilter}
          />
        )}
      </div>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false, {})}
      >
        <div role="presentation" style={{ width: "800px" }}>
          <TimeTravelDrawer
            toggleDrawer={toggleDrawer}
            StaffId={moreItem.StaffId}
            moreItem
          />
        </div>
      </Drawer>
    </div>
  );
};
export default TimeTravel;
