import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../_metronic/_helpers";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
// import SVG from "react-inlinesvg";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import {
  clientsNotParticipatingInGroup,
  clientGroupGet,
  clientGroupUpsert,
} from "../../../redux/actions";
import _Remove from "./../../images/Shape.svg";
import _blank from "./../../images/blank-profile.png";
import _Close from "./../../images/close.svg";

import { ViewNonParticipantsDrawer } from "./drawer/ViewNonParticipantsDrawer";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
  button: {
    display: "block",
    margin: "0 auto",
  },
}));
const Patients = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isClientUpsert, setClientUpsert] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openRemoveClientDialog, setOpenRemoveClientDialog] = useState(false);
  const [clientInfo, setClientInfo] = useState({
    clientId: null,
    clientGroupId: null,
  });

  let checkedArray = [];
  const notParticipatingClientsUpsert = (checkedArray) => {
    // props.toggleDrawer("right", false, {});
    if (checkedArray.toString().length > 0) {
      dispatch(
        clientGroupUpsert({
          ClientGroup: [
            {
              ClientGroupId: null,
              GroupId: props.GroupId,
              ClientIds: checkedArray,
              IsAdd: true,
            },
          ],
        })
      );
    }
  };
  const handleChecked = (ClientGroupId, event) => {
    // if (event.persist()) {
    event.persist();
    // }
    if (event.target.checked) {
      if (checkedArray.includes(event.target.value)) {
        return;
      } else {
        checkedArray.push(event.target.value);
      }
    } else if (!event.target.checked) {
      const index = checkedArray.indexOf(event.target.value);
      if (index > -1) {
        checkedArray.splice(index, 1);
      }
    }
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenRemoveClientDialog(false);
  };
  const toggleDrawer = (side, open, moreItems) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };
  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    props.StaffId ? "Edit Clinical Group" : "New Clinical Group"
  );

  useEffect(() => {
    dispatch(
      clientGroupGet({
        ClientGroup: [
          {
            GroupId: props.GroupId,
          },
        ],
      })
    );
  }, []);
  // const []
  const removeindividual = (isOpen, clientId, clientGroupId) => {
    if (isOpen) {
      setOpenRemoveClientDialog(isOpen);
      setClientInfo({
        clientId: clientId,
        clientGroupId: clientGroupId,
      });
    }
  };

  const { clientsList, isFetching, clientInGroup, isUpsert } = useSelector(
    (state) => {
      const {
        clients_not_participating_in_group: { data },
        client_group_get: { data: clientGroup, isFetching: isGroupFetching },
        client_group_upsert: { data: clientGUpsert },
      } = state;
      if (data?.Data?.Clients) {
        // debugger;
      }
      return {
        clientsList: data?.Data?.Clients || [],
        clientInGroup: clientGroup?.Data?.ClientGroup || [],
        isUpsert: clientGUpsert?.ResponseCode === 1,
        isFetching: isGroupFetching,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    if (isUpsert) {
      dispatch(
        clientGroupGet({
          ClientGroup: [
            {
              GroupId: props.GroupId,
            },
          ],
        })
      );
    }
  }, [isUpsert]);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody>
              <div className="row">
                <span className="col-6">Select Clients to Enroll</span>
                {clientInGroup.length > 0 && (
                  <span
                    className="col-6 text-right"
                    // onClick={toggleDrawer("right", true, clientsList)}
                    onClick={() => setOpenDialog(true)}
                    style={{ color: "#009EF7", cursor: "pointer" }}
                  >
                    ADD CLIENT
                  </span>
                )}
                <div className="col-12">
                  <div className="invite-box p-4">
                    <div class="row">
                      {clientInGroup &&
                        clientInGroup.map((item) => (
                          <div
                            class="d-flex align-items-center m-1 p-3"
                            style={{
                              backgroundColor: "#F7F8FA",
                              width: "fit-content",
                            }}
                          >
                            <img
                              src={item.ProfileImgUrl || _blank}
                              alt=""
                              style={{
                                height: "42px",
                                width: "42px",
                                borderRadius: "50%",
                              }}
                            />
                            <p className="ml-2 mb-0">{item.DisplayName}</p>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                // removeClient(item.ClientId, item.ClientGroupId)
                                removeindividual(
                                  true,
                                  item.ClientId,
                                  item.ClientGroupId
                                )
                              }
                            >
                              <SVG
                                className="ml-2"
                                src={toAbsoluteUrl(_Remove)}
                              />
                            </span>
                          </div>
                        ))}
                    </div>
                    <div className="h-100 d-flex justify-content-center align-items-center">
                      {clientInGroup.length === 0 && (
                        <div>
                          {isFetching ? (
                            <CircularProgress color="secondary" />
                          ) : (
                            <Button
                              variant="outlined"
                              color="secondary"
                              className={classes.button}
                              onClick={() => {
                                setOpenDialog(true);
                                dispatch(
                                  clientsNotParticipatingInGroup({
                                    Client: [
                                      {
                                        Search: null,
                                        GroupId: props.GroupId,
                                      },
                                    ],
                                  })
                                );
                              }}
                            >
                              Select Client
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
            <div
              className="d-flex justify-content-end"
              style={{ paddingRight: "31px", paddingBottom: "31px" }}
            >
              <Button
                variant="outlined"
                color="secondary"
                // className={classes.button}
                onClick={() => {
                  props.handleBack();
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className="ml-5"
                onClick={() => {
                  history.push("/community/clinical-group-list");
                }}
              >
                Done
              </Button>
            </div>
          </Card>
        </div>

        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={"md"}
          maxWidth={"md"}
        >
          <DialogTitle id="alert-dialog-title">
            {"Select Client"}

            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              // onClick={props.toggleDrawer("right", false, {})}
              onClick={handleClose}
              style={{ marginLeft: "777px" }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
            {/* </OverlayTrigger> */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ViewNonParticipantsDrawer
                toggleDrawer={toggleDrawer}
                GroupId={props.GroupId}
                moreItem
                handleClose={handleClose}
                handleChecked={handleChecked}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{
                height: "66px",
                width: "93%",
                backgroundColor: "white",
              }}
            >
              <span
                className="btn btn-primary"
                onClick={() => {
                  handleClose();
                  notParticipatingClientsUpsert(checkedArray.toString());
                }}
                style={{ cursor: "pointer", width: "16%" }}
              >
                Add
              </span>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openRemoveClientDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove client from the group?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              // className={classes.button}
              onClick={() => {
                handleClose();
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="ml-5"
              onClick={() => {
                dispatch(
                  clientGroupUpsert({
                    ClientGroup: [
                      {
                        ClientGroupId: clientInfo.clientGroupId,
                        GroupId: props.GroupId,
                        ClientIds: clientInfo.clientId.toString(),
                        IsAdd: false,
                      },
                    ],
                  })
                );
                setOpenRemoveClientDialog(false);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default Patients;
