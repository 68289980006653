import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* growthPlanTemplateUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.growthPlanTemplateUpsert(payload);
    yield put({
      type: ActionTypes.GROWTH_PLAN_TEMPLATE_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROWTH_PLAN_TEMPLATE_UPSERT_FAILURE,
    });
  }
}

export function* growthPlanTemplateMenuGet(action) {
  try {
    const { payload } = action;
    const response = yield API.growthPlanTemplateMenuGet(payload);
    yield put({
      type: ActionTypes.GROWTH_PLAN_TEMPLATE_MENU_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROWTH_PLAN_TEMPLATE_MENU_GET_FAILURE,
    });
  }
}

export function* growthPlanTemplateGet(action) {
  try {
    const { payload } = action;
    const response = yield API.growthPlanTemplateGet(payload);
    yield put({
      type: ActionTypes.GROWTH_PLAN_TEMPLATE_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROWTH_PLAN_TEMPLATE_GET_FAILURE,
    });
  }
}

export function* growthPlanTemplateFavoriteAdd(action) {
  try {
    const { payload } = action;
    const response = yield API.growthPlanTemplateFavoriteAdd(payload);
    yield put({
      type: ActionTypes.TEMPLATE_FAVORITE_ADD_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TEMPLATE_FAVORITE_ADD_FAILURE,
    });
  }
}

export function* growthPlanTemplateFavoriteDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.growthPlanTemplateFavoriteDelete(payload);
    yield put({
      type: ActionTypes.GROWTH_PLAN_TEMPLATE_FAVORITE_DELETE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROWTH_PLAN_TEMPLATE_FAVORITE_DELETE_FAILURE,
    });
  }
}

export function* growthPlanTemplateDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.growthPlanTemplateDelete(payload);
    yield put({
      type: ActionTypes.GROWTH_PLAN_TEMPLATE_DELETE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROWTH_PLAN_TEMPLATE_DELETE_FAILURE,
    });
  }
}

export function* clientGrowthPlanUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.clientGrowthPlanUpsert(payload);
    yield put({
      type: ActionTypes.CLIENT_GROWTH_PLAN_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_GROWTH_PLAN_UPSERT_FAILURE,
    });
  }
}

export function* clientGrowthPlanGet(action) {
  try {
    const { payload } = action;
    const response = yield API.clientGrowthPlanGet(payload);
    yield put({
      type: ActionTypes.CLIENT_GROWTH_PLAN_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_GROWTH_PLAN_GET_FAILURE,
    });
  }
}

export function* clientGrowthPlanListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.clientGrowthPlanListGet(payload);
    yield put({
      type: ActionTypes.CLIENT_GROWTH_PLAN_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_GROWTH_PLAN_LIST_GET_FAILURE,
    });
  }
}

export function* clientGrowthPlanFromTemplateInsert(action) {
  try {
    const { payload } = action;
    const response = yield API.clientGrowthPlanFromTemplateInsert(payload);
    yield put({
      type: ActionTypes.CLIENT_GROWTH_PLAN_FROM_TEMPLATE_INSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_GROWTH_PLAN_FROM_TEMPLATE_INSERT_FAILURE,
    });
  }
}

export function* clientGrowthPlanDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.clientGrowthPlanDelete(payload);
    yield put({
      type: ActionTypes.CLIENT_GROWTH_PLAN_DELETE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_GROWTH_PLAN_DELETE_FAILURE,
    });
  }
}

export function* clientGrowthPlanClose(action) {
  try {
    const { payload } = action;
    const response = yield API.clientGrowthPlanClose(payload);
    yield put({
      type: ActionTypes.CLIENT_GROWTH_PLAN_CLOSE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_GROWTH_PLAN_CLOSE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.GROWTH_PLAN_TEMPLATE_UPSERT_REQUEST,
      growthPlanTemplateUpsert
    ),
    takeLatest(
      ActionTypes.GROWTH_PLAN_TEMPLATE_MENU_GET_REQUEST,
      growthPlanTemplateMenuGet
    ),
    takeLatest(
      ActionTypes.GROWTH_PLAN_TEMPLATE_GET_REQUEST,
      growthPlanTemplateGet
    ),
    takeLatest(
      ActionTypes.CLIENT_GROWTH_PLAN_LIST_GET_REQUEST,
      clientGrowthPlanListGet
    ),
    takeLatest(
      ActionTypes.GROWTH_PLAN_TEMPLATE_FAVORITE_ADD_REQUEST,
      growthPlanTemplateFavoriteAdd
    ),
    takeLatest(
      ActionTypes.GROWTH_PLAN_TEMPLATE_FAVORITE_DELETE_REQUEST,
      growthPlanTemplateFavoriteDelete
    ),
    takeLatest(
      ActionTypes.GROWTH_PLAN_TEMPLATE_DELETE_REQUEST,
      growthPlanTemplateDelete
    ),
    takeLatest(
      ActionTypes.CLIENT_GROWTH_PLAN_UPSERT_REQUEST,
      clientGrowthPlanUpsert
    ),
    takeLatest(ActionTypes.CLIENT_GROWTH_PLAN_GET_REQUEST, clientGrowthPlanGet),
    takeLatest(
      ActionTypes.CLIENT_GROWTH_PLAN_DELETE_REQUEST,
      clientGrowthPlanDelete
    ),
    takeLatest(
      ActionTypes.CLIENT_GROWTH_PLAN_FROM_TEMPLATE_INSERT_REQUEST,
      clientGrowthPlanFromTemplateInsert
    ),
    takeLatest(
      ActionTypes.CLIENT_GROWTH_PLAN_CLOSE_REQUEST,
      clientGrowthPlanClose
    ),
  ]);
}
