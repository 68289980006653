import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import _CommentCount from "../../images/commentCount.svg";
import { DateWithTimeFormatter } from "../column-formatters";
import _GreenCircleTick from "../../images/green-circle-tick.svg";
import { MoreItemDropdown } from "../../components/custom-component";

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
        background: "white",
        "&:hover": {
            background: "white"
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    }
}));
export const AssigedAssessmentsCard = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const { data, index, toggleDrawer, key, markAsDiscard } = props;
    const {
      ArchivedDate,
      AssignedByStaffId,
      AssignedDate,
      ClientId,
      ClientImageUrl,
      CompletedDate,
      DueDate,
      DueStatus,
      FirstName,
      IsForm,
      IsSurveyArchived,
      IsSurveyCompleted,
      IsSurveyOverDue,
      LastName,
      PreferredName,
      Score,
      StartedDate,
      SurveyId,
      SurveyName,
      SurveyQuestionCategoryId,
      TextForCompleted,
    } = data || {};
    return (
      <div className="assessments-tabs-card" key={key}>
        <div className="assessments-card">
          <div className="completed">
            {/* <div className="score">
                          <span>Score :{Score}</span>
                        </div> */}
            <div className="submitted mb-2">
              <div className="d-flex align-items-center justify-content-center">
                <span className="submitted-text">Assigned</span>
                <span style={{ position: "absolute", right: "0px" }}>
                  {" "}
                  <MoreItemDropdown
                    item="MoreAction"
                    More={[
                      {OnClick: () => {
                          markAsDiscard(SurveyId);
                        },
                        ActionName: "Mark as Discard",
                      },
                    ]}
                  />
                </span>
              </div>
            </div>
            <span className="name">{SurveyName}</span>
            <div className="block">
              <div className="d-flex justify-content-between">
                <div className="assigned-date text-left">
                  <span className="date-text">Assigned </span>
                  <span className="date-value">{AssignedDate}</span>
                </div>
                <div className="assigned-date text-left">
                  <span className="date-text">Due </span>
                  <span className="date-value">{DueDate}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};
