import { all, fork } from "redux-saga/effects";
import student from "./student";
import survey from "./survey";
import login from "./login";
import combo from "./combo";
import todo from "./todo";
import activitylog from "./activitylog";
import dashboard from "./dashboard";
import report from "./report";
import administration from "./adminstration";
import errorhandling from "./errorhandling";
import menu from "./menu";
import userRole from "./userRole";
import licensing from "./licensing";
import appSubscribers from "./subscribers";
import appInvite from "./appInvite";
import callSession from "./callSession";
import newDashboard from "./newDashboard";
import documentsUpload from "./documentsUpload";
import rcm from "./rcm";
import counselor from "./counselor";
import genericForm from "./genericForm";
import generateCallToken from "./call";
import badge from "./badge";
import misc from "./misc";
import bed from "./bed";
import MemberTrends from "./memberTrends";
import clientV2 from "./clientV2";
//youuapi started
import youuLogin from "./youuLogin";
import youuClients from "./youuClients";
import youuCourse from "./youuCourse";
import youuTask from "./youuTask";
import youuCombo from "./youuCombo";
import youuTimeTravel from "./youuTimeTravel";
import youuDashboard from "./youuDashboard";
import youuAssessment from "./youuAssessments";
import youuIntake from "./youuIntake";
import youuJournal from "./youuJournal";
import youuTrend from "./youuTrend";
import youuCenter from "./youuCenter";
import youuGrowthPlan from "./youuGrowthPlan";
import youuStaff from "./youuStaff";
import youuSmallGroup from "./youuSmallGroup";
import youuClinicalGroups from "./youuClinicalGroups";
import youuCouponCode from "./youuCouponCode";
import youuBreathalyzer from "./youuBreathalyzer";
import youuMiscellaneous from "./youuMiscellaneous";
import youuAppointment from "./youuAppointment";

// import * as auth from "../../app/modules/Auth/_redux/authRedux";
export default function* root() {
  yield all([
    // auth.saga(),
    fork(errorhandling),
    fork(login),
    fork(student),
    fork(survey),
    fork(combo),
    fork(todo),
    fork(activitylog),
    fork(dashboard),
    fork(report),
    fork(administration),
    fork(menu),
    fork(userRole),
    fork(licensing),
    fork(appSubscribers),
    fork(appInvite),
    fork(callSession),
    fork(newDashboard),
    fork(documentsUpload),
    fork(rcm),
    fork(counselor),
    fork(generateCallToken),
    fork(badge),
    fork(misc),
    fork(bed),
    fork(MemberTrends),
    fork(clientV2),
    //youuapi started
    fork(genericForm),
    fork(youuLogin),
    fork(youuClients),
    fork(youuCourse),
    fork(youuTask),
    fork(youuCombo),
    fork(youuTimeTravel),
    fork(youuDashboard),
    fork(youuAssessment),
    fork(youuIntake),
    fork(youuJournal),
    fork(youuTrend),
    fork(youuCenter),
    fork(youuGrowthPlan),
    fork(youuStaff),
    fork(youuSmallGroup),
    fork(youuClinicalGroups),
    fork(youuCouponCode),
    fork(youuBreathalyzer),
    fork(youuMiscellaneous),
    fork(youuAppointment),
  ]);
}
