import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* couponListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.couponListGet(payload);
    yield put({
      type: ActionTypes.COUPON_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COUPON_LIST_GET_FAILURE,
    });
  }
}
export function* upsertCouponCode(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertCouponCode(payload);
    yield put({
      type: ActionTypes.UPSERT_COUPON_CODE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_COUPON_CODE_FAILURE,
    });
  }
}

export function* deleteCouponCode(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteCouponCode(payload);
    yield put({
      type: ActionTypes.DELETE_COUPON_CODE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_COUPON_CODE_FAILURE,
    });
  }
}

export function* getCouponCode(action) {
  try {
    const { payload } = action;
    const response = yield API.getCouponCode(payload);
    yield put({
      type: ActionTypes.GET_COUPON_CODE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_COUPON_CODE_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.COUPON_LIST_GET_REQUEST, couponListGet),
    takeLatest(ActionTypes.UPSERT_COUPON_CODE_REQUEST, upsertCouponCode),
    takeLatest(ActionTypes.DELETE_COUPON_CODE_REQUEST, deleteCouponCode),
    takeLatest(ActionTypes.GET_COUPON_CODE_REQUEST, getCouponCode),
  ]);
}
