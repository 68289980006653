import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { ActionsColumnFormatter } from "../../column-formatters";
import { EmotionalAffectChart } from "./emotionalAffectChart";
import CustomTable from "../../custom-component/custom-table";
import _Chat from "../../../images/bi_chat.svg";
import { toast } from "react-toastify";

export const EmotionalAffect = (className) => {
  const actionFormatter2 = (cell, row, rowIndex, formatExtraData) => {
    // const { More, Phone, chat } = row.Actions[0];
    return (
      <div className="d-flex align-items-center justify-content-around">
        <div className="">
          {/* <Chat row={row} /> */}
          <div className="">
            Hello World
            {/* <img
              style={{ cursor: "pointer", display: "block" }}
              src={_Chat}
              alt="Chat"
              width="15px"
            />{" "} */}
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "Date",
      text: "Date",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "Time",
      text: "Time",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },

    {
      dataField: "MoodEntry",
      text: "Mood Entry",
      sort: true,
      formatter: "RedGreenBGFormatter",
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },

    {
      dataField: "Actions",
      text: "Actions",
      formatter: "ActionsColumnFormatter",
      // formatter: columnFormatters.ActionsColumnFormatter,
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
  ];

  const { EmotionalAffectData, upsertClientNoteSuccess } = useSelector(
    ({ user_member_trend_history, upsert_client_note }) => {
      return {
        EmotionalAffectData:
          (user_member_trend_history &&
            user_member_trend_history.data &&
            user_member_trend_history.data.Data &&
            user_member_trend_history.data.Data[0] &&
            user_member_trend_history.data.Data[0].EmotionalAffect &&
            user_member_trend_history.data.Data[0].EmotionalAffect[0]) ||
          [],
        upsertClientNoteSuccess:
          (upsert_client_note &&
            upsert_client_note.data &&
            upsert_client_note.data.success) ||
          false,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (upsertClientNoteSuccess) {
      toast.success("Note added successfully");
    }
  }, [upsertClientNoteSuccess]);
  return (
    <>
      <div
        className="d-flex flex-column w-100"
        style={{
          justifyContent: "space-evenly",
        }}
      >
        {" "}
        <div className="">
          {EmotionalAffectData && EmotionalAffectData.GraphData && (
            <EmotionalAffectChart graphData={EmotionalAffectData.GraphData} />
          )}
        </div>
        {EmotionalAffectData && (
          <div className="member-trend">
            {EmotionalAffectData && EmotionalAffectData.TableData && (
              <CustomTable
                // tableTitle="Member Trends"
                entities={EmotionalAffectData.TableData || []}
                columns={columns || []}
                isCardHeader={false}
                pagination={false}
                // isLoading={isFetching}
                bordered={false}
                // selectRow={selectRow}
                // rowEvents={rowEvents}
                // tableOptions={{
                //   PageSize,
                //   IsRowClick,
                //   IsExcelExport,
                //   IsColumnSelectionEnable,
                // }}
                toggleDrawer={false}
                rowStyle={{
                  backgroundColor: "#F7F8FA",
                  margin: "12px 0px",
                  boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                  borderRadius: "2px",
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
