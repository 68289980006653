import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../../_metronic/layout";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
  growthPlanTemplateGet,
  courseStatusUpdate,
} from "../../../../../redux/actions";
import _blank from "../../../../images/blank-profile.png";
import _Phone from "../../../../images/twilioPhoneAllowed.svg";
import _Edit from "../../../../images/edit.svg";
import _Close from "../../../../images/close.svg";
import { UserHeader } from "../../../custom-component/userHeader";
const { SearchBar, ClearSearchButton } = Search;

const useStyles = makeStyles((theme) => ({
  activateButton: {
    margin: theme.spacing(1),
    color: "red",
    background: "transparent",
    border: "1px solid red",
  },
  deActivateButton: {
    margin: theme.spacing(1),
    color: "white",
    background: "blue",
    "&:hover, &:focus": {
      backgroundColor: "blue",
    },
  },
  input: {
    display: "none",
  },
}));

export const TemplateDetailDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const enableSorting = true;
  const classes = useStyles();
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("Center List");

  useEffect(() => {
    dispatch(
      growthPlanTemplateGet({
        GrowthPlan: [
          {
            GrowthPlanTemplateId: props.TemplateId,
          },
        ],
      })
    );
  }, []);

  const { GrowthPlanTemplateData, isFetching } = useSelector(
    ({ growth_plan_template_get: { data, isFetching } }) => {
      return {
        GrowthPlanTemplateData: data?.Data?.GrowthPlans?.[0],
        isFetching,
      };
    },
    shallowEqual
  );
  const {
    GrowthPlanTemplateId,
    FromClientGrowthPlanId,
    CreatedByStaffId,
    IsPublic,
    IsFavorite,
    IsMyTemplate,
    CenterId,
    TemplateName,
    Description,
    Days,
    MajorTheme,
    MajorThemeId,
    MinorThemes,
    Goals,
    BibleMemorizations,
    BibleReading,
    Lessons,
    CourseIds,
    SpecialProjects,
    IsArchived,
    DateInserted,
    DiscardedDate,
    LastUpdatedBy,
    StartDate,
    DueDate,
    AdminNotes,
    LadderAssociationId,
    LadderAssociated,
    CharacterQuality,
    PersonalReadingRecord,
  } = GrowthPlanTemplateData || {};
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-1">
          <h5>Template Details</h5>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-2 drawer-details-view drawer-template">
          
          <div className="row pb-5">
            <div className="col-11 px-0">
              <span className="title">{TemplateName}</span>
            </div>
            <div className="col-1">
              <OverlayTrigger
                overlay={<Tooltip id="edit-tooltip">Edit Template</Tooltip>}
              >
                <a
                  className="btn btn-icon btn-hover-light-primary btn-sm"
                  onClick={() => {
                    history.push(`/Template/edit?id=${props.TemplateId}`);
                  }}
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl(_Edit)} />
                  </span>
                </a>
              </OverlayTrigger>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Start Date</span>
                  <span className="c-value">
                    {StartDate && moment(StartDate).format("DD-MMM-YYYY")}
                  </span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Due Date</span>
                  <span className="c-value">
                    {DueDate && moment(DueDate).format("DD-MMM-YYYY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Ladder Associated</span>
                  <span className="c-value">{LadderAssociated}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Major Theme</span>
                  <span className="c-value">{MajorTheme}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Description</span>
                  <span className="c-value">{Description}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Minor Themes</span>
                  <span className="c-value">{MinorThemes}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Goals</span>
                  <span className="c-value">{Goals}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Bible Memorization</span>
                  <span className="c-value">{BibleMemorizations}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Bible Reading</span>
                  <span className="c-value">{BibleReading}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Personal Reading</span>
                  <span className="c-value">{PersonalReadingRecord}</span>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Special Projects</span>
                  <span className="c-value">{SpecialProjects}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Administrative Notes</span>
                  <span className="c-value">{AdminNotes}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            style={{
              boxShadow: " 0px 0px 10px 4px rgba(115, 116, 119, 0.06)",
              bordeRadius: "4px",
              padding: "15px 15px",
            }}
          >
            <h4>Summary</h4>
          </div> */}
        </div>
        {/* Footer */}
        <div className="d-flex align-items-center justify-content-between">
          <div className="text-right">
            {/* <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button> */}
            &nbsp;
            {/* {GrowthPlanTemplateId && (
              <Button
                className={
                  StatusText == "Active"
                    ? classes.deActivateButton
                    : classes.activateButton
                }
                onClick={() => {
                  dispatch(
                    courseStatusUpdate({
                      Course: [
                        {
                          CourseId,
                        },
                      ],
                    })
                  );
                }}
              >
                {StatusText == "Active" ? "Activate" : "Deactivate"}
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};
