import React, { useEffect, useState, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _Notes from "../../../images/notes.svg";
import AddNoteModal from "../Notes/addNoteModal";
export const Notes = (cell, row, rowIndex, formatExtraData) => {
  const [modalShow, setModalShow] = useState(false);
  let modalClose = () => {
    setModalShow(false);
  };

  return (
    <div className="">
      <OverlayTrigger overlay={<Tooltip id="chat-tooltip">Notes</Tooltip>}>
        <a
          className="btn btn-icon btn-hover-light-primary btn-sm"
          onClick={() => {
            setModalShow(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl(_Notes)} />
          </span>
        </a>
      </OverlayTrigger>
      <div>
        {modalShow && (
          <AddNoteModal
            show={modalShow}
            onHide={modalClose}
            RowData={cell.RowData}
          />
        )}
      </div>
    </div>
  );
};
