import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { Dropdown } from "react-bootstrap";
import { useSubheader } from "../../../../_metronic/layout";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../../../_metronic/_partials/dropdowns";
import {
  clientSurveyListGet,
  resetSurveyAssignMultiple,
} from "../../../../redux/actions";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";
import { AssignAssessmentDrawer } from "./assignAssessment";
import { FilterAssessmentDrawer } from "./filterAssessment";

import CustomTable from "../../custom-component/custom-table";
import ToggleButtons from "../../custom-component/ToggleButton";
// import CenteredTabs from "../../custom-component/custom-tabs";

const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-hover-light-primary btn-sm btn-icon"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-ver" />
        </Dropdown.Toggle>
        {/* <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu> */}
      </Dropdown>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const Assessment = (className) => {
  const [isOverDue, setOverDue] = React.useState(true);
  const [moreItem, setMoreItem] = useState({});
  const [viewType, setViewType] = useState("tile");

  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (side, open, moreItems) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };
  const suhbeader = useSubheader();
  suhbeader.setTitle("Assessment");

  useEffect(() => {
    // dispatch(
    //   dynamicGridColumnConfigurationGet({
    //     ComponentGrid: [
    //       {
    //         Name: "Center",
    //       },
    //     ],
    //   })
    // );
    // dispatch(
    //   getDynamicGridFilter({
    //     ComponentGrid: [
    //       {
    //         Name: "Center",
    //       },
    //     ],
    //   })
    // );
    dispatch(
      clientSurveyListGet({
        Assessment: [
          {
            ClientId: null,
            showOnlyOverdue: false,
            IsForm: true,
            FormType: "Assessment Forms",
          },
        ],
      })
    );
  }, []);
  const { AssessmentData, isSurveyAssign } = useSelector(
    ({
      client_survey_list_get: { data: ListData },
      survey_assign_multiple,
    }) => {
      return {
        AssessmentData: ListData?.Data?.Surveys,
        isSurveyAssign: survey_assign_multiple?.data?.ResponseCode === 1,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (isSurveyAssign) {
      dispatch(
        clientSurveyListGet({
          Assessment: [
            {
              ClientId: null,
              showOnlyOverdue: false,
              IsForm: true,
              FormType: "Assessment Forms",
            },
          ],
        })
      );
      toggleDrawer("right", false, {})("no needs");
      dispatch(resetSurveyAssignMultiple());
    }
  }, [isSurveyAssign]);
  const handleChange = (evt) => {
    setOverDue(evt.target.checked);
  };
  const handelView = (type) => {
    setViewType(type);
  };
  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    // const { More, Phone, chat } = row.Actions[0];
    return (
      <div className="d-flex align-items-center justify-content-around">
        <div className="">
          {/* <TwilioCalling row={row} /> */}
          <div className="">
            <img
              style={{ cursor: "pointer", display: "block" }}
              src={_Phone}
              alt="Calling Allowed"
              width="15px"
            />{" "}
          </div>
        </div>
        <div className="">
          {/* <Chat row={row} /> */}
          <div className="">
            <img
              style={{ cursor: "pointer", display: "block" }}
              src={_Chat}
              alt="Chat"
              width="15px"
            />{" "}
          </div>
        </div>
        <div className="">
          <ItemDropdown item="MoreAction" />
        </div>
      </div>
    );
  };
  const enableSorting = true;
  const columns = [
    {
      dataField: "PreferredName",
      text: "Client",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "PreferredName",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },

    {
      dataField: "ServiceType",
      text: "Service Type",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "ServiceType",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "LastAssessmentAssigneDate",
      text: "Assigned date",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Date",
      sortField: "LastAssessmentAssigneDate",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "LastAssessmentCompleted",
      text: "Last Assessment completed",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "LastAssessmentCompleted",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "LastAssessmentCompletedOn",
      text: "Last Assessment completed date",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Date",
      sortField: "LastAssessmentCompletedOn",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "OverDueCount",
      text: "Assessments overdue",
      sort: enableSorting,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Integer",
      sortField: "OverDueCount",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "",
      text: "Actions",
      formatter: "ActionsColumnFormatter",
      // sort: false,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
      // formatter: actionFormatter,
      // formatter: (cell, row) => {
      //   debugger;
      //   return <div className="d-flex align-items-center"></div>;
      // },
      // formatExtraData: {
      //   //   openEditProductPage: productsUIProps.openEditProductPage,
      //   //   openDeleteProductDialog: productsUIProps.openDeleteProductDialog,
      // },
      // classes: "text-right pr-0",
      // headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
    // "Actions": [
    //   {
    //     Phone: "",
    //     chat: "",
    //     More: [
    //       {
    //         ActionName: "Schedule Appointment",
    //       },
    //       {
    //         ActionName: "Assign Task",
    //       },
    //       {
    //         ActionName: "Assign Survey",
    //       },
    //       {
    //         ActionName: "Assign Course",
    //       },
    //       {
    //         ActionName: "Add Note",
    //       },
    //       {
    //         ActionName: "Share Client",
    //       }
    //     ],
    //   }
    // ],
  ];
  return (
    <div className="">
      <div className="assessments-page">
        <div className="row">
          <div className="col-4"></div>
          <div className="col-8 d-flex align-items-center justify-content-end">
            <ToggleButtons handelView={handelView} />
            {/* <div className="mr-2 over-due">
              <Checkbox
                checked={isOverDue}
                onChange={handleChange}
                value={isOverDue}
                inputProps={{
                  "aria-label": "primary checkbox",
                }}
              />
              Show Only Overdue
            </div> */}
            <div className="px-2">
              <Button
                variant="outlined"
                color="secondary"
                onClick={toggleDrawer("right", true, "Filter")}
                // className={classes.button}
              >
                Filter
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                onClick={toggleDrawer("right", true, "Assesssment")}
                // className={classes.button}
              >
                Assign Assesssment
              </Button>
            </div>
          </div>
          <div className="col-12 mt-5">
            {viewType === "tile" ? (
              <div className="assessment-section">
                {AssessmentData &&
                  AssessmentData.map((assessment) => {
                    const {
                      ClientId,
                      FirstName,
                      LastName,
                      PreferredName,
                      PendingCnt,
                      CompletedCnt,
                      ClientImageUrl,
                      ServiceType,
                      Service,
                    } = assessment;
                    return (
                      <div id="assessment-card">
                        <div className="assessment-inside">
                          <div className="image">
                            <img
                              src={ClientImageUrl || _blank}
                              className="rounded-circle face-fit-cover"
                              style={{
                                height: "82px",
                                width: "82px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div className="name">
                            <span>{PreferredName}</span>
                          </div>
                          <div className="service-type">
                            <span>{ServiceType}</span>
                          </div>
                          <div className="services">
                            <span className="tag">{Service}</span>
                            {/* <span className="tag">digital care</span> */}
                          </div>
                          <div className="due">
                            <span>{PendingCnt} Assessments Due</span>
                          </div>
                          <div className="navigate">
                            <span
                              onClick={() => {
                                history.push(
                                  `client-roster/chart?id=${ClientId}&tab=surveys`
                                );
                              }}
                            >{`Go to Assessments >`}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="card">
                <div className="card-body pt-2">
                  {AssessmentData && (
                    <CustomTable
                      // tableTitle="Member Trends"
                      entities={AssessmentData || []}
                      columns={columns || []}
                      isCardHeader={false}
                      pagination={true}
                      bordered={false}
                      // tableOptions={{
                      //   PageSize,
                      //   IsRowClick,
                      //   IsExcelExport,
                      //   IsColumnSelectionEnable,
                      // }}
                      toggleDrawer={false}
                      rowStyle={{
                        backgroundColor: "#F7F8FA",
                        margin: "12px 0px",
                        boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                        borderRadius: "2px",
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false, {})}
        >
          <div role="presentation" style={{ width: "800px" }}>
            {moreItem === "Assesssment" && (
              <AssignAssessmentDrawer
                toggleDrawer={toggleDrawer}
                // SmallGroupGuid={moreItem.SmallGroupGuid}
                // moreItem
              />
            )}
            {moreItem === "Filter" && (
              <FilterAssessmentDrawer
                toggleDrawer={toggleDrawer}
                // SmallGroupGuid={moreItem.SmallGroupGuid}
                // moreItem
              />
            )}
          </div>
        </Drawer>
      </div>
    </div>
  );
};
