import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { Helmet } from "react-helmet";
import PageTitle from "../../pageTitleConfig";
import { makeStyles } from "@material-ui/core/styles";
import GenericTable from "../../components/generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  getDynamicGridFilter,
  allGet,
} from "../../../redux/actions";
import { PreIntakeDrawer } from "./drawer";

const Payment = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, moreItems) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };

  const getCenterId = () => {
    let param = new URLSearchParams(history.location.search);
    let centerId = param.get("id");
    if (centerId) {
      centerId = parseInt(centerId);
    } else {
      centerId = null;
    }
    return centerId;
  };
  useEffect(() => {
    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "PreIntake",
          },
        ],
      })
    );
    dispatch(
      allGet({
        Client: [
          {
            IsPreIntakeList: true,
          },
        ],
      })
    );
    dispatch(
      getDynamicGridFilter({
        ComponentGrid: [
          {
            Name: "PreIntake",
          },
        ],
      })
    );
  }, []);
  const onFilter = (filterValue, toggleFilterDrawer) => {
    let data = {
      ComponentGrid: [
        {
          Name: "PreIntake",
          ...filterValue,
        },
      ],
    };

    dispatch(dynamicGridColumnConfigurationGet(data));
    toggleFilterDrawer("right", false)("no needs");
  };
  const { dynamicGridColumnData, isFetching, dynamicGridData } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data, isFetching, error },
      all_get: { data: ListData },
    }) => {
      return {
        dynamicGridColumnData: data,
        dynamicGridData: ListData?.Data?.Clients,
      };
    },
    shallowEqual
  );
  const {
    PageSize,
    IsRowClick,
    IsFilter,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = dynamicGridData || [];
  return (
    <>
      <Helmet>
        <title>{PageTitle.INTAKE_INTAKE}</title>
      </Helmet>
      <div className="pt-5">
        <div className="pre-intake-tabs">
          {Columns && DataSource && (
            <GenericTable
              // tableTitle="Member Trends"
              entities={DataSource || []}
              columns={Columns || []}
              isCardHeader={false}
              pagination={true}
              isLoading={isFetching}
              bordered={false}
              // selectRow={selectRow}
              // rowEvents={rowEvents}
              tableOptions={{
                PageSize,
                IsRowClick,
                IsExcelExport,
                IsColumnSelectionEnable,
                TableButtons,
                IsFilter,
                FileName: "PreIntake",
              }}
              toggleDrawer={toggleDrawer}
              onFilter={onFilter}
            />
          )}
        </div>

        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false, {})}
        >
          <div role="presentation" style={{ width: "800px" }}>
            <PreIntakeDrawer
              toggleDrawer={toggleDrawer}
              StaffId={moreItem.StaffId}
              moreItem
            />
          </div>
        </Drawer>
      </div>
    </>
  );
};
export default Payment;
