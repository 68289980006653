import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { Dropdown } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import { timeTravelListGet } from "../../../../redux/actions";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../../../_metronic/_partials/dropdowns";
// const list = [
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
// ];

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-hover-light-primary btn-sm btn-icon"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-ver" />
        </Dropdown.Toggle>
        {/* <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu> */}
      </Dropdown>
    </>
  );
};

export const TimeLine = (className) => {
  const dispatch = useDispatch();
  let data = {
    TimeLine: [
      {
        FilterBy: null,
        StartDate: null,
        EndDate: null,
        UserGuid: "E144B95C-FD9A-4873-9C6E-7A3D8E7BA6B8",
        IsDashboardCard: true,
      },
    ],
  };

  useEffect(() => {
    dispatch(timeTravelListGet(data));
  }, []);

  const { timeLineData } = useSelector((state) => {
    return {
      timeLineData: state.time_travel_list_get.data || [],
    };
  }, shallowEqual);

  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom page 2");

  return (
    <>
      <div className={`card card-custom card-stretch gutter-b `}>
        {/* Head */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Time Travel
          </h3>
          <div className="card-toolbar">
            <Button color="secondary" className={classes.button}>
              + Add New
            </Button>
          </div>
        </div>
        {/* Body */}
        <div className="card-body pt-2 member-trend">
          <div style={{ maxHeight: "300px", overflow: "scroll" }}>
            {timeLineData &&
              timeLineData.Event &&
              timeLineData.Event.map((item) => {
                const { DisplayName, ProfileImageUrl, Phone, Event } = item;
                const {
                  EventIcon,
                  EventTime,
                  EventTitle,
                  EventTypeId,
                  EventTypeName,
                  EventWarningTags,
                  MiscJson,
                  Service,
                  ServiceTags,
                  ServiceType,
                } = Event[0];
                return (
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "80px" }}
                    key={uuid()}
                  >
                    <div className="col-2  d-flex align-items-center flex-column">
                      <div
                        class="d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: "50%",
                          padding: "5px",
                          // objectFit: "cover",
                          background: "#F7F8FA",
                          width: "50px",
                          height: "50px",
                        }}
                      >
                        {" "}
                        <img
                          src={`../../../images/timeline/${EventIcon}`}
                          // alt="student"
                          // class="img-circle"
                          // height="30"
                          // width="30"
                        />
                      </div>
                      <span style={{ fontSize: "14px" }}> {EventTime}</span>
                    </div>

                    {/* <div className="col-2 px-1 d-flex align-items-center"></div> */}

                    <div className="col-7">
                      <div className="d-flex">
                        <div
                          class="student-list-pic"
                          style={{ flex: "0 0 15%" }}
                        >
                          <img
                            src={ProfileImageUrl || _blank}
                            // alt="student"
                            class="img-circle"
                            height="40"
                            width="40"
                            style={{
                              borderRadius: "50%",
                              padding: "2px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="row flex-column">
                          <div>
                            <span className="member-name">{DisplayName}</span>{" "}
                            <span className="member-service">{Service}</span>
                          </div>
                          <div>
                            {ServiceTags.split(",").map((tag) => {
                              return (
                                <span className="member-tag" key={uuid()}>
                                  {tag}
                                </span>
                              );
                            })}
                            {/* <p className="member-tag">{}</p> */}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          marginTop: "3px",
                        }}
                      >
                        {EventTitle}
                      </div>
                    </div>
                    <div className="col-3 px-1 ">
                      <div className="d-flex align-items-center justify-content-around">
                        <div className="">
                          <img
                            style={{ cursor: "pointer", display: "block" }}
                            src={_Phone}
                            alt="Calling Allowed"
                            width="15px"
                          />{" "}
                        </div>
                        <div className="">
                          <img
                            style={{ cursor: "pointer", display: "block" }}
                            src={_Chat}
                            alt="Chat"
                            width="15px"
                          />{" "}
                        </div>
                        <div className="">
                          <ItemDropdown item="MoreAction" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
