import { ActionTypes } from '../constants';

export default function(
  state = {
    lastUpdatedDimensionId: "",
  },
  action,
) {
  switch (action.type) {
    case ActionTypes.UPSERT_TREATMENT_PLAN_PROBLEM_SUCCESS:
      return { ...state, lastUpdatedDimensionId: action.treatmentPlanDimensionId };
    case ActionTypes.UPSERT_TREATMENT_PLAN_GOAL_SUCCESS:
      return {
        ...state,
        lastUpdatedDimensionId: action.treatmentPlanDimensionId,
      };
    case ActionTypes.UPSERT_TREATMENT_PLAN_OBJECTIVE_SUCCESS:
      return {
        ...state,
        lastUpdatedDimensionId: action.treatmentPlanDimensionId,
      };
    case ActionTypes.UPSERT_TREATMENT_PLAN_INTERVENTION_SUCCESS:
      return {
        ...state,
        lastUpdatedDimensionId: action.treatmentPlanDimensionId,
      };
    default:
      return state;
  }
}
