/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useLang, setLanguage } from "../../../../i18n";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { FormHelperText } from "@material-ui/core";
import {
  updateStaffCenter,
  loadDefaultValues,
  listCombos,
  centerOptionGet,
} from "../../../../../redux/actions";
const languages = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
  },
  {
    lang: "zh",
    name: "Mandarin",
    flag: toAbsoluteUrl("/media/svg/flags/015-china.svg"),
  },
  {
    lang: "es",
    name: "Spanish",
    flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg"),
  },
  {
    lang: "ja",
    name: "Japanese",
    flag: toAbsoluteUrl("/media/svg/flags/063-japan.svg"),
  },
  {
    lang: "de",
    name: "German",
    flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg"),
  },
  {
    lang: "fr",
    name: "French",
    flag: toAbsoluteUrl("/media/svg/flags/195-france.svg"),
  },
];
//Mention all the url where you want to block the center change during editing.
export const blockRoutesDuringCenterChange = [
  "/enrollment",
  "/manage/course-form",
  "/manage/staff-form",
  "/cw/patient-management/new-registration",
  "/cw/group-management/create-group",
  "/manage/announcement-form",
  "/manage/news-form",
];
export function CenterSelectorDropdown() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [XcenterId, setCenter] = useState();

  // const lang = useLang();
  // const currentLanguage = languages.find((x) => x.lang === lang);
  const { Center, defaultCenter, isCenterUpdated } = useSelector(
    ({
      center_option_get: { data: centerOptions, isFetching, error },
      default_values,
      update_staff_center,
    }) => {
      return {
        Center: centerOptions?.Data?.Centers,
        defaultCenter: default_values?.data?.Data?.DefaultValues[0]?.CenterId,
        isCenterUpdated:
          update_staff_center.data && update_staff_center.data.success,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    dispatch(centerOptionGet({}));
    // localStorage.setItem("centerId", defaultCenter);
    // localStorage.setItem("x-centerId", defaultCenter);
    setCenter(defaultCenter);
  }, [defaultCenter]);
  // useEffect(() => {
  //     localStorage.setItem("centerId", defaultCenter);
  //     localStorage.setItem("x-centerId", defaultCenter);
  //     setCenter(defaultCenter);
  // }, [defaultCenter]);
  // useEffect(() => {
  //     localStorage.setItem("centerId", defaultCenter);
  //     localStorage.setItem("x-centerId", defaultCenter);
  //     dispatch(loadDefaultValues({}));
  //     if (isCenterUpdated) {
  //         window.location.reload();
  //     }
  // }, [isCenterUpdated]);
  const handleChange = (center) => {
    let centerId = parseInt(center.target.value);
    localStorage.setItem("x-centerId", centerId);
    localStorage.setItem("centerId", centerId);
    setCenter(centerId);
    // if (blockRoutesDuringCenterChange.includes(history.location.pathname)) {
    //     window.alert("Unable to change center while editing the form");
    //     return;
    // } else dispatch(updateStaffCenter({ centerId: centerId }));
  };
  return (
    <div className="form-group row mb-0">
      {/* <label className="col-lg-3 col-form-label text-lg-right">Center:</label> */}
      <div className="col-lg-12 col-xl-12" style={{ maxWidth: "300px" }}>
        <select
          className="form-control form-control-solid"
          name="header-center"
          // value={defaultCenter}
          value={XcenterId}
          onChange={handleChange}
        >
          {Center &&
            Center.map((item) => {
              return (
                <option value={item.Value} key={uuid()}>
                  {item.Text}
                </option>
              );
            })}
        </select>
        {/* <FormHelperText>Select header width type</FormHelperText> */}
      </div>
    </div>
  );
}
