import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _Badge from "../../images/badge.svg";
import { clientFriendAllGet } from "../../../redux/actions";
const Friends = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    let data = {
      Friend: [
        {
          ClientId: props.ID,
        },
      ],
    };

    dispatch(clientFriendAllGet(data));
  }, []);

  const { friendsData } = useSelector(
    ({ client_friend_all_get: { data, isFetching, error } }) => {
      const { Data, Message } = data || {};
      return {
        friendsData: Data && Data.Friends,
      };
    },
    shallowEqual
  );

  return (
    <div className="card card-custom w-100">
      <div className="card-body pt-5">
        <div className="chart-friends-tab row">
          {friendsData &&
            friendsData.map((item, index) => {
              const {
                FriendUserGuid,
                FullName,
                FriendStatusId,
                FriendStatus,
                Badge,
                FriendProfileImageUrl,
                IsFriendCoach,
              } = item || {};
              return (
                <>
                  <div className="d-flex col-md-4 mt-4 mb-4" key={uuid()}>
                    <img
                      src={FriendProfileImageUrl || ""}
                      className="img-circle image"
                      height="40"
                      width="40"
                      style={{
                        borderRadius: "50%",
                        padding: "2px",
                        objectFit: "cover",
                      }}
                    />
                    &nbsp;
                    <div className="d-flex flex-column ">
                      <span className="name">{FullName}</span>
                      <span className="badge">
                        {" "}
                        <img src={_Badge} className="mr-2" />
                        {Badge}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Friends;
