import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* staffListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.staffListGet(payload);
    yield put({
      type: ActionTypes.STAFF_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_LIST_GET_FAILURE,
    });
  }
}
export function* staffGet(action) {
  try {
    const { payload } = action;
    const response = yield API.staffGet(payload);
    yield put({
      type: ActionTypes.STAFF_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_GET_FAILURE,
    });
  }
}
export function* staffUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.staffUpsert(payload);
    yield put({
      type: ActionTypes.STAFF_UPSERT_SUCCESS,
       payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_UPSERT_FAILURE,
    });
  }
}

export function* staffPermission(action) {
  try {
    const response = yield API.staffPermission(action.payload);
    yield put({
      type: ActionTypes.GET_STAFF_PERMISSION_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STAFF_PERMISSION_FAILURE,
    });
  }
}
export function* updateStaffPermission(action) {
  try {
    const response = yield API.updateStaffPermission(action.payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_PERMISSION_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_PERMISSION_FAILURE,
    });
  }
}

export function* clinicalCenterGet(action) {
  try {
    const response = yield API.clinicalCenterGet(action.payload);
    yield put({
      type: ActionTypes.CLINICAL_CENTER_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLINICAL_CENTER_GET_FAILURE,
    });
  }
}

export function* staffMobileAppInviteSend(action) {
  try {
    const response = yield API.staffMobileAppInviteSend(action.payload);
    yield put({
      type: ActionTypes.STAFF_MOBILE_APP_INVITE_SEND_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_MOBILE_APP_INVITE_SEND_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.STAFF_LIST_GET_REQUEST, staffListGet),
    takeLatest(ActionTypes.STAFF_GET_REQUEST, staffGet),
    takeLatest(ActionTypes.STAFF_UPSERT_REQUEST, staffUpsert),
       takeLatest(ActionTypes.GET_STAFF_PERMISSION_REQUEST, staffPermission),
    takeLatest(
      ActionTypes.UPDATE_STAFF_PERMISSION_REQUEST,
      updateStaffPermission
    ),
      takeLatest(
      ActionTypes.CLINICAL_CENTER_GET_REQUEST,
      clinicalCenterGet
    ),
        takeLatest(
      ActionTypes.STAFF_MOBILE_APP_INVITE_SEND_REQUEST,
      staffMobileAppInviteSend
    ),
  ]);
}
