import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import CustomTable from "../../custom-component/custom-table";
import {
  dynamicGridColumnConfigurationGet,
  dynamicGridDataGet,
  getBreathalyzerTestResult,
} from "../../../../redux/actions";

export const TestHistory = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(
    //   dynamicGridColumnConfigurationGet({
    //     Json: JSON.stringify({
    //       ComponentGrid: [
    //         {
    //           Name: "BreathalyzerTestHistory",
    //         },
    //       ],
    //     }),
    //   })
    // );
    dispatch(
      getBreathalyzerTestResult({
        Breathalyzer: [
          {
            ClientId: props.ClientId || props.ID,
          },
        ],
      })
    );
  }, []);

  const columns = [
    {
      dataField: "TestDate",
      text: "Date",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Date",
      sortField: "TestDateForSort",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: "DateFormatter",
      csvExport: true,
    },
    {
      dataField: "TestTime",
      text: "Time",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "TestTime",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: true,
    },
    {
      dataField: "Latitude",
      text: "Location",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "Latitude",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: true,
    },
    {
      dataField: "Result",
      text: "Result",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "ResultSort",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: "RedGreenBGFormatter",
      csvExport: true,
    },
    {
      dataField: "Remarks",
      text: "Remarks",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "Remarks",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: "RedGreenTextFormatter",
      csvExport: true,
    },
    {
      dataField: "Actions",
      text: "Actions",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Action",
      sortField: "Action",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: "ActionsColumnFormatter",
      csvExport: false,
    },
  ];

  const { BreathalyzerTestResult } = useSelector(
    ({ get_breathalyzer_test_result: { data: ListData } }) => {
      return {
        BreathalyzerTestResult: ListData?.Data?.Schedules || [],
      };
    },
    shallowEqual
  );

  return (
    <>
      <div className="d-flex" style={{ justifyContent: "space-evenly" }}></div>

      {BreathalyzerTestResult && (
        <div className="member-trend">
          {BreathalyzerTestResult && (
            <CustomTable
              // tableTitle="Member Trends"
              entities={BreathalyzerTestResult || []}
              columns={columns || []}
              isCardHeader={false}
              pagination={true}
              // isLoading={isFetching}
              bordered={false}
              // selectRow={selectRow}
              // rowEvents={rowEvents}
              tableOptions={{
                // PageSize,
                // IsRowClick,
                // IsExcelExport,
                // IsColumnSelectionEnable,
                FileName: "Test History",
              }}
              toggleDrawer={false}
              rowStyle={{
                backgroundColor: "#F7F8FA",
                margin: "12px 0px",
                boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                borderRadius: "2px",
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
