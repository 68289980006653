/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { v4 as uuid } from "uuid";
import { Dropdown } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../../../_metronic/_partials/dropdowns";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

const list = [
  { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
  { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
];
export function ToDo({ className }) {
  const classes = useStyles();
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Todo</h3>
          <div className="card-toolbar">
            <Button color="secondary" className={classes.button}>
              + Add New
            </Button>
            {/* <Dropdown className="dropdown-inline" drop="down" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top2"
                variant="transparent"
                className="btn btn-light btn-sm font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
              >
                Today
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu2 />
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </div>
        {/* Body */}
        <div className="card-body pt-2">
          {list.map((item) => {
            const { taskName, taskDuration } = item;

            return (
              <div className="d-flex align-items-center mb-10" key={uuid()}>
                <span className="bullet bullet-bar bg-primary align-self-stretch mr-2"></span>

                <div className="d-flex flex-column flex-grow-1">
                  <a
                    href="#"
                    className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                  >
                    {taskName}
                  </a>
                  <span className="text-muted font-weight-bold">
                    {taskDuration}
                  </span>
                </div>
                <ItemDropdown item="" />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-hover-light-primary btn-sm btn-icon"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-ver" />
        </Dropdown.Toggle>
        {/* <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu> */}
      </Dropdown>
    </>
  );
};
