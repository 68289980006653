import React, { Component, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import GoogleMapReact from "google-map-react";
import Modal from "@material-ui/core/Modal";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import _blank from "../../../images/blank-profile.png";
import ModalData from "./modalData";
import { memberTrendTriggerHistory } from "../../../../redux/actions";
const InfoWindow = (props) =>
  props.show ? (
    <div className="map-info-popup">
      <div
        style={{ display: "flex", justifyContent: "flex-end" }}
        onClick={props.close}
      >
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
      <div style={{ display: "flex" }}>
        <div className="icon-area">
          {/* <i className="fa fa-medkit" aria-hidden="true"></i> */}
          {
            <img
              className="url-icon"
              src={props.TriggerTypeImgUrl}
              style={{ width: "20px", height: "20px" }}
            />
          }
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            Visited this place <b>{props.VisitCount} times</b>
          </span>
          <span className="subText">{props.TriggerTypeValue}</span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          marginTop: "10px",
          padding: "10px 0px",
        }}
      >
        <span className="popText">
          <b>{props.OtherUsersTaggedThisPlaceCount} other</b> users have tagged
          this place.{" "}
        </span>
        <span className="popText2">{props.FullAddress}</span>
      </div>
    </div>
  ) : null;

class AnyReactComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show2: false,
    };
  }
  _onChildClick = (key, childProps) => {
    this.setState({ show: !this.state.show });
  };
  render() {
    const {
      VisitCount,
      TriggerTypeValue,
      TriggerTypeImgUrl,
      OtherUsersTaggedThisPlaceCount,
      FullAddress,
      IsPositive,
      showIsPositive,
      TriggerSign,
      DisplayName,
      TriggerId,
      // memberTrendTriggerHistoryData,
    } = this.props;
    return (
      <div>
        {" "}
        <div
          style={{
            background: TriggerSign == 1 ? "#009EF7" : "red",

            display: "block",
            // width: "100px",
            position: "absolute",
            top: "-43px",
            left: "-45px",
            color: "white",
            whiteSpace: "nowrap",
            padding: "5px",
            // border: "1px solid red",
          }}
        >
          {" "}
          {TriggerTypeValue}
        </div>
        <div className="custom-marker">
          {!showIsPositive && (
            <div className="number-circle">{this.props.VisitCount}</div>
          )}

          {/* {
          showIsPositive && <div className="isPositive" style={IsPositive ? { background: 'yellow' } : { background: 'red' }}>{ IsPositive?<span>+</span>:<span>-</span>}</div>
        }  */}
          <InfoWindow
            show={this.state.show}
            close={this._onChildClick}
            TriggerTypeValue={TriggerTypeValue}
            VisitCount={VisitCount}
            TriggerTypeImgUrl={TriggerTypeImgUrl}
            OtherUsersTaggedThisPlaceCount={OtherUsersTaggedThisPlaceCount}
            FullAddress={FullAddress}
          />
          {showIsPositive && (
            <div
              className="d-flex flex-column"
              // onClick={this._onChildClick}
              style={
                TriggerSign == 1
                  ? {
                      border: "3px solid #009EF7",
                      // height: "50%",
                      background: "#FAFFC1",
                      // padding: "5px",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }
                  : TriggerSign == 0
                  ? {
                      border: "3px solid #FF3B3B",
                      background: "#FFD4D4",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }
                  : {
                      border: "3px solid #828282",
                      background: "#d9d9d9",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }
              }
            >
              {
                <img
                  src={TriggerTypeImgUrl || _blank}
                  style={{ width: "61px", objectFit: "cover" }}
                  //   width={"25px"}
                />
              }
            </div>
          )}
          {!showIsPositive && (
            <div
              className="d-flex flex-column"
              style={
                TriggerSign == 1
                  ? {
                      border: "3px solid #009EF7",
                      // height: "50%",
                      background: "#FAFFC1",
                      // padding: "5px",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }
                  : TriggerSign == 0
                  ? {
                      border: "3px solid #FF3B3B",
                      background: "#FFD4D4",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }
                  : {
                      border: "3px solid #828282",
                      background: "#d9d9d9",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }
              }
              // onClick={this._onChildClick}
            >
              {
                <img
                  src={TriggerTypeImgUrl || _blank}
                  style={{ width: "61px", objectFit: "cover" }}
                  onClick={() => {
                    this.setState({ show2: !this.state.show2 });
                    this.props.dispatch(
                      memberTrendTriggerHistory({
                        Json: JSON.stringify({
                          Trigger: [
                            {
                              TriggerId: 226,
                            },
                          ],
                        }),
                      })
                    );
                  }}
                  // width={"25px"}
                />
              }
            </div>
          )}
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          // className={classes.modal}
          // open={this.state.show2}
          onClose={() => {
            this.setState({
              show2: false,
            });
          }}
          // outline={0}
          closeAfterTransition
          // BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff3d",
          }}
        >
          <div className="p-modal" style={{ outline: "none" }}>
            <ModalData
            // memberTrendTriggerHistoryData={memberTrendTriggerHistoryData}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export const MapView = (props) => {
  const {
    userTriggerHistoryData,
    // memberTrendTriggerHistoryData
  } = props;

  const dispatch = useDispatch();
  const [center, setCenter] = React.useState({
    lat: 59.95,
    lng: 30.33,
  });
  const [zoom, setZoom] = React.useState(5);

  useEffect(() => {
    setCenter({
      lat: userTriggerHistoryData[0].Latitude,
      lng: userTriggerHistoryData[0].Longitude,
    });
  }, [userTriggerHistoryData]);

  //   const { timeLineData } = useSelector((state) => {
  //     return {
  //       timeLineData: state.triggers_list_get.data || [],
  //     };
  //   }, shallowEqual);
  //

  return (
    <>
      {/* Important! Always set the container height explicitly */}
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAYFWM8jJzcn1Bc-blNBXJBqeSPOoA5Ugo",
          }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          {userTriggerHistoryData &&
            userTriggerHistoryData.map((history, index) => {
              return (
                <AnyReactComponent
                  lat={history.Latitude}
                  lng={history.Longitude}
                  VisitCount={history.VisitCount || 0}
                  TriggerTypeImgUrl={
                    history.UserProfileImageUrl || history.TriggerTypeImgUrl
                  }
                  OtherUsersTaggedThisPlaceCount={
                    history.OtherUsersTaggedThisPlaceCount
                  }
                  FullAddress={history.FullAddress}
                  TriggerTypeValue={history.TriggerTypeValue}
                  TriggerSign={history.TriggerSign}
                  DisplayName={history.DisplayName}
                  TriggerId={history.TriggerId}
                  dispatch={dispatch}
                  // memberTrendTriggerHistoryData={memberTrendTriggerHistoryData}
                />
              );
            })}
        </GoogleMapReact>
      </div>
    </>
  );
};
