import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { BreathlyzerChart } from "./breathlyzerChart";
import GenericTable from "../../../components/generic-table/generic-table";
import CustomTable from "../../custom-component/custom-table";

export const Breathlyzer = (className) => {
  const columns = [
    {
      dataField: "Date",
      text: "Date",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "Time",
      text: "Time",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },

    {
      dataField: "Score",
      text: "Score",
      sort: true,
      formatter: "RedGreenBGFormatter",
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "Remarks",
      text: "Remarks",
      sort: true,
      formatter: "RedGreenTextFormatter",
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
    {
      dataField: "",
      text: "Actions",
      formatter: "ActionsColumnFormatter",

      // formatter: columnFormatters.ActionsColumnFormatter,
      // sort: enableSorting,
      // sortCaret: sortCaret,
      // headerSortingClasses: headerSortingClasses,
    },
  ];

  const { BreathalyzertData } = useSelector(({ user_member_trend_history }) => {
    return {
      BreathalyzertData:
        (user_member_trend_history &&
          user_member_trend_history.data &&
          user_member_trend_history.data.Data &&
          user_member_trend_history.data.Data[0] &&
          user_member_trend_history.data.Data[0].Breathalyzer &&
          user_member_trend_history.data.Data[0].Breathalyzer[0]) ||
        [],
    };
  }, shallowEqual);

  return (
    <>
      <div
        className="d-flex  flex-column w-100"
        style={{ justifyContent: "space-evenly" }}
      >
        {" "}
        <div>
          {BreathalyzertData && BreathalyzertData.GraphData && (
            <BreathlyzerChart graphData={BreathalyzertData.GraphData} />
          )}
        </div>
        {BreathalyzertData && (
          // <ToolkitProvider
          //   keyField="id"
          //   data={BreathalyzertData.Tabledata || []}
          //   columns={columns}
          //   // search
          // >
          //   {(props) => (
          //     <div>

          //       <BootstrapTable
          //         wrapperClasses="table-responsive member-trend-table"
          //         classes="table table-head-custom table-vertical-center overflow-hidden"
          //         bootstrap4={true}
          //         bordered={false}
          //         rowStyle={{
          //           backgroundColor: "#F7F8FA",
          //           margin: "12px 0px",
          //           boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
          //           borderRadius: "2px",
          //         }}
          //         // hover
          //         // defaultSorted={defaultSorted}
          //         {...props.baseProps}
          //       />
          //     </div>
          //   )}
          // </ToolkitProvider>
          <div className="member-trend">
            {BreathalyzertData && BreathalyzertData.TableData && (
              <CustomTable
                // tableTitle="Member Trends"
                entities={BreathalyzertData.TableData || []}
                columns={columns || []}
                isCardHeader={false}
                pagination={false}
                // isLoading={isFetching}
                bordered={false}
                rowStyle={{
                  backgroundColor: "#F7F8FA",
                  margin: "12px 0px",
                  boxShadow: "0px 0px 4px rgba(61, 61, 69, 0.1)",
                  borderRadius: "2px",
                }}
                // selectRow={selectRow}
                // rowEvents={rowEvents}
                // tableOptions={{
                //   PageSize,
                //   IsRowClick,
                //   IsExcelExport,
                //   IsColumnSelectionEnable,
                // }}
                toggleDrawer={false}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
