import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import {
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  ButtonToolbar,
} from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _Play from "../../../images/play_grey.svg";
import { getMediaVideoUrl } from "../../../../redux/actions";

export const VideoPlay = (props) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShowIds] = useState(false);
  let modalClose = () => setModalShowIds(false);

  const { MediaGuid, Data } = props;
  const { mediaVideoUrl, isFetching } = useSelector(
    ({ get_media_video_url }) => {
      return {
        mediaVideoUrl:
          get_media_video_url.data && get_media_video_url.data.result,
        isFetching: get_media_video_url.isFetching,
      };
    },
    shallowEqual
  );
  // useEffect(() => {
  //   if (mediaVideoUrl) {
  //     setModalShowIds(!modalShow);
  //   }
  // }, [mediaVideoUrl]);

  const getVideoUrl = () => {
    dispatch(
      getMediaVideoUrl({
        Json: JSON.stringify({
          Media: [
            {
              MediaGuid: MediaGuid,
            },
          ],
        }),
      })
    );
  };

  return (
    <div className="">
      <OverlayTrigger overlay={<Tooltip id="chat-tooltip">Play</Tooltip>}>
        <a
          className="btn btn-icon btn-hover-light-primary btn-sm mx-3"
          onClick={() => {
            getVideoUrl();
            setModalShowIds(!modalShow);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl(_Play)} />
          </span>
        </a>
      </OverlayTrigger>
      {modalShow && (
        <Modal
          {...props}
          show={modalShow}
          onHide={modalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="video-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {mediaVideoUrl && mediaVideoUrl.includes("https") ? (
              <video
                style={{ border: "3px solid #EEE" }}
                width="100%"
                height="auto"
                src={mediaVideoUrl}
                autoplay="true"
                controls
                disablePictureInPicture="true"
                controlsList="nodownload"
              />
            ) : (
              <div className="text-center">
                <h5>{mediaVideoUrl}</h5>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
