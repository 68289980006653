import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
// import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";

const ReduxTextarea = ({
  input,
  type,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  maxLength,
  mobileFormat,
  tabIndex,
  style,
  fieldName,
  min,
  max,
  controlledMaxAge,
  negativeNumberBlocked,
  showPlaceHolder,
  labelText,
  rows,
  multiline,
  rowsMax,
}) => {
  var { touched, error, warning, active } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    // value = input.value.toString();
    value = input.value && input.value.toString();
    onChange = input.onChange;
    onBlur = input.onBlur;
    name = input.name;
  }

  /*if(mobileFormat){
        value = Util.formatPhoneNumber(value);
    }*/

  return (
    <>
      <FormControl fullWidth className="formControl" error={touched && error}>
        <InputLabel htmlFor={id}>{labelText}</InputLabel>
        {!controlledMaxAge ? (
          !negativeNumberBlocked ? (
            <OutlinedInput
              {...input}
              style={style}
              tabIndex={tabIndex}
              type={type}
              name={name}
              id={id}
              // defaultValue={defaultValue}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              // placeholder={active && placeholder}
              placeholder={
                showPlaceHolder ? placeholder : active && placeholder
              }
              className={`${className} ${value && "not-empty"} ${type ==
                "textarea" && "textarea-height"}`}
              disabled={!!disabled}
              maxLength={maxLength}
              autoComplete="off"
              rows={rows}
              // defaultValue="Default Value"
              // placeholder="Placeholder"
              multiline={multiline}
              rowsMax={rowsMax}
            />
          ) : (
            <OutlinedInput
              {...input}
              style={style}
              tabIndex={tabIndex}
              type={type}
              name={name}
              id={id}
              // defaultValue={defaultValue}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={active && placeholder}
              className={`${className} ${value && "not-empty"}`}
              disabled={!!disabled}
              maxLength={maxLength}
              autoComplete="off"
              min={min}
              max={max}
              multiline
              rows="4"
              // defaultValue="Default Value"
              // placeholder="Placeholder"
            />
          )
        ) : (
          <OutlinedInput
            {...input}
            style={style}
            tabIndex={tabIndex}
            type={type}
            name={name}
            id={id}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={active && placeholder}
            className={`${className} ${value && "not-empty"}`}
            disabled={!!disabled}
            maxLength={maxLength}
            autoComplete="off"
            min={min}
            max={max}
            multiline
            //   onKeyPress={function(evt) {
            //     evt.preventDefault();
            //   }
            // }
            rows="4"
            // defaultValue="Default Value"
            // placeholder="Placeholder"
          />
        )}

        {touched &&
          ((error && fieldName && (
            <FormHelperText id="component-error-text">
              {" "}
              {fieldName} {error}
            </FormHelperText>
          )) ||
            (warning && <span className="warning-color">{warning}</span>))}
      </FormControl>
    </>
  );
};

export { ReduxTextarea };
