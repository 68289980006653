import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import jsPDF from "jspdf";
import "jspdf-autotable";

const exportPdf = (columns, rowData, FileName) => {
  const doc = new jsPDF();
  // let headData = [['Name', 'Email', 'Country']];
  let dataField = [];
  /* Filtering Only csvExport=true column */
  let colHeader = columns.filter((item) => {
    if (item.csvExport && !item.hidden) {
      dataField.push(item.dataField);
      return item.text;
    }
  });
  let colHeaderText = colHeader.map((item) => {
    return item.text;
  });
  /*  Construct Row Data */
  let rowItem = rowData.map((item, index) => {
    let data = dataField.map((colKey) => {
      return item[colKey];
    });

    return [...data];
  });

  let headData = [colHeaderText];
  let bodyData = rowItem;
  // let bodyData = [
  //   // ['David', 'david@example.com', 'Sweden'],
  //   // ['Castille', 'castille@example.com', 'Spain'],
  // ];
  // doc.setFontSize(18);
  // doc.text('With content With content With content With content ', 14, 22);
  // doc.setFontSize(11);
  // doc.setTextColor(100);

  var pageSize = doc.internal.pageSize;
  var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
  var textString = `Name : ${FileName}`;
  var text = doc.splitTextToSize(textString, pageWidth - 35, {});
  if (!colHeader.length) {
    doc.text("No record to export", 12, 20);
  } else doc.text(text, 12, 20);
  // doc.text("Timeline", 12, 30);
  doc.autoTable({
    head: headData,
    body: bodyData,
    startY: 25,
    showHead: "firstPage",
    theme: "grid" /*  "striped", */,
  });
  doc.save(`${FileName}.pdf` || "Data.pdf");
};
export default exportPdf;
