import axios from "axios";
import { URLs } from "./configuration";
import { getAppSubscribers } from "../actions";

// axios.defaults.baseURL = "api/v1/";
axios.defaults.baseURL = "https://youu-ent-api-dev.azurewebsites.net/api/";

// Comment Above line and use below line for working with live URL.
// axios.defaults.baseURL = "https://drdev.azurewebsites.net/api/v1/";

axios.defaults.headers.common["Content-Type"] =
  "application/json; charset=utf-8";
//axios.defaults.headers.common['Authorization'] = 'Bearer CfDJ8FnfxrGHJRpLl2eA0rhporHiOsGOPueK_snedUxMIAZ7JCU3TR6Ipfn_HHy36FQEB-ehGAscEOE4hsj5bXHGKJC4cJRpt3Tn_2KmGSASzma_UmC4D0K5ncp0Bxp1ClSpTI2JEFPLknG8NvGaKsMQNXEOuigM8GSLfmCSSVVsk8iaoertvd72ww2WkIdPQhaN2irpU5ksPAJUJMBc5yYUZPsmHiR09WHA9E97L8PPIwI4kaWBcq8GEOTk-1y6IKT-5Q1muTzAJLDHyryQ80DuPxj21Vn3c6R0LqCc2Og9OAhh3eApVPzkJLb42w0L54E68raXcS3HhXOCaVBtLeaUVta-zEC4VJC_5q5t6INT8ENir25bTiEuDN4z7gCc2bhJ3rEgPLMJLsm3X13ZFLBgcEIS_NwBhjt6az1tTslaDPuVaNwZxAOXZvG7PI31oqCmLYcDtKxLilkgbJAQqzicbX5tiDTrIPtyR2BGoSW9XYLu8yyWTcSPH19S5MW_i_AA5YqzST5BJonit65z4lBIRZxBDgfExJ99N-3n08p_-Wn5ZStvv4fzkIafVbzzcAfRbGKk9CKoYmh3VEN9xY3-CoFLTjaI7KUoQmlWQSNAmr92zfW4TdhT-HlSL5v5OExNysxMsDLaMcbGbGP_KyAHDJdM7OPdkMpg92Npo-7PYRaT';

const onRequestSuccess = (config) => {
  console.debug("request success", config);
  var tokenObject = localStorage.getItem("dr:token");
  if (tokenObject) {
    const accessToken = JSON.parse(localStorage.getItem("dr:token"))
      .access_token;
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
    config.headers["x-centerid"] = localStorage.getItem("x-centerId");
  }
  return config;
};

const onRequestFail = (error) => {
  console.debug("request error", error);
  return Promise.reject(error);
};

axios.interceptors.request.use(onRequestSuccess, onRequestFail);

const onResponseSuccess = (response) => {
  console.debug("response success", response);
  if (response && response.data) {
    if (Object.keys(response.data).indexOf("success") > -1) {
      if (!response.data.success && response.data.result) {
        alert(response.data.result);
      }
    }
  }
  return response;
};
const onResponseFail = (error) => {
  console.debug("response error", error);
  return Promise.reject(error);
};

axios.interceptors.response.use(onResponseSuccess, onResponseFail);

export default class API {
  static login = (param) => {
    const defaultOptions = {
      baseURL: "https://youu-ent-api-dev.azurewebsites.net/api/",
      // baseURL: "",
      timeout: 36000,
      headers: {
        accept: "*/*",
        // Accept: "application/json",
        "Content-Type": "application/json",
        // "content-type": "application/x-www-form-urlencoded",
      },
    };
    let instance = axios.create(defaultOptions);
    //var loginData = `?username=` + param.username + `&password=` + param.password + `&grant_type=` + param.grant_type;
    // var formBody = [];
    // for (var property in param) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(param[property]);
    //   formBody.push(encodedKey + "=" + encodedValue);
    // }
    // formBody = formBody.join("&");
    // console.debug(formBody);
    return instance.post("UserAuth/TempLogin", param);
  };

  static refreshToken = (param) => {
    const defaultOptions = {
      baseURL: "https://youu-ent-api-dev.azurewebsites.net/api/",
      // baseURL: "",
      timeout: 36000,
      headers: {
        // "content-type": "application/x-www-form-urlencoded",
        "Content-Type": "multipart/form-data",
      },
    };
    let instance = axios.create(defaultOptions);
    //var loginData = `?username=` + param.username + `&password=` + param.password + `&grant_type=` + param.grant_type;

    var formBody = [];
    for (var property in param) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.debug(formBody);

    return instance.post("UserAuth/TempTokenRefresh", formBody);
  };

  static listStudents = (param) => {
    return axios.post(URLs.listStudents, param);
  };

  static addStudent = (param) => {
    return axios.post(URLs.addStudent, param);
  };

  static addStudentNote = (param) => {
    return axios.post(URLs.addStudentNote, param);
  };

  static getStudent = (param) => {
    return axios.post(URLs.getStudent, param);
  };

  static getDashboard = () => {
    return axios.post(URLs.getDashboard);
  };

  static studentMarkComplete = (param) => {
    return axios.post(URLs.studentMarkComplete, param);
  };

  static studentUnAssign = (param) => {
    return axios.post(URLs.studentUnAssign, param);
  };

  static upsertStudentPin = (param) => {
    return axios.post(URLs.upsertStudentPin, param);
  };

  static validateStudentPin = (param) => {
    return axios.post(URLs.validateStudentPin, param);
  };

  static studentDischarge = (param) => {
    return axios.post(URLs.studentDischarge, param);
  };

  static studentReinstate = (param) => {
    return axios.post(URLs.studentReinstate, param);
  };

  static listCombos = (param) => {
    return axios.post(URLs.listCombos, param);
  };

  static listFilterCombo = (param) => {
    return axios.post(URLs.listFilterCombo, param);
  };

  static listSmartCombos = (param) => {
    return axios.post(URLs.listSmartCombos, param);
  };

  static listTodo = () => {
    return axios.post(URLs.listTodo);
  };

  static listToDoByStudent = (param) => {
    return axios.post(URLs.listTodoByStudent, param);
  };

  static listActivity = () => {
    return axios.post(URLs.listActivity);
  };

  static listActivityByStudent = (param) => {
    return axios.post(URLs.listActivityByStudent, param);
  };

  static addTodo = (param) => {
    return axios.post(URLs.addTodo, param);
  };

  static forgetPassword = (param) => {
    return axios.post(URLs.forgetPassword, param);
  };

  static resetPassword = (param) => {
    return axios.post(URLs.resetPassword, param);
  };

  static getReportData = () => {
    return axios.post(URLs.getReportData);
  };

  static announcementGetAll = (param) => {
    return axios.post(URLs.announcementGetAll, param);
  };

  static announcementGetJson = (param) => {
    return axios.post(URLs.announcementGetJson, param);
  };

  static announcementUpsert = (param) => {
    return axios.post(URLs.announcementUpsert, param);
  };

  static announcementDelete = (param) => {
    return axios.post(URLs.announcementDelete, param);
  };

  static logDevice = (param) => {
    return axios.post(URLs.logDevice, param);
  };

  static logError = (param) => {
    return axios.post(URLs.logError, param);
  };

  static loadMenu = (param) => {
    return axios.post(URLs.loadMenu, param);
  };

  static logOut = (param) => {
    return axios.post(URLs.logOut, param);
  };

  static getStudentProfileDashboardData = (param) => {
    return axios.post(URLs.getStudentProfileDashboardData, param);
  };

  static getStudentsSearch = (param) => {
    return axios.post(URLs.getStudentsSearch, param);
  };

  static getUserRole = (param) => {
    return axios.post(URLs.getUserRole, param);
  };

  static getListJobTitle = (param) => {
    return axios.post(URLs.getListJobTitle, param);
  };

  static updateJobTitle = (param) => {
    return axios.post(URLs.updateJobTitle, param);
  };

  static updateRole = (param) => {
    return axios.post(URLs.updateRole, param);
  };

  static deleteRole = (param) => {
    return axios.post(URLs.deleteRole, param);
  };

  static deleteJobTitle = (param) => {
    return axios.post(URLs.deleteJobTitle, param);
  };

  static updateRolePermissions = (param) => {
    return axios.post(URLs.updateRolePermissions, param);
  };

  static updateJobTitleSync = (param) => {
    return axios.post(URLs.updateJobTitleSync, param);
  };

  static userFeedback = (param) => {
    return axios.post(URLs.userFeedback, param);
  };

  static isLicenseAvailable = (param) => {
    return axios.post(URLs.isLicenseAvailable, param);
  };

  static getDashboardLoadAppUsers = (param) => {
    return axios.post(URLs.getDashboardLoadAppUsers, param);
  };

  static getValidateImage = (param) => {
    return axios.post(URLs.getValidateImage, param);
  };

  static getLicenseActivites = (param) => {
    return axios.post(URLs.getLicenseActivites, param);
  };

  static getCurrentLicenses = (param) => {
    return axios.post(URLs.getCurrentLicenses, param);
  };

  static getLicensesInvoice = (param) => {
    return axios.post(URLs.getLicensesInvoice, param);
  };

  static getLicensesReceipt = (param) => {
    return axios.post(URLs.getLicensesReceipt, param);
  };

  static addLicenses = (param) => {
    return axios.post(URLs.addLicenses, param);
  };

  static getPaymentInfo = (param) => {
    return axios.post(URLs.getPaymentInfo, param);
  };

  static updatePaymentMethode = (param) => {
    return axios.post(URLs.updatePaymentMethode, param);
  };

  static isAchValidation = (param) => {
    return axios.post(URLs.isAchValidation, param);
  };

  static getBillingInfo = (param) => {
    return axios.post(URLs.getBillingInfo, param);
  };

  static achValidate = (param) => {
    return axios.post(URLs.achValidate, param);
  };

  static addNewApplicant = (param) => {
    return axios.post(URLs.addNewApplicant, param);
  };

  static listAppSubscribers = (param) => {
    return axios.post(URLs.listAppSubscribers, param);
  };

  static getAppSubscriberProfileDashboard = (param) => {
    return axios.post(URLs.getAppSubscriberProfileDashboard, param);
  };

  static getAppSubscriberProfile = (param) => {
    return axios.post(URLs.getAppSubscriberProfile, param);
  };

  static getAppSubscriberChat = (param) => {
    return axios.post(URLs.getAppSubscriberChat, param);
  };

  static getAppSubscriberChatCoach = (param) => {
    return axios.post(URLs.getAppSubscriberChatCoach, param);
  };

  static getAppSubscriberProfileActivities = (param) => {
    return axios.post(URLs.getAppSubscriberProfileActivities, param);
  };

  static addAppSubscriberNote = (param) => {
    return axios.post(URLs.addAppSubscriberNote, param);
  };

  static addAppSubscriberTask = (param) => {
    return axios.post(URLs.addAppSubscriberTask, param);
  };

  static deleteAppSubscriberTask = (param) => {
    return axios.post(URLs.deleteAppSubscriberTask, param);
  };

  static appSubscribersUserSearch = (param) => {
    return axios.post(URLs.appSubscribersUserSearch, param);
  };

  static appSubscriberCompleteTask = (param) => {
    return axios.post(URLs.appSubscriberCompleteTask, param);
  };

  static spListCombos = (param) => {
    return axios.post(URLs.spListCombos, param);
  };

  static listMobileAppRoleGet = (param) => {
    return axios.post(URLs.listMobileAppRoleGet, param);
  };
  static appSubscriberMapHistory = (param) => {
    return axios.post(URLs.appSubscribersMapHistory, param);
  };
  static appInvite = (param) => {
    return axios.post(URLs.appInvite, param);
  };
  static getMobileAppDownloadStatus = (param) => {
    return axios.post(URLs.getMobileAppDownloadStatus, param);
  };
  static updateMobileAppRole = (param) => {
    return axios.post(URLs.updateMobileAppRole, param);
  };
  static addNoteType = (param) => {
    return axios.post(URLs.addNoteType, param);
  };
  static changeAppRole = (param) => {
    return axios.post(URLs.changeAppRole, param);
  };
  static twoStepAuth = (param) => {
    return axios.post(URLs.twoStepAuth, param);
  };
  static validatePasscode = (param) => {
    return axios.post(URLs.validatePasscode, param);
  };
  static appInviteDashboard = (param) => {
    return axios.post(URLs.appInviteDashboard, param);
  };

  static isManageLicenseRequired = (param) => {
    return axios.post(URLs.isManageLicenseRequired, param);
  };
  static getAllCallSession = (param) => {
    return axios.post(URLs.getAllCallSession, param);
  };
  static addCallSession = (param) => {
    return axios.post(URLs.addCallSession, param);
  };
  static getAllVacation = (param) => {
    return axios.post(URLs.getAllVacation, param);
  };
  static addVacationDays = (param) => {
    return axios.post(URLs.addVacationDays, param);
  };
  static getOneCallTimeSlots = (param) => {
    return axios.post(URLs.getOneCallTimeSlots, param);
  };
  static getOneCallSession = (param) => {
    return axios.post(URLs.getOneCallSession, param);
  };
  static inviteOneCallSession = (param) => {
    return axios.post(URLs.inviteOneCallSession, param);
  };
  static cancelOneCallSession = (param) => {
    return axios.post(URLs.cancelOneCallSession, param);
  };
  static getStripeApiKey = (param) => {
    return axios.post(URLs.getStripeApiKey, param);
  };
  static editGroupCallSession = (param) => {
    return axios.post(URLs.editGroupCallSession, param);
  };
  static getCallSessionVideo = (param) => {
    return axios.post(URLs.getCallSessionVideo, param);
  };
  static getCallSessionVideoUrl = (param) => {
    return axios.post(URLs.getCallSessionVideoUrl, param);
  };

  static getReports = (param) => {
    return axios.post(URLs.getReports, param);
  };
  static getPromoCode = (param) => {
    return axios.post(URLs.getPromoCode, param);
  };

  static getCounselor = (param) => {
    return axios.post(URLs.getCounselor, param);
  };
  static getClientCounselor = (param) => {
    return axios.post(URLs.getClientCounselor, param);
  };
  static getDynamicComos = (param) => {
    return axios.post(URLs.getDynamicComos, param);
  };
  static sendVoucherCode = (param) => {
    return axios.post(URLs.sendVoucherCode, param);
  };
  static getSmallGroupUsers = (param) => {
    return axios.post(URLs.getSmallGroupUsers, param);
  };
  static uploadLeaderPhoto = (param) => {
    return axios.post(URLs.uploadLeaderPhoto, param);
  };
  static markSystemObservation = (param) => {
    return axios.post(URLs.markSystemObservation, param);
  };
  static appMarkSystemObservation = (param) => {
    return axios.post(URLs.appMarkSystemObservation, param);
  };
  static getAnalytics = (param) => {
    return axios.post(URLs.getAnalytics, param);
  };
  static getActiveClient = (param) => {
    return axios.post(URLs.getActiveClient, param);
  };

  static getNotes = (param) => {
    return axios.post(URLs.getNotes, param);
  };
  static getCategoryClient = (param) => {
    return axios.post(URLs.getCategoryClient, param);
  };
  static getClientDemographic = (param) => {
    return axios.post(URLs.getClientDemographic, param);
  };
  static getCostOfCare = (param) => {
    return axios.post(URLs.getCostOfCare, param);
  };
  static getBehavioralRating = (param) => {
    return axios.post(URLs.getBehavioralRating, param);
  };
  static getScoreImprovement = (param) => {
    return axios.post(URLs.getScoreImprovement, param);
  };
  static getProviderPerformance = (param) => {
    return axios.post(URLs.getProviderPerformance, param);
  };
  static getTriggerLocationActivity = (param) => {
    return axios.post(URLs.getTriggerLocationActivity, param);
  };
  static getServiceDelivered = (param) => {
    return axios.post(URLs.getServiceDelivered, param);
  };
  static getReferralSource = (param) => {
    return axios.post(URLs.getReferralSource, param);
  };
  static getStudentDocumentList = (param) => {
    return axios.post(URLs.getStudentDocumentList, param);
  };
  static archiveStudentDocument = (param) => {
    return axios.post(URLs.archiveStudentDocument, param);
  };
  static uploadDocument = (formData) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
      maxContentLength: 100000000,
      maxBodyLength: 100000000,
    };
    return axios.post(URLs.uploadDocument, formData, config);
  };
  static downloadStudentDocument = (param) => {
    return axios.post(URLs.downloadStudentDocument, param);
  };

  static newsUpsert = (param) => {
    return axios.post(URLs.newsUpsert, param);
  };
  static getListNews = (param) => {
    return axios.post(URLs.getListNews, param);
  };
  static getNews = (param) => {
    return axios.post(URLs.getNews, param);
  };
  static deleteNews = (param) => {
    return axios.post(URLs.deleteNews, param);
  };
  static getEmbedInfo = (param) => {
    return axios.post(URLs.getEmbedInfo, param);
  };
  static getPatientList = (param) => {
    return axios.post(URLs.getPatientList, param);
  };
  static getPatientDetail = (param) => {
    return axios.post(URLs.getPatientDetail, param);
  };
  static upsertPatientEnrollment = (param) => {
    return axios.post(URLs.upsertPatientEnrollment, param);
  };
  static upsertClientInsurance = (param) => {
    return axios.post(URLs.upsertClientInsurance, param);
  };
  static upsertClientMedical = (param) => {
    return axios.post(URLs.upsertClientMedical, param);
  };
  static upsertClientPayment = (param) => {
    return axios.post(URLs.upsertClientPayment, param);
  };
  static upsertProviderEnrollment = (param) => {
    return axios.post(URLs.upsertProviderEnrollment, param);
  };
  static upsertAppointment = (param) => {
    return axios.post(URLs.upsertAppointment, param);
  };
  static listAppointment = (param) => {
    return axios.post(URLs.listAppointment, param);
  };
  static getAppointment = (param) => {
    return axios.post(URLs.getAppointment, param);
  };
  static deleteAppointment = (param) => {
    return axios.post(URLs.deleteAppointment, param);
  };
  static medicateCombos = (param) => {
    return axios.post(URLs.medicateCombos, param);
  };

  static practitionerCombos = (param) => {
    return axios.post(URLs.practitionerCombos, param);
  };
  static practitionerCombosOffice = (param) => {
    return axios.post(URLs.practitionerCombosOffice, param);
  };
  static upsertGroup = (param) => {
    return axios.post(URLs.upsertGroup, param);
  };
  static listGroup = (param) => {
    return axios.post(URLs.listGroup, param);
  };
  static getGroup = (param) => {
    return axios.post(URLs.getGroup, param);
  };
  static deleteGroup = (param) => {
    return axios.post(URLs.deleteGroup, param);
  };
  static upsertClientGroup = (param) => {
    return axios.post(URLs.upsertClientGroup, param);
  };
  static getClientGroup = (param) => {
    return axios.post(URLs.getClientGroup, param);
  };
  static upsertGroupAppointment = (param) => {
    return axios.post(URLs.upsertGroupAppointment, param);
  };
  static upsertGroupAppointmentGroupAllInfoJson = (param) => {
    return axios.post(URLs.upsertGroupAppointmentGroupAllInfoJson, param);
  };
  static getListClientGroup = (param) => {
    return axios.post(URLs.getListClientGroup, param);
  };
  static patientComboGroup = (param) => {
    return axios.post(URLs.patientComboGroup, param);
  };
  static deactivateGroupAppointment = (param) => {
    return axios.post(URLs.deactivateGroupAppointment, param);
  };
  static upsertClientConsent = (param) => {
    return axios.post(URLs.upsertClientConsent, param);
  };
  static listClientConsent = (param) => {
    return axios.post(URLs.listClientConsent, param);
  };
  static deactivateClientConsent = (param) => {
    return axios.post(URLs.deactivateClientConsent, param);
  };
  static deactivateClientScreening = (param) => {
    return axios.post(URLs.deactivateClientScreening, param);
  };
  static loadJudicialDashboard = (param) => {
    return axios.post(URLs.loadJudicialDashboard, param);
  };
  static getJudicialUserList = (param) => {
    return axios.post(URLs.getJudicialUserList, param);
  };
  // static UploadDocumentUsingMultiPart = param => {
  //   return axios.post(URLs.UploadDocumentUsingMultiPart, param);
  // };
  static upsertClientDocument = (param) => {
    return axios.post(URLs.upsertClientDocument, param);
  };
  static UploadDocumentUsingMultiPart = (formData) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
      maxContentLength: 100000000,
      maxBodyLength: 100000000,
    };
    return axios.post(URLs.UploadDocumentUsingMultiPart, formData, config);
  };
  static listClientDocument = (param) => {
    return axios.post(URLs.listClientDocument, param);
  };
  static downloadClientDocument = (param) => {
    return axios.post(URLs.downloadClientDocument, param);
  };
  static archiveClientDocument = (param) => {
    return axios.post(URLs.archiveClientDocument, param);
  };
  static listGroupMembership = (param) => {
    return axios.post(URLs.listGroupMembership, param);
  };

  static getConsentFormTemplate = (param) => {
    return axios.post(URLs.getConsentFormTemplate, param);
  };
  static getComboFacility = (param) => {
    return axios.post(URLs.getComboFacility, param);
  };

  static downloadClientConsent = (param) => {
    return axios.post(URLs.downloadClientConsent, param);
  };
  static comboPractitionerAll = (param) => {
    return axios.post(URLs.comboPractitionerAll, param);
  };
  static comboFacilityAll = (param) => {
    return axios.post(URLs.comboFacilityAll, param);
  };
  static saveClientConsent = (param) => {
    return axios.post(URLs.saveClientConsent, param);
  };
  static getTreatmentPlan = (param) => {
    return axios.post(URLs.getTreatmentPlan, param);
  };
  static getDiagnosis = (param) => {
    return axios.post(URLs.getDiagnosis, param);
  };
  static upsertTreatmentPlanProblem = (param) => {
    return axios.post(URLs.upsertTreatmentPlanProblem, param);
  };
  static upsertMedicalAnalysis = (param) => {
    return axios.post(URLs.upsertMedicalAnalysis, param);
  };
  static upsertTreatmentPlanGoal = (param) => {
    return axios.post(URLs.upsertTreatmentPlanGoal, param);
  };
  static upsertTreatmentPlanObjective = (param) => {
    return axios.post(URLs.upsertTreatmentPlanObjective, param);
  };
  static upsertTreatmentPlanIntervention = (param) => {
    return axios.post(URLs.upsertTreatmentPlanIntervention, param);
  };
  static deleteTreatmentPlan = (param) => {
    return axios.post(URLs.deleteTreatmentPlan, param);
  };
  static getDiagnosisDescription = (param) => {
    return axios.post(URLs.getDiagnosisDescription, param);
  };
  static getDiagnosisCode = (param) => {
    return axios.post(URLs.getDiagnosisCode, param);
  };
  static listTreatmentPlan = (param) => {
    return axios.post(URLs.listTreatmentPlan, param);
  };
  static comboProblemTemplate = (param) => {
    return axios.post(URLs.comboProblemTemplate, param);
  };
  static comboGoalTemplate = (param) => {
    return axios.post(URLs.comboGoalTemplate, param);
  };
  static recurringCount = (param) => {
    return axios.post(URLs.recurringCount, param);
  };

  static loadCaseProfile = (param) => {
    return axios.post(URLs.loadCaseProfile, param);
  };

  static loadLocationAuthHistory = (param) => {
    return axios.post(URLs.loadLocationAuthHistory, param);
  };
  static screenAuthorization = (param) => {
    return axios.post(URLs.screenAuthorization, param);
  };
  static listConsentForm = (param) => {
    return axios.post(URLs.listConsentForm, param);
  };
  static upsertTreatmentPlanDimension = (param) => {
    return axios.post(URLs.upsertTreatmentPlanDimension, param);
  };
  static saveConsentSign = (param) => {
    return axios.post(URLs.saveConsentSign, param);
  };
  static consentFormDownload = (param) => {
    return axios.post(URLs.consentFormDownload, param);
  };
  static listMeeting = (param) => {
    return axios.post(URLs.listMeeting, param);
  };
  static upsertProgressGroupNote = (param) => {
    return axios.post(URLs.upsertProgressGroupNote, param);
  };
  static getProgressNoteGroup = (param) => {
    return axios.post(URLs.getProgressNoteGroup, param);
  };
  static getUserNotePatient = (param) => {
    return axios.post(URLs.getUserNotePatient, param);
  };
  static getUserNoteComment = (param) => {
    return axios.post(URLs.getUserNoteComment, param);
  };
  static addUserNoteComment = (param) => {
    return axios.post(URLs.addUserNoteComment, param);
  };
  static getUserNoteEmoji = (param) => {
    return axios.post(URLs.getUserNoteEmoji, param);
  };
  static addUserNoteEmoji = (param) => {
    return axios.post(URLs.addUserNoteEmoji, param);
  };
  static comboInterventionTemplate = (param) => {
    return axios.post(URLs.comboInterventionTemplate, param);
  };
  static getProgressNoteForIntervention = (param) => {
    return axios.post(URLs.getProgressNoteForIntervention, param);
  };
  static upsertPayer = (param) => {
    return axios.post(URLs.upsertPayer, param);
  };
  static listPayer = (param) => {
    return axios.post(URLs.listPayer, param);
  };
  static deletePayer = (param) => {
    return axios.post(URLs.deletePayer, param);
  };
  static getPayer = (param) => {
    return axios.post(URLs.getPayer, param);
  };
  static comboPayer = (param) => {
    return axios.post(URLs.comboPayer, param);
  };
  static upsertServiceCode = (param) => {
    return axios.post(URLs.upsertServiceCode, param);
  };
  static listServiceCode = (param) => {
    return axios.post(URLs.listServiceCode, param);
  };
  static deleteServiceCode = (param) => {
    return axios.post(URLs.deleteServiceCode, param);
  };
  static getServiceCode = (param) => {
    return axios.post(URLs.getServiceCode, param);
  };
  static comboCode = (param) => {
    return axios.post(URLs.comboCode, param);
  };
  static getCodeDescription = (param) => {
    return axios.post(URLs.getCodeDescription, param);
  };
  static appointmentNoteList = (param) => {
    return axios.post(URLs.appointmentNoteList, param);
  };
  static upsertAppointmentNote = (param) => {
    return axios.post(URLs.upsertAppointmentNote, param);
  };
  static getAppointmentNote = (param) => {
    return axios.post(URLs.getAppointmentNote, param);
  };
  static comboPlaceOfService = (param) => {
    return axios.post(URLs.comboPlaceOfService, param);
  };
  static listServiceLine = (param) => {
    return axios.post(URLs.listServiceLine, param);
  };
  static getServiceLine = (param) => {
    return axios.post(URLs.getServiceLine, param);
  };
  static upsertServiceLine = (param) => {
    return axios.post(URLs.upsertServiceLine, param);
  };
  static upsertAppointmentNoteAddendum = (param) => {
    return axios.post(URLs.upsertAppointmentNoteAddendum, param);
  };
  static listGroupAppointmentNote = (param) => {
    return axios.post(URLs.listGroupAppointmentNote, param);
  };
  static getGroupAppointmentNote = (param) => {
    return axios.post(URLs.getGroupAppointmentNote, param);
  };
  static comboTreatmentPlanIntervention = (param) => {
    return axios.post(URLs.comboTreatmentPlanIntervention, param);
  };
  static comboTreatmentPlanIntervention = (param) => {
    return axios.post(URLs.comboTreatmentPlanIntervention, param);
  };
  static listFeeSchedule = (param) => {
    return axios.post(URLs.listFeeSchedule, param);
  };
  static getFeeSchedule = (param) => {
    return axios.post(URLs.getFeeSchedule, param);
  };
  static upsertFeeSchedule = (param) => {
    return axios.post(URLs.upsertFeeSchedule, param);
  };
  static getTreatmentPlanGraph = (param) => {
    return axios.post(URLs.getTreatmentPlanGraph, param);
  };
  static listGroupServiceLine = (param) => {
    return axios.post(URLs.listGroupServiceLine, param);
  };
  static getGroupServiceLine = (param) => {
    return axios.post(URLs.getGroupServiceLine, param);
  };
  static getGroupBanner = (param) => {
    return axios.post(URLs.getGroupBanner, param);
  };
  static upsertGroupServiceLine = (param) => {
    return axios.post(URLs.upsertGroupServiceLine, param);
  };
  static upsertGroupAppointmentNote = (param) => {
    return axios.post(URLs.upsertGroupAppointmentNote, param);
  };
  static upsertGroupAppointmentNoteAddendum = (param) => {
    return axios.post(URLs.upsertGroupAppointmentNoteAddendum, param);
  };

  static upsertAppointmentNoteIntervention = (param) => {
    return axios.post(URLs.upsertAppointmentNoteIntervention, param);
  };
  static markAsComplete = (param) => {
    return axios.post(URLs.markAsComplete, param);
  };
  static getServiceLineCost = (param) => {
    return axios.post(URLs.getServiceLineCost, param);
  };
  static upsertPin = (param) => {
    return axios.post(URLs.upsertPin, param);
  };

  static loadSmallGroupDashboard = (param) => {
    return axios.post(URLs.loadSmallGroupDashboard, param);
  };
  static upsertTreatmentPlan = (param) => {
    return axios.post(URLs.upsertTreatmentPlan, param);
  };
  static listClientDiagnosis = (param) => {
    return axios.post(URLs.listClientDiagnosis, param);
  };
  static getClientDiagnosis = (param) => {
    return axios.post(URLs.getClientDiagnosis, param);
  };
  static deleteClientDiagnosis = (param) => {
    return axios.post(URLs.deleteClientDiagnosis, param);
  };
  static validatePinExists = (param) => {
    return axios.post(URLs.validatePinExists, param);
  };
  static cloneDiagnosis = (param) => {
    return axios.post(URLs.cloneDiagnosis, param);
  };
  static cloneTreatmentPlan = (param) => {
    return axios.post(URLs.cloneTreatmentPlan, param);
  };

  static rcmClientMerge = (param) => {
    return axios.post(URLs.rcmClientMerge, param);
  };
  static preValidatePin = (param) => {
    return axios.post(URLs.preValidatePin, param);
  };
  static validateClient = (param) => {
    return axios.post(URLs.validateClient, param);
  };
  static getAppAdvisor = (param) => {
    return axios.post(URLs.getAppAdvisor, param);
  };
  static getMediaVideoUrl = (param) => {
    return axios.post(URLs.getMediaVideoUrl, param);
  };
  static getDashboardMenu = (param) => {
    return axios.post(URLs.getDashboardMenu, param);
  };

  static listServiceCodeBundle = (param) => {
    return axios.post(URLs.listServiceCodeBundle, param);
  };
  static getServiceCodeBundle = (param) => {
    return axios.post(URLs.getServiceCodeBundle, param);
  };
  static getStudentNote = (param) => {
    return axios.post(URLs.getStudentNote, param);
  };
  static getStudentNoteComment = (param) => {
    return axios.post(URLs.getStudentNoteComment, param);
  };
  static addStudentNoteComment = (param) => {
    return axios.post(URLs.addStudentNoteComment, param);
  };
  static addStudentNoteEmoji = (param) => {
    return axios.post(URLs.addStudentNoteEmoji, param);
  };
  static getStudentNoteEmoji = (param) => {
    return axios.post(URLs.getStudentNoteEmoji, param);
  };
  static getTreatmentPlanAccordin = (param) => {
    return axios.post(URLs.getTreatmentPlanAccordin, param);
  };

  static getAdvisorCost = (param) => {
    return axios.post(URLs.getAdvisorCost, param);
  };
  static upsertAdvisorCost = (param) => {
    return axios.post(URLs.upsertAdvisorCost, param);
  };
  static claimsPdfDownload = (param) => {
    return axios.post(URLs.claimsPdfDownload, param);
  };
  static getClientService = (param) => {
    return axios.post(URLs.getClientService, param);
  };

  static getDashboardDailySchedule = (param) => {
    return axios.post(URLs.getDashboardDailySchedule, param);
  };
  static getDashboardDailyCaseLoad = (param) => {
    return axios.post(URLs.getDashboardDailyCaseLoad, param);
  };
  static getDashboardAlert = (param) => {
    return axios.post(URLs.getDashboardAlert, param);
  };
  static addClientInsuranceEligibility = (param) => {
    return axios.post(URLs.addClientInsuranceEligibility, param);
  };
  static editClientInsuranceEligibility = (param) => {
    return axios.post(URLs.editClientInsuranceEligibility, param);
  };
  static deleteClientInsuranceEligibility = (param) => {
    return axios.post(URLs.deleteClientInsuranceEligibility, param);
  };

  static dischargeSummarySave = (param) => {
    return axios.post(URLs.dischargeSummarySave, param);
  };
  static getClientAppointment = (param) => {
    return axios.post(URLs.getClientAppointment, param);
  };
  static getGroupAppointment = (param) => {
    return axios.post(URLs.getGroupAppointment, param);
  };
  static unsignForm = (param) => {
    return axios.post(URLs.unsignForm, param);
  };
  static getFilters = (param) => {
    return axios.post(URLs.getFilters, param);
  };
  static listLabOrder = (param) => {
    return axios.post(URLs.listLabOrder, param);
  };
  static getLabOrder = (param) => {
    return axios.post(URLs.getLabOrder, param);
  };
  static upsertLabOrder = (param) => {
    return axios.post(URLs.upsertLabOrder, param);
  };
  static comboDiagnosisList = (param) => {
    return axios.post(URLs.comboDiagnosisList, param);
  };
  static getMedicationNote = (param) => {
    return axios.post(URLs.getMedicationNote, param);
  };
  static upsertClientGroupAppointment = (param) => {
    return axios.post(URLs.upsertClientGroupAppointment, param);
  };
  static signGroupAppointmentNote = (param) => {
    return axios.post(URLs.signGroupAppointmentNote, param);
  };
  static listServiceLinePayment = (param) => {
    return axios.post(URLs.listServiceLinePayment, param);
  };
  static getServiceLinePayment = (param) => {
    return axios.post(URLs.getServiceLinePayment, param);
  };
  static upsertServiceLinePayment = (param) => {
    return axios.post(URLs.upsertServiceLinePayment, param);
  };
  static deleteServiceLinePayment = (param) => {
    return axios.post(URLs.deleteServiceLinePayment, param);
  };
  static comboSupervisor = (param) => {
    return axios.post(URLs.comboSupervisor, param);
  };
  static locationDayHistory = (param) => {
    return axios.post(URLs.locationDayHistory, param);
  };
  static upsertPaymentClient = (param) => {
    return axios.post(URLs.upsertPaymentClient, param);
  };
  static getInsurancePriorityOrder = (param) => {
    return axios.post(URLs.getInsurancePriorityOrder, param);
  };
  static upsertInsurancePriorityOrder = (param) => {
    return axios.post(URLs.upsertInsurancePriorityOrder, param);
  };
  static listCWPayment = (param) => {
    return axios.post(URLs.listCWPayment, param);
  };
  static getCWPayment = (param) => {
    return axios.post(URLs.getCWPayment, param);
  };
  static deleteCWPayment = (param) => {
    return axios.post(URLs.deleteCWPayment, param);
  };
  static pastMeetingInfo = (param) => {
    return axios.post(URLs.pastMeetingInfo, param);
  };
  static listPastMeetingInfo = (param) => {
    return axios.post(URLs.listPastMeetingInfo, param);
  };
  static comboAppointmentTreatmentPlan = (param) => {
    return axios.post(URLs.comboAppointmentTreatmentPlan, param);
  };
  static linkAppointment = (param) => {
    return axios.post(URLs.linkAppointment, param);
  };
  static appointmentLinkGet = (param) => {
    return axios.post(URLs.appointmentLinkGet, param);
  };
  static getBasicProfile = (param) => {
    return axios.post(URLs.getBasicProfile, param);
  };
  static getInvitedList = (param) => {
    return axios.post(URLs.getInvitedList, param);
  };
  static inviteResend = (param) => {
    return axios.post(URLs.inviteResend, param);
  };
  static suspiciousTermAck = (param) => {
    return axios.post(URLs.suspiciousTermAck, param);
  };
  static getBroadcastFilter = (param) => {
    return axios.post(URLs.getBroadcastFilter, param);
  };
  static sendBroadcast = (param) => {
    return axios.post(URLs.sendBroadcast, param);
  };
  static getServiceLineDiagnosis = (param) => {
    return axios.post(URLs.getServiceLineDiagnosis, param);
  };
  static upsertServiceLineDiagnosis = (param) => {
    return axios.post(URLs.upsertServiceLineDiagnosis, param);
  };
  static comboBroadcastTemplate = (param) => {
    return axios.post(URLs.comboBroadcastTemplate, param);
  };
  static upsertBroadcastTemplate = (param) => {
    return axios.post(URLs.upsertBroadcastTemplate, param);
  };
  static deleteNoteType = (param) => {
    return axios.post(URLs.deleteNoteType, param);
  };

  static listClientAuthorization = (param) => {
    return axios.post(URLs.listClientAuthorization, param);
  };
  static getClientAuthorization = (param) => {
    return axios.post(URLs.getClientAuthorization, param);
  };
  static deleteClientAuthorization = (param) => {
    return axios.post(URLs.deleteClientAuthorization, param);
  };
  static upsertClientAuthorization = (param) => {
    return axios.post(URLs.upsertClientAuthorization, param);
  };
  static getNoteTypeArchive = (param) => {
    return axios.post(URLs.getNoteTypeArchive, param);
  };
  static getNoteTypeList = (param) => {
    return axios.post(URLs.getNoteTypeList, param);
  };

  static generateCallToken = (param) => {
    return axios.post(URLs.generateCallToken, param);
  };
  static badgeUpdate = (param) => {
    return axios.post(URLs.badgeUpdate, param);
  };
  static badgeComboGet = (param) => {
    return axios.post(URLs.badgeComboGet, param);
  };
  static panasScaleMoodsGet = (param) => {
    return axios.post(URLs.panasScaleMoodsGet, param);
  };
  static panasTagUpsert = (param) => {
    return axios.post(URLs.panasTagUpsert, param);
  };
  static bedAssignmentGetAll = (param) => {
    return axios.post(URLs.bedAssignmentGetAll, param);
  };
  static bedAssignmentUpsert = (param) => {
    return axios.post(URLs.bedAssignmentUpsert, param);
  };
  static upcomingDischargesGetAll = (param) => {
    return axios.post(URLs.upcomingDischargesGetAll, param);
  };
  static upcomingAdmissions = (param) => {
    return axios.post(URLs.upcomingAdmissions, param);
  };
  static admissionCancel = (param) => {
    return axios.post(URLs.admissionCancel, param);
  };
  static panasTagDelete = (param) => {
    return axios.post(URLs.panasTagDelete, param);
  };
  static dynamicGridList = (param) => {
    return axios.post(URLs.dynamicGridList, param);
  };
  static getGrid = (param) => {
    return axios.post(URLs.getGrid, param);
  };
  static getGridData = (param) => {
    return axios.post(URLs.getGridData, param);
  };
  static upsertFormData = (param) => {
    return axios.post(URLs.upsertFormData, param);
  };
  static formDataGet = (param) => {
    return axios.post(URLs.formDataGet, param);
  };
  static dynamicGridColumnConfigurationGet = (param) => {
    return axios.post(URLs.dynamicGridColumnConfigurationGet, param);
  };
  static dynamicGridDataGet = (param) => {
    return axios.post(URLs.dynamicGridDataGet, param);
  };
  static memberTrendsGet = (param) => {
    return axios.post(URLs.memberTrendsGet, param);
  };
  static advisorMemberTrendsHistoryGet = (param) => {
    return axios.post(URLs.advisorMemberTrendsHistoryGet, param);
  };
  static dashboardAnalyticsGet = (param) => {
    return axios.post(URLs.dashboardAnalyticsGet, param);
  };

  static userActivityGet = (param) => {
    return axios.post(URLs.userActivityGet, param);
  };
  static userSuspiciousWordGet = (param) => {
    return axios.post(URLs.userSuspiciousWordGet, param);
  };
  static userNotificationGet = (param) => {
    return axios.post(URLs.userNotificationGet, param);
  };
  static userMemberTrendHistory = (param) => {
    return axios.post(URLs.userMemberTrendHistory, param);
  };
  static upsertClientNote = (param) => {
    return axios.post(URLs.upsertClientNote, param);
  };
  static getPreviousNotes = (param) => {
    return axios.post(URLs.getPreviousNotes, param);
  };
  static getDynamicGridFilter = (param) => {
    return axios.post(URLs.getDynamicGridFilter, param);
  };
  static getMultistepTab = (param) => {
    return axios.post(URLs.getMultistepTab, param);
  };
  static memberTrendTriggerHistory = (param) => {
    return axios.post(URLs.memberTrendTriggerHistory, param);
  };
  static memberHeaderInfoGet = (param) => {
    return axios.post(URLs.memberHeaderInfoGet, param);
  };

  static getAssessmentDetail = (param) => {
    return axios.post(URLs.getAssessmentDetail, param);
  };

  static assignMultipleV2 = (param) => {
    return axios.post(URLs.assignMultipleV2, param);
  };
  static chatFriendListGet = (param) => {
    return axios.post(URLs.chatFriendListGet, param);
  };

  static listClientV2 = (param) => {
    return axios.post(URLs.listClientV2, param);
  };

  static populationActivitiesClientListGet = (param) => {
    return axios.post(URLs.populationActivitiesClientListGet, param);
  };

  static getCourses = (param) => {
    return axios.post(URLs.listCourses, param);
  };
  static courseAssign = (param) => {
    return axios.post(URLs.courseAssign, param);
  };

  static adminstrationListCourse = (param) => {
    return axios.post(URLs.adminstrationListCourse, param);
  };

  // static adminstrationGetCourse = (param) => {
  //   return axios.post(URLs.adminstrationGetCourse, param);
  // };

  // static adminstrationCourseUpsert = (param) => {
  //   return axios.post(URLs.adminstrationCourseUpsert, param);
  // };

  static adminstrationCourseDelete = (param) => {
    return axios.post(URLs.adminstrationCourseDelete, param);
  };

  static addAppSubscriberCourse = (param) => {
    return axios.post(URLs.addAppSubscriberCourse, param);
  };

  static deleteAppSubscriberCourse = (param) => {
    return axios.post(URLs.deleteAppSubscriberCourse, param);
  };

  static appSubscriberCompleteCourse = (param) => {
    return axios.post(URLs.appSubscriberCompleteCourse, param);
  };

  static appSubscribersupdateCase = (param) => {
    return axios.post(URLs.appSubscribersupdateCase, param);
  };

  static adminstrationListCenter = (param) => {
    return axios.post(URLs.adminstrationListCenter, param);
  };
  // static adminstrationGetCenter = (param) => {
  //   return axios.post(URLs.adminstrationGetCenter, param);
  // };

  // static adminstrationCenterUpsert = (param) => {
  //   return axios.post(URLs.adminstrationCenterUpsert, param);
  // };

  static adminstrationCenterDelete = (param) => {
    return axios.post(URLs.adminstrationCenterDelete, param);
  };

  static updateStaffCenter = (param) => {
    return axios.post(URLs.updateStaffCenter, param);
  };

  static comboCenterConsent = (param) => {
    return axios.post(URLs.comboCenterConsent, param);
  };

  static centerProgramTypeGet = (param) => {
    return axios.post(URLs.centerProgramTypeGet, param);
  };

  static getCenterAdvisorDefaultCosts = (param) => {
    return axios.post(URLs.getCenterAdvisorDefaultCosts, param);
  };

  static updateCenterAdvisorDefaultCost = (param) => {
    return axios.post(URLs.updateCenterAdvisorDefaultCost, param);
  };

  static reactivateCenter = (param) => {
    return axios.post(URLs.reactivateCenter, param);
  };

  static getContract = (param) => {
    return axios.post(URLs.getContract, param);
  };

  static upsertContract = (param) => {
    return axios.post(URLs.upsertContract, param);
  };

  static deleteContract = (param) => {
    return axios.post(URLs.deleteContract, param);
  };

  static closeContract = (param) => {
    return axios.post(URLs.closeContract, param);
  };
  static getContractTemplate = (param) => {
    return axios.post(URLs.getContractTemplate, param);
  };

  static upsertContractTemplate = (param) => {
    return axios.post(URLs.upsertContractTemplate, param);
  };

  static getStudentContractTemplate = (param) => {
    return axios.post(URLs.getStudentContractTemplate, param);
  };

  static createContractFromTemplate = (param) => {
    return axios.post(URLs.createContractFromTemplate, param);
  };

  static menuContractTemplate = (param) => {
    return axios.post(URLs.menuContractTemplate, param);
  };

  static archiveContractTemplate = (param) => {
    return axios.post(URLs.archiveContractTemplate, param);
  };

  static addFavoriteTemplate = (param) => {
    return axios.post(URLs.addFavoriteTemplate, param);
  };

  static removeFavoriteTemplate = (param) => {
    return axios.post(URLs.removeFavoriteTemplate, param);
  };

  static adminstrationStaffDelete = (param) => {
    return axios.post(URLs.adminstrationStaffDelete, param);
  };

  static updateStaffEmail = (param) => {
    return axios.post(URLs.updateStaffEmail, param);
  };

  static updateStaffPassword = (param) => {
    return axios.post(URLs.updateStaffPassword, param);
  };

  static uploadStaffImage = (param) => {
    return axios.post(URLs.uploadStaffImage, param);
  };

  static getStaffShiftLog = (param) => {
    return axios.post(URLs.getStaffShiftLog, param);
  };

  static addStaffShiftLog = (param) => {
    return axios.post(URLs.addStaffShiftLog, param);
  };

  static staffReactivate = (param) => {
    return axios.post(URLs.staffReactivate, param);
  };

  static isStaffProBio = (param) => {
    return axios.post(URLs.isStaffProBio, param);
  };

  static updateStaffProBio = (param) => {
    return axios.post(URLs.updateStaffProBio, param);
  };

  static validateStaffPin = (param) => {
    return axios.post(URLs.validateStaffPin, param);
  };

  static getStaffPractitioner = (param) => {
    return axios.post(URLs.getStaffPractitioner, param);
  };

  static clinicalCenterGet = (param) => {
    return axios.post(URLs.clinicalCenterGet, param);
  };

  static getSurveys = (param) => {
    return axios.post(URLs.getSurveys, param);
  };

  static surveyAssign = (param) => {
    return axios.post(URLs.surveyAssign, param);
  };

  static surveyGetToAssign = (param) => {
    return axios.post(URLs.surveyGetToAssign, param);
  };

  static getSurveyQuestionMultiple = (param) => {
    return axios.post(URLs.getSurveyQuestionMultiple, param);
  };

  static surveyTaskMarkInComplete = (param) => {
    return axios.post(URLs.surveyTaskMarkInComplete, param);
  };

  static getStudentSurveyDashboardData = (param) => {
    return axios.post(URLs.getStudentSurveyDashboardData, param);
  };

  static surveyAnswersGet = (param) => {
    return axios.post(URLs.surveyAnswersGet, param);
  };

  static addAppSubscriberSurvey = (param) => {
    return axios.post(URLs.addAppSubscriberSurvey, param);
  };

  static deleteAppSubscriberSurvey = (param) => {
    return axios.post(URLs.deleteAppSubscriberSurvey, param);
  };
  static listSurveyQuestionCategory = (param) => {
    return axios.post(URLs.listSurveyQuestionCategory, param);
  };
  static listSurveyQuestionCategoryAssessment = (param) => {
    return axios.post(URLs.listSurveyQuestionCategoryAssessment, param);
  };
  static listSurveyQuestionAssessment = (param) => {
    return axios.post(URLs.listSurveyQuestionAssessment, param);
  };
  static getSurveyScore = (param) => {
    return axios.post(URLs.getSurveyScore, param);
  };
  static loadAppSubSurveyDashboard = (param) => {
    return axios.post(URLs.loadAppSubSurveyDashboard, param);
  };
  static appSubSurveyAnsGet = (param) => {
    return axios.post(URLs.appSubSurveyAnsGet, param);
  };
  static listSurveyQuestionDischarge = (param) => {
    return axios.post(URLs.listSurveyQuestionDischarge, param);
  };

  static getSurveysV2 = (param) => {
    return axios.post(URLs.getSurveysV2, param);
  };

  //YOUU API'S START

  static userDataGet = (param) => {
    return axios.post(URLs.userDataGet, param);
  };

  static loadDefaultValues = (param) => {
    return axios.post(URLs.loadDefaultValues, param);
  };

  static sideBarMenuGet = (param) => {
    return axios.post(URLs.sideBarMenuGet, param);
  };

  static allGet = (param) => {
    return axios.post(URLs.allGet, param);
  };

  static getDynamicScreenTab = (param) => {
    return axios.post(URLs.getDynamicScreenTab, param);
  };

  static getFormFieldMetaData = (param) => {
    return axios.post(URLs.getFormFieldMetaData, param);
  };

  static getUserInfo = (param) => {
    return axios.post(URLs.getUserInfo, param);
  };

  //COMBO API's

  static comboListGet = (param) => {
    return axios.post(URLs.comboListGet, param);
  };

  static serviceCodeListGet = (param) => {
    return axios.post(URLs.serviceCodeListGet, param);
  };

  static counselorListGet = (param) => {
    return axios.post(URLs.counselorListGet, param);
  };

  static facilityListGet = (param) => {
    return axios.post(URLs.facilityListGet, param);
  };

  static clientListDropdown = (param) => {
    return axios.post(URLs.clientListDropdown, param);
  };

  static getClientAlbum = (param) => {
    return axios.post(URLs.getClientAlbum, param);
  };

  static postListGet = (param) => {
    return axios.post(URLs.postListGet, param);
  };

  static clientFriendAllGet = (param) => {
    return axios.post(URLs.clientFriendAllGet, param);
  };

  //COURSE API'S

  static assignedCourseListGet = (param) => {
    return axios.post(URLs.assignedCourseListGet, param);
  };

  static courseAssign = (param) => {
    return axios.post(URLs.courseAssign, param);
  };

  static courseUnassign = (param) => {
    return axios.post(URLs.courseUnassign, param);
  };

  static courseMarkAsComplete = (param) => {
    return axios.post(URLs.courseMarkAsComplete, param);
  };

  static courseOptionGet = (param) => {
    return axios.post(URLs.courseOptionGet, param);
  };

  static courseOptionGet = (param) => {
    return axios.post(URLs.courseOptionGet, param);
  };

  static courseUpsert = (param) => {
    return axios.post(URLs.courseUpsert, param);
  };

  static courseGet = (param) => {
    return axios.post(URLs.courseGet, param);
  };

  static courseListGet = (param) => {
    return axios.post(URLs.courseListGet, param);
  };

  static courseStatusUpdate = (param) => {
    return axios.post(URLs.courseStatusUpdate, param);
  };

  //CENTER API'S

  static centerOptionGet = (param) => {
    return axios.post(URLs.centerOptionGet, param);
  };

  static centerUpsert = (param) => {
    return axios.post(URLs.centerUpsert, param);
  };

  static centerListGet = (param) => {
    return axios.post(URLs.centerListGet, param);
  };

  static centerGet = (param) => {
    return axios.post(URLs.centerGet, param);
  };

  static centerStatusUpdate = (param) => {
    return axios.post(URLs.centerStatusUpdate, param);
  };

  //JOURNAL API'S

  static getAllJournal = (param) => {
    return axios.post(URLs.getAllJournal, param);
  };

  static getAllJournalComments = (param) => {
    return axios.post(URLs.getAllJournalComments, param);
  };

  static journalAdd = (param) => {
    return axios.post(URLs.journalAdd, param);
  };

  static journalCommentUpsert = (param) => {
    return axios.post(URLs.journalCommentUpsert, param);
  };

  //TASK API'S

  static taskListGet = (param) => {
    return axios.post(URLs.taskListGet, param);
  };

  static taskUnAssign = (param) => {
    return axios.post(URLs.taskUnAssign, param);
  };

  static taskAssign = (param) => {
    return axios.post(URLs.taskAssign, param);
  };

  static taskMarkComplete = (param) => {
    return axios.post(URLs.taskMarkComplete, param);
  };

  static taskOptionGet = (param) => {
    return axios.post(URLs.taskOptionGet, param);
  };

  static timeTravelListGet = (param) => {
    return axios.post(URLs.timeTravelListGet, param);
  };

  static triggersListGet = (param) => {
    return axios.post(URLs.triggersListGet, param);
  };

  static assessmentsListGet = (param) => {
    return axios.post(URLs.assessmentsListGet, param);
  };

  static assessmentsMultipleAssign = (param) => {
    return axios.post(URLs.assessmentsMultipleAssign, param);
  };

  static assessmentsMarkAsDiscard = (param) => {
    return axios.post(URLs.assessmentsMarkAsDiscard, param);
  };

  static surveyQuestionCategoryOptionGet = (param) => {
    return axios.post(URLs.surveyQuestionCategoryOptionGet, param);
  };

  //INTAKE API'S

  static intakeClientUpsert = (param) => {
    return axios.post(URLs.intakeClientUpsert, param);
  };

  static intakeInsuranceUpsert = (param) => {
    return axios.post(URLs.intakeInsuranceUpsert, param);
  };

  static intakeInsurancePriorityOrderGet = (param) => {
    return axios.post(URLs.intakeInsurancePriorityOrderGet, param);
  };

  static intakeInsurancePriorityOrderUpdate = (param) => {
    return axios.post(URLs.intakeInsurancePriorityOrderUpdate, param);
  };

  static intakeMedicalHistoryUpsert = (param) => {
    return axios.post(URLs.intakeMedicalHistoryUpsert, param);
  };

  static trendsListGet = (param) => {
    return axios.post(URLs.trendsListGet, param);
  };

  static intakeFormDataGet = (param) => {
    return axios.post(URLs.intakeFormDataGet, param);
  };

  static intakeEnrollNow = (param) => {
    return axios.post(URLs.intakeEnrollNow, param);
  };

  static preIntakeGridReports = (param) => {
    return axios.post(URLs.preIntakeGridReports, param);
  };

  static preIntakeDefaultAssessmentGet = (param) => {
    return axios.post(URLs.preIntakeDefaultAssessmentGet, param);
  };

  //GROWTH PLAN API'S

  static growthPlanTemplateUpsert = (param) => {
    return axios.post(URLs.growthPlanTemplateUpsert, param);
  };

  static growthPlanTemplateMenuGet = (param) => {
    return axios.post(URLs.growthPlanTemplateMenuGet, param);
  };

  static growthPlanTemplateGet = (param) => {
    return axios.post(URLs.growthPlanTemplateGet, param);
  };

  static growthPlanTemplateFavoriteAdd = (param) => {
    return axios.post(URLs.growthPlanTemplateFavoriteAdd, param);
  };

  static growthPlanTemplateFavoriteDelete = (param) => {
    return axios.post(URLs.growthPlanTemplateFavoriteDelete, param);
  };

  static growthPlanTemplateDelete = (param) => {
    return axios.post(URLs.growthPlanTemplateDelete, param);
  };

  static clientGrowthPlanUpsert = (param) => {
    return axios.post(URLs.clientGrowthPlanUpsert, param);
  };

  static clientGrowthPlanGet = (param) => {
    return axios.post(URLs.clientGrowthPlanGet, param);
  };

  static clientGrowthPlanListGet = (param) => {
    return axios.post(URLs.clientGrowthPlanListGet, param);
  };

  static clientGrowthPlanDelete = (param) => {
    return axios.post(URLs.clientGrowthPlanDelete, param);
  };

  static clientGrowthPlanFromTemplateInsert = (param) => {
    return axios.post(URLs.clientGrowthPlanFromTemplateInsert, param);
  };

  static clientGrowthPlanClose = (param) => {
    return axios.post(URLs.clientGrowthPlanClose, param);
  };

  //STAFF API'S

  static staffUpsert = (param) => {
    return axios.post(URLs.staffUpsert, param);
  };

  static staffListGet = (param) => {
    return axios.post(URLs.staffListGet, param);
  };

  static staffGet = (param) => {
    return axios.post(URLs.staffGet, param);
  };

  static staffPermission = (param) => {
    return axios.post(URLs.staffPermission, param);
  };

  static updateStaffPermission = (param) => {
    return axios.post(URLs.updateStaffPermission, param);
  };

  static staffMobileAppInviteSend = (param) => {
    return axios.post(URLs.staffMobileAppInviteSend, param);
  };

  //SMALLGROUP API'S

  static getSmallGroupList = (param) => {
    return axios.post(URLs.getSmallGroupList, param);
  };

  static smallGroupGet = (param) => {
    return axios.post(URLs.smallGroupGet, param);
  };

  static smallGroupUpsert = (param) => {
    return axios.post(URLs.smallGroupUpsert, param);
  };

  static smallGroupReferral = (param) => {
    return axios.post(URLs.smallGroupReferral, param);
  };

  static smallGroupUserListGet = (param) => {
    return axios.post(URLs.smallGroupUserListGet, param);
  };

  static smallGroupUserRemove = (param) => {
    return axios.post(URLs.smallGroupUserRemove, param);
  };

  static smallGroupLocationGet = (param) => {
    return axios.post(URLs.smallGroupLocationGet, param);
  };

  static smallGroupLocationUpdate = (param) => {
    return axios.post(URLs.smallGroupLocationUpdate, param);
  };

  static smallGroupImagesGet = (param) => {
    return axios.post(URLs.smallGroupImagesGet, param);
  };

  static upsertBroadcastTemplate = (param) => {
    return axios.post(URLs.upsertBroadcastTemplate, param);
  };

  static broadcastSend = (param) => {
    return axios.post(URLs.broadcastSend, param);
  };

  static surveyAssignMultiple = (param) => {
    return axios.post(URLs.surveyAssignMultiple, param);
  };

  static surveyAnswerUpsert = (param) => {
    return axios.post(URLs.surveyAnswerUpsert, param);
  };

  static clientSurveyListGet = (param) => {
    return axios.post(URLs.clientSurveyListGet, param);
  };

  static surveyQuestionCategoryListGet = (param) => {
    return axios.post(URLs.surveyQuestionCategoryListGet, param);
  };

  static surveyQuestionHierarchialGet = (param) => {
    return axios.post(URLs.surveyQuestionHierarchialGet, param);
  };

  static surveyMarkAsDiscard = (param) => {
    return axios.post(URLs.surveyMarkAsDiscard, param);
  };

  static surveyAssignedListGet = (param) => {
    return axios.post(URLs.surveyAssignedListGet, param);
  };

  static groupUpsert = (param) => {
    return axios.post(URLs.groupUpsert, param);
  };

  static groupGet = (param) => {
    return axios.post(URLs.groupGet, param);
  };

  static groupListGet = (param) => {
    return axios.post(URLs.groupListGet, param);
  };

  static clientGroupUpsert = (param) => {
    return axios.post(URLs.clientGroupUpsert, param);
  };

  static clientsNotParticipatingInGroup = (param) => {
    return axios.post(URLs.clientsNotParticipatingInGroup, param);
  };

  static clientGroupGet = (param) => {
    return axios.post(URLs.clientGroupGet, param);
  };

  static groupAppointmentGroupGet = (param) => {
    return axios.post(URLs.groupAppointmentGroupGet, param);
  };

  static groupAppointmentGroupNotesGet = (param) => {
    return axios.post(URLs.groupAppointmentGroupNotesGet, param);
  };

  static groupServiceLinesGet = (param) => {
    return axios.post(URLs.groupServiceLinesGet, param);
  };

  static groupDeactivate = (param) => {
    return axios.post(URLs.groupDeactivate, param);
  };

  static couponListGet = (param) => {
    return axios.post(URLs.couponListGet, param);
  };

  static upsertCouponCode = (param) => {
    return axios.post(URLs.upsertCouponCode, param);
  };

  static deleteCouponCode = (param) => {
    return axios.post(URLs.deleteCouponCode, param);
  };

  static getCouponCode = (param) => {
    return axios.post(URLs.getCouponCode, param);
  };

  //Breathalyzer
  static getBreathalyzerSchedule = (param) => {
    return axios.post(URLs.getBreathalyzerSchedule, param);
  };

  static listBreathalyzerSchedule = (param) => {
    return axios.post(URLs.listBreathalyzerSchedule, param);
  };

  static upsertBreathalyzerSchedule = (param) => {
    return axios.post(URLs.upsertBreathalyzerSchedule, param);
  };

  static breathalyzerPushATest = (param) => {
    return axios.post(URLs.breathalyzerPushATest, param);
  };

  static breathalyzerEnable = (param) => {
    return axios.post(URLs.breathalyzerEnable, param);
  };

  static getBreathalyzerTestResult = (param) => {
    return axios.post(URLs.getBreathalyzerTestResult, param);
  };

  static getBreathalyzerTestUpcoming = (param) => {
    return axios.post(URLs.getBreathalyzerTestUpcoming, param);
  };

  static breathalyzerTestUpcomingToggle = (param) => {
    return axios.post(URLs.breathalyzerTestUpcomingToggle, param);
  };

  static getStudentBreathalyzerTestResult = (param) => {
    return axios.post(URLs.getStudentBreathalyzerTestResult, param);
  };

  static miscellaneousTaskGet = (param) => {
    return axios.post(URLs.miscellaneousTaskGet, param);
  };

  static miscellaneousTaskListGet = (param) => {
    return axios.post(URLs.miscellaneousTaskListGet, param);
  };

  static miscellaneousTaskDelete = (param) => {
    return axios.post(URLs.miscellaneousTaskDelete, param);
  };

  static miscellaneousTaskUpsert = (param) => {
    return axios.post(URLs.miscellaneousTaskUpsert, param);
  };

  static miscellaneousNoteTypeInsert = (param) => {
    return axios.post(URLs.miscellaneousNoteTypeInsert, param);
  };

  static miscellaneousComboNoteTypesListGet = (param) => {
    return axios.post(URLs.miscellaneousComboNoteTypesListGet, param);
  };

  static miscellaneousNoteTypeDelete = (param) => {
    return axios.post(URLs.miscellaneousNoteTypeDelete, param);
  };

  static miscellaneousNoteTypesListGet = (param) => {
    return axios.post(URLs.miscellaneousNoteTypesListGet, param);
  };

  static miscellaneousNoteTypeGet = (param) => {
    return axios.post(URLs.miscellaneousNoteTypeGet, param);
  };

  static practitionerOptionsGet = (param) => {
    return axios.post(URLs.practitionerOptionsGet, param);
  };

  static facilityOptionsGet = (param) => {
    return axios.post(URLs.facilityOptionsGet, param);
  };

  static appointmentListGet = (param) => {
    return axios.post(URLs.appointmentListGet, param);
  };

  static appointmentUpsert = (param) => {
    return axios.post(URLs.appointmentUpsert, param);
  };

  static groupAppointmentUpsert = (param) => {
    return axios.post(URLs.groupAppointmentUpsert, param);
  };

  static blockAppointmentUpsert = (param) => {
    return axios.post(URLs.blockAppointmentUpsert, param);
  };

  static staffPinValidate = (param) => {
    return axios.post(URLs.staffPinValidate, param);
  };

  static appointmentNoteGet = (param) => {
    return axios.post(URLs.appointmentNoteGet, param);
  };

  static appointmentNoteUpsert = (param) => {
    return axios.post(URLs.appointmentNoteUpsert, param);
  };

  static appointmentNoteListGet = (param) => {
    return axios.post(URLs.appointmentNoteListGet, param);
  };

  static appointmentNoteAddendumUpsert = (param) => {
    return axios.post(URLs.appointmentNoteAddendumUpsert, param);
  };

  static groupAppointmentNoteGet = (param) => {
    return axios.post(URLs.groupAppointmentNoteGet, param);
  };

  static groupAppointmentNoteUpsert = (param) => {
    return axios.post(URLs.groupAppointmentNoteUpsert, param);
  };

  static comboPractitioner = (param) => {
    return axios.post(URLs.comboPractitioner, param);
  };

  static comboFacility = (param) => {
    return axios.post(URLs.comboFacility, param);
  };

  static groupOptionGet = (param) => {
    return axios.post(URLs.groupOptionGet, param);
  };

  static clientGroupOptionsGet = (param) => {
    return axios.post(URLs.clientGroupOptionsGet, param);
  };
}
