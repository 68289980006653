/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory, Link, withRouter } from "react-router-dom";

export function MoreOption({ more }) {
  const history = useHistory();

  return (
    <>
      {/*begin::Navigation*/}
      <ul className="navi navi-hover py-2">
        {more &&
          more.map((item) => {
            return (
              <>
                <li className="navi-item">
                  <span onClick={item.OnClick} className="navi-link">
                    <span className="navi-icon">
                      <i className="flaticon2-list-3"></i>
                    </span>
                    <span className="navi-text">{item.ActionName}</span>
                  </span>
                </li>
                {/* <li className="navi-separator my-3"></li> */}
              </>
            );
          })}
      </ul>
      {/*end::Navigation*/}
    </>
  );
}
