import { toast } from "react-toastify";
export const ResponseHandler = (response) => {
  if (response?.data?.ResponseCode === 1) {
    if (response?.data?.Message) {
      toast.dismiss();
      toast.success(response?.data?.Message,{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    }
    return response.data;
  } else if (response?.data?.ResponseCode === 9) {
    toast.dismiss();
    toast.error(response?.data?.Message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return response.data;
  } else {
    toast.dismiss();
    toast.error(response?.data?.Message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return response.data;
  }
};
