import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";

export function* getDashboard(action) {
  try {
    const response = yield API.getDashboard();
    /*yield put({
      type: ActionTypes.TODO_GET_ALL_REQUEST,
    });*/
    yield put({
      type: ActionTypes.DASHBOARD_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARDT_GET_FAILURE,
    });
  }
}

export function* getStudentsSearch(action) {
  try {
    const response = yield API.getStudentsSearch(action.payload);
    yield put({
      type: ActionTypes.STUDENTS_SEARCH_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENTS_SEARCH_FAILURE,
    });
  }
}

export function* getDashboardLoadAppUsers(action) {
  try {
    const response = yield API.getDashboardLoadAppUsers();
    yield put({
      type: ActionTypes.GET_DASHBOARD_LOAD_APP_USERS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DASHBOARD_LOAD_APP_USERS_FAILURE,
    });
  }
}
export function* loadSmallGroupDashboard(action) {
  try {
    const response = yield API.loadSmallGroupDashboard();
    yield put({
      type: ActionTypes.LOAD_SMALLGROUP_DASHBOARD_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_SMALLGROUP_DASHBOARD_FAILURE,
    });
  }
}
export function* getReports(action) {
  try {
    const response = yield API.getReports(action.payload);
    yield put({
      type: ActionTypes.GET_REPORTS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_REPORTS_FAILURE,
    });
  }
}

export function* loadJudicialDashboard(action) {
  try {
    const response = yield API.loadJudicialDashboard(action.payload);
    yield put({
      type: ActionTypes.LOAD_JUDICIAL_DASHBOARD_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_JUDICIAL_DASHBOARD_FAILURE,
    });
  }
}

export function* getJudicialUserList(action) {
  try {
    const response = yield API.getJudicialUserList(action.payload);
    yield put({
      type: ActionTypes.GET_JUDICIAL_USER_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_JUDICIAL_USER_LIST_FAILURE,
    });
  }
}
export function* getAppAdvisor(action) {
  try {
    const response = yield API.getAppAdvisor(action.payload);
    yield put({
      type: ActionTypes.GET_APP_ADVISOR_SUCCESS,
      payload: JSON.parse(response.data.data)[0].AppAdvisor,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_ADVISOR_FAILURE,
    });
  }
}
export function* getDashboardMenu(action) {
  try {
    const response = yield API.getDashboardMenu(action.payload);
    yield put({
      type: ActionTypes.GET_DASHBOARD_MENU_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DASHBOARD_MENU_FAILURE,
    });
  }
}
export function* suspiciousTermAck(action) {
  try {
    const response = yield API.suspiciousTermAck(action.payload);
    yield put({
      type: ActionTypes.SUSPICIOUS_TERM_ACK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SUSPICIOUS_TERM_ACK_FAILURE,
    });
  }
}

export function* advisorMemberTrendsHistoryGet(action) {
  try {
    const response = yield API.advisorMemberTrendsHistoryGet(action.payload);
    yield put({
      type: ActionTypes.ADVISOR_MEMBER_TRENDS_HISTORY_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADVISOR_MEMBER_TRENDS_HISTORY_GET_FAILURE,
    });
  }
}
export function* dashboardAnalyticsGet(action) {
  try {
    const response = yield API.dashboardAnalyticsGet(action.payload);
    yield put({
      type: ActionTypes.DASHBOARD_ANALYTICS_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Analytics,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_ANALYTICS_GET_FAILURE,
    });
  }
}

export function* userActivityGet(action) {
  try {
    const response = yield API.userActivityGet(action.payload);
    yield put({
      type: ActionTypes.USER_ACTIVITY_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_ACTIVITY_GET_FAILURE,
    });
  }
}

export function* userSuspiciousWordGet(action) {
  try {
    const response = yield API.userSuspiciousWordGet(action.payload);
    yield put({
      type: ActionTypes.USER_SUSPICIOUS_WORD_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_SUSPICIOUS_WORD_GET_FAILURE,
    });
  }
}

export function* userNotificationGet(action) {
  try {
    const response = yield API.userNotificationGet(action.payload);
    yield put({
      type: ActionTypes.USER_NOTIFICATION_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_NOTIFICATION_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.DASHBOARD_GET_REQUEST, getDashboard),
    takeLatest(ActionTypes.STUDENTS_SEARCH_REQUEST, getStudentsSearch),
    takeLatest(
      ActionTypes.GET_DASHBOARD_LOAD_APP_USERS_REQUEST,
      getDashboardLoadAppUsers
    ),
    takeLatest(
      ActionTypes.LOAD_SMALLGROUP_DASHBOARD_REQUEST,
      loadSmallGroupDashboard
    ),
    takeLatest(ActionTypes.GET_REPORTS_REQUEST, getReports),
    takeLatest(
      ActionTypes.LOAD_JUDICIAL_DASHBOARD_REQUEST,
      loadJudicialDashboard
    ),
    takeLatest(ActionTypes.GET_JUDICIAL_USER_LIST_REQUEST, getJudicialUserList),
    takeLatest(ActionTypes.GET_APP_ADVISOR_REQUEST, getAppAdvisor),
    takeLatest(ActionTypes.GET_DASHBOARD_MENU_REQUEST, getDashboardMenu),
    takeLatest(ActionTypes.SUSPICIOUS_TERM_ACK_REQUEST, suspiciousTermAck),
    takeLatest(
      ActionTypes.ADVISOR_MEMBER_TRENDS_HISTORY_GET_REQUEST,
      advisorMemberTrendsHistoryGet
    ),
    takeLatest(
      ActionTypes.DASHBOARD_ANALYTICS_GET_REQUEST,
      dashboardAnalyticsGet
    ),
    takeLatest(ActionTypes.USER_ACTIVITY_GET_REQUEST, userActivityGet),
    takeLatest(
      ActionTypes.USER_SUSPICIOUS_WORD_GET_REQUEST,
      userSuspiciousWordGet
    ),
    takeLatest(ActionTypes.USER_NOTIFICATION_GET_REQUEST, userNotificationGet),
  ]);
}
