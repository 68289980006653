import React from "react";
import { v4 as uuid } from "uuid";
// import { Modal, Checkbox, Row, Col, Radio, Slider, InputNumber } from "antd";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

class CheckboxGroup extends React.Component {
  checkboxGroup() {
    let {
      label,
      required,
      options,
      input,
      meta,
      col,
      offset,
      justify,
      blockDays,
      fullDescription,
      title,
      disabled,
    } = this.props;
    return (
      <FormControl
        component="fieldset"
        variant="standard"
        className="flex-row flex-wrap"
      >
        <FormLabel component="legend">{label}</FormLabel>
        {options.map((option, index) => {
          return (
            <>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={"d-flex align-items-center"}
                      name={`${input.name}[${index}]`}
                      value={option.value}
                      disabled={option.value === blockDays || disabled}
                      checked={
                        (input.value &&
                          input.value.indexOf(option.value) !== -1) ||
                        option.value === blockDays
                      }
                      onChange={(event) => {
                        const newValue = [...input.value];
                        if (event.target.checked) {
                          newValue.push(option.value);
                        } else {
                          newValue.splice(newValue.indexOf(option.value), 1);
                        }

                        return input.onChange(newValue);
                      }}
                    />
                  }
                  label={option.label}
                  // labelPlacement="top"
                />
              </FormGroup>
              {/* <FormHelperText>Be careful</FormHelperText> */}

              {/* {fullDescription && (
              <span style={{ marginLeft: "30px" }}>{option.description}</span>
            )} */}
            </>
          );
        })}
      </FormControl>
    );
  }

  render() {
    return (
      // <Row justify={this.props.justify} className={this.props.widthClassName}>
      <>{this.checkboxGroup()}</>
      // </Row>
    );
  }
}

export default CheckboxGroup;
