import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { toAbsoluteUrl } from "./../../../_metronic/_helpers";
import {
  clientsNotParticipatingInGroup,
  clientGroupUpsert,
  clientListDropdown,
} from "../../../redux/actions";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import _Close from "./../../images/close.svg";
import _blank from "./../../images/blank-profile.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export const ClientListModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExistingClient, setIsExistingClient] = useState(true);
  const clientTypeChange = (event) => {
    if (event.target.value === "newclient") {
      setIsExistingClient(false);
    } else {
      setIsExistingClient(true);
    }
  };
  useEffect(() => {
    dispatch(clientListDropdown({}));
  }, []);
  const { clientsList, isFetching } = useSelector((state) => {
    const {
      client_list_dropdown: { data },
    } = state;
    if (data?.Data?.Clients) {
      //    debugger;
    }
    return {
      clientsList: data?.Data?.Clients || [],
    };
  }, shallowEqual);
  const { Value, Text, ProfileImageUrl } = clientsList || {};
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Client Types
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={clientTypeChange}
              defaultValue="existingclient"
            >
              <FormControlLabel
                value="existingclient"
                control={<Radio />}
                label="Existing Client"
              />
              <FormControlLabel
                value="newclient"
                control={<Radio />}
                label="New Client"
              />
            </RadioGroup>
          </FormControl>
        </div>
        {/* Body */}
        <div className="card-body pt-2 drawer-small-group drawer-details-view">
          {isExistingClient && (
            <>
              <p className="col-6" style={{ display: "inline-block" }}>
                Group Members{" "}
              </p>
              <p
                className="col-6"
                style={{ display: "inline-block", textAlign: "right" }}
              >
                <input
                  type="text"
                  style={{
                    borderColor: "rgba(0,0,0,0.2)",
                    borderRadius: "3px",
                    height: "30px",
                    outline: "none",
                  }}
                  placeholder="Search"
                />
              </p>
              <div className="row">
                <div className="col-11">
                  {/* <span className="small-group-title">{GroupName}</span> */}
                </div>
              </div>
              <div className="">
                {clientsList.map((item) => (
                  <div
                    className="row align-items-center"
                    style={{
                      padding: "10px",
                      margin: "14px 10px",
                      backgroundColor: "#F7F8FA",
                    }}
                  >
                    <Checkbox
                      //   checked={toggles[colName]}
                      value={item.Value}
                      onChange={(e) => props.handleChecked(item.Value, e)}
                    />
                    <img
                      src={item.ProfileImageUrl || _blank}
                      alt=""
                      style={{
                        height: "42px",
                        width: "42px",
                        borderRadius: "50%",
                      }}
                    />
                    <ListItemText
                      style={{ marginLeft: "8px" }}
                      primary={item.Text}
                    />
                    <br />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
    // <h1>SmallGroup</h1>
  );
};
