import React from "react";
import { CenterList } from "../../components/custom-screens/centers";
const Centers = (props) => {
  return (
    <>
      {" "}
      <div>
        <CenterList />
      </div>
    </>
  );
};
export default Centers;
