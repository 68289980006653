import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const CustomLoader = () => { 
  return (
    <div
      style={{
        margin: "0px auto",
        position: "absolute",
        top: "25%",
        left: "45%",
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  );
}


export default CustomLoader;
