import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
    DropdownCustomToggler,
    DropdownMenu1,
    DropdownMenu2
} from "../../../../_metronic/_partials/dropdowns";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import {
    getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses
} from "../../../../_metronic/_helpers";
import { userNotificationGet } from "../../../../redux/actions";
import { MemberTrendsFormatter } from "../../column-formatters";
// import { Chat } from "../../custom-component/Chating";
// import { TwilioCalling } from "../../custom-component/TwilioCalling";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";

const { SearchBar, ClearSearchButton } = Search;

// const list = [
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
// ];
const list = [
    {
        UserGuid: "4B5FC37C-E073-46A3-9346-C1B39918877F",
        DisplayName: "Chanchal Test Dev",
        ProfileImgUrl: "",
        Context: "Chat",
        ForbiddenWord: "X",
        WordUsedDateTime: "2022-02-22T11:15:44.890",
        WordUsedDate: "2022-02-22",
        WordUsedTime: "4:45pm",
        icon: "Create FireStone Logo",
        program: "Detox",
        tags: ["With care team", "Subscriber"],
        msg: "Was at a trigger location",
        flag: "Alcohol"
    }
];

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    },
    input: {
        display: "none"
    }
}));

export const Notifications = className => {
    const dispatch = useDispatch();
    const history = useHistory();
    const columns = [
        {
            dataField: "DisplayName",
            text: "Members",
            sort: enableSorting,
            sortCaret: sortCaret,
            headerSortingClasses: headerSortingClasses,
            formatter: MemberTrendsFormatter
        },
        {
            dataField: "UsedFrom",
            text: "Used In",
            style: {
                minWidth: "80px"
            }
            // sort: enableSorting,
            // sortCaret: sortCaret,
            // headerSortingClasses: headerSortingClasses,
        },
        {
            dataField: "WordUsed",
            text: "Word Used"
            // sort: enableSorting,
            // sortCaret: sortCaret,
            // headerSortingClasses: headerSortingClasses,
        },
        {
            dataField: "",
            text: "Actions",
            // sort: false,
            // sortCaret: sortCaret,
            // headerSortingClasses: headerSortingClasses,
            // formatter: actionFormatter,
            // formatter: (cell, row) => {
            //   debugger;
            //   return <div className="d-flex align-items-center"></div>;
            // },
            // formatExtraData: {
            //   //   openEditProductPage: productsUIProps.openEditProductPage,
            //   //   openDeleteProductDialog: productsUIProps.openDeleteProductDialog,
            // },
            // classes: "text-right pr-0",
            // headerClasses: "text-right pr-3",
            style: {
                minWidth: "150px"
            }
        }
        // "Actions": [
        //   {
        //     Phone: "",
        //     chat: "",
        //     More: [
        //       {
        //         ActionName: "Schedule Appointment",
        //       },
        //       {
        //         ActionName: "Assign Task",
        //       },
        //       {
        //         ActionName: "Assign Survey",
        //       },
        //       {
        //         ActionName: "Assign Course",
        //       },
        //       {
        //         ActionName: "Add Note",
        //       },
        //       {
        //         ActionName: "Share Client",
        //       }
        //     ],
        //   }
        // ],
    ];
    const classes = useStyles();
    const suhbeader = useSubheader();
    suhbeader.setTitle("Dashboard");

    useEffect(() => {
        dispatch(userNotificationGet({}));
    }, []);

    const { userNotificationGetData } = useSelector(
        ({ user_notification_get }) => {
            return {
                userNotificationGetData:
                    (user_notification_get.data &&
                        user_notification_get.data.Notification) ||
                    []
            };
        },
        shallowEqual
    );

    const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
        const { More, Phone, chat } = row.Actions[0];
        return (
            <div className="d-flex align-items-center justify-content-around">
                <div className="">
                    {/* <TwilioCalling row={row} /> */}
                    <div className="">
                        <img
                            style={{ cursor: "pointer", display: "block" }}
                            src={_Phone}
                            alt="Calling Allowed"
                            width="15px"
                        />{" "}
                    </div>
                </div>
                <div className="">
                    {/* <Chat row={row} /> */}
                    <div className="">
                        <img
                            style={{ cursor: "pointer", display: "block" }}
                            src={_Chat}
                            alt="Chat"
                            width="15px"
                        />{" "}
                    </div>
                </div>
                <div className="">
                    <ItemDropdown item="MoreAction" />
                </div>
            </div>
        );
    };

    const enableSorting = true;

    return (
        <>
            <div className={`card card-custom ${className}`}>
                {/* Head */}
                {/* <div className="card-header border-0"></div> */}
                {/* Body */}
                <div
                    className="card-body pt-5 member-trend"
                    style={{ maxHeight: "300px" }}
                >
                    {/* {userNotificationGetData &&
            userNotificationGetData.map((item, index) => {
              const {
                Badge,
                CreatedOn,
                DisplayName,
                MessageBody,
                Phone,
                ProfileImageUrl,
                Service,
                ServiceTags,
                UserGuid,
              } = item;
              return (
                <>
                  <p>{DisplayName}</p>
                </>
              );
            })} */}

                    {userNotificationGetData &&
                        userNotificationGetData.map(item => {
                            const {
                                Badge,
                                CreatedOn,
                                DisplayName,
                                MessageBody,
                                Phone,
                                ProfileImageUrl,
                                Service,
                                ServiceTags,
                                UserGuid
                            } = item;
                            return (
                                <div
                                    className="d-flex align-items-center"
                                    style={{ height: "120px" }}
                                    key={uuid()}
                                >
                                    <div className="col-9">
                                        <span
                                            style={{
                                                fontSize: "14px"
                                            }}
                                        >
                                            {" "}
                                            {CreatedOn}
                                        </span>

                                        <div
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                marginTop: "3px",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                color: "black"
                                            }}
                                        >
                                            {MessageBody}
                                        </div>
                                        <div className="d-flex">
                                            <div
                                                class="student-list-pic"
                                                style={{ flex: "0 0 15%" }}
                                            >
                                                <img
                                                    src={
                                                        ProfileImageUrl ||
                                                        _blank
                                                    }
                                                    // alt="student"
                                                    class="img-circle"
                                                    height="40"
                                                    width="40"
                                                    style={{
                                                        borderRadius: "50%",
                                                        padding: "2px",
                                                        objectFit: "cover"
                                                    }}
                                                />
                                            </div>
                                            <div className="row flex-column">
                                                <div>
                                                    <span className="member-name">
                                                        {DisplayName}
                                                    </span>{" "}
                                                    <span className="member-service">
                                                        {Service}
                                                    </span>
                                                </div>
                                                <div>
                                                    {ServiceTags.split(",").map(
                                                        tag => {
                                                            return (
                                                                <span
                                                                    className="member-tag"
                                                                    key={uuid()}
                                                                >
                                                                    {tag}
                                                                </span>
                                                            );
                                                        }
                                                    )}
                                                    {/* <p className="member-tag">{}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 px-1 ">
                                        <div className="d-flex align-items-center justify-content-around">
                                            <div className="">
                                                <img
                                                    style={{
                                                        cursor: "pointer",
                                                        display: "block"
                                                    }}
                                                    src={_Phone}
                                                    alt="Calling Allowed"
                                                    width="15px"
                                                />{" "}
                                            </div>
                                            <div className="">
                                                <img
                                                    style={{
                                                        cursor: "pointer",
                                                        display: "block"
                                                    }}
                                                    src={_Chat}
                                                    alt="Chat"
                                                    width="15px"
                                                />{" "}
                                            </div>
                                            <div className="">
                                                <ItemDropdown item="MoreAction" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
};

const ItemDropdown = ({ item }) => {
    return (
        <>
            <Dropdown className="dropdown-inline" alignRight>
                <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-toggle-top"
                    className="btn btn-hover-light-primary btn-sm btn-icon"
                    as={DropdownCustomToggler}
                >
                    <i className="ki ki-bold-more-ver" />
                </Dropdown.Toggle>
                {/* <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu> */}
            </Dropdown>
        </>
    );
};
