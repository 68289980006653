import React from "react";
import { MemberTrends } from "../components/custom-screens/memberTrendsPage/index";
const MemberTrendsComponent = (props) => {
  return (
    <>
      {" "}
      <div className="row">
        <MemberTrends />
      </div>
    </>
  );
};
export default MemberTrendsComponent;
