import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CenteredTabs from "../../components/custom-component/custom-tabs";
import { ManageNotes } from "./ManageNotes";
import { ManagePredefinedTasks } from "./ManagePredefinedTasks";

// import { useSubheader } from "../../../../_metronic/layout";
// import GenericTable from "../../generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  courseListGet,
  getDynamicGridFilter,
  resetCourseStatusUpdate,
  resetCourseGet,
} from "../../../redux/actions";

const Miscellaneous = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Labels = {
    ManageNotes: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <ManageNotes tabId="MyTemplates" />
        </>
      );
    },
    ManagePredefinedTasks: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <ManagePredefinedTasks tabId="PublicTemplates" />
        </>
      );
    },
  };
  const TabData = [
    {
      Id: "ManageNotes",
      TabName: "Manage Note Types",
      Component: "ManageNotes",
    },
    {
      Id: "ManagePredefinedTasks",
      TabName: "Manage Predefined Tasks",
      Component: "ManagePredefinedTasks",
    },
  ];
  return (
    <div>
      <div className="custom-tabs">
        <CenteredTabs
          tabsData={TabData}
          labels={Labels}
          baseTabUrl="/admin/miscellaneous?tab="
        />
      </div>
    </div>
  );
};
export default Miscellaneous;
