import React, { useEffect, useState, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _InitiateTestNow from "../../../images/initiateTestNow.svg";
import {
  breathalyzerPushATest,
  resetBreathalyzerPushATest,
} from "../../../../redux/actions";
export const PushTest = ({ RowData }) => {
  const dispatch = useDispatch();
  const { isBreathyzerSuccess } = useSelector(
    ({ breathalyzer_push_test: { data } }) => {
      return {
        isBreathyzerSuccess: data?.ResponseCode === 1,
      };
    },
    shallowEqual
  );
  if (isBreathyzerSuccess) {
    dispatch(resetBreathalyzerPushATest({}));
  }

  return (
    <div className="">
      <OverlayTrigger
        overlay={<Tooltip id="push-test-tooltip">Push a test now</Tooltip>}
      >
        <a
          className="btn btn-icon btn-hover-light-primary btn-sm"
          onClick={() => {
            dispatch(
              breathalyzerPushATest({
                Breathalyzer: [
                  {
                    ClientId: RowData.ClientId,
                  },
                ],
              })
            );
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl(_InitiateTestNow)} />
          </span>
        </a>
      </OverlayTrigger>
    </div>
  );
};
