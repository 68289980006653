export default function(state = { actions: [] }, action) {
  switch (action.type) {
    case 'SaveActions':
      return { ...state, actions: [...state.actions, action.actions] };
    case 'ClearAction':
      return { actions: [] };
    default:
      return state;
  }
}
