import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  getCouponCode,
  deleteCouponCode,
  resetDeleteCouponCode,
  couponListGet,
} from "../../../redux/actions";
import _Edit from "../../images/edit.svg";
import _Delete from "../../images/icons/delete_gray.svg";
import _Close from "../../images/close.svg";

const useStyles = makeStyles((theme) => ({
  activateButton: {
    margin: theme.spacing(1),
    color: "red",
    background: "transparent",
    border: "1px solid red",
  },
  deActivateButton: {
    margin: theme.spacing(1),
    color: "white",
    background: "blue",
    "&:hover, &:focus": {
      backgroundColor: "blue",
    },
  },
  input: {
    display: "none",
  },
}));

export const CouponDrawer = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const enableSorting = true;
  const classes = useStyles();

  useEffect(() => {
    dispatch(getCouponCode({ Coupon: { CouponId: props.CouponId } }));
  }, []);

  const { CouponInfo, isDeleted } = useSelector(
    ({
      get_coupon_code: { data },
      delete_coupon_code: { data: deleteData },
    }) => {
      return {
        CouponInfo: data?.Data?.Coupon?.[0] || {},
        isDeleted: deleteData?.ResponseCode === 1,
      };
    },
    shallowEqual
  );
  const handleClose = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    if (isDeleted) {
      dispatch(resetDeleteCouponCode());
      dispatch(couponListGet({}));
      // props.toggleDrawer("right", false, {});
    }
  }, [isDeleted]);
  const {
    CouponId,
    CenterId,
    CenterName,
    AdvisorId,
    CouponCode,
    SubscriptionType,
    StartDate,
    EndDate,
    IsFreeSubscription,
    IsFreeSubscriptionText,
    MaxUseLimit,
    Advisor,
  } = CouponInfo;

  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <h3>Referral Code Details</h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-5 drawer-center">
          <div className="row pb-5">
            <div className="col-10">
              <span className="center-title">{CouponCode}</span>
            </div>
            <div className="col-2">
              <div>
                <OverlayTrigger
                  overlay={<Tooltip id="edit-tooltip">Edit Coupon</Tooltip>}
                >
                  <a
                    className="btn btn-icon btn-hover-light-primary btn-sm"
                    onClick={() => {
                      history.push(`/admin/coupon/edit?id=${props.CouponId}`);
                    }}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl(_Edit)} />
                    </span>
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={<Tooltip id="edit-tooltip">Delete Coupon</Tooltip>}
                >
                  <a
                    className="btn btn-icon btn-hover-light-primary btn-sm"
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl(_Delete)} />
                    </span>
                  </a>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Center ID</span>
                  <span className="c-value">{CenterId && CenterId}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">CenterName</span>
                  <span className="c-value">{CenterName && CenterName}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Advisor</span>
                  <span className="c-value">{Advisor && Advisor}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">SubscriptionType</span>
                  <span className="c-value">{SubscriptionType}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Start Date</span>
                  <span className="c-value">{StartDate}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">End Date</span>
                  <span className="c-value">{EndDate}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Alumni / Free</span>
                  <span className="c-value">{IsFreeSubscriptionText}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Used Count</span>
                  <span className="c-value">{MaxUseLimit}</span>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete Referral Code"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this Referral Code?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Disagree
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    deleteCouponCode({ Coupon: { CouponId: props.CouponId } })
                  );
                  handleClose();
                }}
                color="secondary"
                autoFocus
                ariant="contained"
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};
