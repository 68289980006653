import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _Archive from "../../../images/archived.svg";
import {
  miscellaneousNoteTypeDelete,
  miscellaneousTaskDelete,
  miscellaneousNoteTypesListGet,
  resetMiscellaneousTaskDelete,
  resetMiscellaneousNoteTypeDelete,
} from "../../../../redux/actions";
import { useEffect } from "react";

export const Archive = ({ RowData }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(resetMiscellaneousTaskDelete());
    dispatch(resetMiscellaneousNoteTypeDelete());
  }, []);

  const { isTaskDelete, isNoteDelete } = useSelector((state) => {
    const {
      miscellaneous_task_delete: { data },
      miscellaneous_note_type_delete: { data: noteDelete },
    } = state;
    return {
      isTaskDelete: data?.ResponseCode === 1,
      isNoteDelete: noteDelete?.ResponseCode === 1,
    };
  }, shallowEqual);

  useEffect(() => {
    if (isTaskDelete) {
      history.push("/admin/miscellaneous?tab=managepredefinedtasks");
      dispatch(resetMiscellaneousTaskDelete());
    }
  }, [isTaskDelete]);

  useEffect(() => {
    if (isNoteDelete) {
      history.push("/admin/miscellaneous?tab=managenotes");
      dispatch(resetMiscellaneousNoteTypeDelete());
    }
  }, [isNoteDelete]);

  return (
    <div className="">
      {/* <OverlayTrigger overlay={<Tooltip id="chat-tooltip">Chat</Tooltip>}>
      <a
        className="btn btn-icon btn-hover-light-primary btn-sm"
        // onClick={() => openEditProductPage(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl(_Chat)} />
        </span>
      </a>
    </OverlayTrigger> */}
      <OverlayTrigger overlay={<Tooltip id="chat-tooltip">Archive</Tooltip>}>
        <div
          className="topbar-item"
          data-toggle="tooltip"
          // title="chat"
          data-placement="right"
        >
          {RowData.NoteTypeId && (
            <div
              className="btn btn-icon  btn-clean btn-md"
              onClick={() => {
                dispatch(
                  miscellaneousNoteTypeDelete({
                    NoteType: [
                      {
                        NoteTypeId: RowData.NoteTypeId,
                        IsDelete: 1,
                      },
                    ],
                  })
                );
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Archive)} />
              </span>
            </div>
          )}
          {RowData.TaskId && (
            <div
              className="btn btn-icon  btn-clean btn-md"
              onClick={() => {
                dispatch(
                  miscellaneousTaskDelete({
                    Task: [
                      {
                        TaskId: RowData.TaskId,
                        IsDelete: 1,
                      },
                    ],
                  })
                );
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Archive)} />
              </span>
            </div>
          )}
        </div>
      </OverlayTrigger>
    </div>
  );
};
