import React from "react";
import { Staff } from "../components/custom-screens/staff/index";
const StaffComponent = (props) => {
  return (
    <>
      <Staff />
    </>
  );
};
export default StaffComponent;
