import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { createBrowserHistory } from "history";
import { ResponseHandler } from "./responseHandler";

export function* login(params) {
  try {
    const response = yield API.login(params.payload);

    let token = {
      expires_in: response?.data?.expires_in,
      access_token: response?.data?.Data?.Token?.AccessToken,
      refresh_token: response?.data?.refresh_token,
      created: Date.now(),
      date_exp: Date.now() + response?.data?.expires_in * 1000,
    };
    localStorage.setItem("dr:token", JSON.stringify(token));
    localStorage.setItem("centerId", response?.data?.Data?.DefaultCenterId);
    localStorage.setItem("x-centerId", response?.data?.Data?.DefaultCenterId);
    yield put({
      type: ActionTypes.LOGIN_SUCCESS,
      payload: response.data,
    });

    yield put({
      type: ActionTypes.USER_DATA_GET_REQUEST,
      payload: {},
    });
  } catch (error) {
    yield put({
      type: ActionTypes.LOGIN_FAILURE,
      error: error,
    });
  }
}
export function* loadDefaultValues(action) {
  const history = createBrowserHistory();
  try {
    const { payload } = action;
    const response = yield API.loadDefaultValues(payload);
    yield put({
      type: ActionTypes.LOAD_DEFAULT_VALUES_SUCCESS,
      payload: ResponseHandler(response),
    });
    if (response) {
      // yield put(history.push(`${response.data.data.defaultDashboard}`));
      yield put({
        type: ActionTypes.SIDE_BAR_MENU_GET_REQUEST,
        payload: {},
      });

      // yield put(history.push(`/programs-services/telehealth`));
    }
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_DEFAULT_VALUES_FAILURE,
    });
  }
}
export function* userDataGet(action) {
  try {
    const response = yield API.userDataGet(action.payload);
    yield put({
      type: ActionTypes.USER_DATA_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
    if (response.data.Data.UserInfo[0].AuthenticationType === 13001)
      yield put({
        type: ActionTypes.LOAD_DEFAULT_VALUES_REQUEST,
        payload: {},
      });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_DATA_GET_FAILURE,
    });
  }
}
export function* sideBarMenuGet(action) {
  try {
    const { payload } = action;
    const response = yield API.sideBarMenuGet(payload);
    yield put({
      type: ActionTypes.SIDE_BAR_MENU_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SIDE_BAR_MENU_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.LOGIN_REQUEST, login),
    takeLatest(ActionTypes.LOAD_DEFAULT_VALUES_REQUEST, loadDefaultValues),
    takeLatest(ActionTypes.USER_DATA_GET_REQUEST, userDataGet),
    takeLatest(ActionTypes.SIDE_BAR_MENU_GET_REQUEST, sideBarMenuGet),
    ,
  ]);
}

// ResponseCodes;
// (FullFailure = 0), // Error
//   (FullSuccess = 1), // Success
//   [Description("success_with_warning")];
// (PartialSuccess = 2), // Currently not in use
//   (UserError = 9), // Error happened due to incorrect input from user. Eg. Liking same post again.
//   (BadRequest = 400);
