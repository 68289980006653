import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { CustomersFilter } from "../custom-component/customers-filter";
import CustomTable from "../custom-component/custom-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import Drawer from "@material-ui/core/Drawer";
let GenericTable = (props) => {
  const {
    columns,
    entities,
    isCardHeader,
    tableTitle,
    pagination,
    bordered,
    selectRow,
    isLoading,
    rowEvents,
    toggleDrawer,
    tableOptions,
    onFilter,
  } = props;
  return (
    <div className="generic-table" id="generic-table">
      <div className="row" id="generic-table-row">
        <div className="col-12" id="generic-table-col">
          <Card id="generic-table-card">
            {isCardHeader && (
              <CardHeader title={tableTitle}>
                {isCardHeader && <CardHeaderToolbar></CardHeaderToolbar>}
              </CardHeader>
            )}

            <CardBody>
              {/* <CustomersFilter /> */}
              <CustomTable
                columns={columns || []}
                entities={entities || []}
                bordered={bordered}
                pagination={pagination}
                isLoading={isLoading}
                toggleDrawer={toggleDrawer}
                tableOptions={tableOptions}
                onFilter={onFilter}
                // selectRowData={selectRow}
                // rowEvents={rowEvents}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default GenericTable = withRouter(connect()(GenericTable));
