import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getAdvisorCost(action) {
  try {
    const { payload } = action;
    const response = yield API.getAdvisorCost(payload);
    yield put({
      type: ActionTypes.GET_ADVISOR_COST_SUCCESS,
      payload: JSON.parse(response.data.data).AdvisorCost[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ADVISOR_COST_FAILURE,
    });
  }
}
export function* upsertAdvisorCost(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertAdvisorCost(payload);
    yield put({
      type: ActionTypes.UPSERT_ADVISOR_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_ADVISOR_COST_FAILURE,
    });
  }
}
export function* getCenterAdvisorDefaultCosts(action) {
  try {
    const { payload } = action;
    const response = yield API.getCenterAdvisorDefaultCosts(payload);
    yield put({
      type: ActionTypes.GET_CENTER_ADVISOR_DEFAULT_COSTS_SUCCESS,
      payload: JSON.parse(response.data.data).AdvisorCost[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CENTER_ADVISOR_DEFAULT_COSTS_FAILURE,
    });
  }
}
export function* updateCenterAdvisorDefaultCost(action) {
  try {
    const { payload } = action;
    const response = yield API.updateCenterAdvisorDefaultCost(payload);
    yield put({
      type: ActionTypes.UPDATE_CENTER_ADVISOR_DEFAULT_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_CENTER_ADVISOR_DEFAULT_COST_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_ADVISOR_COST_REQUEST, getAdvisorCost),
    takeLatest(ActionTypes.UPSERT_ADVISOR_COST_REQUEST, upsertAdvisorCost),
    takeLatest(ActionTypes.GET_CENTER_ADVISOR_DEFAULT_COSTS_REQUEST, getCenterAdvisorDefaultCosts),
    takeLatest(
      ActionTypes.UPDATE_CENTER_ADVISOR_DEFAULT_COST_REQUEST,
      updateCenterAdvisorDefaultCost,
    ),
  ]);
}
