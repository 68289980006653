import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import { getDate, getLocalDate } from "../../util/date";
import { getDate, getLocalDate } from "../../util/date";
const CustomDatepicker = ({ onChange, date, value }) => {
  var props = {
    onChange: onChange,
    dateFormat: "dd-MMM-yyyy",
  };
  if (date) {
    props.selected = date; //moment(date, 'YYYY/MM/DD');
  }
  return <DatePicker {...props} />;
};

export default CustomDatepicker;
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: "100%",
    height:"36px"
  },
}));

const ReduxTimeInput = ({
  input,
  name,
  id,
  value,
  onChange,
  meta,
  errorMessage,
  minDate,
  disabled,
  tabIndex,
  label,
  fieldName,
  autoComplete,
  labelText,
}) => {
  const classes = useStyles();
  const { value: defaultValue } = input || {};
  const { touched, error, warning, active } = meta || {};
  var props = {
    style: { display: "block" },
    dateFormat: "dd-MMM-yyyy",
  };

  if (input) {
    onChange = input.onChange;
  }

  if (input.value) {
    if (typeof input.value == "object") {
      value = getLocalDate(input.value); //moment(input.value, 'YYYY/MM/DD');
    } else {
      value = getDate(input.value);
    }
  }

  return (
    <div>
      <FormControl fullWidth className="formControl" error={touched && error}>
        <InputLabel htmlFor={id} variant="filled">
          {labelText}
        </InputLabel>
        <OutlinedInput
          fullWidth
          {...props}
          id="date"
          label={labelText}
          type="time"
          defaultValue={defaultValue}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChange}
          selected={value}
          autoComplete={autoComplete}
          variant="outlined"
          style={{ height: "36px", marginTop: "4px" }}
        />
        {touched &&
          ((error && fieldName && (
            <FormHelperText id="component-error-text">
              {" "}
              {fieldName} {error}
            </FormHelperText>
          )) ||
            (warning && <span className="warning-color">{warning}</span>))}
      </FormControl>
    </div>
  );
};

export { ReduxTimeInput };
