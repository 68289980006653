import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  reduxForm,
} from "redux-form";
import {
  getFormFieldMetaData,
  groupOptionGet,
  groupAppointmentUpsert,
} from "../../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../../core/generic-form";
import { ReduxSelect } from "../../../../app/components/redux-component";
import formDataHandler from "../../../util/formDataHandler";
import SelectPatient from "../../../components/redux-component/selectPatient";
import CustomLoader from "../../../components/custom-component/custom-loader";

const GroupAppointment = (props) => {
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedClient, setSelectedClient] = useState("");

  useEffect(() => {
    let data = {
      DynamicForm: [
        {
          FormName: "AppointmentGroupAppointment",
        },
      ],
    };
    dispatch(getFormFieldMetaData(data));
    dispatch(groupOptionGet({}));
  }, []);

    const { isFetching } = useSelector((state) => {
      const {
        get_form_field_meta_data: { data, isFetching },
      } = state;
      return { isFetching };
    }, shallowEqual);
  

  const getSelectedClient = (value) => {
    setSelectedClient(value);
  };

  const handleGroupChange = (value) => {
    setSelectedGroup(value);
  };

  const {
    formFields,
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    ClientId,
  } = props;

    const { groupOptionGetData } = useSelector(
      (state) => {
        const { group_option_get: { data } } = state;
        return { groupOptionGetData:data?.Data?.Groups };
      },
      shallowEqual
  );

   const handleCreateAppointment = (values) => {
     let ObjectData = formDataHandler(values);
     const {
       AppointmentDate,
       AppointmentEndTime,
       AppointmentTime,
       MonthlyRecurring,
       OfficeName,
       PlaceOfServiceId,
       PractitionerId,
       ProgramId,
       ReasonForVisit,
       RecurringCount,
       RecurringType,
       ServiceCode,
       Status,
       Title,
     } = ObjectData;

     const formData = {
       GroupAppointment: [
         {
           Title,
           AppointmentDate,
           AppointmentTime,
           AppointmentEndTime,
           PractitionerId,
           OfficeName,
           ServiceCode,
           ReasonForVisit,
           Status,
           ProgramId,
           PlaceOfServiceId,
           GroupId: selectedGroup,
           Clients: selectedClient?.toString(),
           IsRecurring: RecurringType == "None" ? false : true,
           GroupAppointmentRecurring: [
             {
               GroupAppointmentRecurringId: null,
               RecurringType,
               WeekDays: values?.WeekDays?.toString(),
               MonthlyRecurring,
               RecurringCount,
             },
           ],
         },
       ],
     };

     dispatch(groupAppointmentUpsert(formData));
  };
  
  
  return (
    <>
      {isFetching ? (
        <CustomLoader />
      ) : (
        <form
          autoComplete="off"
          onSubmit={handleSubmit(handleCreateAppointment)}
        >
          <div className="enrollment-form staff-form pt-2 pb-2">
            <div className="row pb-5 pl-3 pr-3 pt-3">
              <div class="col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  {" "}
                  <label className="placeholder-label">Select Group</label>
                  <ReduxSelect
                    {...props}
                    options={groupOptionGetData}
                    onChange={handleGroupChange}
                    value={selectedGroup}
                  />
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12">
                <SelectPatient
                  isMulti={true}
                  name={"clientList"}
                  getSelectedClient={getSelectedClient}
                  GroupId={selectedGroup}
                  isGroupSelected={selectedGroup}

                  // handleNext={handleNext}
                  // handleBack={handleBack}
                  // GroupId={getGroupId()}
                  // activeStep={activeStep}
                  // NextTab={NextTab}
                  // FormName={FormName}
                />
              </div>
            </div>
            <div className="row pb-5 pl-3 pr-3 pt-3">
              <DynamicForm formFields={formFields} disable={false} />
            </div>
            <div className="text-right">
              <Button
                variant="contained"
                color="secondary"
                //   className={classes.button}
                type="submit"
                //   disabled={pristine || submitting}
              >
                Create
              </Button>
              &nbsp;
            </div>
          </div>
        </form>
      )}
    </>
  );
};

const ReduxGroupAppointment = reduxForm({
  form: "GroupAppointment",
  enableReinitialize: true,
})(GroupAppointment);

export default ReduxGroupAppointment;
