import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import * as columnFormatters from "../column-formatters123";
import GenericTable from "../../components/generic-table/generic-table";
import {
  isComparer,
  isNumericComparer,
  isDateComparer,
  isDateSort,
} from "../../util/handler";
import {
  //   dynamicGridColumnConfigurationGet,
  clientGroupGet,
  groupGet,
} from "../../../redux/actions";

let Clients = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      clientGroupGet({
        ClientGroup: [
          {
            GroupId: props.ID,
            // Name: tabId || "ClientRosterAllClient",
          },
        ],
      })
    );
    // dispatch(
    //   groupGet({
    //     ClientGroup: [
    //       {
    //         GroupId: props.ID,
    //       },
    //     ],
    //   })
    // );
  }, []);
  const { clientsList, isFetching } = useSelector((state) => {
    const {
      client_group_get: { data: clientGroup },
    } = state;

    return {
      clientsList: clientGroup?.Data?.ClientGroup || [],
    };
  }, shallowEqual);

  //   const {
  //     PageSize,
  //     IsRowClick,
  //     IsExcelExport,
  //     IsColumnSelectionEnable,
  //     Columns,
  //   } = dynamicGridColumnData || {};
  const DataSource = clientsList || [];

  const columns = [
    {
      dataField: "ClientId",
      text: "Client Id",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "Integer",
      sortField: "ClientId",
      width: 100,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "DisplayName",
      text: "Display Name",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "DisplayName",
      width: 150,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "Services",
      text: "Services",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "Services",
      width: 150,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "CenterName",
      text: "Center Name",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "CenterName",
      width: 150,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: null,
      csvExport: false,
    },
    {
      dataField: "Action",
      text: "Action",
      sort: true,
      sortCaret: "sortCaret",
      headerSortingClasses: "headerSortingClasses",
      type: "String",
      sortField: "Action",
      width: 150,
      align: "Left",
      hidden: null,
      Actions: null,
      formatter: "ActionsColumnFormatter",
      csvExport: false,
    },
  ];

  return (
    <>
      <div className="">
        {columns && DataSource && (
          <GenericTable
            tableTitle="Table Data"
            entities={DataSource || []}
            columns={columns || []}
            isCardHeader={false}
            pagination={true}
            isLoading={isFetching}
            bordered={false}
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            // tableOptions={{
            //   PageSize,
            //   IsRowClick,
            //   IsExcelExport,
            //   IsColumnSelectionEnable,
            // }}
          />
        )}
      </div>
    </>
  );
};
export default Clients = withRouter(connect()(Clients));
