import { ActionTypes } from '../constants';
import { COMBOS_LOADED } from '../comboConfig';

export default function(state = {}, action) {
  switch (action.type) {
    case ActionTypes.COMBOS_REQUEST:
      return { ...state };
    case 'COMBOS_FULL_SUCCESS':
      var result = { ...state, data: { ...state.data, ...action.payload } };
      return result;
    case ActionTypes.FILTER_COMBO_SUCCESS:
      var mapper = action.payload.mapper;
      var result = { ...state };
      result.data[mapper] = { ...result.data[mapper], ...action.payload[mapper] };
      return result;
    case ActionTypes.COMBOS_SUCCESS:
      var result = { ...state, data: { ...state.data, ...action.payload } };
      for (var key in action.payload) {
        COMBOS_LOADED[key] = { ...COMBOS_LOADED[key], isLoaded: true };
      }
      return result;
    default:
      return state;
  }
}
