import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import _CommentCount from "../../images/commentCount.svg";
import { DateWithTimeFormatter } from "../column-formatters";
// import _RedCross from "../../images/icons/redCross.svg";
// import _BlueCheck from "../../images/icons/blueCheck.svg";
// import _Edit from "../../images/icons/edit.svg";
// import _RedCrossIcon from "../../images/redCrossIcon.svg";
// import _BlueCheckIcon from "../../images/blueCheckIcon.svg";
// import _EditIcon from "../../images/editIcon.svg";
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
export const JournalCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { data, index, toggleDrawer, key } = props;
  const {
    // CommentCount,
    // DateInserted,
    // IsConfidential,
    // IsPrivate,
    // LastModified,
    // ListOrder,
    // MediaGuid,
    // MediaType,
    // MediaUrl,
    // Note,
    // NoteType,
    // ThumbnailUrl,
    // UpdateByDisplayName,
    // UserNoteEmoji,
    // UserNoteId,
    ClientJournalGuid,
    ClientJournalId,
    DateEntered,
    EnteredBy,
    IsConfidential,
    IsPrivate,
    LastModified,
    Note,
    NoteTypes,
    MediaUrl,
    CommentCnt,
  } = data || {};
  return (
    <div className="journal-tabs" key={key}>
      <div
        className="p-4 mt-5 mb-5"
        style={{ background: "#F7F8FA", borderRadius: "4px" }}
      >
        {" "}
        <div className="mt-5 mb-5 pb-5 ">
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <div className="name">{NoteTypes}</div>
            <div className="date-time-style">
              {DateWithTimeFormatter(DateEntered)}
            </div>
          </div>
        </div>
        <div>
          <p style={{ color: "#182838", fontSize: "14px" }}>{Note}</p>
          {MediaUrl && <img src={MediaUrl} style={{ width: "100%" }} />}

          <div className="mt-2">
            <span
              className="mt-5"
              style={{ cursor: CommentCnt ? "pointer" : "" }}
              onClick={
                CommentCnt
                  ? toggleDrawer("right", true, ClientJournalGuid)
                  : null
              }
            >
              <img src={_CommentCount} className="mr-2" />
              {CommentCnt ? `${CommentCnt} Comments` : "No Comments"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
