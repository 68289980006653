import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import GenericTable from "../../components/generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  getDynamicGridFilter,
  assessmentsListGet,
} from "../../../redux/actions";
import { AssessmentsDrawer } from "./drawer";

const Assessments = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, moreItems) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };

  useEffect(() => {
    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "Assessments",
          },
        ],
      })
    );
    dispatch(assessmentsListGet({}));
    dispatch(
      getDynamicGridFilter({
        ComponentGrid: [
          {
            Name: "Assessments",
          },
        ],
      })
    );
  }, []);
  const onFilter = (filterValue, toggleFilterDrawer) => {
    let data = {
      ComponentGrid: [
        {
          Name: "Assessments",
          ...filterValue,
        },
      ],
    };
    dispatch(assessmentsListGet(data));
    toggleFilterDrawer("right", false)("no needs");
  };

  const { dynamicGridColumnData, isFetching, dynamicGridData } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data: gridColumns },
      population_activities_client_list_get: { data: gridData },
    }) => {
      return {
        dynamicGridColumnData: gridColumns,
        dynamicGridData: gridData?.Activities,
      };
    },
    shallowEqual
  );
  const {
    PageSize,
    IsRowClick,
    IsFilter,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = dynamicGridData || [];
  return (
    <div className="pt-5">
      <div className="assessments-tabs">
        {Columns && DataSource && (
          <GenericTable
            // tableTitle="Member Trends"
            entities={DataSource || []}
            columns={Columns || []}
            isCardHeader={false}
            pagination={true}
            isLoading={isFetching}
            bordered={false}
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            tableOptions={{
              PageSize,
              IsRowClick,
              IsExcelExport,
              IsColumnSelectionEnable,
              TableButtons,
              IsFilter,
              FileName: "Assessments",
            }}
            toggleDrawer={toggleDrawer}
            onFilter={onFilter}
          />
        )}
      </div>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false, {})}
      >
        <div role="presentation" style={{ width: "800px" }}>
          <AssessmentsDrawer
            toggleDrawer={toggleDrawer}
            StaffId={moreItem.StaffId}
            moreItem
          />
        </div>
      </Drawer>
    </div>
  );
};
export default Assessments;
