import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* practitionerOptionsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.practitionerOptionsGet(payload);
    yield put({
      type: ActionTypes.PRACTITIONER_OPTIONS_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PRACTITIONER_OPTIONS_GET_FAILURE,
    });
  }
}

export function* facilityOptionsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.facilityOptionsGet(payload);
    yield put({
      type: ActionTypes.FACILITY_OPTIONS_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.FACILITY_OPTIONS_GET_FAILURE,
    });
  }
}

export function* appointmentListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentListGet(payload);
    yield put({
      type: ActionTypes.APPOINTMENT_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_LIST_GET_FAILURE,
    });
  }
}

export function* appointmentUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentUpsert(payload);
    yield put({
      type: ActionTypes.APPOINTMENT_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_UPSERT_FAILURE,
    });
  }
}

export function* groupAppointmentUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.groupAppointmentUpsert(payload);
    yield put({
      type: ActionTypes.GROUP_APPOINTMENT_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_APPOINTMENT_UPSERT_FAILURE,
    });
  }
}

export function* blockAppointmentUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.blockAppointmentUpsert(payload);
    yield put({
      type: ActionTypes.BLOCK_APPOINTMENT_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BLOCK_APPOINTMENT_UPSERT_FAILURE,
    });
  }
}

export function* staffPinValidate(action) {
  try {
    const { payload } = action;
    const response = yield API.staffPinValidate(payload);
    yield put({
      type: ActionTypes.STAFF_PIN_VALIDATE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_PIN_VALIDATE_FAILURE,
    });
  }
}

export function* appointmentNoteGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentNoteGet(payload);
    yield put({
      type: ActionTypes.APPOINTMENT_NOTE_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_NOTE_GET_FAILURE,
    });
  }
}

export function* appointmentNoteUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentNoteUpsert(payload);
    yield put({
      type: ActionTypes.APPOINTMENT_NOTE_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_NOTE_UPSERT_FAILURE,
    });
  }
}

export function* appointmentNoteListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentNoteListGet(payload);
    yield put({
      type: ActionTypes.APPOINTMENT_NOTE_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_NOTE_LIST_GET_FAILURE,
    });
  }
}

export function* appointmentNoteAddendumUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentNoteAddendumUpsert(payload);
    yield put({
      type: ActionTypes.APPOINTMENT_NOTE_ADDENDUM_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_NOTE_ADDENDUM_UPSERT_FAILURE,
    });
  }
}

export function* groupAppointmentNoteGet(action) {
  try {
    const { payload } = action;
    const response = yield API.groupAppointmentNoteGet(payload);
    yield put({
      type: ActionTypes.GROUP_APPOINTMENT_NOTE_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_APPOINTMENT_NOTE_GET_FAILURE,
    });
  }
}

export function* groupAppointmentNoteUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.groupAppointmentNoteUpsert(payload);
    yield put({
      type: ActionTypes.GROUP_APPOINTMENT_NOTE_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_APPOINTMENT_NOTE_UPSERT_FAILURE,
    });
  }
}

export function* comboPractitioner(action) {
  try {
    const { payload } = action;
    const response = yield API.comboPractitioner(payload);
    yield put({
      type: ActionTypes.COMBO_PRACTITIONER_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_PRACTITIONER_FAILURE,
    });
  }
}

export function* comboFacility(action) {
  try {
    const { payload } = action;
    const response = yield API.comboFacility(payload);
    yield put({
      type: ActionTypes.COMBO_FACILITY_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_FACILITY_FAILURE,
    });
  }
}

export function* groupOptionGet(action) {
  try {
    const { payload } = action;
    const response = yield API.groupOptionGet(payload);
    yield put({
      type: ActionTypes.GROUP_OPTION_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_OPTION_GET_FAILURE,
    });
  }
}

export function* clientGroupOptionsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.clientGroupOptionsGet(payload);
    yield put({
      type: ActionTypes.CLIENT_GROUP_OPTIONS_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_GROUP_OPTIONS_GET_FAILURE,
    });
  }
}


export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.PRACTITIONER_OPTIONS_GET_REQUEST,
      practitionerOptionsGet
    ),
    takeLatest(ActionTypes.FACILITY_OPTIONS_GET_REQUEST, facilityOptionsGet),
    takeLatest(ActionTypes.APPOINTMENT_LIST_GET_REQUEST, appointmentListGet),
    takeLatest(ActionTypes.APPOINTMENT_UPSERT_REQUEST, appointmentUpsert),
    takeLatest(
      ActionTypes.GROUP_APPOINTMENT_UPSERT_REQUEST,
      groupAppointmentUpsert
    ),
    takeLatest(
      ActionTypes.BLOCK_APPOINTMENT_UPSERT_REQUEST,
      blockAppointmentUpsert
    ),
    takeLatest(ActionTypes.STAFF_PIN_VALIDATE_REQUEST, staffPinValidate),
    takeLatest(ActionTypes.APPOINTMENT_NOTE_GET_REQUEST, appointmentNoteGet),
    takeLatest(
      ActionTypes.APPOINTMENT_NOTE_UPSERT_REQUEST,
      appointmentNoteUpsert
    ),
    takeLatest(
      ActionTypes.APPOINTMENT_NOTE_LIST_GET_REQUEST,
      appointmentNoteListGet
    ),
    takeLatest(
      ActionTypes.APPOINTMENT_NOTE_ADDENDUM_UPSERT_REQUEST,
      appointmentNoteAddendumUpsert
    ),
    takeLatest(
      ActionTypes.GROUP_APPOINTMENT_NOTE_GET_REQUEST,
      groupAppointmentNoteGet
    ),
    takeLatest(
      ActionTypes.GROUP_APPOINTMENT_NOTE_UPSERT_REQUEST,
      groupAppointmentNoteUpsert
    ),
    takeLatest(ActionTypes.COMBO_PRACTITIONER_REQUEST, comboPractitioner),
    takeLatest(ActionTypes.COMBO_FACILITY_REQUEST, comboFacility),
    takeLatest(ActionTypes.GROUP_OPTION_GET_REQUEST, groupOptionGet),
    takeLatest(ActionTypes.CLIENT_GROUP_OPTIONS_GET_REQUEST, clientGroupOptionsGet),
  ]);
}
