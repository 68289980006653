import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import _CommentCount from "../../images/commentCount.svg";
import { DateWithTimeFormatter } from "../column-formatters";
import _Archived from "../../images/archived.svg";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
export const ArchivedAssessmentsCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { data, index, toggleDrawer, key } = props;
  const { SurveyName, DueDate, AssignedDate, ArchivedDate } = data || {};
  return (
    <div className="assessments-tabs-card" key={key}>
      <div className="assessments-card">
        <div className="archived">
          <div className="status mb-2">
            <span>
              <SVG
                src={toAbsoluteUrl(_Archived)}
                width={24}
                height="auto"
                title="Archived"
                className="mr-2"
              />{" "}
              Archived
            </span>
          </div>
          <span className="name">{SurveyName}</span>
          <div className="block">
            <div className="d-flex justify-content-between">
              <div className="assigned-date">
                <span className="date-text">Assigned</span>
                <span className="date-value">{AssignedDate}</span>
              </div>
              <div className="archived-date">
                <span className="date-text">Archived </span>{" "}
                <span className="date-value">{ArchivedDate}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
