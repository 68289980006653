import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";

export function* forgetPassword(params) {
  try {
    const response = yield API.forgetPassword(params.payload);
    yield put({
      type: ActionTypes.FORGET_PASSWORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FORGET_PASSWORD_FAILURE,
      error: error,
    });
  }
}

export function* resetPassword(params) {
  try {
    const response = yield API.resetPassword(params.payload);
    yield put({
      type: ActionTypes.RESET_PASSWORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.RESET_PASSWORD_FAILURE,
      error: error,
    });
  }
}

export function* logDevice(params) {
  try {
    const response = yield API.logDevice(params.payload);
    yield put({
      type: ActionTypes.LOG_DEVICE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.LOG_DEVICE_FAILURE,
      error: error,
    });
  }
}

export function* logError(params) {
  try {
    const response = yield API.logError(params.payload);
    yield put({
      type: ActionTypes.LOG_ERROR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.LOG_ERROR_FAILURE,
      error: error,
    });
  }
}

export function* twoStepAuth(params) {
  try {
    const response = yield API.twoStepAuth(params.payload);
    yield put({
      type: ActionTypes.TWO_STEP_AUTH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.TWO_STEP_AUTH_FAILURE,
      error: error,
    });
  }
}

export function* validatePasscode(params) {
  try {
    const response = yield API.validatePasscode(params.payload);
    yield put({
      type: ActionTypes.VALIDATE_PASS_CODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.VALIDATE_PASS_CODE_FAILURE,
      error: error,
    });
  }
}
export default function* root() {
  yield takeLatest(ActionTypes.FORGET_PASSWORD_REQUEST, forgetPassword);
  yield takeLatest(ActionTypes.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(ActionTypes.LOG_DEVICE_REQUEST, logDevice);
  yield takeLatest(ActionTypes.LOG_ERROR_REQUEST, logError);
  yield takeLatest(ActionTypes.TWO_STEP_AUTH_REQUEST, twoStepAuth);
  yield takeLatest(ActionTypes.VALIDATE_PASS_CODE_REQUEST, validatePasscode);
}
