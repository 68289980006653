import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* intakeClientUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.intakeClientUpsert(payload);
    yield put({
      type: ActionTypes.INTAKE_CLIENT_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.INTAKE_CLIENT_UPSERT_FAILURE,
    });
  }
}

export function* intakeInsuranceUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.intakeInsuranceUpsert(payload);
    yield put({
      type: ActionTypes.INTAKE_INSURANCE_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.INTAKE_INSURANCE_UPSERT_FAILURE,
    });
  }
}

export function* intakeInsurancePriorityOrderGet(action) {
  try {
    const { payload } = action;
    const response = yield API.intakeInsurancePriorityOrderGet(payload);
    yield put({
      type: ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_GET_FAILURE,
    });
  }
}

export function* intakeInsurancePriorityOrderUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.intakeInsurancePriorityOrderUpdate(payload);
    yield put({
      type: ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_UPDATE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_UPDATE_FAILURE,
    });
  }
}

export function* intakeMedicalHistoryUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.intakeMedicalHistoryUpsert(payload);
    yield put({
      type: ActionTypes.INTAKE_MEDICAL_HISTORY_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.INTAKE_MEDICAL_HISTORY_UPSERT_FAILURE,
    });
  }
}

export function* intakeFormDataGet(action) {
  try {
    const { payload } = action;
    const response = yield API.intakeFormDataGet(payload);
    yield put({
      type: ActionTypes.INTAKE_FORM_DATA_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.INTAKE_FORM_DATA_GET_FAILURE,
    });
  }
}

export function* intakeEnrollNow(action) {
  try {
    const { payload } = action;
    const response = yield API.intakeEnrollNow(payload);
    yield put({
      type: ActionTypes.INTAKE_ENROLL_NOW_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.INTAKE_ENROLL_NOW_FAILURE,
    });
  }
}

export function* preIntakeGridReports(action) {
  try {
    const { payload } = action;
    const response = yield API.preIntakeGridReports(payload);
    yield put({
      type: ActionTypes.PRE_INTAKE_GRID_REPORTS_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PRE_INTAKE_GRID_REPORTS_FAILURE,
    });
  }
}


export function* preIntakeDefaultAssessmentGet(action) {
  try {
    const { payload } = action;
    const response = yield API.preIntakeDefaultAssessmentGet(payload);
    yield put({
      type: ActionTypes.PRE_INTAKE_DEFAULT_ASSESSMENT_GET_REQUEST_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PRE_INTAKE_DEFAULT_ASSESSMENT_GET_REQUEST_FAILURE,
    });
  }
}


;

export default function* root() {
  yield all([
    takeLatest(ActionTypes.INTAKE_CLIENT_UPSERT_REQUEST, intakeClientUpsert),
    takeLatest(
      ActionTypes.INTAKE_INSURANCE_UPSERT_REQUEST,
      intakeInsuranceUpsert
    ),
    takeLatest(
      ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_GET_REQUEST,
      intakeInsurancePriorityOrderGet
    ),
    takeLatest(
      ActionTypes.INTAKE_INSURANCE_PRIORITY_ORDER_UPDATE_REQUEST,
      intakeInsurancePriorityOrderUpdate
    ),
    takeLatest(
      ActionTypes.INTAKE_MEDICAL_HISTORY_UPSERT_REQUEST,
      intakeMedicalHistoryUpsert
    ),
    takeLatest(ActionTypes.INTAKE_FORM_DATA_GET_REQUEST, intakeFormDataGet),
    takeLatest(ActionTypes.INTAKE_ENROLL_NOW_REQUEST, intakeEnrollNow),
    takeLatest(ActionTypes.PRE_INTAKE_GRID_REPORTS_REQUEST, preIntakeGridReports),
    takeLatest(ActionTypes.PRE_INTAKE_DEFAULT_ASSESSMENT_GET_REQUEST, preIntakeDefaultAssessmentGet)
  ]);
}
