import React from "react";
import { Breathlyzer } from "../components/custom-screens/breathlyzerPage/index";
const BreathlyzerComponent = (props) => {
  return (
    <>
      {" "}
      <div className="row">
        <Breathlyzer />
      </div>
    </>
  );
};
export default BreathlyzerComponent;
