import React from "react";
import SVG from "react-inlinesvg";

import { DictateCheckbox } from "react-dictate-button";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _Microphone from "../../../images/microphone.svg";
const DictationComponent = (props) => {
  return (
    <DictateCheckbox
      className="my-dictate-checkbox"
      type="button"
      grammar="#JSGF V1.0; grammar districts; public <district> = Redmond | Bellevue;"
      onDictate={props.handleDictate}
    >
      {({ readyState }) => {
        switch (readyState) {
          case 0:
            return <SVG src={toAbsoluteUrl(_Microphone)} />;
          case 1:
            return <div className="dictate-status">Preparing to Listen</div>;
          case 2:
            return <div className="dictate-status">Listening...</div>;
          case 3:
            if (props.getDicatationStatus)
              props.getDicatationStatus(readyState)();
            return <div className="dictate-status">Processing...</div>;
          default:
            return <div className="dictate-status">Start Dictation</div>;
        }
      }}
    </DictateCheckbox>
  );
};

export default DictationComponent;
