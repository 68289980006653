import React from "react";

export const BoldTextFormatter = (cell, row, rowIndex, formatExtraData) => {
  // const { More, Phone, chat } = row.Actions[0];
  return (
    <div
      className="d-flex align-items-center"
      style={{ minWidth: "250px", maxWidth: "300px" }}
    >
      <div className="col-12 d-flex align-items-center">
        <div className="d-flex flex-column">
          <div>
            <span style={{ fontSize: "14px", fontWeight: "600" }}>{cell}</span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
