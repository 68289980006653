import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";

export function* userMemberTrendHistory(action) {
  try {
    const { payload } = action;
    const response = yield API.userMemberTrendHistory(payload);
    yield put({
      type: ActionTypes.USER_MEMBER_TREND_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_MEMBER_TREND_HISTORY_FAILURE,
    });
  }
}
export function* upsertClientNote(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertClientNote(payload);
    yield put({
      type: ActionTypes.UPSERT_CLIENT_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CLIENT_NOTE_FAILURE,
    });
  }
}
export function* getPreviousNotes(action) {
  try {
    const { payload } = action;
    const response = yield API.getPreviousNotes(payload);
    yield put({
      type: ActionTypes.GET_PREVIOUS_NOTE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PREVIOUS_NOTE_FAILURE,
    });
  }
}
export function* memberTrendTriggerHistory(action) {
  try {
    const { payload } = action;
    const response = yield API.memberTrendTriggerHistory(payload);
    yield put({
      type: ActionTypes.MEMBER_TREND_TRIGGER_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MEMBER_TREND_TRIGGER_HISTORY_FAILURE,
    });
  }
}

export function* memberHeaderInfoGet(action) {
  try {
    const { payload } = action;
    const response = yield API.memberHeaderInfoGet(payload);
    yield put({
      type: ActionTypes.MEMBER_HEADER_INFO_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MEMBER_HEADER_INFO_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.USER_MEMBER_TREND_HISTORY_REQUEST,
      userMemberTrendHistory
    ),
    takeLatest(ActionTypes.UPSERT_CLIENT_NOTE_REQUEST, upsertClientNote),
    takeLatest(ActionTypes.GET_PREVIOUS_NOTE_REQUEST, getPreviousNotes),
    takeLatest(
      ActionTypes.MEMBER_TREND_TRIGGER_HISTORY_REQUEST,
      memberTrendTriggerHistory
    ),
    takeLatest(ActionTypes.MEMBER_HEADER_INFO_GET_REQUEST, memberHeaderInfoGet),
  ]);
}
