import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* allGet(action) {
    try {
        const { payload } = action;
        const response = yield API.allGet(payload);
        yield put({
            type: ActionTypes.ALL_GET_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.ALL_GET_FAILURE
        });
    }
}

export function* populationActivitiesClientListGet(action) {
    try {
        const { payload } = action;
        const response = yield API.populationActivitiesClientListGet(payload);
        yield put({
            type: ActionTypes.POPULATION_ACTIVITIES_CLIENT_LIST_GET_SUCCESS,
            payload: response.data.Data
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction:
                ActionTypes.POPULATION_ACTIVITIES_CLIENT_LIST_GET_FAILURE
        });
    }
}

export function* getUserInfo(action) {
    try {
        const { payload } = action;
        const response = yield API.getUserInfo(payload);
        yield put({
            type: ActionTypes.GET_USER_INFO_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.GET_USER_INFO_FAILURE
        });
    }
}

export function* getClientAlbum(action) {
    try {
        const { payload } = action;
        const response = yield API.getClientAlbum(payload);
        yield put({
            type: ActionTypes.GET_CLIENT_ALBUM_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.GET_CLIENT_ALBUM_FAILURE
        });
    }
}

export function* postListGet(action) {
    try {
        const { payload } = action;
        const response = yield API.postListGet(payload);
        yield put({
            type: ActionTypes.POST_LIST_GET_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.POST_LIST_GET_FAILURE
        });
    }
}

export function* clientFriendAllGet(action) {
    try {
        const { payload } = action;
        const response = yield API.clientFriendAllGet(payload);
        yield put({
            type: ActionTypes.CLIENT_FRIEND_ALL_GET_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.CLIENT_FRIEND_ALL_GET_FAILURE
        });
    }
}

export function* triggersListGet(action) {
    try {
        const { payload } = action;
        const response = yield API.triggersListGet(payload);
        yield put({
            type: ActionTypes.TRIGGERS_LIST_GET_SUCCESS,
            payload: ResponseHandler(response)
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERRORS_REQUEST,
            successAction: action,
            error: error,
            failedAction: ActionTypes.TRIGGERS_LIST_GET_FAILURE
        });
    }
}

export function* clientListDropdown(action) {
  try {
    const { payload } = action;
    const response = yield API.clientListDropdown(payload);
    yield put({
      type: ActionTypes.CLIENT_LIST_DROPDOWN_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_LIST_DROPDOWN_FAILURE,
    });
  }
}


export default function* root() {
    yield all([
        takeLatest(ActionTypes.ALL_GET_REQUEST, allGet),
        takeLatest(
            ActionTypes.POPULATION_ACTIVITIES_CLIENT_LIST_GET_REQUEST,
            populationActivitiesClientListGet
        ),
        takeLatest(ActionTypes.GET_USER_INFO_REQUEST, getUserInfo),
        takeLatest(ActionTypes.GET_CLIENT_ALBUM_REQUEST, getClientAlbum),
        takeLatest(ActionTypes.POST_LIST_GET_REQUEST, postListGet),
        takeLatest(
            ActionTypes.CLIENT_FRIEND_ALL_GET_REQUEST,
            clientFriendAllGet
        ),
        takeLatest(ActionTypes.TRIGGERS_LIST_GET_REQUEST, triggersListGet),
        takeLatest(ActionTypes.CLIENT_LIST_DROPDOWN_REQUEST, clientListDropdown), 
    ]);
}
