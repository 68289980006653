export const URLs = {
  forgetPassword: "forgetPassword/SendMail",
  resetPassword: "login/ResetPassword",
  getDashboard: `dashboard/GetAllInfo_Json`,
  listStudents: `student/list`,
  addStudent: `student/Upsert`,
  addStudentNote: `student/InsertNote`,
  testError: "student/TestError",
  upsertStudentPin: `student/UpsertPin`,
  validateStudentPin: `student/ValidatePin`,
  getStudent: `student/Student_GetAllInfo_Json`,
  studentMarkComplete: `student/MarkComplete`,
  studentDischarge: `student/Discharge`,
  studentReinstate: `student/StudentReinstate`,
  studentUnAssign: `student/UnAssign`,
  listTodo: `todo/list`,
  listTodoByStudent: `todo/listByStudentId`,
  listActivity: `ActivityLog/list`,
  listActivityByStudent: `ActivityLog/listByStudentId`,
  addTodo: `todo/Upsert`,
  getReportData: `report/GetReportData`,
  announcementGetAll: `Adminstration/ListAnnouncement`,
  announcementGetJson: `Adminstration/GetAnnouncement`,
  announcementUpsert: `Adminstration/UpsertAnnouncement`,
  announcementDelete: `Adminstration/DeleteAnnouncement`,
  logDevice: `login/LogDevice`,
  logError: `user/LogError`,
  loadMenu: `user/ListMenu`,
  logOut: `login/LogOut`,
  getStudentProfileDashboardData: `Student/Student_Dashboard_Json`,
  getStudentsSearch: "student/StudentsSearch",
  getUserRole: "UserRole/List",
  getListJobTitle: "UserRole/ListJobTitle",
  updateJobTitle: "UserRole/UpsertJobTitle",
  updateRole: "UserRole/Upsert",
  deleteRole: "UserRole/Delete",
  deleteJobTitle: "UserRole/DeleteJobTitle",
  updateRolePermissions: "UserRole/UpdatePermissions",
  updateJobTitleSync: "UserRole/JobTitleRoleSync",
  userFeedback: "user/feedback",
  isLicenseAvailable: "Adminstration/IsLicenseAvailable",
  getDashboardLoadAppUsers: `AppSubscribers/LoadAppUsers`,
  getValidateImage: `user/ValidateImage`,
  getLicenseActivites: `Adminstration/GetLicenseActivites`,
  getCurrentLicenses: `Adminstration/GetCurrentLicenses`,
  getLicensesInvoice: `Licensing/InvoiceGet`,
  getLicensesReceipt: `Licensing/ReceiptUrlGet`,
  addLicenses: `Licensing/AddLicense`,
  getPaymentInfo: `Licensing/PaymentInfoGet`,
  updatePaymentMethode: `Licensing/PaymentMethodUpdate`,
  isAchValidation: `Licensing/IsCheckingAccountValidationRequired`,
  achValidate: `Licensing/BankAccountValidate`,
  getBillingInfo: `Licensing/GetBillingInfo`,
  getStripeApiKey: `Licensing/GetStripeApiPublicKey`,
  addNewApplicant: "Student/AddNewApplicant",
  listAppSubscribers: "AppSubscribers/List",
  getAppSubscriberProfileDashboard: "AppSubscribers/Load",
  getAppSubscriberProfile: "AppSubscribers/LoadProfile",
  getAppSubscriberChat: "AppSubscribers/LoadChat",
  getAppSubscriberChatCoach: "AppSubscribers/LoadAppUserCoachChat",
  getAppSubscriberProfileActivities: "AppSubscribers/LoadActivities",
  addAppSubscriberNote: "AppSubscribers/AddNote",
  appSubscribersUserSearch: "AppSubscribers/UsersSearch",
  addAppSubscriberTask: "AppSubscribers/TaskUpsertMultiple",
  deleteAppSubscriberTask: "AppSubscribers/TaskUnassign",
  appSubscriberCompleteTask: "AppSubscribers/TaskMarkComplete",
  appSubscribersupdateCase: "AppSubscribers/UpdateProfileMisc",
  appInvite: `Adminstration/SendMobileAppInvite`,
  getMobileAppDownloadStatus: `Adminstration/GetMobileAppDownloadStatus`,
  isManageLicenseRequired: "Adminstration/IsManageLicenseRequired",
  getAllCallSession: `Counselor/Counselor_CallSession_GetAllInfo_Json`,
  addCallSession: `Counselor/Counselor_CallSession_Upsert`,
  getAllVacation: `Counselor/Counselor_Vacation_GetAllInfo_Json`,
  addVacationDays: `Counselor/Counselor_Vacation_Upsert`,
  getOneCallSession: `Counselor/Counselor_CallSession_GetAllInfo_Json`,
  inviteOneCallSession: `Counselor/Counselor_CallSession_Invite`,
  cancelOneCallSession: `Counselor/Counselor_CallSession_Cancel`,
  editGroupCallSession: `Counselor/Counselor_CallSession_GroupCall_Get_Json`,
  getCallSessionVideo: `Counselor/Counselor_CallSession_VideoList_Get_Json`,
  getCallSessionVideoUrl: `Counselor/Counselor_GetVideoUrl`,
  getReports: `dashboard/GetReports`,
  getPromoCode: `Adminstration/GetPromoCode`,
  getCounselor: `Adminstration/GetCounselor`,
  sendVoucherCode: `SmallGroups/SendVoucherCode`,
  getSmallGroupUsers: `SmallGroups/ListSmallGroupUser`,
  uploadLeaderPhoto: `SmallGroups/UploadPicture`,
  markSystemObservation: "Student/MarkSystemObservation",
  appMarkSystemObservation: "AppSubscribers/MarkSystemObservation",
  getAnalytics: "Dashboard/GetAnalytics",
  getActiveClient: "Dashboard/GetActiveClient",
  getNotes: "Student/GetNote",
  getCategoryClient: "Dashboard/GetCategoryClient",
  getClientDemographic: "Dashboard/GetClientDemographic",
  getCostOfCare: "Dashboard/GetCostOfCare",
  getBehavioralRating: "Dashboard/GetBehavioralRating",
  getScoreImprovement: "Dashboard/GetScoreImprovement",
  getProviderPerformance: "Dashboard/GetProviderPerformance",
  getServiceDelivered: "Dashboard/GetServiceDelivered",
  getReferralSource: "Dashboard/GetReferralSource",
  getStudentDocumentList: "Student/GetStudentDocumentList",
  archiveStudentDocument: "Student/ArchiveStudentDocument",
  uploadDocument: "Student/UploadDocumentUsingMultiPart",
  downloadStudentDocument: "Student/DownloadStudentDocument",
  newsUpsert: `Adminstration/UpsertNews`,
  getListNews: `Adminstration/ListNews`,
  getNews: `Adminstration/GetNews`,
  deleteNews: `Adminstration/DeleteNews`,
  getEmbedInfo: `EmbedInfo/GetEmbedInfo`,
  getPatientList: `Medicaid/ListClient`,
  getPatientDetail: `Medicaid/GetClient`,
  upsertPatientEnrollment: `Medicaid/UpsertClient`,
  upsertClientInsurance: `Medicaid/UpsertClientInsurance`,
  upsertClientMedical: `Medicaid/UpsertClientMedical`,
  upsertClientPayment: `Medicaid/UpsertClientPayment`,
  upsertProviderEnrollment: `Medicaid/UpsertDoctor`,
  upsertAppointment: `Medicaid/UpsertAppointment`,
  listAppointment: `Medicaid/ListAppointment`,
  getAppointment: `Medicaid/GetAppointment`,
  deleteAppointment: `Medicaid/DeleteAppointment`,
  upsertGroup: `Medicaid/UpsertGroup`,
  listGroup: `Medicaid/ListGroup`,
  getGroup: `Medicaid/GetGroup`,
  deleteGroup: `Medicaid/DeleteGroup`,
  upsertClientGroup: `Medicaid/UpsertClientGroup`,
  getClientGroup: `Medicaid/GetClientGroup`,
  upsertGroupAppointment: `Medicaid/UpsertGroupAppointment`,
  upsertGroupAppointmentGroupAllInfoJson: `Medicaid/GetGroupAppointment`,
  getListClientGroup: `Medicaid/ListClient_Group`,
  listCombos: `combo/list`,
  listFilterCombo: `Filtercombo/list`,
  listSmartCombos: `SmartCombo/list`,
  spListCombos: `combo/SPList`,
  getDynamicComos: `combo/ListDynamic`,
  medicateCombos: `Combo/ListMedicaid`,
  practitionerCombos: `Medicaid/ComboPractitioner`,
  practitionerCombosOffice: `Medicaid/ComboPractitionerOffice`,
  patientComboGroup: `Medicaid/ComboGroup`,
  getComboFacility: `Medicaid/ComboFacility`,
  comboPractitionerAll: `Medicaid/ComboPractitionerIncludeAll`,
  comboFacilityAll: `Medicaid/ComboFacilityIncludeAll`,
  getDiagnosis: `Combo/ListDictionary`,
  getDiagnosisCode: `Medicaid/ComboDiagnosis`,
  comboProblemTemplate: `Medicaid/ComboProblemTemplate`,
  comboGoalTemplate: `Medicaid/ComboGoalTemplate`,
  recurringCount: `Medicaid/ComboGroupAppointmnetRecurring`,
  comboInterventionTemplate: `Medicaid/ComboInterventionTemplate`,
  comboPayer: `Medicaid/ComboPayer`,
  comboCode: `Medicaid/ComboCode`,
  comboPlaceOfService: `Medicaid/ComboPlaceOfService`,
  comboTreatmentPlanIntervention: `Medicaid/ComboTreatmentPlanIntervention`,
  comboDiagnosisList: `Medicaid/ComboDiagnosisList`,
  comboSupervisor: `Medicaid/ComboSupervisor`,
  comboAppointmentTreatmentPlan: `Medicaid/ComboAppointmentLink`,
  listMobileAppRoleGet: "combo/List_MobileAppRole_Get",
  deactivateGroupAppointment: `Medicaid/DeactivateGroupAppointment`,
  upsertClientConsent: `Medicaid/UpsertClientConsent`,
  listClientConsent: `Medicaid/ListClientConsent`,
  deactivateClientConsent: `Medicaid/DeactivateClientConsent`,
  deactivateClientScreening: `Medicaid/DeactivateSurvey`,
  loadJudicialDashboard: `AppSubscribers/LoadJudicialDashboard`,
  getJudicialUserList: `AppSubscribers/JudicialUsersList`,
  UploadDocumentUsingMultiPart: `Misc/UploadDocumentUsingMultiPart`,
  // upsertClientDocument: `Student/UploadDocumentUsingMultiPart`,
  upsertClientDocument: `Medicaid/UpsertClientDocument`,
  listClientDocument: `Medicaid/ListClientDocument`,
  downloadClientDocument: `Medicaid/DownloadClientDocument`,
  archiveClientDocument: `Misc/ArchiveDocument`,
  listGroupMembership: `Medicaid/ListGroupMembership`,
  getConsentFormTemplate: `Medicaid/GetConsentFormTemplate`,
  downloadClientConsent: `Medicaid/DownloadClientConsent`,
  saveClientConsent: `Medicaid/ConsentFormSignSave`,
  getTreatmentPlan: `Medicaid/GetTreatmentPlan`,
  upsertTreatmentPlanProblem: `Medicaid/UpsertTreatmentPlanProblem`,
  upsertMedicalAnalysis: `Medicaid/UpsertDiagnosis`,
  upsertTreatmentPlanGoal: `Medicaid/UpsertTreatmentPlanGoal`,
  upsertTreatmentPlanObjective: `Medicaid/UpsertTreatmentPlanObjective`,
  upsertTreatmentPlanIntervention: `Medicaid/UpsertTreatmentPlanIntervention`,
  deleteTreatmentPlan: `Medicaid/DeleteTreatmentPlan`,
  getDiagnosisDescription: `Medicaid/GetDiagnosisDescription`,
  listTreatmentPlan: `Medicaid/ListTreatmentPlan`,
  loadCaseProfile: `AppSubscribers/LoadCaseProfile`,
  loadLocationAuthHistory: `AppSubscribers/LoadLocationAuthHistory`,
  screenAuthorization: `User/AuthorizedPathGetList`,
  upsertTreatmentPlanDimension: `Medicaid/UpsertTreatmentPlanDimension`,
  saveConsentSign: `Medicaid/ConsentFormSignSave`,
  consentFormDownload: `Medicaid/ConsentFormDownload`,
  listMeeting: `Medicaid/ListMeeting`,
  upsertProgressGroupNote: `Medicaid/UpsertProgressNoteGroup`,
  getProgressNoteGroup: `Medicaid/GetProgressNoteGroup`,
  getUserNotePatient: `AppSubscribers/GetUserNotePatient`,
  getUserNoteComment: `AppSubscribers/GetUserNoteComment`,
  addUserNoteComment: `AppSubscribers/AddUserNoteComment`,
  getUserNoteEmoji: `AppSubscribers/GetUserNoteEmoji`,
  addUserNoteEmoji: `AppSubscribers/AddUserNoteEmoji`,
  getProgressNoteForIntervention: `Medicaid/GetProgressNoteForIntervention`,
  upsertPayer: `Medicaid/UpsertPayer`,
  listPayer: `Medicaid/ListPayer`,
  deletePayer: `Medicaid/DeletePayer`,
  getPayer: `Medicaid/GetPayer`,
  upsertServiceCode: `Medicaid/UpsertServiceCode`,
  listServiceCode: `Medicaid/ListServiceCode`,
  deleteServiceCode: `Medicaid/DeleteServiceCode`,
  getServiceCode: `Medicaid/GetServiceCode`,
  getCodeDescription: `Medicaid/GetCodeDescription`,
  appointmentNoteList: `Medicaid/ListAppointmentNote`,
  upsertAppointmentNote: `Medicaid/UpsertAppointmentNote`,
  getAppointmentNote: `Medicaid/GetAppointmentNote`,
  listServiceLine: `Medicaid/ListServiceLine`,
  getServiceLine: `Medicaid/GetServiceLine`,
  upsertServiceLine: `Medicaid/UpsertServiceLine`,
  upsertAppointmentNoteAddendum: `Medicaid/UpsertAppointmentNoteAddendum`,
  listGroupAppointmentNote: `Medicaid/ListGroupAppointmentNote`,
  getGroupAppointmentNote: `Medicaid/GetGroupAppointmentNote`,
  listFeeSchedule: `Medicaid/ListFeeSchedule`,
  getFeeSchedule: `Medicaid/GetFeeSchedule`,
  upsertFeeSchedule: `Medicaid/UpsertFeeSchedule`,
  getTreatmentPlanGraph: `Medicaid/GetTreatmentPlanGraph`,
  listGroupServiceLine: `Medicaid/ListGroupServiceLine`,
  getGroupServiceLine: `Medicaid/GetGroupServiceLine`,
  getGroupBanner: `Medicaid/GetGroupBanner`,
  upsertGroupServiceLine: `Medicaid/UpsertGroupServiceLine`,
  upsertGroupAppointmentNote: `Medicaid/UpsertGroupAppointmentNote`,
  upsertGroupAppointmentNoteAddendum: `Medicaid/UpsertGroupAppointmentNoteAddendum`,
  getServiceLineCost: `Medicaid/GetServiceLineCost`,
  upsertPin: `User/UpdatePin`,
  loadSmallGroupDashboard: `AppSubscribers/LoadSmallGroupDashboard`,
  upsertTreatmentPlan: `Medicaid/UpsertTreatmentPlan`,
  listClientDiagnosis: `Medicaid/ListDiagnosis`,
  getClientDiagnosis: `Medicaid/GetDiagnosis`,
  deleteClientDiagnosis: `Medicaid/DeleteDiagnosis`,
  validatePinExists: `Adminstration/ValidatePinExists`,
  cloneDiagnosis: `Medicaid/CloneDiagnosis`,
  cloneTreatmentPlan: `Medicaid/CloneTreatmentPlan`,
  rcmClientMerge: `Student/ClientMerge`,
  preValidatePin: `Medicaid/PreValidatePin`,
  validateClient: `Medicaid/ValidateClient`,
  getAppAdvisor: `AppSubscribers/GetAppAdvisor`,
  getMediaVideoUrl: `AppSubscribers/GetMediaVideoUrl`,
  getDashboardMenu: `Dashboard/GetMenu`,
  listServiceCodeBundle: `Medicaid/ListServiceCodeBundle`,
  getServiceCodeBundle: `Medicaid/GetServiceCodeBundle`,
  getStudentNote: `Student/GetStudentNote`,
  getStudentNoteComment: `Student/GetStudentNoteComment`,
  addStudentNoteComment: `Student/AddStudentNoteComment`,
  addStudentNoteEmoji: `Student/AddStudentNoteEmoji`,
  getStudentNoteEmoji: `Student/GetStudentNoteEmoji`,
  getTreatmentPlanAccordin: `Medicaid/GetTreatmentPlanAccordin`,

  getAdvisorCost: `Counselor/AdvisorCostGet`,
  upsertAdvisorCost: `Counselor/AdvisorCostUpdate`,
  claimsPdfDownload: `Claims/PDFDownload`,
  getClientService: `Claims/ClientServicesGet`,
  getDashboardDailySchedule: `Medicaid/GetDashboardDailySchedule`,
  getDashboardDailyCaseLoad: `Medicaid/GetDashboardCaseLoad`,
  getDashboardAlert: `Medicaid/GetDashboardAlert`,
  addClientInsuranceEligibility: `Medicaid/AddClientInsuranceEligibility`,
  editClientInsuranceEligibility: `Medicaid/EditClientInsuranceEligibility`,
  deleteClientInsuranceEligibility: `Medicaid/DeleteClientInsuranceEligibility`,
  dischargeSummarySave: `Medicaid/DischargeSummarySave`,
  getClientAppointment: `Medicaid/GetAppointmentClient`,
  getGroupAppointment: `Medicaid/GetGroupAppointmentForGroup`,
  unsignForm: `Medicaid/UnSignForm`,
  getFilters: `Timeline/GetFilters`,
  listLabOrder: `Medicaid/ListLabOrder`,
  getLabOrder: `Medicaid/GetLabOrder`,
  upsertLabOrder: `Medicaid/UpsertLabOrder`,
  getMedicationNote: `Medicaid/GetMedicationNote`,
  upsertClientGroupAppointment: `Medicaid/UpsertClientGroupAppointment`,
  signGroupAppointmentNote: `Medicaid/SignGroupAppointmentNote`,
  listServiceLinePayment: `Medicaid/ListServiceLinePayment`,
  getServiceLinePayment: `Medicaid/GetServiceLinePayment`,
  upsertServiceLinePayment: `Medicaid/UpsertServiceLinePayment`,
  deleteServiceLinePayment: `Medicaid/DeleteServiceLinePayment`,
  locationDayHistory: `AppSubscribers/LocationDayHistory`,
  upsertPaymentClient: `Medicaid/UpsertCWPayment`,
  getInsurancePriorityOrder: `Medicaid/GetClientInsurancePriorityOrder`,
  upsertInsurancePriorityOrder: `Medicaid/UpsertClientInsurancePriorityOrder`,
  listCWPayment: `Medicaid/ListCWPayment`,
  getCWPayment: `Medicaid/GetCWPayment`,
  deleteCWPayment: `Medicaid/DeleteCWPayment`,
  pastMeetingInfo: `SmallGroups/PastMeetingInfo`,
  listPastMeetingInfo: `SmallGroups/ListPastMeetingInfo`,
  linkAppointment: `Medicaid/LinkAppointment`,
  appointmentLinkGet: `Medicaid/AppointmentLinkGet`,
  getBasicProfile: `AppSubscribers/GetBasicProfile`,
  getInvitedList: `AppSubscribers/InvitedListGet`,
  inviteResend: `AppSubscribers/InviteResend`,
  suspiciousTermAck: `AppSubscribers/SuspiciousTermAck`,
  getBroadcastFilter: `AppSubscribers/GetBroadcastFilter`,
  sendBroadcast: `AppSubscribers/SendBroadcast`,
  getServiceLineDiagnosis: `Medicaid/GetServiceLineDiagnosis`,
  upsertServiceLineDiagnosis: `Medicaid/UpsertServiceLineDiagnosis`,
  deleteNoteType: `Adminstration/DeleteNoteType`,
  listClientAuthorization: `Medicaid/ListClientAuthorization`,
  getClientAuthorization: `Medicaid/GetClientAuthorization`,
  deleteClientAuthorization: `Medicaid/DeleteClientAuthorization`,
  upsertClientAuthorization: `Medicaid/UpsertClientAuthorization`,
  getNoteTypeArchive: "Adminstration/GetNoteTypeArchive",
  getNoteTypeList: "Adminstration/GetNoteType",
  generateCallToken: "CallToken/Generate",
  badgeUpdate: "AppSubscribers/BadgeUpdate",
  upsertBroadcastTemplate: `AppSubscribers/UpsertBroadcastTemplate`,
  panasScaleMoodsGet: "Misc/PANASScaleMoodsGet",
  panasTagUpsert: "Medicaid/PANASTagUpsert",
  bedAssignmentGetAll: "BedMgmt/BedAssignmentGetAll",
  bedAssignmentUpsert: "BedMgmt/BedAssignmentUpsert",
  upcomingDischargesGetAll: "BedMgmt/UpcomingDischarges ",
  upcomingAdmissions: "BedMgmt/UpcomingAdmissions ",
  admissionCancel: "BedMgmt/AdmissionCancel ",
  panasTagDelete: "Medicaid/PANASTagDelete",
  dynamicGridList: "AppSubscribers/DynamicGridList",
  getGrid: "Component/GridGet",
  getGridData: "Component/GridDataGet",
  dynamicGridDataGet: "GenericForm/DynamicGridDataGet",
  dashboardAnalyticsGet: "Dashboard/AnalyticsGet",
  userActivityGet: "Dashboard/UserActivityGet",
  userSuspiciousWordGet: "Dashboard/UserSuspiciousWordGet",
  userNotificationGet: "Dashboard/UserNotificationGet",
  userMemberTrendHistory: "Dashboard/UserMemberTrendHistory",
  upsertClientNote: "ClientNote/Upsert",
  getPreviousNotes: "ClientNote/GetPreviousNotes",
  memberHeaderInfoGet: "Dashboard/MemberHeaderInfoGet",
  chatFriendListGet: "User/ChatFriendListGet",
  listClientV2: "Medicaid/ListClientV2",
  courseAssign: `student/MultipleCourseAssign`,
  listCourses: `student/GetCurrentCourses`,
  adminstrationListCourse: `Adminstration/ListCourse`, //not using now
  adminstrationCourseUpsert: `Adminstration/UpsertCourse`, //not using now
  adminstrationCourseDelete: `Adminstration/DeleteCourse`,
  adminstrationGetCourse: `Adminstration/GetCourse`, //not using now
  addAppSubscriberCourse: "AppSubscribers/CourseAssignMultiple",
  deleteAppSubscriberCourse: "AppSubscribers/CourseUnAssign",
  appSubscriberCompleteCourse: "AppSubscribers/CourseMarkComplete",
  appSubscribersMapHistory: "AppSubscribers/TriggeredHistory",
  getTriggerLocationActivity: "Dashboard/GetTriggerLocationActivity",
  memberTrendTriggerHistory: "Dashboard/MemberTrendTriggerHistory",
  advisorMemberTrendsHistoryGet: "Dashboard/AdvisorMemberTrendsHistoryGet",
  getContract: "student/GetContract",
  upsertContract: "student/UpsertContract",
  deleteContract: "student/DeleteContract",
  getContractTemplate: "ContractTemplate/GetContractTemplate",
  upsertContractTemplate: "ContractTemplate/UpsertContractTemplate",
  getStudentContractTemplate: "ContractTemplate/GetContractTemplate",
  createContractFromTemplate: "ContractTemplate/CreateContractFromTemplate",
  addFavoriteTemplate: "ContractTemplate/AddFavoriteTemplate",
  removeFavoriteTemplate: "ContractTemplate/RemoveFavoriteTemplate",
  closeContract: "Student/CloseContract",
  menuContractTemplate: "ContractTemplate/MenuContractTemplate",
  archiveContractTemplate: "ContractTemplate/ArchiveContractTemplate",
  addNoteType: `Adminstration/AddNotesForCenter`,
  getClientCounselor: `Adminstration/GetCounselorByCenter`,
  comboCenterConsent: `Medicaid/ComboCenterConsentForm`,
  centerProgramTypeGet: `AppSubscribers/CenterProgramTypeGet`,
  getCenterAdvisorDefaultCosts: `Counselor/CenterAdvisorDefaultCostsGet`,
  updateCenterAdvisorDefaultCost: `Counselor/CenterAdvisorDefaultCostUpdate`,
  reactivateCenter: "Adminstration/ReactivateCenter",
  adminstrationListCenter: `Adminstration/ListCenter`,
  adminstrationGetCenter: `Adminstration/GetCenter`, //not using now
  adminstrationCenterUpsert: `Adminstration/UpsertCenter`, //not using now
  adminstrationCenterDelete: `Adminstration/DeleteCenter`,
  changeAppRole: `Adminstration/UpdateUserRole`,
  updateStaffCenter: `User/UpdateCenter`,
  updateMobileAppRole: `Adminstration/UpdateStaffMobileAppRole`,
  twoStepAuth: `Adminstration/GetStaffPassCode`,
  validatePasscode: `Adminstration/ValidateStaffPassCode`,
  appInviteDashboard: `Adminstration/SendMobileAppInvite_Dashboard`,
  adminstrationStaffDelete: `Adminstration/DeleteStaff`,
  updateStaffEmail: `User/UpdateEmail`,
  updateStaffPassword: `User/UpdatePassword`,
  uploadStaffImage: "Adminstration/UploadStaffImage",
  getStaffShiftLog: `Adminstration/GetStaffShiftLog`,
  addStaffShiftLog: `Adminstration/UpsertStaffShiftLog`,
  staffReactivate: `Adminstration/ReActivateStaff`,
  isStaffProBio: "SmallGroups/CheckStaffProfessionalBio",
  updateStaffProBio: "Adminstration/UpsertStaffProfessionalBio",
  validateStaffPin: `Adminstration/ValidatePin`,
  getStaffPractitioner: `Medicaid/GetStaffPractitioner`,
  getSurveys: `Survey/SurveyStudentsGetAllPending`,
  getSurveyQuestionMultiple: `Survey/SurveyQuestionGetMultiple`,
  surveyAnswerUpsert: `Survey/SurveyAnswerUpsert`,
  surveyAssign: `Survey/AssignMultiple`,
  surveyGetToAssign: `Survey/GetToAssign`,
  getStudentSurveyDashboardData: `Student/Student_Survey_Dashboard_Get_Json`,
  surveyAnswersGet: `Survey/Survey_Answers_Get`,
  addAppSubscriberSurvey: "AppSubscribers/AddSurvey",
  deleteAppSubscriberSurvey: "AppSubscribers/MarkAsDiscard",
  surveyTaskMarkInComplete: `survey/MarkAsDiscard`,
  listSurveyQuestionCategory: `Medicaid/ListSurveyQuestionCategory`,
  listSurveyQuestionCategoryAssessment: `Medicaid/ListSurveyQuestionCategoryAssessment`,
  listSurveyQuestionAssessment: `Medicaid/ListSurveyQuestionAssessment`,
  getSurveyScore: `Medicaid/GetSurveyScorePartial`,
  loadAppSubSurveyDashboard: `AppSubscribers/LoadSurveyDashboard`,
  appSubSurveyAnsGet: `AppSubscribers/Survey_Answers_Get`,
  listConsentForm: `Medicaid/ListSurveyQuestionCategoryConsentForm`,
  listSurveyQuestionDischarge: `Medicaid/ListSurveyQuestionCategoryDischarge`,
  upsertAppointmentNoteIntervention: `Medicaid/UpsertAppointmentNoteIntervention`,
  markAsComplete: `Survey/MarkAsComplete`,
  getSurveysV2: "Survey/SurveyStudentsGetAllPendingV2",
  assignMultipleV2: "Survey/AssignMultipleV2",
  getAssessmentDetail: "Survey/AssessmentDetailListGetV2",
  //Extra API's NOT USING RIGHT NOW
  //YOUUAPI STARTED.....................................................................................................
  loadDefaultValues: `UserAuth/DefaultValuesGet`,
  userDataGet: `UserAuth/UserDataGet`,
  sideBarMenuGet: "GenericForm/SidebarMenuGet",
  allGet: "Client/ClientListGet",
  populationActivitiesClientListGet: "PopulationActivities/AllGet",
  clientListDropdown: "Lookup/ClientListGet",
  //DYNAMIC API'S
  getMultistepTab: "GenericForm/DynamicMultistepTabGet",
  getDynamicScreenTab: "GenericForm/DynamicScreenTabGet",
  getDynamicGridFilter: "GenericForm/GridFilterGet",
  dynamicGridColumnConfigurationGet: "GenericForm/GridManifestGet",
  upsertFormData: `GenericForm/UpsertFormData`,
  formDataGet: "GenericForm/FormDataGet",
  getFormFieldMetaData: `GenericForm/FormFieldMetaDataGet`,
  //COMBO API'S
  comboListGet: "Lookup/ComboListGet",
  serviceCodeListGet: "Lookup/ServiceCodeListGet",
  counselorListGet: "Lookup/CounselorListGet",
  facilityListGet: "Lookup/FacilityListGet",
  getOneCallTimeSlots: `Lookup/TimeSlotsGet`,
  //Profile API's
  getUserInfo: "ClientChart/ClientProfileGet",
  //TASKS API'S
  taskOptionGet: "ClientChart/TaskOptionGet",
  taskListGet: "ClientChart/TaskListGet",
  taskAssign: "ClientChart/TaskAssignOrUpdate",
  taskUnAssign: `ClientChart/TaskUnassign`,
  taskMarkComplete: `ClientChart/TaskMarkAsComplete`,
  //COURSE API'S
  assignedCourseListGet: "Course/AssignCourseListGet",
  courseAssign: "Course/CourseAssign",
  courseUnassign: "Course/CourseUnassign",
  courseMarkAsComplete: "Course/CourseMarkAsComplete",
  courseOptionGet: "Lookup/CourseOptionGet",
  courseListGet: "Course/CourseListGet",
  courseUpsert: "Course/CourseUpsert",
  courseGet: `Course/CourseGet`,
  courseStatusUpdate: "Course/CourseStatusUpdate",
  //CENTER API'S
  centerOptionGet: "Lookup/CenterOptionGet",
  centerUpsert: "Center/CenterUpsert",
  centerListGet: "Center/CenterListGet",
  centerGet: "Center/CenterGet",
  centerStatusUpdate: "Center/CenterStatusUpdate",
  //ALBUM API's
  getClientAlbum: "ClientChart/ClientAlbumGet",
  //POSTS API'S
  postListGet: "ClientChart/ClientPostGet",
  clientFriendAllGet: "ClientChart/ClientFriendAllGet",
  //JOURNAL API'S
  getAllJournalComments: "ClientChart/JournalCommentsAllGet",
  getAllJournal: "ClientChart/JournalAllGet",
  journalAdd: "ClientChart/JournalAdd",
  journalCommentUpsert: "ClientChart/JournalCommentUpsert",
  //TIMETIME API'S
  timeTravelListGet: `TimeTravel/TimeTravelListGet`,
  //TRIGGER API'S
  triggersListGet: "Trigger/TriggersListGet",
  //DASHBOARD API'S
  memberTrendsGet: "Dashboard/MemberTrendsGet",
  //ASSESSMENT API'S
  assessmentsMultipleAssign: "ClientChart/AssessmentsMultipleAssign",
  assessmentsListGet: "ClientChart/AssessmentsListGet",
  assessmentsMarkAsDiscard: "ClientChart/AssessmentsMarkAsDiscard",
  surveyQuestionCategoryOptionGet: "Lookup/SurveyQuestionCategoryOptionGet",
  //INTAKE API'S
  //Client/IntakeclientUpsert (new api below)
  intakeClientUpsert: "Client/ClientUpsert",
  intakeInsuranceUpsert: "Client/IntakeInsuranceUpsert",
  intakeInsurancePriorityOrderGet: "Client/IntakeInsurancePriorityOrderGet",
  intakeInsurancePriorityOrderUpdate:
    "Client/IntakeInsurancePriorityOrderUpdate",
  intakeMedicalHistoryUpsert: "Client/IntakeMedicalHistoryUpsert",
  intakeFormDataGet: "Client/IntakeFormDataGet",
  //Client/IntakeEnrollNow(new api below)
  intakeEnrollNow: "Client/ClientEnrollStatusUpdate",
  preIntakeGridReports: "Client/PreIntakeGridReports",
  preIntakeDefaultAssessmentGet: "Client/PreIntakeDefaultAssessmentGet",
  //TRENDS API'S
  trendsListGet: "ClientChart/TrendsListGet",
  //GROWTH PLAN ACTIONS
  growthPlanTemplateUpsert: "GrowthPlan/GrowthPlanTemplateUpsert",
  growthPlanTemplateMenuGet: "GrowthPlan/GrowthPlanTemplateMenuGet",
  growthPlanTemplateGet: "GrowthPlan/GrowthPlanTemplateGet",
  growthPlanTemplateFavoriteAdd: "GrowthPlan/GrowthPlanTemplateFavoriteAdd",
  growthPlanTemplateFavoriteDelete:
    "GrowthPlan/GrowthPlanTemplateFavoriteDelete",
  growthPlanTemplateDelete: "GrowthPlan/GrowthPlanTemplateDelete",
  clientGrowthPlanUpsert: "GrowthPlan/ClientGrowthPlanUpsert",
  clientGrowthPlanGet: "GrowthPlan/ClientGrowthPlanGet",
  clientGrowthPlanListGet: "GrowthPlan/ClientGrowthPlanListGet",
  clientGrowthPlanDelete: "GrowthPlan/ClientGrowthPlanDelete",
  clientGrowthPlanFromTemplateInsert:
    "GrowthPlan/ClientGrowthPlanFromTemplateInsert",
  clientGrowthPlanClose: "GrowthPlan/ClientGrowthPlanClose",
  //STAFF ACTIONS
  staffListGet: `Staff/StaffListGet`,
  staffGet: `Staff/StaffGet`,
  staffUpsert: `Staff/StaffUpsert`,
  staffMobileAppInviteSend: "SendToUser/StaffMobileAppInviteSend",
  //  staffPermission: `StaffPermission/Get`,
  staffPermission: "Staff/StaffPermissionListGet",
  //  updateStaffPermission: `StaffPermission/Update`,
  updateStaffPermission: "Staff/StaffPermissionUpdate",
  clinicalCenterGet: "Lookup/ClinicalCenterGet",
  //SMALLGROUP API'S
  getSmallGroupList: `SmallGroup/SmallGroupListGet`,
  smallGroupGet: `SmallGroup/SmallGroupGet`,
  smallGroupUpsert: `SmallGroup/SmallGroupUpsert`,
  smallGroupReferral: "SmallGroup/SmallGroupReferral",
  smallGroupUserListGet: "SmallGroup/SmallGroupUserListGet",
  smallGroupUserRemove: "SmallGroup/SmallGroupUserRemove",
  smallGroupLocationGet: "SmallGroup/SmallGroupLocationGet",
  smallGroupLocationUpdate: "SmallGroup/SmallGroupLocationUpdate",
  smallGroupImagesGet: "SmallGroup/SmallGroupImagesGet",
  upsertBroadcastTemplate: "SmallGroup/UpsertBroadcastTemplate",
  broadcastSend: "SendToUser/BroadcastSend",
  surveyAssignMultiple: "Survey/SurveyAssignMultiple",
  surveyAnswerUpsert: "Survey/SurveyAnswerUpsert",
  clientSurveyListGet: "Survey/ClientSurveyListGet",
  surveyQuestionCategoryListGet: "Survey/SurveyQuestionCategoryListGet",
  surveyQuestionHierarchialGet: "Survey/SurveyQuestionHierarchialGet",
  surveyMarkAsDiscard: "Survey/SurveyMarkASDiscard",
  surveyAssignedListGet: "Survey/SurveyAssignedListGet",
  //CLINICAL  GROUPS
  groupUpsert: "ClinicalGroup/GroupUpsert",
  groupGet: "ClinicalGroup/GroupGet",
  groupListGet: "ClinicalGroup/GroupListGet",
  clientGroupUpsert: "ClinicalGroup/ClientGroupUpsert",
  clientsNotParticipatingInGroup: "ClinicalGroup/NotParticipatingClientsGet",
  clientGroupGet: "ClinicalGroup/ClientGroupGet",
  groupAppointmentGroupGet: "ClinicalGroup/GroupAppointmentGroupGet",
  groupAppointmentGroupNotesGet: "ClinicalGroup/GroupAppointmentGroupNotesGet",
  groupServiceLinesGet: "ClinicalGroup/GroupServiceLinesGet",
  groupDeactivate: "ClinicalGroup/GroupDeactivate",
  couponListGet: "Coupon/CouponListGet",
  upsertCouponCode: "Coupon/CouponUpsert",
  deleteCouponCode: "Coupon/CouponDelete",
  getCouponCode: "Coupon/CouponGet",
  //Breathalyzer
  listBreathalyzerSchedule: `Breathlyzer/ScheduleListGet`,
  getBreathalyzerTestResult: `Breathlyzer/ScheduleTestResultGet`,
  getBreathalyzerTestUpcoming: `Breathlyzer/ScheduleUpcomingTestListGet`,
  upsertBreathalyzerSchedule: `Breathlyzer/BreathalyzerScheduleUpsert`,
  breathalyzerPushATest: `Breathlyzer/BreathalyzerAdhocTest`,
  getBreathalyzerSchedule: `Breathlyzer/BreathalyzerScheduleGet`,
  breathalyzerEnable: `AppSubscribers/BreathalyzerEnable`,
  breathalyzerTestUpcomingToggle: `Breathlyzer/ScheduleUpcomingTestCancel`,
  getStudentBreathalyzerTestResult: `Student/GetBreathalyzerScheduleTestResults`,
  //Miscellaneous
  miscellaneousTaskGet: "Miscellaneous/TaskGet",
  miscellaneousTaskListGet: "Miscellaneous/TaskListGet",
  miscellaneousTaskDelete: "Miscellaneous/TaskDelete",
  miscellaneousTaskUpsert: "Miscellaneous/TaskUpsert",
  miscellaneousNoteTypeInsert: "Miscellaneous/NoteTypeInsert",
  miscellaneousComboNoteTypesListGet: "Miscellaneous/ComboNoteTypesListGet",
  miscellaneousNoteTypeDelete: "Miscellaneous/NoteTypeDelete",
  miscellaneousNoteTypesListGet: "Miscellaneous/NoteTypesListGet",
  miscellaneousNoteTypeGet: "Miscellaneous/NoteTypeGet",
  //Appointments
  practitionerOptionsGet: "lookup/PractitionerOptionsGet",
  facilityOptionsGet: "lookup/FacilityOptionsGet",
  appointmentListGet: "Appointment/AppointmentListGet",
  appointmentUpsert: "Appointment/AppointmentUpsert",
  groupAppointmentUpsert: "Appointment/GroupAppointmentUpsert",
  blockAppointmentUpsert: "Appointment/BlockAppointmentUpsert",
  staffPinValidate: "Appointment/StaffPinValidate",
  appointmentNoteGet: "Appointment/AppointmentNoteGet",
  appointmentNoteUpsert: "Appointment/AppointmentNoteUpsert",
  appointmentNoteListGet: "Appointment/AppointmentNoteListGet",
  appointmentNoteAddendumUpsert: "Appointment/AppointmentNoteAddendumUpsert",
  groupAppointmentNoteGet: "Appointment/GroupAppointmentNoteGet",
  groupAppointmentNoteUpsert: "Appointment/GroupAppointmentNoteUpsert",
  comboPractitioner: "Appointment/ComboPractitioner",
  comboFacility: "Appointment/ComboFacility",
  groupOptionGet: "Appointment/GroupOptionGet",
  clientGroupOptionsGet: "Lookup/ClientGroupOptionsGet",
};
