import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import {
  getFormFieldMetaData,
  upsertBreathalyzerSchedule,
  resetUpsertBreathalyzerSchedule,
  getOneCallTimeSlots,
  clientListDropdown,
  getBreathalyzerSchedule,
  resetGetBreathalyzerSchedule,
} from "../../../../redux/actions";

import {
  ReduxInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxDateInput,
  ReduxTimeInput,
  ReduxDollarInput,
  ReduxMultiSelect,
  ReduxToggle,
  ReduxCheckbox,
  ReduxAutoComplete,
  CheckboxGroup,
} from "../../../components/redux-component/index";
import Validator from "../../../Validator";
import { getClientId } from "../../../util/getParam";

const weekDays = [
  { label: "Mo", value: 2, text: "Monday" },
  { label: "Tu", value: 3, text: "Tuesday" },
  { label: "We", value: 4, text: "Wednesday" },
  { label: "Th", value: 5, text: "Thursday" },
  { label: "Fr", value: 6, text: "Friday" },
  { label: "Sa", value: 7, text: "Saturday" },
  { label: "Su", value: 1, text: "Sunday" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  // container: {
  //   display: "flex",
  //   flexWrap: "wrap",
  // },
  // input: {
  //   margin: theme.spacing(1),
  // },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

let BForm = (props) => {
  const suhbeader = useSubheader();
  const [TimeSlotBlock, setTimeSlotBlock] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  suhbeader.setTitle(
    props.BreathalyzerId ? "Edit Breathlyzer Test" : "New Breathlyzer Test"
  );

  useEffect(() => {
    // dispatch(
    //   getFormFieldMetaData({
    //     DynamicForm: [
    //       {
    //         FormName: "Breathlyzer",
    //       },
    //     ],
    //   })
    // );
    dispatch(getOneCallTimeSlots({}));
    dispatch(clientListDropdown({}));
    // props.reset();
    return () => {
      dispatch(resetGetBreathalyzerSchedule());
    };
  }, []);
  const getTimeSlot = () => {
    let content = [];
    for (let i = 0; i < testsPerDay; i++) {
      content.push(
        <div className="col-md-4 col-lg-4 mt-2" key={uuid()}>
          <div class="form-group">
            <Field
              name={`option${i}`}
              component={ReduxSelect}
              options={timeSlots}
              fieldName="time"
              labelText={"Test Time*"}
              isReturnText={false}
              // validate={Validator.required}
            />
          </div>
        </div>
      );
    }
    return content;
  };
  const selector = formValueSelector("breathlyzerForm");
  const {
    clientDropdown,
    getGenericFormFields,
    isWeek,
    testsPerDay,
    timeSlots,
    randomValue,
  } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data },
      get_one_call_timeSlots: { data: timeSlotsData },
      client_list_dropdown: { data: clientDropdownData },
    } = state;
    return {
      clientDropdown: clientDropdownData?.Data?.Clients,
      genericFormData: data?.Data?.FormFieldMetaData || [],
      isWeek: selector(state, "RepeatFrequency"),
      testsPerDay: selector(state, "TestsPerDay"),
      randomValue: selector(state, "IsRandom"),
      timeSlots: timeSlotsData?.Data?.TimeSlots?.[0]?.TimeSlots || [],
    };
  }, shallowEqual);
  useEffect(() => {
    if (testsPerDay && testsPerDay.length > 0) {
      let data = getTimeSlot();
      setTimeSlotBlock(data);
    }
  }, [testsPerDay]);

  const { handleSubmit, pristine, reset, submitting, onSubmit } = props;
  return (
    <>
      <Card>
        {/* <CardHeader title={"Add New Course"}></CardHeader> */}
        <CardBody>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="row pb-5 mt-5">
              <div className="col-sm-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name="currentUser"
                    component={ReduxSelect}
                    options={clientDropdown}
                    fieldName="Current User"
                    labelText={"Current User*"}
                    // validate={Validator.required}
                    isReturnText={false}
                    // isMulti={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name="StartDate"
                    component={ReduxDateInput}
                    fieldName="Start Date"
                    labelText={"Start Date*"}
                    validate={Validator.required}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name="EndDate"
                    component={ReduxDateInput}
                    fieldName="End Date"
                    labelText={"End Date*"}
                    validate={Validator.required}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name="RepeatFrequency"
                    component={ReduxSelect}
                    // options={["Guitar", "Cycling", "Hiking"]}
                    options={[
                      { Value: "Weekly", Text: "Weekly" },
                      { Value: "Daily", Text: "Daily" },
                    ]}
                    fieldName="Repeat Frequency"
                    labelText={"Repeat Frequency*"}
                    // validate={Validator.required}
                    isReturnText={false}
                    // multiple={true}
                  />
                </div>
              </div>
              {isWeek == "Weekly" && (
                <div class="col-lg-8">
                  <div
                    style={{
                      flexFlow: "wrap",
                      display: "flex",
                    }}
                    className="repeat-weekly"
                  >
                    <div class="form-group">
                      <Field
                        name="DaysOfWeek"
                        component={CheckboxGroup}
                        col={3}
                        widthClassName={"w-75"}
                        options={weekDays}
                        title={true}
                        fieldName="Repeat On"
                        label="Repeat On"
                        labelText="Repeat On"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="col-sm-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name="IsRandom"
                    component={ReduxSelect}
                    // options={["Guitar", "Cycling", "Hiking"]}
                    options={[
                      { Value: true, Text: "Yes" },
                      { Value: false, Text: "No" },
                    ]}
                    fieldName="Choose Time Randomly"
                    labelText={"Choose Time Randomly*"}
                    // validate={Validator.required}
                    isReturnText={false}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div class="form-group">
                  <Field
                    id={"TestsPerDay"}
                    name={`TestsPerDay`}
                    component={ReduxInput}
                    fieldName="Tests Per Dayr"
                    type="number"
                    labelText="Tests Per Day"
                    maxLength={10}
                    // onChange={(event) => {
                    //   setTestsPerDay(event.target.value);
                    // }}
                  />
                </div>
              </div>
              {randomValue ? (
                <>
                  <div className="col-lg-4 col-sm-6">
                    <div class="form-group">
                      <Field
                        name={`StartTimeSlotId`}
                        component={ReduxSelect}
                        options={timeSlots}
                        fieldName="Start Time"
                        labelText={"Start Time*"}
                        // validate={Validator.required}
                        isReturnText={false}
                        validate={Validator.required}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <div class="form-group">
                      <Field
                        name={`EndTimeSlotId`}
                        component={ReduxSelect}
                        options={timeSlots}
                        fieldName="End Time"
                        labelText={"End Time*"}
                        // validate={Validator.required}
                        isReturnText={false}
                        validate={Validator.required}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <div className="row">
                    {TimeSlotBlock && TimeSlotBlock.map((item) => item)}
                  </div>
                </div>
              )}
            </div>

            <div className="text-right">
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              &nbsp;
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
};
const ReduxCForm = reduxForm({
  form: "breathlyzerForm",
  enableReinitialize: true,
})(BForm);

const BreathlyzerTestForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [BreathalyzerData, setBreathalyzerData] = useState();
  useEffect(() => {
    // dispatch(
    //   getFormFieldMetaData({
    //     DynamicForm: [
    //       {
    //         FormName: "Breathlyzer",
    //       },
    //     ],
    //   })
    // );
    if (getClientId()) {
      dispatch(
        getBreathalyzerSchedule({
          BreathalyzerSchedule: [
            {
              ClientId: getClientId(),
            },
          ],
        })
      );
    }

    // return () => {};
  }, []);
  const { isInserted, formData } = useSelector(
    ({ upsert_breathalyzer_schedule, get_breathalyzer_schedule: { data } }) => {
      return {
        isInserted: upsert_breathalyzer_schedule?.data?.ResponseCode === 1,
        formData: data?.Data?.Schedules?.[0],
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (isInserted) {
      dispatch(resetUpsertBreathalyzerSchedule());
      reset("breathlyzerForm");
      history.push("/breathlyzer/list");
    }
  }, [isInserted]);
  useEffect(() => {
    if (formData) {
      let timeSlot = {};
      if (formData.TimeSlots) {
        formData.TimeSlots.split(",").map((item, index) => {
          timeSlot = {
            ...timeSlot,
            [`option${index}`]: parseInt(item),
          };
        });
      }
      let data = {
        BreathalyzerScheduleGuid: formData.BreathalyzerScheduleGuid,
        currentUser: formData.ClientId,
        StartDate: formData.StartDate,
        EndDate: formData.EndDate,
        RepeatFrequency: formData.RepeatFrequency,
        DaysOfWeek: formData.DaysOfWeek,
        IsRandom: formData.IsRandom,
        TestsPerDay: formData.TestsPerDay,
        // timeSlotSelectedValues: formData.TimeSlots,
        StartTimeSlotId: formData.StartTimeSlotId,
        EndTimeSlotId: formData.EndTimeSlotId,
        ...timeSlot,
      };
      setBreathalyzerData(data);
    }
  }, [formData]);

  const onSubmit = (values) => {
    const {
      currentUser,
      TestsPerDay,
      StartTimeSlotId,
      StartDate,
      RepeatFrequency,
      IsRandom,
      EndTimeSlotId,
      EndDate,
      DaysOfWeek,
      BreathalyzerScheduleGuid,
    } = values;

    let timeSlotSelectedValues = [];
    if (!IsRandom) {
      for (let i = 0; i < parseInt(TestsPerDay); i++) {
        timeSlotSelectedValues.push(values[`option${i}`]);
      }
    }

    const data = {
      BreathalyzerSchedule: [
        {
          BreathalyzerScheduleGuid: BreathalyzerScheduleGuid,
          ClientId: currentUser,
          StartDate: StartDate,
          EndDate: EndDate,
          RepeatFrequency: RepeatFrequency,
          DaysOfWeek: DaysOfWeek ? DaysOfWeek.toString() : null,
          IsRandom: IsRandom,
          TestsPerDay: TestsPerDay && parseInt(TestsPerDay),
          TimeSlots:
            timeSlotSelectedValues.length > 0
              ? timeSlotSelectedValues.toString()
              : null,
          StartTimeSlotId: StartTimeSlotId,
          EndTimeSlotId: EndTimeSlotId,
        },
      ],
    };

    dispatch(upsertBreathalyzerSchedule(data));
  };
  return (
    <ReduxCForm
      onSubmit={onSubmit}
      initialValues={BreathalyzerData}
      BreathalyzerId={getClientId()}
    />
  );
};
export default BreathlyzerTestForm;
