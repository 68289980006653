import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CenteredTabs from "../../components/custom-component/custom-tabs";
import {
  FavoritesTemplates,
  MyTemplates,
  PublicTemplates,
} from "../../components/custom-screens/growthPlan";

// import { useSubheader } from "../../../../_metronic/layout";
// import GenericTable from "../../generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  courseListGet,
  getDynamicGridFilter,
  resetCourseStatusUpdate,
  resetCourseGet,
} from "../../../redux/actions";

const GrowthPlan = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Labels = {
    MyTemplates: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <MyTemplates tabId="MyTemplates" />
        </>
      );
    },
    PublicTemplates: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <PublicTemplates tabId="PublicTemplates" />
        </>
      );
    },
    Favorites: () => {
      return (
        <>
          {/* <span>Coming Soon</span> */}
          <FavoritesTemplates tabId="FavoritesTemplates" />
        </>
      );
    },
  };
  const TabData = [
    {
      Id: "MyTemplates",
      TabName: "My Templates",
      Component: "MyTemplates",
    },
    {
      Id: "PublicTemplates",
      TabName: "PublicTemplates",
      Component: "PublicTemplates",
    },
    {
      Id: "Favorites",
      TabName: "Favorites",
      Component: "Favorites",
    },
  ];
  return (
    <div>
      <div className="custom-tabs">
        <CenteredTabs
          tabsData={TabData}
          labels={Labels}
          baseTabUrl="/growth-plan?tab="
        />
      </div>
    </div>
  );
};
export default GrowthPlan;
