import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";

export function* getAllCallSession(action) {
  try {
    const { payload } = action;
    const response = yield API.getAllCallSession(payload);
    yield put({
      type: ActionTypes.GET_ALL_CALL_SESSION_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ALL_CALL_SESSION_FAILURE,
    });
  }
}
export function* addCallSession(action) {
  try {
    const { payload } = action;
    const response = yield API.addCallSession(payload);
    yield put({
      type: ActionTypes.ADD_CALL_SESSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_CALL_SESSION_FAILURE,
    });
  }
}
export function* getAllVacation(action) {
  try {
    const { payload } = action;
    const response = yield API.getAllVacation(payload);
    yield put({
      type: ActionTypes.GET_ALL_VACATION_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ALL_VACATION_FAILURE,
    });
  }
}
export function* addVacationDays(action) {
  try {
    const { payload } = action;
    const response = yield API.addVacationDays(payload);
    yield put({
      type: ActionTypes.ADD_VACATION_DAYS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_VACATION_DAYS_FAILURE,
    });
  }
}

export function* getOneCallSession(action) {
  try {
    const { payload } = action;
    const response = yield API.getOneCallSession(payload);
    yield put({
      type: ActionTypes.GET_ONE_CALL_SESSION_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ONE_CALL_SESSION_FAILURE,
    });
  }
}
export function* inviteOneCallSession(action) {
  try {
    const { payload } = action;
    const response = yield API.inviteOneCallSession(payload);
    yield put({
      type: ActionTypes.INVITE_ONE_CALL_SESSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.INVITE_ONE_CALL_SESSION_FAILURE,
    });
  }
}
export function* cancelOneCallSession(action) {
  try {
    const { payload } = action;
    const response = yield API.cancelOneCallSession(payload);
    yield put({
      type: ActionTypes.CANCEL_ONE_CALL_SESSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CANCEL_ONE_CALL_SESSION_FAILURE,
    });
  }
}
export function* editGroupCallSession(action) {
  try {
    const { payload } = action;
    const response = yield API.editGroupCallSession(payload);
    yield put({
      type: ActionTypes.EDIT_GROUP_CALL_SESSION_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.EDIT_GROUP_CALL_SESSION_FAILURE,
    });
  }
}
export function* getCallSessionVideo(action) {
  try {
    const { payload } = action;
    const response = yield API.getCallSessionVideo(payload);
    yield put({
      type: ActionTypes.GET_CALL_SESSION_VIDEO_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CALL_SESSION_VIDEO_FAILURE,
    });
  }
}
export function* getCallSessionVideoUrl(action) {
  try {
    const { payload } = action;
    const response = yield API.getCallSessionVideoUrl(payload);
    yield put({
      type: ActionTypes.GET_CALL_SESSION_VIDEO_URL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CALL_SESSION_VIDEO_URL_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_ALL_CALL_SESSION_REQUEST, getAllCallSession),
    takeLatest(ActionTypes.ADD_CALL_SESSION_REQUEST, addCallSession),
    takeLatest(ActionTypes.GET_ALL_VACATION_REQUEST, getAllVacation),
    takeLatest(ActionTypes.ADD_VACATION_DAYS_REQUEST, addVacationDays),
    takeLatest(ActionTypes.GET_ONE_CALL_SESSION_REQUEST, getOneCallSession),
    takeLatest(
      ActionTypes.INVITE_ONE_CALL_SESSION_REQUEST,
      inviteOneCallSession
    ),
    takeLatest(
      ActionTypes.CANCEL_ONE_CALL_SESSION_REQUEST,
      cancelOneCallSession
    ),
    takeLatest(
      ActionTypes.EDIT_GROUP_CALL_SESSION_REQUEST,
      editGroupCallSession
    ),
    takeLatest(ActionTypes.GET_CALL_SESSION_VIDEO_REQUEST, getCallSessionVideo),
    takeLatest(
      ActionTypes.GET_CALL_SESSION_VIDEO_URL_REQUEST,
      getCallSessionVideoUrl
    ),
  ]);
}
