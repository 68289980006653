import React from "react";
import { useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import _CancelTestNow from "../../../images/cancel.svg";
import _UndoTestNow from "../../../images/icons/undo.svg";
import { breathalyzerTestUpcomingToggle } from "../../../../redux/actions";
export const CancelTest = ({ RowData }) => {
  const dispatch = useDispatch();

  return (
    <div className="">
      {RowData.IsCanceled ? (
        <OverlayTrigger
          overlay={<Tooltip id="push-test-tooltip">Cancel this test</Tooltip>}
        >
          <a
            className="btn btn-icon btn-hover-light-primary btn-sm"
            onClick={() => {
              dispatch(
                breathalyzerTestUpcomingToggle({
                  Breathalyzer: [
                    {
                      ClientId: RowData.ClientId,
                      BreathalyzerTestInstanceId:
                        RowData.BreathalyzerTestInstanceId,
                      IsCancel: !RowData.IsCanceled,
                      IsCancelUndo: !!RowData.IsCanceled,
                    },
                  ],
                })
              );
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl(_CancelTestNow)} />
            </span>
          </a>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          overlay={<Tooltip id="push-test-tooltip">Undo this test now</Tooltip>}
        >
          <a
            className="btn btn-icon btn-hover-light-primary btn-sm"
            onClick={() => {
              dispatch(
                breathalyzerTestUpcomingToggle({
                  Breathalyzer: [
                    {
                      ClientId: RowData.ClientId,
                      BreathalyzerTestInstanceId:
                        RowData.BreathalyzerTestInstanceId,
                      IsCancel: !RowData.IsCanceled,
                      IsCancelUndo: !!RowData.IsCanceled,
                    },
                  ],
                })
              );
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl(_UndoTestNow)} />
            </span>
          </a>
        </OverlayTrigger>
      )}
    </div>
  );
};
