import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../../../_metronic/_partials/dropdowns";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";

import GenericTable from "../../generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  courseListGet,
  getDynamicGridFilter,
  resetCourseStatusUpdate,
  resetCourseGet,
} from "../../../../redux/actions";
import { MemberTrendsFormatter } from "../../column-formatters";
// import { Chat } from "../../custom-component/Chating";
// import { TwilioCalling } from "../../custom-component/TwilioCalling";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";
import { CourseDrawer } from "./course-drawer";
const { SearchBar, ClearSearchButton } = Search;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const Course = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, moreItems) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };

  useEffect(() => {
    
    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "Course",
          },
        ],
      })
    );
    dispatch(
      getDynamicGridFilter({
        ComponentGrid: [
          {
            Name: "Course",
          },
        ],
      })
    );

    dispatch(courseListGet({ searchStr: null }));
  }, []);
  const onFilter = (filterValue, toggleFilterDrawer) => {
    let data = {
      ...filterValue,
      LadderAssociation: parseInt(filterValue.LadderAssociation),
      CourseType: filterValue.CourseType,
      CourseTopic: filterValue.CourseTopic,
    };
    dispatch(courseListGet(data));
    toggleFilterDrawer("right", false)("no needs");
  };


  const { dynamicGridColumnData, isFetching, courseListData,isCourseStatusChange } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data, isFetching, error },
      course_list_get: { data: ListData },
      course_status_update,
    }) => {
      return {
        dynamicGridColumnData: data,
        courseListData: ListData?.Data?.CourseList,
        isCourseStatusChange: course_status_update?.data?.ResponseCode === 1,
      };
    },
    shallowEqual
  );


    useEffect(() => {
      if (isCourseStatusChange) {
        toggleDrawer("right", false)("no needs");
        dispatch(courseListGet({ searchStr: null }));
        dispatch(resetCourseGet({}));
        dispatch(resetCourseStatusUpdate());
      }
    }, [isCourseStatusChange]);
  
  const {
    PageSize,
    IsRowClick,
    IsFilter,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = courseListData || [];

  // const { Trend } = advisorMemberTrendsData || {};
  const enableSorting = true;

  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Course List");

  return (
    <>
      <div>
        <div className="member-trend">
          {Columns && DataSource && (
            <GenericTable
              // tableTitle="Member Trends"
              entities={DataSource || []}
              columns={Columns || []}
              isCardHeader={false}
              pagination={true}
              isLoading={isFetching}
              bordered={false}
              // selectRow={selectRow}
              // rowEvents={rowEvents}
              tableOptions={{
                PageSize,
                IsRowClick,
                IsFilter,
                IsExcelExport,
                IsColumnSelectionEnable,
                TableButtons,
                FileName: "Course",
              }}
              toggleDrawer={toggleDrawer}
              onFilter={onFilter}
            />
          )}
        </div>
        {state.right && (
          <Drawer
            anchor="right"
            open={state.right}
            onClose={toggleDrawer("right", false, {})}
          >
            <div role="presentation" style={{ width: "800px" }}>
              <CourseDrawer
                toggleDrawer={toggleDrawer}
                CourseId={moreItem?.CourseId}
                moreItem
              />
            </div>
          </Drawer>
        )}
      </div>
    </>
  );
};
