import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* badgeUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.badgeUpdate(payload);
    yield put({
      type: ActionTypes.BADGE_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BADGE_UPDATE_FAILURE,
    });
  }
}
export function* badgeComboGet(action) {
  try {
    const { payload } = action;
    const response = yield API.badgeComboGet(payload);
    yield put({
      type: ActionTypes.BADGE_COMBO_GET_SUCCESS,
      payload: JSON.parse(response.data.result)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BADGE_COMBO_GET_FAILURE,
    });
  }
}
export default function* root() {
  yield all([takeLatest(ActionTypes.BADGE_COMBO_GET_REQUEST, badgeComboGet)]);
  yield all([takeLatest(ActionTypes.BADGE_UPDATE_REQUEST, badgeUpdate)]);
}
