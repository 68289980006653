import React, { useEffect } from "react";
import axios from "axios";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import { ProductsFilter } from "./products-filter/ProductsFilter";
import { ProductsTable } from "./products/products-table/ProductsTable";
// import { ProductsGrouping } from "./products-grouping/ProductsGrouping";
// import { useProductsUIContext } from "./ProductsUIContext";
import { login, loadDefaultValues } from "../../../redux/actions";
let Tabledata = (props) => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(
  //     login({
  //       imageSrc: "",
  //       username: "satish+99@ceanalytics.com",
  //       password: "Abcd123456!",
  //       grant_type: "password",
  //       scope: "offline_access",
  //       isFacialAuthentication: 0,
  //     })
  //   );
  // }, []);

  // useEffect(() => {
  //   if (props.isSuccess) {
  //     var token = {
  //       expires_in: props.expires_in,
  //       access_token: props.access_token,
  //       refresh_token: props.refresh_token,
  //       created: Date.now(),
  //       date_exp: Date.now() + props.expires_in * 1000,
  //     };
  //     localStorage.setItem("dr:token", JSON.stringify(token));
  //     dispatch(loadDefaultValues({}));
  //   }
  // }, [props.isSuccess]);

  return (
    <Card>
      <CardHeader title="Demo Table">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            className="btn btn-primary"
            // onClick={productsUIProps.newProductButtonClick}
          >
            New Product
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <ProductsFilter /> */}
        {/* {productsUIProps.ids.length > 0 && (
          <>
            <ProductsGrouping />
          </>
        )} */}
        <ProductsTable />
      </CardBody>
    </Card>
  );
};
// const mapStateToProps = (state, props) => {
//   const { login } = state;
//   const { data, isFetching, error } = login;

//   var isSucess = false;
//   var errorMessage = "";
//   var access_token = "";
//   var expires_in = "";
//   var refresh_token = "";

//   if (data) {
//     if (data.access_token) {
//       isSucess = true;
//       access_token = data.access_token;
//       expires_in = data.expires_in;
//       refresh_token = data.refresh_token;
//     } else {
//       errorMessage = "Login Failed";
//     }
//   } else if (error) {
//     errorMessage = error.error_description;
//   }

//   return {
//     isSuccess: isSucess,
//     access_token: access_token,
//     expires_in: expires_in,
//     refresh_token: refresh_token,
//   };
// };
export default Tabledata = withRouter(connect()(Tabledata));
