import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Button from "@material-ui/core/Button";
import AppointmentModal from "./appointmentsForm";
// import { newClientFormFields } from "./tempData";
import { appointmentListGet } from "../../../redux/actions";
const AppointmentModule = (props) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [currentAppointmentType, setCurrentAppointmentType] = useState(
    "NewClient"
  );

  let modalClose = () => setModalShow(false);

  let getCurrentAppointmentType = (value) => {
    setCurrentAppointmentType(value);
  };

  const FORM_NAME = {
    NewClient: "AppointmentNewClient",
    ExistingClient: "AppointmentExistingClient",
    GroupAppointment: "GroupAppointment",
    BlockAppointment: "AppointmentBlockAppointment",
  };

  const selector = formValueSelector(FORM_NAME[currentAppointmentType]);

  const CONDITION_TYPE = {
    isRecurringTypeDependentField: () => {
      return recurringTypeValue != "None";
    },
    isRecurringTypeMonthlyDependentField: () => {
      return recurringTypeValue == "Monthly";
    },
    isRecurringTypeWeeklyDependentField: () => {
      return recurringTypeValue == "Weekly";
    },
  };

  const {
    getGenericFormFields,
    recurringTypeValue,
    appointmentList,
    isAppointmentUpsertSuccess,
    isGroupAppointmentUpsertSuccess,
    isBlockAppointmentUpsertSuccess,
  } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data },
      appointment_list_get: { data: appointmentListData },
      appointment_upsert: { data: appointmentUpsertData },
      group_appointment_upsert: { data: groupAppointmentUpsertData },
      block_appointment_upsert: { data: blockAppointmentUpsertData },
    } = state;
    return {
      getGenericFormFields: data?.Data?.FormFieldMetaData || [],
      recurringTypeValue: selector(state, "RecurringType"),
      appointmentList:
        appointmentListData?.Data?.Appointments?.[0]?.Appointment,
      isAppointmentUpsertSuccess: appointmentUpsertData?.ResponseCode == 1,
      isGroupAppointmentUpsertSuccess:
        groupAppointmentUpsertData?.ResponseCode == 1,
      isBlockAppointmentUpsertSuccess:
        blockAppointmentUpsertData?.ResponseCode == 1,
    };
  }, shallowEqual);

  const getData = () => {
    dispatch(appointmentListGet({}));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (getGenericFormFields.length > 0) {
      let conditionalConversion =
        getGenericFormFields &&
        getGenericFormFields.map((item, index) => {
          if (
            item.condition == "isRecurringTypeDependentField" ||
            item.condition == "isRecurringTypeMonthlyDependentField" ||
            item.condition == "isRecurringTypeWeeklyDependentField"
          ) {
            return {
              ...item,
              condition: CONDITION_TYPE[item.condition],
            };
          } else {
            return item;
          }
        });
      setFormFields(conditionalConversion);
    }
  }, [getGenericFormFields, recurringTypeValue]);

  useEffect(() => {
    if (
      isAppointmentUpsertSuccess == 1 ||
      isGroupAppointmentUpsertSuccess == 1 ||
      isBlockAppointmentUpsertSuccess == 1
    ) {
      setModalShow(false);
      getData();
    }
  }, [
    isAppointmentUpsertSuccess,
    isGroupAppointmentUpsertSuccess,
    isBlockAppointmentUpsertSuccess,
  ]);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="mt-4 mb-4">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setModalShow(true);
              }}
            >
              Add New Appointment
            </Button>
          </div>
        </div>
        <div className="col-md-12">
          <div className="p-4 card card-custom">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              headerToolbar={{
                left: "timeGridDay,timeGridWeek,dayGridMonth",
                center: "title",
                right: "today,prev,next",
                // center: 'title',
                // right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              events={appointmentList}
              buttonText={{
                today: "Today",
                month: "Month",
                week: "Week",
                day: "Day",
                list: "List",
              }}
              editable={false}
              // disableDragging={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              }}
            />{" "}
            {modalShow && (
              <AppointmentModal
                show={modalShow}
                onHide={modalClose}
                // formFields={getGenericFormFields}
                formFields={formFields}
                getCurrentAppointmentType={getCurrentAppointmentType}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AppointmentModule;
