import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* centerUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.centerUpsert(payload);
    yield put({
      type: ActionTypes.CENTER_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_UPSERT_FAILURE,
    });
  }
}

export function* centerListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerListGet(payload);
    yield put({
      type: ActionTypes.CENTER_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_LIST_GET_FAILURE,
    });
  }
}

export function* centerGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerGet(payload);
    yield put({
      type: ActionTypes.CENTER_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_GET_FAILURE,
    });
  }
}

export function* centerStatusUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.centerStatusUpdate(payload);
    yield put({
      type: ActionTypes.CENTER_STATUS_UPDATE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_STATUS_UPDATE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.CENTER_UPSERT_REQUEST, centerUpsert),
    takeLatest(ActionTypes.CENTER_LIST_GET_REQUEST, centerListGet),
    takeLatest(ActionTypes.CENTER_GET_REQUEST, centerGet),
    takeLatest(ActionTypes.CENTER_STATUS_UPDATE_REQUEST, centerStatusUpdate),
  ]);
}
