import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import _RedCross from "../../images/icons/redCross.svg";
import _BlueCheck from "../../images/icons/blueCheck.svg";
import _Edit from "../../images/icons/edit.svg";
import _RedCrossIcon from "../../images/redCrossIcon.svg";
import _BlueCheckIcon from "../../images/blueCheckIcon.svg";
import _EditIcon from "../../images/editIcon.svg";
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
export const CourseCard = (props) => {
   const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { data, handleEdit, handleDelete, handleComplete, key } = props;
  const {
    CourseCenterStudentId,
    CourseId,
    CenterId,
    StudentId,
    EnrollDate,
    DueDate,
    DueDateForSort,
    DueDateString,
    CompletedDate,
    UnassignDate,
    DueDateFontColor,
    CourseName,
    CourseDescription,
    CourseStatus,
    ListPriority,
    Score,
    CourseCategory,
  } = data || {};
  const courseStatus = {
    "-1": {
      color: "markAsUnassign",
      hideButtons: true,
      date: UnassignDate,
      name: "Unassigned On",
    },
    "0": {
      color: "markAsDone",
      hideButtons: false,
      date: DueDateString,
      dateFontColor: DueDateFontColor,
      name: "To be Finished",
    },
    "1": {
      color: "markAsInComplete",
      hideButtons: true,
      date: CompletedDate,
      name: "Completed On",
    },
  };

   const handleClickOpen=()=> {
    setOpenDialog(true);
  }

  const handleClose=()=> {
    setOpenDialog(false);
  }


  return (
    <div className="course-card" key={uuid()}>
      <div
        className={`row mt-5 mb-5 pt-5 pb-5 ${courseStatus[CourseStatus].color}`}
        // className={
        //   CompletedDate
        //     ? "row mt-5 mb-5 pt-5 pb-5 markAsDone"
        //     : UnassignDate
        //     ? "row mt-5 mb-5 pt-5 pb-5 markAsUnassign"
        //     : "row mt-5 mb-5 pt-5 pb-5 markAsInComplete"
        // }
      >
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12 date">{CourseName}</div>
            <div
              className="col-md-12 date mt-2"
              style={{
                color: "#7F8188",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {CourseCategory}
            </div>
            <div
              className="col-md-12 d-flex align-items-center pt-3"
              style={{ justifyContent: "space-between" }}
            >
              <div>
                <span className="time-label">Enrolled On : </span>
                <span className="value">{EnrollDate}</span>
              </div>
              <div>
                <span className="time-label">
                  {courseStatus[CourseStatus].name} :
                </span>
                <span className="value">
                  {" "}
                  {courseStatus[CourseStatus].date}
                </span>
              </div>
              {CompletedDate && (
                <div>
                  <span className="time-label">Score : </span>
                  <span className="value">{Score}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
          {/* {!courseStatus[CourseStatus].hideButtons && handleEdit && (
            <Tooltip title="Edit Due Date" aria-label="Edit Due Date">
              <Fab
                color="secondary"
                aria-label="Add"
                variant="extended"
                size="small"
                className={classes.fab}
                onClick={() => {
                  handleEdit(data);
                }}
              >
                <SVG src={toAbsoluteUrl(_Edit)} />
              </Fab>
            </Tooltip>
          )} */}
          {!courseStatus[CourseStatus].hideButtons && handleComplete && (
            <Tooltip title="Mark As Complete" aria-label="Mark As Complete">
              <Fab
                color="secondary"
                aria-label="markAsComplete"
                variant="extended"
                size="small"
                className={classes.fab}
                onClick={() => {
                  handleComplete(data);
                }}
              >
                <SVG src={toAbsoluteUrl(_BlueCheck)} />
              </Fab>
            </Tooltip>
          )}
          {!courseStatus[CourseStatus].hideButtons && handleDelete && (
            <Tooltip title="Mark As Incomplete" aria-label="Mark As Incomplete">
              <Fab
                color="secondary"
                aria-label="markAsIncomplete"
                variant="extended"
                size="small"
                className={classes.fab}
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                <SVG src={toAbsoluteUrl(_RedCross)} />
              </Fab>
            </Tooltip>
          )}

          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Mark as Incomplete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to mark this course as incomplete?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button
                onClick={() => {
                  handleDelete(data);
                  handleClose();
                }}
                color="primary"
                autoFocus
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
