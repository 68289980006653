import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from "redux-form";
import {
  getFormFieldMetaData,
  intakeFormDataGet,
  resetIntakeFormDataGet,
  intakeClientUpsert,
  resetIntakeClientUpsert,
  listCombos,
} from "../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";
import formDataHandler from "../../util/formDataHandler";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const IntakeDemographics = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: props.FormName,
          },
        ],
      })
    );
    if (props.ID || props.ID === 0) {
      dispatch(
        intakeFormDataGet({
          Client: [
            {
              ClientId: props.ID,
            },
          ],
        })
      );
    }
    return () => {
      dispatch(resetIntakeFormDataGet());
      dispatch(reset("intakeDemographics"));
    };
  }, []);
  const onSubmit = (values) => {
    let ObjectData = formDataHandler(values);
    const data = {
      Client: [
        {
          ...ObjectData,
        },
      ],
    };

    dispatch(intakeClientUpsert(data));
  };
  const { clientData, isSuccess, isFetching, clientId } = useSelector(
    (state) => {
      const {
        intake_client_upsert: { data: upsertData },
        intake_form_data_get: { data },
      } = state;
      return {
        clientData: data?.Data?.IntakeFormData?.[0],
        isSuccess: upsertData?.ResponseCode === 1,
        clientId: upsertData?.Id,
      };
    },
    shallowEqual
  );
  // useEffect(() => {
  //   if (staffData && staffData[0]) {
  //   }
  // }, [staffData]);
  useEffect(() => {
    if (isSuccess) {
      dispatch(resetIntakeClientUpsert());
      if (!props.ID) {
        history.push("/intake/intake-list");
      } else {
        props.handleNext();
      }
    }
  }, [isSuccess]);
  return (
    <div className="">
      <ReduxDemographics
        initialValues={{ CountryISO: "USA", ...clientData }}
        ClientId={props.ID}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
        activeStep={props.activeStep}
        NextTab={props.NextTab}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default IntakeDemographics;

const DemographicsForm = (props) => {
  const [formFields, setFormFields] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const suhbeader = useSubheader();
  suhbeader.setTitle(props.ClientId ? "Edit Demographics" : "Add Demographics");
  const selector = formValueSelector("intakeDemographics");
  const { genericFormData, isFetching, currentCountryValue } = useSelector(
    (state) => {
      const {
        get_form_field_meta_data: { data },
      } = state;
      return {
        genericFormData: data?.Data?.FormFieldMetaData || [],
        currentCountryValue: selector(state, "CountryISO"),
      };
    },
    shallowEqual
  );


  useEffect(() => {
    if (genericFormData && genericFormData.length) {
      let validationConversion = genericFormData;
      validationConversion =
        validationConversion &&
        validationConversion.map((item, index) => {
          if (item.name == "StateOrProvince") {
            let currentOptions = item.DependentOptions.filter(
              (countryStateObj) => {
                //   return item.Parent == "USA";
                if (countryStateObj.Parent == "USA") {
                  debugger;
                }
                return countryStateObj.Parent == currentCountryValue;
              }
            );

            let currentOptionsValue = currentOptions?.[0]?.Child;

            return {
              ...item,
              options: currentOptionsValue || [],
            };
          } else {
            return item;
          }
          // return item;
        });
      setFormFields(validationConversion);
    }
  }, [genericFormData, currentCountryValue]);


  //Will remove below code once tested properlly.

  // useEffect(() => {
  //   if (genericFormData && genericFormData.length) {
  //     let newFormFields =
  //       formFields &&
  //       formFields.map((item, index) => {
  //         if (item.name == "StateOrProvince") {
  //           let currentOptions = item.DependentOptions.filter(
  //             (countryStateObj) => {
  //               if (countryStateObj.Parent == "USA") {
  //                 debugger;
  //               }

  //               return countryStateObj.Parent == currentCountryValue;
  //             }
  //           );

  //           let currentOptionsValue = currentOptions?.[0]?.Child;
  //           return {
  //             ...item,
  //             options: currentOptionsValue || [],
  //           };
  //         } else {
  //           return item;
  //         }
  //         // return item;
  //       });

  //     setFormFields(newFormFields);
  //   }
  // }, [currentCountryValue]);



  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    ClientId,
  } = props;

  return (
    <div className="row">
      <div className="col-12">
        <Card>
          {/* <CardHeader title={"Add New Course"}></CardHeader> */}
          <CardBody>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="enrollment-form staff-form pt-2 pb-2">
                <div className="row pb-5 pl-3 pr-3 pt-3">
                  <DynamicForm formFields={formFields} disable={false} />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-left">
                  {ClientId && (
                    <>
                      {activeStep != 0 && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          onClick={handleBack}
                          disabled={activeStep === 0}
                        >
                          Back
                        </Button>
                      )}
                      &nbsp;
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={handleNext}
                        disabled={!NextTab}
                      >
                        Next
                      </Button>
                    </>
                  )}
                </div>
                <div className="text-right">
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Save
                  </Button>
                  &nbsp;
                  {/* <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    disabled={pristine || submitting}
                    onClick={reset}
                  >
                    Clear Values
                  </Button> */}
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const ReduxDemographics = reduxForm({
  form: "intakeDemographics",
  enableReinitialize: true,
})(DemographicsForm);
