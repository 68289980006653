import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import formDataHandler from "../../../util/formDataHandler";
import {
  getFormFieldMetaData,
  centerUpsert,
  // resetAdminstrationCenterUpsert,
  centerGet,
  resetCenterGet,
} from "../../../../redux/actions";

import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../../core/generic-form";
import Validator from "../../../Validator";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

let CForm = (props) => {
  const [formFields, setFormFields] = useState([]);
  const suhbeader = useSubheader();
  suhbeader.setTitle(props.centerId ? "Edit Center" : "Add New Center");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    props.reset();
    // return () => {};
  }, []);

  const selector = formValueSelector("centerForm");

  const { getGenericFormFields, currentCountryValue } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data, isFetching, error },
    } = state;
    return {
      getGenericFormFields: data?.Data?.FormFieldMetaData || [],
      currentCountryValue: selector(state, "CountryISO"),
    };
  }, shallowEqual);

  useEffect(() => {
    if (getGenericFormFields && getGenericFormFields.length) {
      let validationConversion = getGenericFormFields;
      validationConversion =
        validationConversion &&
        validationConversion.map((item, index) => {
          if (
            item.name == "StateOrProvince" ||
            item.name == "BillingState" ||
            item.name == "FormHeaderState"
          ) {
            let currentOptionsTemp = item.DependentOptions.filter((item) => {
              return item.Parent == currentCountryValue;
            });

            let currentOptions =
              (currentOptionsTemp &&
                currentOptionsTemp[0] &&
                currentOptionsTemp[0].Child) ||
              [];
            return {
              ...item,
              options: currentOptions || [],
            };
          } else {
            return item;
          }
        });
      setFormFields(validationConversion);
    }
  }, [getGenericFormFields]);

  useEffect(() => {
    if (getGenericFormFields && getGenericFormFields.length) {
      let newFormFields =
        formFields &&
        formFields.map((item, index) => {
          if (
            item.name == "StateOrProvince" ||
            item.name == "BillingState" ||
            item.name == "FormHeaderState"
          ) {
            let currentOptions = item.DependentOptions.filter((item) => {
              return item.Parent == currentCountryValue;
            })[0].Child;
            let stateText =
              currentCountryValue === "AUS" ? "Territories" : "State/Province";
            return {
              ...item,
              options: currentOptions || [],
              // labelText: stateText,
              // fieldName: stateText,
            };
          } else {
            return item;
          }
        });
      setFormFields(newFormFields);
    }
  }, [currentCountryValue]);
  
  const { handleSubmit, pristine, reset, submitting, onSubmit } = props;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="enrollment-form staff-form pt-2 pb-2">
                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    {" "}
                    <DynamicForm formFields={formFields} disable={false} />
                  </div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Submit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  {/* <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    disabled={pristine || submitting}
                    onClick={reset}
                  >
                    Clear Values
                  </Button> */}
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
const ReduxCForm = reduxForm({
  form: "centerForm",
  enableReinitialize: true,
})(CForm);

const CenterForm = (props) => {
  const dispatch = useDispatch();
  const [centerDataInit, setCenterDataInit] = useState({ CountryISO: "USA" });
  const history = useHistory();
  const getId = () => {
    var param = new URLSearchParams(history.location.search);
    var centerId = param.get("id");
    if (centerId) {
      centerId = parseInt(centerId);
    } else {
      centerId = null;
    }
    return centerId;
  };
  useEffect(() => {
    dispatch(resetCenterGet());

    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: "Center",
          },
        ],
      })
    );
    if (getId() || getId() === 0) {
      dispatch(centerGet({ Center: { CenterId: getId() } }));
    }
  }, []);
  const { centerDataObj, isInserted } = useSelector(
    ({ center_get: { data }, center_upsert }) => {
      return {
        centerDataObj: data?.Data?.Centers?.[0],
        isInserted: center_upsert.data && center_upsert.data.success,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (isInserted) {
      toast.success("Center added successfully");
      // dispatch(resetAdminstrationCenterUpsert());
      history.push("/center/list");
    }
  }, [isInserted]);

  useEffect(() => {
    if (centerDataObj) {
      setCenterDataInit(centerDataObj);
    }
  }, [centerDataObj]);

  const onSubmit = (values) => {
    let centerId = null;
    let paramCenterId = getId();
    if (paramCenterId && paramCenterId > 0) {
      centerId = paramCenterId;
    }
    let ObjectData = formDataHandler(values);

    const data = {
      Center: [
        {
          CenterId: centerId ? centerId : null,
          // Abstract: abstract,
          // AuthorName: authorName,
          // AuthorStaffId: authorStaffId,
          // CopyrightMsg: copyrightMsg,
          // CourseCode: null,
          // CourseLength: null,
          // CourseLengthUnit: null,
          // CourseTopicId: courseTopic,
          // CourseTypeId: courseType,
          // CourseUrl: courseUrl,
          // Description: description,
          // InactivatedDate: inactivatedDate,
          // LadderAssociation: this.getStringType(values.ladderAssociation),
          // ContractLadderAssociationId: this.getStringType(
          //   values.contractLadderAssociationId
          // ),
          // LanguageISO: language,
          // Name: name,
          // PublishedDate: publishedDate,
          ...ObjectData,
        },
      ],
    };
    dispatch(centerUpsert(data));
  };
  return (
    <ReduxCForm
      onSubmit={onSubmit}
      initialValues={centerDataInit}
      centerId={getId()}
    />
  );
};
export default CenterForm;
