import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";

const Toggle = ({
  value,
  onChange,
  checkedChildren,
  unCheckedChildren,
  disabled,
  color,
  labelText,
}) => (
  <FormGroup>
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={onChange}
          size="small"
          checkedChildren="Yes"
          unCheckedChildren="No"
          disabled={disabled}
          color={color}
        />
      }
      label={labelText}
      labelPlacement="start"
    />
  </FormGroup>
);

export default Toggle;

const ReduxToggle = ({
  input,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  tabIndex,
  color,
  labelText,
  fieldName,
}) => {
  var { touched, error, warning } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value;
    onChange = input.onChange;
    onBlur = input.onBlur;
  }

  return (
    <FormControl
      component="fieldset"
      // className="formControl"
      error={touched && error}
    >
      <Toggle
        {...input}
        tabIndex={tabIndex}
        name={name}
        id={id}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={className}
        disabled={!!disabled}
        color={color}
        labelText={labelText}
      />

      {touched &&
        ((error && fieldName && (
          <FormHelperText id="component-error-text">
            {" "}
            {fieldName} {error}
          </FormHelperText>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </FormControl>
  );
};

export { ReduxToggle };
