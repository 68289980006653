import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* getSurveysV2(action) {
  try {
    const { payload } = action;
    const response = yield API.getSurveysV2(payload);
    yield put({
      type: ActionTypes.GET_SURVEYS_V2_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SURVEYS_V2_FAILURE,
    });
  }
}

export function* getAssessmentDetail(action) {
  try {
    const { payload } = action;
    const response = yield API.getAssessmentDetail(payload);
    yield put({
      type: ActionTypes.GET_ASSESSMENT_DETAIL_LIST_V2_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ASSESSMENT_DETAIL_LIST_V2_FAILURE,
    });
  }
}

export function* assignMultipleV2(action) {
  try {
    const { payload } = action;
    const response = yield API.assignMultipleV2(payload);
    yield put({
      type: ActionTypes.ASSIGN_MULTIPLE_V2_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ASSIGN_MULTIPLE_V2_FAILURE,
    });
  }
}

export function* assessmentsListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.assessmentsListGet(payload);
    yield put({
      type: ActionTypes.ASSESSMENTS_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ASSESSMENTS_LIST_GET_FAILURE,
    });
  }
}

export function* assessmentsMarkAsDiscard(action) {
  try {
    const { payload } = action;
    const response = yield API.assessmentsMarkAsDiscard(payload);
    yield put({
      type: ActionTypes.ASSESSMENTS_MARK_AS_DISCARD_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ASSESSMENTS_MARK_AS_DISCARD_FAILURE,
    });
  }
}

export function* assessmentsMultipleAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.assessmentsMultipleAssign(payload);
    yield put({
      type: ActionTypes.ASSESSMENTS_MULTIPLE_ASSIGN_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ASSESSMENTS_MULTIPLE_ASSIGN_FAILURE,
    });
  }
}

export function* surveyQuestionCategoryOptionGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionCategoryOptionGet(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_CATEGORY_OPTION_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_CATEGORY_OPTION_GET_FAILURE,
    });
  }
}

export function* surveyAssignMultiple(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyAssignMultiple(payload);
    yield put({
      type: ActionTypes.SURVEY_ASSIGN_MULTIPLE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_ASSIGN_MULTIPLE_FAILURE,
    });
  }
}

export function* surveyAnswerUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyAnswerUpsert(payload);
    yield put({
      type: ActionTypes.SURVEY_ANSWER_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_ANSWER_UPSERT_FAILURE,
    });
  }
}

export function* clientSurveyListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.clientSurveyListGet(payload);
    yield put({
      type: ActionTypes.CLIENT_SURVEY_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_SURVEY_LIST_GET_FAILURE,
    });
  }
}

export function* surveyQuestionCategoryListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionCategoryListGet(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_CATEGORY_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_CATEGORY_LIST_GET_FAILURE,
    });
  }
}

export function* surveyQuestionHierarchialGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionHierarchialGet(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_HIERARCHIAL_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_HIERARCHIAL_GET_FAILURE,
    });
  }
}

export function* surveyMarkAsDiscard(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyMarkAsDiscard(payload);
    yield put({
      type: ActionTypes.SURVEY_MARK_AS_DISCARD_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_MARK_AS_DISCARD_FAILURE,
    });
  }
}

export function* surveyAssignedListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyAssignedListGet(payload);
    yield put({
      type: ActionTypes.SURVEY_ASSIGNED_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_ASSIGNED_LIST_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_SURVEYS_V2_REQUEST, getSurveysV2),
    takeLatest(
      ActionTypes.GET_ASSESSMENT_DETAIL_LIST_V2_REQUEST,
      getAssessmentDetail
    ),
    takeLatest(ActionTypes.ASSIGN_MULTIPLE_V2_REQUEST, assignMultipleV2),
    takeLatest(ActionTypes.ASSESSMENTS_LIST_GET_REQUEST, assessmentsListGet),
    takeLatest(
      ActionTypes.ASSESSMENTS_MARK_AS_DISCARD_REQUEST,
      assessmentsMarkAsDiscard
    ),
    takeLatest(
      ActionTypes.ASSESSMENTS_MULTIPLE_ASSIGN_REQUEST,
      assessmentsMultipleAssign
    ),
    takeLatest(
      ActionTypes.SURVEY_QUESTION_CATEGORY_OPTION_GET_REQUEST,
      surveyQuestionCategoryOptionGet
    ),
    takeLatest(
      ActionTypes.SURVEY_ASSIGN_MULTIPLE_REQUEST,
      surveyAssignMultiple
    ),
    takeLatest(ActionTypes.SURVEY_ANSWER_UPSERT_REQUEST, surveyAnswerUpsert),
    takeLatest(ActionTypes.CLIENT_SURVEY_LIST_GET_REQUEST, clientSurveyListGet),
    takeLatest(
      ActionTypes.SURVEY_QUESTION_CATEGORY_LIST_GET_REQUEST,
      surveyQuestionCategoryListGet
    ),
    takeLatest(
      ActionTypes.SURVEY_QUESTION_HIERARCHIAL_GET_REQUEST,
      surveyQuestionHierarchialGet
    ),
    takeLatest(ActionTypes.SURVEY_MARK_AS_DISCARD_REQUEST, surveyMarkAsDiscard),
    takeLatest(
      ActionTypes.SURVEY_ASSIGNED_LIST_GET_REQUEST,
      surveyAssignedListGet
    ),
  ]);
}
