import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import { triggersListGet } from "../../../../redux/actions";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";
import { MapView } from "./mapView";
import { ListView } from "./listView";
// const list = [
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
// ];

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: "5px" }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const Triggers = (className) => {
  const classes = useStyles();
  const suhbeader = useSubheader();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(triggersListGet({}));
  }, []);

  const { userTriggerHistoryData } = useSelector(
    ({ triggers_list_get: { data: TriggersData } }) => {
      return {
        userTriggerHistoryData: TriggersData?.Data?.Triggers,
      };
    },
    shallowEqual
  );

  return (
    <>
      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        scrollButtons="auto"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        className="drawer-tabs"
      >
        <Tab label="Map View" style={{ textTransform: "capitalize" }} />
        <Tab label="List View" style={{ textTransform: "capitalize" }} />
      </Tabs>
      {/* </AppBar> */}
      {value === 0 && (
        <TabContainer>
          {" "}
          <div className="d-flex align-items-center">
            <div className="col-12 px-0 d-flex align-items-center">
              {userTriggerHistoryData && (
                <MapView userTriggerHistoryData={userTriggerHistoryData} />
              )}{" "}
            </div>
          </div>
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <div className="d-flex align-items-center">
            <div className="col-12 px-0 d-flex align-items-center">
              {userTriggerHistoryData && (
                <ListView userTriggerHistoryData={userTriggerHistoryData} />
              )}
            </div>
          </div>
        </TabContainer>
      )}
    </>
  );
};
