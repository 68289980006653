import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import { useSubheader } from "../../../../_metronic/layout";
import {
  miscellaneousTaskUpsert,
  resetMiscellaneousTaskUpsert,
} from "../../../redux/actions";

// import {
//   ReduxInput,
//   ReduxSelect,
//   ReduxTextarea,
//   ReduxDateInput,
//   ReduxTimeInput,
//   ReduxDollarInput,
//   ReduxMultiSelect,
//   ReduxToggle,
//   ReduxCheckbox,
//   ReduxAutoComplete,
//   CheckboxGroup,
// } from "../../../components/redux-component/index";
// import Validator from "../../../Validator";
// import { getClientId } from "../../../util/getParam";

const AddPredinedTask = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(resetMiscellaneousTaskUpsert());
  }, []);

  const { taskDetails } = useSelector((state) => {
    const {
      miscellaneous_task_upsert: { data: taskGet },
    } = state;
    return {
      taskDetails: taskGet?.ResponseCode === 1,
    };
  }, shallowEqual);

  useEffect(() => {
    if (taskDetails) {
      history.push("/admin/miscellaneous?tab=managepredefinedtasks");
      dispatch(resetMiscellaneousTaskUpsert());
    }
  }, [taskDetails]);

  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleDetail = (e) => {
    setDetail(e.target.value);
  };
  const insertTaskDetail = () => {
    if (title && detail) {
      dispatch(
        miscellaneousTaskUpsert({
          Task: [
            {
              TaskId: null,
              Title: title,
              Detail: detail,
            },
          ],
        })
      );
      setTitle("");
      setDetail("");
    }
  };
  return (
    <Card>
      <CardBody className="d-flex flex-column">
        <div className="addNotes">
          <TextField
            // id="note-text"
            label="Enter Predefined Task"
            value={title}
            onChange={handleTitle}
            //   className={classes.textField}
            margin="normal"
            style={{
              width: "300px",
            }}
            // helperText="hello"
            variant="outlined"
          />
          <TextField
            // id="note-text"
            label="Predefined task detail"
            value={detail}
            onChange={handleDetail}
            //   className={classes.textField}
            margin="normal"
            style={{
              width: "300px",
              marginLeft: "15px",
            }}
            // helperText="hello"
            variant="outlined"
          />
        </div>
        <div>
          <Button
            variant="outlined"
            color="secondary"
            style={{
              marginTop: "18px",
              width: "100px",
              marginBottom: "15px",
            }}
            onClick={() =>
              history.push("/admin/miscellaneous?tab=managepredefinedtasks")
            }
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ width: "100px", marginLeft: "15px" }}
            onClick={insertTaskDetail}
            disabled={detail && title ? false : true}
          >
            Add
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddPredinedTask;
