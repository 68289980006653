import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* bedAssignmentGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.bedAssignmentGetAll(payload);
    yield put({
      type: ActionTypes.BED_ASSIGNMENT_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BED_ASSIGNMENT_GET_ALL_FAILURE,
    });
  }
}
export function* bedAssignmentUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.bedAssignmentUpsert(payload);
    yield put({
      type: ActionTypes.BED_ASSIGNMENT_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BED_ASSIGNMENT_UPSERT_FAILURE,
    });
  }
}
export function* upcomingDischargesGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.upcomingDischargesGetAll(payload);
    yield put({
      type: ActionTypes.UPCOMING_DISCHARGES_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPCOMING_DISCHARGES_GET_ALL_FAILURE,
    });
  }
}
export function* upcomingAdmissions(action) {
  try {
    const { payload } = action;
    const response = yield API.upcomingAdmissions(payload);
    yield put({
      type: ActionTypes.UPCOMING_ADMISSIONS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPCOMING_ADMISSIONS_FAILURE,
    });
  }
}
export function* admissionCancel(action) {
  try {
    const { payload } = action;
    const response = yield API.admissionCancel(payload);
    yield put({
      type: ActionTypes.ADMISSION_CANCEL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADMISSION_CANCEL_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.BED_ASSIGNMENT_GET_ALL_REQUEST, bedAssignmentGetAll),
    takeLatest(ActionTypes.BED_ASSIGNMENT_UPSERT_REQUEST, bedAssignmentUpsert),
    takeLatest(ActionTypes.UPCOMING_DISCHARGES_GET_ALL_REQUEST, upcomingDischargesGetAll),
    takeLatest(ActionTypes.UPCOMING_ADMISSIONS_REQUEST, upcomingAdmissions),
    takeLatest(ActionTypes.ADMISSION_CANCEL_REQUEST, admissionCancel),
  ]);
}
