import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";

export function* adminstrationListCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationListCourse(payload);
    yield put({
      type: ActionTypes.COURSE_GET_ALL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_GET_ALL_FAILURE,
    });
  }
}


export function* adminstrationListCenter(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationListCenter(payload);
    yield put({
      type: ActionTypes.CENTER_GET_ALL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_GET_ALL_FAILURE,
    });
  }
}

export function* adminstrationGetCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationGetCourse(payload);
    yield put({
      type: ActionTypes.COURSE_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Course[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_GET_FAILURE,
    });
  }
}



export function* updateMobileAppRole(action) {
  try {
    const response = yield API.updateMobileAppRole(action.payload);
    yield put({
      type: ActionTypes.UPDATE_MOBILE_APP_ROLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_MOBILE_APP_ROLE_FAILURE,
    });
  }
}
export function* adminstrationGetCenter(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationGetCenter(payload);
    yield put({
      type: ActionTypes.CENTER_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Center[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_GET_FAILURE,
    });
  }
}

export function* adminstrationCourseUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationCourseUpsert(payload);
    yield put({
      type: ActionTypes.COURSE_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_UPSERT_FAILURE,
    });
  }
}



export function* adminstrationCenterUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationCenterUpsert(payload);
    yield put({
      type: ActionTypes.CENTER_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_UPSERT_FAILURE,
    });
  }
}

export function* adminstrationCourseDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationCourseDelete(payload);
    yield put({
      type: ActionTypes.COURSE_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_DELETE_FAILURE,
    });
  }
}

export function* adminstrationCenterDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationCenterDelete(payload);
    yield put({
      type: ActionTypes.CENTER_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_DELETE_FAILURE,
    });
  }
}

export function* announcementGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.announcementGetAll(payload);
    yield put({
      type: ActionTypes.ANNOUNCEMENT_GET_ALL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ANNOUNCEMENT_GET_ALL_FAILURE,
    });
  }
}

export function* announcementGetJson(action) {
  try {
    const { payload } = action;
    const response = yield API.announcementGetJson(payload);
    yield put({
      type: ActionTypes.ANNOUNCEMENT_GET_JSON_SUCCESS,
      payload: JSON.parse(response.data.data).Announcement[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ANNOUNCEMENT_GET_JSON_FAILURE,
    });
  }
}
export function* announcementUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.announcementUpsert(payload);
    yield put({
      type: ActionTypes.ANNOUNCEMENT_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ANNOUNCEMENT_UPSERT_FAILURE,
    });
  }
}
export function* announcementDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.announcementDelete(payload);
    yield put({
      type: ActionTypes.ANNOUNCEMENT_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ANNOUNCEMENT_DELETE_FAILURE,
    });
  }
}

export function* isLicenseAvailable(action) {
  try {
    const { payload } = action;
    const response = yield API.isLicenseAvailable(payload);
    yield put({
      type: ActionTypes.IS_LICENSE_AVAILABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.IS_LICENSE_AVAILABLE_FAILURE,
    });
  }
}

export function* getLicenseActivites(action) {
  try {
    const { payload } = action;
    const response = yield API.getLicenseActivites(payload);
    yield put({
      type: ActionTypes.GET_LICENSE_ACTIVITES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LICENSE_ACTIVITES_FAILURE,
    });
  }
}

export function* getCurrentLicenses(action) {
  try {
    const { payload } = action;
    const response = yield API.getCurrentLicenses(payload);
    yield put({
      type: ActionTypes.GET_CURRENT_LICENSES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CURRENT_LICENSES_FAILURE,
    });
  }
}

export function* changeAppRole(action) {
  try {
    const { payload } = action;
    const response = yield API.changeAppRole(payload);
    yield put({
      type: ActionTypes.CHANGE_APP_ROLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CHANGE_APP_ROLE_FAILURE,
    });
  }
}

export function* appInviteDashboard(action) {
  try {
    const { payload } = action;
    const response = yield API.appInviteDashboard(payload);
    yield put({
      type: ActionTypes.APP_INVITE_DASHBOARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_INVITE_DASHBOARD_FAILURE,
    });
  }
}


export function* getPromoCode(action) {
  try {
    const { payload } = action;
    const response = yield API.getPromoCode(payload);
    yield put({
      type: ActionTypes.GET_PROMO_CODE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PROMO_CODE_FAILURE,
    });
  }
}

export function* isManageLicenseRequired(action) {
  try {
    const { payload } = action;
    const response = yield API.isManageLicenseRequired(payload);
    yield put({
      type: ActionTypes.IS_MANAGE_LICENSE_REQUIRED_SUCCESS,
      payload: JSON.parse(response.data.data)[0].Staff[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.IS_MANAGE_LICENSE_REQUIRED_FAILURE,
    });
  }
}
export function* newsUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.newsUpsert(payload);
    yield put({
      type: ActionTypes.NEWS_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.NEWS_UPSERT_FAILURE,
    });
  }
}
export function* getListNews(action) {
  try {
    const { payload } = action;
    const response = yield API.getListNews(payload);
    yield put({
      type: ActionTypes.GET_LIST_NEWS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LIST_NEWS_FAILURE,
    });
  }
}
export function* getNews(action) {
  try {
    const { payload } = action;
    const response = yield API.getNews(payload);
    yield put({
      type: ActionTypes.GET_NEWS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_NEWS_FAILURE,
    });
  }
}
export function* deleteNews(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteNews(payload);
    yield put({
      type: ActionTypes.DELETE_NEWS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_NEWS_FAILURE,
    });
  }
}

export function* validatePinExists(action) {
  try {
    const { payload } = action;
    const response = yield API.validatePinExists(payload);
    yield put({
      type: ActionTypes.VALIDATE_PIN_EXISTS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.VALIDATE_PIN_EXISTS_FAILURE,
    });
  }
}

export function* deleteNoteType(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteNoteType(payload);
    yield put({
      type: ActionTypes.DELETE_NOTE_TYPE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_NOTE_TYPE_FAILURE,
    });
  }
}

export function* getNoteTypeArchive(action) {
  try {
    const { payload } = action;
    const response = yield API.getNoteTypeArchive(payload);
    yield put({
      type: ActionTypes.GET_NOTE_TYPE_ARCHIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_NOTE_TYPE_ARCHIVE_FAILURE,
    });
  }
}
export function* getNoteTypeList(action) {
  try {
    const { payload } = action;
    const response = yield API.getNoteTypeList(payload);
    yield put({
      type: ActionTypes.GET_NOTE_TYPE_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_NOTE_TYPE_LIST_FAILURE,
    });
  }
}
export function* reactivateCenter(action) {
  try {
    const { payload } = action;
    const response = yield API.reactivateCenter(payload);
    yield put({
      type: ActionTypes.CENTER_REACTIVATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_REACTIVATE_FAILURE,
    });
  }
}

export function* adminstrationStaffDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationStaffDelete(payload);
    yield put({
      type: ActionTypes.STAFF_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_DELETE_FAILURE,
    });
  }
}

export function* updateStaffEmail(action) {
  try {
    const { payload } = action;
    const response = yield API.updateStaffEmail(payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_EMAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_EMAIL_FAILURE,
    });
  }
}

export function* updateStaffPassword(action) {
  try {
    const { payload } = action;
    const response = yield API.updateStaffPassword(payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_PASSWORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_PASSWORD_FAILURE,
    });
  }
}

export function* updateStaffCenter(action) {
  try {
    const { payload } = action;
    const response = yield API.updateStaffCenter(payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_CENTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_CENTER_FAILURE,
    });
  }
}

export function* uploadStaffImage(action) {
  try {
    const { payload } = action;
    const response = yield API.uploadStaffImage(payload);
    yield put({
      type: ActionTypes.UPLOAD_STAFF_IMAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPLOAD_STAFF_IMAGE_FAILURE,
    });
  }
}

export function* getStaffShiftLog(action) {
  try {
    const { payload } = action;
    const response = yield API.getStaffShiftLog(payload);
    yield put({
      type: ActionTypes.GET_STAFF_SHIFT_LOG_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STAFF_SHIFT_LOG_FAILURE,
    });
  }
}

export function* addStaffShiftLog(action) {
  try {
    const { payload } = action;
    const response = yield API.addStaffShiftLog(payload);
    yield put({
      type: ActionTypes.ADD_STAFF_SHIFT_LOG_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_STAFF_SHIFT_LOG_FAILURE,
    });
  }
}

export function* staffReactivate(action) {
  try {
    const { payload } = action;
    const response = yield API.staffReactivate(payload);
    yield put({
      type: ActionTypes.STAFF_REACTIVATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_REACTIVATE_FAILURE,
    });
  }
}


export default function* root() {
  yield all([
    takeLatest(ActionTypes.COURSE_GET_ALL_REQUEST, adminstrationListCourse),
    takeLatest(ActionTypes.CENTER_GET_ALL_REQUEST, adminstrationListCenter),
    takeLatest(ActionTypes.COURSE_GET_REQUEST, adminstrationGetCourse),
    takeLatest(ActionTypes.CENTER_GET_REQUEST, adminstrationGetCenter),
    takeLatest(ActionTypes.COURSE_UPSERT_REQUEST, adminstrationCourseUpsert),
    takeLatest(ActionTypes.CENTER_UPSERT_REQUEST, adminstrationCenterUpsert),
    takeLatest(ActionTypes.CENTER_DELETE_REQUEST, adminstrationCenterDelete),
    takeLatest(ActionTypes.COURSE_DELETE_REQUEST, adminstrationCourseDelete),
    takeLatest(ActionTypes.ANNOUNCEMENT_GET_ALL_REQUEST, announcementGetAll),
    takeLatest(ActionTypes.ANNOUNCEMENT_GET_JSON_REQUEST, announcementGetJson),
    takeLatest(ActionTypes.ANNOUNCEMENT_UPSERT_REQUEST, announcementUpsert),
    takeLatest(ActionTypes.ANNOUNCEMENT_DELETE_REQUEST, announcementDelete),
    takeLatest(ActionTypes.IS_LICENSE_AVAILABLE_REQUEST, isLicenseAvailable),
    takeLatest(ActionTypes.GET_LICENSE_ACTIVITES_REQUEST, getLicenseActivites),
    takeLatest(ActionTypes.GET_CURRENT_LICENSES_REQUEST, getCurrentLicenses),
    takeLatest(ActionTypes.UPDATE_MOBILE_APP_ROLE_REQUEST, updateMobileAppRole),
    takeLatest(ActionTypes.CHANGE_APP_ROLE_REQUEST, changeAppRole),
    takeLatest(ActionTypes.APP_INVITE_DASHBOARD_REQUEST, appInviteDashboard),
    takeLatest(ActionTypes.GET_PROMO_CODE_REQUEST, getPromoCode),
    takeLatest(
      ActionTypes.IS_MANAGE_LICENSE_REQUIRED_REQUEST,
      isManageLicenseRequired
    ),
    takeLatest(ActionTypes.NEWS_UPSERT_REQUEST, newsUpsert),
    takeLatest(ActionTypes.GET_LIST_NEWS_REQUEST, getListNews),
    takeLatest(ActionTypes.GET_NEWS_REQUEST, getNews),
    takeLatest(ActionTypes.DELETE_NEWS_REQUEST, deleteNews),
    takeLatest(ActionTypes.VALIDATE_PIN_EXISTS_REQUEST, validatePinExists),
    takeLatest(ActionTypes.DELETE_NOTE_TYPE_REQUEST, deleteNoteType),
    takeLatest(ActionTypes.GET_NOTE_TYPE_ARCHIVE_REQUEST, getNoteTypeArchive),
    takeLatest(ActionTypes.GET_NOTE_TYPE_LIST_REQUEST, getNoteTypeList),
    takeLatest(ActionTypes.CENTER_REACTIVATE_REQUEST, reactivateCenter),
    takeLatest(ActionTypes.STAFF_DELETE_REQUEST, adminstrationStaffDelete),
    takeLatest(ActionTypes.UPDATE_STAFF_EMAIL_REQUEST, updateStaffEmail),
    takeLatest(ActionTypes.UPDATE_STAFF_PASSWORD_REQUEST, updateStaffPassword),
    takeLatest(ActionTypes.UPDATE_STAFF_CENTER_REQUEST, updateStaffCenter),
    takeLatest(ActionTypes.UPLOAD_STAFF_IMAGE_REQUEST, uploadStaffImage),
    takeLatest(ActionTypes.GET_STAFF_SHIFT_LOG_REQUEST, getStaffShiftLog),
    takeLatest(ActionTypes.ADD_STAFF_SHIFT_LOG_REQUEST, addStaffShiftLog),
    takeLatest(ActionTypes.STAFF_REACTIVATE_REQUEST, staffReactivate),
  ]);
}
