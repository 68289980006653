import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getMultistepTab, resetStaffUpsert } from "../../../redux/actions";
import GenericTab from "../../components/gereric-tabs";
import Detail from "./details";
import Invites from "./invites";

const SmallGroupForm = (props) => {
  const history = useHistory();

  const getSmallGroupId = () => {
    let param = new URLSearchParams(history.location.search);
    let smallGroupGuid = param.get("id");
    if (smallGroupGuid) {
      return smallGroupGuid;
    } else {
      smallGroupGuid = null;
    }
    return smallGroupGuid;
  };

  const getStepContent = (
    step,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    FormName,
    ScreenName,
    ScreenTabId,
    IsDynamicTab
  ) => {
    switch (step) {
      case "Details":
        return (
          <Detail
            handleNext={handleNext}
            handleBack={handleBack}
            SmallGroupGuid={getSmallGroupId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      case "Invite Users":
        return (
          <Invites
            handleNext={handleNext}
            handleBack={handleBack}
            SmallGroupGuid={getSmallGroupId()}
            activeStep={activeStep}
            NextTab={NextTab}
            FormName={FormName}
          />
        );
      default:
        return "Loading.......";
    }
  };

  return (
    <>
      <div className="staff-page">
        <GenericTab getStepContent={getStepContent} FormName="SmallGroup" />
      </div>
    </>
  );
};
export default SmallGroupForm;
