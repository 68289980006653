import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import _Tile from "../../../images/icons/tile-view.svg";
import _List from "../../../images/icons/list-view.svg";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
const ToggleButtons = (props) => {
  return (
    <div className="toggle-button">
      <OverlayTrigger overlay={<Tooltip id="tile-tooltip">Tile View</Tooltip>}>
        <div
          className="btn btn-icon btn-hover-light-primary btn-sm"
          onClick={() => props.handelView("tile")}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl(_Tile)} />
          </span>
        </div>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip id="list-tooltip">List View</Tooltip>}>
        <div
          className="btn btn-icon btn-hover-light-primary btn-sm"
          onClick={() => props.handelView("list")}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl(_List)} />
          </span>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default ToggleButtons;
