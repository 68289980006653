import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageTitle from "../../../pageTitleConfig";
import ProfileSection from "./profileSection";
import TabsSection from "./tabsSection";
import { useSubheader } from "../../../../_metronic/layout";

const ClientChartPage = (props) => {
  const history = useHistory();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Chart");

  return (
    <>
      <Helmet>
        <title>{PageTitle.CLIENT_ROSTER_PROFILE_TAB}</title>{" "}
      </Helmet>{" "}
      <div className="row">
        <div className="col-md-3">
          <ProfileSection />
        </div>
        <div className="col-md-9">
          <TabsSection />
        </div>
      </div>
    </>
  );
};
export default ClientChartPage;
