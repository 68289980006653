import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { makeStyles } from "@material-ui/core/styles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  surveyQuestionCategoryOptionGet,
  assessmentsMultipleAssign,
  clientListDropdown,
  surveyAssignMultiple,
} from "../../../../redux/actions";
import _Edit from "../../../images/edit.svg";
import _Close from "../../../images/close.svg";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../../core/generic-form";
import formDataHandler from "../../../util/formDataHandler";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));
let AssignForm = (props) => {
  const classes = useStyles();
  const selector = formValueSelector("assessmentAssignForm");

  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    AssessmentFormFields,
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="enrollment-form assign-assessment-form pt-5 pb-2">
              <div className="row pb-5 px-3 pt-5">
                <DynamicForm
                  formFields={AssessmentFormFields}
                  disable={false}
                />
              </div>
            </div>
            <div className="text-right">
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={props.onCancel}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                type="submit"
                disabled={pristine || submitting}
              >
                Assign
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
const ReduxAssignForm = reduxForm({
  form: "assessmentAssignForm",
  enableReinitialize: true,
})(AssignForm);

export const AssignAssessmentDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      surveyQuestionCategoryOptionGet({
        Assessment: [
          {
            SurveyQuestionCategoryTypeId: "4",
          },
        ],
      })
    );
    dispatch(clientListDropdown({}));
  }, []);

  const { surveyOptions, clientDropdown, isSurveyAssign } = useSelector(
    ({
      survey_question_category_option_get: { data: surveyOptionsData },
      client_list_dropdown: { data: clientDropdownData },
    }) => {
      return {
        surveyOptions: surveyOptionsData?.Data?.SurveyQuestionCategories,
        clientDropdown: clientDropdownData?.Data?.Clients,
      };
    },
    shallowEqual
  );

  const onSubmit = (values) => {
    let ObjectData = formDataHandler(values);
    const data = {
      Survey: [
        {
          ...ObjectData,
          IsSystemAssigned: false,
          IsInternalCall: false,
        },
      ],
    };
    dispatch(surveyAssignMultiple(data));
  };
  let AssessmentFormFields = [
    {
      FormFieldMetaDataId: 78,
      name: "ClientIds",
      validate: null,
      isRequired: true,
      fieldName: "Client",
      isMulti: false,
      DependentOn: null,
      options: clientDropdown || [],
      DependentOptions: null,
      checkBoxOptions: null,
      for: null,
      componentType: "ReduxSelect",
      label: "Select Client",
      labelText: "Select Client",
      FiledLength: null,
      format: null,
      normalize: null,
      helperText: null,
      class: "col-12 col-md-6 col-lg-6",
      isDisplayTitle: null,
      Title: null,
      condition: null,
    },
    {
      FormFieldMetaDataId: 78,
      name: "SurveyQuestionCategoryIds",
      validate: null,
      isRequired: true,
      fieldName: "Assessment Name",
      isMulti: false,
      DependentOn: null,
      options: surveyOptions || [],
      DependentOptions: null,
      checkBoxOptions: null,
      for: null,
      componentType: "ReduxSelect",
      label: "Assessment Name",
      labelText: "Assessment Name",
      FiledLength: null,
      format: null,
      normalize: null,
      helperText: null,
      class: "col-12 col-md-6 col-lg-6",
      isDisplayTitle: null,
      Title: null,
      condition: null,
    },
    {
      FormFieldMetaDataId: 82,
      name: "DueDate",
      validate: null,
      isRequired: true,
      fieldName: "PublisheDue DatedDate",
      isMulti: false,
      DependentOn: null,
      options: null,
      DependentOptions: null,
      checkBoxOptions: null,
      for: null,
      componentType: "ReduxDateInput",
      label: "Due Date",
      labelText: "Due Date",
      FiledLength: null,
      format: null,
      normalize: null,
      helperText: null,
      class: "col-12 col-md-6 col-lg-6",
      isDisplayTitle: null,
      Title: null,
      condition: null,
    },
  ];
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-1">
          <h3>Assign Assessment</h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-2 drawer-assessment drawer-details-view">
          <ReduxAssignForm
            onSubmit={onSubmit}
            AssessmentFormFields={AssessmentFormFields}
            onCancel={props.toggleDrawer("right", false, {})}
            // initialValues={centerDataInit}
            // centerId={getId()}
          />
        </div>
      </div>
    </>
  );
};
