import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../../../_metronic/_partials/dropdowns";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";

import GenericTable from "../../generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  getDynamicGridFilter,
  centerListGet,
  resetCenterStatusUpdate,
  resetCenterGet,
} from "../../../../redux/actions";
import { MemberTrendsFormatter } from "../../column-formatters";
// import { Chat } from "../../custom-component/Chating";
// import { TwilioCalling } from "../../custom-component/TwilioCalling";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";
import { CenterDrawer } from "./center-drawer";
const { SearchBar, ClearSearchButton } = Search;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const CenterList = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, moreItems) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(moreItems);
  };

  const onFilter = (filterValue, toggleFilterDrawer) => {
    let data = {
      ...filterValue,
      // LadderAssociation: parseInt(filterValue.LadderAssociation),
      // CourseType: filterValue.CourseType,
      // CourseTopic: filterValue.CourseTopic,
    };
    dispatch(centerListGet(data));
    toggleFilterDrawer("right", false)("no needs");
  };

   useEffect(() => {
     dispatch(
       dynamicGridColumnConfigurationGet({
         ComponentGrid: [
           {
             Name: "Center",
           },
         ],
       })
     );
     dispatch(
       getDynamicGridFilter({
         ComponentGrid: [
           {
             Name: "Center",
           },
         ],
       })
     );

     dispatch(centerListGet({ searchStr: null }));
   }, []);

  const {
    dynamicGridColumnData,
    isFetching,
    centerListData,
    isCenterStatusChange,
  } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data, isFetching, error },
      center_list_get: { data: ListData },
      center_status_update,
    }) => {
      return {
        dynamicGridColumnData: data,
        centerListData: ListData?.Data?.CenterList,
        isCenterStatusChange: center_status_update?.data?.ResponseCode===1,
      };
    },
    shallowEqual
    );
  
  useEffect(() => {
    if (isCenterStatusChange) {
      toggleDrawer("right", false)("no needs");
      dispatch(centerListGet({ searchStr: null }));
      dispatch(resetCenterGet());
      dispatch(resetCenterStatusUpdate());
    }
  }, [isCenterStatusChange]);

  const {
    PageSize,
    IsRowClick,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    IsFilter,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = centerListData || [];

  // const { Trend } = advisorMemberTrendsData || {};
  const enableSorting = true;

  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Center List");

  return (
    <>
      <div>
        <div className="member-trend">
          {Columns && DataSource && (
            <GenericTable
              // tableTitle="Member Trends"
              entities={DataSource || []}
              columns={Columns || []}
              isCardHeader={false}
              pagination={true}
              isLoading={isFetching}
              bordered={false}
              // selectRow={selectRow}
              // rowEvents={rowEvents}
              tableOptions={{
                PageSize,
                IsRowClick,
                IsExcelExport,
                IsColumnSelectionEnable,
                TableButtons,
                IsFilter,
                FileName: "Center",
              }}
              toggleDrawer={toggleDrawer}
              onFilter={onFilter}
            />
          )}
        </div>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false, {})}
        >
          <div role="presentation" style={{ width: "800px" }}>
            <CenterDrawer
              toggleDrawer={toggleDrawer}
              CenterId={moreItem?.CenterId}
              moreItem
            />
          </div>
        </Drawer>
      </div>
    </>
  );
};
