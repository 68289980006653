import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";

import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import GenericTable from "../../../components/generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  dynamicGridDataGet,
  listBreathalyzerSchedule,
  getDynamicGridFilter,
} from "../../../../redux/actions";
import { BreathlyzerDrawer } from "./breathlyzer-drawer";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const Breathlyzer = (className) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, row) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(row);
    if (open == true) {
      history.push(
        `/breathlyzer/list/?id=${row.ClientId}&drawer=true&tab=test-history`
      );
    } else {
      history.push("/breathlyzer/list");
    }
  };

  useEffect(() => {
    var param = new URLSearchParams(history.location.search);
    var isDrawerOpen = param.get("drawer");

    if (isDrawerOpen == "true") {
      setState({
        right: true,
      });
    }

    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "Breathalyzer",
          },
        ],
      })
    );

    dispatch(
      listBreathalyzerSchedule({
        BreathalyzerSchedule: [{}],
      })
    );
    dispatch(
      getDynamicGridFilter({
        ComponentGrid: [
          {
            Name: "Breathalyzer",
          },
        ],
      })
    );
  }, []);

  const onFilter = (filterValue, toggleFilterDrawer) => {
    let data = {
      ComponentGrid: [
        {
          Name: "Breathalyzer",
          ...filterValue,
        },
      ],
    };

    dispatch(dynamicGridColumnConfigurationGet(data));
    toggleFilterDrawer("right", false)("no needs");
  };

  const {
    dynamicGridColumnData,
    isFetching,
    dynamicGridData,
    isTestCanceled,
  } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data, isFetching, error },
      list_breathalyzer_schedule: { data: ListData },
      breathalyzer_test_upcoming_toggle: { data: CancelData },
    }) => {
      return {
        dynamicGridData: ListData?.Data?.Schedules,
        dynamicGridColumnData: data,
        isTestCanceled: CancelData?.ResponseCode === 1,
      };
    },
    shallowEqual
  );

  const {
    PageSize,
    IsRowClick,
    IsExcelExport,
    IsColumnSelectionEnable,
    Columns,
    TableButtons,
    IsFilter,
  } = dynamicGridColumnData || {};
  const DataSource = dynamicGridData || [];

  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Breathlyzer");

  return (
    <>
      <div className="container-fluid">
        <div className="member-trend breathalyzer-test-list">
          {Columns && DataSource && (
            <GenericTable
              // tableTitle="Member Trends"
              entities={DataSource || []}
              columns={Columns || []}
              isCardHeader={false}
              pagination={true}
              isLoading={isFetching}
              bordered={false}
              // selectRow={selectRow}
              // rowEvents={rowEvents}
              tableOptions={{
                PageSize,
                IsRowClick,
                IsExcelExport,
                IsColumnSelectionEnable,
                TableButtons,
                IsFilter,
                FileName: "Breathlyzer",
              }}
              toggleDrawer={toggleDrawer}
              onFilter={onFilter}
            />
          )}
        </div>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          <div role="presentation" style={{ width: "800px" }}>
            <BreathlyzerDrawer
              toggleDrawer={toggleDrawer}
              ClientId={moreItem?.ClientId}
              moreItem
            />
          </div>
        </Drawer>
      </div>
    </>
  );
};
