import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

const AntdCheckbox = ({
  value,
  onChange,
  label,
  checkedChildren,
  unCheckedChildren,
  disabled,
  labelText,
  error,
  color,
}) => (
  <FormGroup row>
    <FormControlLabel
      error={error}
      control={
        <Checkbox
          checked={value}
          onChange={onChange}
          size="small"
          checkedChildren="Yes"
          unCheckedChildren="No"
          disabled={disabled}
          className="redux-checkbox"
          color={color}
        />
      }
      label={labelText}
    />
  </FormGroup>
);

export const ReduxCheckbox = ({
  input,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  tabIndex,
  labelText,
  fieldName,
  color,
}) => {
  var { touched, error, warning } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value;
    onChange = input.onChange;
    onBlur = input.onBlur;
  }
  return (
    <FormControl className="formControl" error={touched && error}>
      <AntdCheckbox
        {...input}
        className={`d-flex align-items-center ${className}`}
        value={value}
        checked={value}
        labelText={labelText}
        name={name}
        id={id}
        // className={className}
        disabled={!!disabled}
        error={error}
        color={color}
      />

      {touched &&
        ((error && fieldName && (
          <FormHelperText id="component-error-text">
            {" "}
            {fieldName} {error}
          </FormHelperText>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </FormControl>
  );
};

// export default ReduxCheckbox;
