import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

import { useSubheader } from "../../_metronic/layout";
import { Analytics } from "../components/custom-screens/analytics/index";
import { ToDo } from "../components/custom-screens/toDo";
import { TimeLine } from "../components/custom-screens/timeline/index";
import { Triggers } from "../components/custom-screens/triggers/index";
import { MemberTrends } from "../components/custom-screens/memberTrends/index";
import { Activities } from "../components/custom-screens/activities/index";
import { SuspiciousWords } from "../components/custom-screens/suspiciousWords/index";
import { Notifications } from "../components/custom-screens/notifications/index";
import CenteredTabs from "../components/custom-component/custom-tabs";
import {
  dashboardAnalyticsGet,
  memberTrendsGet,
  getDynamicScreenTab,
  resetGetDynamicScreenTab,
} from "../../redux/actions";
import { getClientId } from "../util/getParam";
const list = [
  { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
  { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
];

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

const Labels = { MemberTrends: () => <MemberTrends ID={getClientId()} /> };

// {
//   Name: "Activities",
//   Component: () => (
//     <Activities
//     // {...props}
//     />
//   ),
// },
// {
//   Name: "Suspicious Words",
//   Component: () => (
//     <SuspiciousWords
//     // {...props}
//     />
//   ),
// },
// {
//   Name: "Notifications",
//   Component: () => (
//     <Notifications
//     // {...props}
//     />
//   ),
// },

export const MyPage2 = (className) => {
  const classes = useStyles();
  const suhbeader = useSubheader();
  const dispatch = useDispatch();

  suhbeader.setTitle("Dashboard");

  useEffect(() => {
    // dispatch(dashboardAnalyticsGet({}));
    dispatch(
      memberTrendsGet({
        // Json: JSON.stringify(data),
      })
    );
    let data = {
      Client: [
        {
          ScreenName: "Alerts",
        },
      ],
    };

    dispatch(getDynamicScreenTab(data));
    return () => {
      dispatch(resetGetDynamicScreenTab());
    };
  }, []);

  const { TabInfo, Summary } = useSelector(
    ({ get_dynamic_screen_tab: { data, isFeching } }) => {
      if (data) {
        debugger;
      }
      return {
        TabInfo: data && data.Tabs,
      };
    },
    shallowEqual
  );

  return (
    <>
      {" "}
      <div className="row">
        <div className="col-lg-4 col-xxl-4">
          {/* <Analytics className="card-stretch gutter-b" /> */}
        </div>
        <div className="col-lg-12 col-xxl-12">
          <div className="dashboard__alerts custom-tabs">
            {/* <CenteredTabs tabsData={tabLabels} /> */}
            {TabInfo && TabInfo.length > 0 && (
              <CenteredTabs
                tabsData={TabInfo}
                labels={Labels}
                baseTabUrl={`/outcomes-and-trials?&tab=`}
              />
            )}
          </div>
          {/* <MemberTrends className="card-stretch gutter-b" /> */}
        </div>
        <div className="col-lg-4">
          {/* <ToDo className="card-stretch gutter-b" /> */}
        </div>

        <div className="col-lg-8">{/* <TimeLine /> */}</div>

        <div className="col-lg-4 col-xxl-12 order-2 order-xxl-2 custom-tabs">
          <Triggers />
        </div>

        <div className="col-lg-12 col-xxl-12 order-1 order-xxl-2 mt-5">
          {/* <FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" /> */}
        </div>
      </div>
    </>
  );
};
