import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import GenericTable from "../../components/generic-table/generic-table";
import {
  dynamicGridColumnConfigurationGet,
  listBreathalyzerSchedule,
  getDynamicGridFilter,
} from "../../../redux/actions";
import { BreathlyzerDrawer } from "./drawer";
// import { BreathlyzerDrawer } from "../../components/custom-screens/breathlyzerPage/breathlyzer-drawer";

const Breathlyzer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    right: false,
  });
  const [moreItem, setMoreItem] = useState({});
  const toggleDrawer = (side, open, row) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    setMoreItem(row);
    debugger;
    if (open == true) {
      history.push(
        `/population-activities/?id=${row.ClientId}&drawer=true&tab=test-history`
      );
    } else {
      history.push("/population-activities?tab=breathlyzer");
    }
  };

  useEffect(() => {
    let param = new URLSearchParams(history.location.search);
    let isDrawerOpen = param.get("drawer");

    if (isDrawerOpen == "true") {
      setState({
        right: true,
      });
    }
    dispatch(
      dynamicGridColumnConfigurationGet({
        ComponentGrid: [
          {
            Name: "Breathalyzer",
          },
        ],
      })
    );
    dispatch(
      listBreathalyzerSchedule({
        BreathalyzerSchedule: [{}],
      })
    );
    dispatch(
      getDynamicGridFilter({
        ComponentGrid: [
          {
            Name: "Breathalyzer",
          },
        ],
      })
    );
  }, []);
  const onFilter = (filterValue, toggleFilterDrawer) => {
    let data = {
      ComponentGrid: [
        {
          Name: "Breathalyzer",
          ...filterValue,
        },
      ],
    };

    dispatch(dynamicGridColumnConfigurationGet(data));
    toggleFilterDrawer("right", false)("no needs");
  };
  const {
    dynamicGridColumnData,
    isFetching,
    dynamicGridData,
    isTestCanceled,
  } = useSelector(
    ({
      dynamic_grid_column_configuration_get: { data, isFetching, error },
      list_breathalyzer_schedule: { data: ListData },
      breathalyzer_test_upcoming_toggle: { data: CancelData },
    }) => {
      return {
        dynamicGridData: ListData?.Data?.Schedules,
        dynamicGridColumnData: data,
        isTestCanceled: CancelData?.ResponseCode === 1,
      };
    },
    shallowEqual
  );
  const {
    PageSize,
    IsRowClick,
    IsFilter,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    Columns,
  } = dynamicGridColumnData || {};
  const DataSource = dynamicGridData || [];
  return (
    <div className="pt-5">
      <div className="breathlyzer-tabs member-trend breathalyzer-test-list">
        {Columns && DataSource && (
          <GenericTable
            // tableTitle="Member Trends"
            entities={DataSource || []}
            columns={Columns || []}
            isCardHeader={false}
            pagination={true}
            isLoading={isFetching}
            bordered={false}
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            tableOptions={{
              PageSize,
              IsRowClick,
              IsExcelExport,
              IsColumnSelectionEnable,
              TableButtons,
              IsFilter,
              FileName: "Breathlyzer",
            }}
            toggleDrawer={toggleDrawer}
            onFilter={onFilter}
          />
        )}
      </div>

      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        <div role="presentation" style={{ width: "800px" }}>
          <BreathlyzerDrawer
            toggleDrawer={toggleDrawer}
            ClientId={moreItem?.ClientId}
            moreItem={moreItem}
          />
        </div>
      </Drawer>
    </div>
  );
};
export default Breathlyzer;
