import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Field, reduxForm } from "redux-form";

import {
  ReduxInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxDateInput,
  ReduxTimeInput,
  ReduxDollarInput,
  ReduxMultiSelect,
  ReduxToggle,
  ReduxCheckbox,
  ReduxAutoComplete,
  CheckboxGroup,
} from "../../../components/redux-component/index";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import CustomTable from "../../custom-component/custom-table";
import BreathlyzerTestForm from "./breathlyzerTestForm";

const weekDays = [
  { label: "Mo", value: 2, text: "Monday" },
  { label: "Tu", value: 3, text: "Tuesday" },
  { label: "We", value: 4, text: "Wednesday" },
  { label: "Th", value: 5, text: "Thursday" },
  { label: "Fr", value: 6, text: "Friday" },
  { label: "Sa", value: 7, text: "Saturday" },
  { label: "Su", value: 1, text: "Sunday" },
];

const userOptions = [
  {
    Text: "user1",
    Value: "1",
  },
  {
    Text: "user2",
    Value: "2",
  },
  {
    Text: "user3",
    Value: "3",
  },
  {
    Text: "user4",
    Value: "4",
  },
  {
    Text: "user5",
    Value: "5",
  },
];

const suggestions = [
  { label: "Afghanistan" },
  { label: "Aland Islands" },
  { label: "Albania" },
  { label: "Algeria" },
  { label: "American Samoa" },
  { label: "Andorra" },
  { label: "Angola" },
  { label: "Anguilla" },
  { label: "Antarctica" },
  { label: "Antigua and Barbuda" },
  { label: "Argentina" },
  { label: "Armenia" },
  { label: "Aruba" },
  { label: "Australia" },
  { label: "Austria" },
  { label: "Azerbaijan" },
  { label: "Bahamas" },
  { label: "Bahrain" },
  { label: "Bangladesh" },
  { label: "Barbados" },
  { label: "Belarus" },
  { label: "Belgium" },
  { label: "Belize" },
  { label: "Benin" },
  { label: "Bermuda" },
  { label: "Bhutan" },
  { label: "Bolivia, Plurinational State of" },
  { label: "Bonaire, Sint Eustatius and Saba" },
  { label: "Bosnia and Herzegovina" },
  { label: "Botswana" },
  { label: "Bouvet Island" },
  { label: "Brazil" },
  { label: "British Indian Ocean Territory" },
  { label: "Brunei Darussalam" },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    background: "red",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const ManageSchedule = (props) => {
  const classes = useStyles();
  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <div className="manage-schedule">
      <BreathlyzerTestForm />
    </div>
  );
};
export default reduxForm({
  form: "MaterialUiForm",
})(connect()(ManageSchedule));
