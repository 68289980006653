import React, {
  useEffect,
  useMemo,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import { v4 as uuid } from "uuid";
import Drawer from "@material-ui/core/Drawer";
import { isEqual, isFunction } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
  ColumnToggle,
} from "react-bootstrap-table2-toolkit";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";

import {
  ColorColumnFormatter,
  PriceColumnFormatter,
  MemberTrendsFormatter,
  TrendsFormatter,
  ActionsColumnFormatter,
  LastTestFormatter,
  BreathalyzerTestResultFormatter,
  RedGreenBGFormatter,
  RedGreenTextFormatter,
  BoldTextFormatter,
  DateWithTimeFormatter,
  DateFormatter,
  ProfileFormatter,
} from "../../column-formatters/index";
import { ExportData } from "./export";
// import * as columnFormatters from "../../column-formatters/index";
import { CustomToggleList } from "./CustomToggleList";
import { FilterDrawer } from "./filterDrawer";
import { useProductsUIContext } from "../ProductsUIContext";
import { Pagination } from "../../../../_metronic/_partials/controls";
const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;
const { ToggleList } = ColumnToggle;

const defaultSorted = [{ dataField: "id", order: "asc" }];
const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
const initialFilter = {
  filter: {
    model: "",
    manufacture: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10,
};

// export const FORMATTER_TYPES = {
//   sortCaret: sortCaret,
//   headerSortingClasses: headerSortingClasses,
//   getSelectRow: getSelectRow,
//   getHandlerTableChange: getHandlerTableChange,
//   NoRecordsFoundMessage: NoRecordsFoundMessage,
//   PleaseWaitMessage: PleaseWaitMessage,
// };

export const FORMATTER_TYPES = {
  ColorColumnFormatter: ColorColumnFormatter,
  PriceColumnFormatter: PriceColumnFormatter,
  MemberTrendsFormatter: MemberTrendsFormatter,
  TrendsFormatter: TrendsFormatter,
  ActionsColumnFormatter: ActionsColumnFormatter,
  LastTestFormatter: LastTestFormatter,
  BreathalyzerTestResultFormatter: BreathalyzerTestResultFormatter,
  RedGreenBGFormatter: RedGreenBGFormatter,
  RedGreenTextFormatter: RedGreenTextFormatter,
  BoldTextFormatter: BoldTextFormatter,
  DateWithTimeFormatter: DateWithTimeFormatter,
  DateFormatter: DateFormatter,
  ProfileFormatter: ProfileFormatter,
};

export let CustomTable = (props) => {
  const history = useHistory();
  const [tableColumns, setTableColumns] = useState([]);
  const [rowData, setRowData] = useState([]);

  // const [queryParams, setQueryParamsBase] = useState(initialFilter);
  // const setQueryParams = useCallback((nextQueryParams) => {
  //   debugger;
  //   setQueryParamsBase((prevQueryParams) => {
  //     if (isFunction(nextQueryParams)) {
  //       nextQueryParams = nextQueryParams(prevQueryParams);
  //     }

  //     if (isEqual(prevQueryParams, nextQueryParams)) {
  //       return prevQueryParams;
  //     }

  //     return nextQueryParams;
  //   });
  // }, []);
  // const productsUIContext = useProductsUIContext();
  // const productsUIProps = useMemo(() => {
  //   return {
  //     ids: productsUIContext.ids,
  //     setIds: productsUIContext.setIds,
  //     queryParams: productsUIContext.queryParams,
  //     setQueryParams: productsUIContext.setQueryParams,
  //     openEditProductPage: productsUIContext.openEditProductPage,
  //     openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
  //     // ids: ids,
  //     // setIds: setIds,
  //     // queryParams: queryParams,
  //     // setQueryParams: setQueryParams,
  //     // openEditProductPage: productsUIContext.openEditProductPage,
  //     // openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
  //   };
  // }, [productsUIContext]);
  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.products }),
  //   shallowEqual
  // );
  // const { totalCount, entities, listLoading } = currentState;

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   // clear selections list
  //   productsUIProps.setIds([]);
  //   // server call by queryParams
  //   // dispatch(actions.fetchProducts(productsUIProps.queryParams));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [productsUIProps.queryParams, dispatch]);

  const {
    columns,
    entities,
    bordered,
    pagination,
    isLoading,
    selectRowData,
    // rowEvents,
    tableOptions,
    toggleDrawer,
    rowStyle,
    onFilter,
  } = props;
  const paginationOptions = {
    // custom: true,
    totalSize: entities.length,
    sizePerPageList: sizePerPageList,
    sizePerPage: PageSize || 10,
    // sizePerPage: productsUIProps.queryParams.pageSize,
    // page: productsUIProps.queryParams.pageNumber,
    showTotal: true,
    // withFirstAndLast: false, // hide the going to first and last page button
    // alwaysShowAllBtns: true, // always show the next and previous page button
    // firstPageText: "First", // the text of first page button
    // prePageText: "Prev", // the text of previous page button
    // nextPageText: "Next", // the text of next page button
    // lastPageText: "Last", // the text of last page button
    // nextPageTitle: "Go to next", // the title of next page button
    // prePageTitle: "Go to previous", // the title of previous page button
    // firstPageTitle: "Go to first", // the title of first page button
    // lastPageTitle: "Go to last", // the title of last page button
  };
  const selectRow = {
    // mode: "radio",
    mode: "checkbox",
    clickToSelect: true,
    // selected: [1, 3],
    // clickToEdit: true, // Click to edit cell also
    // nonSelectable: [0, 2, 4],
  };
  const rowEvents = {
    // onClick: (e, row, rowIndex) => {},
    onDoubleClick: (e, row, rowIndex) => {
      row.OnRowClick && history.push(`${row.OnRowClick}`);
    },
  };
  useEffect(() => {
    setRowData(entities);
  }, [entities]);
  // const afterSearch = (newResult) => {
  //   console.log(newResult);
  // };
  const {
    PageSize,
    IsRowClick,
    IsFilter,
    IsExcelExport,
    IsColumnSelectionEnable,
    TableButtons,
    FileName,
  } = tableOptions || {};
  useEffect(() => {
    if (columns && columns.length > 0) {
      let newColumn = [];
      columns.map((item) => {
        let colObj = {
          ...item,
          sortCaret: sortCaret,
          headerSortingClasses: headerSortingClasses,
          csvExport: true,
          // formatExtraData: {
          //   toggleDrawer: toggleDrawer ? toggleDrawer : false,
          // },
        };
        if (item.type === "String") {
          colObj = {
            ...item,
            // sortCaret: FORMATTER_TYPES[item.sortCaret],
            sortCaret: sortCaret,
            headerSortingClasses: headerSortingClasses,
            // sortFunc: (a, b, order, dataField, rowA, rowB) => {
            //   debugger;
            //   if (order === "asc") {
            //     debugger;
            //     return Date.parse(a) - Date.parse(b);
            //   } else if (order === "desc") {
            //     debugger;
            //     return Date.parse(b) - Date.parse(a);
            //   }
            // },
            // dataIndex: item.key,
            // sorter: (a, b) => isComparer(a[item.sortField], b[item.sortField]),
            // sortDirections: ["descend", "ascend"],
            // searchable: false,
            // hidden: true,
            // formatter: (cell, row) => owners[cell],
            // headerFormatter: priceFormatter,
            // headerAlign: "center",
            // headerAlign: (column, colIndex) => "right",
            // headerTitle: true,
            // headerTitle: (column, colIndex) =>
            //   `this is custom title for ${column.text}`,
            // headerAttrs: { title: "ID header column" },
            // csvExport: false,
            // csvType: Number,
            // csvFormatter: (cell, row, rowIndex) => `$ ${cell}NTD`,
            // csvText: "CSV Product ID",
          };
        } else if (item.type === "Integer") {
          colObj = {
            ...item,
            sortCaret: sortCaret,
            headerSortingClasses: headerSortingClasses,
            // sortFunc: (a, b, order, dataField) => {
            //   debugger;
            //   if (order === "asc") {
            //     return b - a;
            //   }
            //   return a - b; // desc
            // },
          };
        } else if (item.type === "Date") {
          colObj = {
            ...item,
            sortCaret: sortCaret,
            headerSortingClasses: headerSortingClasses,
            formatter: FORMATTER_TYPES["DateFormatter"],
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
              if (order === "asc") {
                return Date.parse(a) - Date.parse(b);
              } else if (order === "desc") {
                return Date.parse(b) - Date.parse(a);
              }
            },
          };
        }
        // else if (item.type === "DateTime") {
        //   colObj = {
        //     ...item,
        //     sortCaret: sortCaret,
        //     headerSortingClasses: headerSortingClasses,
        //     formatter: FORMATTER_TYPES["DateWithTimeFormatter"],
        //     sortFunc: (a, b, order, dataField, rowA, rowB) => {
        //       if (order === "asc") {
        //         return Date.parse(a) - Date.parse(b);
        //       } else if (order === "desc") {
        //         return Date.parse(b) - Date.parse(a);
        //       }
        //     },
        //   };
        // }
        if (item.formatter) {
          colObj = {
            ...colObj,
            formatter: FORMATTER_TYPES[item.formatter],
          };
        }
        if (toggleDrawer) {
          colObj = {
            ...colObj,
            formatExtraData: {
              toggleDrawer: toggleDrawer,
            },
          };
        } else {
          colObj = {
            ...colObj,
            formatExtraData: {
              toggleDrawer: false,
            },
          };
        }
        // else if (item.type === "Image") {
        //   colObj = {
        //     ...item,
        //     render: (text, record) => (
        //       <div class="student-list-pic">
        //         <img
        //           src={text || _blank}
        //           alt={record.altText}
        //           class="img-circle"
        //           height="40"
        //           width="40"
        //           style={{ borderRadius: "50%", objectFit: "cover" }}
        //         />
        //       </div>
        //     ),
        //   };
        // }
        // else if (item.type === "Phone") {
        //   colObj = {
        //     ...item,
        //     dataIndex: item.key,
        //     sorter: (a, b) =>
        //       isNumericComparer(a[item.sortField], b[item.sortField]),
        //     sortDirections: ["descend", "ascend"],
        //     render: (text, record) => {
        //       return (
        //         <div className="d-flex">
        //           <div
        //             class="tools-grid"
        //             onClick={(event) => {
        //               if (record.IsVoiceCallAllowed) {
        //                 this.setState({
        //                   callModelVisible: true,
        //                   clickedRecord: record,
        //                 });
        //                 event.stopPropagation();
        //               } else {
        //                 alert("VOIP call not allowed");
        //                 event.stopPropagation();
        //               }
        //             }}
        //             style={{
        //               cursor: "pointer",
        //               width: "125px",
        //               marginRight: "10px",
        //             }}
        //           >
        //             <p
        //               className="d-flex justify-content-around"
        //               style={
        //                 record.IsVoiceCallAllowed
        //                   ? { color: "#46B4FE" }
        //                   : { color: "black" }
        //               }
        //             >
        //               {" "}
        //               {record.IsVoiceCallAllowed ? (
        //                 <img
        //                   style={{ cursor: "pointer", display: "block" }}
        //                   src={`images/twilioPhoneAllowed.svg`}
        //                   alt="Calling Allowed"
        //                   width="10px"
        //                 />
        //               ) : null}
        //               {record.Phone}
        //             </p>
        //           </div>
        //         </div>
        //       );
        //     },
        //   };
        // }
        // else if (item.type === "Action") {
        //   colObj = {
        //     ...item,
        //     dataIndex: item.key,
        //     key: "x",
        //     render: (text, record) => {
        //       return (
        //         <div className="d-flex">
        //           {record.Actions &&
        //             record.Actions.map((recordItem) => {
        //               return (
        //                 <div
        //                   class="tools-grid mr-5"
        //                   onClick={(event) => {
        //                     this.props.history.push(`${recordItem.OnClick}`);
        //                     event.stopPropagation();
        //                   }}
        //                   style={{
        //                     cursor: "pointer",
        //                     width: "25px",
        //                     height: "25px",
        //                     marginRight: "10px",
        //                   }}
        //                 >
        //                   {recordItem.Icon ? (
        //                     <img
        //                       style={{ cursor: "pointer", display: "block" }}
        //                       src={`${recordItem.Icon}`}
        //                       alt={recordItem.Text}
        //                       width="25px"
        //                     />
        //                   ) : (
        //                     <span className={recordItem.Class}>
        //                       {recordItem.Text}
        //                     </span>
        //                   )}
        //                 </div>
        //               );
        //             })}
        //         </div>
        //       );
        //     },
        //   };
        // }
        //         {
        //   dataField: "action",
        //   text: "Actions",
        //   formatter: columnFormatters.ActionsColumnFormatter,
        //   formatExtraData: {
        //     openEditProductPage: productsUIProps.openEditProductPage,
        //     openDeleteProductDialog: productsUIProps.openDeleteProductDialog,
        //   },
        //   classes: "text-right pr-0",
        //   headerClasses: "text-right pr-3",
        //   style: {
        //     minWidth: "100px",
        //   },
        // },
        newColumn.push(colObj);
      });
      setTableColumns(newColumn);
    }
  }, [columns]);

  const [filterState, setFilterState] = useState({
    right: false,
  });
  const toggleFilterDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFilterState({ ...filterState, [side]: open });
  };

  return (
    <>
      {tableColumns && tableColumns.length > 0 && (
        <ToolkitProvider
          id={"custome-table"}
          keyField="id"
          data={entities}
          columns={tableColumns || []}
          // search
          search={{
            afterSearch: (newResult) => {
              setRowData(newResult);
            },
          }}
          // search={{ defaultSearch: "2101" }}
          // search={{ afterSearch }}
          // exportCSV
          exportCSV={{
            onlyExportFiltered: true,
            // ignoreHeader: true,
            // onlyExportSelection: true,
            exportAll: false,
            // fileName: FileName ? FileName : "",
            // fileName: "fie_name.xls",
            fileName: FileName ? `${FileName}.csv` : "excelData.csv",
            // blobType:
            //   "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          }}
          columnToggle
        >
          {(props) => (
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <SearchBar
                    {...props.searchProps}
                    className="custome-search-field mr-2"
                    style={{ color: "black" }}
                    delay={1000}
                    placeholder="Search in all fields"
                  />

                  <CustomToggleList
                    {...props.columnToggleProps}
                    setTableColumns={setTableColumns}
                  />
                </div>
                <div className="d-flex align-items-center">
                  {
                    <ExportData
                      tableColumns={tableColumns}
                      rowData={rowData}
                      FileName={FileName}
                      csvProps={props.csvProps}
                      IsExcelExport={IsExcelExport}
                    />
                  }
                  {/* {IsExcelExport && (
                    <div className="export-button mx-2">
                      <ExportCSVButton {...props.csvProps}>CSV</ExportCSVButton>
                    </div>
                  )} */}
                  {/* {IsExcelExport && (
                    <Button
                      variant="outlined"
                      color={"secondary"}
                      className="mx-2"
                      onClick={() => {
                        exportPdf(tableColumns, rowData, FileName);
                      }}
                    >
                      PDF
                    </Button>
                  )} */}
                  {/* <h3>Input something at below input field:</h3> */}

                  {IsFilter && onFilter && (
                    <Button
                      variant="outlined"
                      color={"secondary"}
                      onClick={toggleFilterDrawer("right", true)}
                      className="mx-2"
                    >
                      Filter
                    </Button>
                  )}
                  {TableButtons &&
                    TableButtons.map((item, index) => {
                      const {
                        text,
                        isNewPage,
                        isModal,
                        isDrawer,
                        className,
                        onClick,
                      } = item;
                      if (isNewPage) {
                        return (
                          <Button
                            variant="contained"
                            color={className ? className : "secondary"}
                            className="mx-2"
                            onClick={(props) => {
                              history.push(`${onClick}`);
                            }}
                          >
                            {text}
                          </Button>
                        );
                      } else if (isModal) {
                        return <></>;
                      } else if (isDrawer) {
                        return <></>;
                      }
                    })}
                </div>
              </div>
              {/* <ClearSearchButton {...props.searchProps} /> */}
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4={true}
                // remote
                bordered={bordered}
                // striped
                hover={IsRowClick}
                // condensed
                // keyField="id"
                // data={entities}
                // columns={columns}
                // defaultSorted={defaultSorted}
                pagination={
                  pagination ? paginationFactory(paginationOptions) : null
                }
                // tabIndexCell
                // selectRow={{
                //   ...selectRow,
                //   ...selectRowData,
                // }}
                rowStyle={rowStyle}
                rowEvents={IsRowClick ? rowEvents : null}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      )}

      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={bordered}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={defaultSorted}
                // defaultSortDirection="asc"
                onTableChange={getHandlerTableChange(
                  productsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: productsUIProps.ids,
                //   setIds: productsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider> */}
      {filterState.right && (
        <Drawer
          transitionDuration={{ enter: 500, exit: 1000 }}
          anchor="right"
          open={filterState.right}
          onClose={toggleFilterDrawer("right", false)}
        >
          <div role="presentation" style={{ width: "800px" }}>
            <FilterDrawer
              toggleFilterDrawer={toggleFilterDrawer}
              onFilter={onFilter}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
