import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

import { useSubheader } from "../../../../_metronic/layout";
import CenteredTabs from "../../custom-component/custom-tabs";
import {
  BehavioralRating,
  Assessments,
  VirtualSecondOpinion,
  Trigger,
  Feeds,
  Services,
  CounselorNotes,
  Journal,
  Meetings,
  TreatmentPlan,
  Leaderboard,
  Profile,
  VideoCallLogs,
  Breathlyzer,
  Chats,
  Task,
  Course,
  GrowthPlan,
  SuspiciousWords,
  TimeTravel,
} from "../../../pages/populationActivitiesTabs";
import {
  getDynamicScreenTab,
  resetGetDynamicScreenTab,
} from "../../../../redux/actions";
// const tabLabels = [
//   {
//     Name: "Trends",
//     Component: () => <h6>Sample Text</h6>,
//   },
// ];
const TabsSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [TabLabels, setTabLabels] = useState([]);
  useEffect(() => {
    let data = {
      Client: [
        {
          ScreenName: "PopulationActivities",
        },
      ],
    };
    dispatch(getDynamicScreenTab(data));
    return () => {
      dispatch(resetGetDynamicScreenTab());
    };
  }, []);

  const Labels = {
    TimeTravel: () => {
      return (
        <>
          {/* <span>Coming Soon ...</span> */}
          <TimeTravel tabId="TimeTravel" />
        </>
      );
    },
    BehavioralRating: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <BehavioralRating tabId="BehavioralRating" /> */}
        </>
      );
    },
    Assessments: () => {
      return (
        <>
          {/* <span>Coming Soon ...</span> */}
          <Assessments tabId="Assessments" />
        </>
      );
    },
    VirtualSecondOpinion: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <VirtualSecondOpinion tabId="VirtualSecondOpinion" /> */}
        </>
      );
    },
    Trigger: () => {
      return (
        <>
          {/* <span>Coming Soon ...</span> */}
          <Trigger tabId="Trigger" />
        </>
      );
    },
    Journal: () => {
      return (
        <>
          {/* <span>Coming Soon ...</span> */}
          <Journal tabId="Journal" />
        </>
      );
    },
    Tasks: () => {
      return (
        <>
          {/* <span>Coming Soon ...</span> */}
          <Task tabId="Tasks" />
        </>
      );
    },
    Feeds: () => {
      return (
        <>
          {/* <span>Coming Soon ...</span> */}
          <Feeds tabId="Feeds" />
        </>
      );
    },
    Chats: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <Chats tabId="Chats" /> */}
        </>
      );
    },
    Breathlyzer: () => {
      return (
        <>
          {/* <span>Coming Soon ...</span> */}
          <Breathlyzer tabId="Breathlyzer" />
        </>
      );
    },
    Services: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <Services tabId="Services" /> */}
        </>
      );
    },
    Courses: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <Course tabId="Courses" /> */}
        </>
      );
    },
    GrowthPlans: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <GrowthPlan tabId="GrowthPlans" /> */}
        </>
      );
    },
    CounselorNotes: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <CounselorNotes tabId="CounselorNotes" /> */}
        </>
      );
    },
    Meetings: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <Meetings tabId="Meetings" /> */}
        </>
      );
    },
    VideoCallLogs: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <VideoCallLogs tabId="VideoCallLogs" /> */}
        </>
      );
    },
    TreatmentPlan: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <TreatmentPlan tabId="TreatmentPlan" /> */}
        </>
      );
    },
    Profile: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <Profile tabId="Profile" /> */}
        </>
      );
    },
    Leaderboard: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <Leaderboard tabId="Leaderboard" /> */}
        </>
      );
    },
    SuspiciousWords: () => {
      return (
        <>
          <span>Coming Soon ...</span>
          {/* <SuspiciousWords tabId="SuspiciousWords" /> */}
        </>
      );
    },
  };

  const { TabInfo, Summary } = useSelector(
    ({ get_dynamic_screen_tab: { data, isFeching } }) => {
      return {
        TabInfo: data && data.Tabs,
      };
    },
    shallowEqual
  );
  // useEffect(() => {
  //   if (TabInfo && TabInfo.length > 0) {
  //     let tabs = TabInfo.map((tab, index) => {
  //       return {
  //         Id: tab.TabName,
  //         Name: tab.TabName,
  //         Component: Labels[tab.Component],
  //       };
  //     });
  //     setTabLabels(tabs);
  //   }
  // }, [TabInfo]);

  return (
    <div className="dashboard__alerts custom-tabs">
      {TabInfo && TabInfo.length > 0 && (
        <CenteredTabs
          tabsData={TabInfo}
          labels={Labels}
          baseTabUrl="/population-activities?tab="
        />
      )}
    </div>
  );
};
export default TabsSection;
