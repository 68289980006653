import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import _Close from "../../../../images/close.svg";
import _blank from "../../../../images/blank-profile.png";
import CustomLoader from "../../../../components/custom-component/custom-loader";

export const ViewNonParticipantsDrawer = (props) => {
  const { isMulti, name } = props;
  const [selectedValue, setSelectedValue] = useState("");

  const { clientGroupOptionsGetData, isFetching } = useSelector(
    ({ client_group_options_get: { data, isFetching } }) => {
      return {
        clientGroupOptionsGetData: data?.Data?.Clients,
        isFetching,
      };
    },
    shallowEqual
  );

  const handleChange = (event) => {
    debugger;
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="border-0 pt-1">
          <span style={{ paddingLeft: "10px" }}>Clients</span>
        </div>
        {/* Body */}
        <div className="card-body pt-2 drawer-small-group drawer-details-view">
          <div className="row">
            <div className="col-11">
              {/* <span className="small-group-title">Clients</span> */}
            </div>
          </div>
          <div>
            {isFetching ? (
              <CustomLoader />
            ) : (
              <>
                {clientGroupOptionsGetData &&
                  clientGroupOptionsGetData.map((item) => {
                    return (
                      <div
                        className="row align-items-center"
                        style={{
                          padding: "10px",
                          margin: "14px 10px",
                          backgroundColor: "#F7F8FA",
                        }}
                      >
                        {isMulti ? (
                          <Checkbox
                            value={item.Value}
                            onChange={(e) => {
                              props.handleChecked(item.Value, e);
                            }}
                          />
                        ) : (
                          <Radio
                            checked={
                              parseInt(selectedValue) === parseInt(item.Value)
                            }
                            onChange={(e) => {
                              debugger;
                              handleChange(e);
                              props.handleRadioChange(item.Value);
                            }}
                            value={item.Value}
                            name={name}
                          />
                        )}

                        <div class="circular_image">
                          <img
                            src={item.ProfileImageUrl || _blank}
                            alt=""
                            style={{
                              height: "42px",
                              width: "42px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <ListItemText
                          style={{ marginLeft: "8px" }}
                          primary={item.Text}
                        />
                        <br />
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
