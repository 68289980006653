import React, { useEffect, useState, useCallback } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { toAbsoluteUrl } from "./../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Radio from "@material-ui/core/Radio";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDropzone } from "react-dropzone";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import _Close from "./../../images/close.svg";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from "redux-form";
import {
  getFormFieldMetaData,
  clientGroupGet,
  groupGet,
  groupUpsert,
  resetGroupGet,
  resetGroupUpsert,
  resetClientGroupGet,
  groupDeactivate,
} from "../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";
import formDataHandler from "../../util/formDataHandler";
import _deactivate from "./../../images/deactivate.svg";
import _reactivate from "./../../images/reactivate.svg";

const img = {
  height: "60px",
  width: "60px",
  borderRadius: "50%",
};

const useStyles = makeStyles((theme) => ({
  error: {
    color: "red",
    borderColor: "red",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const DetailForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const suhbeader = useSubheader();
  const [formFields, setFormFields] = useState([]);
  const [GLPPTypeValue, setGLPPType] = useState("E");
  const [files, setFiles] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);

  suhbeader.setTitle(
    props.GroupId ? "Edit Clinical Group" : "New Clinical Group"
  );
  const selector = formValueSelector("CGDetailForm");
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);
  const thumbs = files.map((file) => (
    <div key={file.name}>
      <img src={file.preview} style={img} />
    </div>
  ));
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".jpg,.jpeg,.png",
    maxSize: 2097152,
    maxFiles: 1,
    multiple: false,
    onDrop: onDrop,
  });

  const { genericFormData, isFetching } = useSelector((state) => {
    const {
      get_form_field_meta_data: { data },
    } = state;
    return {
      genericFormData: data?.Data?.FormFieldMetaData || [],
      // currentCountryValue: selector(state, "CountryISO"),
    };
  }, shallowEqual);
  const handleGLPictureChange = (event) => {
    setGLPPType(event.target.value);
  };
  useEffect(() => {
    if (genericFormData && genericFormData.length) {
      setFormFields(genericFormData);
    }
  }, [genericFormData]);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    GroupId,
    IsArchived,
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="enrollment-form small-group-form pt-2 pb-2">
                  <div className="row pb-5 pl-3 pr-3 pt-5">
                    <DynamicForm formFields={formFields} disable={IsArchived} />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-left">
                    {!GroupId && (
                      <>
                        {activeStep != 0 && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            Back
                          </Button>
                        )}
                        &nbsp;
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={() => {
                            // history.push(
                            //   `/admin/staff/edit-form?tab=roles-and-permissions&id=${props.SmallGroupGuid ||
                            //     props.smallGroupGuid}`
                            // );
                            handleNext();
                          }}
                          disabled={!NextTab}
                        >
                          Next
                        </Button>
                      </>
                    )}
                    {GroupId && (
                      <div>
                        {IsArchived ? (
                          <Button
                            variant="outlined"
                            color="secondary"
                            // className={classes.button}
                            onClick={() => {
                              dispatch(
                                groupDeactivate({
                                  Group: [
                                    {
                                      GroupId: GroupId,
                                      IsActivate: 1,
                                    },
                                  ],
                                })
                              );
                              history.push(`/community/clinical-group-list`);
                            }}
                            disabled={!NextTab}
                          >
                            <SVG
                              className="mr-2"
                              src={toAbsoluteUrl(_reactivate)}
                              style={{ height: "15px" }}
                            />
                            Reactivate Group
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="error"
                            className={classes.error}
                            onClick={() => {
                              setOpenDialog(true);
                            }}
                            disabled={!NextTab}
                          >
                            <SVG
                              className="mr-2"
                              src={toAbsoluteUrl(_deactivate)}
                              style={{ height: "15px" }}
                            />
                            Deactivate Group
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      type="submit"
                      disabled={pristine || submitting}
                    >
                      Submit
                    </Button>
                    &nbsp;
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      disabled={pristine || submitting}
                      onClick={reset}
                    >
                      Clear Values
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to Deactivate this group?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              className="d-flex justify-content-end"
              style={{ paddingRight: "31px", paddingBottom: "31px" }}
            >
              <Button
                variant="outlined"
                color="secondary"
                // className={classes.button}
                onClick={() => {
                  handleClose();
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className="ml-5"
                onClick={() => {
                  dispatch(
                    groupDeactivate({
                      Group: [
                        {
                          GroupId: GroupId,
                          IsActivate: 0,
                        },
                      ],
                    })
                  );
                  history.push(`/community/clinical-group-list`);
                }}
              >
                Yes
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const ReduxGIForm = reduxForm({
  form: "CGDetailForm",
  enableReinitialize: true,
})(DetailForm);

const GroupInformation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(resetGroupGet());
    dispatch(resetGroupUpsert());
    dispatch(
      getFormFieldMetaData({
        DynamicForm: [
          {
            FormName: "GroupInformation",
          },
        ],
      })
    );

    if (props.GroupId) {
      dispatch(
        groupGet({
          ClientGroup: [
            {
              GroupId: props.GroupId,
            },
          ],
        })
      );
    }
    return () => {
      dispatch(resetClientGroupGet());
    };
  }, []);

  const onSubmit = (values) => {
    let ObjectData = formDataHandler(values);
    const formData = {
      Group: [
        {
          ...ObjectData,
        },
      ],
    };
    dispatch(groupUpsert(formData));
  };

  const {
    GroupData,
    isGroupUpsert,
    isFetching,
    GroupId,
    GroupInfo,
    IsArchived,
  } = useSelector((state) => {
    const {
      group_upsert: { data: upsertData },
      group_get: { data: GroupData },
    } = state;
    return {
      GroupData: GroupData?.Data?.ClinicalGroup?.[0] || {},
      GroupInfo: GroupData?.Data?.ClinicalGroup?.[0] || {},
      IsArchived: GroupData?.Data?.ClinicalGroup?.[0]?.IsArchived || false,
      isGroupUpsert: upsertData?.ResponseCode === 1,
      GroupId: upsertData?.Data?.Id,
    };
  }, shallowEqual);

  useEffect(() => {
    if (isGroupUpsert) {
      history.replace(`/community/group/edit?id=${GroupId}`);
      dispatch(resetGroupUpsert());
      props.handleNext();
    }
  }, [isGroupUpsert]);

  return (
    <div className="">
      <ReduxGIForm
        initialValues={{ ...GroupInfo }}
        GroupId={props.GroupId}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
        activeStep={props.activeStep}
        NextTab={props.NextTab}
        onSubmit={onSubmit}
        IsArchived={IsArchived}
      />
    </div>
  );
};

export default GroupInformation;
