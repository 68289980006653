import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

import PageTitle from "../pageTitleConfig";
import { useSubheader } from "../../_metronic/layout";
import { Analytics } from "../components/custom-screens/analytics/index";
import { ToDo } from "../components/custom-screens/toDo";
import { TimeLine } from "../components/custom-screens/timeline/index";
import { Triggers } from "../components/custom-screens/triggers/index";
import { MemberTrends } from "../components/custom-screens/memberTrends/index";
import { Activities } from "../components/custom-screens/activities/index";
import { SuspiciousWords } from "../components/custom-screens/suspiciousWords/index";
import { Notifications } from "../components/custom-screens/notifications/index";
import CenteredTabs from "../components/custom-component/custom-tabs";
import {
  dashboardAnalyticsGet,
  memberTrendsGet,
  getDynamicScreenTab,
  resetGetDynamicScreenTab,
  triggersListGet,
} from "../../redux/actions";
import { getClientId } from "../util/getParam";


const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

const Labels = {
  MemberTrends: () => {
    return (
      <>
        {/* <span>Coming Soon</span> */}
        <MemberTrends ID={getClientId()} />
      </>
    );
  },
  Activities: () => {
    return (
      <>
        <span>Coming Soon</span>
        {/* <Activities ID={getClientId()} /> */}
      </>
    );
  },
  SuspiciousWords: () => {
    return (
      <>
        <span>Coming Soon</span>
        {/* <SuspiciousWords ID={getClientId()} /> */}
      </>
    );
  },
  Notifications: () => {
    return (
      <>
        <span>Coming Soon</span>
        {/* <Notifications ID={getClientId()} /> */}
      </>
    );
  },
};

export const Dashboard = (className) => {
  const classes = useStyles();
  const suhbeader = useSubheader();
  const dispatch = useDispatch();

  suhbeader.setTitle("Dashboard");

  useEffect(() => {
    // dispatch(dashboardAnalyticsGet({}));
    dispatch(memberTrendsGet({}));
    dispatch(triggersListGet({}));
    let data = {
      Client: [
        {
          ScreenName: "Alerts",
        },
      ],
    };

    dispatch(getDynamicScreenTab(data));
    return () => {
      dispatch(resetGetDynamicScreenTab());
    };
  }, []);

  const { TabInfo, Summary } = useSelector(
    ({ get_dynamic_screen_tab: { data, isFeching } }) => {
      return {
        TabInfo: data?.Tabs,
      };
    },
    shallowEqual
  );

  return (
    <>
      <Helmet>
        <title>{PageTitle.DASHBOARD}</title>
      </Helmet>
      <div className="row">
        {/* <div className="col-lg-4 col-xxl-4">
          <Analytics className="card-stretch gutter-b" />
        </div> */}
        <div className="col-lg-12 col-xxl-12">
          <div className="dashboard__alerts custom-tabs">
            {/* <CenteredTabs tabsData={tabLabels} /> */}
            {TabInfo && TabInfo.length > 0 && (
              <CenteredTabs
                tabsData={TabInfo}
                labels={Labels}
                header="Alerts"
                baseTabUrl={`/dashboard?&tab=`}
              />
            )}
          </div>
          {/* <MemberTrends className="card-stretch gutter-b" /> */}
        </div>
        <div className="col-lg-4">
          {/* <ToDo className="card-stretch gutter-b" /> */}
        </div>

        <div className="col-lg-8">{/* <TimeLine /> */}</div>

        <div className="col-lg-4 col-xxl-12 order-2 order-xxl-2 custom-tabs">
          <div className={`card card-custom`}>
            {/* Head */}
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                Active Map
              </h3>
              {/* <div className="card-toolbar">
            <Button color="secondary" className={classes.button}>
              + Add New
            </Button>
          </div> */}
            </div>
            {/* Body */}
            <div className="card-body pt-2 member-trend">
              <Triggers />
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-xxl-12 order-1 order-xxl-2 mt-5">
          {/* <FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" /> */}
        </div>
      </div>
    </>
  );
};
