import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
// import StepLabel from "@material-ui/core/StepLabel";
// import Check from "@mui/icons-material/Check";
import { getMultistepTab } from "../../../redux/actions";
import { getClientId } from "../../util/getParam";
const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function GenericTab(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const [steps, setSteps] = React.useState([]);

  useEffect(() => {
    dispatch(
      getMultistepTab({
        Tab: [
          {
            FormName: props.FormName,
            FormAction: null,
            CurrentTab: null,
          },
        ],
      })
    );
  }, []);
  const { getSteps, isFetching } = useSelector((state) => {
    const {
      get_multistep_tab: { data },
    } = state;
    return {
      getSteps: data?.Data?.ScreenTabs,
    };
  }, shallowEqual);

  useEffect(() => {
    if (getSteps && getSteps.length > 0) {
      const {
        FormName,
        TabName,
        ScreenName,
        ScreenTabId,
        IsDynamicTab,
        NextTab,
        PreviousTab,
      } = getSteps[0];
      let preSteps = null;
      let postSteps = null;
      let newSteps = [];
      if (PreviousTab) {
        // preSteps = [PreviousTab[0].TabName];
        // Managment decided to show all the previous tabs which user completed 10/22.
        preSteps = PreviousTab?.map((item) => item.TabName);
      }
      if (NextTab) {
        postSteps = [NextTab[0].TabName];
      }
      if (preSteps && postSteps) {
        newSteps = [...preSteps, TabName, ...postSteps];
        // setActiveStep(1);
      } else if (preSteps && !postSteps) {
        newSteps = [...preSteps, TabName];
        // setActiveStep(1);
      } else if (!preSteps && postSteps) {
        newSteps = [TabName, ...postSteps];
        // setActiveStep(0);
        // setActiveStep("roles");
      }
      // if (postSteps) {
      //   newSteps = [TabName, ...postSteps];
      // } else {
      //   newSteps = [TabName];
      // }
      // let tabIndex = Object.keys(newSteps);
      let tabIndex = newSteps.indexOf(TabName);
      setSteps(newSteps);
      setActiveStep(parseInt(tabIndex));
    }
  }, [getSteps]);
  function totalSteps() {
    return steps.length;
  }

  function isStepOptional(step) {
    return step === 1;
  }

  function handleSkip() {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  }

  function skippedSteps() {
    return skipped.size;
  }

  function completedSteps() {
    return completed.size;
  }

  function allStepsCompleted() {
    return completedSteps() === totalSteps() - skippedSteps();
  }

  function isLastStep() {
    return activeStep === totalSteps() - 1;
  }


  
  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;
    // setActiveStep(newActiveStep);
    // let abc = new set(steps);
    // debugger;
    // abc.add("pawan");
    // debugger;
    // setSteps(abc);
    // setSteps([...steps, "new tab"]);
    // props.onNext();
    let {
      FormName,
      TabName,
      ScreenName,
      ScreenTabId,
      IsDynamicTab,
      NextTab,
      PreviousTab,
    } = getSteps[0];
    dispatch(
      getMultistepTab({
        Tab: [
          {
            FormName: props.FormName,
            FormAction: "Next",
            CurrentTab: TabName,
            Id: getClientId() || null,
          },
        ],
      })
    );
  }

  function handleBack() {
    let {
      FormName,
      TabName,
      ScreenName,
      ScreenTabId,
      IsDynamicTab,
      NextTab,
      PreviousTab,
    } = getSteps[0];
    dispatch(
      getMultistepTab({
        Tab: [
          {
            FormName: props.FormName,
            FormAction: "Previous",
            CurrentTab: TabName,
            Id: getClientId() || null,
          },
        ],
      })
    );
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  function handleComplete() {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== totalSteps() - skippedSteps()) {
      handleNext();
    }
  }

  function handleReset() {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  }

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function isStepComplete(step) {
    return completed.has(step);
  }

  const {
    FormName,
    TabName,
    ScreenName,
    ScreenTabId,
    IsDynamicTab,
    NextTab,
    PreviousTab,
  } = (getSteps && getSteps[0]) || {};
  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        //    nonLinear     //Enable this to allow step clickable
        activeStep={activeStep}
      >
        {steps &&
          steps.map((label, index) => {
            const stepProps = {};
            const buttonProps = {};
            //   if (isStepOptional(index)) {
            //     buttonProps.optional = (
            //       <Typography variant="caption">Optional</Typography>
            //     );
            //   }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                {/* <StepLabel>{label}</StepLabel> */}
                <StepButton
                  onClick={handleStep(index)}
                  completed={isStepComplete(index)}
                  {...buttonProps}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {props.getStepContent(
                TabName,
                handleNext,
                handleBack,
                activeStep,
                NextTab,
                FormName,
                ScreenName,
                ScreenTabId,
                IsDynamicTab
              )}
            </Typography>
            <div>
              {/* <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button> */}

              {/* <Button
                disabled={!NextTab}
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                Next
              </Button> */}
              {/* {isStepOptional(activeStep) && !completed.has(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )} */}
              {/* {activeStep !== steps.length &&
                (completed.has(activeStep) ? (
                  <Typography variant="caption" className={classes.completed}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleComplete}
                  >
                    {completedSteps() === totalSteps() - 1
                      ? "Finish"
                      : "Complete Step"}
                  </Button>
                ))} */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GenericTab = withRouter(GenericTab);
