import React from "react";
export const LastTestFormatter = (cellContent, row) => {
  const { TestDate, ResultClassBlock, ResultClassText, ResultText } =
    (cellContent && cellContent[0]) || {};

  return (
    <div>
      <span className="test-date">{TestDate}</span>&nbsp;&nbsp;
      <span style={{ display: "inline" }}>
        <span className={`${ResultClassBlock} block`}></span>
        <span className={`${ResultClassText} text`}>{ResultText}</span>
      </span>
    </div>
  );
};
