import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { courseGet, courseStatusUpdate } from "../../../../redux/actions";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Edit from "../../../images/edit.svg";
import _Close from "../../../images/close.svg";
import { UserHeader } from "../../custom-component/userHeader";
const { SearchBar, ClearSearchButton } = Search;

const useStyles = makeStyles((theme) => ({
  activateButton: {
    margin: theme.spacing(1),
    color: "red",
    background: "transparent",
    border: "1px solid red",
  },
  deActivateButton: {
    margin: theme.spacing(1),
    color: "white",
    background: "blue",
    "&:hover, &:focus": {
      backgroundColor: "blue",
    },
  },
  input: {
    display: "none",
  },
}));

export const CourseDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const enableSorting = true;
  const classes = useStyles();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Center List");

  // getCourseId = () => {
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   var courseId = param.get("courseId") || param.get("courseid");
  //   if (courseId) {
  //     courseId = parseInt(courseId);
  //   } else {
  //     courseId = null;
  //   }
  //   return courseId;
  // };
  useEffect(() => {
    dispatch(courseGet({ Course: { CourseId: props.CourseId } }));
  }, []);

  const { CourseInfo, isFetching } = useSelector(
    ({ course_get: { data, isFetching } }) => {
      if (isFetching) {
        debugger;
      }
      return {
        CourseInfo: data?.Data?.Courses?.[0],
        isFetching,
      };
    },
    shallowEqual
  );
  const {
    Abstract,
    AuthorName,
    AuthorStaffMember,
    AuthorStaffId,
    ContractLadderAssociationId,
    ContractLadderAssociation,
    CopyrightMsg,
    CourseCode,
    CourseId,
    CourseLength,
    CourseLengthUnit,
    CourseTopicId,
    CourseTypeDesc,
    CourseTypeId,
    CourseTopicDesc,
    CourseUrl,
    Description,
    InactivatedDate,
    LadderAssociation,
    LanguageISO,
    Name,
    PublishedDate,
    PublishedDateDDMMMYYYY,
    LadderAssociations,
    Status,
    StatusText,
  } = CourseInfo || {};
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <h3>Course Details</h3>
          <OverlayTrigger overlay={<Tooltip id="close-tooltip">Close</Tooltip>}>
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={props.toggleDrawer("right", false, {})}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_Close)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
        {/* Body */}
        <div className="card-body pt-5 drawer-course">
          <div className="row pb-5">
            <div className="col-11">
              <span className="course-title">{Name}</span>
            </div>
            <div className="col-1">
              <OverlayTrigger
                overlay={<Tooltip id="edit-tooltip">Edit Course</Tooltip>}
              >
                <a
                  className="btn btn-icon btn-hover-light-primary btn-sm"
                  onClick={() => {
                    history.push(`/admin/courses/edit?id=${props.CourseId}`);
                  }}
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl(_Edit)} />
                  </span>
                </a>
              </OverlayTrigger>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Ladder Associated</span>
                  <span className="c-value">{LadderAssociations}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Ladder Category</span>
                  <span className="c-value">{ContractLadderAssociation}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Topic</span>
                  <span className="c-value">{CourseTopicDesc}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Status</span>
                  <span className="c-value">{StatusText}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Author</span>
                  <span className="c-value">{AuthorName}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Author Staff Member</span>
                  <span className="c-value">{AuthorStaffMember}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Published Date</span>
                  <span className="c-value">{PublishedDate}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Course Type</span>
                  <span className="c-value">{CourseTypeDesc}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Language</span>
                  <span className="c-value">{LanguageISO}</span>
                </div>
                <div className="col-md-6 col-sm-12 c-item">
                  <span className="c-label">Course Url</span>
                  <span className="c-value">{CourseUrl}</span>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Abstract</span>
                  <span className="c-value">{Abstract}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-md-12 col-sm-12 c-item">
                  <span className="c-label">Description</span>
                  <span className="c-value">{Description}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            style={{
              boxShadow: " 0px 0px 10px 4px rgba(115, 116, 119, 0.06)",
              bordeRadius: "4px",
              padding: "15px 15px",
            }}
          >
            <h4>Summary</h4>
          </div> */}
        </div>
        {/* Footer */}
        <div className="d-flex align-items-center justify-content-between">
          <div className="text-right">
            {/* <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button> */}
            &nbsp;
            {CourseId && (
              <Button
                className={
                  StatusText == "Active"
                    ? classes.deActivateButton
                    : classes.activateButton
                }
                onClick={() => {
                  dispatch(
                    courseStatusUpdate({
                      Course: [
                        {
                          CourseId,
                        },
                      ],
                    })
                  );
                }}
              >
                {StatusText == "Active" ? "Activate" : "Deactivate"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
