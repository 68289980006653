import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { toast } from "react-toastify";
import Drawer from "@material-ui/core/Drawer";

import {
    taskListGet,
    taskUnAssign,
    resetTaskUnAssign,
    resetTaskMarkComplete,
    taskMarkComplete
} from "../../../../redux/actions";
import _Close from "../../../images/close.svg";
import { ReduxPreDefinedTaskForm } from "../../clientChartTabs/forms/assignPreDefinedTaskForm";
import { ReduxTaskForm } from "../../clientChartTabs/forms/assignTaskForm";
import { DueDateForm } from "../../clientChartTabs/forms/dueDateForm";
import { TaskCard } from "../../../components/custom-cards";

export const TaskDrawer = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [taskD, setTaskD] = useState();
    const [taskCategory, setTaskCategory] = useState("preDefinedTask");
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const getLatestData = () => {
        dispatch(
            taskListGet({
                Task: [
                    {
                        ClientId: props.ID
                    }
                ]
            })
        );
    };
    useEffect(() => {
        getLatestData();
    }, []);
    const { taskData, isCompleted, isDeleted } = useSelector(
        ({
            task_list_get: { data: listData },
            task_unassign: { data: unassignData },
            task_mark_complete: { data: completedData }
        }) => {
            return {
                taskData: listData?.Data?.Tasks,
                isCompleted: completedData?.ResponseCode === 1,
                isDeleted: unassignData?.ResponseCode === 1
            };
        },
        shallowEqual
    );
    useEffect(() => {
        if (isCompleted) {
            resetTaskMarkComplete();
            getLatestData();
        }
    }, [isCompleted]);
    useEffect(() => {
        if (isDeleted) {
            resetTaskUnAssign();
            getLatestData();
        }
    }, [isDeleted]);
    const editTask = values => {
        setTaskD(values);
        toggleDrawer("right", true, "dueDate")("no use");
    };
    const deleteTask = ({ ClientTaskId }) => {
        dispatch(
            taskUnAssign({
                Task: [
                    {
                        ClientTaskId
                    }
                ]
            })
        );
    };
    const markAsCompleteTask = ({ ClientTaskId }) => {
        dispatch(
            taskMarkComplete({
                Task: [
                    {
                        ClientTaskId
                    }
                ]
            })
        );
    };
    const toggleDrawer = (side, open, task) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [side]: open });
        setTaskCategory(task);
    };
    return (
        <>
            <div className={`card card-custom`}>
                {/* Head */}
                <div className="card-header border-1">
                    <h3>Task Drawer</h3>
                    <OverlayTrigger
                        overlay={<Tooltip id="close-tooltip">Close</Tooltip>}
                    >
                        <a
                            className="btn btn-icon btn-hover-light-primary btn-sm"
                            onClick={props.toggleDrawer("right", false, {})}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl(_Close)} />
                            </span>
                        </a>
                    </OverlayTrigger>
                </div>
                {/* Body */}
                <div className="card-body pt-5 drawer-course">
                    <div className="row task-drawer-section">
                        <div className="col-12">
                            <div className="d-flex justify-content-end">
                                <Button
                                    variant="outlined"
                                    color={"secondary"}
                                    className="mr-2 px-5"
                                >
                                    Filter
                                </Button>
                                <Button
                                    onClick={toggleDrawer(
                                        "right",
                                        true,
                                        "preDefinedTask"
                                    )}
                                    className="secondary mx-2"
                                    variant="contained"
                                    color="secondary"
                                >
                                    + Assign Pre-defined Task
                                </Button>
                                <Button
                                    onClick={toggleDrawer(
                                        "right",
                                        true,
                                        "newTask"
                                    )}
                                    className="secondary mx-2"
                                    variant="contained"
                                    color="secondary"
                                >
                                    + Assign Task
                                </Button>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="survey-list-section mt-5 mb-5">
                                {taskData &&
                                    taskData.map((item, index) => {
                                        return (
                                            <TaskCard
                                                index={index}
                                                data={item}
                                                handleEditTask={editTask}
                                                handleDeleteTask={deleteTask}
                                                handleCompleteTask={
                                                    markAsCompleteTask
                                                }
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                        <Drawer
                            anchor="right"
                            open={drawerState.right}
                            onClose={toggleDrawer("right", false)}
                        >
                            <div role="presentation" style={{ width: "600px" }}>
                                {taskCategory == "preDefinedTask" && (
                                    <ReduxPreDefinedTaskForm
                                        side="right"
                                        ID={props.ID}
                                        toggleDrawer={toggleDrawer}
                                    />
                                )}
                                {taskCategory == "newTask" && (
                                    <ReduxTaskForm
                                        side="right"
                                        ID={props.ID}
                                        toggleDrawer={toggleDrawer}
                                    />
                                )}
                                {taskCategory == "dueDate" && (
                                    <DueDateForm
                                        side="right"
                                        ID={props.ID}
                                        taskD={taskD}
                                        // toggleDrawer={toggleDrawer}
                                    />
                                )}
                            </div>
                        </Drawer>
                    </div>
                </div>
            </div>
        </>
    );
};
