import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import API from "../requests";
import { ResponseHandler } from "./responseHandler";

export function* miscellaneousTaskGet(action) {
  try {
    const { payload } = action;
    const response = yield API.miscellaneousTaskGet(payload);
    yield put({
      type: ActionTypes.MISCELLANEOUS_TASK_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MISCELLANEOUS_TASK_GET_FAILURE,
    });
  }
}

export function* miscellaneousTaskListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.miscellaneousTaskListGet(payload);
    yield put({
      type: ActionTypes.MISCELLANEOUS_TASK_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MISCELLANEOUS_TASK_LIST_GET_FAILURE,
    });
  }
}

export function* miscellaneousTaskDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.miscellaneousTaskDelete(payload);
    yield put({
      type: ActionTypes.MISCELLANEOUS_TASK_DELETE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MISCELLANEOUS_TASK_DELETE_FAILURE,
    });
  }
}

export function* miscellaneousTaskUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.miscellaneousTaskUpsert(payload);
    yield put({
      type: ActionTypes.MISCELLANEOUS_TASK_UPSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MISCELLANEOUS_TASK_UPSERT_FAILURE,
    });
  }
}

export function* miscellaneousNoteTypeInsert(action) {
  try {
    const { payload } = action;
    const response = yield API.miscellaneousNoteTypeInsert(payload);
    yield put({
      type: ActionTypes.MISCELLANEOUS_NOTE_TYPE_INSERT_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MISCELLANEOUS_NOTE_TYPE_INSERT_FAILURE,
    });
  }
}

export function* miscellaneousComboNoteTypesListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.miscellaneousComboNoteTypesListGet(payload);
    yield put({
      type: ActionTypes.MISCELLANEOUS_COMBO_NOTE_TYPES_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MISCELLANEOUS_COMBO_NOTE_TYPES_LIST_GET_FAILURE,
    });
  }
}

export function* miscellaneousNoteTypeDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.miscellaneousNoteTypeDelete(payload);
    yield put({
      type: ActionTypes.MISCELLANEOUS_NOTE_TYPE_DELETE_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MISCELLANEOUS_NOTE_TYPE_DELETE_FAILURE,
    });
  }
}

export function* miscellaneousNoteTypesListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.miscellaneousNoteTypesListGet(payload);
    yield put({
      type: ActionTypes.MISCELLANEOUS_NOTE_TYPES_LIST_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MISCELLANEOUS_NOTE_TYPES_LIST_GET_FAILURE,
    });
  }
}

export function* miscellaneousNoteTypeGet(action) {
  try {
    const { payload } = action;
    const response = yield API.miscellaneousNoteTypeGet(payload);
    yield put({
      type: ActionTypes.MISCELLANEOUS_NOTE_TYPE_GET_SUCCESS,
      payload: ResponseHandler(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MISCELLANEOUS_NOTE_TYPE_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.MISCELLANEOUS_TASK_GET_REQUEST,
      miscellaneousTaskGet
    ),
    takeLatest(
      ActionTypes.MISCELLANEOUS_TASK_LIST_GET_REQUEST,
      miscellaneousTaskListGet
    ),
    takeLatest(
      ActionTypes.MISCELLANEOUS_TASK_DELETE_REQUEST,
      miscellaneousTaskDelete
    ),
    takeLatest(
      ActionTypes.MISCELLANEOUS_TASK_UPSERT_REQUEST,
      miscellaneousTaskUpsert
    ),
    takeLatest(
      ActionTypes.MISCELLANEOUS_NOTE_TYPE_INSERT_REQUEST,
      miscellaneousNoteTypeInsert
    ),
    takeLatest(
      ActionTypes.MISCELLANEOUS_COMBO_NOTE_TYPES_LIST_GET_REQUEST,
      miscellaneousComboNoteTypesListGet
    ),
    takeLatest(
      ActionTypes.MISCELLANEOUS_NOTE_TYPE_DELETE_REQUEST,
      miscellaneousNoteTypeDelete
    ),
    takeLatest(
      ActionTypes.MISCELLANEOUS_NOTE_TYPES_LIST_GET_REQUEST,
      miscellaneousNoteTypesListGet
    ),
    takeLatest(
      ActionTypes.MISCELLANEOUS_NOTE_TYPE_GET_REQUEST,
      miscellaneousNoteTypeGet
    ),
  ]);
}
