import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ tableColumns, rowData, FileName }) => {
  let dataField = [];
  let colHeader =
    tableColumns &&
    tableColumns.filter((item) => {
      if (item.csvExport && !item.hidden) {
        dataField.push(item.dataField);
        return item.text;
      }
    });

  return (
    <ExcelFile
      filename={FileName}
      element={
        <div className="excel-download d-flex align-items-center">
          <span className="mr-2">{"XLSX"}</span>
        </div>
      }
    >
      <ExcelSheet data={rowData} name={FileName}>
        {dataField.map((col) => {
          return <ExcelColumn label={col} value={col} />;
        })}
      </ExcelSheet>
    </ExcelFile>
  );
};
export default ExportExcel;
