import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuid } from "uuid";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import _RedCross from "../../images/icons/redCross.svg";
import _BlueCheck from "../../images/icons/blueCheck.svg";
import _Edit from "../../images/icons/edit.svg";
import _RedCrossIcon from "../../images/redCrossIcon.svg";
import _BlueCheckIcon from "../../images/blueCheckIcon.svg";
import _EditIcon from "../../images/editIcon.svg";
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
export const TaskCard = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const {
    data,
    handleEditTask,
    handleDeleteTask,
    handleCompleteTask,
    index,
  } = props;
  const {
    AssignedDate,
    AssignedDateForSort,
    ClientId,
    ClientImageUrl,
    ClientTaskId,
    DueDate,
    DueDateFontColor,
    DueDateForSort,
    DueDateString,
    FinishedDate,
    FirstName,
    LastName,
    ListPriority,
    RepeatDayOfWeek,
    RepeatFrequencyCode,
    TaskId,
    TaskStatus,
    Title,
    Detail,
    TodoType,
    UnassignDate,
  } = data || {};

  const taskStatus = {
    "-1": {
      color: "markAsUnassign",
      hideButtons: true,
      date: UnassignDate,
      name: "Unassigned",
    },
    "0": {
      color: "markAsDone",
      hideButtons: false,
      date: DueDateString,
      dateFontColor: DueDateFontColor,
      name: "Due Date",
    },
    "1": {
      color: "markAsInComplete",
      hideButtons: true,
      date: FinishedDate,
      name: "Completed",
    },
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="task-card" key={uuid()}>
      <div
        className={`row mt-5 mb-5 pt-5 pb-5 ${taskStatus[TaskStatus] &&
          taskStatus[TaskStatus].color}`}
      >
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12 date">{Title}</div>
            <p
              className="col-md-12 d-flex align-items-center mt-3"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div>
                <span className="time-label">Detail : </span>
                <span className="value"> {Detail}</span>
              </div>
            </p>
            <div
              className="col-md-12 d-flex align-items-center"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div>
                <span className="time-label">Assigned Date : </span>
                <span className="value"> {AssignedDate}</span>
              </div>
              <div>
                <span className="time-label">
                  {taskStatus[TaskStatus] && taskStatus[TaskStatus].name} :
                </span>
                <span className="value">
                  {" "}
                  {taskStatus[TaskStatus] && taskStatus[TaskStatus].date}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
          {taskStatus[TaskStatus] &&
            !taskStatus[TaskStatus].hideButtons &&
            handleEditTask && (
              <Tooltip title="Edit Due Date" aria-label="Edit Due Date">
                <Fab
                  color="secondary"
                  aria-label="Add"
                  variant="extended"
                  size="small"
                  className={classes.fab}
                  onClick={() => {
                    handleEditTask(data);
                  }}
                >
                  <SVG src={toAbsoluteUrl(_Edit)} />
                </Fab>
              </Tooltip>
            )}
          {taskStatus[TaskStatus] &&
            !taskStatus[TaskStatus].hideButtons &&
            handleCompleteTask && (
              <Tooltip title="Mark As Complete" aria-label="Mark As Complete">
                <Fab
                  color="secondary"
                  aria-label="markAsComplete"
                  variant="extended"
                  size="small"
                  className={classes.fab}
                  onClick={() => {
                    handleCompleteTask(data);
                  }}
                >
                  <SVG src={toAbsoluteUrl(_BlueCheck)} />
                </Fab>
              </Tooltip>
            )}
          {taskStatus[TaskStatus] &&
            !taskStatus[TaskStatus].hideButtons &&
            handleDeleteTask && (
              <Tooltip
                title="Mark As Incomplete"
                aria-label="Mark As Incomplete"
              >
                <Fab
                  color="secondary"
                  aria-label="markAsIncomplete"
                  variant="extended"
                  size="small"
                  className={classes.fab}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                >
                  <SVG src={toAbsoluteUrl(_RedCross)} />
                </Fab>
              </Tooltip>
            )}
        </div>

        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Mark as Incomplete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to mark this task as incomplete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                handleDeleteTask(data);
                handleClose();
              }}
              color="primary"
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
