import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";

import _Close from "../../../images/close.svg";

// import { Popover, Cascader, Icon, Checkbox, Table } from "antd";
// import TaskCompleted from "./customScreens/TaskCompleted";
// import SurveyCompleted from "./customScreens/SurveyCompleted";
// import TeleHealth from "./customScreens/TeleHealth";
// import ProfilePictureChange from "./customScreens/ProfilePictureChange";
// import Friend from "./customScreens/Friend";
// import BreathalyzerTest from "./customScreens/BreathalyzerTest";
// import TriggerAdded from "./customScreens/TriggerAdded";
// import Mood from "./customScreens/Mood";
// import PanasScale from "./customScreens/PanasScale";
// import Post from "./customScreens/Post";
// import ForbiddenWords from "./customScreens/ForbiddenWords";
// import Chat from "./customScreens/Chat";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export const EventViewDrawer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom page");
  //   useEffect(() => {
  //     dispatch(adminstrationGetCourse({ CourseId: props.CourseId }));
  //   }, []);
  const { toggleEventDrawer } = props;
  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <h3> {"EventTitle"}</h3>
          <span onClick={props.toggleEventDrawer("right", false)}>
            <img
              src={_Close}
              alt="Close"
              style={{ cursor: "pointer", alignSelf: "baseline" }}
            />
          </span>
        </div>
        {/* Body */}
        <div className="card-body pt-5 drawer-filter">
          <div className="row">
            <div className="col-12">hello</div>
          </div>
        </div>
      </div>
    </>
  );
};
