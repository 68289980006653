import { listSmartCombos, listFilterCombo } from './actions';

export const COMBOS_LOADED = {
  Center: { alwaysLoaded: true },
  StateProvince: { alwaysLoaded: true },
  Staff: { alwaysLoaded: true },
  RCM_ServiceCode: { alwaysLoaded: true },
  RCM_PayerInsurance: { alwaysLoaded: true },
};

export const ProcessDynamicCombo = ({ json }) => {};

export const ProcessFilterCombos = ({ combo, json, ...rest }) => {
  return listFilterCombo({
    comboTypes: combo,
    json: json,
    ...rest,
  });
};

export const ProcessCombos = comboItems => {
  var combos = [],
    isProcess = false,
    processItems = '';
  comboItems.split(',').forEach(item => {
    if (COMBOS_LOADED[item]) {
      if (COMBOS_LOADED[item].alwaysLoaded) {
        combos.push(item);
      } else {
        if (!COMBOS_LOADED[item].isLoaded) {
          combos.push(item);
        }
      }
    } else {
      combos.push(item);
    }
  });

  if (combos.length > 0) {
    isProcess = true;
    processItems = listSmartCombos({
      comboTypes: combos.join(','),
    });
  }

  return {
    isProcess,
    processItems,
  };
};
