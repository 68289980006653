import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
    DropdownCustomToggler,
    DropdownMenu1,
    DropdownMenu2
} from "../../../../_metronic/_partials/dropdowns";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../../_metronic/layout";
import {
    getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses
} from "../../../../_metronic/_helpers";
import { userSuspiciousWordGet } from "../../../../redux/actions";
import { MemberTrendsFormatter } from "../../column-formatters";
// import { Chat } from "../../custom-component/Chating";
// import { TwilioCalling } from "../../custom-component/TwilioCalling";
import _blank from "../../../images/blank-profile.png";
import _Phone from "../../../images/twilioPhoneAllowed.svg";
import _Chat from "../../../images/bi_chat.svg";

const { SearchBar, ClearSearchButton } = Search;

// const list = [
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
//   { taskName: "Create FireStone Logo", taskDuration: "Due in 2 Days" },
// ];
const list = [
    {
        UserGuid: "4B5FC37C-E073-46A3-9346-C1B39918877F",
        DisplayName: "Chanchal Test Dev",
        ProfileImgUrl: "",
        Context: "Chat",
        ForbiddenWord: "X",
        WordUsedDateTime: "2022-02-22T11:15:44.890",
        WordUsedDate: "2022-02-22",
        WordUsedTime: "4:45pm",
        icon: "Create FireStone Logo",
        program: "Detox",
        tags: ["With care team", "Subscriber"],
        msg: "Was at a trigger location",
        flag: "Alcohol"
    }
];

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    },
    input: {
        display: "none"
    }
}));

export const SuspiciousWords = className => {
    const dispatch = useDispatch();
    const history = useHistory();
    const columns = [
        {
            dataField: "DisplayName",
            text: "Members",
            sort: enableSorting,
            sortCaret: sortCaret,
            headerSortingClasses: headerSortingClasses,
            formatter: MemberTrendsFormatter
        },
        {
            dataField: "UsedFrom",
            text: "Used In",
            style: {
                minWidth: "80px"
            }
            // sort: enableSorting,
            // sortCaret: sortCaret,
            // headerSortingClasses: headerSortingClasses,
        },
        {
            dataField: "WordUsed",
            text: "Word Used"
            // sort: enableSorting,
            // sortCaret: sortCaret,
            // headerSortingClasses: headerSortingClasses,
        },
        {
            dataField: "",
            text: "Actions",
            // sort: false,
            // sortCaret: sortCaret,
            // headerSortingClasses: headerSortingClasses,
            // formatter: actionFormatter,
            // formatter: (cell, row) => {
            //   debugger;
            //   return <div className="d-flex align-items-center"></div>;
            // },
            // formatExtraData: {
            //   //   openEditProductPage: productsUIProps.openEditProductPage,
            //   //   openDeleteProductDialog: productsUIProps.openDeleteProductDialog,
            // },
            // classes: "text-right pr-0",
            // headerClasses: "text-right pr-3",
            style: {
                minWidth: "50px"
            }
        }
        // "Actions": [
        //   {
        //     Phone: "",
        //     chat: "",
        //     More: [
        //       {
        //         ActionName: "Schedule Appointment",
        //       },
        //       {
        //         ActionName: "Assign Task",
        //       },
        //       {
        //         ActionName: "Assign Survey",
        //       },
        //       {
        //         ActionName: "Assign Course",
        //       },
        //       {
        //         ActionName: "Add Note",
        //       },
        //       {
        //         ActionName: "Share Client",
        //       }
        //     ],
        //   }
        // ],
    ];
    const classes = useStyles();
    const suhbeader = useSubheader();
    suhbeader.setTitle("Dashboard");

    useEffect(() => {
        dispatch(userSuspiciousWordGet({}));
    }, []);

    const { userSuspiciousWordGetData } = useSelector(
        ({ user_suspicious_word_get }) => {
            return {
                userSuspiciousWordGetData:
                    (user_suspicious_word_get.data &&
                        user_suspicious_word_get.data.SuspiciousWord) ||
                    []
            };
        },
        shallowEqual
    );
    const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
        const { More, Phone, chat } = row.Actions[0];
        return (
            <div className="d-flex align-items-center justify-content-around">
                <div className="">
                    {/* <TwilioCalling row={row} /> */}
                    <div className="">
                        <img
                            style={{ cursor: "pointer", display: "block" }}
                            src={_Phone}
                            alt="Calling Allowed"
                            width="15px"
                        />{" "}
                    </div>
                </div>
                <div className="">
                    {/* <Chat row={row} /> */}
                    <div className="">
                        <img
                            style={{ cursor: "pointer", display: "block" }}
                            src={_Chat}
                            alt="Chat"
                            width="15px"
                        />{" "}
                    </div>
                </div>
                <div className="">
                    <ItemDropdown item="MoreAction" />
                </div>
            </div>
        );
    };

    const enableSorting = true;

    return (
        <>
            <div className={`card card-custom ${className}`}>
                {/* Head */}
                {/* <div className="card-header border-0"></div> */}
                {/* Body */}
                <div className="card-body pt-5 member-trend">
                    {userSuspiciousWordGetData && (
                        <ToolkitProvider
                            keyField="id"
                            data={userSuspiciousWordGetData || []}
                            columns={columns}
                            search
                        >
                            {props => (
                                <div>
                                    <SearchBar
                                        {...props.searchProps}
                                        className="custome-search-field"
                                        style={{ color: "black" }}
                                        delay={500}
                                        placeholder="Search"
                                    />
                                    {/* <ClearSearchButton {...props.searchProps} /> */}
                                    <BootstrapTable
                                        wrapperClasses="table-responsive member-trend-table"
                                        classes="table table-head-custom table-vertical-center overflow-hidden"
                                        bootstrap4={true}
                                        bordered={false}
                                        rowStyle={{
                                            backgroundColor: "#F7F8FA",
                                            margin: "12px 0px",
                                            boxShadow:
                                                "0px 0px 4px rgba(61, 61, 69, 0.1)",
                                            borderRadius: "2px"
                                        }}
                                        // hover
                                        // defaultSorted={defaultSorted}
                                        {...props.baseProps}
                                        // style={{ height: "365px" }}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    )}
                </div>
            </div>
        </>
    );
};

const ItemDropdown = ({ item }) => {
    return (
        <>
            <Dropdown className="dropdown-inline" alignRight>
                <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-toggle-top"
                    className="btn btn-hover-light-primary btn-sm btn-icon"
                    as={DropdownCustomToggler}
                >
                    <i className="ki ki-bold-more-ver" />
                </Dropdown.Toggle>
                {/* <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu> */}
            </Dropdown>
        </>
    );
};
