import React from "react";
export const RedGreenBGFormatter = (cellContent, row) => {
  return (
    <>
      {cellContent && (
        <span
          style={
            row.IsRedColor
              ? {
                  color: "#FF2C2C",
                  padding: "2px 4px",
                  background: "#F6E4E5",
                }
              : {
                  color: "#00DE09",
                  padding: "2px 4px",
                  background: "#EAFCF4",
                }
          }
        >
          {cellContent}
        </span>
      )}
    </>
  );
};
