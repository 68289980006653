import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import _EN from "../../../images/en.svg";
import _EST from "../../../images/est.svg";
import _Enabled from "../../../images/enabled.svg";
import _Badge from "../../../images/badge.svg";
import _blank from "../../../images/blank-profile.png";

import { useSubheader } from "../../../../_metronic/layout";
import { getUserInfo } from "../../../../redux/actions";
import { getClientId } from "../../../util/getParam";
const UserInfoComponent = (props) => {
  const {
    Age,
    Badge,
    PreferredName,
    Gender,
    ProfileImg,
    Phone,
    Service,
    ServiceTags,
    SubscribeStatus,
    TrendLevel,
  } = props.clientData || {};
  const { Notification, TimeZone, Language } = props.UserInfo || {};
  return (
    <>
      <div className="card card-custom w-100">
        <div className="card-body p-5">
          <div className="user-basic-profile row">
            <div className="col-12">
              <div className="user-avatar">
                <div
                  className={
                    "pic-circle-arrow-down"
                    // TrendLevel == 0
                    //   ? "pic-circle-neutral"
                    //   : TrendLevel == 1
                    //   ? "pic-circle-arrow-up"
                    //   : "pic-circle-arrow-down"
                  }
                >
                  <div class="student-list-pic" style={{ flex: "0 0 29%" }}>
                    <img
                      src={ProfileImg || _blank}
                      // alt="student"
                      class="img-circle"
                      height="40"
                      width="40"
                      style={{
                        borderRadius: "50%",
                        padding: "2px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="user-basic-detail">
                <div className="basic-detail">
                  <div>
                    <span className="name">{PreferredName}</span>
                    <span className="badge">
                      <img src={_Badge} className="mr-2" />
                      {Badge}
                    </span>
                  </div>
                  <div className="d-flex mb-3 justify-content-center">
                    <div className="mr-2">
                      <span className="gender">{Gender},</span>
                      <span className="age"> {Age}</span>
                    </div>
                    <div>
                      <span className="phone">{Phone}</span>
                    </div>
                  </div>
                </div>
                <div className="user-services mb-3">
                  <span className="tag">{Service}</span>
                  <span className="tag">{ServiceTags}</span>
                  <span className="tag">{SubscribeStatus}</span>
                </div>
                <div className="user-notification">
                  <span className="settings">
                    <img src={_Enabled} className="mr-2" />
                    {Notification}
                  </span>
                  <span className="settings">
                    <img src={_EST} className="mr-2" />
                    {TimeZone}
                  </span>
                  <span className="settings">
                    <img src={_EN} className="mr-2" />
                    {Language}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const UserStoryCompnent = (props) => {
  return (
    <>
      {" "}
      <div className="card card-custom mt-5">
        <div className="card-body p-5">
          <h1 className="mb-5" style={{ fontSize: "16px" }}>
            Story
          </h1>
          <p
            style={{
              fontSize: "14px",
              // textAlign: "justify"
            }}
          >
            {props.MyStory || ""}
          </p>
        </div>
      </div>
    </>
  );
};

const UserDetailCompnent = (props) => {
  const {
    FullName,
    Advisor,
    AppJoiningDate,
    NotificationLastDelivered,
    LocationLastReceived,
    LastOpenedDate,
    Location,
    CurrentSubscription,
    LegalName,
    LocationEnabled,
  } = props.Details;

  const data = [
    {
      label: "Case Id",
      value: "Not available",
    },
    {
      label: "Legal Name",
      value: LegalName,
    },
    {
      label: "Advisor",
      value: Advisor,
    },
    {
      label: "Current Subscription",
      value: CurrentSubscription,
    },
    {
      label: "Program",
      value: "Not available",
    },
    {
      label: "Location Sharing",
      value: LocationEnabled,
    },
    {
      label: "App Joining Date",
      value: AppJoiningDate,
    },
    {
      label: "Last Notification",
      value: NotificationLastDelivered,
    },
    {
      label: "Location Last Recieved",
      value: LocationLastReceived,
    },
    {
      label: "App Last Opened Date",
      value: LastOpenedDate,
    },
  ];
  return (
    <>
      <div className="card card-custom mt-5">
        <div className="card-body p-5">
          <h1 className="mb-5" style={{ fontSize: "16px" }}>
            Details
          </h1>
          <div className="row profile-section-details">
            {data &&
              data.map((item) => {
                const { label, value } = item;
                return (
                  <div className="col-lg-12 col-xxl-6" key={uuid()}>
                    <div className="profile-section-label">{label}</div>
                    {value && (
                      <div className="profile-section-label-value">{value}</div>
                    )}
                  </div>
                );
              })}

            <div className="col-12">
              <div className="profile-section-label">Location</div>
              {Location && (
                <div className="profile-section-label-value">{Location}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const UserStatesCompnent = (props) => {
  const { DeviceName, DeviceType, AppVersion } = props.Stats || {};
  const data = [
    {
      label: "Device Type",
      value: DeviceType,
    },
    {
      label: "Device OS Version",
      value: DeviceName,
    },
    {
      label: "App Version",
      value: AppVersion,
    },
  ];
  return (
    <>
      <div className="card card-custom mt-5">
        <div className="card-body p-5">
          <h1 className="mb-5" style={{ fontSize: "16px" }}>
            Stats
          </h1>
          <div className="row profile-section-Stats">
            {data &&
              data.map((item) => {
                const { label, value } = item;
                return (
                  <div className="col-lg-12 col-xl-6 col-xxl-4" key={uuid()}>
                    <div className="profile-section-label">{label}</div>
                    {value && (
                      <div className="profile-section-label-value">{value}</div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

const ProfileSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let data = {
      Client: [
        {
          // ClientId: 100000491,
          ClientId: getClientId(),
        },
      ],
    };

    dispatch(getUserInfo(data));
  }, []);

  const { clientData } = useSelector(
    ({ get_user_info: { data, isFetching, error } }) => {
      const { Data, Message } = data || {};
      return {
        clientData: Data && Data.ClientData && Data.ClientData[0],
      };
    },
    shallowEqual
  );

  return (
    <>
      {clientData && (
        <div>
          {clientData && (
            <UserInfoComponent
              clientData={clientData}
              UserInfo={clientData.UserInfo && clientData.UserInfo[0]}
            />
          )}

          {clientData.MyStory && (
            <UserStoryCompnent MyStory={clientData.MyStory} />
          )}

          {clientData.Details && clientData.Details[0] && (
            <UserDetailCompnent
              Details={clientData.Details && clientData.Details[0]}
            />
          )}

          {clientData.Stats && clientData.Stats[0] && (
            <UserStatesCompnent
              Stats={clientData.Stats && clientData.Stats[0]}
            />
          )}
        </div>
      )}
    </>
  );
};
export default ProfileSection;
