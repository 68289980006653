import { combineReducers } from "redux";
import { ReduxGenerator } from "../../core";
import { reducer as form } from "redux-form";
import actionsSave from "./actionsSave";

import Combo from "./combo";
import lastUpdatedDimensionId from "./lastUpdatedTreatmentPlan";

import * as Counter from "./Counter";
import * as WeatherForecasts from "./WeatherForecasts";
import * as featchData from "./FeatchUserData";

import * as auth from "../../app/modules/Auth/_redux/authRedux";

const rootReducer = combineReducers({
  combos: Combo,
  auth: auth.reducer,
  lastUpdatedDimensionId: lastUpdatedDimensionId,
  display_error: ReduxGenerator.getReducer("DISPLAY_ERROR"),
  dashboard_get: ReduxGenerator.getReducer("DASHBOARD_GET"),
  students: ReduxGenerator.getReducer("STUDENTS"),
  student_add: ReduxGenerator.getReducer("STUDENT_ADD"),
  student_note_add: ReduxGenerator.getReducer("STUDENT_NOTE_ADD"),
  student_couse_assign: ReduxGenerator.getReducer("STUDENT_COURSE_ASSIGN"),
  student_pin_upsert: ReduxGenerator.getReducer("STUDENT_PIN_UPSERT"),
  student_validate_pin: ReduxGenerator.getReducer("STUDENT_VALIDATE_PIN"),
  student_mark_complete: ReduxGenerator.getReducer("STUDENT_MARK_COMPLETE"),
  student_unassign: ReduxGenerator.getReducer("STUDENT_UNASSIGN"),
  student_get: ReduxGenerator.getReducer("STUDENT_GET"),
  student_discharge: ReduxGenerator.getReducer("STUDENT_DISCHARGE"),
  student_reinstate: ReduxGenerator.getReducer("STUDENT_REINSTATE"),
  forget_password: ReduxGenerator.getReducer("FORGET_PASSWORD"),
  reset_password: ReduxGenerator.getReducer("RESET_PASSWORD"),
  counter: Counter.reducer,
  weatherForecasts: WeatherForecasts.reducer,
  featchuser: featchData.featchReducer,
  todos: ReduxGenerator.getReducer("TODO_GET_ALL"),
  todos_by_student: ReduxGenerator.getReducer("TODO_GET_ALL_BY_STUDENT"),
  activities: ReduxGenerator.getReducer("ACTIVITY_GET_ALL"),
  activities_by_student: ReduxGenerator.getReducer(
    "ACTIVITY_GET_ALL_BY_STUDENT"
  ),
  todo_add: ReduxGenerator.getReducer("TODO_ADD"),
  get_report_data: ReduxGenerator.getReducer("GET_REPORT_DATA"),
  log_device: ReduxGenerator.getReducer("LOG_DEVICE"),
  log_error: ReduxGenerator.getReducer("LOG_ERROR"),
  form,
  announcement_get_all: ReduxGenerator.getReducer("ANNOUNCEMENT_GET_ALL"),
  announcement_get_json: ReduxGenerator.getReducer("ANNOUNCEMENT_GET_JSON"),
  announcement_upsert: ReduxGenerator.getReducer("ANNOUNCEMENT_UPSERT"),
  announcement_delete: ReduxGenerator.getReducer("ANNOUNCEMENT_DELETE"),
  menu: ReduxGenerator.getReducer("LOAD_MENU"),
  logout: ReduxGenerator.getReducer("LOGOUT"),
  student_get_profile_dashboard_data: ReduxGenerator.getReducer(
    "STUDENT_GET_PROFILE_DASHBOARD_DATA"
  ),
  student_search: ReduxGenerator.getReducer("STUDENTS_SEARCH"),
  user_role_list: ReduxGenerator.getReducer("USER_ROLE_LIST"),
  list_job_title: ReduxGenerator.getReducer("LIST_JOB_TITLE"),
  update_job_title: ReduxGenerator.getReducer("UPDATE_JOB_TITLE"),
  update_role: ReduxGenerator.getReducer("UPDATE_ROLE"),
  delete_role: ReduxGenerator.getReducer("DELETE_ROLE"),
  delete_job_title: ReduxGenerator.getReducer("DELETE_JOB_TITLE"),
  update_role_permissions: ReduxGenerator.getReducer("UPDATE_ROLE_PERMISSIONS"),
  update_job_title_sync: ReduxGenerator.getReducer("UPDATE_JOB_TITLE_SYNC"),
  user_feedback: ReduxGenerator.getReducer("USER_FEEDBACK"),
  is_license_available: ReduxGenerator.getReducer("IS_LICENSE_AVAILABLE"),
  get_dashboard_load_app_users: ReduxGenerator.getReducer(
    "GET_DASHBOARD_LOAD_APP_USERS"
  ),
  get_validate_image: ReduxGenerator.getReducer("GET_VALIDATE_IMAGE"),
  get_license_activites: ReduxGenerator.getReducer("GET_LICENSE_ACTIVITES"),
  get_current_licenses: ReduxGenerator.getReducer("GET_CURRENT_LICENSES"),
  get_licenses_invoice: ReduxGenerator.getReducer("GET_LICENSES_INVOICE"),
  get_licenses_receipt: ReduxGenerator.getReducer("GET_LICENSES_RECEIPT"),
  add_licenses: ReduxGenerator.getReducer("ADD_LICENSES"),
  get_payment_info: ReduxGenerator.getReducer("GET_PAYMENT_INFO"),
  is_ach_validation: ReduxGenerator.getReducer("IS_ACH_VALIDATION"),
  get_billing_info: ReduxGenerator.getReducer("GET_BILLING_INFO"),
  update_payment_methode: ReduxGenerator.getReducer("UPDATE_PAYMENT_METHODE"),
  ach_validation: ReduxGenerator.getReducer("ACH_VALIDATE"),
  add_new_applicant: ReduxGenerator.getReducer("ADD_NEW_APPLICANT"),
  get_app_subscribers: ReduxGenerator.getReducer("GET_APP_SUBSCRIBERS"),
  get_app_subscribers_dashboard: ReduxGenerator.getReducer(
    "GET_APP_SUBSCRIBERS_DASHBOARD"
  ),
  get_app_subscribers_profile: ReduxGenerator.getReducer(
    "GET_APP_SUBSCRIBERS_PROFILE"
  ),
  get_app_subscribers_chat: ReduxGenerator.getReducer(
    "GET_APP_SUBSCRIBERS_CHAT"
  ),
  get_app_subscribers_chat_coach: ReduxGenerator.getReducer(
    "GET_APP_SUBSCRIBERS_CHAT_COACH"
  ),
  get_app_subscribers_activities: ReduxGenerator.getReducer(
    "GET_APP_SUBSCRIBERS_ACTIVITIES"
  ),
  add_app_subscribers_note: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_INSERT_NOTE"
  ),
  add_app_subscribers_task: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_ADD_TASK"
  ),
  delete_app_subscribers_task: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_DELETE_TASK"
  ),
  app_subscribers_user_search: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_USER_SEARCH"
  ),
  app_subscribers_complete_task: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_COMPLETE_TASK"
  ),
  update_app_subscribers_case: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_UPDATE_CASE"
  ),
  list_mobile_appRole_get: ReduxGenerator.getReducer(
    "GET_LIST_MOBILE_APP_ROLE"
  ),
  sp_list_combos: ReduxGenerator.getReducer("SP_LIST_COMBOS"),
  app_subscribers_map_history: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_MAP_HISTORY"
  ),
  app_invite: ReduxGenerator.getReducer("APP_INVITE"),
  get_mobile_app_download_status: ReduxGenerator.getReducer(
    "GET_MOBILE_APP_DOWNLOAD_STATUS"
  ),
  update_mobile_app_role: ReduxGenerator.getReducer("UPDATE_MOBILE_APP_ROLE"),
  add_note_type: ReduxGenerator.getReducer("ADD_NOTE_TYPE"),
  change_app_role: ReduxGenerator.getReducer("CHANGE_APP_ROLE"),
  two_step_auth: ReduxGenerator.getReducer("TWO_STEP_AUTH"),
  validate_passcode: ReduxGenerator.getReducer("VALIDATE_PASS_CODE"),
  app_invite_dashboard: ReduxGenerator.getReducer("APP_INVITE_DASHBOARD"),
  get_all_call_session: ReduxGenerator.getReducer("GET_ALL_CALL_SESSION"),
  add_call_session: ReduxGenerator.getReducer("ADD_CALL_SESSION"),
  get_all_vacation: ReduxGenerator.getReducer("GET_ALL_VACATION"),
  add_vacation_days: ReduxGenerator.getReducer("ADD_VACATION_DAYS"),
  get_one_call_timeSlots: ReduxGenerator.getReducer("GET_ONE_CALL_TIMESLOTS"),
  get_one_call_session: ReduxGenerator.getReducer("GET_ONE_CALL_SESSION"),
  invite_one_call_session: ReduxGenerator.getReducer("INVITE_ONE_CALL_SESSION"),
  cancel_one_call_session: ReduxGenerator.getReducer("CANCEL_ONE_CALL_SESSION"),
  get_stripe_api_key: ReduxGenerator.getReducer("GET_STRIPE_API_KEY"),
  edit_group_call_session: ReduxGenerator.getReducer("EDIT_GROUP_CALL_SESSION"),
  get_call_session_video: ReduxGenerator.getReducer("GET_CALL_SESSION_VIDEO"),
  get_call_session_video_url: ReduxGenerator.getReducer(
    "GET_CALL_SESSION_VIDEO_URL"
  ),
  get_reports: ReduxGenerator.getReducer("GET_REPORTS"),
  get_promo_code: ReduxGenerator.getReducer("GET_PROMO_CODE"),

  get_counselor: ReduxGenerator.getReducer("GET_COUNSELOR"),
  get_client_counselor: ReduxGenerator.getReducer("GET_CLIENT_COUNSELOR"),
  get_dynamic_combos: ReduxGenerator.getReducer("COMBOS_DYNAMIC"),
  send_voucher_code: ReduxGenerator.getReducer("SEND_VOUCHER_CODE"),
  get_small_group_users: ReduxGenerator.getReducer("GET_SMALL_GROUP_USERS"),
  upload_leader_photo: ReduxGenerator.getReducer("UPLOAD_LEADER_PHOTO"),
  mark_system_observation: ReduxGenerator.getReducer("MARK_SYSTEM_OBSERVATION"),
  app_mark_system_observation: ReduxGenerator.getReducer(
    "APP_MARK_SYSTEM_OBSERVATION"
  ),
  get_Analytics: ReduxGenerator.getReducer("GET_ANALYTICS"),
  get_active_client: ReduxGenerator.getReducer("GET_ACTIVE_CLIENT"),
  is_manage_license_required: ReduxGenerator.getReducer(
    "IS_MANAGE_LICENSE_REQUIRED"
  ),
  get_notes: ReduxGenerator.getReducer("GET_NOTES"),
  get_category_client: ReduxGenerator.getReducer("GET_CATEGORY_CLIENT"),
  get_client_demographic: ReduxGenerator.getReducer("GET_CLIENT_DEMOGRAPHIC"),
  get_cost_of_care: ReduxGenerator.getReducer("GET_COST_OF_CARE"),
  get_behavioral_rating: ReduxGenerator.getReducer("GET_BEHAVIORAL_RATING"),
  get_score_improvement: ReduxGenerator.getReducer("GET_SCORE_IMPROVEMENT"),
  get_provider_performance: ReduxGenerator.getReducer(
    "GET_PROVIDER_PERFORMANCE"
  ),
  get_Trigger_Location_Activity: ReduxGenerator.getReducer(
    "GET_TRIGGER_LOCATION"
  ),
  get_Service_Delivered: ReduxGenerator.getReducer("GET_SERVICE_DELIVERED"),
  get_referral_source: ReduxGenerator.getReducer("GET_REFERRAL_SOURCE"),
  get_student_document_list: ReduxGenerator.getReducer("GET_DOCUMENT_LIST"),
  archive_student_document: ReduxGenerator.getReducer(
    "ARCHIVE_STUDENT_DOCUMENT"
  ),
  upload_document: ReduxGenerator.getReducer("UPLOAD_DOCUMENT"),
  download_student_document: ReduxGenerator.getReducer("DOWNLOAD_DOCUMENT"),
  actions_save: actionsSave,
  news_upsert: ReduxGenerator.getReducer("NEWS_UPSERT"),
  get_list_news: ReduxGenerator.getReducer("GET_LIST_NEWS"),
  get_news: ReduxGenerator.getReducer("GET_NEWS"),
  delete_news: ReduxGenerator.getReducer("DELETE_NEWS"),
  get_embed_info: ReduxGenerator.getReducer("GET_EMBED_INFO"),
  get_patient_list: ReduxGenerator.getReducer("GET_CLIENT_LIST"),
  get_patient_detail: ReduxGenerator.getReducer("GET_CLIENT_DETAIL"),
  upsert_patient_enrollment: ReduxGenerator.getReducer(
    "UPSERT_PATIENT_ENROLLMENT"
  ),
  upsert_client_insurance: ReduxGenerator.getReducer("UPSERT_CLIENT_INSURANCE"),
  upsert_client_medical: ReduxGenerator.getReducer("UPSERT_CLIENT_MEDICAL"),
  upsert_client_payment: ReduxGenerator.getReducer("UPSERT_CLIENT_PAYMENT"),
  upsert_provider_enrollment: ReduxGenerator.getReducer(
    "UPSERT_PROVIDER_ENROLLMENT"
  ),
  upsert_appointment: ReduxGenerator.getReducer("UPSERT_APPOINTMENT"),
  list_appointment: ReduxGenerator.getReducer("LIST_APPOINTMENT"),
  get_appointment: ReduxGenerator.getReducer("GET_APPOINTMENT"),
  delete_appointment: ReduxGenerator.getReducer("DELETE_APPOINTMENT"),
  medicate_combos: ReduxGenerator.getReducer("COMBOS_MEDICATE"),
  practitioner_combos: ReduxGenerator.getReducer("COMBOS_PRACTITIONER"),
  practitioner_combos_office: ReduxGenerator.getReducer(
    "COMBOS_PRACTITIONER_OFFICE"
  ),
  upsert_group: ReduxGenerator.getReducer("UPSERT_GROUP"),
  list_group: ReduxGenerator.getReducer("LIST_GROUP"),
  get_group: ReduxGenerator.getReducer("GET_GROUP"),
  delete_group: ReduxGenerator.getReducer("DELETE_GROUP"),
  upsert_client_group: ReduxGenerator.getReducer("UPSERT_CLIENT_GROUP"),
  get_client_group: ReduxGenerator.getReducer("GET_CLIENT_GROUP"),
  upsert_group_appointment: ReduxGenerator.getReducer(
    "UPSERT_GROUP_APPOINTMENT"
  ),
  upsert_group_appointment_group_all_info_json: ReduxGenerator.getReducer(
    "UPSERT_GROUP_APPOINTMENT_GROUP_ALL_INFO_JSON"
  ),
  get_list_client_group: ReduxGenerator.getReducer("GET_LIST_CLIENT_GROUP"),
  patient_combo_group: ReduxGenerator.getReducer("PATIENT_COMBO_GROUP"),
  deactivate_group_appointment: ReduxGenerator.getReducer(
    "DEACTIVATE_GROUP_APPOINTMENT"
  ),
  upsert_client_consent: ReduxGenerator.getReducer("UPSERT_CLIENT_CONSENT"),
  list_client_consent: ReduxGenerator.getReducer("LIST_CLIENT_CONSENT"),
  deactivate_client_consent: ReduxGenerator.getReducer(
    "DEACTIVATE_CLIENT_CONSENT"
  ),
  deactivate_client_screening: ReduxGenerator.getReducer(
    "DEACTIVATE_CLIENT_SCREENING"
  ),
  load_judicial_dashboard: ReduxGenerator.getReducer("LOAD_JUDICIAL_DASHBOARD"),
  get_judicial_user_list: ReduxGenerator.getReducer("GET_JUDICIAL_USER_LIST"),
  upload_document_using_multiPart: ReduxGenerator.getReducer(
    "UPLOAD_DOCUMENT_USING_MULTIPART"
  ),
  upsert_client_document: ReduxGenerator.getReducer("UPSERT_CLIENT_DOCUMENT"),
  list_client_document: ReduxGenerator.getReducer("LIST_CLIENT_DOCUMENT"),
  download_client_document: ReduxGenerator.getReducer(
    "DOWNLOAD_CLIENT_DOCUMENT"
  ),
  archive_client_document: ReduxGenerator.getReducer("ARCHIVE_DOCUMENT"),
  list_group_membership: ReduxGenerator.getReducer("LIST_GROUP_MEMBERSHIP"),
  download_client_consent: ReduxGenerator.getReducer("DOWNLOAD_CLIENT_CONSENT"),
  combo_practitioner_all: ReduxGenerator.getReducer("COMBO_PRACTITIONER_ALL"),
  combo_facility_all: ReduxGenerator.getReducer("COMBO_FACILITY_ALL"),
  save_client_consent: ReduxGenerator.getReducer("SAVE_CLIENT_CONSENT"),
  get_treatment_plan: ReduxGenerator.getReducer("GET_TREATMENT_PLAN"),
  get_diagnosis: ReduxGenerator.getReducer("GET_DIAGNOSIS"),
  upsert_treatment_plan_problem: ReduxGenerator.getReducer(
    "UPSERT_TREATMENT_PLAN_PROBLEM"
  ),
  upsert_medical_analysis: ReduxGenerator.getReducer("UPSERT_MEDICAL_ANALYSIS"),
  upsert_treatment_plan_goal: ReduxGenerator.getReducer(
    "UPSERT_TREATMENT_PLAN_GOAL"
  ),
  upsert_treatment_plan_objective: ReduxGenerator.getReducer(
    "UPSERT_TREATMENT_PLAN_OBJECTIVE"
  ),
  upsert_treatment_plan_intervention: ReduxGenerator.getReducer(
    "UPSERT_TREATMENT_PLAN_INTERVENTION"
  ),
  delete_treatment_plan: ReduxGenerator.getReducer("DELETE_TREATMENT_PLAN"),
  get_diagnosis_description: ReduxGenerator.getReducer(
    "GET_DIAGNOSIS_DESCRIPTION"
  ),
  get_diagnosis_code: ReduxGenerator.getReducer("GET_DIAGNOSIS_CODE"),
  combo_problem_template: ReduxGenerator.getReducer("COMBO_PROBLEM_TEMPLATE"),
  combo_goal_template: ReduxGenerator.getReducer("COMBO_GOAL_TEMPLATE"),
  list_treatment_plan: ReduxGenerator.getReducer("LIST_TREATMENT_PLAN"),
  recurring_count: ReduxGenerator.getReducer("RECURRING_COUNT"),
  load_case_profile: ReduxGenerator.getReducer("LOAD_CASE_PROFILE"),
  load_location_auth_history: ReduxGenerator.getReducer(
    "LOAD_LOCATION_AUTH_HISTORY"
  ),
  screen_authorization: ReduxGenerator.getReducer("SCREEN_AUTHORIZATION"),
  list_consent_form: ReduxGenerator.getReducer("LIST_CONSENT_FORM"),
  upsert_treatment_plan_dimension: ReduxGenerator.getReducer(
    "UPSERT_TREATMENT_PLAN_DIMENSION"
  ),
  save_consent_sign: ReduxGenerator.getReducer("SAVE_CONSENT_SIGN"),
  consent_form_download: ReduxGenerator.getReducer("CONSENT_FORM_DOWNLOAD"),
  list_meeting: ReduxGenerator.getReducer("LIST_MEETING"),
  upsert_progress_group_note: ReduxGenerator.getReducer(
    "UPSERT_PROGRESS_GROUP_NOTE"
  ),
  get_progress_note_group: ReduxGenerator.getReducer("GET_PROGRESS_NOTE_GROUP"),
  get_user_note_patient: ReduxGenerator.getReducer("GET_USER_NOTE_PATIENT"),
  get_user_note_comment: ReduxGenerator.getReducer("GET_USER_NOTE_COMMENT"),
  add_user_note_comment: ReduxGenerator.getReducer("ADD_USER_NOTE_COMMENT"),
  get_user_note_emoji: ReduxGenerator.getReducer("GET_USER_NOTE_EMOJI"),
  add_user_note_emoji: ReduxGenerator.getReducer("ADD_USER_NOTE_EMOJI"),
  combo_intervention_template: ReduxGenerator.getReducer(
    "COMBO_INTERVENTION_TEMPLATE"
  ),
  get_progress_note_for_intervention: ReduxGenerator.getReducer(
    "GET_PROGRESS_NOTE_FOR_INTERVENTION"
  ),
  upsert_payer: ReduxGenerator.getReducer("UPSERT_PAYER"),
  list_payer: ReduxGenerator.getReducer("LIST_PAYER"),
  delete_payer: ReduxGenerator.getReducer("DELETE_PAYER"),
  get_payer: ReduxGenerator.getReducer("GET_PAYER"),
  combo_payer: ReduxGenerator.getReducer("COMBO_PAYER"),
  upsert_service_code: ReduxGenerator.getReducer("UPSERT_SERVICE_CODE"),
  list_service_code: ReduxGenerator.getReducer("LIST_SERVICE_CODE"),
  delete_service_code: ReduxGenerator.getReducer("DELETE_SERVICE_CODE"),
  get_service_code: ReduxGenerator.getReducer("GET_SERVICE_CODE"),
  combo_code: ReduxGenerator.getReducer("COMBO_CODE"),
  get_code_description: ReduxGenerator.getReducer("GET_CODE_DESCRIPTION"),
  appointment_note_list: ReduxGenerator.getReducer("APPOINTMENT_NOTE_LIST"),
  upsert_appointment_note: ReduxGenerator.getReducer("UPSERT_APPOINTMENT_NOTE"),
  get_appointment_note: ReduxGenerator.getReducer("GET_APPOINTMENT_NOTE"),
  combo_place_of_service: ReduxGenerator.getReducer("COMBO_PLACE_OF_SERVICE"),
  list_service_line: ReduxGenerator.getReducer("LIST_SERVICE_LINE"),
  get_service_line: ReduxGenerator.getReducer("GET_SERVICE_LINE"),
  upsert_service_line: ReduxGenerator.getReducer("UPSERT_SERVICE_LINE"),
  upsert_appointment_note_addendum: ReduxGenerator.getReducer(
    "UPSERT_APPOINTMENT_NOTE_ADDENDUM"
  ),
  list_group_appointment_note: ReduxGenerator.getReducer(
    "LIST_GROUP_APPOINTMENT_NOTE"
  ),
  get_group_appointment_note: ReduxGenerator.getReducer(
    "GET_GROUP_APPOINTMENT_NOTE"
  ),
  combo_treatment_plan_intervention: ReduxGenerator.getReducer(
    "COMBO_TREATMENT_PLAN_INTERVENTION"
  ),
  list_fee_schedule: ReduxGenerator.getReducer("LIST_FEE_SCHEDULE"),
  get_fee_schedule: ReduxGenerator.getReducer("GET_FEE_SCHEDULE"),
  upsert_fee_schedule: ReduxGenerator.getReducer("UPSERT_FEE_SCHEDULE"),
  get_treatment_plan_graph: ReduxGenerator.getReducer(
    "GET_TREATMENT_PLAN_GRAPH"
  ),
  list_group_service_line: ReduxGenerator.getReducer("LIST_GROUP_SERVICE_LINE"),
  get_group_service_line: ReduxGenerator.getReducer("GET_GROUP_SERVICE_LINE"),
  get_group_banner: ReduxGenerator.getReducer("GET_GROUP_BANNER"),
  upsert_group_service_line: ReduxGenerator.getReducer(
    "UPSERT_GROUP_SERVICE_LINE"
  ),
  upsert_group_appointment_note: ReduxGenerator.getReducer(
    "UPSERT_GROUP_APPOINTMENT_NOTE"
  ),
  upsert_group_appointment_note_addendum: ReduxGenerator.getReducer(
    "UPSERT_GROUP_APPOINTMENT_NOTE_ADDENDUM"
  ),
  upsert_appointment_note_intervention: ReduxGenerator.getReducer(
    "UPSERT_APPOINTMENT_NOTE_INTERVENTION"
  ),
  mark_as_complete: ReduxGenerator.getReducer("MARK_AS_COMPLETE"),
  get_service_line_cost: ReduxGenerator.getReducer("GET_SERVICE_LINE_COST"),
  upsert_pin: ReduxGenerator.getReducer("UPSERT_PIN"),
  load_smallGroup_dashboard: ReduxGenerator.getReducer(
    "LOAD_SMALLGROUP_DASHBOARD"
  ),
  upsert_treatment_plan: ReduxGenerator.getReducer("UPSERT_TREATMENT_PLAN"),
  list_client_diagnosis: ReduxGenerator.getReducer("LIST_CLIENT_DIAGNOSIS"),
  get_client_diagnosis: ReduxGenerator.getReducer("GET_CLIENT_DIAGNOSIS"),
  delete_client_diagnosis: ReduxGenerator.getReducer("DELETE_CLIENT_DIAGNOSIS"),
  validate_pin_exists: ReduxGenerator.getReducer("VALIDATE_PIN_EXISTS"),
  clone_diagnosis: ReduxGenerator.getReducer("CLONE_DIAGNOSIS"),
  clone_treatment_plan: ReduxGenerator.getReducer(
    "CLONE_TREATMENT_PLAN_DIAGNOSIS"
  ),

  rcm_client_merge: ReduxGenerator.getReducer("RCM_CLIENT_MERGE"),
  pre_validate_pin: ReduxGenerator.getReducer("PRE_VALIDATE_PIN"),
  validate_client: ReduxGenerator.getReducer("VALIDATE_CLIENT"),
  get_app_advisor: ReduxGenerator.getReducer("GET_APP_ADVISOR"),
  get_media_video_url: ReduxGenerator.getReducer("GET_MEDIA_VIDEO_URL"),
  get_dashboard_menu: ReduxGenerator.getReducer("GET_DASHBOARD_MENU"),
  list_service_code_bundle: ReduxGenerator.getReducer(
    "LIST_SERVICE_CODE_BUNDLE"
  ),
  get_service_code_bundle: ReduxGenerator.getReducer("GET_SERVICE_CODE_BUNDLE"),
  get_student_note: ReduxGenerator.getReducer("GET_STUDENT_NOTE"),
  get_student_noteComment: ReduxGenerator.getReducer(
    "GET_STUDENT_NOTE_COMMENT"
  ),
  add_student_noteComment: ReduxGenerator.getReducer(
    "ADD_STUDENT_NOTE_COMMENT"
  ),
  add_student_noteEmoji: ReduxGenerator.getReducer("ADD_STUDENT_NOTE_EMOJI"),
  get_student_noteEmoji: ReduxGenerator.getReducer("GET_STUDENT_NOTE_EMOJI"),
  get_treatment_plan_accordin: ReduxGenerator.getReducer(
    "GET_TREATMENT_PLAN_ACCORDIN"
  ),

  get_advisor_cost: ReduxGenerator.getReducer("GET_ADVISOR_COST"),
  upsert_advisor_cost: ReduxGenerator.getReducer("UPSERT_ADVISOR_COST"),
  claims_pdf_download: ReduxGenerator.getReducer("CLAIMS_PDF_DOWNLOAD"),
  get_client_service: ReduxGenerator.getReducer("GET_CLIENT_SERVICE"),
  time_travel_list_get: ReduxGenerator.getReducer("TIME_TRAVEL_LIST_GET"),
  get_dashboard_daily_schedule: ReduxGenerator.getReducer(
    "GET_DASHBOARD_DAILY_SCHEDULES"
  ),
  get_dashboard_daily_case_load: ReduxGenerator.getReducer(
    "GET_DASHBOARD_DAILY_CASE_LOAD"
  ),
  get_dashboard_alert: ReduxGenerator.getReducer("GET_DASHBOARD_ALERT"),

  add_client_insurance_eligibility: ReduxGenerator.getReducer(
    "ADD_CLIENT_INSURANCE_ELIGIBILITY"
  ),
  edit_client_insurance_eligibility: ReduxGenerator.getReducer(
    "EDIT_CLIENT_INSURANCE_ELIGIBILITY"
  ),
  delete_client_insurance_eligibility: ReduxGenerator.getReducer(
    "DELETE_CLIENT_INSURANCE_ELIGIBILITY"
  ),
  discharge_summary_save: ReduxGenerator.getReducer("DISCHARGE_SUMMERY_SAVE"),
  get_client_appointment: ReduxGenerator.getReducer("GET_CLIENT_APPOINTMENT"),
  get_group_appointment: ReduxGenerator.getReducer("GET_GROUP_APPOINTMENT"),
  unsign_form: ReduxGenerator.getReducer("UNSIGN_FORM"),
  get_filters: ReduxGenerator.getReducer("GET_FILTERS"),
  list_lab_order: ReduxGenerator.getReducer("LIST_LAB_ORDER"),
  get_lab_order: ReduxGenerator.getReducer("GET_LAB_ORDER"),
  upsert_lab_order: ReduxGenerator.getReducer("UPSERT_LAB_ORDER"),
  combo_diagnosis_list: ReduxGenerator.getReducer("COMBO_DIAGNOSIS_LIST"),
  get_medication_note: ReduxGenerator.getReducer("GET_MEDICATION_NOTE"),
  upsert_client_group_appointment: ReduxGenerator.getReducer(
    "UPSERT_CLIENT_GROUP_APPOINTMENT"
  ),
  sign_group_appointment_note: ReduxGenerator.getReducer(
    "SIGN_GROUP_APPOINTMENT_NOTE"
  ),
  list_service_line_payment: ReduxGenerator.getReducer(
    "LIST_SERVICE_LINE_PAYMENT"
  ),
  get_service_line_payment: ReduxGenerator.getReducer(
    "GET_SERVICE_LINE_PAYMENT"
  ),
  upsert_service_line_payment: ReduxGenerator.getReducer(
    "UPSERT_SERVICE_LINE_PAYMENT"
  ),
  delete_service_line_payment: ReduxGenerator.getReducer(
    "DELETE_SERVICE_LINE_PAYMENT"
  ),
  combo_supervisor: ReduxGenerator.getReducer("COMBO_SUPERVISOR"),
  location_day_history: ReduxGenerator.getReducer("LOCATION_DAY_HISTORY"),
  upsert_payment_client: ReduxGenerator.getReducer("UPSERT_PAYMENT_CLIENT"),
  get_insurance_priority_order: ReduxGenerator.getReducer(
    "GET_INSURANCE_PRIORITY_ORDER"
  ),
  upsert_insurance_priority_order: ReduxGenerator.getReducer(
    "UPSERT_INSURANCE_PRIORITY_ORDER"
  ),
  list_CWPayment: ReduxGenerator.getReducer("LIST_CW_PAYMENT"),
  get_CWPayment: ReduxGenerator.getReducer("GET_CW_PAYMENT"),
  delete_CWPayment: ReduxGenerator.getReducer("DELETE_CW_PAYMENT"),
  past_meeting_info: ReduxGenerator.getReducer("PAST_MEETING_INFO"),
  list_past_meeting_info: ReduxGenerator.getReducer("LIST_PAST_MEETING_INFO"),
  combo_appointment_treatment_plan: ReduxGenerator.getReducer(
    "COMBO_APPOINTMENT_TREATMENT_PLAN"
  ),
  link_appointment: ReduxGenerator.getReducer("LINK_APPOINTMENT"),
  appointment_link_get: ReduxGenerator.getReducer("APPOINTMENT_LINK_GET"),
  get_basic_profile: ReduxGenerator.getReducer("GET_BASIC_PROFILE"),
  get_invited_list: ReduxGenerator.getReducer("GET_INVITED_LIST"),
  invite_resend: ReduxGenerator.getReducer("INVITE_RESEND"),
  suspicious_term_ack: ReduxGenerator.getReducer("SUSPICIOUS_TERM_ACK"),
  get_broadcast_filter: ReduxGenerator.getReducer("GET_BROADCAST_FILTER"),
  send_broadcast: ReduxGenerator.getReducer("SEND_BROADCAST"),
  get_service_line_diagnosis: ReduxGenerator.getReducer(
    "GET_SERVICE_LINE_DIAGNOSIS"
  ),
  upsert_service_line_diagnosis: ReduxGenerator.getReducer(
    "UPSERT_SERVICE_LINE_DIAGNOSIS"
  ),
  combo_broadcast_template: ReduxGenerator.getReducer(
    "COMBO_BROADCAST_TEMPLATE"
  ),
  upsert_broadcast_template: ReduxGenerator.getReducer(
    "UPSERT_BROADCAST_TEMPLATE"
  ),
  delete_note_type: ReduxGenerator.getReducer("DELETE_NOTE_TYPE"),
  list_client_authorization: ReduxGenerator.getReducer(
    "LIST_CLIENT_AUTHORIZATION"
  ),
  get_client_authorization: ReduxGenerator.getReducer(
    "GET_CLIENT_AUTHORIZATION"
  ),
  delete_client_authorization: ReduxGenerator.getReducer(
    "DELETE_CLIENT_AUTHORIZATION"
  ),
  upsert_client_authorization: ReduxGenerator.getReducer(
    "UPSERT_CLIENT_AUTHORIZATION"
  ),
  get_note_type_archive: ReduxGenerator.getReducer("GET_NOTE_TYPE_ARCHIVE"),
  get_note_type_list: ReduxGenerator.getReducer("GET_NOTE_TYPE_LIST"),

  generate_call_token: ReduxGenerator.getReducer("GENERATE_CALL_TOKEN"),
  badge_combo_get: ReduxGenerator.getReducer("BADGE_COMBO_GET"),
  badge_update: ReduxGenerator.getReducer("BADGE_UPDATE"),
  panas_scale_moods_get: ReduxGenerator.getReducer("PANAS_SCALE_MOODS_GET"),
  panas_tag_upsert: ReduxGenerator.getReducer("PANAS_TAG_UPSERT"),
  bed_assignment_get_all: ReduxGenerator.getReducer("BED_ASSIGNMENT_GET_ALL"),
  bed_assignment_upsert: ReduxGenerator.getReducer("BED_ASSIGNMENT_UPSERT"),
  upcoming_discharges_get_all: ReduxGenerator.getReducer(
    "UPCOMING_DISCHARGES_GET_ALL"
  ),
  upcoming_admissions: ReduxGenerator.getReducer("UPCOMING_ADMISSIONS"),
  admission_cancel: ReduxGenerator.getReducer("ADMISSION_CANCEL"),
  panas_tag_delete: ReduxGenerator.getReducer("PANAS_TAG_DELETE"),
  dynamic_grid_list: ReduxGenerator.getReducer("DYNAMIC_GRID_LIST"),
  get_grid: ReduxGenerator.getReducer("GET_GRID"),
  get_grid_data: ReduxGenerator.getReducer("GET_GRID_DATA"),
  upsert_form_data: ReduxGenerator.getReducer("UPSERT_FORM_DATA"),
  form_data_get: ReduxGenerator.getReducer("FORM_DATA_GET"),
  dynamic_grid_data_get: ReduxGenerator.getReducer("DYNAMIC_GRID_DATA_GET"),
  member_trends_get: ReduxGenerator.getReducer("MEMBER_TRENDS_GET"),
  advisor_member_trends_history_get: ReduxGenerator.getReducer(
    "ADVISOR_MEMBER_TRENDS_HISTORY_GET"
  ),
  dashboard_analytics_get: ReduxGenerator.getReducer("DASHBOARD_ANALYTICS_GET"),
  user_activity_get: ReduxGenerator.getReducer("USER_ACTIVITY_GET"),
  user_suspicious_word_get: ReduxGenerator.getReducer(
    "USER_SUSPICIOUS_WORD_GET"
  ),
  user_notification_get: ReduxGenerator.getReducer("USER_NOTIFICATION_GET"),
  user_member_trend_history: ReduxGenerator.getReducer(
    "USER_MEMBER_TREND_HISTORY"
  ),
  upsert_client_note: ReduxGenerator.getReducer("UPSERT_CLIENT_NOTE"),
  get_previous_notes: ReduxGenerator.getReducer("GET_PREVIOUS_NOTE"),
  get_dynamic_grid_filter: ReduxGenerator.getReducer("GET_DYNAMIC_GRID_FILTER"),

  member_trend_trigger_history: ReduxGenerator.getReducer(
    "MEMBER_TREND_TRIGGER_HISTORY"
  ),
  member_header_info_get: ReduxGenerator.getReducer("MEMBER_HEADER_INFO_GET"),
  get_assessment_detail_list: ReduxGenerator.getReducer(
    "GET_ASSESSMENT_DETAIL_LIST_V2"
  ),
  assign_multiple_v2: ReduxGenerator.getReducer("ASSIGN_MULTIPLE_V2"),
  chat_friend_list_get: ReduxGenerator.getReducer("CHAT_FRIEND_LIST_GET"),
  list_client_v2: ReduxGenerator.getReducer("LIST_CLIENT_V2"),
  population_activities_client_list_get: ReduxGenerator.getReducer(
    "POPULATION_ACTIVITIES_CLIENT_LIST_GET"
  ),
  center_get_all: ReduxGenerator.getReducer("CENTER_GET_ALL"),
  center_delete: ReduxGenerator.getReducer("CENTER_DELETE"),
  update_staff_center: ReduxGenerator.getReducer("UPDATE_STAFF_CENTER"),
  combo_center_consent: ReduxGenerator.getReducer("COMBO_CENTER_CONSENT"),
  center_program_type_get: ReduxGenerator.getReducer("CENTER_PROGRAM_TYPE_GET"),
  get_center_advisor_default_costs: ReduxGenerator.getReducer(
    "GET_CENTER_ADVISOR_DEFAULT_COSTS"
  ),
  update_center_advisor_default_cost: ReduxGenerator.getReducer(
    "UPDATE_CENTER_ADVISOR_DEFAULT_COST"
  ),
  center_reactivate: ReduxGenerator.getReducer("CENTER_REACTIVATE"),
  student_courses: ReduxGenerator.getReducer("STUDENT_GET_ALL_COURSES"),
  course_delete: ReduxGenerator.getReducer("COURSE_DELETE"),
  course_get_all: ReduxGenerator.getReducer("COURSE_GET_ALL"),
  add_app_subscribers_course: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_ADD_COURSE"
  ),
  delete_app_subscribers_course: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_DELETE_COURSE"
  ),
  app_subscribers_complete_course: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_COMPLETE_COURSE"
  ),
  get_contract: ReduxGenerator.getReducer("GET_CONTRACT"),
  upsert_contract: ReduxGenerator.getReducer("UPSERT_CONTRACT"),
  delete_contract: ReduxGenerator.getReducer("DELETE_CONTRACT"),
  get_contract_template: ReduxGenerator.getReducer("GET_CONTRACT_TEMPLATE"),
  upsert_contract_template: ReduxGenerator.getReducer(
    "UPSERT_CONTRACT_TEMPLATE"
  ),
  get_student_contract_template: ReduxGenerator.getReducer(
    "GET_STUDENT_CONTRACT_TEMPLATE"
  ),
  create_contract_from_template: ReduxGenerator.getReducer(
    "CREATE_CONTRACT_FROM_TEMPLATE"
  ),
  add_favorite_template: ReduxGenerator.getReducer("ADD_FAVORITE_TEMPLATE"),
  remove_favorite_template: ReduxGenerator.getReducer(
    "REMOVE_FAVORITE_TEMPLATE"
  ),
  close_contract: ReduxGenerator.getReducer("CLOSE_CONTRACT"),
  menu_contract_template: ReduxGenerator.getReducer("MENU_CONTRACT_TEMPLATE"),
  archive_contract_template: ReduxGenerator.getReducer(
    "ARCHIVE_CONTRACT_TEMPLATE"
  ),
  staff_delete: ReduxGenerator.getReducer("STAFF_DELETE"),
  update_staff_email: ReduxGenerator.getReducer("UPDATE_STAFF_EMAIL"),
  update_staff_password: ReduxGenerator.getReducer("UPDATE_STAFF_PASSWORD"),
  upload_staff_image: ReduxGenerator.getReducer("UPLOAD_STAFF_IMAGE"),
  get_staff_shift_log: ReduxGenerator.getReducer("GET_STAFF_SHIFT_LOG"),
  add_staff_shift_log: ReduxGenerator.getReducer("ADD_STAFF_SHIFT_LOG"),
  staff_reactivate: ReduxGenerator.getReducer("STAFF_REACTIVATE"),
  is_staff_pro_bio: ReduxGenerator.getReducer("CHECK_STAFF_PRO_BIO"),
  update_staff_pro_bio: ReduxGenerator.getReducer("UPDATE_STAFF_PRO_BIO"),
  validate_staff_pin: ReduxGenerator.getReducer("VALIDATE_STAFF_PIN"),
  get_staff_practitioner: ReduxGenerator.getReducer("GET_STAFF_PRACTITIONER"),
  get_student_survery_dashboard_data: ReduxGenerator.getReducer(
    "STUDENT_SURVEY_DASHBOARD_GET_JSON"
  ),

  survey_task_mark_in_complete: ReduxGenerator.getReducer(
    "SURVEY_TASK_MARK_IN_COMPLETE"
  ),
  surveys: ReduxGenerator.getReducer("SURVEY_STUDENTS_GET_ALL_PENDING"),
  survey_assign: ReduxGenerator.getReducer("SURVEY_ASSIGN"),
  survey_get_to_assign: ReduxGenerator.getReducer("SURVEY_GET_TO_ASSIGN"),
  survery_question_get_multiple: ReduxGenerator.getReducer(
    "SURVEY_QUESTION_GET_MULTIPLE"
  ),
  survey_answers_get: ReduxGenerator.getReducer("SURVEY_ANSWERS_GET"),
  add_app_subscribers_survey_assign: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_SURVEY_ASSIGN"
  ),
  delete_app_subscribers_survey: ReduxGenerator.getReducer(
    "APP_SUBSCRIBERS_SURVEY_UNASSIGN"
  ),
  list_survey_question_category: ReduxGenerator.getReducer(
    "LIST_SURVEY_QUESTION_CATEGORY"
  ),
  list_survey_question_category_assessment: ReduxGenerator.getReducer(
    "LIST_SURVEY_QUESTION_CATEGORY_ASSESSMENT"
  ),
  list_survey_question_assessment: ReduxGenerator.getReducer(
    "LIST_SURVEY_QUESTION_ASSESSMENT"
  ),
  get_survey_score: ReduxGenerator.getReducer("GET_SURVEY_SCORE"),
  get_consent_form_template: ReduxGenerator.getReducer(
    "GET_CONSENT_FORM_TEMPLATE"
  ),
  get_combo_facility: ReduxGenerator.getReducer("GET_COMBO_FACEILITY"),
  load_app_sub_survey_dashboard: ReduxGenerator.getReducer(
    "LOAD_APP_SUB_SURVEY_DASHBOARD"
  ),
  app_sub_survey_ans_get: ReduxGenerator.getReducer("APP_SUB_SURVEY_ANS_GET"),
  list_survey_question_discharge: ReduxGenerator.getReducer(
    "LIST_SURVEY_QUESTION_DISCHARGE"
  ),
  get_surveys_v2: ReduxGenerator.getReducer("GET_SURVEYS_V2"),
  survey_question_category_option_get: ReduxGenerator.getReducer(
    "SURVEY_QUESTION_CATEGORY_OPTION_GET"
  ),
  //youu started
  login: ReduxGenerator.getReducer("LOGIN"),
  default_values: ReduxGenerator.getReducer("LOAD_DEFAULT_VALUES"),
  user_data_get: ReduxGenerator.getReducer("USER_DATA_GET"),
  all_get: ReduxGenerator.getReducer("ALL_GET"),
  client_list_dropdown: ReduxGenerator.getReducer("CLIENT_LIST_DROPDOWN"),
  side_bar_menu_get: ReduxGenerator.getReducer("SIDE_BAR_MENU_GET"),
  get_dynamic_screen_tab: ReduxGenerator.getReducer("GET_DYNAMIC_SCREEN_TAB"),
  get_form_field_meta_data: ReduxGenerator.getReducer(
    "GET_FORM_FIELD_META_DATA"
  ),
  dynamic_grid_column_configuration_get: ReduxGenerator.getReducer(
    "DYNAMIC_GRID_COLUMN_CONFIGURATION_GET"
  ),
  get_multistep_tab: ReduxGenerator.getReducer("GET_MULTISTEP_TAB"),
  get_user_info: ReduxGenerator.getReducer("GET_USER_INFO"),
  get_client_album: ReduxGenerator.getReducer("GET_CLIENT_ALBUM"),
  post_list_get: ReduxGenerator.getReducer("POST_LIST_GET"),
  client_friend_all_get: ReduxGenerator.getReducer("CLIENT_FRIEND_ALL_GET"),
  assigned_course_list_get: ReduxGenerator.getReducer(
    "ASSIGNED_COURSE_LIST_GET"
  ),
  course_assign: ReduxGenerator.getReducer("COURSE_ASSIGN"),
  course_unassign: ReduxGenerator.getReducer("COURSE_UNASSIGN"),
  course_mark_as_complete: ReduxGenerator.getReducer("COURSE_MARK_AS_COMPLETE"),
  course_option_get: ReduxGenerator.getReducer("COURSE_OPTION_GET"),
  course_get: ReduxGenerator.getReducer("COURSE_GET"),
  course_upsert: ReduxGenerator.getReducer("COURSE_UPSERT"),
  course_list_get: ReduxGenerator.getReducer("COURSE_LIST_GET"),
  course_status_update: ReduxGenerator.getReducer("COURSE_STATUS_UPDATE"),
  center_option_get: ReduxGenerator.getReducer("CENTER_OPTION_GET"),
  center_upsert: ReduxGenerator.getReducer("CENTER_UPSERT"),
  center_list_get: ReduxGenerator.getReducer("CENTER_LIST_GET"),
  center_get: ReduxGenerator.getReducer("CENTER_GET"),
  center_status_update: ReduxGenerator.getReducer("CENTER_STATUS_UPDATE"),
  get_all_journal: ReduxGenerator.getReducer("GET_ALL_JOURNAL"),
  get_all_journal_comments: ReduxGenerator.getReducer(
    "GET_ALL_JOURNAL_COMMENTS"
  ),
  journal_add: ReduxGenerator.getReducer("JOURNAL_ADD"),
  journal_comment_upsert: ReduxGenerator.getReducer("JOURNAL_COMMENT_UPSERT"),
  task_list_get: ReduxGenerator.getReducer("TASK_LIST_GET"),
  task_upsert: ReduxGenerator.getReducer("TASK_ASSIGN"),
  task_unassign: ReduxGenerator.getReducer("TASK_UNASSIGN"),
  task_option_get: ReduxGenerator.getReducer("TASK_OPTIONS_GET"),
  task_mark_complete: ReduxGenerator.getReducer("TASK_MARK_COMPLETE"),
  combo_list_get: ReduxGenerator.getReducer("COMBO_LIST_GET"),
  service_code_list_get: ReduxGenerator.getReducer("SERVICE_CODE_LIST_GET"),
  counselor_list_get: ReduxGenerator.getReducer("COUNSELOR_LIST_GET"),
  facility_list_get: ReduxGenerator.getReducer("FACILITY_LIST_GET"),
  triggers_list_get: ReduxGenerator.getReducer("TRIGGERS_LIST_GET"),
  assessments_list_get: ReduxGenerator.getReducer("ASSESSMENTS_LIST_GET"),
  assessments_mark_as_discard: ReduxGenerator.getReducer(
    "ASSESSMENTS_MARK_AS_DISCARD"
  ),
  assessments_multiple_assign: ReduxGenerator.getReducer(
    "ASSESSMENTS_MULTIPLE_ASSIGN"
  ),
  trends_list_get: ReduxGenerator.getReducer("TRENDS_LIST_GET"),
  intake_client_upsert: ReduxGenerator.getReducer("INTAKE_CLIENT_UPSERT"),
  intake_insurance_upsert: ReduxGenerator.getReducer("INTAKE_INSURANCE_UPSERT"),
  intake_insurance_priority_order_get: ReduxGenerator.getReducer(
    "INTAKE_INSURANCE_PRIORITY_ORDER_GET"
  ),
  intake_insurance_priority_order_update: ReduxGenerator.getReducer(
    "INTAKE_INSURANCE_PRIORITY_ORDER_UPDATE"
  ),
  intake_medical_history_upsert: ReduxGenerator.getReducer(
    "INTAKE_MEDICAL_HISTORY_UPSERT"
  ),
  intake_form_data_get: ReduxGenerator.getReducer("INTAKE_FORM_DATA_GET"),
  intake_enroll_now: ReduxGenerator.getReducer("INTAKE_ENROLL_NOW"),
  pre_intake_grid_reports: ReduxGenerator.getReducer("PRE_INTAKE_GRID_REPORTS"),
  pre_intake_default_assessment_get: ReduxGenerator.getReducer(
    "PRE_INTAKE_DEFAULT_ASSESSMENT_GET"
  ),
  growth_plan_template_upsert: ReduxGenerator.getReducer(
    "GROWTH_PLAN_TEMPLATE_UPSERT"
  ),
  growth_plan_template_menu_get: ReduxGenerator.getReducer(
    "GROWTH_PLAN_TEMPLATE_MENU_GET"
  ),
  growth_plan_template_get: ReduxGenerator.getReducer(
    "GROWTH_PLAN_TEMPLATE_GET"
  ),
  growth_plan_template_favorite_add: ReduxGenerator.getReducer(
    "GROWTH_PLAN_TEMPLATE_FAVORITE_ADD"
  ),
  growth_plan_template_favorite_delete: ReduxGenerator.getReducer(
    "GROWTH_PLAN_TEMPLATE_FAVORITE_DELETE"
  ),
  growth_plan_template_delete: ReduxGenerator.getReducer(
    "GROWTH_PLAN_TEMPLATE_DELETE"
  ),
  client_growth_plan_upsert: ReduxGenerator.getReducer(
    "CLIENT_GROWTH_PLAN_UPSERT"
  ),
  client_growth_plan_get: ReduxGenerator.getReducer("CLIENT_GROWTH_PLAN_GET"),
  client_growth_plan_list_get: ReduxGenerator.getReducer(
    "CLIENT_GROWTH_PLAN_LIST_GET"
  ),
  client_growth_plan_delete: ReduxGenerator.getReducer(
    "CLIENT_GROWTH_PLAN_DELETE"
  ),
  client_growth_plan_from_template_insert: ReduxGenerator.getReducer(
    "CLIENT_GROWTH_PLAN_FROM_TEMPLATE_INSERT"
  ),
  client_growth_plan_close: ReduxGenerator.getReducer(
    "CLIENT_GROWTH_PLAN_CLOSE"
  ),
  staff_list_get: ReduxGenerator.getReducer("STAFF_LIST_GET"),
  staff_get: ReduxGenerator.getReducer("STAFF_GET"),
  staff_upsert: ReduxGenerator.getReducer("STAFF_UPSERT"),
  get_staff_permission: ReduxGenerator.getReducer("GET_STAFF_PERMISSION"),
  update_staff_permission: ReduxGenerator.getReducer("UPDATE_STAFF_PERMISSION"),
  staff_mobile_app_invite_send: ReduxGenerator.getReducer(
    "STAFF_MOBILE_APP_INVITE_SEND"
  ),
  clinical_center_get: ReduxGenerator.getReducer("CLINICAL_CENTER_GET"),
  get_small_group_list: ReduxGenerator.getReducer("GET_SMALL_GROUP_LIST"),
  small_group_get: ReduxGenerator.getReducer("SMALL_GROUP_GET"),
  small_group_upsert: ReduxGenerator.getReducer("SMALL_GROUP_UPSERT"),
  small_group_referral: ReduxGenerator.getReducer("SMALL_GROUP_REFERRAL"),
  small_group_user_list_get: ReduxGenerator.getReducer(
    "SMALL_GROUP_USER_LIST_GET"
  ),
  small_group_user_remove: ReduxGenerator.getReducer("SMALL_GROUP_USER_REMOVE"),
  small_group_location_get: ReduxGenerator.getReducer(
    "SMALL_GROUP_LOCATION_GET"
  ),
  small_group_location_update: ReduxGenerator.getReducer(
    "SMALL_GROUP_LOCATION_UPDATE"
  ),
  small_group_images_get: ReduxGenerator.getReducer("SMALL_GROUP_IMAGES_GET"),
  upsert_broadcast_template: ReduxGenerator.getReducer(
    "UPSERT_BROADCAST_TEMPLATE"
  ),
  broadcast_send: ReduxGenerator.getReducer("BROADCAST_SEND"),
  survey_assign_multiple: ReduxGenerator.getReducer("SURVEY_ASSIGN_MULTIPLE"),
  survey_answer_upsert: ReduxGenerator.getReducer("SURVEY_ANSWER_UPSERT"),
  client_survey_list_get: ReduxGenerator.getReducer("CLIENT_SURVEY_LIST_GET"),
  survey_question_category_list_get: ReduxGenerator.getReducer(
    "SURVEY_QUESTION_CATEGORY_LIST_GET"
  ),
  survey_question_hierarchial_get: ReduxGenerator.getReducer(
    "SURVEY_QUESTION_HIERARCHIAL_GET"
  ),
  survey_mark_as_discard: ReduxGenerator.getReducer("SURVEY_MARK_AS_DISCARD"),
  survey_assigned_list_get: ReduxGenerator.getReducer(
    "SURVEY_ASSIGNED_LIST_GET"
  ),
  group_upsert: ReduxGenerator.getReducer("GROUP_UPSERT"),
  group_list_get: ReduxGenerator.getReducer("GROUP_LIST_GET"),
  client_group_upsert: ReduxGenerator.getReducer("CLIENT_GROUP_UPSERT"),
  clients_not_participating_in_group: ReduxGenerator.getReducer(
    "CLIENTS_NOT_PARTICIPATING_IN_GROUP"
  ),
  client_group_get: ReduxGenerator.getReducer("CLIENT_GROUP_GET"),
  group_appointment_group_get: ReduxGenerator.getReducer(
    "GROUP_APPOINTMENT_GROUP_GET"
  ),
  group_appointment_group_notes_get: ReduxGenerator.getReducer(
    "GROUP_APPOINTMENT_GROUP_NOTES_GET"
  ),
  group_service_lines_get: ReduxGenerator.getReducer("GROUP_SERVICE_LINES_GET"),
  group_deactivate: ReduxGenerator.getReducer("GROUP_DEACTIVATE"),
  group_get: ReduxGenerator.getReducer("GROUP_GET"),
  coupon_list_get: ReduxGenerator.getReducer("COUPON_LIST_GET"),
  upsert_coupon_code: ReduxGenerator.getReducer("UPSERT_COUPON_CODE"),
  delete_coupon_code: ReduxGenerator.getReducer("DELETE_COUPON_CODE"),
  get_coupon_code: ReduxGenerator.getReducer("GET_COUPON_CODE"),
  get_breathalyzer_schedule: ReduxGenerator.getReducer(
    "GET_BREATHALYZER_SCHEDULE"
  ),
  list_breathalyzer_schedule: ReduxGenerator.getReducer(
    "LIST_BREATHALYZER_SCHEDULE"
  ),
  upsert_breathalyzer_schedule: ReduxGenerator.getReducer(
    "UPSERT_BREATHALYZER_SCHEDULE"
  ),
  breathalyzer_push_test: ReduxGenerator.getReducer("BREATHALYZER_PUSH_A_TEST"),
  breathalyzer_enable: ReduxGenerator.getReducer("BREATHALYZER_ENABLE"),
  get_breathalyzer_test_result: ReduxGenerator.getReducer(
    "GET_BREATHALYZER_TEST_RESULT"
  ),
  get_breathalyzer_test_upcoming: ReduxGenerator.getReducer(
    "GET_BREATHALYZER_TEST_UPCOMING"
  ),
  breathalyzer_test_upcoming_toggle: ReduxGenerator.getReducer(
    "BREATHALYZER_TEST_UPCOMING_TOGGLE"
  ),
  get_student_breathalyzer_test_result: ReduxGenerator.getReducer(
    "GET_STUDENT_BREATHALYZER_TEST_RESULT"
  ),
  miscellaneous_task_get: ReduxGenerator.getReducer("MISCELLANEOUS_TASK_GET"),
  miscellaneous_task_list_get: ReduxGenerator.getReducer(
    "MISCELLANEOUS_TASK_LIST_GET"
  ),
  miscellaneous_task_delete: ReduxGenerator.getReducer(
    "MISCELLANEOUS_TASK_DELETE"
  ),
  miscellaneous_task_upsert: ReduxGenerator.getReducer(
    "MISCELLANEOUS_TASK_UPSERT"
  ),
  miscellaneous_note_type_insert: ReduxGenerator.getReducer(
    "MISCELLANEOUS_NOTE_TYPE_INSERT"
  ),
  miscellaneous_combo_note_types_list_get: ReduxGenerator.getReducer(
    "MISCELLANEOUS_COMBO_NOTE_TYPES_LIST_GET"
  ),
  miscellaneous_note_type_delete: ReduxGenerator.getReducer(
    "MISCELLANEOUS_NOTE_TYPE_DELETE"
  ),
  miscellaneous_note_types_list_get: ReduxGenerator.getReducer(
    "MISCELLANEOUS_NOTE_TYPES_LIST_GET"
  ),
  miscellaneous_note_type_get: ReduxGenerator.getReducer(
    "MISCELLANEOUS_NOTE_TYPE_GET"
  ),
  practitioner_options_get: ReduxGenerator.getReducer(
    "PRACTITIONER_OPTIONS_GET"
  ),
  facility_options_get: ReduxGenerator.getReducer("FACILITY_OPTIONS_GET"),
  appointment_list_get: ReduxGenerator.getReducer("APPOINTMENT_LIST_GET"),
  appointment_upsert: ReduxGenerator.getReducer("APPOINTMENT_UPSERT"),
  group_appointment_upsert: ReduxGenerator.getReducer(
    "GROUP_APPOINTMENT_UPSERT"
  ),
  block_appointment_upsert: ReduxGenerator.getReducer(
    "BLOCK_APPOINTMENT_UPSERT"
  ),
  staff_pin_validate: ReduxGenerator.getReducer("STAFF_PIN_VALIDATE"),
  appointment_note_get: ReduxGenerator.getReducer("APPOINTMENT_NOTE_GET"),
  appointment_note_upsert: ReduxGenerator.getReducer("APPOINTMENT_NOTE_UPSERT"),
  appointment_note_list_get: ReduxGenerator.getReducer(
    "APPOINTMENT_NOTE_LIST_GET"
  ),
  appointment_note_addendum_upsert: ReduxGenerator.getReducer(
    "APPOINTMENT_NOTE_ADDENDUM_UPSERT"
  ),
  group_appointment_note_get: ReduxGenerator.getReducer(
    "GROUP_APPOINTMENT_NOTE_GET"
  ),
  group_appointment_note_upsert: ReduxGenerator.getReducer(
    "GROUP_APPOINTMENT_NOTE_UPSERT"
  ),
  combo_practitioner: ReduxGenerator.getReducer("COMBO_PRACTITIONER"),
  combo_facility: ReduxGenerator.getReducer("COMBO_FACILITY"),
  group_option_get: ReduxGenerator.getReducer("GROUP_OPTION_GET"),
  client_group_options_get: ReduxGenerator.getReducer(
    "CLIENT_GROUP_OPTIONS_GET"
  ),
});
export default rootReducer;
