import React from "react";
import { Assessment } from "../../components/custom-screens/assessments";
const Assessments = (props) => {
  return (
    <>
      <div>
        <Assessment />
      </div>
    </>
  );
};
export default Assessments;
