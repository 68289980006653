/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Button from "@material-ui/core/Button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Dropdown } from "react-bootstrap";
import {
  DropdownCustomToggler,
  DropdownMenu,
} from "../../../_metronic/_partials/dropdowns";
import {
  TwilioCalling,
  Chat,
  Video,
  Notes,
  Hyperlink,
  ItemDropdown,
  PushTest,
  CancelTest,
  Archive,
  Restore,
} from "../custom-component";
import _OpenExternal from "../../images/openExternal.svg";

// const ItemDropdown = ({ item, More }) => {
//   return (
//     <>
//       <Dropdown className="dropdown-inline" alignRight>
//         <Dropdown.Toggle
//           variant="transparent"
//           id="dropdown-toggle-top"
//           className="btn btn-hover-light-primary btn-sm btn-icon"
//           as={DropdownCustomToggler}
//         >
//           <i className="ki ki-bold-more-ver" />
//         </Dropdown.Toggle>
//         <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
//           <DropdownMenu more={More} />
//         </Dropdown.Menu>
//       </Dropdown>
//     </>
//   );
// };

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { toggleDrawer }
) => {
  const {
    DisplayName,
    MemberName,
    FirstName,
    Phone,
    PrimaryPhone,
    ProfileImageUrl,
    ProfileImgUrl,
    MediGuid,
    MediaGuid,
    Actions,
  } = row;
  const userData = {
    Name: DisplayName,
    FirstName: FirstName,
    Phone: Phone,
    PrimaryPhone: PrimaryPhone,
    ProfileImageUrl: ProfileImageUrl,
    ProfileImgUrl: ProfileImgUrl,
  };

  let ActionData = null;
  if (typeof Actions === "string") {
    ActionData = JSON.parse(Actions);
  } else {
    ActionData = Actions;
  }
  const {
    IsChat,
    IsPhone,
    IsVideo,
    IsNote,
    IsHyperlink,
    More,
    IsInitiateTestNow,
    IsCancelTest,
    IsMiscellaneousArchived,
  } = ActionData || {};
  return (
    <div className="d-flex align-items-center justify-content-start">
      {IsPhone && (
        <div className="">
          <TwilioCalling userData={userData} />{" "}
        </div>
      )}
      {IsNote && (
        <div className="">
          <Notes RowData={row} />
        </div>
      )}
      {IsMiscellaneousArchived === true && (
        <div className="">
          <Restore RowData={row} />
        </div>
      )}
      {IsMiscellaneousArchived === false && (
        <div className="">
          <Archive RowData={row} />
        </div>
      )}
      {IsChat && (
        <div className="">
          <Chat Data={row} />
        </div>
      )}
      {IsInitiateTestNow && (
        <div className="">
          <PushTest RowData={row} />
        </div>
      )}
      {IsCancelTest && (
        <div className="">
          <CancelTest RowData={row} />
        </div>
      )}
      {IsVideo && MediaGuid && (
        <div className="">
          <Video Data={row} MediaGuid={MediaGuid} />
        </div>
      )}
      {/* {true && (
        <div className="">
          <Hyperlink Data={row} />
        </div>
      )} */}
      {toggleDrawer && (
        <div className="">
          <OverlayTrigger
            overlay={<Tooltip id="chat-tooltip">Open External</Tooltip>}
          >
            <a
              className="btn btn-icon btn-hover-light-primary btn-sm"
              onClick={toggleDrawer("right", true, row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(_OpenExternal)} />
              </span>
            </a>
          </OverlayTrigger>
        </div>
      )}
      {More && (
        <div className="">
          <ItemDropdown item="MoreAction" More={More} />
        </div>
      )}
    </div>
  );
};
