import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* listActivity(action) {
  try {
    const response = yield API.listActivity();
    yield put({
      type: ActionTypes.ACTIVITY_GET_ALL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ACTIVITY_GET_ALL_FAILURE,
    });
  }
}

export function* listActivityByStudent(action) {
  try {
    const { payload } = action;
    const response = yield API.listActivityByStudent(payload);
    yield put({
      type: ActionTypes.ACTIVITY_GET_ALL_BY_STUDENT_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ACTIVITY_GET_ALL_BY_STUDENT_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.ACTIVITY_GET_ALL_REQUEST, listActivity),
    takeLatest(ActionTypes.ACTIVITY_GET_ALL_BY_STUDENT_REQUEST, listActivityByStudent),
  ]);
}
