import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import formDataHandler from "../../util/formDataHandler";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from "redux-form";
import {
  getFormFieldMetaData,
  formDataGet,
  resetFormDataGet,
  upsertFormData,
  staffReactivate,
  staffDelete,
  staffUpsert,
  resetStaffUpsert,
  listCombos,
  appInvite,
  staffGet,
  resetStaffGet,
  clinicalCenterGet,
} from "../../../redux/actions";
import DynamicForm, {
  DynamicSection,
  COMPONENT_TYPES,
} from "../../../core/generic-form";


import {
  ReduxInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxDateInput,
  ReduxTimeInput,
  ReduxDollarInput,
  ReduxMultiSelect,
  ReduxToggle,
  ReduxCheckbox,
  ReduxAutoComplete,
  CheckboxGroup,
} from "../../components/redux-component/index";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
}));

const ProviderCentersForm = ({
  fields,
  name,
  meta: { touched, error },
  Centers,
  providerCenterRow,
  change,
  disable,
  dispatch,
  Center,
  ClinicalCenterDropdown,
}) => {
  return (
    <>
      {fields.map((providerCenters, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6 mb-5">
                <h6>Center {index + 1}</h6>
              </div>
              <div class="col-12 col-md-6">
                <a
                  class="delete-contact"
                  style={{ display: "table", marginLeft: "auto" }}
                  onClick={() => fields.remove(index)}
                  disabled={disable}
                >
                  <i class="fas fa-trash" /> Delete
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <Field
                    name={`${providerCenters}.CenterId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Center*"
                    options={ClinicalCenterDropdown}
                    fieldName={"Center"}
                    disabled={disable}
                    // validate={Validator.required}
                    labelText={"Center*"}
                  />
                  {/* <label class="placeholder-label">Center Name</label> */}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <Field
                    name={`${providerCenters}.HireDate`}
                    type="text"
                    // validate={Validator.required}
                    label="Hire Date"
                    component={ReduxDateInput}
                    fieldName={"Hire Date"}
                    labelText={"Hire Date*"}
                    disabled={disable}
                  />
                  {/* <i class="far fa-calendar-alt form-control-icon" /> */}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <Field
                    name={`${providerCenters}.TerminationDate`}
                    type="text"
                    // validate={Validator.required}
                    label="Termination Date"
                    component={ReduxDateInput}
                    fieldName={"Termination Date"}
                    disabled={disable}
                    labelText={"Termination Date*"}
                  />
                  {/* <i class="far fa-calendar-alt form-control-icon" /> */}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div className="row">
                  {" "}
                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label
                        class="custom-control-label"
                        for="is-emergency-contact"
                      >
                        Is Supervisor{"   "}
                        <Field
                          name={`${providerCenters}.IsSupervisor`}
                          type="text"
                          class="custom-control-input not-empty"
                          component={ReduxToggle}
                          disabled={disable}
                        />
                      </label>
                    </div>
                  </div>
                  <div class="col-6 col-md-3 col-lg-6">
                    <div class="form-group">
                      <label
                        class="custom-control-label"
                        for="is-emergency-contact"
                      >
                        Is Read Only{"   "}
                        <Field
                          name={`${providerCenters}.IsReadOnly`}
                          type="text"
                          class="custom-control-input not-empty"
                          component={ReduxToggle}
                          disabled={disable}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a
            class="add-contact"
            onClick={() => fields.push({})}
            disabled={disable}
          >
            {fields.length >= 1 && (
              <label>
                <a> + Add Another Center</a>
              </label>
            )}
            {fields.length == 0 && (
              <label>
                {" "}
                <a> + Add Center</a>
              </label>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

const ProviderCentersForm1 = connect((state) => {
  const {
    clinical_center_get: { data: ClinicalCenterDropdown },
  } = state;
  const providerCenterRow = formValueSelector("staffForm_practitioner")(
    state,
    "Center"
  );
  return {
    providerCenterRow,
    ClinicalCenterDropdown: ClinicalCenterDropdown?.Data?.ClinicalCenter,
  };
})(ProviderCentersForm);

const ProviderForm = (props) => {
  const [formFields, setFormFields] = useState([
    {
      FormFieldMetaDataId: 361,
      name: "Credentials",
      validate: null,
      isRequired: null,
      fieldName: "Credentials",
      isMulti: null,
      DependentOn: null,
      options: null,
      DependentOptions: null,
      for: null,
      componentType: "ReduxInput",
      label: "Credentials",
      labelText: "Credentials",
      FiledLength: null,
      format: null,
      normalize: null,
      helperText: null,
      Class: null,
      isDisplayTitle: null,
      Title: "Credentials",
    },
    {
      FormFieldMetaDataId: 362,
      name: "NPI",
      validate: null,
      isRequired: null,
      fieldName: "NPI",
      isMulti: null,
      DependentOn: null,
      options: null,
      DependentOptions: null,
      for: null,
      componentType: "ReduxInput",
      label: "NPI",
      labelText: "NPI",
      FiledLength: null,
      format: null,
      normalize: null,
      helperText: null,
      Class: null,
      isDisplayTitle: null,
      Title: "NPI",
    },
    {
      FormFieldMetaDataId: 363,
      name: "Taxonomy",
      validate: null,
      isRequired: null,
      fieldName: "Taxonomy",
      isMulti: null,
      DependentOn: null,
      options: null,
      DependentOptions: null,
      for: null,
      componentType: "ReduxInput",
      label: "Taxonomy",
      labelText: "Taxonomy",
      FiledLength: null,
      format: null,
      normalize: null,
      helperText: null,
      Class: null,
      isDisplayTitle: null,
      Title: "Taxonomy",
    },
    {
      FormFieldMetaDataId: 364,
      name: "ClearingHouseProviderId",
      validate: null,
      isRequired: null,
      fieldName: "CH Provider Id",
      isMulti: null,
      DependentOn: null,
      options: null,
      DependentOptions: null,
      for: null,
      componentType: "ReduxInput",
      label: "Clearing House Provider Id",
      labelText: "Clearing House Provider Id",
      FiledLength: null,
      format: null,
      normalize: null,
      helperText: null,
      Class: null,
      isDisplayTitle: null,
      Title: "Clearing House Provider Id",
    },
  ]);
  const [isProvider, setIsProvider] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const suhbeader = useSubheader();
  suhbeader.setTitle(props.StaffId ? "Edit Staff" : "Add New Staff");
  const selector = formValueSelector("appForm");

  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    handleNext,
    handleBack,
    activeStep,
    NextTab,
    StaffId,
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            {/* <CardHeader title={"Add New Course"}></CardHeader> */}
            <CardBody>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="staff-form pt-2 pb-2">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="row pb-5 pl-3 pr-3 pt-3">
                        <DynamicForm formFields={formFields} disable={false} />
                      </div>
                      <FieldArray
                        name="Center"
                        component={ProviderCentersForm1}
                        // disable={IsDeActivated}
                        // dispatch={props.dispatch}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-left">
                    {StaffId && (
                      <>
                        {activeStep != 0 && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                              history.push(
                                `/admin/staff/edit-form?tab=mobile-app-details&id=${props.StaffId ||
                                  props.staffId}`
                              );
                              props.handleBack();
                            }}
                            disabled={activeStep === 0}
                          >
                            Back
                          </Button>
                        )}
                        &nbsp;
                        {NextTab && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={handleNext}
                            disabled={!NextTab}
                          >
                            Next
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <div className="text-right">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      type="submit"
                      disabled={pristine || submitting}
                    >
                      Save
                    </Button>
                    &nbsp;
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      disabled={pristine || submitting}
                      onClick={reset}
                    >
                      Clear Values
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const ReduxProviderForm = reduxForm({
  form: "providerForm",
  enableReinitialize: true,
})(ProviderForm);

const ProviderDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getStaffId = () => {
    let param = new URLSearchParams(history.location.search);
    let staffId = param.get("id");
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };
  
  const { staffData, isStaffUpsert, isFetching, staffId } = useSelector(
      (state) => {
        const {
          staff_upsert: { data: upsertData },
          staff_get: { data: staffUser },
        } = state;
        return {
          staffData: staffUser?.Data?.Staffs?.[0] || {},
          isStaffUpsert: upsertData?.ResponseCode === 1,
          staffId: parseInt(upsertData?.Data?.Id),
        };
      },
      shallowEqual
    );

  useEffect(() => {
     dispatch(clinicalCenterGet({}));

    if (props.StaffId || props.StaffId === 0) {
      dispatch(
        staffGet({
          Staff: [
            {
              StaffId: props.StaffId,
            },
          ],
        })
      );
    }
    return () => {
      dispatch(resetStaffGet({}));
    };
  }, []);

  const onSubmit = (values) => {
    let ObjectData = formDataHandler(values);
    const formData = {
      Staff: [
        {
          
          ...formDataHandler(staffData),
          ...ObjectData,
          InviteDetails:null,
          Misc: values.Misc && [formDataHandler(values.Misc[0])],
          Center:
            values.Center && values.Center.map((item) => formDataHandler(item)),
        },
      ],
    };
    dispatch(staffUpsert(formData));
  };

  useEffect(() => {
    if (isStaffUpsert) {
      history.push(`/admin/staff/list`);
      dispatch(resetStaffUpsert());
      props.handleNext();
    }
  }, [isStaffUpsert]);
  return (
    <div className="">
      <ReduxProviderForm
        initialValues={{
          ...staffData
          }
        }
        StaffId={getStaffId()}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
        activeStep={props.activeStep}
        NextTab={props.NextTab}
        onSubmit={onSubmit}
        // onCancel={() =>
        //   this.props.history.push("/manage/staff-management/staff-list")
        // }
        // updateStep={this.props.updateStep}
        // formData={this.props.initialValues}
        // getStaffStatus={this.props.getStaffStatus}
      />
    </div>
  );
};

export default ProviderDetail;
